import React, { Component } from "react";
import { Tooltip } from "antd";

import Avatar from "components/users/avatar/avatar";

import styles from "./user_bar.module.css";

export default class UsersBar extends Component {
  render() {
    const { users = [] } = this.props;

    return (
      <div className={styles.container}>
        {users.map((user, i) => (
          <Tooltip key={i} title={user.name}>
            <span className={styles.tooltipContainer}>
              <Avatar name={user.name} email={user.email} />
            </span>
          </Tooltip>
        ))}
      </div>
    );
  }
}
