import React from "react";

import { formatToLocal } from "utils/dates";

import styles from "./styles.module.css";

export function LabelTimestamp({ timestamp }) {
  return (
    <>
      <div className={styles.date}>{formatToLocal(timestamp, "YYYY-MM-DD")}</div>
      <div className={styles.time}>{formatToLocal(timestamp, "HH:mm:ss")}</div>
    </>
  );
}
