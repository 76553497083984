import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import classNames from "classnames";

import eventTypeOptions from "config/constants/event_type_options";

import dayjs from "utils/dayjs";
import getChannelInfoByCode from "utils/get_channel_info_by_code";

import { MainSection, RightSection } from "./common";

import styles from "./events_list.module.css";

const getChannelIcon = (booking_unique_id) => {
  if (booking_unique_id) {
    const channelCode = booking_unique_id.substr(0, 3);

    const channel = getChannelInfoByCode(channelCode);

    if (channel && channel.icon) {
      return <img src={channel.icon} className={styles.icon} alt={channel.title} />;
    }
    return <div className={styles.emptyIcon}>{channelCode}</div>;
  }
  return null;
};

function EventsListItemInquiry({ item, isMobile }) {
  const { t } = useTranslation();
  const {
    event,
    payload: { booking_details },
  } = item;

  const eventTitle = useMemo(() => {
    const findEventOption = eventTypeOptions.find((i) => i === event);
    if (findEventOption) {
      return t(`live_feed_events_widget:events:${findEventOption}`).toUpperCase();
    }
    return null;
  }, [t, event]);

  const arrivalDateValue = dayjs(booking_details.arrival_date).format(
    t("formats:date_time_with_weekday"),
  );
  const eventTitleClassName = classNames(styles.event, styles[`event--${event}`]);
  const channelIcon = getChannelIcon("ABB");

  return (
    <>
      <MainSection>
        <p className={eventTitleClassName}>{eventTitle}</p>
        <p className={styles.arrivalDate}>{arrivalDateValue}</p>

        <p className={styles.description}>
          {booking_details.guest_name} <span>|</span> 1 {t("live_feed_events_widget:room")}
          <span>x</span> {booking_details.nights} {t("live_feed_events_widget:night")}
        </p>
      </MainSection>
      <RightSection
        isMobile={isMobile}
        channelIcon={channelIcon}
        amount={booking_details.expected_payout_amount_accurate}
      />
    </>
  );
}

EventsListItemInquiry.propTypes = {
  item: PropTypes.shape({}),
};

export default EventsListItemInquiry;
