import { useQuery } from "react-query";
import store from "store";

const { AdminUsers } = store;
export default function useProperties(userId) {
  return useQuery(["admin_user_properties", userId], () => AdminUsers.properties(userId), {
    enabled: !!userId,
    retry: false,
  });
}
