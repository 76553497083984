import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Form, Radio } from "antd";

import { horizontalFormItemLayout } from "config/constants/layouts/form";

import styles from "styles/form_in_drawer.module.css";

function handleChangeCallback(callback, field) {
  return (event) => {
    callback(field, event.target.value);
  };
}

function InputCalculationMode(props) {
  const { t } = useTranslation();
  const { model, disabled, onChange } = props;
  const onHandleChange = handleChangeCallback(onChange, "rate_mode");
  const isDisabled = Boolean(model.rate_category_id) || Boolean(model.id) || disabled;

  if (model.sell_mode !== "per_person") {
    return null;
  }

  return (
    <Form.Item {...horizontalFormItemLayout} label="Rate Mode">
      <Radio.Group
        value={model.rate_mode}
        onChange={onHandleChange}
        className={styles.buttonGroup}
        disabled={isDisabled}
      >
        {(model.inherit_rate === false || model.is_derived === false) && (
          <Radio.Button className={styles.buttonGroup__button__1_3} value="manual">
            <span data-cy="radio_group_sell_mode_per_person_manual">
              {t("rates_page:form:per_person_manual_option")}
            </span>
          </Radio.Button>
        )}
        <Radio.Button className={styles.buttonGroup__button__1_3} value="derived">
          <span data-cy="radio_group_sell_mode_per_person_derived">
            {t("rates_page:form:per_person_derived_option")}
          </span>
        </Radio.Button>
        <Radio.Button className={styles.buttonGroup__button__1_3} value="auto">
          <span data-cy="radio_group_sell_mode_per_person_auto">
            {t("rates_page:form:per_person_auto_option")}
          </span>
        </Radio.Button>
        {model.inherit_rate === true && model.is_derived === true && (
          <Radio.Button className={styles.buttonGroup__button__1_3} value="cascade">
            {t("rates_page:form:per_person_cascade_option")}
          </Radio.Button>
        )}
      </Radio.Group>
    </Form.Item>
  );
}

InputCalculationMode.propTypes = {
  t: PropTypes.func,
  model: PropTypes.object,
  onChange: PropTypes.func,
};

export default InputCalculationMode;
