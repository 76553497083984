import React from "react";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  IssuesCloseOutlined,
} from "@ant-design/icons";

import { BookingRequested } from "./events/booking_requested";
import { BookingResent } from "./events/booking_resent";
import { BookingRevisionAcknowledged } from "./events/booking_revision_acknowledged";
import { BookingWebhookSent } from "./events/booking_webhook_sent";
import { LabelTimestamp } from "./events/label_timestamp";
import { SystemEvent } from "./events/system_event";
import { SystemEventWithRevision } from "./events/system_event_with_revision";

const eventTypes = {
  booking_created: SystemEventWithRevision,
  booking_modified: SystemEventWithRevision,
  booking_cancelled: SystemEventWithRevision,
  property_email_notification_sent: SystemEvent,
  customer_email_notification_sent: SystemEvent,
  booking_revision_acknowledged: BookingRevisionAcknowledged,
  booking_revision_received_via_feed: BookingRequested,
  booking_revision_received_via_list: BookingRequested,
  booking_revision_received_by_id: BookingRequested,
  booking_revision_resent: BookingResent,
  booking_received_via_booking_find: BookingRequested,
  booking_received_via_booking_list: BookingRequested,
  booking_resent: BookingResent,
  booking_unacked_email_sent: SystemEvent,
  booking_webhook_sent: BookingWebhookSent,
};

const colors = {
  booking_created: "#52c419",
  booking_modified: "#ffc53d",
  booking_cancelled: "#ff4d4f",
  property_email_notification_sent: "blue",
  customer_email_notification_sent: "blue",
  booking_revision_acknowledged: "gray",
  booking_revision_received_via_feed: "gray",
  booking_revision_received_via_list: "gray",
  booking_revision_received_by_id: "gray",
  booking_revision_resent: "blue",
  booking_received_via_booking_find: "gray",
  booking_received_via_booking_list: "gray",
  booking_resent: "blue",
  booking_unacked_email_sent: "blue",
  booking_webhook_sent: "blue",
};

const dots = {
  booking_created: <CheckCircleOutlined />,
  booking_modified: <IssuesCloseOutlined />,
  booking_cancelled: <CloseCircleOutlined />,
};

export function buildTimelineItem(event) {
  const EventComponent = eventTypes[event.event];

  return {
    dot: dots[event.event],
    color: colors[event.event],
    label: <LabelTimestamp timestamp={event.inserted_at} />,
    children: <EventComponent event={event} />,
  };
}
