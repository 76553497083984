import { useQuery } from "react-query";
import store from "store";

const { AdminWlPartners } = store;

export function useAdminWlPartners(tableParams) {
  const { searchQuery, pagination, order } = tableParams || {};

  let filter;

  if (searchQuery) {
    filter = { title: { has: searchQuery } };
  }

  return useQuery(["admin_wl_partners", tableParams], () => AdminWlPartners.list(filter, pagination, order), {
    enabled: !!tableParams,
  });
}
