const positiveNumberValidator = (value) => {
  if (Array.isArray(value)) {
    const isPositiveValue = value.some((item) => Number(item) <= 0);
    const isNumberValue = value.some((item) => isNaN(Number(item)));

    if (isPositiveValue) {
      return [false, "Should be a positive value"];
    }
    if (isNumberValue) {
      return [false, "Should be a number"];
    }
  }
  return [true, null];
};

const positiveIntegerValidator = (value) => {
  if (Array.isArray(value)) {
    const isIntegerValue = value.some((item) => !Number.isInteger(Number(item)));
    if (isIntegerValue) {
      return [false, "Should be a integer value"];
    }
  }

  return [true, null];
};

const VALIDATORS = {
  rate: positiveNumberValidator,
  availability_offset: positiveIntegerValidator,
  min_stay_arrival: positiveIntegerValidator,
  min_stay_through: positiveIntegerValidator,
  max_stay: positiveIntegerValidator,
  max_availability: positiveIntegerValidator,
  availability: positiveIntegerValidator,
};

export default (restriction, value) => {
  if (value.length === 0 && restriction === "max_availability") {
    return [true, null];
  }

  if (typeof VALIDATORS[restriction] === "function") {
    return VALIDATORS[restriction](value);
  }

  return [true, null];
};
