import { useMutation, useQueryClient } from "react-query";
import store from "store";

const { ExtraCategories } = store;

export function useDeleteCategoryAction(propertyId) {
  const queryClient = useQueryClient();

  return useMutation(({ id }) => ExtraCategories.delete(id), {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["extras", propertyId],
      });
    },
  });
}
