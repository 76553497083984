import React, { Component } from "react";
import store from "store";

import withComponentRef from "containers/with_component_ref";

import confirmDirtyStateSave from "utils/confirm_dirty_state_save";
import EventEmitter from "utils/event_emitter";
import parseApiErrors from "utils/parse_api_errors";

import convertMinNightsValue from "../../utils/convert_min_nights_value";

import ListingAvailabilityForm from "./listing_availability_form";

const { Channels } = store;

const DEFAULT_WEEKDAYS = [false, false, false, false, false, false, false];

class AvailabilityFormDrawer extends Component {
  DEFAULT_VALUE = {
    day_of_week_check_out: DEFAULT_WEEKDAYS,
    day_of_week_check_in: DEFAULT_WEEKDAYS,
  };

  state = {
    loading: false,
    submitting: false,
    value: this.DEFAULT_VALUE,
    errors: {},
    globalErrors: {},
  };

  formRef = React.createRef();

  componentDidMount() {
    const { listing } = this.props;

    if (listing) {
      this.setState({ value: listing.availability_rule });
    }
  }

  componentDidUpdate(prevProps) {
    const { listing } = this.props;

    if (listing && listing !== prevProps.listing) {
      this.setState({ value: listing.availability_rule });
    }
  }

  handleListingRefresh = () => {
    const { listing, channelId } = this.props;
    const { listing_id } = listing;

    return Channels.airbnbLoadListingAvailability(channelId, { listing_id }).then((response) => {
      const { day_of_week_min_nights, ...rest } = response;

      const updatedMinNights = convertMinNightsValue(day_of_week_min_nights);
      const value = { ...rest, day_of_week_min_nights: updatedMinNights };

      this.setState({ value });
    });
  };

  handleSubmit = () => {
    const { listing, channelId, onClose, onAvailabilityUpdate } = this.props;
    const { value } = this.state;
    const { id } = listing;

    this.setState({ submitting: true });

    this.formRef.current
      .validate()
      .then(() => {
        const { day_of_week_min_nights, ...restValue } = value;

        const formattedMinNights = day_of_week_min_nights.map((day) => (day.isChecked ? day.value : -1));

        const data = {
          channel_rate_plan_id: id,
          data: {
            day_of_week_min_nights: formattedMinNights,
            ...restValue,
          },
        };

        Channels.airbnbUpdateListingAvailability(channelId, data)
          .then((response) => {
            this.setState({ submitting: false, errors: {}, globalErrors: {} });
            onAvailabilityUpdate(response);
            onClose();
          })
          .catch((error) => {
            if (!error.isValidationError) {
              throw error;
            }

            const { details } = error.errors;

            if (details.error === "invalid_token") {
              this.handleInvalidTokenError(details);
              return;
            }

            const { availability_rule, ...errors } = parseApiErrors(error);

            this.setState({ submitting: false, errors, globalErrors: { availability_rule } });
          });
      })
      .catch(() => {
        this.setState({ submitting: false });
      });
  };

  handleInvalidTokenError = (errorDetails) => {
    EventEmitter.trigger("channel_management:invalid_token", errorDetails);
    this.setState({ submitting: false });
  };

  handleChange = (value) => {
    this.setState({ value });
  };

  beforeClose = () => {
    const { current } = this.formRef;

    if (current) {
      const { dirty } = current.formik.current;
      return confirmDirtyStateSave(dirty);
    }

    return Promise.reject();
  };

  render() {
    const { value, loading, submitting, errors, globalErrors } = this.state;

    return (
      <ListingAvailabilityForm
        value={value}
        loading={loading}
        defaultValue={this.DEFAULT_VALUE}
        errors={errors}
        globalErrors={globalErrors}
        submitting={submitting}
        componentRef={this.formRef}
        onRefresh={this.handleListingRefresh}
        onChange={this.handleChange}
        onSubmit={this.handleSubmit}
      />
    );
  }
}

export default withComponentRef(AvailabilityFormDrawer);
