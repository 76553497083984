import React, { PureComponent } from "react";
import debounce from "lodash/debounce";

const DEFAULT_DEBOUNCE_TIME = 100;

export default (WrappedComponent) => {
  class WithDebounce extends PureComponent {
    state = {};

    static getDerivedStateFromProps(nextProps, prevState) {
      const nextState = {};

      if (nextProps.onChange !== prevState.onChange) {
        const { debounceTime = DEFAULT_DEBOUNCE_TIME } = nextProps;
        nextState.onChange = nextProps.onChange;
        nextState.onChangeDebounced = debounce(nextState.onChange, debounceTime);
      }

      return nextState;
    }

    render() {
      const { forwardedRef, withDebounce = true, onChange, ...passThroughProps } = this.props;

      const { onChangeDebounced } = this.state;

      const onChangeToPass = withDebounce ? onChangeDebounced : onChange;

      return (
        <WrappedComponent
          {...passThroughProps}
          withDebounce={withDebounce}
          ref={forwardedRef}
          onChange={onChangeToPass}
        />
      );
    }
  }

  return React.forwardRef((props, ref) => {
    return <WithDebounce {...props} forwardedRef={ref} />;
  });
};
