export default function combineRoomTypesWithRatePlans(roomTypes, ratePlans) {
  const updatedRoomTypes = roomTypes
    .filter((roomType) => {
      const isRatePlanPresentForRoom = !!ratePlans[roomType.id];
      return isRatePlanPresentForRoom;
    })
    .map((roomType) => {
      const roomRelatedRates = ratePlans[roomType.id];

      return {
        ...roomType,
        ratePlans: roomRelatedRates,
      };
    });

  return updatedRoomTypes;
}
