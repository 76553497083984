import React from "react";
import { useTranslation } from "react-i18next";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import classNames from "classnames";

import styles from "../styles.module.css";

const MappingStatus = ({ isError }) => {
  const { t } = useTranslation();

  return (
    <span className={styles.mappingStatus}>
      {isError
        ? <ExclamationCircleOutlined title={t("mapping:rates_mapping_conflict")} />
        : <>&rarr;</>}
    </span>
  );
};

export const MappingRow = ({ source, target, showMapping = true, isError = false, className }) => {
  return (
    <div className={classNames(styles.row, className)}>
      <div className={styles.column}>{source}</div>
      {showMapping && (
        <>
          <div className={styles.mappingStatus}>
            <MappingStatus isError={isError} />
          </div>
          <div className={styles.column}>
            {target}
          </div>
        </>
      )}
    </div>
  );
};
