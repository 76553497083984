import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import ChannexDrawer from "drawers/channex_drawer";

import DrawerFormWrapper from "components/drawer_form_wrapper";

import ListingAvailabilityForm from "./form";

class ListingAvailabilityDrawer extends Component {
  render() {
    const { t, listing, visible, channelId, onClose, onAvailabilityUpdate } = this.props;

    return (
      <ChannexDrawer
        title={t("channels_page:form:listing:availability_drawer:title")}
        visible={visible}
        onClose={onClose}
      >
        {({ componentRef, handleCloseFromContent }) => (
          <DrawerFormWrapper withActions>
            <ListingAvailabilityForm
              listing={listing}
              channelId={channelId}
              componentRef={componentRef}
              onAvailabilityUpdate={onAvailabilityUpdate}
              onClose={handleCloseFromContent}
            />
          </DrawerFormWrapper>
        )}
      </ChannexDrawer>
    );
  }
}

export default withTranslation()(ListingAvailabilityDrawer);
