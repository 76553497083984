import React from "react";
import { useTranslation } from "react-i18next";

import ChannexDrawer from "drawers/channex_drawer";

import DrawerFormWrapper from "components/drawer_form_wrapper";

import { CreateAvailabilityRuleForm } from "./create_form/create_form";

export function CreateDrawer({ propertyId, visible, onClose }) {
  const { t } = useTranslation();
  const title = t("availability_rules:titles:create");

  return (
    <ChannexDrawer title={title} visible={visible} onClose={onClose}>
      <DrawerFormWrapper withActions>
        <CreateAvailabilityRuleForm propertyId={propertyId} onFinish={onClose} />
      </DrawerFormWrapper>
    </ChannexDrawer>
  );
}
