import React from "react";

import Desktop from "./desktop";
import Mobile from "./mobile";

import styles from "./channel_score.module.css";

export default function ChannelScore({ review, isMobile }) {
  const {
    attributes: { overallScore, scores },
  } = review;

  if (!scores || scores.length === 0) {
    return null;
  }

  return (
    <div className={styles.scores}>
      {isMobile && <Mobile overall={overallScore} scores={scores} />}
      {isMobile || <Desktop overall={overallScore} scores={scores} />}
    </div>
  );
}
