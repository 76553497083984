const MIN_LIMIT = 1;

const calculateLimitFromRef = (ref, itemHeight) => {
  if (!ref.current) {
    return null;
  }

  const {
    current: { clientHeight },
  } = ref;

  const limit = Math.ceil(clientHeight / itemHeight);

  return limit || MIN_LIMIT;
};

export default calculateLimitFromRef;
