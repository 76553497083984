import React from "react";
import { useNavigate } from "react-router";
import { useOutletContext } from "react-router-dom";
import qs from "qs";

import { stringifyQueryParams } from "../../routing/utils";

import StateChangesDrawer from "./state_changes_drawer";

export const StateChangesDrawerRoutable = () => {
  const navigate = useNavigate();
  const { closePath, ratePlans, isLoadingRatePlans, roomTypes, isLoadingRoomTypes } = useOutletContext();

  const params = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
    comma: true,
    plainObjects: true,
  });

  // todo: validate params and redirect to inventory if invalid

  const handleClose = () => {
    const { log: _log, ...inventoryParams } = params;
    navigate(`${closePath}${stringifyQueryParams(inventoryParams)}`);
  };

  const { property_id, log } = params;

  return (
    <StateChangesDrawer
      visible
      ratePlanId={log.rate_plan_id}
      roomTypeId={log.room_type_id}
      date={log.date}
      restriction={log.restriction}
      propertyId={property_id}
      ratePlans={ratePlans}
      isLoadingRatePlans={isLoadingRatePlans}
      roomTypes={roomTypes}
      isLoadingRoomTypes={isLoadingRoomTypes}
      onClose={handleClose}
    />
  );
};
