import React, { useEffect } from "react";

export default function useDragging() {
  const dragCounterRef = React.useRef(0);
  const [isDragging, setIsDragging] = React.useState(false);

  const handleWindowDragEnter = (e) => {
    e.preventDefault();

    dragCounterRef.current += 1;
    if (dragCounterRef.current > 0) {
      setIsDragging(true);
    }
  };

  const handleWindowDragLeave = (e) => {
    e.preventDefault();

    dragCounterRef.current -= 1;
    if (dragCounterRef.current === 0) {
      setIsDragging(false);
    }
  };

  const handleWindowDrop = (e) => {
    e.preventDefault();

    dragCounterRef.current = 0;
    setIsDragging(false);
  };

  useEffect(() => {
    window.addEventListener("dragenter", handleWindowDragEnter);
    window.addEventListener("dragleave", handleWindowDragLeave);
    window.addEventListener("drop", handleWindowDrop);

    return () => {
      window.removeEventListener("dragenter", handleWindowDragEnter);
      window.removeEventListener("dragleave", handleWindowDragLeave);
      window.removeEventListener("drop", handleWindowDrop);
    };
  }, []);

  return isDragging;
}
