import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import FormSelectModifiable from "components/forms/inputs/form_select_modifiable";

const getUnavailableTaxIds = (parentTax) => {
  if (!parentTax) {
    return [];
  }

  const { taxes = [], id } = parentTax;

  const taxIds = taxes.reduce(
    (acc, tax) => {
      return [...acc, tax.id];
    },
    [id],
  );

  return taxIds;
};

export default function TaxAddButtonContent({ taxes, parentTax, onSelect, onCreate }) {
  const [selectedTaxId, setSelectedTaxId] = useState();
  const { t } = useTranslation();

  const options = useMemo(() => {
    if (!taxes) {
      return [];
    }

    const unavailableIds = getUnavailableTaxIds(parentTax);

    return Object.values(taxes)
      .filter((tax) => {
        const isTaxAvailable = !unavailableIds.includes(tax.id);
        const isSelectedTax = selectedTaxId === tax.id;

        return isSelectedTax || isTaxAvailable;
      })
      .map(({ id, title }) => ({ value: id, representation: title }));
  }, [parentTax, taxes, selectedTaxId]);

  const handleChange = useCallback(
    (taxId) => {
      setSelectedTaxId(taxId);
      onSelect(taxId);
    },
    [onSelect],
  );

  return (
    <FormSelectModifiable
      name="add_tax"
      view="vertical"
      label={t("taxes:tax_set:form:add_modal:tax")}
      placeholder={t("taxes:tax_set:form:add_modal:tax")}
      value={selectedTaxId}
      options={options}
      onChange={handleChange}
      onNew={onCreate}
    />
  );
}
