import React from "react";

import ContentContainer from "../../../../../_common/content_container";

import PrivateFeedback from "./private_feedback/private_feedback";
import PublicFeedback from "./public_feedback/public_feedback";

export default function GuestFeedback({ review }) {
  return (
    <ContentContainer quotes border>
      <div>
        <PublicFeedback review={review} />
        <PrivateFeedback review={review} />
      </div>
    </ContentContainer>
  );
}
