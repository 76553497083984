import React from "react";

import ChannexDrawer from "drawers/channex_drawer";

import ApplicationUsersList from "./application_users_list";

export default function AirBnbManageRatePlanDrawer({ value, visible, onClose }) {
  return (
    <ChannexDrawer title="Manage Access" visible={visible} onClose={onClose}>
      {() => (
        <ApplicationUsersList value={value} onFinish={onClose} />
      )}
    </ChannexDrawer>
  );
}
