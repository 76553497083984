import { useEffect, useState } from "react";
import { captureException } from "@sentry/react";
import store from "store";

import getSortedArray from "../../utils/get_sorted_array";

const { RoomTypes } = store;

const DEFAULT_SORT_FIELD = "title";

export default function useRoomTypes(propertyId, { sorted = false, sortedBy = DEFAULT_SORT_FIELD } = {}) {
  const [roomTypes, setRoomTypes] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadRoomTypes = async () => {
      let loadedRoomTypes;

      try {
        loadedRoomTypes = await RoomTypes.options({ property_id: propertyId });

        if (sorted || sortedBy) {
          loadedRoomTypes = getSortedArray(loadedRoomTypes || [], sortedBy);
        }

        setRoomTypes(loadedRoomTypes);
      } catch (apiError) {
        setError(apiError);
      } finally {
        setIsLoading(false);
      }
    };

    loadRoomTypes().catch(captureException);
  }, [propertyId, sorted, sortedBy]);

  return { roomTypes, error, isLoading };
}
