import React, { Component } from "react";
import { Spin } from "antd";

import styles from "./widget_loader.module.css";

class WidgetLoader extends Component {
  render() {
    return (
      <div className={styles.loader}>
        <Spin />
      </div>
    );
  }
}

export default WidgetLoader;
