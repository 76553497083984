import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import _ from "lodash";

export const useQueryParams = (paramsConfig, options = {}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const params = useMemo(() => {
    return Object.entries(paramsConfig).reduce((acc, [key, config]) => {
      const paramName = _.snakeCase(key);
      let paramValue = searchParams.get(paramName);

      if (paramValue === undefined) {
        if (config.default !== undefined) {
          acc[key] = config.default;
        }

        return acc;
      }

      if (config.parse) {
        paramValue = config.parse(paramValue);
      }

      if (paramValue === null && config.default !== undefined) {
        acc[key] = config.default;
      } else {
        acc[key] = paramValue;
      }

      return acc;
    }, {});
  }, [paramsConfig, searchParams]);

  const formatParams = useCallback((rawParams) => {
    return Object.entries(rawParams).reduce((acc, [key, value]) => {
      const paramName = _.snakeCase(key);

      if (paramsConfig[key]?.format) {
        acc[paramName] = paramsConfig[key].format(value);
      } else {
        acc[paramName] = value;
      }

      return acc;
    }, {});
  }, [paramsConfig]);

  const replaceParams = useCallback((newParams) => {
    if (options.beforeUpdate) {
      newParams = options.beforeUpdate(newParams);
    }

    newParams = formatParams(newParams);

    setSearchParams(newParams);
  }, [options, formatParams, setSearchParams]);

  const updateParams = useCallback((partialParams) => {
    let newParams = { ...params, ...partialParams };

    if (options.beforeUpdate) {
      newParams = options.beforeUpdate(newParams);
    }

    newParams = formatParams(newParams);

    setSearchParams(newParams);
  }, [options, params, formatParams, setSearchParams]);

  return {
    ...params,
    replaceParams,
    updateParams,
  };
};
