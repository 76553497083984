import React from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, Image, Upload } from "antd";

import { horizontalFormItemLayout } from "config/constants/layouts/form";

function readFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = function () {
      resolve(reader.result);
    };

    reader.onerror = function (error) {
      reject(error);
    };
  });
}

export const FormFileBase64 = React.forwardRef(({ accept, cutHeader = false, label, errors, onChange, value }, ref) => {
  const validation = () => {
    if (errors) {
      const message = Array.isArray(errors) ? errors.join(" ") : errors;

      return {
        validateStatus: "error",
        help: <span data-cy="input_error_message">{message}</span>,
      };
    }

    return {};
  };

  return (
    <Form.Item
      label={label}
      {...validation()}
      {...horizontalFormItemLayout}
    >
      {value && (
        <div>
          <Image style={{ maxWidth: 100, maxHeight: 100, padding: 12 }} src={value} />
        </div>
      )}
      <Upload
        ref={ref}
        accept={accept}
        multiple={false}
        showUploadList={false}
        beforeUpload={() => false}
        onChange={async ({ file }) => {
          if (file.status === "removed") {
            onChange(null);
            return;
          }

          let data = await readFile(file);

          if (cutHeader) {
            const [_header, body] = data.split(",");

            if (body) {
              data = body;
            }
          }

          onChange(data);
        }}
      >
        <Button icon={<UploadOutlined />}>Click to Upload</Button>
      </Upload>
    </Form.Item>
  );
});
