import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { EditOutlined } from "@ant-design/icons";

import { Actions } from "components/crud_table/columns/actions/actions";
import { ReactQueryCrudTable } from "components/crud_table/react_query";

import useDrawer from "hooks/use_drawer";

import { removeMenuItem } from "../../components/menu/items/remove";
import { DateRangeValue } from "../../components/value_presentation/range";
import { getActivePropertyId } from "../../store/storage/selectors/session_selector";

import { CreateDrawer } from "./create_drawer/create_drawer";
import { EditDrawer } from "./edit_drawer/edit_drawer";
import { useAvailabilityRules } from "./use_availability_rules/use_availability_rules";
import { useDeleteAction } from "./use_delete_action";

export function AvailabilityRulesTable() {
  const { t } = useTranslation();
  const propertyId = useSelector(getActivePropertyId);

  const [tableParams, setTableParams] = useState();
  const { isOpen: isCreateDrawerOpen, open: openCreateDrawer, close: closeCreateDrawer } = useDrawer();
  const { payload: editDrawerPayload, isOpen: isEditDrawerOpen, open: openEditDrawer, close: closeEditDrawer } = useDrawer();
  const { mutateAsync: deleteAction } = useDeleteAction(propertyId);

  const query = useAvailabilityRules(propertyId, tableParams);

  const handleEditClick = (record) => () => {
    openEditDrawer(record);
  };

  const columns = () => {
    return [{
      title: t("general:title"),
      dataIndex: "title",
      key: "title",
      sorter: true,
      render: (value) => value,
    }, {
      title: t("general:dates"),
      dataIndex: "dates",
      key: "dates",
      render: (_value, record) => <DateRangeValue from={record.startDate} to={record.endDate} />,
    }, {
      title: t("general:actions"),
      key: "action",
      align: "right",
      width: 100,
      render: (_value, record) => (
        <Actions
          items={[
            {
              key: "edit",
              onClick: handleEditClick(record),
              label: (
                <div data-cy="edit_availability_rules">
                  <EditOutlined /> {t("general:action:edit")}
                </div>
              ),
            },
            removeMenuItem({
              onRemove: () => deleteAction({ id: record.id }),
            }),
          ]}
        />
      ),
    }];
  };

  return (
    <>
      <ReactQueryCrudTable
        search={false}
        updateUrl={false}
        columns={columns}
        query={query}
        onCreateClick={openCreateDrawer}
        onTableParamsChange={setTableParams}
      />
      <CreateDrawer
        propertyId={propertyId}
        visible={isCreateDrawerOpen}
        onClose={closeCreateDrawer}
      />
      <EditDrawer
        propertyId={propertyId}
        visible={isEditDrawerOpen}
        availabilityRule={editDrawerPayload}
        onClose={closeEditDrawer}
      />
    </>
  );
}
