import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import withRouter from "routing/with_router";

import styles from "styles/view_list.module.css";

class ChannelView extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    model: PropTypes.object,
  };

  render() {
    const { t, model } = this.props;

    return (
      model && (
        <dl className={styles.list}>
          <dt className={styles.list__term}>{t("channels_page:form:title_label")}:</dt>
          <dd className={styles.list__description}>{model.title}</dd>
        </dl>
      )
    );
  }
}

const mapStateToProps = ({ channels }, { params }) => {
  const { channelId } = params;
  const model = channels[channelId];

  return {
    model,
  };
};

export default withTranslation()(withRouter(connect(mapStateToProps)(ChannelView)));
