import React from "react";

import FormSecretInput from "components/forms/inputs/formik/form_secret_input";

export default function ({ t, namespace, field, inputRef, handleFieldChange }) {
  return (
    <FormSecretInput
      inputRef={inputRef}
      namespace={namespace}
      name={field}
      view="horizontal"
      placeholder={t(`channels_page:form:settings_${field}_placeholder`)}
      label={t(`channels_page:form:settings_${field}_label`)}
      onChange={handleFieldChange}
    />
  );
}
