import React from "react";
import { Field } from "formik";

import FormTableSelectInner from "./form_table_select_inner";

export default function FormTableSelect({ name, title, label, items, onChange }) {
  return (
    <Field name={name}>
      {({ field, form }) => (
        <FormTableSelectInner
          label={label}
          items={items}
          title={title}
          field={field}
          form={form}
          onChange={onChange}
        />
      )}
    </Field>
  );
}
