import { useTranslation } from "react-i18next";
import { Typography } from "antd";

export const Title = () => {
  const { t } = useTranslation();

  return (
    <Typography.Title level={4} style={{ padding: "12px 12px 0" }}>
      {t("reports:titles:channels_report")}
    </Typography.Title>
  );
};
