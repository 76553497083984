import React from "react";

import { EventDescription } from "./event_description";
import { EventRevision } from "./event_revision";
import { EventTitle } from "./event_title";

export function SystemEventWithRevision({ event }) {
  return (
    <>
      <EventTitle event={event} />
      <EventDescription>
        <EventRevision value={event.booking_revision_id} />
      </EventDescription>
    </>
  );
}
