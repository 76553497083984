import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import store from "store";
import {
  getChannelMessages,
  getChannelMessagesMeta,
} from "store/storage/selectors/channel_messages_threads_selector";
import { getPropertyOptionById } from "store/storage/selectors/properties_selector";

import Chat from "components/chat";
import * as Page from "components/page";

import getChannelInfoByCode from "utils/get_channel_info_by_code";

import { calcCanReplyToBooking } from "../../chat/calc_can_reply_to_booking";

import NothingImage from "static/nothing-to-display.svg";
import styles from "./messages.module.css";

const { ChannelMessagesThreads } = store;
const { Bookings } = store;

export default function Messages({ booking }) {
  const { t } = useTranslation();
  const { id, property_id, customer, unique_id } = booking;
  const [isChannelSupported, setIsChannelSupported] = useState(true);
  const messagesList = useSelector(getChannelMessages(id));
  const messagesMeta = useSelector(getChannelMessagesMeta(id));
  const property = useSelector(getPropertyOptionById(property_id));

  const canBeReplied = calcCanReplyToBooking(booking, messagesList);

  useEffect(
    function checkChannelSupported() {
      const channelCode = unique_id.substr(0, 3);
      const channel = getChannelInfoByCode(channelCode);
      if (!channel.supportMessages) {
        setIsChannelSupported(false);
      }
    },
    [unique_id],
  );

  useEffect(
    function resetMessagesList() {
      ChannelMessagesThreads.resetMessagesList(id);
    },
    [id],
  );

  const loadList = useCallback(
    (meta) => {
      return Bookings.messagesListByBookingId(id, meta)
        .then((data) => {
          return data;
        })
        .catch(({ errors }) => {
          if (errors.code === "not_supported") {
            setIsChannelSupported(false);
          }
          return errors;
        });
    },
    [id, setIsChannelSupported],
  );

  const handleSendMessage = (message) => {
    return Bookings.sendMessageByBookingId(id, message);
  };

  if (!isChannelSupported) {
    return (
      <div className={styles.errorMessageWrapper}>
        <Page.ErrorMessage
          text={t("bookings_page:booking_view_dialog:messages:no_support")}
          icon={NothingImage}
        />
      </div>
    );
  }

  const senderName = `${customer.name} ${customer.surname}`;

  return (
    <div className={styles.messagesWrapper}>
      <Chat
        disabled={!canBeReplied}
        disabledReason={!canBeReplied && t("booking_messages:cannot_reply_old_booking")}
        messages={messagesList}
        meta={messagesMeta}
        onLoad={loadList}
        onSend={handleSendMessage}
        className={styles.messagesListWrapper}
        sender={senderName}
        receiver={property?.title}
      />
    </div>
  );
}
