import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tag, Typography } from "antd";
import store from "store";

import List from "components/list";
import Loading from "components/loading";

const { Paragraph } = Typography;

const { Webhooks } = store;

export default function LogView({ webhookId, webhookLogId }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(null);
  const [webhookLog, setWebhookLog] = useState({});
  const getResultTag = (result) => {
    return result ? <Tag color="green">Success</Tag> : <Tag color="red">Failure</Tag>;
  };

  useEffect(
    function loadWebhookLog() {
      if (loading === null) {
        setLoading(true);

        Webhooks.findLog(webhookId, webhookLogId)
          .then((response) => {
            setWebhookLog(response.data.attributes);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [webhookId, webhookLogId, loading, setLoading, setWebhookLog],
  );

  if (loading !== false) {
    return <Loading />;
  }

  return (
    <div>
      <List>
        <List.Item term={t("webhook_log_drawer:fields:id")} description={webhookLog.id} />
        <List.Item term={t("webhook_log_drawer:fields:event")} description={webhookLog.event} />
        <List.Item
          term={t("webhook_log_drawer:fields:result")}
          description={getResultTag(webhookLog.success)}
        />
        <List.Item
          term={t("webhook_log_drawer:fields:inserted_at")}
          description={webhookLog.inserted_at}
        />
      </List>
      <div className="bordered">
        <strong>{t("webhook_log_drawer:fields:payload")}</strong>
        <Paragraph>
          <pre>{webhookLog.payload}</pre>
        </Paragraph>
      </div>
      <div className="bordered">
        <strong>{t("webhook_log_drawer:fields:response")}</strong>
        <Paragraph>
          <pre>{webhookLog.response}</pre>
        </Paragraph>
      </div>
    </div>
  );
}
