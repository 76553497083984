import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Tabs } from "antd";

import ChannexDrawerRoutable from "drawers/channex_drawer_routable";

import FormWrapper from "components/webhooks/form_wrapper";
import WebhookLogs from "components/webhooks/logs";

import styles from "drawers/channex_drawer.module.css";

function WebhookFormDrawer() {
  const { t } = useTranslation();
  const { webhookId } = useParams();

  const title = t(webhookId ? "webhook_drawer:edit_header" : "webhook_drawer:create_header");
  const activeTab = "general";

  const buildItems = (handleCloseFromContent) => {
    const items = [{
      label: <span data-cy="genaral_tab">{t("webhook_drawer:tab_keys:settings")}</span>,
      key: "general",
      children: (
        <FormWrapper entityId={webhookId} onClose={handleCloseFromContent} />
      ),
    }];

    if (webhookId) {
      items.push({
        className: styles.noActionsInDrawer,
        label: <span data-cy="logs_tab">{t("webhook_drawer:tab_keys:logs")}</span>,
        key: "logs",
        children: (
          <WebhookLogs webhookId={webhookId} />
        ),
      });
    }

    return items;
  };

  return (
    <ChannexDrawerRoutable title={title} dataCy="webhook_drawer">
      {({ handleCloseFromContent }) => (
        <Tabs items={buildItems(handleCloseFromContent)} defaultActiveKey={activeTab} />
      )}
    </ChannexDrawerRoutable>
  );
}

export default WebhookFormDrawer;
