import { useMutation, useQueryClient } from "react-query";
import store from "store";

const { AvailabilityRules } = store;

export function useDeleteAction(propertyId) {
  const queryClient = useQueryClient();

  return useMutation(({ id }) => AvailabilityRules.delete(id), {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["availability_rules", propertyId],
      });
    },
  });
}
