import getThreads from "utils/merge_two_arrays";

import { SCORES_ADD } from "../constants";

const initialState = {
  scores: {
    list: null,
    meta: null,
  },
};

const ACTION_HANDLERS = {
  [SCORES_ADD]: (state, action) => {
    const score = action.payload;
    const scores = state.scores.list || [];

    const updatedScores = getThreads([score], scores);

    return {
      ...state,
      scores: {
        ...state.scores,
        list: updatedScores,
        meta: {
          ...state.scores.meta,
        },
      },
    };
  },
};

export default function scoresReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
