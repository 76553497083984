import convertToHashmap from "utils/convert_to_hashmap";

import {
  APPLICATIONS_ADD_INSTALLED,
  APPLICATIONS_LOAD_INSTALLED,
  APPLICATIONS_REMOVE_INSTALLED,
} from "../constants";

const initialState = {
  installed: null,
};

const ACTION_HANDLERS = {
  [APPLICATIONS_LOAD_INSTALLED]: (state, action) => {
    return {
      ...state,
      installed: convertToHashmap(action.payload),
    };
  },
  [APPLICATIONS_ADD_INSTALLED]: (state, action) => {
    const installedApp = action.payload;

    const installed = {
      ...state.installed,
      [installedApp.id]: installedApp,
    };

    return { ...state, installed };
  },
  [APPLICATIONS_REMOVE_INSTALLED]: (state, action) => {
    const removedAppId = action.payload;
    const { [removedAppId]: _removedApp, ...installed } = state.installed;

    return { ...state, installed };
  },
};

export default function applicationsReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
