import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import store from "store";

import CleaningPolicyForm from "components/hotel_policy/forms/cleaning_policy_form";
import GeneralPolicyForm from "components/hotel_policy/forms/general_policy_form";
import InternetAccessForm from "components/hotel_policy/forms/internet_access_form";
import ParkingPolicyForm from "components/hotel_policy/forms/parking_policy_form";
import PetsPolicyForm from "components/hotel_policy/forms/pets_policy_form";
import SmokingPolicyForm from "components/hotel_policy/forms/smoking_policy_form";
import Wizard from "components/wizard";

import withComponentRef from "containers/with_component_ref";

import confirmDirtyStateSave from "utils/confirm_dirty_state_save";
import parseApiErrors from "utils/parse_api_errors";

import formInDrawerStyles from "styles/form_in_drawer.module.css";

const { HotelPolicies } = store;

class HotelPolicyWizard extends Component {
  state = {
    formValue: {},
    errors: {},
    submitting: false,
  };

  wizardRef = React.createRef();

  componentDidMount() {
    const { currency } = this.props;

    this.setState({ formValue: { currency } });
  }

  handleFinish = (value) => {
    const { propertyId, onClose, onCreate } = this.props;

    this.setState({ submitting: true });

    HotelPolicies.create({ ...value, property_id: propertyId })
      .then((response) => {
        onCreate(response);
        onClose();
      })
      .catch((error) => {
        if (!error.isValidationError) {
          throw error;
        }

        this.setState({ errors: parseApiErrors(error) });
      })
      .finally(() => {
        this.setState({ submitting: false });
      });
  };

  beforeClose = () => {
    const { getIsWizardDirty } = this.wizardRef.current;

    const isDirty = getIsWizardDirty();

    return confirmDirtyStateSave(isDirty);
  };

  render() {
    const { t } = this.props;
    const { errors, formValue, submitting } = this.state;

    return (
      <div>
        <Wizard
          initialStep="generalPolicy"
          initialValues={formValue}
          loading={submitting}
          ref={this.wizardRef}
          errors={errors}
          onFinish={this.handleFinish}
          actionsClassName={formInDrawerStyles.actions}
        >
          <Wizard.StepWithChannexForm
            content={<GeneralPolicyForm />}
            name="generalPolicy"
            title={t("policies:general_policy:title")}
          />
          <Wizard.StepWithChannexForm
            content={<PetsPolicyForm />}
            name="petsPolicy"
            title={t("policies:pets_policy:title")}
          />
          <Wizard.StepWithChannexForm
            content={<SmokingPolicyForm />}
            name="smokingPolicy"
            title={t("policies:smoking_policy:title")}
          />
          <Wizard.StepWithChannexForm
            content={<InternetAccessForm />}
            name="internetAccess"
            title={t("policies:internet_access:title")}
          />
          <Wizard.StepWithChannexForm
            content={<ParkingPolicyForm />}
            name="parkingPolicy"
            title={t("policies:parking_policy:title")}
          />
          <Wizard.StepWithChannexForm
            content={<CleaningPolicyForm />}
            name="cleaningPolicy"
            title={t("policies:cleaning_policy:title")}
          />
        </Wizard>
      </div>
    );
  }
}

export default withTranslation()(withComponentRef(HotelPolicyWizard));
