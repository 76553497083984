import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import Layouts from "components/layouts";
import MappingDirection from "components/mapping/mapping_direction";

import alphabetSort from "utils/alphabet_sort";
import { buildMultiOccupancyRatesList } from "utils/build_multioccupancy_rates_list";

import updatePrimaryOccValues from "./room_mapping/room_rates_mapping/update_primary_occ_values";
import RemovedRates from "./removed_rates";
import RemovedRooms from "./removed_rooms";
import RoomMapping from "./room_mapping";
import { updateReadonlyStatuses } from "./update_readonly_statuses";

const OCCUPANCY_BASED_PRICING = "OBP";
const DEFAULT_MAPPING_SETTINGS = {};
const DEFAULT_MAPPING_OPTIONS = {};
const CHANNEL_WITH_REUSED_ROOMS = ["Traveloka"];

class MultiOccupancyMapping extends Component {
  state = {
    rooms: [],
    mappings: {},
    unmappedRoomsList: [],
    removedRatesTree: {},
    removedRooms: {},
  };

  componentDidMount() {
    this.updateRooms();
    this.updateMappings();
    this.buildUnmappedRoomsList();
    this.buildRemovedRatesList();
    this.buildRemovedRoomsList();
  }

  componentDidUpdate(oldProps) {
    const {
      mappings: oldMappings,
      roomTypes: oldRoomTypes,
      mappingOptions: oldMappingOptions,
      mappingSettings: oldMappingSettings,
    } = oldProps;

    const { mappings, roomTypes, mappingSettings, mappingOptions } = this.props;

    const isMappingSettingsChanged = mappingSettings !== oldMappingSettings;
    const isMappingsChanged = mappings && mappings !== oldMappings;

    if (mappingOptions !== oldMappingOptions) {
      this.updateRooms();
    }

    if (isMappingsChanged || isMappingSettingsChanged) {
      this.updateMappings();
      this.buildRemovedRatesList();
      this.buildRemovedRoomsList();
    }

    if (roomTypes !== oldRoomTypes || isMappingSettingsChanged) {
      this.buildUnmappedRoomsList();
    }
  }

  getMappingOptions = () => {
    return this.props.mappingOptions || DEFAULT_MAPPING_OPTIONS;
  };

  getMappingSettings = () => {
    return this.props.mappingSettings || DEFAULT_MAPPING_SETTINGS;
  };

  updateRooms = () => {
    const rooms = this.getSortedRooms();

    this.setState({ rooms });
  };

  getSortedRooms = () => {
    const { rooms } = this.getMappingOptions();

    let sortedRooms = Array.isArray(rooms) ? rooms.sort(alphabetSort("title")) : [];

    sortedRooms = sortedRooms.map((room) => {
      const { rates } = room;

      const sortedRates = Array.isArray(rates) ? rates.sort(alphabetSort("title")) : [];

      return { ...room, rates: sortedRates };
    });

    return sortedRooms;
  };

  buildUnmappedRoomsList = () => {
    const { channelCode, roomTypes } = this.props;
    const { rooms } = this.getMappingSettings();
    const mappedRoomsEntities = Object.values(rooms || {});

    if (!Array.isArray(roomTypes)) {
      return;
    }

    const unmappedRoomsList = CHANNEL_WITH_REUSED_ROOMS.includes(channelCode)
      ? roomTypes
      : roomTypes.filter((roomType) => {
        return !mappedRoomsEntities.find((roomId) => {
          return roomId === roomType.id;
        });
      });

    this.setState({ unmappedRoomsList });
  };

  buildRemovedRoomsList = () => {
    const mappingSettings = this.getMappingSettings();
    const mappingOptions = this.getMappingOptions();
    const mappedRooms = mappingSettings.rooms || {};
    const { rooms = [] } = mappingOptions;

    const mappedRoomsIds = Object.keys(mappedRooms || {});

    const removedRooms = mappedRoomsIds
      .filter((roomId) => !rooms.some((room) => String(room.id) === roomId))
      .reduce((acc, roomId) => ({ ...acc, [roomId]: mappedRooms[roomId] }), {});

    this.setState({ removedRooms });
  };

  buildRemovedRatesList = () => {
    const { mappings, ratePlans } = this.props;
    const { rooms = [] } = this.getMappingOptions();

    if (!Array.isArray(rooms)) {
      return;
    }

    const removedRatesTree = Object.keys(mappings || {})
      .reduce((acc, rateId) => {
        const externalRate = mappings[rateId];

        if (Array.isArray(externalRate)) {
          externalRate.filter(Boolean).forEach((rate) => {
            acc.push({ id: rateId, ...rate });
          });

          if (!externalRate) {
            return acc;
          }

          return acc;
        }

        acc.push({ ...externalRate, id: rateId });
        return acc;
      }, [])
      .filter((externalRate) => {
        const { rate_plan_code, room_type_code, occupancy } = externalRate;
        const mappedRoom = rooms.find((room) => room.id === room_type_code);

        if (!mappedRoom) {
          return true;
        }

        const isMappedRateExist = mappedRoom.rates.some((rate) => {
          const isIdMatches = rate.id === rate_plan_code;
          const hasMultiOccupancies = Array.isArray(rate.occupancies) && rate.occupancies.length;

          const isSingleOccupancyRate = rate.price_1 && occupancy === 1;
          const isMappedRateOccupancyMatches = rate.max_persons === occupancy;

          const isOccupancyMatches = hasMultiOccupancies
            ? rate.occupancies.includes(occupancy)
            : isSingleOccupancyRate || isMappedRateOccupancyMatches;

          return isIdMatches && isOccupancyMatches;
        });

        return !isMappedRateExist;
      })
      .reduce((acc, externalRate) => {
        const channexRate = ratePlans.find((ratePlan) => ratePlan.id === externalRate.id);

        const { room_type_code } = externalRate;
        const { [room_type_code]: removedRoom = [] } = acc;

        const updatedRates = [...removedRoom, { ...externalRate, channexRate }];

        return { ...acc, [room_type_code]: updatedRates };
      }, {});

    this.setState({ removedRatesTree });
  };

  /*
    Takes mapping and prepares it for further usage:
    1) Ensure that mapping value for rate plain is an Array
    2) Set readonly prop from external rate to mapping
  */
  updateMappings = () => {
    const { mappings, t } = this.props;
    const rooms = this.getSortedRooms();

    const mappingsWithArrayValue = Object.keys(mappings || {}).reduce((acc, ratePlanId) => {
      const mapping = mappings[ratePlanId];

      if (!Array.isArray(mapping)) {
        acc[ratePlanId] = [mapping];

        return acc;
      }

      acc[ratePlanId] = mapping;

      return acc;
    }, {});

    const externalRatesPerRoomId = rooms.reduce((acc, room) => {
      const ratesList = buildMultiOccupancyRatesList(room.rates, t);

      return { ...acc, [room.id]: ratesList };
    }, {});

    const mappingsWithReadonly = updateReadonlyStatuses(
      mappingsWithArrayValue,
      externalRatesPerRoomId,
    );

    this.setState({ mappings: mappingsWithReadonly });
  };

  onChangeMapping = () => {
    return (value) => {
      const { onChangeMapping } = this.props;

      onChangeMapping(updatePrimaryOccValues(value));
    };
  };

  render() {
    const {
      roomTypes,
      ratePlans,
      channelTitle,
      onChangeSettings,
      onRefresh,
    } = this.props;
    const { mappings, unmappedRoomsList, rooms, removedRatesTree, removedRooms } = this.state;
    const mappingOptions = this.getMappingOptions();
    const mappingSettings = this.getMappingSettings();
    const onChangeMapping = this.onChangeMapping();

    const isOccupancyBased = mappingOptions.pricing_type === OCCUPANCY_BASED_PRICING;

    return (
      <Layouts.WithRefresh onRefresh={onRefresh}>
        <MappingDirection channelTitle={channelTitle} reverse />
        {rooms.map((room) => {
          return (
            <RoomMapping
              key={room.id}
              mappings={mappings}
              room={room}
              roomTypes={roomTypes}
              unmappedRooms={unmappedRoomsList}
              ratePlans={ratePlans}
              isOccupancyBased={isOccupancyBased}
              mappingSettings={mappingSettings}
              onChangeMapping={onChangeMapping}
              onChangeSettings={onChangeSettings}
            />
          );
        })}
        <RemovedRooms
          removedRooms={removedRooms}
          mappingSettings={mappingSettings}
          roomTypes={roomTypes}
          onChangeSettings={onChangeSettings}
        />
        <RemovedRates
          ratesTree={removedRatesTree}
          mappings={mappings}
          onChangeMapping={onChangeMapping}
        />
      </Layouts.WithRefresh>
    );
  }
}

const MappingComponent = withTranslation()(MultiOccupancyMapping);
MappingComponent.MAPPING_TYPE = "ExternalRoomRate";

export default MappingComponent;
