import React from "react";
import { useTranslation } from "react-i18next";

import ChannexDrawerRoutable from "../channex_drawer_routable";

import { AvailabilityRulesTable } from "./availability_rules_table";

export function AvailabilityRulesDrawer() {
  const { t } = useTranslation();
  const title = t("availability_rules:titles:availability_rules");

  return (
    <ChannexDrawerRoutable title={title}>
      <AvailabilityRulesTable />
    </ChannexDrawerRoutable>
  );
}
