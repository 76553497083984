import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import ChannexDrawer from "drawers/channex_drawer";

import DrawerFormWrapper from "components/drawer_form_wrapper/drawer_form_wrapper";

import TaskViewDetails from "../task_view/components/task_view_details";

class TaskDrawer extends Component {
  render() {
    const { t, taskId, visible, onClose } = this.props;

    return (
      <ChannexDrawer visible={visible} title={t("tasks_page:view_dialog_header")} onClose={onClose}>
        {({ handleCloseFromContent }) => (
          <DrawerFormWrapper>
            <TaskViewDetails taskId={taskId} onClose={handleCloseFromContent} />
          </DrawerFormWrapper>
        )}
      </ChannexDrawer>
    );
  }
}

export default withTranslation()(TaskDrawer);
