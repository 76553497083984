import React from "react";

import FormInput from "components/forms/inputs/formik/form_input";

const MIN_NUMBER = 0;

export default function ({ t, schema, namespace, field, inputRef, handleFieldChange }) {
  return (
    <FormInput
      namespace={namespace}
      name={field}
      precision="2"
      min={MIN_NUMBER}
      view="horizontal"
      placeholder={t(`channels_page:form:settings_${field}_placeholder`)}
      label={t(`channels_page:form:settings_${field}_label`)}
      type={schema.type}
      inputRef={inputRef}
      onChange={handleFieldChange}
    />
  );
}
