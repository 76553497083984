/* eslint-disable import/prefer-default-export */

const getUnusedMappingByKey = (key, mappingOptions, mappings) => {
  if (!mappingOptions[`${key}_dictionary`]) {
    return [];
  }

  const { values } = mappingOptions[`${key}_dictionary`];

  const usedOptions = Object.values(mappings)
    .filter((mapping) => mapping !== null)
    .map((mapping) => `${mapping[key]}`);

  return values
    .filter((option) => !usedOptions.includes(`${option.id}`))
    .map((option) => option.id);
};

const getUnusedMappings = (mappings, mappingOptions, mappingSchema) => {
  const unusedMappings = {};

  Object.keys(mappingSchema).forEach((key) => {
    const unused = getUnusedMappingByKey(key, mappingOptions, mappings);

    if (unused.length > 0) {
      unusedMappings[key] = unused;
    }
  });

  return unusedMappings;
};

export { getUnusedMappings };
