import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";

import ChannexModal from "components/channex_modal";

export default function ShowCardIFrameModal({ visible, onClose, url }) {
  const { t } = useTranslation();

  return (
    <ChannexModal
      title={t("applications_page:channex_pci:iframe_title")}
      visible={visible}
      onCancel={onClose}
      footer={[<Button key="back" onClick={onClose}>{t("general:action:close")}</Button>]}
    >
      <iframe title="show_card_iframe" src={url} height="300" width="472" frameBorder="0" scrolling="no" />
    </ChannexModal>
  );
}
