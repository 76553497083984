const prettifyJson = (data) => {
  let prettifiedData = data;

  if (typeof data === "string") {
    prettifiedData = JSON.parse(data);
  }

  return JSON.stringify(prettifiedData, true, 2);
};

export default prettifyJson;
