import React from "react";

import { EventDescription } from "./event_description";
import { EventIpAddress } from "./event_ip_address";
import { EventTitle } from "./event_title";
import { EventUser } from "./event_user";

export function BookingResent({ event }) {
  return (
    <>
      <EventTitle event={event} />
      <EventDescription>
        <EventIpAddress value={event.ip_address} />
        <EventUser username={event.user_name} email={event.user_email} />
      </EventDescription>
    </>
  );
}
