import React, { Component } from "react";
import queryString from "query-string";
import store from "store";

import withRouter from "routing/with_router";

const { Auth } = store;

const withLogic = (FormComponent) => {
  class HOC extends Component {
    constructor(props) {
      super(props);

      this.state = {
        loading: true,
        isSuccess: false,
      };
    }

    componentDidMount() {
      const query = queryString.parse(window.location.search);

      if (query && query.token) {
        Auth.confirmRegistration(query.token).then(
          () => {
            this.setState({
              loading: false,
              isSuccess: true,
            });
          },
          () => {
            this.setState({
              loading: false,
              isSuccess: false,
            });
          },
        );
      } else {
        this.setState({
          loading: false,
          isSuccess: false,
        });
      }
    }

    render() {
      const { loading, isSuccess } = this.state;
      return <FormComponent {...this.props} loading={loading} isSuccess={isSuccess} />;
    }
  }

  return withRouter(HOC);
};

export default withLogic;
