import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Form } from "antd";
import compose from "recompose/compose";
import * as yup from "yup";

import currencyOptions from "config/constants/currency_options";
import errorMessages from "config/constants/errors";

import ChannexForm from "components/channex_form";
import FormikFormInput from "components/forms/inputs/formik/form_input";
import FormikFormInputNumber from "components/forms/inputs/formik/form_input_number";
import FormikFormSelect from "components/forms/inputs/formik/form_select";
import RadioInput from "components/forms/inputs/formik/radio";

class GeneralPolicyForm extends Component {
  CHECKIN_RANGE_START_HOUR = 0;
  CHECKIN_RANGE_END_HOUR = 23.5;
  CHECKIN_RANGE_STEP_MINUTES = 30;

  // logic assumes step is a multiple of hour
  MAX_MIN_CHILD_AGE = 16;

  VALIDATION_SCHEMA = yup.object().shape({
    title: yup.string().trim().required(errorMessages.required()),
    currency: yup.string().required(errorMessages.required()),
    checkin_from_time: yup.string().required(errorMessages.required()),
    checkin_to_time: yup.string().required(errorMessages.required()),
    checkout_from_time: yup.string().required(errorMessages.required()),
    checkout_to_time: yup.string().required(errorMessages.required()),
    self_checkin_checkout: yup.bool(),
    max_count_of_guests: yup
      .number()
      .moreThan(-1, errorMessages.positive())
      .integer(errorMessages.integer())
      .required(errorMessages.required())
      .typeError(errorMessages.number()),
    is_adults_only: yup.bool(),
    infant_max_age: yup
      .number()
      .moreThan(-1, errorMessages.positive())
      .integer(errorMessages.integer())
      .typeError(errorMessages.number()),
    children_max_age: yup
      .number()
      .positive(errorMessages.positive())
      .integer(errorMessages.integer())
      .typeError(errorMessages.number()),
  });

  DEFAULT_VALUE = {
    is_adults_only: false,
    self_checkin_checkout: false,
  };

  static FIELDS = [
    "is_adults_only",
    "checkin_from_time",
    "checkin_to_time",
    "checkout_from_time",
    "checkout_to_time",
    "self_checkin_checkout",
    "max_count_of_guests",
    "title",
    "currency",
    "infant_max_age",
    "children_max_age",
  ];

  inputRefs = {
    title: React.createRef(),
    currency: React.createRef(),
    checkin_from_time: React.createRef(),
    checkin_to_time: React.createRef(),
    checkout_from_time: React.createRef(),
    checkout_to_time: React.createRef(),
    self_checkin_checkout: React.createRef(),
    max_count_of_guests: React.createRef(),
    is_adults_only: React.createRef(),
    infant_max_age: React.createRef(),
    children_max_age: React.createRef(),
  };

  componentDidMount() {
    const { focusField } = this.props;

    if (focusField) {
      this.inputRefs?.[focusField]?.current?.focus();
    }
  }

  generateTimeIntervals = () => {
    const intervals = ((this.CHECKIN_RANGE_END_HOUR - this.CHECKIN_RANGE_START_HOUR) * 60)
        / this.CHECKIN_RANGE_STEP_MINUTES
      + 1;

    const options = Array(intervals)
      .fill()
      .map((_, i) => {
        const hour = this.CHECKIN_RANGE_START_HOUR + Math.floor((this.CHECKIN_RANGE_STEP_MINUTES * i) / 60);
        const minute = (this.CHECKIN_RANGE_STEP_MINUTES * i) % 60;
        const paddedHour = hour < 10 ? `0${hour}` : `${hour}`;
        const paddedMinute = minute === 0 ? "00" : `${minute}`;
        const time = `${paddedHour}:${paddedMinute}`;

        return {
          value: time,
          representation: time,
        };
      });

    return options;
  };

  getCheckoutOptions = (checkout) => {
    const timeIntervals = this.generateTimeIntervals();

    if (!checkout) {
      return timeIntervals;
    }

    const index = timeIntervals.findIndex((time) => time.value === checkout);

    if (index === -1) {
      return timeIntervals;
    }

    return timeIntervals.splice(index);
  };

  getCheckinOptions = (checkin) => {
    const timeIntervals = this.generateTimeIntervals();

    if (!checkin) {
      return timeIntervals;
    }

    const index = timeIntervals.findIndex((time) => time.value === checkin);

    if (index === -1) {
      return timeIntervals;
    }

    return timeIntervals.splice(0, index + 1);
  };

  getYesNoOptions = () => {
    const { t } = this.props;

    return [
      {
        value: false,
        representation: t("general:no"),
      },
      {
        value: true,
        representation: t("general:yes"),
      },
    ];
  };

  getAllowChildrenOptions = () => {
    return this.getYesNoOptions();
  };

  getSelfCheckinOptions = () => {
    return this.getYesNoOptions();
  };

  render() {
    const { t, onChange, value, componentRef, errors } = this.props;

    return (
      <ChannexForm
        defaultValue={this.DEFAULT_VALUE}
        value={value}
        errors={errors}
        componentRef={componentRef}
        validationSchema={this.VALIDATION_SCHEMA}
        fields={GeneralPolicyForm.FIELDS}
        onChange={onChange}
      >
        {({ values, handleSubmit }) => (
          <Form onFinish={handleSubmit}>
            <FormikFormInput
              name="title"
              inputRef={this.inputRefs.title}
              view="horizontal"
              placeholder={t("policies:general_policy:property_title")}
              label={t("policies:general_policy:property_title")}
            />
            <FormikFormSelect
              name="currency"
              inputRef={this.inputRefs.currency}
              view="horizontal"
              placeholder={t("policies:general_policy:currency")}
              label={t("policies:general_policy:currency")}
              options={currencyOptions()}
            />
            <FormikFormSelect
              name="checkin_from_time"
              inputRef={this.inputRefs.checkin_from_time}
              view="horizontal"
              placeholder={t("policies:general_policy:check_in_from")}
              label={t("policies:general_policy:check_in_from")}
              options={this.getCheckinOptions(values.checkin_to_time)}
            />
            <FormikFormSelect
              name="checkin_to_time"
              inputRef={this.inputRefs.checkin_to_time}
              view="horizontal"
              placeholder={t("policies:general_policy:check_in_to")}
              label={t("policies:general_policy:check_in_to")}
              options={this.getCheckoutOptions(values.checkin_from_time)}
            />
            <FormikFormSelect
              name="checkout_from_time"
              inputRef={this.inputRefs.checkout_from_time}
              view="horizontal"
              placeholder={t("policies:general_policy:check_out_from")}
              label={t("policies:general_policy:check_out_from")}
              options={this.getCheckinOptions(values.checkout_to_time)}
            />
            <FormikFormSelect
              name="checkout_to_time"
              inputRef={this.inputRefs.checkout_to_time}
              view="horizontal"
              placeholder={t("policies:general_policy:check_out_to")}
              label={t("policies:general_policy:check_out_to")}
              options={this.getCheckoutOptions(values.checkout_from_time)}
            />
            <RadioInput
              name="self_checkin_checkout"
              inputRef={this.inputRefs.self_checkin_checkout}
              view="horizontal"
              label={t("policies:general_policy:self_checkin_checkout")}
              options={this.getSelfCheckinOptions()}
            />
            <FormikFormInputNumber
              name="max_count_of_guests"
              inputRef={this.inputRefs.max_count_of_guests}
              min="1"
              precision="0"
              view="horizontal"
              placeholder={t("policies:general_policy:max_guests")}
              label={t("policies:general_policy:max_guests")}
            />
            <RadioInput
              name="is_adults_only"
              inputRef={this.inputRefs.is_adults_only}
              view="horizontal"
              label={t("policies:general_policy:adults_only")}
              options={this.getAllowChildrenOptions()}
            />
            {(value.is_adults_only === false || typeof value.is_adults_only === "undefined") && (
              <>
                <FormikFormInputNumber
                  name="infant_max_age"
                  inputRef={this.inputRefs.infant_max_age}
                  max="10"
                  precision="0"
                  view="horizontal"
                  placeholder={t("policies:general_policy:infant_max_age")}
                  label={t("policies:general_policy:infant_max_age")}
                />
                <FormikFormInputNumber
                  name="children_max_age"
                  inputRef={this.inputRefs.children_max_age}
                  max="21"
                  precision="0"
                  view="horizontal"
                  placeholder={t("policies:general_policy:children_max_age")}
                  label={t("policies:general_policy:children_max_age")}
                />
              </>
            )}
          </Form>
        )}
      </ChannexForm>
    );
  }
}

const enhance = compose(withTranslation());

export default enhance(GeneralPolicyForm);
