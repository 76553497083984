import React from "react";
import { useTranslation } from "react-i18next";

export default function PrimaryToggleTooltipContent({ rate, isReadonly }) {
  const { primary_occ } = rate;
  const { t } = useTranslation();

  let message = t("channels_page:form:booking_com_tooltip:read_only_rate");

  if (!isReadonly) {
    message = primary_occ
      ? t("channels_page:form:booking_com_tooltip:primary_rate")
      : t("channels_page:form:booking_com_tooltip:secondary_rate");
  }

  return <div>{message}</div>;
}
