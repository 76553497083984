import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tag } from "antd";
import store from "store";

import ActionBar from "components/event/action_bar";

import { bookingDate } from "utils/dates";
import showErrorMessageFromResponse from "utils/show_error_message_from_response";

import AlterationRequestActionButtons from "./alteration_request_action_buttons";

import viewStyles from "styles/view_list.module.css";

const { Bookings, LiveFeedEvents } = store;

export default function AlterationRequestActions({ eventData, onClose }) {
  const [booking, setBooking] = useState({ loading: true });
  const { t } = useTranslation();

  useEffect(
    function loadBooking() {
      const booking_id = eventData.payload.bms
        ? eventData.payload.bms.booking_id
        : eventData.payload.booking_id;

      Bookings.find(booking_id).then((bookingAnswer) => {
        setBooking(bookingAnswer);
      });
    },
    [eventData.payload],
  );

  const handleResolve = (resolution) => {
    return LiveFeedEvents.resolve(eventData.id, resolution)
      .then(onClose)
      .catch(showErrorMessageFromResponse);
  };

  const actions = eventData.payload.resolved ? (
    <div style={{ textAlign: "right" }}>
      <Tag color="success">{t("live_feed_event_drawer:resolved")}</Tag>
      {eventData.payload.comment ? (
        <>
          <br />
          {eventData.payload.comment}
        </>
      ) : null}
    </div>
  ) : (
    <AlterationRequestActionButtons onResolve={handleResolve} />
  );

  if (booking.loading) {
    return null;
  }

  const payload = eventData.payload.bms
    ? eventData.payload.bms.raw_message.reservation
    : eventData.payload;

  const content = (
    <dl className={viewStyles.list}>
      <dt className={viewStyles.list__term}>{t("alteration_request:initiated_by")}:</dt>
      <dd className={viewStyles.list__description_small}>{payload.initiated_by}</dd>
      <dt className={viewStyles.list__term}>{t("alteration_request:occupancy")}:</dt>
      <dd className={viewStyles.list__description_small}>
        <div className={viewStyles.list__new}>
          A: {payload.guest_details.number_of_adults} C: {payload.guest_details.number_of_children}{" "}
          I: {payload.guest_details.number_of_infants}
        </div>
        <div className={viewStyles.list__old}>
          A: {booking.occupancy.adults} C: {booking.occupancy.children} I:{" "}
          {booking.occupancy.infants}
        </div>
      </dd>
      <dt className={viewStyles.list__term}>{t("alteration_request:checkin_date")}:</dt>
      <dd className={viewStyles.list__description_small}>
        <div className={viewStyles.list__new}>
          {bookingDate(payload.start_date, t("formats:date_time_with_weekday"))}
        </div>
        <div className={viewStyles.list__old}>
          {bookingDate(booking.arrival_date, t("formats:date_time_with_weekday"))}
        </div>
      </dd>
      <dt className={viewStyles.list__term}>{t("alteration_request:checkout_date")}:</dt>
      <dd className={viewStyles.list__description_small}>
        <div className={viewStyles.list__new}>
          {bookingDate(payload.end_date, t("formats:date_time_with_weekday"))}
        </div>
        <div className={viewStyles.list__old}>
          {bookingDate(booking.departure_date, t("formats:date_time_with_weekday"))}
        </div>
      </dd>
      <dt className={viewStyles.list__term}>{t("alteration_request:amount")}:</dt>
      <dd className={viewStyles.list__description_small}>
        <div className={viewStyles.list__new}>{payload.expected_payout_amount_accurate}</div>
        <div className={viewStyles.list__old}>{booking.amount}</div>
      </dd>
    </dl>
  );

  return (
    <ActionBar
      title={t(`live_feed_events_widget:events:${eventData.event}`)}
      actions={actions}
      content={content}
    />
  );
}
