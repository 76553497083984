import React from "react";

import SUPPORTED_LANGUAGES from "config/constants/supported_languages";

import SubmitButton from "components/forms/buttons/submit_button";
import GlobalErrors from "components/forms/global_errors";
import FormFileBase64Input from "components/forms/inputs/hook_form/form_file_base64_input";
import FormInput from "components/forms/inputs/hook_form/form_input";
import FormSelect from "components/forms/inputs/hook_form/form_select";
import { TextValue } from "components/forms/inputs/text_value/text_value";
import BottomActions from "components/forms/layout/drawer/bottom_actions/bottom_actions";
import useAppForm from "components/hook_form/use_app_form";

import { validationSchema } from "./validation_schema";

export function WhiteLabelDomainForm({ values, isLoading, onSubmit }) {
  const { handleSubmit, errors, control } = useAppForm({
    defaultValue: values,
    validationSchema,
    fieldNames: ["title", "domain", "favicon", "logoBig", "logoSmall", "locale"],
    submitHandler: onSubmit,
  });

  const isEdit = !!values?.id;

  return (
    <div>
      <div>
        <GlobalErrors hookForm errors={errors} />

        {isEdit && (
          <TextValue
            label="Id"
            value={values.id}
            copy
          />
        )}
        <FormInput
          name="title"
          label="Title"
          errors={errors?.title?.message}
          control={control}
        />
        <FormInput
          disabled={isEdit}
          name="domain"
          label="Domain"
          errors={errors?.domain?.message}
          control={control}
        />
        <FormFileBase64Input
          name="favicon"
          label="Favicon"
          accept="image/vnd.microsoft.icon"
          errors={errors?.favicon?.message}
          control={control}
        />
        <FormFileBase64Input
          name="logoBig"
          label="Logo Big"
          accept="image/*"
          errors={errors?.logoBig?.message}
          control={control}
        />
        <FormFileBase64Input
          name="logoSmall"
          label="Logo Small"
          accept="image/*"
          errors={errors?.logoSmall?.message}
          control={control}
        />
        <FormSelect
          name="locale"
          label="Locale"
          allowClear
          control={control}
          errors={errors?.locale?.message}
          options={SUPPORTED_LANGUAGES.map((lang) => ({ value: lang.key, representation: lang.value }))}
        />
      </div>
      {isEdit && (
        <div>
          <legend>Settings</legend>
          <pre>
            {JSON.stringify(values.settings, null, 2)}
          </pre>
        </div>
      )}

      <BottomActions>
        <SubmitButton onClick={() => { handleSubmit(); }} loading={isLoading}>
          Save
        </SubmitButton>
      </BottomActions>
    </div>
  );
}
