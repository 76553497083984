import React from "react";

import WidgetLoader from "../../common/widget_loader";

import styles from "./events_list.module.css";

function EventsListLoader() {
  return (
    <div className={styles.innerLoader}>
      <WidgetLoader />
    </div>
  );
}

export default EventsListLoader;
