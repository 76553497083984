import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { FilterOutlined, SearchOutlined } from "@ant-design/icons";
import { Badge, Button, Input } from "antd";
import classNames from "classnames";

import styles from "./crud_top_bar.module.css";

export default function CRUDTopBar({
  borderless,
  actions,
  searchQuery,
  onSearchChange,
  advancedSearch,
  advancedSearchApply,
  advancedSearchFilter,
  search = true,
}) {
  const [advancedSearchIsVisible, setAdvancedSearchIsVisible] = useState(false);
  const { t } = useTranslation();

  const containerClassName = classNames(styles.contentSearch, {
    [styles.contentSearchBorderless]: borderless,
  });

  const handleSearchChange = useCallback(
    (event) => {
      const formattedQuery = event.target.value || "";

      onSearchChange(formattedQuery);
    },
    [onSearchChange],
  );

  const toggleAdvancedSearch = (_event) => {
    setAdvancedSearchIsVisible(!advancedSearchIsVisible);
  };

  const apply = (filter) => {
    toggleAdvancedSearch();
    advancedSearchApply(filter);
  };

  const filterIsNotEmpty = (filter) => {
    const globalApplicableFilters = ["property_id", "group_id"];

    return (
      Object.keys(filter).filter((key) => globalApplicableFilters.indexOf(key) === -1).length > 0
    );
  };

  const AdvancedSearch = advancedSearch;

  return (
    <div className={containerClassName}>
      <div data-cy="crud_table_search_field" className={styles.contentSearchField}>
        {search && (
          <Input
            type="text"
            prefix={<SearchOutlined className={styles.contentSearchIcon} />}
            addonAfter={
              advancedSearch && (
                <Button onClick={toggleAdvancedSearch}>
                  <Badge dot={filterIsNotEmpty(advancedSearchFilter)}>
                    <FilterOutlined />
                    &nbsp;{t("general:crud_table:advanced_search:button")}
                  </Badge>
                </Button>
              )
            }
            placeholder={t("general:search_field_placeholder")}
            className={styles.contentSearchField}
            value={searchQuery}
            onChange={handleSearchChange}
          />
        )}
        {AdvancedSearch && advancedSearchIsVisible && (
          <AdvancedSearch
            advancedSearchApply={apply}
            advancedSearchFilter={advancedSearchFilter}
            toggleVisibility={toggleAdvancedSearch}
          />
        )}
      </div>
      {actions && <div className={styles.contentSearchActions}>{actions}</div>}
    </div>
  );
}
