import React from "react";

import styles from "./mapping_section.module.css";

export default function MappingSection({ title, children }) {
  return (
    <div>
      <div className={styles.header}>{title}</div>
      {children}
    </div>
  );
}
