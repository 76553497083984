import { createSelector } from "reselect";

export const getReviews = (state) => Object.values(state.reviews.entities).sort((a, b) => {
  if (a.attributes.receivedAt < b.attributes.receivedAt) {
    return 1;
  }
  if (a.attributes.receivedAt > b.attributes.receivedAt) {
    return -1;
  }
  return 0;
});
export const getReviewsMeta = (state) => state.reviews.meta;
export const getReviewById = (reviewId) => createSelector([getReviews], (reviews) => {
  return reviews?.find(({ id }) => reviewId === id);
});
