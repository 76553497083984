import { TAXES_ADD, TAXES_DROP, TAXES_LOAD } from "../constants";

export function taxesLoad(storage) {
  return function (records, meta) {
    storage.dispatch({ type: TAXES_LOAD, payload: { records, meta } });
  };
}

export function taxesAdd(storage) {
  return function (payload) {
    storage.dispatch({ type: TAXES_ADD, payload });
  };
}

export function taxesDrop(storage) {
  return function (payload) {
    storage.dispatch({ type: TAXES_DROP, payload });
  };
}
