const MEAL_TYPES = {
  none: 0,
  all_inclusive: 1,
  breakfast: 2,
  lunch: 3,
  dinner: 4,
  american: 5,
  bed_and_breakfast: 6,
  buffet_breakfast: 7,
  carribean_breakfast: 8,
  continental_breakfast: 9,
  english_breakfast: 10,
  european_plan: 11,
  family_plan: 12,
  full_board: 13,
  full_breakfast: 14,
  half_board: 15,
  room_only: 16,
  self_catering: 17,
  bermuda: 18,
  dinner_bed_and_breakfast_plan: 19,
  family_american: 20,
  breakfast_and_lunch: 21,
  lunch_and_dinner: 22,
};

export default MEAL_TYPES;
