import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import Placeholder from "components/no_data_placeholder";
import * as Page from "components/page";

import { pathBuilder } from "routing";
import useRouting from "routing/use_routing";

import styles from "./no_app_placeholder.module.css";

export default function NoAppPlaceholder() {
  const { t } = useTranslation();
  const { userAppRoutes } = useRouting();
  const redirectPath = pathBuilder(userAppRoutes.applications);

  return (
    <Page.Main>
      <Placeholder
        className={styles.placeholder}
        emptyMessage={
          <div>
            {t("messages_page:have_the_app")}
            <NavLink className={styles.link} to={redirectPath}>
              {t("messages_page:here")}
            </NavLink>
            {t("messages_page:to_add_chat")}
          </div>
        }
      />
    </Page.Main>
  );
}
