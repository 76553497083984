import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Space } from "antd";

import Flexbox from "components/flexbox";

export default function StartingState({ onCloseClick }) {
  const { t } = useTranslation();

  return (
    <div>
      <p>{t("bookings_export:starting_message")}</p>
      <Flexbox justifyContent="end">
        <Space>
          <Button onClick={onCloseClick}>{t("general:action:close")}</Button>
          <Button type="primary" loading>{t("bookings_export:action_state:starting")}</Button>
        </Space>
      </Flexbox>
    </div>
  );
}
