import React, { useEffect, useState } from "react";

import ChannelManagementDrawerControlled from "drawers/channel_management_drawer_controlled";

import EventEmitter from "utils/event_emitter";

const MISSING_CONTENT_TAB = "5";

export default function ChannelReadiness() {
  const [channelId, setChannelId] = useState(null);

  const handleDrawerClose = () => setChannelId(null);

  useEffect(function setEventListeners() {
    EventEmitter.bind("channel_management:show_missing_content", setChannelId);

    return () => EventEmitter.unbind("channel_management:show_missing_content", setChannelId);
  }, []);

  return (
    <ChannelManagementDrawerControlled
      visible={Boolean(channelId)}
      channelId={channelId}
      defaultTab={MISSING_CONTENT_TAB}
      onClose={handleDrawerClose}
    />
  );
}
