import React, { useMemo } from "react";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Switch } from "antd";

function InventoryValueSwitchController({ onChangeBoolValue, value }) {
  const selectedRateValues = useMemo(() => value.some(Boolean), [value]);

  return (
    <Switch
      data-cy="modal_switch"
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
      checked={selectedRateValues}
      onChange={onChangeBoolValue}
    />
  );
}

export default InventoryValueSwitchController;
