import React from "react";
import { useTranslation } from "react-i18next";
import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown } from "antd";

import Issue from "./issue";

export default function IssueDropdown({ issues, onIssueClick }) {
  const { t } = useTranslation();

  const items = issues.map((issue) => ({
    key: issue.id,
    onClick: () => onIssueClick(issue.id),
    label: <Issue issue={issue} key={issue.id} />,
  }));

  return (
    <Dropdown menu={{ items }} trigger={["click"]}>
      <Button type="link">
        {t("bookings_page:booking_view_dialog:info:issue_resolve")}
        <DownOutlined />
      </Button>
    </Dropdown>
  );
}
