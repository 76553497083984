import React, { Component } from "react";

import { ActionBar } from "components/issue";

import MappingModal from "./mapping_modal";
import ResolveAction from "./resolve_action";

class UnmappedRoomActionBar extends Component {
  state = {
    isModalOpen: false,
  };

  handleToggleModal = () => this.setState(({ isModalOpen }) => ({ isModalOpen: !isModalOpen }));

  render() {
    const { issue, details, onResolve } = this.props;
    const { isModalOpen } = this.state;
    const resolveAction = <ResolveAction onClick={this.handleToggleModal} />;

    return (
      <>
        <MappingModal
          issue={issue}
          details={details}
          onClose={this.handleToggleModal}
          onResolve={onResolve}
          visible={isModalOpen}
        />

        <ActionBar
          issue={issue}
          resolveAction={resolveAction}
        />
      </>
    );
  }
}

export default UnmappedRoomActionBar;
