import React, { Component } from "react";
import { Form, Input } from "antd";
import classNames from "classnames";
import snakeCase from "lodash/snakeCase";

import AddonAfterWrapper from "./components/addon_after_wrapper";
import getFieldLayout from "./utils/get_field_layout";
import getFieldStyle from "./utils/get_field_style";

import styles from "./form_input_number/form_input_number.module.css";

const FormItem = Form.Item;

const DEFAULT_CALLBACK = () => {};

class FormInput extends Component {
  validation = () => {
    const { errors } = this.props;

    if (errors) {
      const message = Array.isArray(errors) ? errors.join(" ") : errors;

      return {
        validateStatus: "error",
        help: <span data-cy="input_error_message">{message}</span>,
      };
    }

    return {};
  };

  renderIcon = () => {
    const { icon } = this.props;

    if (icon && React.isValidElement(icon)) {
      return React.cloneElement(icon, { style: { color: "rgba(0,0,0,.25)" } });
    }

    return null;
  };

  state = {
    stateValue: null,
  };

  componentDidMount() {
    const { defaultValue } = this.props;

    this.setState({
      stateValue: defaultValue,
    });
  }

  onChange = (event) => {
    const { onChange, hookForm } = this.props;

    if (hookForm) {
      onChange(event);
      return;
    }

    this.setState({
      stateValue: event.target.value,
    });

    if (onChange && typeof onChange === "function") {
      onChange(event.target.value, event.target.name);
    }
  };

  getFieldValue = () => {
    const { value, onChange } = this.props;
    const { stateValue } = this.state;

    const isValueProvided = "value" in this.props;

    return onChange && isValueProvided ? value : value || stateValue;
  };

  // react hook form requires this method to be defined
  focus = () => {};

  render() {
    const {
      type,
      placeholder,
      inputRef,
      label,
      defaultValue,
      addonAfter,
      allowClear,
      extra,
      min,
      max,
      disabled,
      name,
      className,
      view,
      inputClassName,
      onPressEnter = DEFAULT_CALLBACK,
      onBlur = DEFAULT_CALLBACK,
      grouped = false,
      groupMain = false,
    } = this.props;

    const formItemLayout = grouped ? {} : getFieldLayout(view);
    const style = getFieldStyle(this.props);
    const dataCy = name ? snakeCase(`input_${name.replace(".", "_")}`) : null;

    const formItemClassName = classNames(className, {
      [styles.formItemMainInGroup]: groupMain,
      [styles.formItemWithAddon]: addonAfter,
    });

    return (
      <FormItem
        style={style}
        className={formItemClassName}
        {...formItemLayout}
        {...this.validation()}
        label={label}
        extra={extra}
        data-cy={dataCy}
      >
        <Input
          rootClassName={inputClassName}
          allowClear={allowClear}
          type={type}
          prefix={this.renderIcon()}
          ref={inputRef}
          placeholder={placeholder}
          defaultValue={defaultValue}
          value={this.getFieldValue()}
          addonAfter={addonAfter && <AddonAfterWrapper>{addonAfter}</AddonAfterWrapper>}
          min={min}
          max={max}
          disabled={disabled}
          name={name}
          onChange={this.onChange}
          onBlur={onBlur}
          onPressEnter={onPressEnter}
        />
      </FormItem>
    );
  }
}

export default FormInput;
