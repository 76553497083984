const parseBoolean = (value, defaultValue) => {
  if (value === undefined) {
    return defaultValue;
  }

  if (value === "true") {
    return true;
  }

  if (value === "false") {
    return false;
  }

  return !!value;
};

const config = {
  GOOGLE_MAP_API_KEY: import.meta.env.VITE_GOOGLE_MAP_API_KEY,
  UPLOADCARE_PUBLIC_KEY: import.meta.env.VITE_UPLOADCARE_PUBLIC_KEY,
  UPLOADCARE_CDN_BASE: "https://img.channex.io/",
  SENTRY_DNS: import.meta.env.VITE_SENTRY_DNS,
  APP_ENVIRONMENT: import.meta.env.VITE_APP_ENVIRONMENT || "local",
  APP_VERSION: import.meta.env.VITE_APP_VERSION || "0.0.0",
  API_SECURE: parseBoolean(import.meta.env.VITE_API_SECURE, true),
  API_HOST: import.meta.env.VITE_API_HOST || window.location.host,
};

export default config;
