import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { Button, Flex } from "antd";

import { pathBuilder } from "routing";
import useRouting from "routing/use_routing";

import { bookingDate } from "utils/dates";

import styles from "../message.module.css";

export const MessageContent = ({ messageItem, onShowImage }) => {
  const { t } = useTranslation();
  const { userAppRoutes } = useRouting();
  const [showTranslation, setShowTranslation] = useState(false);

  const { attributes } = messageItem;

  const { message, sender: senderType, attachments, attachmentId, meta } = attributes;

  const threadId = messageItem?.relationships?.messageThread?.data?.id;

  const systemMessageContent = useCallback((event) => {
    let result;

    switch (event) {
      case "reservation_requested":
        result = (
          <span>
            Received reservation request{" "}
            <Link
              to={pathBuilder(userAppRoutes.messages.dialog.liveFeedEventView, {
                threadId,
                eventId: meta.liveFeedEventId,
              })}
            >
              {meta.otaReservationCode}
            </Link>
            .
          </span>
        );
        break;

      case "alteration_requested":
        result = (
          <span>
            Received alteration request{" "}
            <Link
              to={pathBuilder(userAppRoutes.messages.dialog.liveFeedEventView, {
                threadId,
                eventId: meta.liveFeedEventId,
              })}
            >
              {meta.otaReservationCode}
            </Link>
            .
          </span>
        );
        break;

      case "close_thread":
        result = <span>Thread was closed by {meta.userName}.</span>;
        break;

      case "open_thread":
        result = <span>Thread was opened by {meta.userName}.</span>;
        break;

      case "inquiry":
        result = (
          <span>
            New inquiry for {meta.bookingDetails.listingName}<br />
            Checkin Date: {bookingDate(meta.bookingDetails.checkinDate, t("formats:date_time_with_weekday"))},
            checkout date: {bookingDate(meta.bookingDetails.checkoutDate, t("formats:date_time_with_weekday"))}<br />
            Expected payout amount: {meta.bookingDetails.expectedPayoutAmountAccurate} {meta.bookingDetails.currency}
          </span>
        );
        break;

      default:
        result = null;
        break;
    }

    return result;
  }, [threadId, userAppRoutes, meta, t]);

  const messageContent = useMemo(() => {
    if (senderType === "system") {
      return (
        <p className={styles.message}>
          <strong>{t(`messages_page:system_messages:${message}`)}</strong>
          <br />
          {systemMessageContent(message)}
        </p>
      );
    }

    if (attachmentId && !attachments?.length) {
      return (
        <div className={styles.message}>
          <div className={`${styles.attachment} ${styles.attachment__loading}`}>
            <LoadingOutlined />
          </div>
        </div>
      );
    }

    if (attachments?.length > 0) {
      return (
        <div className={styles.message}>
          {attachments.map((img) => {
            const onOpenModal = () => onShowImage(img);

            return (
              <div
                key={img}
                onClick={onOpenModal}
                style={{ backgroundImage: `url("${img}")` }}
                className={styles.attachment}
              />
            );
          })}
        </div>
      );
    }

    const isTranslationPresent = meta?.translatedMessage;
    const resultMessage = showTranslation && isTranslationPresent ? meta.translatedMessage : message;

    return (
      <Flex vertical>
        <p className={styles.message}>{resultMessage}</p>
        {isTranslationPresent && (
          <Button
            style={{ alignSelf: "flex-end", height: 20, padding: 2, fontSize: 12 }}
            type="link"
            onClick={() => setShowTranslation(!showTranslation)}
          >
            {showTranslation ? t("messages:actions:show_original") : t("messages:actions:show_translation")}
          </Button>
        )}
      </Flex>
    );
  }, [attachmentId, attachments, message, senderType, systemMessageContent, meta, showTranslation, t, onShowImage]);

  return messageContent;
};
