import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button, Col, Row, Spin } from "antd";

import FormInput from "components/forms/inputs/form_input";
import { Currency } from "components/value_presentation";

import withComponentRef from "containers/with_component_ref";

import styles from "./tax_set_calculator.module.css";

const DEFAULT_PRICE_VALUE = 100;

class TaxSetCalculator extends Component {
  renderResults = () => {
    const { t, results, currency } = this.props;
    const { net_price, total_price, taxes = {} } = results;

    const data = [
      {
        name: t("taxes:tax_set:form:tax_calculator:table:net_price"),
        value: net_price,
      },
      {
        name: t("taxes:tax_set:form:tax_calculator:table:total_price"),
        value: total_price,
      },
    ];

    Object.keys(taxes).forEach((key) => {
      data.push({ name: key, value: taxes[key] });
    });

    return (
      <Row>
        <Col sm={16} xs={24} className={styles.resultContainer}>
          <Row className={styles.resultHeader}>
            <Col span={12}>{t("taxes:tax_set:form:tax_calculator:table:name_header")}</Col>
            <Col span={12}>{t("taxes:tax_set:form:tax_calculator:table:value_header")}</Col>
          </Row>
          {data.map(({ name, value }, index) => (
            <Row className={styles.resultLine} key={index}>
              <Col span={12}>{name}</Col>
              <Col span={12}>
                <Currency value={value} currency={currency} />
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
    );
  };

  render() {
    const {
      t,
      date,
      days,
      currency,
      results,
      loading,
      errors,
      handlePriceChange,
      handleDateChange,
      handleAddDate,
      handleRemoveDate,
    } = this.props;

    return (
      <>
        <legend>{t("taxes:tax_set:form:tax_calculator:legend")}</legend>
        <FormInput
          value={date}
          errors={errors}
          name="date"
          onChange={handleDateChange}
          view="horizontal"
          type="date"
          placeholder={t("taxes:tax_set:form:tax_calculator:date_placeholder")}
          label={t("taxes:tax_set:form:tax_calculator:date_label")}
          defaultValue={date}
        />
        {days.map((day, index) => (
          <div key={index}>
            <FormInput
              value={day}
              errors={errors}
              name={`raw_price_${index}`}
              onChange={handlePriceChange}
              view="horizontal"
              type="text"
              placeholder={t("taxes:tax_set:form:tax_calculator:net_price_placeholder")}
              label={t("taxes:tax_set:form:tax_calculator:net_price_label")}
              defaultValue={DEFAULT_PRICE_VALUE}
              addonAfter={currency}
            />
          </div>
        ))}

        <Row>
          <Col sm={16} xs={24} className={styles.resultContainer}>
            <Button onClick={handleAddDate}>{t("taxes:tax_set:form:tax_calculator:add_date")}</Button>
            &nbsp;
            <Button onClick={handleRemoveDate}>{t("taxes:tax_set:form:tax_calculator:remove_date")}</Button>
          </Col>
        </Row>

        <br />

        {results && (
          <Spin className={styles.container} spinning={loading}>
            {this.renderResults()}
          </Spin>
        )}
      </>
    );
  }
}

export default withTranslation()(withComponentRef(TaxSetCalculator));
