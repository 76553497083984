import React from "react";

import { Reply } from "../_common/reply";

import GuestFeedback from "./guest_feedback";

export const ReplyToGuestFeedback = ({ review }) => {
  return (
    <div>
      <GuestFeedback review={review} />
      <Reply review={review} />
    </div>
  );
};
