const RESTRICTIONS = [
  "rate",
  "stop_sell",
  "closed_to_arrival",
  "closed_to_departure",
  "min_stay_arrival",
  "min_stay_through",
  "min_stay",
  "max_stay",
  "availability",
];

export default RESTRICTIONS;
