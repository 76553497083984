const formatSortedField = (val) => {
  return val.toLowerCase ? val.toLowerCase() : val;
};

export default (field) => (a, b) => {
  const aFormattedValue = formatSortedField(a[field]);
  const bFormattedValue = formatSortedField(b[field]);

  if (aFormattedValue < bFormattedValue) {
    return -1;
  }
  if (aFormattedValue > bFormattedValue) {
    return 1;
  }
  return 0;
};
