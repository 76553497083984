import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button } from "antd";

class Ignore extends Component {
  render() {
    const { t, onClick } = this.props;

    return (
      <Button type="primary" onClick={onClick}>
        {t("issues_drawer:action_bar:ignore")}
      </Button>
    );
  }
}

export default withTranslation()(Ignore);
