import React from "react";
import { SortableContainer } from "react-sortable-hoc";
import { Row } from "antd";

import styles from "../gallery.module.css";

export default SortableContainer(({ items }) => (
  <Row gutter={8} className={styles.photosRow}>
    {items}
  </Row>
));
