import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import RangePicker from "components/mobile/inventory/range_picker";

import dayjs from "utils/dayjs";
import rangePresets from "utils/range_presets";

import style from "./title.module.css";

function Title({ dateFrom = null, dateTo = null, onSelectDate }) {
  const { t } = useTranslation();

  const minDate = dayjs().subtract(90, "days").startOf("day");
  const maxDate = dayjs().endOf("day");

  return (
    <div>
      <div className={style.header__inner}>
        <div>{t("booking_sources_widget:header")}</div>

        <div>
          <RangePicker
            presets={rangePresets()}
            value={[dateFrom, dateTo]}
            onChange={onSelectDate}
            disabledDate={(date) => date.isBefore(minDate) || date.isAfter(maxDate)}
          />
        </div>
      </div>
    </div>
  );
}

Title.propTypes = {
  dateFrom: PropTypes.object,
  dateTo: PropTypes.object,
};

export default Title;
