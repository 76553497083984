import React from "react";
import { useTranslation } from "react-i18next";

import Loading from "components/loading";
import NoDataPlaceholder from "components/no_data_placeholder/no_data_placeholder";

import ApplicationsViewSection from "./applications_view_section";

export default function ApplicationsView(props) {
  const { t } = useTranslation();
  const {
    loading,
    installedApps,
    availableApps,
    onInstall,
    onUninstall,
    canManageApplications,
  } = props;
  const isAppsPresent = installedApps.length || availableApps.length;

  if (loading) {
    return <Loading />;
  }

  if (!isAppsPresent) {
    return <NoDataPlaceholder />;
  }

  return (
    <>
      <ApplicationsViewSection
        title={t("applications_page:installed")}
        items={installedApps}
        onInstall={onInstall}
        onUninstall={onUninstall}
        canManageApplications={canManageApplications}
      />
      <ApplicationsViewSection
        title={t("applications_page:available")}
        items={availableApps}
        onInstall={onInstall}
        onUninstall={onUninstall}
        canManageApplications={canManageApplications}
      />
    </>
  );
}
