import React from "react";
import { useTranslation } from "react-i18next";

import ChannexDrawer from "drawers/channex_drawer";

import DrawerFormWrapper from "components/drawer_form_wrapper";

import { EditExtraCategoryForm } from "./edit_form/edit_form";

export function EditCategoryDrawer({ visible, propertyId, extraCategory, onClose }) {
  const { t } = useTranslation();
  const title = t("property_extras:titles:edit_extra_category");

  return (
    <ChannexDrawer title={title} visible={visible} onClose={onClose}>
      <DrawerFormWrapper withActions>
        <EditExtraCategoryForm propertyId={propertyId} value={extraCategory} closeDrawer={onClose} />
      </DrawerFormWrapper>
    </ChannexDrawer>
  );
}
