import React from "react";
import { useTranslation } from "react-i18next";

import ChannexDrawer from "drawers/channex_drawer";

import ManageUser from "./manage_user/manage_user";

export default function EditDrawer({ visible, userId, onClose }) {
  const { t } = useTranslation();

  const title = t("admin:user_edit_drawer:header");

  return (
    <ChannexDrawer title={title} visible={visible} onClose={onClose}>
      <ManageUser userId={userId} closeDrawer={onClose} />
    </ChannexDrawer>
  );
}
