import React, { Component } from "react";

import RateSelector from "components/rate_plans/rate_selector";

import calcSelectorState, { buildSelectedRatePlans } from "./calc_selector_state";

export class FormRateSelector extends Component {
  initialRatePlanValue = (ratePlanId) => {
    const { value } = this.props;

    return value.includes(ratePlanId);
  };

  handleChange = (value) => {
    const { onChange } = this.props;

    const selectedRatePlansIds = Object.entries(value)
      .filter(([_ratePlanId, isSelected]) => isSelected)
      .map(([ratePlanId, _isSelected]) => ratePlanId);

    if (onChange) {
      return onChange(selectedRatePlansIds);
    }
  };

  handleAllToggle = (event) => {
    const { disabledRatePlans = [], roomTypes = [] } = this.props;

    const value = event.target.checked;
    const newSelectedRatePlans = {};

    roomTypes.forEach((roomType) => {
      roomType.ratePlans.forEach((ratePlan) => {
        if (disabledRatePlans.includes(ratePlan.id)) {
          newSelectedRatePlans[ratePlan.id] = this.initialRatePlanValue(ratePlan.id);
        } else {
          newSelectedRatePlans[ratePlan.id] = value;
        }
      });
    });

    this.handleChange(newSelectedRatePlans);
  };

  handleRoomTypeToggle = (roomTypeId) => (event) => {
    const { value, disabledRatePlans = [], roomTypes = [] } = this.props;

    const selectedRatePlans = buildSelectedRatePlans(value);
    const roomType = roomTypes.filter((el) => el.id === roomTypeId)[0];

    const newSelectedRatePlans = {
      ...selectedRatePlans,
    };

    roomType.ratePlans.forEach((ratePlan) => {
      if (disabledRatePlans.includes(ratePlan.id)) {
        newSelectedRatePlans[ratePlan.id] = this.initialRatePlanValue(ratePlan.id);
      } else {
        newSelectedRatePlans[ratePlan.id] = event.target.checked;
      }
    });

    this.handleChange(newSelectedRatePlans);
  };

  handleRatePlanToggle = (ratePlanId) => (event) => {
    const { value, disabledRatePlans = [] } = this.props;

    if (disabledRatePlans.includes(ratePlanId)) {
      return;
    }

    const selectedRatePlans = buildSelectedRatePlans(value);

    const newSelectedRatePlans = {
      ...selectedRatePlans,
      [ratePlanId]: event.target.checked,
    };

    this.handleChange(newSelectedRatePlans);
  };

  render() {
    const {
      value,
      roomTypes = [],
      disabledRatePlans: disabledRatePlansFromProps = [],
      ...props
    } = this.props;

    const {
      selectedRatePlans,
      disabledRatePlans,
      selectedRoomTypes,
      allSelected,
      allIndeterminate,
      allDisabled,
    } = calcSelectorState(value, disabledRatePlansFromProps, roomTypes);

    return (
      <RateSelector
        {...props}
        roomTypes={roomTypes}
        selectedRatePlans={selectedRatePlans}
        disabledRatePlans={disabledRatePlans}
        selectedRoomTypes={selectedRoomTypes}
        allSelected={allSelected}
        allIndeterminate={allIndeterminate}
        allDisabled={allDisabled}
        onAllToggle={this.handleAllToggle}
        onRoomTypeToggle={this.handleRoomTypeToggle}
        onRatePlanToggle={this.handleRatePlanToggle}
      />
    );
  }
}
