import React, { Component } from "react";
import { UserOutlined } from "@ant-design/icons";

import styles from "./inventory_rate_plan.module.css";

class InventoryRatePlan extends Component {
  render() {
    const { title, occupancy, className } = this.props;

    return (
      <div className={className}>
        <span className={styles.table__rateNameCell__title}>{title}</span>
        <small className={styles.table__rowLabel}>
          <UserOutlined />
          {occupancy}
        </small>
      </div>
    );
  }
}

export default InventoryRatePlan;
