export default [
  "inherit_availability_offset",
  "inherit_closed_to_arrival",
  "inherit_closed_to_departure",
  "inherit_max_availability",
  "inherit_max_stay",
  "inherit_min_stay_arrival",
  "inherit_min_stay_through",
  "inherit_rate",
  "inherit_stop_sell",
];
