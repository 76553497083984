import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import ChannexDrawerRoutable from "drawers/channex_drawer_routable";

import ChannelManagement from "components/channel_management";

function ChannelManagementDrawer() {
  const { t } = useTranslation();
  const { channelId } = useParams();
  const title = t(
    channelId ? "channels_page:edit_dialog_header" : "channels_page:create_dialog_header",
  );

  return (
    <ChannexDrawerRoutable title={title} dataCy="channel_drawer">
      {({ componentRef, handleCloseFromContent }) => (
        <ChannelManagement
          id={channelId}
          componentRef={componentRef}
          onClose={handleCloseFromContent}
        />
      )}
    </ChannexDrawerRoutable>
  );
}

export default ChannelManagementDrawer;
