import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import SubmitButton from "components/forms/buttons/submit_button";
import GlobalErrors from "components/forms/global_errors";
import FormInput from "components/forms/inputs/hook_form/form_input";
import FormSelect from "components/forms/inputs/hook_form/form_select";
import { FormTextarea } from "components/forms/inputs/hook_form/form_textarea";
import { TextValue } from "components/forms/inputs/text_value/text_value";
import BottomActions from "components/forms/layout/drawer/bottom_actions/bottom_actions";
import useAppForm from "components/hook_form/use_app_form";

import { validationSchema } from "./validation_schema";

export function ExtraForm({ value, currency, isLoading, onSubmit }) {
  const { t } = useTranslation();
  const firstInputRef = useRef();

  const { handleSubmit, errors, control } = useAppForm({
    defaultValue: value,
    validationSchema,
    fieldNames: ["title", "description", "priceMode", "pricePerUnit"],
    submitHandler: (formValue) => {
      const normValue = {
        ...formValue,
        pricePerUnit: `${formValue.pricePerUnit}`,
      };

      onSubmit(normValue);
    },
  });

  const handleFormKeyDown = (e) => {
    if (e.code === "Enter") {
      handleSubmit();
    }
  };

  useEffect(() => {
    if (!firstInputRef.current) {
      return;
    }

    setTimeout(() => {
      firstInputRef.current?.focus();
    }, 300);
  }, [firstInputRef]);

  const isEdit = !!value?.id;

  return (
    <div onKeyDown={handleFormKeyDown}>
      <div>
        <GlobalErrors hookForm errors={errors} />

        {isEdit && (
          <TextValue
            label="Id"
            value={value.id}
            copy
          />
        )}
        <FormInput
          ref={firstInputRef}
          name="title"
          label={t("general:title")}
          errors={errors?.title?.message}
          placeholder={t("general:title")}
          control={control}
        />
        <FormTextarea
          name="description"
          label="Description"
          placeholder="Description"
          errors={errors?.description?.message}
          control={control}
        />
        <FormSelect
          name="priceMode"
          label="Price mode"
          placeholder="Price mode"
          errors={errors?.priceMode?.message}
          control={control}
          options={[
            { value: "per_item", label: "Per Item" },
            { value: "per_stay", label: "Per Stay" },
            { value: "per_person", label: "Per Person" },
            { value: "per_night", label: "Per Night" },
            { value: "per_person_per_night", label: "Per Person Per Night" },
          ]}
        />
        <FormInput
          name="pricePerUnit"
          label="Price per unit"
          placeholder="Price per unit"
          errors={errors?.pricePerUnit?.message}
          control={control}
          addonAfter={currency}
        />
      </div>

      <BottomActions>
        <SubmitButton onClick={() => { handleSubmit(); }} loading={isLoading}>
          {t("general:action:save")}
        </SubmitButton>
      </BottomActions>
    </div>
  );
}
