import React from "react";
import { Controller } from "react-hook-form";
import { Col, Row } from "antd";

export const buildHookFormInput = (InputComponent) => {
  return function HookFormInput({
    name,
    control,
    grouped = false,
    ...inputProps
  }) {
    const controlledInput = (
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <InputComponent
            grouped={grouped}
            {...inputProps}
            {...field}
          />
        )}
      />
    );

    if (grouped) {
      return controlledInput;
    }

    return (
      <Row>
        <Col span={24}>
          {controlledInput}
        </Col>
      </Row>
    );
  };
};
