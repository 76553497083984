import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Tabs } from "antd";

import styles from "../threads.module.css";

function ThreadsHeader({ isThreadActive, handleSetFilter }) {
  const { t } = useTranslation();
  const handleChange = useCallback((val) => handleSetFilter(val === "true"), [handleSetFilter]);

  const items = [{
    label: t("messages_page:thread:filters:active"),
    key: "true",
  }, {
    label: t("messages_page:thread:filters:closed"),
    key: "false",
  }];
  return (
    <Tabs items={items} activeKey={String(isThreadActive)} className={styles.filters} onChange={handleChange} />
  );
}

ThreadsHeader.propTypes = {
  isThreadActive: PropTypes.bool,
  handleSetFilter: PropTypes.func.isRequired,
};

export default ThreadsHeader;
