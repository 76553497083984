import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Form } from "antd";

import SUPPORTED_LANGUAGES from "config/constants/supported_languages";

import SubmitButton from "components/forms/buttons/submit_button";
import FormInput from "components/forms/inputs/form_input";
import FormSelect from "components/forms/inputs/form_select";

import styles from "styles/form_in_drawer.module.css";

const DEFAULT_ROLE = "user";

class AccessManagement extends Component {
  emailField = React.createRef();

  state = {
    form: {
      email: "",
      role: DEFAULT_ROLE,
    },
  };

  componentDidMount() {
    const { defaultRole = DEFAULT_ROLE, isInviteMode, initialValues } = this.props;

    if (isInviteMode) {
      this.setState(({ form }) => ({
        form: {
          ...form,
          role: defaultRole,
        },
      }));

      this.emailField.current.focus();
    } else {
      this.setState({
        form: {
          ...initialValues,
        },
      });
    }
  }

  handleSubmit = () => {
    const { onSubmit } = this.props;
    const { form } = this.state;

    const { ...formValues } = form;

    onSubmit(formValues);
  };

  handleFieldChange = (field) => (value) => {
    this.setState(({ form }) => ({
      form: {
        ...form,
        [field]: value,
      },
    }));
  };

  render() {
    const { t, isInviteMode, loading, initialValues, errors } = this.props;

    return (
      <Form onFinish={this.handleSubmit}>
        <FormInput
          name="email"
          onChange={this.handleFieldChange("email")}
          inputRef={this.emailField}
          view="horizontal"
          type="text"
          placeholder={t("general:email")}
          label={t("general:email")}
          defaultValue={initialValues && initialValues.email}
          errors={errors ? errors.email : null}
          disabled={!isInviteMode}
        />
        <FormSelect
          name="preferred_language"
          onChange={this.handleFieldChange("preferred_language")}
          label={t("user_invite_drawer:form:preferred_language:label")}
          placeholder={t("user_invite_drawer:form:preferred_language:placeholder")}
          defaultValue={SUPPORTED_LANGUAGES[0].key}
          options={SUPPORTED_LANGUAGES.map((lang) => ({ value: lang.key, representation: lang.value }))}
          errors={errors ? errors.preferred_language : null}
        />

        <div className={styles.actions}>
          <SubmitButton loading={loading}>{t("groups_page:submit_button")}</SubmitButton>
        </div>
      </Form>
    );
  }
}

export default withTranslation()(AccessManagement);
