import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Table } from "antd";

import EmptySearchResultPlaceholder from "components/empty_search_result_placeholder";
import Loading from "components/loading";
import NoDataPlaceholder from "components/no_data_placeholder";

import withRouter from "routing/with_router";

import styles from "./crud_table.module.css";

class InnerTable extends Component {
  static propTypes = {
    columns: PropTypes.func.isRequired,
    emptyMessage: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,

    showCreateMessage: PropTypes.bool,
    msgCreateLink: PropTypes.string,
    expandedMethod: PropTypes.func,
    showTableWithoutResults: PropTypes.bool,
    internalLoading: PropTypes.bool,

    bar: PropTypes.any,

    limit: PropTypes.any,
    total: PropTypes.any,
    page: PropTypes.any,
    order: PropTypes.any,

    isAsyncData: PropTypes.bool,
    isInitialDataLoaded: PropTypes.any,
    loading: PropTypes.any,
    data: PropTypes.any,

    onExpand: PropTypes.any,
    onTableChange: PropTypes.any,
  };

  getOrderParams = () => {
    const { order } = this.props;

    const [orderBy, orderDir] = Object.entries(order)[0];

    return {
      orderBy,
      orderDir,
    };
  };

  columns() {
    const { columns: getColumns } = this.props;

    const columns = getColumns().map((col) => ({ ...col, sortOrder: null }));

    const { orderBy, orderDir } = this.getOrderParams();
    const orderDirFull = orderDir === "asc" ? "ascend" : "descend";

    const colIndex = columns.findIndex(({ key }) => key === orderBy);

    if (colIndex >= 0) {
      columns[colIndex].sortOrder = orderDirFull;
    }

    return columns;
  }

  loading() {
    const { data, isAsyncData, isInitialDataLoaded, loading } = this.props;

    const isInitialLoadingComplete = isAsyncData ? isInitialDataLoaded : true;
    const isDataLoaded = data && typeof data.length === "number";

    return !(isInitialLoadingComplete && isDataLoaded && !loading);
  }

  emptyMessage() {
    const { emptyMessage } = this.props;

    return emptyMessage;
  }

  rowKey() {
    return "id";
  }

  renderLoading() {
    const { isInitialDataLoaded, internalLoading = false } = this.props;

    if (internalLoading && isInitialDataLoaded) {
      return null;
    }

    if (!this.loading()) {
      return null;
    }

    return <Loading />;
  }

  renderNoData() {
    const {
      t,
      msgCreateLink,
      data,
      showCreateMessage = true,
      showTableWithoutResults = false,
      isDataFilteredBySearch = false,
    } = this.props;

    if (!this.loading() && Array.isArray(data) && data.length === 0 && !showTableWithoutResults) {
      const actionParams = {
        type: "link",
        createMessageText: t("general:create_first_before"),
        createMessageActionText: t("general:create_first_link"),
        to: msgCreateLink,
      };

      const noDataAction = showCreateMessage ? actionParams : {};

      if (isDataFilteredBySearch) {
        return <EmptySearchResultPlaceholder />;
      }

      return <NoDataPlaceholder emptyMessage={this.emptyMessage()} {...noDataAction} />;
    }

    return null;
  }

  renderTable() {
    const {
      data,
      expandedMethod,
      onExpand,
      showTableWithoutResults = false,
      internalLoading = false,
      onTableChange,
      limit,
      page,
      total,
      loading,
      isInitialDataLoaded,
    } = this.props;

    const pagination = {
      "data-cy": "crud_table_pagination",
      current: page,
      pageSize: limit,
      total,
      size: "small",
      showSizeChanger: false,
    };

    const entitiesLength = data ? data.length : 0;

    if ((!this.loading() || internalLoading) && (entitiesLength !== 0 || showTableWithoutResults)) {
      return (
        <Table
          locale={{
            emptyText: isInitialDataLoaded ? undefined : "Loading...",
          }}
          style={{ height: "100%" }}
          dataSource={data}
          columns={this.columns()}
          rowKey={this.rowKey()}
          pagination={pagination}
          onChange={onTableChange}
          onExpand={onExpand}
          loading={loading}
          rowClassName={(record) => (record.disabled ? styles.disabledRow : null)}
          expandedRowRender={expandedMethod ? expandedMethod() : null}
        />
      );
    }
    return null;
  }

  render() {
    const { bar, style } = this.props;

    return (
      <div style={{ ...style, display: "flex", flexDirection: "column" }} data-cy="crud_table">
        {bar || null}
        <div className={styles.content__content} style={{ height: "100%" }}>
          {this.renderLoading()}
          {this.renderNoData()}
          {this.renderTable()}
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(InnerTable));
