import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import ChannexDrawerRoutable from "drawers/channex_drawer_routable";

import ChannelEventsView from "components/channel_events_view";

import withRouter from "routing/with_router";

class ChannelEventDrawer extends Component {
  render() {
    const { t, params } = this.props;
    const { actionId } = params;

    return (
      <ChannexDrawerRoutable title={t("channel_events_page:channel_events_view_dialog:header")}>
        {() => <ChannelEventsView actionId={actionId} />}
      </ChannexDrawerRoutable>
    );
  }
}

export default withRouter(withTranslation()(ChannelEventDrawer));
