import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Row } from "antd";
import { Form } from "formik";
import * as yup from "yup";

import channelFilterOptions from "config/constants/channels/channel_filter";

import ChannexForm from "components/channex_form";
import FormikFormSelect from "components/forms/inputs/formik/form_select";

import styles from "./advanced_search.module.css";

const restoreFromFilter = (filter) => {
  const output = {};

  if (filter.channel && typeof filter.channel === "string") {
    output.channel = filter.channel.split(",");
  } else {
    output.channel = [];
  }

  if (filter.is_active && typeof filter.is_active === "string") {
    output.is_active = filter.is_active.split(",").map((item) => item === "true");
  } else {
    output.is_active = [];
  }

  return output;
};

const convertAdvancedSearchToFilters = (advancedSearchValue) => {
  const advancedFilter = {};

  if (advancedSearchValue.channel) {
    advancedFilter.channel = advancedSearchValue.channel.join(",");
  }

  if (advancedSearchValue.is_active) {
    advancedFilter.is_active = advancedSearchValue.is_active.join(",");
  }

  return advancedFilter;
};

export default function AdvancedSearch({
  advancedSearchApply,
  advancedSearchFilter,
  toggleVisibility,
}) {
  const [value, setValue] = useState(restoreFromFilter(advancedSearchFilter));
  const { t } = useTranslation();
  const VALIDATION_SCHEMA = yup.object().shape({});
  const DEFAULT_VALUE = {
    channel: [],
    is_active: [],
  };
  const componentRef = React.createRef();

  const onChange = (changes) => {
    setValue(changes);
  };

  const cancel = () => {
    toggleVisibility();
  };

  const reset = () => {
    setValue(DEFAULT_VALUE);
    advancedSearchApply(convertAdvancedSearchToFilters({}));
  };

  const apply = () => {
    advancedSearchApply(convertAdvancedSearchToFilters(value));
  };

  return (
    <div className={styles.advancedSearchContainer}>
      <ChannexForm
        onChange={onChange}
        validationSchema={VALIDATION_SCHEMA}
        defaultValue={DEFAULT_VALUE}
        value={value}
        componentRef={componentRef}
      >
        {({ _handleSubmit }) => (
          <Form>
            <Row>
              <Col span={16}>
                <FormikFormSelect
                  name="channel"
                  mode="multiple"
                  view="fullWidth"
                  placeholder={t("channels_page:advanced_search:channel")}
                  label={t("channels_page:advanced_search:channel")}
                  options={channelFilterOptions}
                />
              </Col>
              <Col span={8}>
                <FormikFormSelect
                  name="is_active"
                  mode="multiple"
                  view="horizontal"
                  placeholder={t("channels_page:advanced_search:is_active")}
                  label={t("channels_page:advanced_search:is_active")}
                  options={[
                    {
                      value: true,
                      representation: t("channels_page:advanced_search:is_active_options:active"),
                    },
                    {
                      value: false,
                      representation: t("channels_page:advanced_search:is_active_options:inactive"),
                    },
                  ]}
                />
              </Col>
            </Row>
          </Form>
        )}
      </ChannexForm>
      <div className={styles.advancedSearchControls}>
        <Button onClick={cancel}>{t("general:crud_table:advanced_search:cancel")}</Button>
        &nbsp;
        <Button onClick={reset}>{t("general:crud_table:advanced_search:reset")}</Button>
        &nbsp;
        <Button onClick={apply} type="primary">
          {t("general:crud_table:advanced_search:search")}
        </Button>
      </div>
    </div>
  );
}

AdvancedSearch.filters = ["channel", "is_active"];
