import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import ChannexDrawer from "drawers/channex_drawer";

import DrawerFormWrapper from "components/drawer_form_wrapper";

import ListingPriceForm from "./form";

class ListingPriceDrawer extends Component {
  render() {
    const { t, listing, visible, channelId, onPricingUpdate, onClose } = this.props;

    return (
      <ChannexDrawer
        title={t("channels_page:form:listing:price_drawer:title")}
        visible={visible}
        onClose={onClose}
      >
        {({ handleCloseFromContent, componentRef }) => (
          <DrawerFormWrapper withActions>
            <ListingPriceForm
              listing={listing}
              channelId={channelId}
              componentRef={componentRef}
              onPricingUpdate={onPricingUpdate}
              onClose={handleCloseFromContent}
            />
          </DrawerFormWrapper>
        )}
      </ChannexDrawer>
    );
  }
}

export default withTranslation()(ListingPriceDrawer);
