import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import ChannexDrawer from "drawers/channex_drawer";

import DrawerFormWrapper from "components/drawer_form_wrapper/drawer_form_wrapper";

import CancellationPolicyEditForm from "./components/cancellation_policy_edit_form";

class CancellationPolicyEditDrawer extends Component {
  render() {
    const { t, id, visible, propertyId, onCreate, onClose } = this.props;

    return (
      <ChannexDrawer
        visible={visible}
        title={id ? t("properties_page:policies:cancellation_policy:edit_dialog_title") : t("policies:dict:cancellation_policy")}
        dataCy="cancellation_policy_drawer"
        onClose={onClose}
      >
        {({ handleCloseFromContent, componentRef }) => (
          <DrawerFormWrapper withActions>
            <CancellationPolicyEditForm
              policyId={id}
              propertyId={propertyId}
              onClose={handleCloseFromContent}
              onCreate={onCreate}
              componentRef={componentRef}
            />
          </DrawerFormWrapper>
        )}
      </ChannexDrawer>
    );
  }
}

export default withTranslation()(CancellationPolicyEditDrawer);
