import React from "react";
import { SortableElement } from "react-sortable-hoc";
import { MenuOutlined } from "@ant-design/icons";

export default SortableElement(({ children, isGrabbing, style = {} }) => (
  <div style={{ background: "#fff", ...style }}>
    <MenuOutlined
      style={{
        cursor: isGrabbing ? "grabbing" : "grab",
      }}
    /> {children}
  </div>
));
