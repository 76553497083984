import Flexbox from "components/flexbox";

import { MappingDirection } from "./mapping_direction";
import { RateMappingRow } from "./rate_mapping_row";
import { RoomMappingRow } from "./room_mapping_row";
import { useMapping } from "./use_mapping";
import { VirtualList } from "./virtual_list";

export const ExperimentalPrimaryRatePerRoom = ({ channelTitle, mappings, roomTypes, ratePlans, mappingOptions, mappingSettings, mappingSchema, onChangeSettings, onChangeMapping }) => {
  const {
    mappingItems,
    handleRoomMappingChange,
    handleRoomMappingDelete,
    handlePrimaryRateChange,
    handleRateMappingChange,
    handleRateMappingDelete,
  } = useMapping({
    mappings,
    mappingOptions: mappingOptions || {}, // when channel is created mappings are rendered before mappingOptions are fetched
    mappingSettings,
    ratePlans,
    roomTypes,
    onChangeSettings,
    onChangeMapping,
  });

  return (
    <Flexbox flexDirection="column" fullHeight>
      <MappingDirection channelTitle={channelTitle} />
      <Flexbox>
        <VirtualList
          items={mappingItems}
          renderItem={(mappingItem) => {
            if (mappingItem.type === "room") {
              return (
                <RoomMappingRow
                  externalRoom={mappingItem.externalRoom}
                  mapping={mappingItem.mapping}
                  roomTypeOptions={mappingItem.roomTypes}
                  onChange={handleRoomMappingChange}
                  onDelete={handleRoomMappingDelete}
                />
              );
            }

            if (mappingItem.type === "rate") {
              return (
                <RateMappingRow
                  externalRate={mappingItem.externalRate}
                  mapping={mappingItem.mapping}
                  ratePlans={mappingItem.ratePlans}
                  mappingSchema={mappingSchema}
                  onPrimaryRateChange={handlePrimaryRateChange}
                  onChange={handleRateMappingChange}
                  onDelete={handleRateMappingDelete}
                />
              );
            }

            throw new Error(`unsupported mapping item: ${mappingItem}`);
          }}
        />
      </Flexbox>
    </Flexbox>
  );
};

ExperimentalPrimaryRatePerRoom.MAPPING_TYPE = "ExternalRoomRate";
