import React from "react";
import store from "store";

const { Geocoding } = store;

export default (WrappedComponent) => {
  class WithFormSubmission extends React.Component {
    state = {
      isLoading: false,
    };

    getByAddress = ({ country, zip_code, state, city, address }) => {
      this.setState({ isLoading: true });

      const fullAddress = [country, state, city, zip_code, address].join(",");

      return Geocoding.getByAddress(fullAddress)
        .then(({ attributes }) => {
          this.setState({ isLoading: false });
          return attributes;
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          return Promise.reject(error);
        });
    };

    getByLocation = ({ lat, lon }) => {
      this.setState({ isLoading: true });

      return Geocoding.getByLocation({ lat, lon })
        .then(({ attributes }) => {
          this.setState({ isLoading: false });
          return attributes;
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          return Promise.reject(error);
        });
    };

    render() {
      const { isLoading } = this.state;

      const geocoding = {
        getByAddress: this.getByAddress,
        getByLocation: this.getByLocation,
        isLoading,
      };

      return <WrappedComponent geocoding={geocoding} {...this.props} />;
    }
  }

  return WithFormSubmission;
};
