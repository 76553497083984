import React, { useCallback } from "react";
import { StarFilled, StarOutlined, StopOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

import PrimaryToggleTooltipContent from "./primary_toggle_tooltip_content";

export default function PrimaryToggle({ rate, isReadonly, onClick }) {
  const handleClick = useCallback(() => onClick(rate), [rate, onClick]);

  let icon = <StopOutlined />;

  if (!isReadonly) {
    icon = rate.primary_occ ? (
      <StarFilled onClick={handleClick} />
    ) : (
      <StarOutlined onClick={handleClick} />
    );
  }

  return (
    <Tooltip
      placement="top"
      title={<PrimaryToggleTooltipContent rate={rate} isReadonly={isReadonly} />}
    >
      {icon}
    </Tooltip>
  );
}
