import _ from "lodash";
import store from "store";

import channelCodes from "config/constants/channels/channel_codes";
import currencyRestrictedChannels from "config/constants/channels/currency_restricted_channels";

import getChannelMultyOccupancyStatus from "./get_channel_multi_occupancy_status";

const { Channels, RatePlans, RoomTypes } = store;

const RATES_SELECT_WITH_MAPPING_MODE = "RATES_SELECT_WITH_MAPPING_MODE";
const RATES_SELECT_ONLY_MODE = "RATES_SELECT_ONLY_MODE";

const USER_SOURCE_MAPPING_CHANNEL_CODES = ["VerticalBooking"];

const loadAvailableChannels = (payload) => {
  return Channels.availableToConnect().then((response) => {
    const availableChannels = response.data;

    // stub for channel mappingSource
    _.each(availableChannels, (channel) => {
      channel.mappingSource = USER_SOURCE_MAPPING_CHANNEL_CODES.includes(channel.code)
        ? "user"
        : "api";
      channel.credentialsSource = channel.code === channelCodes.AirBNB.code ? "oauth" : "form";
    });

    return {
      ...payload,
      availableChannels,
    };
  });
};

const filterSettingsParams = (settings, rateParams, channelCode) => {
  if (channelCodes.AirBNB.code === channelCode) {
    return _.pick(settings, [...Object.keys(rateParams), "occupancy", "primary_occ"]);
  }

  return _.pick(settings, Object.keys(rateParams));
};

const convertRatePlansToMappings = (ratePlans, rateParams, channelCode) => {
  return ratePlans.reduce((acc, el) => {
    const { rate_plan_id: ratePlanId, settings, id } = el;

    const mappingSettings = rateParams ? filterSettingsParams(settings, rateParams, channelCode) : settings;

    if (channelCodes.AirBNB.code === channelCode) {
      mappingSettings.id = id;
    }

    const existingMapping = acc[ratePlanId];

    if (existingMapping) {
      acc[ratePlanId] = Array.isArray(existingMapping)
        ? [...existingMapping, mappingSettings]
        : [existingMapping, mappingSettings];

      return acc;
    }

    acc[ratePlanId] = mappingSettings;
    return acc;
  }, {});
};

const getSelectedChannel = (availableChannels, channelCode) => {
  return availableChannels.find((el) => el.code === channelCode) || {};
};

const getRatesSelectEnabledValue = (selectedChannel, modelSettings) => {
  let isRatesSelectEnabled = false;

  if (selectedChannel) {
    const hasRateParams = selectedChannel.rate_params !== null;
    const hasMappingMode = selectedChannel.mapping_mode !== null;
    isRatesSelectEnabled = hasRateParams || hasMappingMode;
  }

  if (selectedChannel && modelSettings && selectedChannel.credentialsSource === "oauth") {
    isRatesSelectEnabled = isRatesSelectEnabled && !modelSettings.token_invalid;
  }

  return isRatesSelectEnabled;
};

const getRatesSelectMode = (selectedChannel) => {
  switch (selectedChannel.mapping_mode) {
    case "listing":
    case "tree":
      return RATES_SELECT_WITH_MAPPING_MODE;
    default:
      return RATES_SELECT_ONLY_MODE;
  }
};

const getPreselectedProperties = (activeProperty, propertiesOptions) => {
  let properties = [];

  if (propertiesOptions.length === 1) {
    properties = [propertiesOptions[0].id];
  }

  if (activeProperty) {
    properties = [activeProperty];
  }

  return properties;
};

const getDefaultGroupIdForNewChannel = ({ activeGroup, activeProperty, groups, propertiesOptions }) => {
  // if some group selected by user - use it
  if (activeGroup) {
    return activeGroup;
  }

  // also some property can be selected by user, if no property selected - use first group from all available groups
  if (!activeProperty) {
    return groups[0].id;
  }

  // get selected property groups and use first one
  const selectedProperty = propertiesOptions.find((el) => el.id === activeProperty);

  // fallback: if property not found - use first group from all available groups
  if (!selectedProperty) {
    return groups[0].id;
  }

  // fallback: if property has no groups - use first group from all available groups
  if (!selectedProperty.group_ids || selectedProperty.group_ids.length === 0) {
    return groups[0].id;
  }

  // use first group from selected property groups
  return selectedProperty.group_ids[0];
};

const loadChannel = (activeGroup, activeProperty, groups, propertiesOptions, restoredValue) => (
  payload,
) => {
  if (restoredValue) {
    const selectedChannel = getSelectedChannel(payload.availableChannels, restoredValue.channel);
    const ratesSelectMode = getRatesSelectMode(selectedChannel);
    const isRatesSelectEnabled = getRatesSelectEnabledValue(
      selectedChannel,
      restoredValue.settings,
    );

    return Promise.resolve({
      ...payload,
      selectedChannel,
      isRatesSelectEnabled,
      ratesSelectMode,
      model: restoredValue,
    });
  }

  if (!payload.id) {
    const properties = getPreselectedProperties(activeProperty, propertiesOptions);

    const defaultGroupId = getDefaultGroupIdForNewChannel({ activeGroup, activeProperty, groups, propertiesOptions });

    return Promise.resolve({
      ...payload,
      selectedChannel: null,
      isRatesSelectEnabled: false,
      model: {
        group_id: defaultGroupId,
        mappingSource: null,
        properties,
        settings: {
          mappingSettings: {},
        },
        mappings: {},
        known_mappings_list: [],
      },
    });
  }

  return Channels.find(payload.id).then((response) => {
    const model = { ...response.data.attributes };
    const selectedChannel = getSelectedChannel(payload.availableChannels, model.channel);
    const { settings } = model;

    const ratesSelectMode = getRatesSelectMode(selectedChannel);

    model.mappingSource = selectedChannel.mappingSource;

    model.mappings = model.rate_plans
      ? convertRatePlansToMappings(
        model.rate_plans,
        selectedChannel.rate_params,
        selectedChannel.code,
      )
      : {};

    const isRatesSelectEnabled = getRatesSelectEnabledValue(selectedChannel, settings);

    return {
      ...payload,
      selectedChannel,
      isRatesSelectEnabled,
      ratesSelectMode,
      model,
    };
  });
};

const loadRoomTypes = (payload) => {
  const { model } = payload;

  const propertyIds = model.properties;
  const filter = propertyIds.length > 0 ? { property_id: propertyIds } : {};

  if (!propertyIds.length) {
    return Promise.resolve({
      ...payload,
      roomTypes: null,
    });
  }

  return RoomTypes.options(filter).then((roomTypes) => ({
    ...payload,
    roomTypes,
  }));
};

const loadConnectionDetails = (payload) => {
  if (payload.id && currencyRestrictedChannels.includes(payload.model.channel)) {
    const { settings, channel } = payload.model;

    return Channels.getConnectionsDetails({ settings, channel })
      .then(({ attributes }) => ({
        ...payload,
        model: {
          ...payload.model,
          currency: attributes.currency || null,
        },
        connectionSettings: attributes,
      }))
      .catch((error) => {
        if (!error.isBadRequest) {
          throw error;
        }

        return {
          ...payload,
          connectionSettings: {},
        };
      });
  }

  return Promise.resolve({
    ...payload,
    connectionSettings: {},
  });
};

const loadRatePlans = (payload) => {
  const { model } = payload;
  const isExistingChannelWithProperty = model.properties.length && model.id;

  if (isExistingChannelWithProperty) {
    const propertyIds = model.properties;
    const loadMultiOccupancyPlans = getChannelMultyOccupancyStatus(model.channel);

    const requestArgs = { property_id: propertyIds };

    return RatePlans.options(requestArgs, { multi_occupancy: loadMultiOccupancyPlans }).then(
      (ratePlans) => {
        return {
          ...payload,
          ratePlans,
        };
      },
    );
  }

  return Promise.resolve({
    ...payload,
    ratePlans: null,
  });
};

const loadChannelWithMapping = (
  id,
  activeGroup,
  activeProperty,
  groups,
  propertiesOptions,
  restoredValue,
) => {
  const payload = {
    id,
  };

  return loadAvailableChannels(payload)
    .then(loadChannel(activeGroup, activeProperty, groups, propertiesOptions, restoredValue))
    .then(loadRoomTypes)
    .then(loadConnectionDetails)
    .then(loadRatePlans);
};

export default loadChannelWithMapping;
