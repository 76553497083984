import React from "react";
import { Button, Result, Table } from "antd";

import Loading from "components/loading";

import useProperties from "./use_properties";

import tableStyles from "styles/tables.module.css";

export default function Properties({ userId }) {
  const { data, isLoading, isError, refetch } = useProperties(userId);

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return (
      <Result
        status="warning"
        title="Unable to fetch data."
        subTitle="Please try again by clicking the button below."
        extra={
          <Button type="primary" onClick={refetch}>
            Retry
          </Button>
        }
      />
    );
  }

  const columns = [
    {
      title: "Property",
      key: "title",
      render: (_text, record) => {
        return record.title;
      },
    }, {
      title: "Role",
      key: "role",
      render: (_text, record) => {
        return record.role;
      },
    },
  ];

  return (
    <Table
      columns={columns}
      className={tableStyles.fullTabTable}
      dataSource={data}
      pagination={false}
      showHeader
      rowKey="id"
    />
  );
}
