import { useQuery } from "react-query";
import store from "store";

const { AdminWlEmailSettings } = store;
export function useAdminWlEmailSettings(whiteLabelPartnerId, tableParams) {
  const { searchQuery, pagination, order } = tableParams || {};

  const filter = {
    wlPartnerId: whiteLabelPartnerId,
  };

  if (searchQuery) {
    filter.fromName = { has: searchQuery };
  }

  return useQuery(["admin_wl_email_settings", whiteLabelPartnerId, tableParams], () => AdminWlEmailSettings.list(filter, pagination, order), {
    enabled: !!whiteLabelPartnerId && !!tableParams,
    retry: false,
  });
}
