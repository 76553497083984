let storage = {};

const setItem = (name, value) => {
  storage[name] = value;
};

const getItem = (name) => {
  return storage[name];
};

const removeItem = (name) => {
  delete storage[name];
};

const clear = () => {
  storage = {};
};

export default {
  setItem,
  getItem,
  removeItem,
  clear,
};
