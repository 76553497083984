import React from "react";
import { useTranslation } from "react-i18next";

import MappingSection from "components/mapping/mapping_section";

import RemovedRoom from "./removed_room";

export default function RemovedRooms({
  removedRooms,
  roomTypes,
  mappingSettings,
  onChangeSettings,
}) {
  const { t } = useTranslation();
  const removedRoomsIds = Object.keys(removedRooms);

  if (!removedRoomsIds.length) {
    return null;
  }

  const handleDelete = (externalRoomId) => {
    const { rooms } = mappingSettings;

    const { [externalRoomId]: _roomToRemove, ...remainingRooms } = rooms;

    onChangeSettings({ ...mappingSettings, rooms: remainingRooms });
  };

  return (
    <MappingSection title={t("channels_page:form:removed_rooms_header")}>
      {removedRoomsIds.map((externalId) => (
        <RemovedRoom
          key={externalId}
          externalId={externalId}
          id={removedRooms[externalId]}
          roomTypes={roomTypes}
          onDelete={handleDelete}
        />
      ))}
    </MappingSection>
  );
}
