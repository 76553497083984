import React from "react";
import PropTypes from "prop-types";

import FormInput from "components/forms/inputs/form_input";

function handleChangeCallback(callback, field) {
  return (value) => {
    callback(field, value);
  };
}

function InputTitle(props) {
  const { t, model, errors, disabled, onChange } = props;
  const onHandleChange = handleChangeCallback(onChange, "title");

  return (
    <FormInput
      name="title"
      view="horizontal"
      type="text"
      placeholder={t("rates_page:form:title_placeholder")}
      label={t("rates_page:form:title_label")}
      defaultValue={model.title}
      errors={errors ? errors.title : null}
      onChange={onHandleChange}
      disabled={Boolean(model.rate_category_id) || disabled}
    />
  );
}

InputTitle.propTypes = {
  t: PropTypes.func,
  model: PropTypes.object,
  errors: PropTypes.object,
  onChange: PropTypes.func,
};

function areEqual(prevProps, nextProps) {
  const prevValue = prevProps.model.title;
  const prevErrors = prevProps.errors.title || null;
  const nextValue = nextProps.model.title;
  const nextErrors = nextProps.errors.title || null;

  return prevValue === nextValue && prevErrors === nextErrors;
}

export default React.memo(InputTitle, areEqual);
