import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Alert } from "antd";

import Input from "components/forms/inputs/form_input";

export default function DeletePropertyModalContent({ value, error, onChange, onEnter }) {
  const { t } = useTranslation();
  const inputRef = useRef();

  useEffect(
    function setFocus() {
      inputRef.current?.focus();
    },
    [inputRef],
  );

  return (
    <>
      <Alert type="warning" message={t("properties_page:delete_property_modal:warning")} />
      <Input
        auto
        inputRef={inputRef}
        view="vertical"
        name="property_name"
        label={t("properties_page:delete_property_modal:tooltip")}
        placeholder={t("properties_page:delete_property_modal:property_title")}
        value={value}
        errors={error}
        onChange={onChange}
        onPressEnter={onEnter}
      />
    </>
  );
}
