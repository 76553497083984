import React from "react";
import { useTranslation } from "react-i18next";

import Text from "../../../../../_common/text";

export default function NoReplyReason({ isEmptyFeedback, isReplyTimeframeExpired }) {
  const { t } = useTranslation();

  let reason = "You can't reply to this review";

  if (isEmptyFeedback) {
    reason = t("reviews_page:feedback:not_allowed_for_empty");
  } else if (isReplyTimeframeExpired) {
    reason = t("reviews_page:feedback:not_allowed_for_expired");
  }

  return (
    <Text disabled>{reason}</Text>
  );
}
