import React from "react";
import { CheckCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

import styles from "./tax_actions_icon.module.css";

export default function TaxActionsIcon({ isDefault, isLoading }) {
  if (isLoading) {
    return <Spin indicator={<LoadingOutlined className={styles.loadingIcon} />} />;
  }

  if (isDefault) {
    return <CheckCircleOutlined className={styles.defaultIcon} />;
  }

  return null;
}
