import React from "react";
import { useTranslation } from "react-i18next";
import { Space } from "antd";

import * as Page from "../../page";

import { PropertySelector } from "./property_selector";

import ErrorImage from "static/too-many-properties.svg";

export const ChosenPropertyRequiredError = () => {
  const { t } = useTranslation();

  const text = (
    <Space direction="vertical" size={16}>
      <span>{t("inventory_page:error_message")}</span>
      <PropertySelector />
    </Space>
  );

  return (
    <Page.ErrorMessage
      icon={ErrorImage}
      text={text}
    />
  );
};
