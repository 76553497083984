import React from "react";

import { useTimezones } from "./use_timezones";

export function withTimezones(Component) {
  function WithTimezones(props) {
    const { isLoading, timezones, applyTz } = useTimezones();

    return <Component {...props} timezones={timezones} applyTz={applyTz} isTimezonesLoading={isLoading} />;
  }

  return WithTimezones;
}
