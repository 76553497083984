import { createSelector } from "reselect";

import setPropertyLocation from "utils/set_property_location";

export const getProperties = (state) => state.properties;

export const getPropertiesList = createSelector(getProperties, ({ entities }) => {
  return entities ? Object.values(entities).map(setPropertyLocation) : null;
});

export const getPropertiesOptions = createSelector(getProperties, ({ options }) => {
  if (!Array.isArray(options)) {
    return options;
  }

  return options.sort((a, b) => `${a.title}`.localeCompare(b.title));
});

export const getPropertyOptionById = (propertyId) => createSelector([getPropertiesOptions], (options) => {
  return options?.find(({ id }) => propertyId === id);
});
