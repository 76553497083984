import { useTranslation } from "react-i18next";

import ChannexDrawer from "drawers/channex_drawer";

import { ListingBookingSettingsForm } from "./form/form";

export const ListingBookingSettingsDrawer = ({ listing, visible, channelId, onClose, onAvailabilityUpdate }) => {
  const { t } = useTranslation();

  return (
    <ChannexDrawer
      title={t("channels_page:form:listing:booking_settings_drawer:title")}
      visible={visible}
      onClose={onClose}
    >
      {({ handleCloseFromContent }) => (
        <ListingBookingSettingsForm
          listing={listing}
          channelId={channelId}
          onAvailabilityUpdate={onAvailabilityUpdate}
          onClose={handleCloseFromContent}
        />
      )}
    </ChannexDrawer>
  );
};
