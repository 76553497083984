import { useQuery } from "react-query";
import store from "store";

const { AdminWlDomains } = store;
export function useAdminWlDomains(whiteLabelPartnerId, tableParams) {
  const { searchQuery, pagination, order } = tableParams || {};

  const filter = {
    wlPartnerId: whiteLabelPartnerId,
  };

  if (searchQuery) {
    filter.domain = { has: searchQuery };
  }

  return useQuery(["admin_wl_domains", whiteLabelPartnerId, tableParams], () => AdminWlDomains.list(filter, pagination, order), {
    enabled: !!whiteLabelPartnerId && !!tableParams,
    retry: false,
  });
}
