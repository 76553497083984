export default (t) => (a, b) => {
  const representationA = t(`general:restrictions:${a}`);
  const representationB = t(`general:restrictions:${b}`);

  if (representationA > representationB) {
    return 1;
  }
  if (representationA < representationB) {
    return -1;
  }

  return 0;
};
