import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Popover } from "antd";
import { getTaxesList } from "store/storage/selectors/taxes_selector";

import TaxEditDrawer from "drawers/tax_edit_drawer";

import useBoolState from "utils/use_bool_state";

import TaxAddButtonContent from "./tax_add_button_content";

import styles from "./tax_add_button.module.css";

export default function TaxAddButton({
  text,
  showIcon = true,
  propertyId,
  parentTax,
  onSelect,
  dataCy,
}) {
  const taxes = useSelector(getTaxesList);
  const [isTaxDrawerOpen, setTaxDrawerOpen, setTaxDrawerClosed] = useBoolState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const { t } = useTranslation();

  const handleChange = useCallback(
    (taxId) => {
      const selectedTax = taxes[taxId];
      onSelect(selectedTax);
      setIsPopoverOpen(false);
    },
    [taxes, onSelect],
  );

  const handleTaxCreateClick = useCallback(() => {
    setTaxDrawerOpen();
    setIsPopoverOpen(false);
  }, [setTaxDrawerOpen]);

  return (
    <>
      <Popover
        overlayClassName={styles.popoverContainer}
        trigger="click"
        destroyTooltipOnHide
        title={t("taxes:tax_set:form:add_modal:title")}
        open={isPopoverOpen}
        onOpenChange={setIsPopoverOpen}
        content={
          <TaxAddButtonContent
            taxes={taxes}
            parentTax={parentTax}
            onSelect={handleChange}
            onCreate={handleTaxCreateClick}
          />
        }
      >
        <Button data-cy={dataCy} type="link" size="small" icon={showIcon && <PlusCircleOutlined />}>
          {text}
        </Button>
      </Popover>
      <TaxEditDrawer
        propertyId={propertyId}
        visible={isTaxDrawerOpen}
        onClose={setTaxDrawerClosed}
        onCreate={onSelect}
      />
    </>
  );
}
