import React from "react";
import { useTranslation } from "react-i18next";

import Billsby from "./billsby";
import NotPresent from "./not_present";

const subscriptionRepresentations = {
  Billsby,
};

const renderSubscription = (subscription) => {
  let SubscriptionComponent = null;
  if (subscription) {
    SubscriptionComponent = subscriptionRepresentations[subscription.subscription_provider];
  } else {
    SubscriptionComponent = NotPresent;
  }

  return <SubscriptionComponent subscription={subscription} />;
};

export default function Subscription({ subscription }) {
  const { t } = useTranslation();

  return (
    <div>
      <legend>{t("billing_account_drawer:subscription:title")}</legend>
      {renderSubscription(subscription)}
    </div>
  );
}
