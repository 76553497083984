import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import _ from "lodash";
import store from "store";

import addDeletedOptionsInMappings from "components/channel_management/components/mapping/utils/add_deleted_options_in_mappings";
import buildRatesTree from "components/channel_management/components/mapping/utils/build_rates_tree";
import RefreshButton from "components/forms/buttons/refresh_button";
import InputSearch from "components/forms/items/input_search";
import Layouts from "components/layouts";

import handleActionError from "utils/handle_action_error";
import useBoolState from "utils/use_bool_state";

import { buildOrderedMappingSchema } from "../../../utils/build_ordered_mapping_schema";

import BlockedListings from "./components/blocked_listings";
import ReverseMapping from "./components/reverse_mapping/reverse_mapping";
import transformMappingsToArray from "./utils/transform_mappings_to_array";
import transformMappingsToObject from "./utils/transform_mappings_to_object";

const { Channels, subscribe } = store;

const getListings = (mappingOptions) => mappingOptions?.listing_id_dictionary?.values || [];

const getMappedListingIds = (mappings) => {
  const mappedListingIds = transformMappingsToArray(mappings)
    .map((mapping) => mapping.mapping.listing_id);

  return _.uniq(mappedListingIds);
};

const getUnmappedListings = (mappingOptions, mappedListingIds) => {
  const listings = getListings(mappingOptions);

  return listings.filter((listing) => !mappedListingIds.includes(listing.id));
};

const getAvailableMappingOptions = (mappingOptions, unavailableListings) => {
  const listings = getListings(mappingOptions);

  const availableListings = listings.filter(
    ({ id }) => !unavailableListings.find((listing) => listing.id === id),
  );

  return {
    ...mappingOptions,
    listing_id_dictionary: { values: availableListings },
  };
};

const resetListingProcessingStatus = (mappingOptions, listingId, isExactListingProcessing) => {
  const listings = getListings(mappingOptions);

  const updatedListings = listings.map((listing) => {
    if (listing.id !== listingId) {
      return listing;
    }

    const updatedListing = _.cloneDeep(listing);
    updatedListing.synchronization_category = null;

    if (updatedListing.isExactListingProcessing) {
      if (isExactListingProcessing) {
        delete updatedListing.isExactListingProcessing;
        delete updatedListing.isProcessing;
      }
    } else {
      delete updatedListing.isProcessing;
    }

    return updatedListing;
  });

  const updatedMappingOptions = _.cloneDeep(mappingOptions);
  updatedMappingOptions.listing_id_dictionary.values = updatedListings;

  return updatedMappingOptions;
};

const setListingProcessingStatus = (mappingOptions, listingId, isExactListingProcessing) => {
  const listings = getListings(mappingOptions);

  const updatedListings = listings.map((listing) => {
    if (listing.id !== listingId) {
      return listing;
    }

    return {
      ...listing,
      isProcessing: true,
      isExactListingProcessing,
    };
  });

  const updatedMappingOptions = _.cloneDeep(mappingOptions);
  updatedMappingOptions.listing_id_dictionary.values = updatedListings;

  return updatedMappingOptions;
};

export default function AirbnbMapping(props) {
  const [loading, setLoading, setLoadingComplete] = useBoolState(false);

  const [searchQuery, setSearchQuery] = useState("");
  const {
    channelId,
    ratePlans,
    roomTypes,
    mappingOptions,
    channelTitle,
    channelProperties,
    mappings,
    properties,
    mappingSchema,
    onChangeMapping,
    onRefresh,
    onMappingOptionsChange,
  } = props;

  const orderedMappingSchema = useMemo(() => buildOrderedMappingSchema(mappingSchema), [mappingSchema]);

  const mappingsRef = useRef(mappings);
  useEffect(() => { mappingsRef.current = mappings; }, [mappings]);

  const mappingOptionsRef = useRef(mappingOptions);
  useEffect(() => { mappingOptionsRef.current = mappingOptions; }, [mappingOptions]);

  const ratesTree = useMemo(() => {
    return buildRatesTree(channelProperties, properties, roomTypes, ratePlans);
  }, [channelProperties, properties, roomTypes, ratePlans]);

  const mappingOptionsWithDeleted = useMemo(() => {
    return addDeletedOptionsInMappings(mappingSchema, mappings, mappingOptions);
  }, [mappingSchema, mappings, mappingOptions]);

  const blockedListings = useMemo(() => {
    const mappedListingIds = getMappedListingIds(mappings);
    const unmappedListings = getUnmappedListings(mappingOptionsWithDeleted, mappedListingIds);

    return unmappedListings.filter((listing) => Boolean(listing?.synchronization_category));
  }, [mappingOptionsWithDeleted, mappings]);

  const availableMappingOptions = useMemo(() => {
    return getAvailableMappingOptions(mappingOptionsWithDeleted, blockedListings);
  }, [mappingOptionsWithDeleted, blockedListings]);

  const handleRefreshClick = useCallback(() => {
    setLoading();

    return onRefresh().finally(setLoadingComplete);
  }, [setLoading, setLoadingComplete, onRefresh]);

  const handleMappingCreate = (mapping) => {
    const mappingsAsArray = transformMappingsToArray(mappingsRef.current);

    const isFirstMappingInOccupancyGroup = mappingsAsArray.find((item) => item.mapping.airbnb_rate_plan_id === mapping.settings.airbnb_rate_plan_id && item.mapping.listing_id === mapping.settings.listing_id) === undefined;

    if (isFirstMappingInOccupancyGroup) {
      mapping.settings.primary_occ = true;
    }

    return Channels.createMapping(channelId, mapping)
      .then((response) => {
        const newMapping = {
          mappingId: response.id,
          ratePlanId: mapping.rate_plan_id,
          mapping: mapping.settings,
        };
        mappingsAsArray.push(newMapping);
        const updatedMappings = transformMappingsToObject(mappingsAsArray);

        onChangeMapping(updatedMappings);
      })
      .catch(handleActionError);
  };

  const handlePrimaryOccToggle = (mappingId, mapping) => {
    const updatedMapping = {
      ...mapping,
      settings: {
        ...mapping.settings,
        primary_occ: true,
      },
    };

    return Channels.updateMapping(channelId, mappingId, updatedMapping)
      .then(() => {
        const mappingsAsArray = transformMappingsToArray(mappingsRef.current);
        const mappingsForAirbnbRatePlan = mappingsAsArray.filter((item) => item.mapping.airbnb_rate_plan_id === mapping.settings.airbnb_rate_plan_id && item.mapping.listing_id === mapping.settings.listing_id);

        mappingsForAirbnbRatePlan.forEach((item) => {
          if (item.mappingId === mappingId) {
            item.mapping.primary_occ = true;
          } else {
            item.mapping.primary_occ = false;
          }
        });

        const mappingsByRatePlanId = transformMappingsToObject(mappingsAsArray);

        onChangeMapping(mappingsByRatePlanId);
      })
      .catch(handleActionError);
  };

  const handleMappingDelete = (mappingId) => {
    const mappingsAsArray = transformMappingsToArray(mappingsRef.current);
    const deletedMapping = mappingsAsArray.find((mapping) => _.isEqual(mapping.mappingId, mappingId));

    const isListingOnlyMapping = deletedMapping.mapping.listing_id && !deletedMapping.mapping.airbnb_rate_plan_id;

    const updatedMappingOptions = setListingProcessingStatus(mappingOptionsRef.current, deletedMapping.mapping.listing_id, isListingOnlyMapping);
    onMappingOptionsChange(updatedMappingOptions);

    return Channels.deleteMapping(channelId, mappingId).catch(handleActionError);
  };

  const handleListingDisconnect = (listingId) => {
    return Channels.airbnbDisconnectListing(channelId, listingId)
      .then(() => {
        const updatedMappingOptions = resetListingProcessingStatus(mappingOptionsRef.current, listingId);
        onMappingOptionsChange(updatedMappingOptions);
      })
      .catch(handleActionError);
  };

  const handleMappingUpdatedEvent = (payload) => {
    const mappingsAsArray = transformMappingsToArray(mappingsRef.current);

    mappingsAsArray.forEach((item) => {
      const mappingWoPrimaryOcc = _.omit(item.mapping, ["primary_occ"]);
      const payloadWoPrimaryOcc = _.omit(payload, ["primary_occ"]);

      if (_.isEqual(mappingWoPrimaryOcc, payloadWoPrimaryOcc)) {
        item.mapping = payload;
      }
    });

    const updatedMappings = transformMappingsToObject(mappingsAsArray);
    onChangeMapping(updatedMappings);
  };

  const handleMappingRemovedEvent = (payload) => {
    const mappingsAsArray = transformMappingsToArray(mappingsRef.current);

    // delete from mappings as array and return deleted element
    const deletedMappingIndex = mappingsAsArray.findIndex((item) => _.isEqual(_.omit(item.mapping, ["primary_occ"]), payload));

    if (deletedMappingIndex === -1) {
      return;
    }

    const filteredMappings = [...mappingsAsArray];
    const deletedMapping = [...filteredMappings.splice(deletedMappingIndex, 1)][0];

    const updatedMappings = transformMappingsToObject(filteredMappings);

    onChangeMapping(updatedMappings);

    const isListingOnlyMapping = payload.listing_id && !payload.airbnb_rate_plan_id;

    const updatedMappingOptions = resetListingProcessingStatus(mappingOptionsRef.current, deletedMapping.mapping.listing_id, isListingOnlyMapping);
    onMappingOptionsChange(updatedMappingOptions);
  };

  useEffect(() => {
    const subscriptionMappingRemoved = subscribe("airbnb:mapping_removed", handleMappingRemovedEvent);
    const subscriptionMappingUpdated = subscribe("airbnb:mapping_updated", handleMappingUpdatedEvent);

    return () => {
      subscriptionMappingRemoved.remove();
      subscriptionMappingUpdated.remove();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const toolbar = useMemo(() => {
    const total = getListings(mappingOptions).length;

    return (
      <>
        <InputSearch value={searchQuery} total={total} onChange={setSearchQuery} />
        <RefreshButton onClick={handleRefreshClick} />
      </>
    );
  }, [mappingOptions, searchQuery, handleRefreshClick]);

  return (
    <Layouts.WithToolbar toolbar={toolbar} loading={loading}>
      <ReverseMapping
        channelTitle={channelTitle}
        mappings={mappings}
        searchQuery={searchQuery}
        ratesTree={ratesTree}
        mappingOptions={availableMappingOptions}
        orderedMappingSchema={orderedMappingSchema}
        ratePlans={ratePlans}
        blockedListings={blockedListings}
        onMappingCreate={handleMappingCreate}
        onMappingDelete={handleMappingDelete}
        onPrimaryOccToggle={handlePrimaryOccToggle}
      />
      <BlockedListings blockedListings={blockedListings} onDisconnect={handleListingDisconnect} />
    </Layouts.WithToolbar>
  );
}

AirbnbMapping.MAPPING_TYPE = "AirBNB";
