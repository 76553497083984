import React from "react";
import { AutoSizer } from "react-virtualized";

export default (WrappedComponent) => {
  class WithSizes extends React.Component {
    render() {
      return (
        <AutoSizer>
          {({ height, width }) => {
            // prevent rendering with 0 height, for some reason it happens on initial render
            // but crud table calculates items per page based on height, so it's important to
            // prevent rendering with 0 height
            if (height === 0) {
              return null;
            }

            // https://github.com/bvaughn/react-virtualized/issues/1287
            // react-virtualized uses .offsetWidth and .offsetHeight to calculate parent dimensions
            // this produces problems when parent dimensions contain floating point values
            // because .offsetWidth and .offsetHeight rounded to integer and 123.79 becomes 124
            // -1 is a workaround to deal with cases when floating point values are rounded up to higher integer
            // this is losing 1px of dimensions for cases when floating point values are rounded down to lower integer
            // but it's better than having 1px more and flickering scrollbars
            return <WrappedComponent height={height - 1} width={width - 1} {...this.props} />;
          }}
        </AutoSizer>
      );
    }
  }

  return WithSizes;
};
