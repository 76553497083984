import { useMutation, useQueryClient } from "react-query";
import store from "store";

const { AdminChannelAdapters } = store;

export function useSetAccessModeAction() {
  const queryClient = useQueryClient();

  return useMutation(({ id, attrs }) => AdminChannelAdapters.update(id, attrs), {
    onSuccess: () => {
      queryClient.invalidateQueries("admin_channel_adapters");
    },
  });
}
