import { convertToCamelCase, convertToSnakeCase } from "store/utils/case_converter";

import attributesExtractor from "../utils/attributes_extractor";

let transport;

const ENDPOINT = "channels";

export default class AirbnbRatePlans {
  constructor(container) {
    transport = container.transport;
  }

  list(channelId) {
    const method = "POST";
    const url = `${ENDPOINT}/${channelId}/action/retrieve_rate_plans`;

    return transport.send(method, url)
      .then(convertToCamelCase)
      .then((response) => {
        return attributesExtractor(response.data);
      });
  }

  create(channelId, attrs) {
    const method = "POST";
    const url = `${ENDPOINT}/${channelId}/action/create_rate_plan`;
    const body = convertToSnakeCase({
      airbnbRatePlan: attrs,
    });

    return transport.send(method, url, body);
  }

  update(channelId, attrs) {
    const method = "POST";
    const url = `${ENDPOINT}/${channelId}/action/update_rate_plan`;
    const body = convertToSnakeCase({
      airbnbRatePlan: attrs,
    });

    return transport.send(method, url, body);
  }

  remove(channelId, id) {
    const method = "POST";
    const url = `${ENDPOINT}/${channelId}/action/delete_rate_plan`;
    const body = convertToSnakeCase({
      airbnbRatePlan: {
        id,
      },
    });

    return transport.send(method, url, body);
  }
}
