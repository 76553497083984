import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Button, Modal, notification } from "antd";
import store from "store";
import { getInstalledAppsByPropertyIdAndCode } from "store/storage/selectors/applications_selector";
import { getAppMode, getUser } from "store/storage/selectors/session_selector";

import appCodes from "config/constants/app_codes";
import APP_MODES from "config/constants/app_modes";

import ShowCardIFrameModal from "./show_card_iframe_modal";
import ShowCardMFAConfirmationModal from "./show_card_mfa_confirmation_modal";

const { confirm } = Modal;
const { PCICards } = store;

export default function ShowCardButton(props) {
  const { t } = useTranslation();
  const { revision } = props;

  const [isMFACodeModalShown, setMFACodeModalShown] = useState(false);
  const [isIFrameModalShown, setIFrameModalShown] = useState(false);
  const [iframeURL, setIFrameURL] = useState(null);
  const [requestSessionID, setRequestSessionID] = useState(null);

  const applicationInstallation = useSelector(getInstalledAppsByPropertyIdAndCode(revision.property_id, appCodes.pci));
  const appMode = useSelector(getAppMode);
  const user = useSelector(getUser);

  const settings = applicationInstallation?.settings || {};
  const isDefaultViewMode = appMode === APP_MODES.DEFAULT;
  const isPCIAppInstalled = Boolean(applicationInstallation);
  const isUserHasAccess = settings[user.id] === true;
  const isButtonVisible = isPCIAppInstalled && isDefaultViewMode && isUserHasAccess;

  const showError = (field, resolve) => {
    return (error) => {
      let errorMessage = t("applications_page:channex_pci:unknown_error");
      if (error.errors?.details?.[field]) {
        errorMessage = error.errors.details[field][0];
      }

      notification.error({
        message: t("applications_page:channex_pci:error"),
        description: errorMessage,
      });

      resolve();
    };
  };

  const getRevisionId = () => {
    return revision.revision_id || revision.id;
  };

  const openMFACodeModal = () => {
    setMFACodeModalShown(true);
  };

  const closeMFACodeModal = () => {
    setRequestSessionID(null);
    setMFACodeModalShown(false);
  };

  const openIFrameModal = (code) => {
    return new Promise((resolve, _reject) => {
      PCICards.get_card_url(parseInt(code), requestSessionID).then(
        (data) => {
          resolve();
          setIFrameURL(data.card_url);
          setIFrameModalShown(true);
        },
        showError("confirmation_code", resolve),
      );
    });
  };

  const closeIFrameModal = () => {
    setIFrameURL(null);
    setRequestSessionID(null);
    setIFrameModalShown(false);
  };

  const createShowCardRequest = () => {
    return new Promise((resolve, _reject) => {
      PCICards.request_card_data(getRevisionId()).then(
        (data) => {
          setRequestSessionID(data.request_session.id);
          openMFACodeModal();
          resolve();
        },
        showError("booking_revision_id", resolve),
      );
    });
  };

  const showConfirmationDialog = () => {
    confirm({
      title: t("applications_page:channex_pci:confirmation_title"),
      content: t("applications_page:channex_pci:confirmation_content"),
      onOk: createShowCardRequest,
      onCancel() {},
    });
  };

  if (isButtonVisible) {
    return (
      <>
        <Button type="primary" onClick={showConfirmationDialog}>
          {t("applications_page:channex_pci:show_card_button_text")}
        </Button>
        <ShowCardMFAConfirmationModal
          visible={isMFACodeModalShown}
          onConfirm={openIFrameModal}
          onClose={closeMFACodeModal}
        />
        <ShowCardIFrameModal
          url={iframeURL}
          visible={isIFrameModalShown}
          onClose={closeIFrameModal}
        />
      </>
    );
  }

  return null;
}
