import { useQuery } from "react-query";
import store from "store";

const { Integrations: { Apaleo } } = store;

export const useApaleoProperties = ({ appName }) => {
  return useQuery(["apaleo_properties"], () => Apaleo.getProperties({ appName }), {
    retry: false,
    refetchInterval: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: 0,
  });
};
