import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import getChannelInfoByCode from "utils/get_channel_info_by_code";

import styles from "./sources_chart.module.css";

const DEFAULT_BLACK_COLOR = "#000";
const DEFAULT_LIGHT_COLOR = "rgba(0, 0, 0, 0.45)";
const DEFAULT_DARK_COLOR = "rgba(0, 0, 0, 0.65)";

function LegendList({ data, activeIndex }) {
  const getChannelName = useCallback((code) => {
    const channel = getChannelInfoByCode(code);
    if (channel) {
      return channel.title;
    }
    return code;
  }, []);

  const getChannelIcon = useCallback((code) => {
    const channel = getChannelInfoByCode(code);

    if (channel && channel.icon) {
      return <img src={channel.icon} className={styles.legendIcon} alt={channel.title} />;
    }

    return <div className={styles.legendEmptyIcon}>{code}</div>;
  }, []);

  const getPercent = useCallback(
    (amount) => {
      const totalAmountSources = data.reduce((acc, el) => {
        acc += el.totalAmount;
        return acc;
      }, 0);

      const value = (amount / totalAmountSources) * 100;
      return value > 1 ? value.toFixed() : "< 1";
    },
    [data],
  );

  const getLightColorText = useCallback(
    (index) => {
      return activeIndex === index ? DEFAULT_BLACK_COLOR : DEFAULT_LIGHT_COLOR;
    },
    [activeIndex],
  );

  const getDarkColorText = useCallback(
    (index) => {
      return activeIndex === index ? DEFAULT_BLACK_COLOR : DEFAULT_DARK_COLOR;
    },
    [activeIndex],
  );

  const listItems = useMemo(
    () => data.map((item, index) => {
      const { channel, countOfBookings, totalAmount, currency, totalAmountFormatted } = item;
      const channelIcon = getChannelIcon(channel);
      const name = getChannelName(channel);
      const percent = getPercent(totalAmount);

      const darkColor = getDarkColorText(index);
      const lightColor = getLightColorText(index);

      return (
        <li key={index} className={styles.legendItem}>
          <div className={classNames(styles.coll, styles.coll_name)} style={{ color: darkColor }}>
            <div className={styles.nameInner}>
              {channelIcon}
              <div className={styles.nameInner__channelName}>
                {name}
              </div>
            </div>
          </div>

          <div
            className={classNames(styles.coll, styles.coll_countOfBookings)}
            style={{ color: lightColor }}
          >
            <span className={styles.countOfBookings}>{countOfBookings}</span>
          </div>

          <div
            className={classNames(styles.coll, styles.coll_legendAmount)}
            style={{ color: darkColor }}
          >
            {totalAmountFormatted} {currency}
          </div>
          <div
            className={classNames(styles.coll, styles.coll_percent)}
            style={{ color: lightColor }}
          >
            {percent}%
          </div>
        </li>
      );
    }),
    [data, getChannelIcon, getChannelName, getDarkColorText, getLightColorText, getPercent],
  );

  return <ul className={styles.legendList}>{listItems}</ul>;
}

LegendList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  activeIndex: PropTypes.number,
};

export default LegendList;
