import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";
import classnames from "classnames";

import NothingImage from "static/nothing-to-display.svg";
import styles from "./no_data_placeholder.module.css";

export default class NoDataPlaceholder extends Component {
  renderLink = () => {
    const { to, createMessageActionText } = this.props;

    return to && <Link to={to}>{createMessageActionText}</Link>;
  };

  renderButton = () => {
    const { createMessageActionText, onClick } = this.props;

    return (
      <Button type="link" className={styles.button} onClick={onClick}>
        {createMessageActionText}
      </Button>
    );
  };

  render() {
    const { emptyMessage, createMessageText, createComponent = null, type, className } = this.props;

    let action;

    switch (type) {
      case "link":
        action = this.renderLink();
        break;

      case "button":
        action = this.renderButton();
        break;

      default:
        action = createComponent;
        break;
    }

    const noDataAction = createMessageText && (
      <>
        <br />
        <p>
          {createMessageText} {action}
        </p>
      </>
    );
    const containerClassName = classnames(styles.container, className);

    return (
      <div className={containerClassName}>
        <img src={NothingImage} alt="nothing" />
        {emptyMessage}
        {noDataAction}
      </div>
    );
  }
}
