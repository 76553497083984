import React, { useState } from "react";

import ChannexPopover from "components/channex_popover";

import { MappingValue } from "../mapping_value";

import { MappingForm } from "./mapping_form";

export const ExtraValuesMappingEditor = ({ mapping, externalRate, ratePlansById, mappingSchema, isInvalid, onDelete, onChange }) => {
  const [isFormOpen, setIsFormOpen] = useState(false);

  if (!isFormOpen) {
    return (
      <MappingValue
        isInvalid={isInvalid}
        mapping={mapping}
        ratePlansById={ratePlansById}
        onClick={() => setIsFormOpen((open) => !open)}
      />
    );
  }

  const handleSubmit = async (...args) => {
    await onChange(...args);
    setIsFormOpen(false);
  };

  const handleDelete = async (...args) => {
    await onDelete(...args);
    setIsFormOpen(false);
  };

  return (
    <ChannexPopover
      trigger="click"
      open={isFormOpen}
      onOpenChange={(open) => setIsFormOpen(open)}
      content={
        <MappingForm
          mapping={mapping}
          externalRate={externalRate}
          ratePlans={Object.values(ratePlansById)}
          mappingSchema={mappingSchema}
          onCancel={() => setIsFormOpen(false)}
          onSubmit={handleSubmit}
          onDelete={handleDelete}
        />
      }
    >
      <MappingValue
        mapping={mapping}
        ratePlansById={ratePlansById}
        onClick={() => setIsFormOpen((open) => !open)}
      />
    </ChannexPopover>
  );
};
