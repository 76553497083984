import React, { Component } from "react";

class WizardStepWithChannexForm extends Component {
  form = React.createRef();

  getFields = () => {
    return this.form.current.getFields();
  };

  beforeLeave = () => {
    if (!this.form.current) {
      return Promise.reject();
    }

    return this.form.current.validate();
  };

  render() {
    const { value, errors, onChange, content, contentProps } = this.props;

    const UpdatedContent = React.cloneElement(content, {
      componentRef: this.form,
      value,
      errors,
      contentProps,
      onChange,
    });

    return UpdatedContent;
  }
}

export default WizardStepWithChannexForm;
