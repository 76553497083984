import store from "store";

const { Bookings } = store;

export default (issue) => {
  const { payload = {} } = issue;
  const { booking_id } = payload;

  return Bookings.find(booking_id, { relationships: "all" });
};
