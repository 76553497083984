import { WEBHOOK_LOGS_LOAD } from "../constants";

const initialState = null;
const ACTION_HANDLERS = {
  [WEBHOOK_LOGS_LOAD]: (_state, action) => {
    const entities = action.payload.records.reduce((acc, { id, attributes }) => {
      acc[id] = attributes;
      return acc;
    }, {});

    return {
      entities,
      meta: action.payload.meta,
    };
  },
};

export default function webhookLogsReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
