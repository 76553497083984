import React from "react";
import classnames from "classnames";

import styles from "./content_container.module.css";

export default function ContentContainer({ className, quotes, border, innerPadding = true, children }) {
  const outerContainerClassName = classnames(styles.outerContainer, className || "", {
    [styles.withBorder]: border,
  });

  const innerContainerClassName = classnames(styles.innerContainer, {
    [styles.withQuotes]: quotes,
    [styles.withInnerPadding]: innerPadding,
  });

  return (
    <div className={outerContainerClassName}>
      <div className={innerContainerClassName}>{children}</div>
    </div>
  );
}
