import React from "react";
import { useTranslation } from "react-i18next";

import ContentContainer from "../../../../_common/content_container";
import SectionHeader from "../../../../_common/section_header/section_header";

import NoReplyReason from "./no_reply_reason";
import ReplyForm from "./reply_form";
import ReplyMessage from "./reply_message";

export const Reply = ({ review }) => {
  const { t } = useTranslation();

  const { isReplied, content, isExpired } = review.attributes;

  const isEmptyFeedback = !content;
  const shouldReply = !isReplied && !isEmptyFeedback && !isExpired;
  const cannotReply = !isReplied && !shouldReply;

  return (
    <ContentContainer>
      <SectionHeader header={t("reviews_page:feedback:response_subheader")} />
      {isReplied && <ReplyMessage review={review} />}
      {shouldReply && <ReplyForm review={review} />}
      {cannotReply && <NoReplyReason isEmptyFeedback={isEmptyFeedback} isReplyTimeframeExpired={isExpired} />}
    </ContentContainer>
  );
};
