import { useMemo, useState } from "react";

import { FormNestedListSelector, TREE_VALUE_MODE } from "components/forms/inputs/form_nested_list_selector";
import FormItem from "components/forms/items/form_item";

const valueFromInput = (value) => {
  return value.map(({ id, children }) => ({
    id,
    rate_plans: children,
  }));
};

export const ApaleoRoomRatesSelector = ({ rooms, onChange }) => {
  const [value, setValue] = useState([]);

  const tree = useMemo(() => {
    return rooms.map(({ ratePlans, ...room }) => {
      return ({
        ...room,
        children: ratePlans,
      });
    });
  }, [rooms]);

  return (
    <FormItem label="Select Rooms">
      <FormNestedListSelector
        valueMode={TREE_VALUE_MODE}
        standalone
        value={value}
        tree={tree}
        onChange={(newValue) => {
          setValue(newValue);

          onChange(valueFromInput(newValue));
        }}
      />
    </FormItem>
  );
};
