import { convertToCamelCase, convertToSnakeCase } from "store/utils/case_converter";

import attributesExtractor from "../utils/attributes_extractor";

let transport;
let storage;

const ENDPOINT = "user_api_keys";

const MAX_PAGE_SIZE = 100;

export default class UserApiKeys {
  constructor(container) {
    transport = container.transport;
    storage = container.storage;
  }

  list(order = { title: "asc" }) {
    return transport
      .send("GET", ENDPOINT, { order, pagination: { page: 1, limit: MAX_PAGE_SIZE } })
      .then(convertToCamelCase)
      .then((response) => {
        const formattedData = attributesExtractor(response.data);
        storage.userApiKeysLoad(formattedData, response.meta);
        return formattedData;
      });
  }

  find(id) {
    return transport
      .send("GET", `${ENDPOINT}/${id}`)
      .then(convertToCamelCase)
      .then((response) => {
        return attributesExtractor(response.data);
      });
  }

  update(id, attrs) {
    const convertedAttrs = convertToSnakeCase(attrs);

    return transport
      .send("PUT", `${ENDPOINT}/${id}`, convertedAttrs)
      .then(convertToCamelCase)
      .then((response) => {
        return attributesExtractor(response.data);
      });
  }

  create(attrs) {
    const convertedAttrs = convertToSnakeCase(attrs);

    return transport
      .send("POST", ENDPOINT, convertedAttrs)
      .then(convertToCamelCase)
      .then((response) => {
        const formattedData = attributesExtractor(response.data);
        storage.userApiKeySet(formattedData);

        return formattedData;
      });
  }

  withdraw(id) {
    return transport
      .send("POST", `${ENDPOINT}/${id}/withdraw`)
      .then(convertToCamelCase)
      .then((response) => {
        const formattedData = attributesExtractor(response.data);
        storage.userApiKeySet(formattedData);

        return formattedData;
      });
  }
}
