import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import _ from "lodash";
import store from "store";

import ConnectionSettingsComponent from "./connection_settings_components";

const { Channels } = store;

class ConnectionSettings extends Component {
  static propTypes = {
    t: PropTypes.func,
  };

  state = {
    connectionStatus: null,
    connectionErrors: null,
  };

  isConnectionSettingsFilled = () => {
    const { form, namespace } = this.props;

    const settings = form.values[namespace];
    const connectionSettings = _.omit(settings, "mappingSettings", "mapping");

    return Object.keys(connectionSettings).every((param) => {
      const value = connectionSettings[param];

      return !_.isNil(value) && value !== "" && value !== false;
    });
  };

  componentDidMount() {
    if (this.isConnectionSettingsFilled()) {
      this.testConnection(true);
    }
  }

  handleFieldChange = (value, field, form) => {
    this.setState({ connectionStatus: null, connectionErrors: null });

    form.setFieldValue(field.name, value);
  };

  testConnection = (isInitialTest = false) => {
    const { onConnectionChecked, form, namespace } = this.props;

    const channel = form.values.channel;

    const settingsWithoutMapping = _.omit(form.values[namespace], "mapping");

    this.setState({ connectionStatus: "loading" });

    Channels.testConnection({ settings: settingsWithoutMapping, channel })
      .then((response) => {
        this.setState({
          connectionStatus: response.data.success ? "success" : "error",
          connectionErrors: response.data.errors ? response.data.errors : null,
        });

        if (!isInitialTest && response.data.success) {
          onConnectionChecked();
        }
      })
      .catch(() => {
        this.setState({ connectionStatus: "error", connectionErrors: "internal_error" });
      });
  };

  handleTestConnectionClick = () => {
    this.testConnection();
  };

  render() {
    const { t, settingsSchema, mappingSchema, form, namespace, focusField } = this.props;
    const { connectionStatus, connectionErrors } = this.state;

    return (
      <ConnectionSettingsComponent
        t={t}
        form={form}
        namespace={namespace}
        settingsSchema={settingsSchema}
        mappingSchema={mappingSchema}
        connectionStatus={connectionStatus}
        connectionErrors={connectionErrors}
        focusField={focusField}
        onTestConnectionClick={this.handleTestConnectionClick}
        handleFieldChange={this.handleFieldChange}
      />
    );
  }
}

export default withTranslation()(ConnectionSettings);
