import React from "react";
import { useTranslation } from "react-i18next";

export default function Issue({ issue, ...menuProps }) {
  const { t } = useTranslation();
  const { name } = issue;

  return (
    <div {...menuProps}>
      {t(`issues:types:${name}`)}
    </div>
  );
}
