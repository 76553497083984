import React from "react";

import AttachedFile from "./attached_file";

import styles from "./styles.module.css";

export default function AttachedFiles({ files, onDelete }) {
  if (!files?.length) {
    return null;
  }

  return (
    <div className={styles.attachedFiles}>
      {files.map((file) => (
        <AttachedFile
          key={file.id}
          file={file}
          onDelete={onDelete}
        />
      ))}
    </div>
  );
}
