const convertToHashmap = (array, key = "id") => {
  if (!Array.isArray(array)) {
    return {};
  }

  return array.reduce((acc, el) => {
    if (!el || !el[key]) {
      return acc;
    }

    acc[el[key]] = el;
    return acc;
  }, {});
};

export default convertToHashmap;
