import React from "react";
import * as yup from "yup";

import errorMessages from "config/constants/errors";

import HookForm from "components/hook_form";

import useCreateSubscriptionAction from "./use_create_subscription_action";

export default function ManageSubscriptionForm({ billingAccountId, onFinish }) {
  const { mutate: createSubscription, isLoading: isCreateLoading } = useCreateSubscriptionAction();

  const validationSchema = yup.object().shape({
    customerId: yup.string().trim().required(errorMessages.required()),
    subscriptionId: yup.string().trim().required(errorMessages.required()),
  });

  return (
    <HookForm
      validationSchema={validationSchema}
      fields={
        [{
          field: "customerId",
          type: "text",
          label: "Billsby Customer ID",
        }, {
          field: "subscriptionId",
          type: "text",
          label: "Billsby Subscription ID",
        }]
      }
      submit={({ customerId, subscriptionId }) => createSubscription({
        billingAccountId,
        body: {
          customerId,
          subscriptionId,
        },
      })}
      isSubmitting={isCreateLoading}
      onFinish={onFinish}
    />
  );
}
