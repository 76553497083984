import React, { Component } from "react";
import { Checkbox } from "antd";

import RateTitle from "components/rates/title";

import styles from "./rate_row_input.module.css";

class RateRowInput extends Component {
  handleChange = (e) => {
    const { onChange } = this.props;
    const { checked } = e.target;

    onChange(checked);
  };

  render() {
    const { className = "", value, rate } = this.props;

    return (
      <div className={className}>
        <Checkbox
          data-cy="rate_checkbox"
          className={styles.checkbox}
          checked={value}
          onChange={this.handleChange}
        />
        <RateTitle rate={rate} />
      </div>
    );
  }
}

export default RateRowInput;
