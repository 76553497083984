import React, { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space } from "antd";

import useBoolState from "utils/use_bool_state";

import styles from "./form_table_select.module.css";

const RENDER_DELAY = 100;

export default function FormTableSelectFilterDropdown(props) {
  const [isSearching, setIsSearching, setIsNotSearching] = useBoolState(false);
  const { t } = useTranslation();
  const inputRef = useRef();
  const { dataIndex, filterDropdownProps, onSearch, onReset } = props;

  const { setSelectedKeys, selectedKeys, confirm, clearFilters, visible } = filterDropdownProps;

  const handleQueryChange = useCallback(
    (event) => {
      const newSelectedKeys = event.target.value ? [event.target.value] : [];

      setSelectedKeys(newSelectedKeys);
    },
    [setSelectedKeys],
  );

  const handleSearch = useCallback(() => {
    setIsSearching();
    onSearch(selectedKeys[0], dataIndex, confirm);
  }, [selectedKeys, dataIndex, confirm, onSearch, setIsSearching]);

  const handleReset = useCallback(() => {
    clearFilters();
    onReset();
  }, [clearFilters, onReset]);

  useEffect(
    function focusSearchInput() {
      const isInputShown = inputRef.current && visible;

      // Literally from antd docs
      const focusTimeout = setTimeout(() => {
        if (!isInputShown) {
          return;
        }

        inputRef.current.select();
        inputRef.current.focus();
      }, RENDER_DELAY);

      return () => clearTimeout(focusTimeout);
    },
    [visible, inputRef],
  );

  useEffect(
    function handleSearchQueryChange() {
      if (!isSearching) {
        return;
      }

      confirm();
      setIsNotSearching();
    },
    [isSearching, confirm, setIsNotSearching],
  );

  return (
    <div className={styles.dropdownContainer}>
      <Input
        ref={inputRef}
        className={styles.dropdownSearchInput}
        placeholder={t("general:search_field_placeholder")}
        value={selectedKeys[0]}
        onChange={handleQueryChange}
        onPressEnter={handleSearch}
      />
      <Space className={styles.dropdownButtonContainer}>
        <Button
          className={styles.dropdownButton}
          type="primary"
          onClick={handleSearch}
          icon={<SearchOutlined />}
          size="small"
        >
          {t("general:search_field_placeholder")}
        </Button>
        <Button className={styles.dropdownButton} size="small" onClick={handleReset}>
          {t("general:action:clear_filter")}
        </Button>
      </Space>
    </div>
  );
}
