import React from "react";
import _ from "lodash";

export default {
  clean: {
    title: () => "Clean",
    icon: (
      <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.962 10.004a8.25 8.25 0 0 0-7.997 8.246l-.004-.252A8.25 8.25 0 0 0 1.715 10l.252-.004A8.25 8.25 0 0 0 9.965 1.75l.003.252A8.25 8.25 0 0 0 18.215 10z" stroke="#595959" strokeWidth="1.6" fill="none" fillRule="evenodd" strokeLinejoin="round" />
      </svg>
    ),
  },
  comfort: {
    title: () => "Comfort",
    icon: (
      <svg width="19" height="18" viewBox="0 0 19 18" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd" opacity=".65">
          <path d="M.965 0h18v18h-18z" />
          <path d="M5.236 4.94c.35 0 .633-.284.633-.633V.633a.632.632 0 1 0-1.266 0v3.674c0 .35.283.632.633.632zm10.775 2.53h-2.109c0-.699-.566-1.265-1.266-1.265H3.918c-.7 0-1.266.566-1.266 1.266v4.36c0 .059.004.117.012.173a5.626 5.626 0 0 0 11.173 1.232h2.174c.7 0 1.266-.566 1.266-1.265V8.736c0-.7-.566-1.265-1.266-1.265zm-3.375 4.5h-.019a4.359 4.359 0 1 1-8.68 0h-.02v-4.5h8.72v4.5zm3.375-.14h-1.968V8.736h1.968v3.094zm-4.834-6.89c.35 0 .633-.284.633-.633V.633a.632.632 0 1 0-1.265 0v3.674c0 .35.283.632.632.632zm-2.988 0c.35 0 .633-.284.633-.633V.633a.632.632 0 1 0-1.266 0v3.674c0 .35.283.632.633.632z" fill="#000" fillRule="nonzero" />
        </g>
      </svg>
    ),
  },
  facilities: {
    title: () => "Facilities",
    icon: (
      <svg width="19" height="18" viewBox="0 0 19 18" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd" opacity=".65">
          <path d="M.965 0h18v18h-18z" />
          <path d="M16.347 8.223a5.064 5.064 0 0 0-6.685-.42L3.172 1.31a.631.631 0 0 0-.894 0 .631.631 0 0 0 0 .895l6.491 6.492a5.064 5.064 0 0 0 7.58 6.685 5.062 5.062 0 0 0-.002-7.16zm-.895 6.265a3.796 3.796 0 0 1-5.37 0 3.796 3.796 0 0 1 0-5.37 3.796 3.796 0 1 1 5.37 5.37zM7.15 5.265l1.343-1.343a.631.631 0 0 0 0-.895.631.631 0 0 0-.895 0L6.254 4.37l.895.895zM6.8 2.232a.631.631 0 0 0 0-.894.631.631 0 0 0-.895 0L4.563 2.68l.895.894L6.8 2.232z" fill="#000" fillRule="nonzero" />
        </g>
      </svg>
    ),
  },
  location: {
    title: () => "Location",
    icon: (
      <svg width="19" height="18" viewBox="0 0 19 18" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
          <path d="M.965 0h18v18h-18z" />
          <path d="M9.965 4.003c-.948 0-1.84.369-2.51 1.038a3.526 3.526 0 0 0-1.04 2.51c0 .95.37 1.84 1.04 2.51.67.67 1.56 1.04 2.51 1.04.949 0 1.838-.37 2.51-1.04a3.526 3.526 0 0 0 1.039-2.51c0-.949-.37-1.838-1.04-2.51a3.532 3.532 0 0 0-2.51-1.038zm0 5.843A2.296 2.296 0 0 1 7.67 7.552a2.296 2.296 0 0 1 2.294-2.294 2.296 2.296 0 0 1 2.294 2.294 2.296 2.296 0 0 1-2.294 2.294zm6.303-5.107a6.162 6.162 0 0 0-1.506-1.914 7.18 7.18 0 0 0-2.187-1.287 7.434 7.434 0 0 0-2.609-.471h-.003c-.893 0-1.77.158-2.609.471a7.18 7.18 0 0 0-2.186 1.287A6.157 6.157 0 0 0 3.66 4.739 5.246 5.246 0 0 0 3.1 7.093c0 1.13.277 2.292.826 3.454.453.961 1.095 1.932 1.909 2.884 1.455 1.706 3.093 2.925 3.73 3.37a.695.695 0 0 0 .801 0c.637-.445 2.277-1.664 3.73-3.37.813-.952 1.456-1.923 1.91-2.884.548-1.162.826-2.326.826-3.454a5.24 5.24 0 0 0-.563-2.354zm-.694 2.356c0 .943-.238 1.926-.707 2.92-.402.857-.984 1.732-1.726 2.603-1.158 1.358-2.456 2.397-3.176 2.926-.721-.53-2.018-1.568-3.177-2.926-.742-.87-1.323-1.748-1.728-2.605-.469-.994-.706-1.976-.706-2.92 0-.619.144-1.224.43-1.795a4.935 4.935 0 0 1 1.204-1.526 5.907 5.907 0 0 1 1.804-1.061c.7-.26 1.43-.392 2.17-.392h.004c.742 0 1.473.131 2.17.392.673.25 1.28.608 1.805 1.061.518.447.923.96 1.202 1.526.287.577.43 1.18.43 1.797z" fill="#000" fillOpacity=".65" fillRule="nonzero" />
        </g>
      </svg>
    ),
  },
  staff: {
    title: () => "Staff",
    icon: (
      <svg width="19" height="18" viewBox="0 0 19 18" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd" opacity=".65">
          <path d="M.965 0h18v18h-18z" />
          <path d="M16.24 8.22a4.111 4.111 0 0 0-1.218-.821c.523-.268.97-.657 1.306-1.137.41-.586.626-1.269.626-1.98 0-1.943-1.627-3.523-3.627-3.523-.758 0-1.483.224-2.098.648-.536.37-.954.865-1.218 1.444a3.664 3.664 0 0 0-2.657-1.125c-2 0-3.627 1.58-3.627 3.523 0 .71.216 1.393.626 1.978.335.48.783.869 1.306 1.139-.451.198-.86.473-1.218.821A3.924 3.924 0 0 0 3.23 12.02v2.885c0 .876.734 1.59 1.638 1.59h4.978c.657 0 1.25-.385 1.507-.966h4.468c.901 0 1.637-.713 1.637-1.591V11.05a3.937 3.937 0 0 0-1.217-2.83zm-4.571-5.548a2.357 2.357 0 0 1 1.658-.668c.627 0 1.215.237 1.657.668a2.23 2.23 0 0 1 0 3.22 2.357 2.357 0 0 1-1.657.667 2.357 2.357 0 0 1-1.658-.667c-.444-.43-.687-1-.687-1.61 0-.61.243-1.18.687-1.61zM5.008 5.249c0-.61.243-1.18.687-1.61a2.357 2.357 0 0 1 1.658-.668c.627 0 1.215.237 1.657.668a2.228 2.228 0 0 1 0 3.22 2.357 2.357 0 0 1-1.657.668 2.367 2.367 0 0 1-1.66-.668 2.23 2.23 0 0 1-.685-1.61zm5.187 9.653a.33.33 0 0 1-.104.242.356.356 0 0 1-.25.102H4.864a.35.35 0 0 1-.353-.344v-2.885a2.7 2.7 0 0 1 .835-1.95 2.864 2.864 0 0 1 2.008-.811 2.864 2.864 0 0 1 2.03.835l.006.007a2.7 2.7 0 0 1 .806 1.92v2.884zm-.317-6.05a4.1 4.1 0 0 0-.83-.488 3.59 3.59 0 0 0 1.62-1.684c.278.29.602.533.961.717a4.121 4.121 0 0 0-1.751 1.456zm6.291 5.085a.35.35 0 0 1-.353.343H11.48v-2.263c0-.794-.238-1.556-.69-2.211a2.862 2.862 0 0 1 2.536-1.517c.756 0 1.469.287 2.008.81a2.7 2.7 0 0 1 .835 1.95v2.888z" fill="#000" fillRule="nonzero" />
        </g>
      </svg>
    ),
  },
  value: {
    title: () => "Value",
    icon: (
      <svg width="19" height="18" viewBox="0 0 19 18" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd" opacity=".65">
          <path d="M.965 0h18v18h-18z" />
          <path d="M9.965 2.392c.093 0 .188.05.235.15l1.837 3.914c.037.08.11.134.195.146l4.13.631a.26.26 0 0 1 .145.438l-3.002 3.078a.257.257 0 0 0-.07.223l.706 4.335a.26.26 0 0 1-.381.269l-3.67-2.029a.263.263 0 0 0-.25 0l-3.67 2.029a.256.256 0 0 1-.125.033.261.261 0 0 1-.257-.302l.707-4.335a.257.257 0 0 0-.07-.223L3.42 7.671a.26.26 0 0 1 .146-.438l4.131-.63a.258.258 0 0 0 .195-.147L9.73 2.542c.048-.1.142-.15.236-.15zm0-1.265a1.518 1.518 0 0 0-1.38.877l-1.61 3.428-3.599.55A1.518 1.518 0 0 0 2.16 7.005a1.518 1.518 0 0 0 .355 1.549l2.649 2.715-.626 3.832a1.526 1.526 0 0 0 2.245 1.58l3.182-1.757 3.181 1.76a1.526 1.526 0 0 0 2.245-1.58l-.626-3.833 2.65-2.716c.4-.411.535-1.003.354-1.548a1.518 1.518 0 0 0-1.216-1.023l-3.598-.552-1.61-3.428a1.517 1.517 0 0 0-1.38-.877z" fill="#000" fillRule="nonzero" />
        </g>
      </svg>
    ),
  },
  unknown: {
    title: (type) => _.capitalize(type),
    icon: (
      <svg width="19" height="18" viewBox="0 0 19 18" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd" opacity=".65">
          <path d="M.965 0h18v18h-18z" />
          <path d="M9.965 2.392c.093 0 .188.05.235.15l1.837 3.914c.037.08.11.134.195.146l4.13.631a.26.26 0 0 1 .145.438l-3.002 3.078a.257.257 0 0 0-.07.223l.706 4.335a.26.26 0 0 1-.381.269l-3.67-2.029a.263.263 0 0 0-.25 0l-3.67 2.029a.256.256 0 0 1-.125.033.261.261 0 0 1-.257-.302l.707-4.335a.257.257 0 0 0-.07-.223L3.42 7.671a.26.26 0 0 1 .146-.438l4.131-.63a.258.258 0 0 0 .195-.147L9.73 2.542c.048-.1.142-.15.236-.15zm0-1.265a1.518 1.518 0 0 0-1.38.877l-1.61 3.428-3.599.55A1.518 1.518 0 0 0 2.16 7.005a1.518 1.518 0 0 0 .355 1.549l2.649 2.715-.626 3.832a1.526 1.526 0 0 0 2.245 1.58l3.182-1.757 3.181 1.76a1.526 1.526 0 0 0 2.245-1.58l-.626-3.833 2.65-2.716c.4-.411.535-1.003.354-1.548a1.518 1.518 0 0 0-1.216-1.023l-3.598-.552-1.61-3.428a1.517 1.517 0 0 0-1.38-.877z" fill="#000" fillRule="nonzero" />
        </g>
      </svg>
    ),
  },
};
