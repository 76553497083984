import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, notification } from "antd";
import store from "store";

import SubmitButton from "components/forms/buttons/submit_button";
import FormTextarea from "components/forms/inputs/form_textarea";

const { Reviews } = store;

export default function ReplyForm({ review }) {
  const { t } = useTranslation();

  const [reply, setReply] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(
    function resetReply() {
      setReply(null);
      setLoading(false);
    },
    [review],
  );

  const handleReplyChange = (_field, fieldValue) => {
    setReply(fieldValue);
  };

  const submitReply = () => {
    setLoading(true);

    Reviews.reply(review.id, reply).then(
      (_repliedReview) => {
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        notification.error({
          message: "Error",
          description: error.errors.title,
        });
      },
    );
  };

  return (
    <Form onFinish={submitReply}>
      <FormTextarea
        name="reply"
        value={reply}
        view="fullWidth"
        onChange={handleReplyChange}
      />
      <SubmitButton disabled={!reply} loading={loading}>
        {t("reviews_page:feedback:send")}
      </SubmitButton>
    </Form>
  );
}
