import React from "react";
import PropTypes from "prop-types";
import { ApiOutlined, FormOutlined, LoadingOutlined } from "@ant-design/icons";

import styles from "./form_message.module.css";

const iconsByName = {
  api: <ApiOutlined />,
  form: <FormOutlined />,
  loading: <LoadingOutlined />,
};

function FormMessage(props) {
  const { icon = "form", message } = props;

  return (
    <div className={styles.formMessage}>
      {iconsByName[icon]}
      {message}
    </div>
  );
}

FormMessage.propTypes = {
  icon: PropTypes.string,
  message: PropTypes.string,
};

export default FormMessage;
