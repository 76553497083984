import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Col, Row } from "antd";

import { MappingDeleteButton } from "components/mapping/mapping_row/mapping_delete_button";
import MappingStatus from "components/mapping/mapping_status";
import RateTitleWithId from "components/rates/title_with_id";

import { buildMultiOccupancyRatesList } from "utils/build_multioccupancy_rates_list";

import MappingSelect from "../mapping_select";

import PrimaryToggle from "./primary_toggle";
import updatePrimaryOccValues from "./update_primary_occ_values";

import roomRatesMappingStyles from "./room_rates_mapping.module.css";
import styles from "components/channel_management/components/mapping/tree_mapping/components/direct_mapping/components/mapping_row.module.css";

class RoomRatesMapping extends Component {
  componentDidMount() {
    const { mappings, onChangeMapping } = this.props;

    const mappingsWithOccupancy = updatePrimaryOccValues(mappings);

    return onChangeMapping(mappingsWithOccupancy);
  }

  handleRateMappingChange = (rate) => (ratePlanId) => {
    const { room, mappings, onChangeMapping } = this.props;

    const mappingsWithoutCurrentRate = this.removeRateMapping(mappings, rate);

    if (!ratePlanId) {
      return onChangeMapping(mappingsWithoutCurrentRate);
    }

    const newMapping = {
      room_type_code: room.id,
      rate_plan_code: rate.id,
      occupancy: rate.occupancy,
      type: room.type,
      readonly: rate.readonly,
      primary_occ: false,
      occ_changed: false,
    };

    const oldMappings = Object.values(this.getRateValue(rate));

    if (oldMappings.length) {
      const { primary_occ, occ_changed } = oldMappings[0][0];

      newMapping.primary_occ = primary_occ;
      newMapping.occ_changed = occ_changed;
    }

    const existingMappings = mappings[ratePlanId] || [];
    const updatedMappings = {
      ...mappings,
      [ratePlanId]: [...existingMappings, newMapping],
    };

    const mappingsWithOccupancy = updatePrimaryOccValues(updatedMappings);

    return onChangeMapping(mappingsWithOccupancy);
  };

  handleRateMappingDelete = (rate) => () => {
    const { mappings, onChangeMapping } = this.props;
    const updatedMappings = this.removeRateMapping(mappings, rate);

    const mappingsWithOccupancy = updatePrimaryOccValues(updatedMappings);

    onChangeMapping(mappingsWithOccupancy);
  };

  removeRateMapping = (mappings, rate) => {
    const mappingsToUpdate = { ...mappings };
    const rateIds = Object.keys(this.getRateValue(rate));
    const associatedMappings = rateIds.map((rateId) => mappingsToUpdate[rateId]);

    const mappingIndexArray = associatedMappings.map((mapping) => this.getMappingIndex(mapping, rate));

    if (rateIds.length && mappingIndexArray.length) {
      rateIds.forEach((rateId) => {
        mappingIndexArray.forEach((mappingIndex) => {
          mappingsToUpdate[rateId][mappingIndex] = null;
        });
      });
    }

    return mappingsToUpdate;
  };

  getMappingIndex = (mappings, rate) => {
    return mappings?.findIndex((mapping) => this.isMappingMatchesWithRate(mapping, rate));
  };

  getRateValue = (rate) => {
    const { mappings } = this.props;

    return Object.keys(mappings).reduce((acc, mappedRateId) => {
      const activeMappings = mappings[mappedRateId];

      const mappedRates = activeMappings
        && activeMappings.filter((mapping) => this.isMappingMatchesWithRate(mapping, rate));

      if (mappedRates && mappedRates.length) {
        acc[mappedRateId] = [...(acc[mappedRateId] || []), ...mappedRates];
      }

      return acc;
    }, {});
  };

  isMappingMatchesWithRate = (mapping, rate) => {
    const { room } = this.props;

    return (
      mapping
      && mapping.rate_plan_code === rate.id
      && mapping.occupancy === rate.occupancy
      && mapping.room_type_code === room.id
    );
  };

  handlePrimaryRateToggle = (toggledRate) => {
    const { mappings, onChangeMapping } = this.props;
    const updatedMappings = {};
    const updatedOccupancy = !toggledRate.primary_occ;

    Object.keys(mappings).forEach((channexRateId) => {
      const mappedRates = mappings[channexRateId];

      const updatedRates = mappedRates.map((rate) => {
        if (!rate) {
          return rate;
        }

        const isRoomMatches = toggledRate.room_type_code === rate.room_type_code;
        const isRateMatches = toggledRate.rate_plan_code === rate.rate_plan_code;
        const isRelatedRate = isRoomMatches && isRateMatches;

        if (isRelatedRate && updatedOccupancy) {
          const isOccupancyMatches = toggledRate.occupancy === rate.occupancy;

          return {
            ...rate,
            primary_occ: isOccupancyMatches,
            occ_changed: true,
          };
        }

        return rate;
      });

      updatedMappings[channexRateId] = updatedRates;
    });

    onChangeMapping(updatedMappings);
  };

  render() {
    const { room, ratePlans, mappingSettings, isOccupancyBased, t } = this.props;
    const { rooms } = mappingSettings;

    const mappedRoomId = rooms ? rooms[room.id] : null;
    const availableRatePlans = ratePlans.filter(
      (ratePlan) => ratePlan.room_type_id === mappedRoomId,
    );
    const ratesPerOccupancy = buildMultiOccupancyRatesList(room.rates, t);

    return ratesPerOccupancy.map((rate, index) => {
      const mappedRates = this.getRateValue(rate);
      const selectedValues = Object.keys(mappedRates);
      const isReadonly = rate.readonly;

      let mappedBcomRate = {};
      let showToggleColumn = false;

      if (isOccupancyBased) {
        mappedBcomRate = Object.values(mappedRates)
          .flat()
          .find((mappedRate) => {
            return (
              mappedRate.rate_plan_code === rate.id && mappedRate.occupancy === rate.occupancy
            );
          }) || {};

        showToggleColumn = isOccupancyBased && (mappedBcomRate.rate_plan_code || isReadonly);
      }

      const isPrimary = mappedBcomRate.primary_occ && mappedBcomRate.rate_plan_code;
      const rateToggleClass = isPrimary || isReadonly
        ? roomRatesMappingStyles.primarySelectActive
        : roomRatesMappingStyles.primarySelect;
      const rateSectionWidth = showToggleColumn ? 9 : 11;

      return (
        <Row className={roomRatesMappingStyles.rateMapping} key={`${rate.id}-${index}`}>
          <Col className={roomRatesMappingStyles.rateTitle} span={rateSectionWidth}>
            <RateTitleWithId rate={rate} showReadOnly />
          </Col>

          {mappedRoomId && (
            <>
              {showToggleColumn && (
                <Col span={2} className={rateToggleClass}>
                  <PrimaryToggle
                    rate={mappedBcomRate}
                    isReadonly={isReadonly}
                    onClick={this.handlePrimaryRateToggle}
                  />
                </Col>
              )}
              <Col className={roomRatesMappingStyles.iconCol} span={1}>
                <MappingStatus />
              </Col>
              <Col span={11} className={styles.mappedRateCol}>
                <MappingSelect
                  value={selectedValues}
                  options={availableRatePlans}
                  onChange={this.handleRateMappingChange(rate)}
                />
              </Col>
              <Col span={1}>
                {selectedValues.length ? (
                  <MappingDeleteButton visible onClick={this.handleRateMappingDelete(rate)} />
                ) : null}
              </Col>
            </>
          )}
        </Row>
      );
    });
  }
}

export default withTranslation()(RoomRatesMapping);
