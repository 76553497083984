import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Form } from "antd";

import SubmitButton from "components/forms/buttons/submit_button";
import FormSelect from "components/forms/inputs/form_select";
import FormTextarea from "components/forms/inputs/form_textarea";

import RoomPhotos from "./components/room_photos";

import styles from "styles/form_in_drawer.module.css";

const DEFAULT_CONTENT_VALUE = {
  description: "",
  photos: [],
};

class ContentForm extends Component {
  handleContentChange = (field, fieldValue) => {
    const { value, onChange } = this.props;
    const { content = DEFAULT_CONTENT_VALUE } = value;
    const updatedContent = { ...content, [field]: fieldValue };

    onChange("content")(updatedContent);
  };

  render() {
    const { t, facilitiesOptions, isLoading, value, errors, onChange, onSubmit } = this.props;
    const { content = DEFAULT_CONTENT_VALUE } = value;
    const { content: contentErrors } = errors;

    return (
      <Form onFinish={onSubmit}>
        <FormTextarea
          errors={contentErrors ? contentErrors.description : null}
          label={t("rooms_page:content:description_label")}
          placeholder={t("rooms_page:content:description_placeholder")}
          name="description"
          value={content.description}
          onChange={this.handleContentChange}
        />
        <FormSelect
          view="horizontal"
          mode="multiple"
          name="facilities"
          allowClear
          placeholder={t("rooms_page:form:facilities_placeholder")}
          onChange={onChange("facilities")}
          value={value.facilities}
          label={t("rooms_page:form:facilities_label")}
          errors={errors ? errors.facilities : null}
          options={facilitiesOptions}
          isGroup
        />
        <RoomPhotos name="photos" value={content.photos} onChange={this.handleContentChange} />
        <div className={styles.actions}>
          <SubmitButton loading={isLoading}>{t("rooms_page:submit_button")}</SubmitButton>
        </div>
      </Form>
    );
  }
}

export default withTranslation()(ContentForm);
