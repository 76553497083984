import React, { useCallback, useMemo, useState } from "react";
import { Card } from "antd";
import classNames from "classnames";

import dayjs from "utils/dayjs";

import { Content } from "./content/content";
import Title from "./title";

import styles from "./styles.module.css";
import dashboardStyles from "styles/dashboard.module.css";

const DEFAULT_DATE_RANGE = [
  dayjs().subtract(1, "months").startOf("day"),
  dayjs().startOf("day"),
];

function BookingSourcesWidget() {
  const [dateFrom, setDateFrom] = useState(DEFAULT_DATE_RANGE[0]);
  const [dateTo, setDateTo] = useState(DEFAULT_DATE_RANGE[1]);

  const onSelectDate = useCallback((dates) => {
    if (dates?.length > 0) {
      setDateFrom(dates[0]);
      setDateTo(dates[1]);
    }
  }, []);

  const isDefaultDateRange = useMemo(() => {
    return dateFrom.isSame(DEFAULT_DATE_RANGE[0]) && dateTo.isSame(DEFAULT_DATE_RANGE[1]);
  }, [dateFrom, dateTo]);

  const cardClassName = classNames(
    dashboardStyles.dashboard__widget,
    dashboardStyles.dashboard__widget__emptyPadding,
    dashboardStyles.dashboard__widget__withBorder,
    styles.cardWrapper,
  );

  return (
    <Card
      className={cardClassName}
      title={
        <Title
          dateFrom={dateFrom}
          dateTo={dateTo}
          onSelectDate={onSelectDate}
        />
      }
    >
      <Content
        dateFrom={dateFrom}
        dateTo={dateTo}
        isDefaultDateRange={isDefaultDateRange}
      />
    </Card>
  );
}

export default BookingSourcesWidget;
