import React, { Component } from "react";
import { Form } from "antd";
import _ from "lodash";

import { horizontalFormItemLayout } from "config/constants/layouts/form";

import ClientFileReader from "components/forms/inputs/client_file_reader";

import MappingPreview from "./mapping_preview";

export default class FileField extends Component {
  render() {
    const { t, settings, field, schema, mappingSchema, form, handleFieldChange } = this.props;

    const fieldName = "settings.mapping";

    const fieldValue = _.get(settings, "mapping");
    const status = _.isEmpty(fieldValue) ? null : "success";

    return (
      <Form.Item
        key={field}
        {...horizontalFormItemLayout}
        label={t(`channels_page:form:settings_${field}_label`)}
        data-cy="file_mapping"
      >
        <ClientFileReader
          format="csv"
          parserOptions={schema.parserOptions}
          initialStatus={status}
          preview={
            <MappingPreview
              t={t}
              settings={settings}
              parserOptions={schema.parserOptions}
              mappingSchema={mappingSchema}
            />
          }
          onChange={(value) => {
            const newValue = { ...fieldValue, ...value };

            handleFieldChange(newValue, { name: fieldName }, form);
          }}
        />
      </Form.Item>
    );
  }
}
