import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import RoomMapping from "./room_mapping";

export default function KnownMappings({
  knownMappings,
  mappingOptions,
  roomTypes,
  ratePlans,
  onDelete,
}) {
  const [knownMappingsByRoomId, setKnownMappingsByRoomId] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    if (!Array.isArray(knownMappings)) {
      return;
    }

    const knownMappingsPerRoom = knownMappings.reduce((acc, mapping) => {
      const { room_type_code } = mapping;
      const { [room_type_code]: roomMappings = [] } = acc;

      const updatedRoomRates = [...roomMappings, mapping];

      return { ...acc, [room_type_code]: updatedRoomRates };
    }, {});

    setKnownMappingsByRoomId(knownMappingsPerRoom);
  }, [knownMappings]);

  if (!knownMappings?.length) {
    return null;
  }

  return (
    <>
      <legend>{t("channels_page:form:known_mappings_legend")}</legend>
      {Object.keys(knownMappingsByRoomId).map((roomId) => (
        <RoomMapping
          key={roomId}
          knownMappings={knownMappings}
          roomId={roomId}
          mappingOptions={mappingOptions}
          knownMappingsPerRoom={knownMappingsByRoomId[roomId]}
          roomTypes={roomTypes}
          ratePlans={ratePlans}
          onDelete={onDelete}
        />
      ))}
    </>
  );
}
