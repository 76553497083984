import React from "react";
import classNames from "classnames";

import styles from "./drawer_form_wrapper.module.css";

export default function ({ className, withActions = false, fullHeight = false, children }) {
  const wrapperClassName = classNames(styles.wrapper, className, {
    [styles.wrapperWithButton]: withActions,
    [styles.fullHeight]: fullHeight,
  });

  return (
    <div className={wrapperClassName}>
      {children}
    </div>
  );
}
