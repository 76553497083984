import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import store from "store";
import {
  getChannelMessages,
  getChannelMessagesMeta,
  getChannelMessagesThreadById,
} from "store/storage/selectors/channel_messages_threads_selector";
import { getPropertyOptionById } from "store/storage/selectors/properties_selector";

import Chat from "components/chat";
import { calcCanReplyToBooking } from "components/chat/calc_can_reply_to_booking";

import { pathBuilder } from "routing";
import useRouting from "routing/use_routing";

import showErrorMessageFromResponse from "utils/show_error_message_from_response";

import DialogHeader from "./dialog_header";

import styles from "./dialog.module.css";

const { ws, ChannelMessagesThreads, Bookings } = store;

let requestedBookingId = null;

export default function Dialog({ isMobile }) {
  const { t } = useTranslation();
  const { threadId } = useParams();
  const navigate = useNavigate();
  const { userAppRoutes } = useRouting();
  const messagesList = useSelector(getChannelMessages(threadId));
  const messagesMeta = useSelector(getChannelMessagesMeta(threadId));
  const thread = useSelector(getChannelMessagesThreadById(threadId));
  const property = useSelector(getPropertyOptionById(thread?.relationships.property.data.id));
  const [bookingParams, setBookingParams] = useState(null);
  const bookingId = thread?.relationships.booking?.data?.id;

  // there can be threads without booking, for threads by bookings we should disable chat if booking is old
  let canBeReplied = true;
  if (bookingParams) {
    canBeReplied = calcCanReplyToBooking(bookingParams, messagesList);
  }

  const onCloseThread = () => {
    ChannelMessagesThreads.closeThread(threadId).then(() => {
      navigate("/messages");
    });
  };

  const onOpenThread = () => {
    ChannelMessagesThreads.openThread(threadId).then(() => {
      navigate("/messages");
    });
  };

  const loadList = useCallback(
    (meta) => {
      return ChannelMessagesThreads.messagesListByThreadId(threadId, meta)
        .then((data) => {
          return data;
        })
        .catch((errors) => {
          showErrorMessageFromResponse(errors);

          throw errors;
        });
    },
    [threadId],
  );

  useEffect(
    function loadAssociatedBooking() {
      setBookingParams(null);

      if (!bookingId) {
        requestedBookingId = null;
        return;
      }

      requestedBookingId = bookingId;

      Bookings.find(bookingId, { relationships: "all" })
        .then((booking) => {
          if (requestedBookingId !== booking.id) {
            return;
          }

          setBookingParams(booking);
        })
        .catch(showErrorMessageFromResponse);
    },
    [bookingId],
  );

  useEffect(
    function resetMessagesList() {
      ChannelMessagesThreads.resetMessagesList(threadId);
    },
    [threadId],
  );

  const handleSendMessage = (val) => {
    return ChannelMessagesThreads.sendMessageByThreadId(threadId, val);
  };

  const handleThreadReload = () => {
    ChannelMessagesThreads.resetMessagesList(threadId);
  };

  useEffect(() => {
    const chatChannel = ws.socket.channel(`messages:${threadId}`);
    chatChannel.join();
    chatChannel.on("new_message", (message) => {
      ChannelMessagesThreads.handleMessageByThreadId(threadId, message);
    });

    return function cleanup() {
      chatChannel.leave();
    };
  }, [threadId]);

  if (!property) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <DialogHeader
        thread={thread}
        isMobile={isMobile}
        bookingParams={bookingParams}
        handleCloseThread={onCloseThread}
        handleOpenThread={onOpenThread}
      />
      <Chat
        thread={thread}
        disabled={!canBeReplied}
        disabledReason={!canBeReplied && t("booking_messages:cannot_reply_old_booking")}
        messages={messagesList}
        meta={messagesMeta}
        className={styles.messagesListWrapper}
        onLoad={loadList}
        sender={thread.attributes.title}
        receiver={property.title}
        onSend={handleSendMessage}
        onThreadReload={handleThreadReload}
      />
      <Outlet context={{ closePath: pathBuilder(userAppRoutes.messages.dialog, { threadId }) }} />
    </div>
  );
}
