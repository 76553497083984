import { useQuery } from "react-query";
import store from "store";
import attributesExtractor from "store/utils/attributes_extractor";
import { convertToCamelCase } from "store/utils/case_converter";

const { Channels } = store;

export function useChannels(propertyId) {
  const { data, ...query } = useQuery({
    queryKey: ["channels", propertyId],
    queryFn: () => Channels.list({ property_id: propertyId }, { limit: 100 })
      .then(convertToCamelCase)
      .then((response) => attributesExtractor(response.data)),
    enabled: !!propertyId,
  });

  return {
    channels: data,
    ...query,
  };
}
