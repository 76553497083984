import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import ChannexDrawer from "drawers/channex_drawer";

import RoomForm from "components/room_form";

class RoomFormDrawerControlled extends Component {
  render() {
    const { t, roomTypeId, visible, onClose } = this.props;

    const title = roomTypeId
      ? t("rooms_page:edit_dialog_header")
      : t("rooms_page:create_dialog_header");

    return (
      <ChannexDrawer visible={visible} title={title} onClose={onClose} dataCy="room_drawer">
        {({ handleCloseFromContent, componentRef }) => (
          <RoomForm id={roomTypeId} componentRef={componentRef} onClose={handleCloseFromContent} />
        )}
      </ChannexDrawer>
    );
  }
}

export default withTranslation()(RoomFormDrawerControlled);
