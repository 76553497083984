import React, { forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import store from "store";

import { Location } from "../../../location";
import { StepTitle } from "../step_title";

const { Integrations: { Apaleo } } = store;

export const AuthStep = forwardRef((props, ref) => {
  const { t } = useTranslation();

  const { appName } = props;

  const mutation = useMutation(() => Apaleo.oauth({ appName }));

  useImperativeHandle(ref, () => ({
    proceed: async () => {
      const result = await mutation.mutateAsync();
      Location.assign(result.authUrl);

      return false;
    },
  }));

  return (
    <div>
      <StepTitle>{t("apaleo_import:titles:authenticate")}</StepTitle>
      <p>
        {t("apaleo_import:messages:authenticate")}
      </p>
    </div>
  );
});
