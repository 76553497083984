import { useTranslation } from "react-i18next";

import { MappingRow } from "../mapping_row";

import styles from "./mapping_direction.module.css";

export const MappingDirection = ({ channelTitle }) => {
  const { t } = useTranslation();

  return (
    <MappingRow
      className={styles.row}
      source={channelTitle}
      target={t("channels_page:form:channel_manager")}
    />
  );
};
