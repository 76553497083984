import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import MEAL_TYPES from "config/constants/meal_types";

import FormSelect from "components/forms/inputs/form_select";

const FIELD_NAME = "meal_type";

export default function InputMealType({ model, errors, disabled, onChange }) {
  const { t } = useTranslation();
  const [mealOptions, setMealOptions] = useState([]);

  const handleChange = useCallback((value) => onChange(FIELD_NAME, value), [onChange]);

  useEffect(() => {
    const options = Object.keys(MEAL_TYPES).map((value) => ({
      value,
      representation: t(`meal_types:${value}`),
    }));

    setMealOptions(options);
  }, [t]);

  return (
    <FormSelect
      name="meal_type"
      view="horizontal"
      placeholder={t("rates_page:form:meal_type_placeholder")}
      label={t("rates_page:form:meal_type_label")}
      defaultValue={model.meal_type}
      value={model.meal_type}
      disabled={disabled}
      errors={errors ? errors.meal_type : null}
      options={mealOptions}
      onChange={handleChange}
    />
  );
}
