import React from "react";
import { Form } from "antd";

import getFieldLayout from "components/forms/inputs/utils/get_field_layout";

export default function FormItem(props) {
  const { view, children, ...rest } = props;
  const layout = getFieldLayout(view);

  return (
    <Form.Item {...layout} {...rest}>
      {children}
    </Form.Item>
  );
}
