import { BOOKING_STATS_LOAD } from "../constants";

const initialState = null;
const ACTION_HANDLERS = {
  [BOOKING_STATS_LOAD]: (state, action) => {
    return action.payload;
  },
};

export default function groupsReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
