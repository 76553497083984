import { useQuery } from "react-query";
import store from "store";

const { AdminUsers } = store;
export default function useUser(userId) {
  return useQuery(["admin_users", userId], () => AdminUsers.find(userId), {
    enabled: !!userId,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    cacheTime: 0,
  });
}
