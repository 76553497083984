import React from "react";
import PropTypes from "prop-types";
import { Form, Radio } from "antd";

import { horizontalFormItemLayout } from "config/constants/layouts/form";

import styles from "styles/form_in_drawer.module.css";

function handleChangeCallback(callback, field) {
  return (event) => {
    callback(field, event.target.value);
  };
}

function InputSellMode(props) {
  const { t, model, disabled, onChange } = props;
  const onHandleChange = handleChangeCallback(onChange, "sell_mode");
  const isDisabled = Boolean(model.rate_category_id) || Boolean(model.id) || disabled;

  return (
    <Form.Item {...horizontalFormItemLayout} label={t("rates_page:form:sell_mode_label")}>
      <Radio.Group
        value={model.sell_mode}
        onChange={onHandleChange}
        className={styles.buttonGroup}
        disabled={isDisabled}
      >
        <Radio.Button className={styles.buttonGroup__button__1_2} value="per_room">
          <span data-cy="radio_group_sell_mode_per_room">
            {t("rates_page:form:per_room_option")}
          </span>
        </Radio.Button>
        <Radio.Button className={styles.buttonGroup__button__1_2} value="per_person">
          <span data-cy="radio_group_sell_mode_per_person">
            {t("rates_page:form:per_person_option")}
          </span>
        </Radio.Button>
      </Radio.Group>
    </Form.Item>
  );
}

InputSellMode.propTypes = {
  t: PropTypes.func,
  model: PropTypes.object,
  onChange: PropTypes.func,
};

export default InputSellMode;
