import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { buildMappingItems } from "../build_mapping_items";
import { transformMappingsToArray } from "../utils/transform_mappings_to_array";
import { transformMappingsToObject } from "../utils/transform_mappings_to_object";

const buildMappingsPerExternalRoom = (mappingsAsArray) => {
  return mappingsAsArray.reduce((acc, mapping) => {
    const { room_type_code, rate_plan_code, occupancy } = mapping.settings;

    acc[room_type_code] = acc[room_type_code] || {};
    acc[room_type_code][rate_plan_code] = acc[room_type_code][rate_plan_code] || {};

    if (typeof occupancy !== "undefined") {
      acc[room_type_code][rate_plan_code][`occ_${occupancy}`] = mapping;
    } else {
      acc[room_type_code][rate_plan_code] = mapping;
    }

    return acc;
  }, {});
};

const buildUnknownMappingOptions = ({ mappingsAsArray, mappingOptions, mappingSettings }) => {
  const mappingOptionsById = mappingOptions.rooms.reduce((roomAcc, room) => {
    roomAcc[room.id] = { ...room };
    roomAcc[room.id].rates = room.rates.reduce((rateAcc, rate) => {
      rateAcc[rate.id] = rate;
      return rateAcc;
    }, {});
    return roomAcc;
  }, {});

  const unknownMappingOptionsById = {};

  Object.entries(mappingSettings.rooms).forEach(([externalRoomTypeCode, roomTypeId]) => {
    if (roomTypeId && !mappingOptionsById[externalRoomTypeCode]) {
      unknownMappingOptionsById[externalRoomTypeCode] = {
        isInvalid: true,
        isRemoved: true,
        id: externalRoomTypeCode,
        title: `${externalRoomTypeCode}`,
        rates: {},
      };
    }
  });

  mappingsAsArray.forEach(({ settings }) => {
    const { room_type_code, rate_plan_code, occupancy } = settings;
    const isOccBasedMapping = typeof occupancy !== "undefined";

    if (!mappingOptionsById[room_type_code]) {
      unknownMappingOptionsById[room_type_code] = {
        isInvalid: true,
        isRemoved: true,
        id: room_type_code,
        title: `${room_type_code}`,
        rates: {},
      };
    }

    if (mappingOptionsById[room_type_code]) {
      if (
        !mappingOptionsById[room_type_code].rates[rate_plan_code]
        || (isOccBasedMapping && !mappingOptionsById[room_type_code].rates[rate_plan_code].occupancies.includes(occupancy))
      ) {
        unknownMappingOptionsById[room_type_code] = unknownMappingOptionsById[room_type_code] || {
          ...mappingOptionsById[room_type_code],
          isInvalid: true,
          rates: {},
        };

        const unknownRate = {
          isInvalid: true,
          isRemoved: true,
          id: rate_plan_code,
          title: `${rate_plan_code}`,
        };

        if (isOccBasedMapping) {
          unknownRate.occupancy = occupancy;
        }

        unknownMappingOptionsById[room_type_code].rates[rate_plan_code] = unknownRate;
      }
    } else if (unknownMappingOptionsById[room_type_code]) {
      const unknownRate = {
        isInvalid: true,
        isRemoved: true,
        id: rate_plan_code,
        title: `${rate_plan_code}`,
      };

      if (isOccBasedMapping) {
        unknownRate.occupancy = occupancy;
      }

      unknownMappingOptionsById[room_type_code].rates[rate_plan_code] = unknownRate;
    }
  });

  const unknownRoomsWithRates = Object.values(unknownMappingOptionsById).map((room) => {
    if (room.rates) {
      room.rates = Object.values(room.rates);
    }

    return room;
  });

  return {
    rooms: unknownRoomsWithRates,
  };
};

export const useMultiOccupancyExtraValuesMapping = (sourceData) => {
  const {
    mappings,
    mappingSettings,
    ratePlans,
    roomTypes,
    onChangeSettings,
    onChangeMapping,
  } = sourceData;

  // in channel management for failed requests mappingOptions is set to null
  const mappingOptions = useMemo(() => sourceData.mappingOptions || {}, [sourceData.mappingOptions]);

  mappingSettings.rooms = mappingSettings.rooms || {};
  mappingOptions.rooms = mappingOptions.rooms || [];

  const { t } = useTranslation();
  const mappingsAsArray = useMemo(() => transformMappingsToArray(mappings), [mappings]);
  const mappingsPerExternalRoom = useMemo(() => buildMappingsPerExternalRoom(mappingsAsArray), [mappingsAsArray]);

  const unknownMappingOptions = useMemo(
    () => buildUnknownMappingOptions({ mappingsAsArray, mappingOptions, mappingSettings }),
    [mappingsAsArray, mappingOptions, mappingSettings],
  );

  const mappingItems = useMemo(
    () => buildMappingItems({ mappingOptions, unknownMappingOptions, mappingsPerExternalRoom, mappingSettings, roomTypes, ratePlans, t }),
    [mappingOptions, unknownMappingOptions, mappingsPerExternalRoom, mappingSettings, roomTypes, ratePlans, t],
  );

  const handleRoomMappingChange = useCallback(({ externalRoom, mapping: newMapping, prevMapping }) => {
    const newMappingSettings = {
      ...mappingSettings,
      rooms: {
        ...mappingSettings.rooms,
        [externalRoom.id]: newMapping.roomTypeId,
      },
    };

    // if mapping for room was changed we need to remove all rate mappings for that room
    if (prevMapping) {
      const newMappings = mappingsAsArray.filter((mapping) => {
        return mapping.settings.room_type_code !== externalRoom.id;
      });

      if (newMappings.length !== mappingsAsArray.length) {
        onChangeMapping(transformMappingsToObject(newMappings));
      }
    }

    onChangeSettings(newMappingSettings);
  }, [mappingSettings, mappingsAsArray, onChangeMapping, onChangeSettings]);

  const handleRoomMappingDelete = useCallback(({ externalRoom }) => {
    const newMappingSettings = {
      ...mappingSettings,
      rooms: {
        ...mappingSettings.rooms,
        [externalRoom.id]: null,
      },
    };

    // remove all rate mapping for that room
    const newMappings = mappingsAsArray.filter((mapping) => {
      return mapping.settings.room_type_code !== externalRoom.id;
    });

    if (newMappings.length !== mappingsAsArray.length) {
      onChangeMapping(transformMappingsToObject(newMappings));
    }

    onChangeSettings(newMappingSettings);
  }, [mappingSettings, mappingsAsArray, onChangeSettings, onChangeMapping]);

  const handlePrimaryOccChange = useCallback(({ mapping: newPrimaryOccMapping }) => {
    // room with several occupancies can have only one primary occupancy
    const newMappings = mappingsAsArray.map((mapping) => {
      // reset primary_occ for all rate plans in room
      if (newPrimaryOccMapping.settings.rate_plan_code === mapping.settings.rate_plan_code && newPrimaryOccMapping.settings.room_type_code === mapping.settings.room_type_code) {
        mapping.settings.primary_occ = false;
      }

      // set specified occupancy as primary
      if (newPrimaryOccMapping.settings.rate_plan_code === mapping.settings.rate_plan_code && newPrimaryOccMapping.settings.room_type_code === mapping.settings.room_type_code && newPrimaryOccMapping.settings.occupancy === mapping.settings.occupancy) {
        mapping.settings.primary_occ = true;
      }

      return mapping;
    });

    const mappingsAsObject = transformMappingsToObject(newMappings);
    onChangeMapping(mappingsAsObject);
  }, [mappingsAsArray, onChangeMapping]);

  const handleRateMappingChange = useCallback(({ mapping: newMapping, prevMapping }) => {
    let newMappings = mappingsAsArray;
    const isOccBasedMapping = typeof newMapping.settings.occupancy !== "undefined";

    if (prevMapping) {
      newMappings = mappingsAsArray.map((mapping) => {
        const isRoomAndRateMatch = mapping.settings.rate_plan_code === newMapping.settings.rate_plan_code && mapping.settings.room_type_code === newMapping.settings.room_type_code;
        const isOccBasedMappingMatch = isOccBasedMapping && mapping.settings.occupancy === newMapping.settings.occupancy;

        if (isRoomAndRateMatch && (isOccBasedMapping ? isOccBasedMappingMatch : true)) {
          return newMapping;
        }

        return mapping;
      });
    } else {
      if (isOccBasedMapping) {
        const externalRateMappingsForAllOccupancies = Object.values(
          mappingsPerExternalRoom?.[newMapping.settings.room_type_code]?.[newMapping.settings.rate_plan_code] || {},
        );
        const isPrimaryOccExistsInRatesMappings = externalRateMappingsForAllOccupancies.find((ms) => ms.settings.primary_occ);

        if (!isPrimaryOccExistsInRatesMappings) {
          newMapping.settings.primary_occ = true;
        }
      }

      newMappings.push(newMapping);
    }

    const mappingsAsObject = transformMappingsToObject(newMappings);
    onChangeMapping(mappingsAsObject);
  }, [mappingsAsArray, mappingsPerExternalRoom, onChangeMapping]);

  const handleRateMappingDelete = useCallback(({ mapping }) => {
    const mappingItemSettings = mapping.settings;
    const isOccBasedMapping = typeof mappingItemSettings.occupancy !== "undefined";

    let newMappings = mappingsAsArray;
    let needToUpdatePrimaryOcc = false;

    if (isOccBasedMapping && mappingItemSettings.primary_occ) {
      const externalRateMappingsForAllOccupancies = Object.values(
        mappingsPerExternalRoom?.[mappingItemSettings.room_type_code]?.[mappingItemSettings.rate_plan_code] || {},
      );

      if (externalRateMappingsForAllOccupancies.length > 1) {
        needToUpdatePrimaryOcc = true;
      }
    }

    newMappings = newMappings.filter(({ settings }) => {
      const isRoomAndRateMatch = settings.rate_plan_code === mappingItemSettings.rate_plan_code && settings.room_type_code === mappingItemSettings.room_type_code;
      const isOccBasedMappingMatch = isOccBasedMapping && settings.occupancy === mappingItemSettings.occupancy;

      return !(isRoomAndRateMatch && (isOccBasedMapping ? isOccBasedMappingMatch : true));
    });

    if (needToUpdatePrimaryOcc) {
      for (const newMapping of newMappings) {
        if (newMapping.settings.room_type_code === mappingItemSettings.room_type_code && newMapping.settings.rate_plan_code === mappingItemSettings.rate_plan_code) {
          newMapping.settings.primary_occ = true;
          break;
        }
      }
    }

    const mappingsAsObject = transformMappingsToObject(newMappings);

    onChangeMapping(mappingsAsObject);
  }, [mappingsAsArray, mappingsPerExternalRoom, onChangeMapping]);

  return {
    mappingItems,
    handleRoomMappingChange,
    handleRoomMappingDelete,
    handlePrimaryOccChange,
    handleRateMappingChange,
    handleRateMappingDelete,
  };
};
