import React from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import channelCodes from "config/constants/channels/channel_codes";

import SubmitButton from "components/forms/buttons/submit_button";
import GlobalErrors from "components/forms/global_errors";
import FormInput from "components/forms/inputs/hook_form/form_input";
import { FormListSelector } from "components/forms/inputs/hook_form/form_list_selector/form_list_selector";
import { FormRangePicker } from "components/forms/inputs/hook_form/form_rangepicker";
import FormSelect from "components/forms/inputs/hook_form/form_select";
import { FormWeekdays, WEEKDAY_VALUES } from "components/forms/inputs/hook_form/form_weekdays";
import InputGroup from "components/forms/inputs/input_group/input_group";
import { TextValue } from "components/forms/inputs/text_value/text_value";
import BottomActions from "components/forms/layout/drawer/bottom_actions/bottom_actions";
import useAppForm from "components/hook_form/use_app_form";

import { validationSchema } from "./validation_schema";

const parseToForm = (value) => {
  if (!value) {
    return {
      affectedDates: {
        weekdays: [...WEEKDAY_VALUES],
      },
    };
  }

  return {
    ...value,
    affectedDates: {
      weekdays: value.days || [...WEEKDAY_VALUES],
      range: [
        value.startDate ? dayjs(value.startDate) : null,
        value.endDate ? dayjs(value.endDate) : null,
      ],
    },
  };
};

const parseToModel = (value) => {
  const { affectedDates, ...rest } = value;
  const { weekdays, range: [startDate, endDate] } = affectedDates;

  return {
    ...rest,
    days: weekdays,
    startDate: startDate?.format("YYYY-MM-DD"),
    endDate: endDate?.format("YYYY-MM-DD") || null,
  };
};

export function AvailabilityRuleForm({ value, channels, roomTypes, isLoading, onSubmit }) {
  const { t } = useTranslation();
  const parsedValue = parseToForm(value);

  const TYPES = {
    close_out: t("general:restrictions:close_out"),
    availability_offset: t("general:restrictions:availability_offset"),
    max_availability: t("general:restrictions:max_availability"),
  };

  const { handleSubmit, errors, control, watch } = useAppForm({
    defaultValue: parsedValue,
    validationSchema,
    fieldNames: ["title", "affectedDates", "type", "value", "affectedChannels", "affectedRoomTypes"],
    submitHandler: (formValue) => {
      onSubmit(parseToModel(formValue));
    },
  });

  const isEdit = !!value?.id;
  const type = watch("type");
  const isValuePresent = ["availability_offset", "max_availability"].includes(type);

  const channelOptions = channels.map((channel) => {
    const channelConfig = channelCodes[channel.channel] || channelCodes.Unknown;

    return {
      id: channel.id,
      label: `${channel.title || "No Title"} (${channelConfig.title})`,
    };
  });
  const roomTypeOptions = roomTypes.map((roomType) => ({ id: roomType.id, label: roomType.title }));

  return (
    <div>
      <div>
        <GlobalErrors hookForm errors={errors} />

        {isEdit && (
          <TextValue
            label="Id"
            value={value.id}
            copy
          />
        )}
        <FormInput
          name="title"
          label="Title"
          errors={errors?.title?.message}
          control={control}
        />
        <InputGroup
          label="Affected Dates"
          direction="vertical"
        >
          <FormRangePicker
            grouped
            disabledDate={(current) => current.isBefore(dayjs().subtract(1, "day"))}
            name="affectedDates.range"
            allowEmpty={[false, true]}
            format="YYYY-MM-DD"
            control={control}
            errors={errors?.affectedDates?.range?.message}
          />
          <FormWeekdays
            grouped
            name="affectedDates.weekdays"
            control={control}
            errors={errors?.affectedDates?.weekdays?.message}
          />
        </InputGroup>
        <FormSelect
          name="type"
          label="Type"
          placeholder="Select Type"
          control={control}
          errors={errors?.type?.message}
          options={Object.entries(TYPES).map(([optionKey, optionValue]) => ({ value: optionKey, label: optionValue }))}
        />
        {isValuePresent && (
          <FormInput
            name="value"
            label="Value"
            errors={errors?.value?.message}
            control={control}
          />
        )}
        <FormListSelector
          name="affectedChannels"
          label="Affected Channels"
          control={control}
          errors={errors?.affectedChannels?.message}
          items={channelOptions}
        />
        <FormListSelector
          name="affectedRoomTypes"
          label="Affected Room Types"
          control={control}
          errors={errors?.affectedRoomTypes?.message}
          items={roomTypeOptions}
        />
      </div>

      <BottomActions>
        <SubmitButton onClick={() => { handleSubmit(); }} loading={isLoading}>
          Save
        </SubmitButton>
      </BottomActions>
    </div>
  );
}
