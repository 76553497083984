import React from "react";
import classNames from "classnames";

import Card from "./card";
import Details from "./details";

import styles from "./channel_total_score.module.css";

export default function ChannelTotalScore({ score, property, isMobile }) {
  const className = classNames(styles.score, {
    [styles.mobile]: isMobile,
  });

  return (
    <div className={className}>
      <Card score={score} property={property} />
      {!isMobile && <Details score={score} />}
    </div>
  );
}
