import React from "react";
import { useTranslation } from "react-i18next";

import MappingRow from "components/mapping/mapping_row";
import RateTitle from "components/rates/title";

export default function RemovedRate({ ratePlan, onMappingDelete }) {
  const { t } = useTranslation();
  const bookingComRate = { title: ratePlan.rate_plan_code, occupancy: ratePlan.occupancy };
  const { channexRate } = ratePlan;

  const handleMappingDelete = () => onMappingDelete(ratePlan);

  const externalRateTitle = (
    <>
      {t("channels_page:form:id")}
      <RateTitle rate={bookingComRate} />
    </>
  );

  return (
    <MappingRow
      isMapped
      title={externalRateTitle}
      action={<RateTitle rate={channexRate} />}
      onDelete={handleMappingDelete}
    />
  );
}
