export default function transformMappingsToArray(mappings) {
  const mappingArray = [];

  Object.entries(mappings).forEach(([ratePlanId, ratePlanMappings]) => {
    ratePlanMappings = Array.isArray(ratePlanMappings) ? ratePlanMappings : [ratePlanMappings];

    ratePlanMappings.forEach((ratePlanMapping) => {
      // rate plan mapping is null when deleted from TreeMapping component on UI
      if (!ratePlanMapping) {
        return;
      }

      const { id, ...mapping } = ratePlanMapping;

      mappingArray.push({
        mappingId: id,
        ratePlanId,
        mapping,
      });
    });
  });

  return mappingArray;
}
