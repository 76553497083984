import React from "react";
import { SortableElement } from "react-sortable-hoc";
import { Col } from "antd";

import styles from "../gallery.module.css";

export default SortableElement(({ width, value, key }) => (
  <Col className={styles.galleryPhoto} key={key} span={width}>
    {value}
  </Col>
));
