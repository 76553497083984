import React, { Component } from "react";
import store from "store";

import Loading from "components/loading";

import withComponentRef from "containers/with_component_ref";

import confirmDirtyStateSave from "utils/confirm_dirty_state_save";
import parseApiErrors from "utils/parse_api_errors";

const { Taxes, TaxSets } = store;

const withLogic = (FormComponent) => {
  class HOC extends Component {
    state = {
      loading: true,
      submitting: false,
      value: {},
      errors: {},
    };

    formRef = React.createRef();

    componentDidMount() {
      const { entityId, propertyId } = this.props;

      Taxes.list({ property_id: propertyId }, { page: 1, limit: 100 }, { title: "asc" });

      if (entityId) {
        this.loadTaxSet();
      } else {
        this.setState({ loading: false });
      }
    }

    loadTaxSet = () => {
      const { entityId } = this.props;

      TaxSets.find(entityId).then((value) => {
        this.setState({ value, loading: false });
      });
    };

    handleSubmit = () => {
      const { entityId, propertyId, onClose, onCreate = () => {} } = this.props;
      const { value } = this.state;

      const submitHandler = entityId ? TaxSets.update : TaxSets.create;

      this.setState({ submitting: true });

      this.formRef.current
        .validate()
        .then(() => {
          submitHandler({ ...value, property_id: propertyId })
            .then((result) => {
              onCreate(result);
              onClose();
            })
            .catch((error) => {
              if (!error.isValidationError) {
                throw error;
              }

              this.setState({ errors: parseApiErrors(error) });
            })
            .finally(() => {
              this.setState({ submitting: false });
            });
        })
        .catch(() => {
          this.setState({ submitting: false });
        });
    };

    handleChange = (value) => {
      this.setState({ value });
    };

    beforeClose = () => {
      const { current } = this.formRef;

      if (!current) {
        return Promise.resolve();
      }

      const { dirty } = current.formik.current;

      return confirmDirtyStateSave(dirty);
    };

    render() {
      const { value, loading, submitting, errors } = this.state;

      if (loading) {
        return <Loading />;
      }

      return (
        <FormComponent
          {...this.props}
          value={value}
          errors={errors}
          submitting={submitting}
          componentRef={this.formRef}
          onChange={this.handleChange}
          onSubmit={this.handleSubmit}
        />
      );
    }
  }

  return withComponentRef(HOC);
};

export default withLogic;
