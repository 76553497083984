import alphabetSort from "utils/alphabet_sort";

export default (object, sortKey) => {
  let result = null;

  if (object) {
    result = Object.values(object).sort(alphabetSort(sortKey));
  }

  return result;
};
