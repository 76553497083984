import defaultFlags from "config/feature_flags";

export default () => {
  const url = new URL(window.location.href);
  const flagString = url.searchParams.get("ff");
  const urlEnabledFlagsList = flagString ? flagString.split(",").map((entry) => [entry, true]) : [];
  const urlEnabledFlags = Object.fromEntries(urlEnabledFlagsList);

  return { ...defaultFlags, ...urlEnabledFlags };
};
