import { formatNumber } from "./format_number";

const formatter = Intl.NumberFormat(undefined, {
  notation: "compact",
});

const FORMAT_FROM = 1_000_000;

export const formatRate = (value) => {
  if (value >= FORMAT_FROM) {
    return formatter.format(value);
  }

  return formatNumber(value);
};
