import React from "react";

import FormCheckbox from "components/forms/inputs/formik/form_checkbox";

export default function ({ t, schema, namespace, field, inputRef, handleFieldChange }) {
  return (
    <FormCheckbox
      namespace={namespace}
      name={field}
      view="horizontal"
      placeholder={t(`channels_page:form:settings_${field}_placeholder`)}
      label={t(`channels_page:form:settings_${field}_label`)}
      onChange={handleFieldChange}
      type={schema.type}
      inputRef={inputRef}
    />
  );
}
