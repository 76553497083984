import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import classNames from "classnames";

import channelCodes from "config/constants/channels/channel_codes";
import eventTypeOptions from "config/constants/event_type_options";

import dayjs from "utils/dayjs";

import { MainSection } from "./common";

import styles from "./events_list.module.css";

function EventsListItemNewReview({ item }) {
  const { t } = useTranslation();
  const {
    event,
    payload: { ota, ota_reservation_id },
    inserted_at,
  } = item;

  const eventTitle = useMemo(() => {
    const findEventOption = eventTypeOptions.find((i) => i === event);
    if (findEventOption) {
      return t(`live_feed_events_widget:events:${findEventOption}`).toUpperCase();
    }
    return null;
  }, [t, event]);

  const insertedAtDateValue = dayjs(inserted_at).format(t("formats:date_time_with_weekday"));
  const insertedAtTimeValue = dayjs(inserted_at).format(t("formats:iso_time_with_seconds"));
  const eventTitleClassName = classNames(styles.event, styles[`event--${event}`]);

  const messageText = `${t("reviews_page:review:message")} ${channelCodes[ota].title}. ${t("bookings_page:booking_view_dialog:info:ota_reservation_id")}: ${ota_reservation_id}`;

  return (
    <MainSection>
      <p className={eventTitleClassName}>{eventTitle}</p>
      <p className={styles.message}>{messageText}</p>
      <p className={styles.description}>
        {insertedAtDateValue} <span>|</span> {insertedAtTimeValue}
      </p>
    </MainSection>
  );
}

EventsListItemNewReview.propTypes = {
  item: PropTypes.shape({}),
};

export default EventsListItemNewReview;
