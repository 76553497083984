import React, { memo } from "react";
import { Checkbox, Col, Row } from "antd";

import styles from "./item_selector.module.css";

export const ItemSelector = memo((props) => {
  const {
    id,
    level,
    title,
    indeterminate,
    selected,
    disabled,
    onItemToggle,
  } = props;

  return (
    <Row key={id} style={{ paddingLeft: level * 32 }} className={styles.itemRow}>
      <Col>
        <Checkbox
          onChange={onItemToggle(id)}
          indeterminate={indeterminate}
          checked={selected}
          disabled={disabled}
        >
          {title}
        </Checkbox>
      </Col>
    </Row>
  );
});
