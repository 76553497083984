import React from "react";

import { useAvailabilityRuleOptions } from "./use_availability_rule_options";

export function withAvailabilityRuleOptions(Component) {
  function WithAvailabilityRuleOptions(props) {
    const { data, error, isLoading } = useAvailabilityRuleOptions(props.propertyId || props.activeProperty);

    return <Component {...props} availabilityRuleOptions={data} availabilityRuleOptionsError={error} isAvailabilityRuleOptionsLoading={isLoading} />;
  }

  return WithAvailabilityRuleOptions;
}
