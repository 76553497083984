import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  CheckCircleOutlined,
  CloudSyncOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Button, Col, Form, Radio, Row, Space, Typography } from "antd";
import _ from "lodash";
import store from "store";

import useRatePlans from "data/use_rate_plans";
import useRoomTypes from "data/use_room_types";

import SubmitButton from "components/forms/buttons/submit_button";
import GlobalErrors from "components/forms/global_errors";
import FormCheckbox from "components/forms/inputs/form_checkbox";
import FormInput from "components/forms/inputs/form_input";
import FormSelect from "components/forms/inputs/form_select";
import Loading from "components/loading";

import classifyApiErrors from "utils/classify_api_errors";
import parseApiErrors from "utils/parse_api_errors";

import useApplicationAction from "./hooks/use_application_action";

import styles from "./shared.module.css";

const { Applications } = store;
const TAX_MODES = ["excluded", "included"];

const buildSettings = (roomTypes, ratePlans, installation) => {
  const defaultSettings = _.isEmpty(installation.settings) ? null : installation.settings;

  return _.reduce(roomTypes, (acc, roomType) => {
    const ratePlanIds = ratePlans[roomType.id]?.map((rP) => rP.id);
    const mappedRatePlans = Object.values(installation.ratePlans).filter((rP) => ratePlanIds?.includes(rP.ratePlanId));

    acc[roomType.id] = {
      code: mappedRatePlans[0]?.settings?.roomTypeCode,
      title: roomType.title,
      ratePlans: {},
    };

    acc[roomType.id].ratePlans = _.reduce(ratePlans[roomType.id], (accRP, ratePlan) => {
      const mappedRatePlan = mappedRatePlans.find((rP) => rP.ratePlanId === ratePlan.id);

      accRP[ratePlan.id] = {
        code: mappedRatePlan?.settings?.ratePlanCode,
        id: mappedRatePlan?.id,
        title: ratePlan.title,
      };

      return accRP;
    }, acc[roomType.id].ratePlans);

    return acc;
  }, defaultSettings || { hotelCode: null, clientId: null, clientSecret: null, taxMode: TAX_MODES[0], disableBookings: false });
};

const buildFieldNames = (roomTypes, ratePlans) => {
  return _.reduce(roomTypes, (acc, roomType) => {
    acc.push(`rooms.${roomType.id}.code`);

    _.forEach(ratePlans[roomType.id], (ratePlan) => {
      acc.push(`rooms.${roomType.id}.ratePlans.${ratePlan.id}.code`);
    });

    return acc;
  }, ["hotelCode"]);
};

function MappingStatus({ isApaleoMappingLoaded, isChannexMappingLoaded, apaleoData, channexMapping }) {
  if (!isApaleoMappingLoaded || !isChannexMappingLoaded || apaleoData.length === 0) {
    return null;
  }

  const apaleoRoomCodes = apaleoData.roomTypes.map((rt) => rt.id);
  const apaleoRatePlans = apaleoData.roomTypes.reduce((acc, rt) => {
    return acc.concat(rt.ratePlans.map((rp) => `${rt.id}${rp.id}`));
  }, []);
  const mappedApaleoRoomCodes = Object.values(channexMapping).map((r) => r?.code).filter((c) => !!c);
  const mappedApaleoRatePlanCodes = Object.values(channexMapping).reduce((acc, room) => {
    if (!room?.ratePlans) {
      return acc;
    }

    const apaleoRoomCode = room.code;

    return acc.concat(Object.values(room.ratePlans).map((rp) => rp.code).filter((c) => !!c).map((c) => {
      return `${apaleoRoomCode}${c}`;
    }));
  }, []);

  const unmappedApaleoRoomCodes = apaleoRoomCodes.filter((c) => !mappedApaleoRoomCodes.includes(c));
  const unmappedApaleoRatePlanCodes = apaleoRatePlans.filter((c) => !mappedApaleoRatePlanCodes.includes(c));

  return (
    <div>
      {unmappedApaleoRoomCodes.length > 0
        ? (
          <div>
            <ExclamationCircleOutlined style={{ color: "#faad14" }} /> <Typography.Text type="secondary">Unmapped Apaleo Rooms: {unmappedApaleoRoomCodes.length}</Typography.Text>
          </div>
        )
        : (
          <div>
            <CheckCircleOutlined style={{ color: "#52c41a" }} /> <Typography.Text type="secondary">All Apaleo Rooms mapped</Typography.Text>
          </div>
        )}
      {unmappedApaleoRatePlanCodes.length > 0
        ? (
          <div>
            <ExclamationCircleOutlined style={{ color: "#faad14" }} /> <Typography.Text type="secondary">Unmapped Apaleo Rate Plans: {unmappedApaleoRatePlanCodes.length}</Typography.Text>
          </div>
        )
        : (
          <div>
            <CheckCircleOutlined style={{ color: "#52c41a" }} /> <Typography.Text type="secondary">All Apaleo Rate Plans mapped</Typography.Text>
          </div>
        )}
    </div>
  );
}

const ApaleoSettings = forwardRef(({ installation, embedded = false, onClose, onApaleoMappingLoaded }, ref) => {
  const { propertyId } = installation;

  const { t } = useTranslation();
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [settings, setSettings] = useState(null);

  const {
    handleSubmit,
    reset,
    formState: { errors },
    clearErrors,
    setError,
    watch,
    control,
    setValue,
  } = useForm();

  const values = watch();
  const { taxMode, disableBookings } = values;

  const { data, refetch: loadMappingDetails, isSuccess: isApaleoMappingLoaded, isLoading: isApaleoMappingInitiallyLoading, isFetching: isApaleoMappingLoading } = useApplicationAction({
    appCode: "apaleo",
    action: "mapping_details",
    query: { propertyId, hotelCode: values.hotelCode },
  }, {
    enabled: false,
    retry: false,
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    cacheTime: 0,
    onSuccess: () => {
      if (onApaleoMappingLoaded) {
        onApaleoMappingLoaded();
      }
    },
  });

  const { data: properties, refetch: loadProperties, isSuccess: isApaleoPropertiesLoaded } = useApplicationAction({
    appCode: "apaleo",
    action: "get_properties",
    query: { propertyId },
  }, {
    enabled: false,
    retry: false,
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    cacheTime: 0,
  });

  const { refetch: fullSync, isFetching: isApaleoFullSyncLoading } = useApplicationAction({
    appCode: "apaleo",
    action: "full_sync",
    query: { propertyId },
  }, {
    enabled: false,
    retry: false,
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    cacheTime: 0,
  });

  const { data: subscription, refetch: loadSubscription, isSuccess: isApaleoSubscriptionLoaded } = useApplicationAction({
    appCode: "apaleo",
    action: "get_subscription",
    query: { propertyId },
  }, {
    enabled: false,
    retry: false,
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    cacheTime: 0,
  });

  useEffect(() => {
    if (!data || data.length === 0) {
      return;
    }

    const apaleoRoomCodes = data.roomTypes.map((rt) => rt.id);
    const apaleoRatePlans = data.roomTypes.reduce((acc, rt) => {
      return acc.concat(rt.ratePlans.map((rp) => `${rt.id}${rp.id}`));
    }, []);

    Object.entries(values).forEach(([roomTypeId, room]) => {
      if (!room?.code) {
        return;
      }

      if (!apaleoRoomCodes.includes(room.code)) {
        setValue(`${roomTypeId}.code`, null);
      }

      Object.entries(room.ratePlans).forEach(([ratePlanId, ratePlan]) => {
        if (!ratePlan.code) {
          return;
        }

        const apaleoRatePlanCode = `${room.code}${ratePlan.code}`;

        if (!apaleoRatePlans.includes(apaleoRatePlanCode)) {
          setValue(`${roomTypeId}.ratePlans.${ratePlanId}.code`, null);
        }
      });
    }, []);
  }, [data, properties, subscription, values, setValue]);

  const { ratePlans, isLoading: isRatePlansLoading } = useRatePlans(propertyId, { multiOccupancy: true, groupResults: true });
  const { roomTypes, isLoading: isRoomTypesLoading } = useRoomTypes(propertyId, { sorted: true });
  const [isChannexMappingLoaded, setIsChannexMappingLoaded] = useState(false);

  useEffect(() => {
    if (isRatePlansLoading || isRoomTypesLoading) {
      return;
    }

    const initialSettings = buildSettings(roomTypes, ratePlans, installation);

    if (settings === null) {
      setSettings(initialSettings);
    }

    reset(initialSettings);
    setIsChannexMappingLoaded(true);
  }, [roomTypes, ratePlans, isRatePlansLoading, isRoomTypesLoading, installation, settings, reset]);

  const isLoadButtonEnabled = !!settings?.userId || (!!settings?.hotelCode && !!settings?.clientId && !!settings?.clientSecret);
  const connectedApp = !!settings?.userId;

  useEffect(() => {
    if (values && values.hotelCode && isLoadButtonEnabled) {
      loadMappingDetails();
    }
  }, [values.hotelCode, isLoadButtonEnabled]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isLoadButtonEnabled) {
      loadMappingDetails();
      loadProperties();
      loadSubscription();
    }
  }, [isLoadButtonEnabled]); // eslint-disable-line react-hooks/exhaustive-deps

  const formFieldNames = useMemo(() => {
    if (isRatePlansLoading || isRoomTypesLoading) {
      return [];
    }

    return buildFieldNames(roomTypes, ratePlans);
  }, [roomTypes, ratePlans, isRatePlansLoading, isRoomTypesLoading]);

  const submit = (settingsData) => {
    settingsData.taxMode = settings.taxMode;
    settingsData.disableBookings = settings.disableBookings;

    const updatedSettings = _.omitBy(settingsData, (value, _key) => value?.title);

    const applicationRatePlans = Object.values(settingsData).reduce((acc, room) => {
      if (!room?.code) {
        return acc;
      }

      return Object.entries(room.ratePlans).reduce((accRatePlans, [ratePlanId, rP]) => {
        if (!rP?.code) {
          return accRatePlans;
        }

        return [
          ...accRatePlans,
          {
            id: rP.id,
            rate_plan_id: ratePlanId,
            settings: {
              room_type_code: room.code,
              rate_plan_code: rP.code,
            },
          },
        ];
      }, []).concat(acc);
    }, []);

    setSaveInProgress(true);

    return Applications
      .update({ ...installation, settings: updatedSettings, rate_plans: applicationRatePlans, ratePlans: null })
      .then(onClose)
      .catch((error) => {
        if (!error.isValidationError) {
          throw error;
        }

        const parsedErrors = parseApiErrors(error);
        const { formErrors, globalErrors } = classifyApiErrors(parsedErrors, formFieldNames);

        setError("global", globalErrors);
        Object.entries(formErrors).forEach(([key, value]) => {
          setError(key, value);
        });

        // to rely on parent promises control flow, catch should throw something to not resolve promise
        if (embedded) {
          throw error;
        }
      })
      .finally(() => setSaveInProgress(false));
  };

  useImperativeHandle(ref, () => ({
    submit: () => {
      clearErrors();
      return handleSubmit(submit)();
    },
  }));

  if (isRatePlansLoading || isRoomTypesLoading) {
    return <Loading />;
  }

  const isMappingLoaded = isApaleoMappingLoaded && data;
  const isPropertiesLoaded = isApaleoPropertiesLoaded && properties && properties.length > 0;
  const isSubscriptionLoaded = isApaleoSubscriptionLoaded && subscription && subscription.id;

  const availableApaleoRoomTypes = (roomTypeId) => {
    if (!isMappingLoaded || data.length === 0) {
      return [];
    }

    const mappedCodeToRoomTypeId = values?.[roomTypeId]?.code;
    const usedCodes = Object.values(values).map((r) => r?.code).filter((c) => !!c);

    const availableCodes = data.roomTypes
      .filter((r) => {
        return !(usedCodes.includes(r.id) && r.id !== mappedCodeToRoomTypeId);
      });

    return availableCodes.map(({ id, title }) => ({ value: id, representation: title }));
  };

  const roomRateOptions = (roomTypeId) => {
    if (!values?.[roomTypeId]?.code || data.length === 0) {
      return [];
    }

    const mappedApaleoRoomCode = values[roomTypeId].code;
    const apaleoRoom = data.roomTypes.find((r) => r.id === mappedApaleoRoomCode);

    if (!apaleoRoom) {
      return [];
    }

    return (apaleoRoom?.ratePlans || []).map(({ id, title }) => ({ value: id, representation: title }));
  };

  const propertyOptions = () => {
    return (properties || []).map(({ id, title }) => ({ value: id, representation: title }));
  };

  return (
    <div>
      <Form>
        <GlobalErrors errors={errors.global} />

        {(embedded && isApaleoMappingInitiallyLoading) && (
          <div style={{ display: "flex", justifyContent: "center", padding: "24px 0" }}>
            <Loading centerPlacement={false} />
          </div>
        )}

        {embedded || (
          <Row>
            <Col span={11} className={styles.roomTitle}>
              {t("applications_page:apaleo:hotel_code")}
            </Col>
            <Col span={1} className={styles.arrow}>
              &rarr;
            </Col>
            <Col span={12} className={styles.inputCol}>
              <Controller
                name="hotelCode"
                control={control}
                render={({ field }) => (
                  <div style={{ display: "flex" }}>

                    {isPropertiesLoaded && (
                      <FormSelect
                        style={{ flex: 1 }}
                        hookForm
                        allowClear
                        view="fullWidth"
                        placeholder={t("applications_page:apaleo:hotel_code")}
                        errors={errors.hotelCode}
                        disabled={!isPropertiesLoaded}
                        options={propertyOptions()}
                        {...field}
                        onChange={(e) => {
                          field.onChange(e || null);
                        }}
                      />
                    )}

                    {isPropertiesLoaded || (
                    <FormInput
                      style={{ flex: 1 }}
                      hookFormq
                      view="fullWidth"
                      placeholder={t("applications_page:apaleo:hotel_code")}
                      errors={errors.hotelCode}
                      {...field}
                    />
                    )}
                    <div style={{ lineHeight: "40px" }}>
                      <Button
                        disabled={!isLoadButtonEnabled}
                        loading={isApaleoMappingLoading}
                        type="primary"
                        icon={isApaleoMappingLoading ? <LoadingOutlined /> : <CloudSyncOutlined />}
                        onClick={() => {
                          loadMappingDetails();
                        }}
                      >
                        Load data
                      </Button>
                    </div>
                  </div>
                )}
              />
            </Col>
          </Row>
        )}

        {connectedApp || (
          <Row>
            <Col span={11} className={styles.roomTitle}>
              {t("applications_page:apaleo:client_id")}
            </Col>
            <Col span={1} className={styles.arrow}>
              &rarr;
            </Col>
            <Col span={12} className={styles.inputCol}>
              <Controller
                name="clientId"
                control={control}
                render={({ field }) => (
                  <FormInput
                    hookForm
                    view="fullWidth"
                    placeholder={t("applications_page:apaleo:client_id")}
                    errors={errors.hotelCode}
                    {...field}
                  />
                )}
              />
            </Col>
          </Row>
        )}

        {connectedApp || (
          <Row>
            <Col span={11} className={styles.roomTitle}>
              {t("applications_page:apaleo:client_secret")}
            </Col>
            <Col span={1} className={styles.arrow}>
              &rarr;
            </Col>
            <Col span={12} className={styles.inputCol}>
              <Controller
                name="clientSecret"
                control={control}
                render={({ field }) => (
                  <FormInput
                    hookForm
                    view="fullWidth"
                    placeholder={t("applications_page:apaleo:client_secret")}
                    errors={errors.hotelCode}
                    {...field}
                  />
                )}
              />
            </Col>
          </Row>
        )}

        <Row>
          <Col span={11} className={styles.roomTitle}>
            {t("applications_page:apaleo:tax_mode")}
          </Col>
          <Col span={1} className={styles.arrow}>
            &rarr;
          </Col>

          {taxMode && (

          <Col span={12} className={styles.inputCol}>
            <Radio.Group defaultValue={taxMode}>
              <Space direction="vertical">
                {TAX_MODES.map((tM) => (
                  <div key={tM}>
                    <Radio
                      value={tM}
                      onChange={(e) => {
                        settings.taxMode = e.target.value;
                      }}
                    >
                      {t(`applications_page:apaleo:tax_mode_${tM}`)}
                    </Radio>
                  </div>
                ))}
              </Space>
            </Radio.Group>
          </Col>
          )}
        </Row>

        <Row>
          <Col span={11} className={styles.roomTitle}>
            {t("applications_page:apaleo:disable_bookings")}
          </Col>
          <Col span={1} className={styles.arrow}>
            &rarr;
          </Col>

          <Col span={12} className={styles.inputCol}>
            <FormCheckbox
              view="horizontal"
              name="disable_bookings"
              onChange={(checked) => {
                settings.disableBookings = checked;
              }}
              defaultValue={disableBookings}
            />
          </Col>
        </Row>

        {isSubscriptionLoaded && (
        <Row>
          <Col span={11} className={styles.roomTitle}>
            {t("applications_page:apaleo:subscription_id")}
          </Col>
          <Col span={1} className={styles.arrow}>
            &rarr;
          </Col>
          <Col span={12} className={styles.roomTitle}>
            {subscription.id}
          </Col>
        </Row>
        )}

        <MappingStatus
          isApaleoMappingLoaded={isApaleoMappingLoaded}
          isChannexMappingLoaded={isChannexMappingLoaded}
          apaleoData={data}
          channexMapping={values}
        />

        {isMappingLoaded && (
          <>
            <Row className={styles.columnHeaders}>
              <Col span={11}>{t("applications_page:apaleo:channel_manager")}</Col>
              <Col span={1}>&rarr;</Col>
              <Col span={12}>{t("applications_page:apaleo:apaleo")}</Col>
            </Row>

            {roomTypes.map((roomType) => (
              <div key={roomType.id}>
                <Row>
                  <Col span={11} className={styles.roomTitle}>
                    {roomType.title}
                  </Col>
                  <Col span={1} className={styles.arrow}>
                    &rarr;
                  </Col>
                  <Col span={12} className={styles.inputCol}>
                    <Controller
                      name={`${roomType.id}.code`}
                      control={control}
                      render={({ field }) => (
                        <FormSelect
                          hookForm
                          allowClear
                          view="fullWidth"
                          placeholder={t("applications_page:apaleo:room_type_code")}
                          errors={errors[`${roomType.id}.code`]}
                          disabled={availableApaleoRoomTypes(roomType.id).length === 0}
                          options={availableApaleoRoomTypes(roomType.id)}
                          {...field}
                          onChange={(e) => {
                            Object.keys(values[roomType.id].ratePlans).forEach((rpId) => {
                              setValue(`${roomType.id}.ratePlans.${rpId}.code`, null);
                            });

                            field.onChange(e || null);
                          }}
                        />
                      )}
                    />
                  </Col>
                </Row>

                {!ratePlans[roomType.id] && <Typography.Text type="secondary">No Rate Plan</Typography.Text>}

                {ratePlans[roomType.id] && ratePlans[roomType.id].map((ratePlan) => {
                  return (
                    <Row key={ratePlan.id}>
                      <Col span={11} className={styles.rateTitle}>
                        {ratePlan.title} ({ratePlan.occupancy})
                      </Col>
                      <Col span={1} className={styles.arrow}>
                        &rarr;
                      </Col>
                      <Col span={12} className={styles.inputCol}>
                        <Controller
                          name={`${roomType.id}.ratePlans.${ratePlan.id}.code`}
                          control={control}
                          render={({ field }) => (
                            <FormSelect
                              hookForm
                              allowClear
                              view="fullWidth"
                              placeholder={t("applications_page:apaleo:rate_plan_code")}
                              errors={errors[`${roomType.id}.ratePlans.${ratePlan.id}.code`]}
                              disabled={roomRateOptions(roomType.id).length === 0}
                              options={roomRateOptions(roomType.id)}
                              {...field}
                              onChange={(e) => {
                                field.onChange(e || null);
                              }}
                            />
                          )}
                        />
                      </Col>
                    </Row>
                  );
                })}
              </div>
            ))}
          </>
        )}
      </Form>

      {(isMappingLoaded && !embedded) && (
        <div style={{ float: "right" }}>
          <Button
            disabled={!isLoadButtonEnabled && !isMappingLoaded}
            loading={isApaleoFullSyncLoading}
            onClick={() => {
              fullSync();
            }}
          >
            Full Sync
          </Button>
        </div>
      )}

      {!embedded && (
        <div className={styles.actions}>
          <SubmitButton onClick={() => { clearErrors(); handleSubmit(submit)(); }} loading={saveInProgress}>
            {t("applications_page:apaleo:save")}
          </SubmitButton>
        </div>
      )}
    </div>
  );
});

export default ApaleoSettings;
