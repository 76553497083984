import { useEffect } from "react";
import { useSelector } from "react-redux";
import store from "store";
import { getUser } from "store/storage/selectors/session_selector";
import relationshipsExtractor from "store/utils/relationships_extractor";

import EventEmitter from "utils/event_emitter";

const { ws } = store;

export default function useUserChannelSubscription() {
  const user = useSelector(getUser);

  useEffect(
    function initUserChannelWSListeners() {
      const userChannel = ws.socket.channel(`user:${user.id}`);
      userChannel.join();

      userChannel.on("property_created", (payload) => {
        const newProperty = relationshipsExtractor(payload.data);

        EventEmitter.trigger("property:created", newProperty);
      });

      userChannel.on("property_updated", (payload) => {
        const updatedProperty = relationshipsExtractor(payload.data);

        EventEmitter.trigger("property:updated", updatedProperty);
      });

      userChannel.on("property_deleted", (payload) => {
        EventEmitter.trigger("property:deleted", payload.id);
      });

      userChannel.on("live_feed_event_created", (payload) => EventEmitter.trigger("live_feed_event:created", payload.data));

      userChannel.on("bookings_export_started", (payload) => {
        EventEmitter.trigger("bookings_export_started", payload);
      });

      userChannel.on("bookings_export_progress", (payload) => {
        EventEmitter.trigger("bookings_export_progress", payload);
      });

      userChannel.on("bookings_export_complete", (payload) => {
        EventEmitter.trigger("bookings_export_complete", payload);
      });

      userChannel.on("bookings_export_failed", (payload) => {
        EventEmitter.trigger("bookings_export_failed", payload);
      });

      userChannel.on("pdf_completed", (payload) => {
        EventEmitter.trigger("pdf_completed", payload);
      });

      userChannel.on("pdf_failed", (payload) => {
        EventEmitter.trigger("pdf_failed", payload);
      });

      return () => {
        userChannel.leave();
      };
    },
    [user],
  );
}
