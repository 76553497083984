import React from "react";
import { UserOutlined } from "@ant-design/icons";
import { Checkbox, Col, Row } from "antd";

import styles from "./rate_plan_selector.module.css";

function RatePlanSelector(props) {
  const {
    ratePlan,
    selected,
    disabled,
    onRatePlanToggle,
  } = props;

  return (
    <Row key={ratePlan.id} className={styles.rateRow}>
      <Col>
        <Checkbox
          onChange={onRatePlanToggle(ratePlan.id, ratePlan.room_type_id)}
          checked={selected}
          disabled={disabled}
        >
          {ratePlan.title} <UserOutlined />
          {ratePlan.occupancy}
        </Checkbox>
      </Col>
    </Row>
  );
}

export default React.memo(RatePlanSelector);
