import React from "react";

import SpinContainer from "../spin_container";

import styles from "./with_toolbar.module.css";

export default function WithToolbar({ toolbar, children, loading }) {
  return (
    <div className={styles.container}>
      <div className={styles.toolbar}>{toolbar}</div>
      <SpinContainer spinning={loading} className={styles.content}>
        {children}
      </SpinContainer>
    </div>
  );
}
