/* eslint-disable no-shadow */
import { GROUPS_ADD, GROUPS_DROP, GROUPS_LOAD, GROUPS_LOAD_OPTIONS } from "../constants";

const initialState = {
  entities: null,
  options: null,
};

const ACTION_HANDLERS = {
  [GROUPS_LOAD]: (state, action) => {
    const entities = action.payload.reduce((acc, el) => {
      acc[el.id] = el.attributes;
      if (el.relationships) {
        Object.keys(el.relationships).forEach((key) => {
          if (Array.isArray(el.relationships[key].data)) {
            acc[el.id][key] = el.relationships[key].data
              .map((el) => el.attributes)
              .reduce((acc, el) => {
                acc[el.id] = el;
                return acc;
              }, {});
          } else {
            acc[el.id][`${key}_id`] = el.relationships[key].data.id;
          }
        });
      }
      return acc;
    }, {});

    return { ...state, entities };
  },
  [GROUPS_ADD]: (state, action) => {
    const item = action.payload.attributes;

    if (action.payload.relationships) {
      Object.keys(action.payload.relationships).forEach((key) => {
        if (Array.isArray(action.payload.relationships[key].data)) {
          item[key] = action.payload.relationships[key].data
            .map((el) => el.attributes)
            .reduce((acc, el) => {
              acc[el.id] = el;
              return acc;
            }, {});
        } else {
          item[`${key}_id`] = action.payload.relationships[key].data.id;
        }
      });
    }

    const entities = { ...state.entities, [action.payload.id]: item };

    return { ...state, entities };
  },
  [GROUPS_DROP]: (state, action) => {
    const entities = Object.keys(state.entities)
      .filter((key) => {
        return key !== action.payload.id;
      })
      .reduce((acc, key) => {
        acc[key] = state.entities[key];
        return acc;
      }, {});

    return { ...state, entities };
  },
  [GROUPS_LOAD_OPTIONS]: (state, action) => {
    return {
      ...state,
      options: action.payload,
    };
  },
};

export default function groupsReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
