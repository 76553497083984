import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import ChannexDrawerRoutable from "drawers/channex_drawer_routable";

import WebhookLogView from "components/webhooks/log_view";

function WebhookLogDrawer() {
  const { t } = useTranslation();
  const { webhookId, webhookLogId } = useParams();
  const title = t("webhook_log_drawer:title");

  return (
    <ChannexDrawerRoutable title={title} dataCy="webhook_log_drawer">
      {() => <WebhookLogView webhookId={webhookId} webhookLogId={webhookLogId} />}
    </ChannexDrawerRoutable>
  );
}

export default WebhookLogDrawer;
