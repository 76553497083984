import React from "react";
import { Controller } from "react-hook-form";
import { Col, DatePicker, Form, Row } from "antd";

import getFieldLayout from "../utils/get_field_layout";

import styles from "../../../../styles/form_in_drawer.module.css";

const { RangePicker } = DatePicker;

const RIGHT_ARROW = (
  <span
    style={{
      display: "inline-block",
      paddingTop: "2px",
      width: "14px",
      fill: "rgba(0, 0, 0, 0.25)",
    }}
  >
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Layer_1" viewBox="0 0 476.213 476.213" xmlSpace="preserve">
      <polygon points="345.606,107.5 324.394,128.713 418.787,223.107 0,223.107 0,253.107 418.787,253.107 324.394,347.5 345.606,368.713 476.213,238.106 " />
    </svg>
  </span>
);
export function FormRangePicker({ name, label, errors, control, grouped, ...inputProps }) {
  const formItemLayout = grouped ? {} : getFieldLayout();

  const validation = () => {
    if (!errors) {
      return {};
    }

    const message = Array.isArray(errors) ? errors.join(". ") : errors;

    return {
      validateStatus: "error",
      help: <span data-cy="input_error_message">{message}</span>,
    };
  };

  return (
    <Row>
      <Col span={24}>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Form.Item
              {...formItemLayout}
              {...validation(errors)}
              label={label}
              data-cy={`rangepicker_${name}`}
            >
              <RangePicker
                className={styles.buttonGroup}
                separator={RIGHT_ARROW}

                {...inputProps}
                {...field}
              />
            </Form.Item>
          )}
        />
      </Col>
    </Row>
  );
}
