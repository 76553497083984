import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Empty, notification, Space, Typography } from "antd";
import store from "store";

import Flexbox from "components/flexbox";

const { Channels } = store;

export default function EnableRatePlans({ channelId, onChannelRefresh }) {
  const { t } = useTranslation();
  const [isActionProcessing, setIsActionProcessing] = React.useState(false);

  const enableRatePlans = () => {
    setIsActionProcessing(true);

    const errorNotificationMessage = t("airbnb_rate_plans:error_messages:unable_to_enable");

    Channels.airbnbEnableRatePlans(channelId)
      .then(() => {
        onChannelRefresh();
      })
      .catch(() => {
        setIsActionProcessing(false);
        notification.error({ message: "Error", description: errorNotificationMessage });
      });
  };

  return (
    <Flexbox justifyContent="center" style={{ height: "100%", overflow: "auto", padding: "0 20%" }}>
      <Empty
        description={(
          <Space direction="vertical">
            <Typography.Text type="secondary">{t("airbnb_rate_plans:enable_text")}</Typography.Text>
            <Button type="primary" loading={isActionProcessing} onClick={() => enableRatePlans()}>{t("airbnb_rate_plans:actions:enable")}</Button>
          </Space>
        )}
      />
    </Flexbox>
  );
}
