import { useSelector } from "react-redux";

export default function AppModeRenderer({ visible, children }) {
  const session = useSelector((store) => store.session);
  const isAppModeMatches = visible.includes(session.appMode);

  if (!isAppModeMatches) {
    return null;
  }

  return children;
}
