const isRateHaveDefinedOccupancies = (rate) => Array.isArray(rate.occupancies) && rate.occupancies.length;

const buildRatesPerOccupancy = (rate) => {
  const { max_persons: _maxPersons, occupancies, ...rateProps } = rate;

  return occupancies.map((occupancy) => ({ ...rateProps, occupancy }));
};

function buildRatesForMaxPersonsAndSinglePerson(rate, t) {
  const rates = [];
  const { max_persons, occupancies: _occupancies, ...rateProps } = rate;

  // TODO: can we have max_persons: 1 with price_1 set?
  // in that case rateForMaxPersons and single Rate looks the same
  const rateForMaxPersons = { ...rateProps, occupancy: max_persons };

  rates.push(rateForMaxPersons);

  // is rate should have separate single occupancy mapping
  if (rateProps.price_1) {
    const singleRate = {
      ...rateProps,
      occupancy: 1,
      title: `${rateForMaxPersons.title} - ${t("channels_page:form:single_occupancy")}`,
    };

    rates.push(singleRate);
  }

  return rates;
}

export const buildMultiOccupancyRatesList = (rates, t) => {
  const generatedRates = rates.reduce((acc, rate) => {
    if (isRateHaveDefinedOccupancies(rate)) {
      return acc.concat(buildRatesPerOccupancy(rate));
    }

    return acc.concat(buildRatesForMaxPersonsAndSinglePerson(rate, t));
  }, []);

  return generatedRates;
};
