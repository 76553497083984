import React from "react";
import { useMediaQuery } from "react-responsive";
import { MenuOutlined } from "@ant-design/icons";

import styles from "./logo.module.css";

export default function Logo({ onLogoClick, onMenuClick }) {
  const isMobile = useMediaQuery({ query: "(max-width: 1275px)" });

  if (isMobile) {
    return (
      <MenuOutlined className={styles.toggle} onClick={onMenuClick} />
    );
  }

  return (
    <img
      src="/assets/logo_small.svg"
      onClick={onLogoClick}
      className={styles.logo}
      alt="Logo"
    />
  );
}
