import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import classNames from "classnames";

import eventTypeOptions from "config/constants/event_type_options";

import dayjs from "utils/dayjs";

import { MainSection } from "./common";

import styles from "./events_list.module.css";

function EventsListItemRateError({ item }) {
  const { t } = useTranslation();
  const { event, payload, inserted_at } = item;
  const { error_type, channel_name } = payload;

  const eventTitle = useMemo(() => {
    const findEventOption = eventTypeOptions.find((i) => i === event);
    if (findEventOption) {
      return t(`live_feed_events_widget:events:${findEventOption}`).toUpperCase();
    }
    return null;
  }, [t, event]);

  const insertedAtDateValue = dayjs(inserted_at).format(t("formats:date_time_with_weekday"));
  const insertedAtTimeValue = dayjs(inserted_at).format(t("formats:iso_time_with_seconds"));
  const eventTitleClassName = classNames(styles.event, styles[`event--${event}`]);

  const error = t(`live_feed_events_widget:errors:${error_type}`);
  const channelName = channel_name ? `${channel_name},` : "";

  return (
    <MainSection>
      <p className={eventTitleClassName}>{eventTitle}</p>
      <p className={styles.arrivalDate}>
        {channelName} {error}
      </p>
      <p className={styles.description}>
        {insertedAtDateValue} <span>|</span> {insertedAtTimeValue}
      </p>
    </MainSection>
  );
}

EventsListItemRateError.propTypes = {
  item: PropTypes.shape({}),
};

export default EventsListItemRateError;
