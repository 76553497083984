import { createContext, useContext } from "react";

import useWebSocket from "./use_web_socket";

const WebSocketContext = createContext(null);
export function useWebSocketContext() {
  return useContext(WebSocketContext);
}

export function WebSocketProvider({ children, ...params }) {
  const ws = useWebSocket(params);

  return (
    <WebSocketContext.Provider value={ws}>
      {children}
    </WebSocketContext.Provider>
  );
}
