import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import GroupRateFieldComponent from "components/forms/groups/group_rate_field_component";
import InputChildrenFee from "components/forms/items/input_children_fee";
import InputInfantFee from "components/forms/items/input_infant_fee";

function GroupRateSettings(props) {
  const { t } = useTranslation();
  const { errors, disabled, model, onChange } = props;

  return (
    <>
      <GroupRateFieldComponent {...props} />
      {model.sell_mode === "per_person" && (
        <>
          <InputChildrenFee
            t={t}
            errors={errors}
            disabled={disabled}
            model={model}
            onChange={onChange}
          />
          <InputInfantFee
            t={t}
            errors={errors}
            disabled={disabled}
            model={model}
            onChange={onChange}
          />
        </>
      )}
    </>
  );
}

GroupRateSettings.propTypes = {
  t: PropTypes.func,
  model: PropTypes.object,
  errors: PropTypes.object,
  onChange: PropTypes.func,
};

export default GroupRateSettings;
