import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";

export default function BulkUpdateButton({ onClick }) {
  const { t } = useTranslation();

  return (
    <Button data-cy="bulk_update_button" onClick={onClick} type="primary">
      {t("general:action:bulk_update")}
    </Button>
  );
}
