import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Modal } from "antd";
import { FormikProvider, useFormik } from "formik";
import _ from "lodash";
import store from "store";

import FormikFormSelect from "components/forms/inputs/formik/form_select";

const { AdminBillingAccounts } = store;

function AssignBillingAccountModal({ propertyId, billingAccountId, onSave, onClose }) {
  const { t } = useTranslation();
  const [billingAccounts, setBillingAccounts] = useState([
    {
      value: billingAccountId,
      representation: t("properties_sub_page:assign_billing_account_form:loading"),
    },
  ]);
  const [loading, setLoading] = useState(true);

  const formik = useFormik({
    initialValues: {
      propertyId,
      billingAccountId,
    },
  });

  useEffect(() => {
    AdminBillingAccounts.options().then((response) => {
      setBillingAccounts(
        response.map((el) => {
          return { value: el.id, representation: el.companyName };
        }),
      );
      setLoading(false);
    });
  }, []);

  const onOk = () => {
    onSave(formik.values);
  };

  const onCancel = () => {
    onClose();
  };

  return (
    (
      <Modal
        title={t("properties_sub_page:assign_billing_account_form:title")}
        open="true"
        onOk={onOk}
        onCancel={onCancel}
        okButtonProps={{
          disabled: loading,
        }}
      >
        <FormikProvider value={formik}>
          <Form>
            <FormikFormSelect
              name="billingAccountId"
              placeholder={t("properties_sub_page:assign_billing_account_form:label")}
              label={t("properties_sub_page:assign_billing_account_form:label")}
              options={billingAccounts}
              loading={loading}
              disabled={loading}
            />
          </Form>
        </FormikProvider>
      </Modal>
    )
  );
}

export default AssignBillingAccountModal;
