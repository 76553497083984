import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import store from "store";
import { getInstalledAppsByPropertyId } from "store/storage/selectors/applications_selector";
import { getActivePropertyId } from "store/storage/selectors/session_selector";

import { ChosenPropertyRequiredError } from "components/errors/chosen_property_required_error";
import * as Page from "components/page";

import showErrorMessageFromResponse from "utils/show_error_message_from_response";
import useBoolState from "utils/use_bool_state";

import ApplicationsPageView from "./applications_view";
import mergeAppLists from "./merge_app_lists";

const { Applications, BillingAccounts } = store;

function ApplicationsPage({ user }) {
  const [loading, setLoading, setLoadingComplete] = useBoolState(false);
  const [appList, setAppList] = useState([]);
  const [billingAccount, setBillingAccount] = useState(null);
  const activeProperty = useSelector(getActivePropertyId);
  const installedAppsList = useSelector(getInstalledAppsByPropertyId(activeProperty));

  const loadBillingAccount = () => {
    BillingAccounts.info().then(
      (billingAccountResponse) => {
        setBillingAccount(billingAccountResponse);
      },
      (_error) => {},
    );
  };

  useEffect(
    function loadAppList() {
      setLoading();
      loadBillingAccount();

      Applications.list()
        .then(setAppList)
        .catch(showErrorMessageFromResponse)
        .finally(setLoadingComplete);
    },
    [setLoading, setLoadingComplete],
  );

  const handleAppInstall = useCallback(
    (applicationId) => {
      return Applications.install(applicationId, activeProperty);
    },
    [activeProperty],
  );

  const handleAppUninstall = useCallback((installationId) => {
    return Applications.uninstall(installationId);
  }, []);

  const mergedAppList = useMemo(() => {
    return mergeAppLists(appList, installedAppsList);
  }, [appList, installedAppsList]);

  const availableApps = useMemo(() => {
    return mergedAppList.filter((app) => !app.propertyId);
  }, [mergedAppList]);

  const installedApps = useMemo(() => {
    return mergedAppList.filter((app) => app.propertyId);
  }, [mergedAppList]);

  const hasActiveSubscription = () => {
    return billingAccount?.subscription?.isActive === true;
  };

  const isAdmin = () => {
    return user.system_role === "admin";
  };

  const isStaging = () => {
    return ["staging.channex.io", "localhost"].includes(window.location.hostname);
  };

  const canManageApplications = () => {
    return hasActiveSubscription() || isAdmin() || isStaging();
  };

  if (!activeProperty) {
    return (
      <Page.Main>
        <ChosenPropertyRequiredError />
      </Page.Main>
    );
  }

  const closePath = ""; // TODO: where

  return (
    <Page.Main>
      <ApplicationsPageView
        loading={loading}
        installedApps={installedApps}
        availableApps={availableApps}
        onInstall={handleAppInstall}
        onUninstall={handleAppUninstall}
        canManageApplications={canManageApplications()}
      />

      <Outlet context={{ closePath }} />
    </Page.Main>
  );
}

const mapStateToProps = ({ user }) => ({
  user,
});

export default connect(mapStateToProps)(ApplicationsPage);
