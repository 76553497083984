import React from "react";
import { useTranslation } from "react-i18next";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";

import { Actions } from "components/crud_table/columns/actions/actions";
import Legend from "components/forms/layout/legend/legend";
import Loading from "components/loading";
import { removeMenuItem } from "components/menu/items/remove";
import NoDataPlaceholder from "components/no_data_placeholder";

import { useActiveProperty } from "hooks/use_active_property";
import useDrawer from "hooks/use_drawer";

import { CreateCategoryDrawer } from "./create_category_drawer/create_category_drawer";
import { CreateExtraDrawer } from "./create_extra_drawer/create_extra_drawer";
import { EditCategoryDrawer } from "./edit_category_drawer/edit_category_drawer";
import { EditExtraDrawer } from "./edit_extra_drawer/edit_extra_drawer";
import { useDeleteCategoryAction } from "./use_delete_category_action";
import { useDeleteExtraAction } from "./use_delete_extra_action";
import { useExtras } from "./use_extras";

const Row = ({ paddingLeft = 0, children }) => {
  return (
    <div
      style={{
        display: "flex",
        borderBottom: "1px solid #f0f0f0",
        padding: "0 16px",
        height: "55px",
        alignContent: "center",
        flexWrap: "wrap",
        paddingLeft,
      }}
    >
      {children}
    </div>
  );
};

const ExtraCategoryRow = ({ entity, onEditClick, onAddExtraClick, onDeleteClick }) => {
  return (
    <Row paddingLeft={20}>
      <div style={{ flex: 1, padding: "8px 0" }}>{entity.title}</div>
      <Actions
        items={[{
          key: "edit",
          onClick: () => onEditClick(entity),
          label: "Edit",
        }, {
          key: "add_extra",
          onClick: () => onAddExtraClick(entity),
          label: "Add Extra",
        }, {
          type: "divider",
        },
        removeMenuItem({
          icon: false,
          onRemove: () => onDeleteClick(entity),
        }),
        ]}
      />
    </Row>
  );
};

const ExtraRow = ({ entity, onEditClick, onDeleteClick }) => {
  return (
    <Row paddingLeft={40}>
      <div style={{ flex: 1, padding: "8px 0" }}>{entity.title}</div>
      <Actions
        items={[{
          key: "edit",
          onClick: () => onEditClick(entity),
          label: "Edit",
        }, {
          type: "divider",
        },
        removeMenuItem({
          icon: false,
          onRemove: () => onDeleteClick(entity),
        }),
        ]}
      />
    </Row>
  );
};

export function ExtrasList({ propertyId }) {
  const { t } = useTranslation();

  const { isOpen: isCreateCategoryDrawerOpen, open: openCreateCategoryDrawer, close: closeCreateCategoryDrawer } = useDrawer();
  const { payload: categoryEditDrawerPayload, isOpen: isEditCategoryDrawerOpen, open: openEditCategoryDrawer, close: closeEditCategoryDrawer } = useDrawer();
  const { payload: extraCreateDrawerPayload, isOpen: isCreateExtraDrawerOpen, open: openCreateExtraDrawer, close: closeCreateExtraDrawer } = useDrawer();
  const { payload: extraEditDrawerPayload, isOpen: isEditExtraDrawerOpen, open: openEditExtraDrawer, close: closeEditExtraDrawer } = useDrawer();
  const { mutateAsync: deleteCategoryAction } = useDeleteCategoryAction(propertyId);
  const { mutateAsync: deleteExtraAction } = useDeleteExtraAction(propertyId);

  const query = useExtras(propertyId, { plainList: true });
  const activeProperty = useActiveProperty();

  const handleDeleteCategoryClick = (record) => {
    return deleteCategoryAction({ id: record.id });
  };

  const handleEditCategoryClick = (record) => {
    openEditCategoryDrawer(record);
  };

  const handleAddCategoryClick = () => {
    openCreateCategoryDrawer();
  };

  const handleAddExtraClick = (extraCategory) => {
    openCreateExtraDrawer({ extraCategoryId: extraCategory.id });
  };

  const handleEditExtraClick = (extra) => {
    openEditExtraDrawer(extra);
  };

  const handleDeleteExtraClick = (extra) => {
    return deleteExtraAction({ id: extra.id });
  };

  return (
    <>
      <Legend marginBottom={false}>
        {t("property_extras:titles:extras")}
        <Button
          key="create-extra-category"
          icon={<PlusCircleOutlined />}
          type="link"
          onClick={handleAddCategoryClick}
        >
          {t("general:action:create")}
        </Button>
      </Legend>

      {(query.isLoading || activeProperty.isLoading) && (
        <Loading />
      )}

      {(query.isFetched && query.data.length === 0) && (
        <div>
          <NoDataPlaceholder
            emptyMessage={t("property_extras:messages:no_extras_created")}
            type="button"
            createMessageText={t("general:create_first_before")}
            createMessageActionText={t("general:create_first_link")}
            onClick={handleAddCategoryClick}
          />
        </div>
      )}
      {query.data?.map((extra) => (
        extra.type === "category"
          ? (
            <ExtraCategoryRow
              key={extra.id}
              entity={extra}
              onEditClick={handleEditCategoryClick}
              onDeleteClick={handleDeleteCategoryClick}
              onAddExtraClick={handleAddExtraClick}
            />
          )
          : (
            <ExtraRow
              key={extra.id}
              entity={extra}
              onEditClick={handleEditExtraClick}
              onDeleteClick={handleDeleteExtraClick}
            />
          )
      ))}

      <CreateCategoryDrawer
        visible={isCreateCategoryDrawerOpen}
        propertyId={propertyId}
        onClose={closeCreateCategoryDrawer}
      />
      <EditCategoryDrawer
        visible={isEditCategoryDrawerOpen}
        propertyId={propertyId}
        extraCategory={categoryEditDrawerPayload}
        onClose={closeEditCategoryDrawer}
      />
      <CreateExtraDrawer
        visible={isCreateExtraDrawerOpen}
        propertyId={propertyId}
        currency={activeProperty.data?.currency}
        extraCategoryId={extraCreateDrawerPayload?.extraCategoryId}
        onClose={closeCreateExtraDrawer}
      />
      <EditExtraDrawer
        visible={isEditExtraDrawerOpen}
        propertyId={propertyId}
        extra={extraEditDrawerPayload}
        onClose={closeEditExtraDrawer}
      />
    </>
  );
}
