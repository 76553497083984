import React, { memo } from "react";

import { MappingDeleteButton } from "components/mapping/mapping_row/mapping_delete_button";
import RoomTitle from "components/rooms/room_title";

import { MappingRow } from "../mapping_row";

import { MappingSelect } from "./mapping_select";

import styles from "../styles.module.css";

export const RoomMappingRow = memo(({
  externalRoom,
  mapping,
  roomTypeOptions,
  onChange,
  onDelete,
}) => {
  const roomTypeId = mapping?.roomTypeId;
  const isMapped = !!roomTypeId;

  return (
    <MappingRow
      source={
        <RoomTitle room={externalRoom} />
      }
      target={(
        <div className={styles.column__withActions}>
          <div className={styles.columnContent}>
            <MappingSelect
              value={roomTypeId}
              options={roomTypeOptions}
              onChange={(value) => {
                if (value === roomTypeId) {
                  return; // no change
                }

                const newMapping = {
                  roomTypeId: value,
                };
                onChange({ externalRoom, mapping: newMapping, prevMapping: mapping });
              }}
            />
          </div>
          <div className={styles.columnActions}>
            <MappingDeleteButton visible={isMapped} onClick={() => onDelete({ externalRoom, mapping })} />
          </div>
        </div>
      )}
    />
  );
});
