import PhotoCard from "./photo_card/photo_card";
import PhotoCardMeta from "./photo_card_meta/photo_card_meta";
import PhotoCardMetaForm from "./photo_card_meta_form";
import PhotoCardUploadButton from "./photo_card_upload_button";

PhotoCard.Meta = PhotoCardMeta;
PhotoCard.MetaForm = PhotoCardMetaForm;
PhotoCard.UploadButton = PhotoCardUploadButton;

export default PhotoCard;
