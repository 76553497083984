import React from "react";
import { useTranslation } from "react-i18next";
import { Checkbox } from "antd";

import styles from "./select_all_input.module.css";

export default function SelectAllInput({ checked, onChange }) {
  const { t } = useTranslation();

  const handleChange = () => onChange(!checked);

  return (
    <Checkbox
      data-cy="select_all_rates_checkbox"
      checked={checked}
      className={styles.select}
      onChange={handleChange}
    >
      {t("channels_page:form:select_all")}
    </Checkbox>
  );
}
