import dayjs from "dayjs";
import i18n from "i18next";

export default () => {
  return [7, 14, 30, 90].map((days) => {
    return {
      label: i18n.t(`reports:ranges:last_${days}_days`),
      value: [dayjs().add(-days, "d"), dayjs()],
    };
  });
};
