import {
  WHITE_LABEL_PARTNERS_ADD,
  WHITE_LABEL_PARTNERS_DROP,
  WHITE_LABEL_PARTNERS_LOAD,
} from "../constants";

export function whiteLabelPartnersLoad(storage) {
  return function (whiteLabelPartners) {
    storage.dispatch({ type: WHITE_LABEL_PARTNERS_LOAD, payload: whiteLabelPartners });
  };
}

export function whiteLabelPartnersAdd(storage) {
  return function (whiteLabelPartner) {
    storage.dispatch({ type: WHITE_LABEL_PARTNERS_ADD, payload: whiteLabelPartner });
  };
}

export function whiteLabelPartnersDrop(storage) {
  return function (whiteLabelPartner) {
    storage.dispatch({ type: WHITE_LABEL_PARTNERS_DROP, payload: whiteLabelPartner });
  };
}
