import React from "react";

import { ExtraForm } from "../../extra_form/extra_form";

import { useCreateAction } from "./use_create_action";

export function CreateExtraForm({ propertyId, currency, extraCategoryId, onFinish }) {
  const { mutateAsync, isLoading } = useCreateAction(propertyId);

  const handleSubmit = async (formValues) => {
    const attrs = { ...formValues };
    attrs.propertyId = propertyId;
    attrs.extraCategoryId = extraCategoryId;

    const result = await mutateAsync({ attrs });
    onFinish(result);
  };

  return (
    <ExtraForm
      currency={currency}
      isLoading={isLoading}
      onSubmit={handleSubmit}
    />
  );
}
