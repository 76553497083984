import React from "react";

import styles from "./action_bar.module.css";

export default function ActionBar({ title, actions, content }) {
  return (
    <div className={styles.container}>
      <div className={styles.title}>{title}</div>

      {actions && <div className={styles.actions}>{actions}</div>}
      {content && <div className={styles.content}>{content}</div>}
    </div>
  );
}
