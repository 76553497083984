import attributesExtractor from "../utils/attributes_extractor";
import { convertToCamelCase, convertToSnakeCase } from "../utils/case_converter";

let transport;

const ENDPOINT = "channel_availability_rules";

export default class AvailabilityRules {
  constructor(container) {
    transport = container.transport;
  }

  list(filter = {}, pagination = { page: 1, limit: 10 }, order) {
    const normalizedFilters = convertToSnakeCase(filter);

    return transport
      .send("GET", ENDPOINT, { filter: normalizedFilters, pagination, order })
      .then(convertToCamelCase)
      .then(({ data, meta }) => {
        return {
          data: attributesExtractor(data),
          meta,
        };
      });
  }

  all(filter = {}) {
    const normalizedFilters = convertToSnakeCase(filter);

    return transport
      .send("GET", `${ENDPOINT}/options`, { filter: normalizedFilters })
      .then(convertToCamelCase)
      .then(({ data }) => {
        return attributesExtractor(data);
      });
  }

  create(propertyId, attrs) {
    const convertedAttrs = convertToSnakeCase({
      channelAvailabilityRule: {
        ...attrs,
        propertyId,
      },
    });

    return transport
      .send("POST", ENDPOINT, convertedAttrs)
      .then(convertToCamelCase)
      .then((response) => {
        return attributesExtractor(response.data);
      });
  }

  update(id, attrs) {
    const body = convertToSnakeCase({
      channelAvailabilityRule: attrs,
    });

    return transport
      .send("PUT", `${ENDPOINT}/${id}`, body)
      .then(convertToCamelCase)
      .then((response) => {
        return attributesExtractor(response.data);
      });
  }

  delete(id) {
    return transport.send("DELETE", `${ENDPOINT}/${id}`);
  }
}
