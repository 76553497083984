import React, { Component } from "react";
import { Table } from "antd";
import _ from "lodash";

import styles from "./mapping_preview.module.css";

class MappingPreview extends Component {
  render() {
    const { t, settings, parserOptions, mappingSchema } = this.props;

    const fieldValue = _.get(settings, "mapping");

    const preview = Object.keys(parserOptions.mapping).map((mappingKey) => {
      const schemaKey = mappingKey.replace("_dictionary", "");
      const title = mappingSchema[schemaKey].title;

      const values = fieldValue[mappingKey] && fieldValue[mappingKey].values;

      if (!values) {
        return (
          <div className={styles.item}>
            <div>{title}</div>
            <div>no data</div>
          </div>
        );
      }

      const columns = [
        {
          title: t("mapping:columns:id"),
          key: "id",
          dataIndex: "id",
        },
        {
          title: t("mapping:columns:title"),
          key: "title",
          dataIndex: "title",
        },
      ];

      return (
        <div className={styles.item} key={mappingKey}>
          <div>{title}</div>
          <Table
            columns={columns}
            dataSource={values}
            rowKey="id"
            showHeader={false}
            bordered={false}
            size="small"
          />
        </div>
      );
    });

    return <div className={styles.container}>{preview}</div>;
  }
}

export default MappingPreview;
