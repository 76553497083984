import React from "react";
import { Collapse } from "antd";

import Room from "./room";

export default function RoomTypes({ roomTypes }) {
  const items = roomTypes.map((roomType, index) => ({
    key: index.toString(),
    label: roomType.title,
    children: <Room room={roomType} />,
  }));

  return (
    <Collapse items={items} accordion />
  );
}
