import React, { Component } from "react";
import Media from "react-media";
import { Col } from "antd";

import GallerySortableContainer from "./components/gallery_sortable_container";
import GallerySortableItem from "./components/gallery_sortable_item";

import styles from "./gallery.module.css";

const DEFAULT_ROW_CAPACITY = 24;
const COLUMN_NUMBER_WIDE = 3;
const COLUMN_NUMBER_MIDDLE = 2;
const COLUMN_NUMBER_NARROW = 1;

class Gallery extends Component {
  state = {
    numberOfColumns: null,
  };

  handleMediaChange = (numberOfColumns) => (matches) => {
    if (!matches) {
      return;
    }

    this.setState({ numberOfColumns });
  };

  getColumnWidth = () => {
    const { numberOfColumns } = this.state;

    return DEFAULT_ROW_CAPACITY / numberOfColumns;
  };

  renderSortablePhotoList = () => {
    const { children, uploadButton, onSortEnd } = this.props;

    const columnWidth = this.getColumnWidth();

    const photoList = React.Children.map(children, (child, index) => {
      const { id } = child.props;

      return (
        <GallerySortableItem
          width={columnWidth}
          index={index}
          value={child}
          key={`gallery_sortable_photo_${id}`}
        />
      );
    });

    const wrappedUploadButton = uploadButton && (
      <Col span={columnWidth} className={styles.galleryPhoto} key="gallery-upload-button">
        {uploadButton}
      </Col>
    );

    return (
      <GallerySortableContainer
        helperClass={styles.sortableHelper}
        items={[...photoList, wrappedUploadButton]}
        axis="xy"
        useDragHandle
        onSortEnd={onSortEnd}
      />
    );
  };

  render() {
    return (
      <div className={styles.photosContainer}>
        <Media query="(max-width: 499px)" onChange={this.handleMediaChange(COLUMN_NUMBER_NARROW)} />
        <Media
          query="(min-width: 500px) and (max-width: 719px)"
          onChange={this.handleMediaChange(COLUMN_NUMBER_MIDDLE)}
        />
        <Media query="(min-width: 720px)" onChange={this.handleMediaChange(COLUMN_NUMBER_WIDE)} />
        {this.renderSortablePhotoList()}
      </div>
    );
  }
}

export default Gallery;
