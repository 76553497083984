import React from "react";

import { ExtraCategoryForm } from "../../extra_category_form/extra_category_form";

import { useUpdateAction } from "./use_update_action";

export function EditExtraCategoryForm({ propertyId, value, closeDrawer }) {
  const { mutateAsync, isLoading } = useUpdateAction(propertyId);

  const handleSubmit = async (formValues) => {
    const { id, ...attrs } = formValues;
    attrs.propertyId = propertyId;

    const result = await mutateAsync({ id, attrs });
    closeDrawer(result);
  };

  return (
    <ExtraCategoryForm
      value={value}
      isLoading={isLoading}
      onSubmit={handleSubmit}
    />
  );
}
