import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { notification } from "antd";
import store from "store";

import parseApiErrors from "utils/parse_api_errors";

import ChangePasswordFormView from "./change_password_form_view";

const { Profiles } = store;

class ChangePasswordForm extends Component {
  state = {
    value: {},
    errors: {},
    loading: false,
  };

  formRef = createRef();

  componentDidMount() {
    this.resetForm();
  }

  resetForm = () => {
    const { id } = this.props.user;
    const value = {
      id,
      currentPassword: "",
      password: "",
      passwordConfirmation: "",
    };

    this.setState({ value, errors: {} });
  };

  handleFormChange = (value) => {
    this.setState({ value });
  };

  handleSubmit = () => {
    const { value } = this.state;

    this.setState({ loading: true });

    this.formRef.current
      .validate()
      .then(() => {
        const payloadToSend = {
          id: value.id,
          currentPassword: value.currentPassword,
          password: value.password,
          passwordConfirmation: value.passwordConfirmation,
        };

        Profiles.update(payloadToSend)
          .then(() => {
            notification.success({
              message: "Success",
              description: "Password Changed",
            });

            this.resetForm();
            this.formRef.current.formik.current.resetForm();
          })
          .catch((error) => {
            if (!error.isValidationError) {
              throw error;
            }

            this.setState({ errors: parseApiErrors(error) });
          })
          .finally(() => {
            this.setState({ loading: false });
          });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { value, errors, loading } = this.state;

    return (
      <ChangePasswordFormView
        value={value}
        errors={errors}
        loading={loading}
        componentRef={this.formRef}
        onChange={this.handleFormChange}
        onSubmit={this.handleSubmit}
      />
    );
  }
}

const mapStateToProps = ({ user }) => ({ user });

export default connect(mapStateToProps)(ChangePasswordForm);
