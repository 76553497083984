import React from "react";

import RateTitle from "components/rates/title";

export default function TitleWithId({ className, rate = {} }) {
  const parsedRate = rate;

  if (parsedRate.id.includes(":")) {
    const [id, occupancy] = parsedRate.id.split(":");

    parsedRate.id = id;
    parsedRate.occupancy = occupancy;
  }

  const rateId = parsedRate.id ? ` (${parsedRate.id})` : "";
  const rateTitleWithId = `${parsedRate.title}${rateId}`;
  const updatedRate = { ...parsedRate, title: rateTitleWithId };

  return <RateTitle className={className} rate={updatedRate} />;
}
