import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import style from "./title.module.css";

class Title extends Component {
  render() {
    const { t } = this.props;

    return (
      <div className={style.widgetHeader}>
        <div className={style.widgetHeader__title}>{t("announcements_widget:header")}</div>
      </div>
    );
  }
}

export default withTranslation()(Title);
