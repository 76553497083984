import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Empty } from "antd";

import CHANNELS_WITHOUT_SETTINGS from "config/constants/channels_without_settings";

import RefreshButton from "components/forms/buttons/refresh_button";
import Layouts from "components/layouts";
import MappingDirection from "components/mapping/mapping_direction";
import MappingListSettings from "components/mapping/mapping_list_settings";

import MappingRow from "./components/mapping_row";

class DirectMapping extends Component {
  state = {
    mappingDialogs: {},
    tempMappings: {},
  };

  openMappingDialog = (rateId) => {
    this.setState({ mappingDialogs: { [rateId]: true } });
  };

  removeMapping = (rateId) => {
    const { mappings, onChangeMapping } = this.props;

    onChangeMapping({
      ...mappings,
      [rateId]: null,
    });
  };

  handleApplyMapping = (rateId) => {
    const { mappings, onChangeMapping } = this.props;
    const { tempMappings, mappingDialogs } = this.state;

    if (tempMappings[rateId]) {
      this.setState({
        mappingDialogs: { ...mappingDialogs, [rateId]: false },
        tempMappings: {},
      });

      onChangeMapping({ ...mappings, [rateId]: { ...tempMappings[rateId] } });
    } else {
      this.setState({ mappingDialogs: { ...mappingDialogs, [rateId]: false } });
    }
  };

  handlePopupDeleteClick = (rateId) => {
    this.removeMapping(rateId);
    this.closePopup(rateId);
  };

  closePopup = (rateId) => {
    const { mappingDialogs } = this.state;

    this.setState({
      mappingDialogs: { ...mappingDialogs, [rateId]: false },
    });

    // when popup closes there is an animation duration which shows form
    // we need to delay form values erase by that animation duration to prevent form being empty while popup is still closing
    setTimeout(() => {
      this.setState({ tempMappings: {} });
    }, 200);
  };

  handleChangeTempMappings = (tempMappings) => {
    this.setState({ tempMappings });
  };

  renderToolbar = () => {
    const {
      mappingSettings,
      onChangeSettings,
      onRefresh,
      channelCode,
    } = this.props;
    const { reverseMapping } = mappingSettings;

    return (
      <>
        <RefreshButton onClick={onRefresh} />
        {CHANNELS_WITHOUT_SETTINGS.includes(channelCode) ? null : (
          <MappingListSettings
            mappingSettings={mappingSettings}
            onChangeSettings={onChangeSettings}
            reorderEnabled={reverseMapping}
          />
        )}
      </>
    );
  };

  render() {
    const {
      t,
      loading,
      mappingSettings,
      channelTitle,
      channelProperties,
      ratesTree,
      ratePlans,
      mappings,
      mappingOptions,
      mappingSchema,
    } = this.props;
    const { mappingDialogs, tempMappings } = this.state;
    const { reverseMapping } = mappingSettings;

    return (
      <Layouts.WithToolbar toolbar={this.renderToolbar()} loading={loading}>
        <>
          <MappingDirection channelTitle={channelTitle} reverse={reverseMapping} />
          {channelProperties
            && ratesTree.map(({ children: rooms, ...property }) => (
              <div key={property.id}>
                <h3 style={{ paddingBottom: 0 }}>{property.title}</h3>

                {rooms.length === 0 && (
                  <Empty description={t("channels_page:form:message:no_rooms")} />
                )}
                {rooms.map(({ children: rates, ...room }) => (
                  <div key={room.id}>
                    <h4 style={{ paddingTop: 8 }}>{room.title}</h4>
                    {rates.length === 0 && (
                      <Empty description={t("channels_page:form:message:no_rates")} />
                    )}
                    {rates.map((rate) => (
                      <MappingRow
                        primary
                        key={rate.id}
                        popupOpen={mappingDialogs[rate.id] || false}
                        room={room}
                        rate={rate}
                        ratePlans={ratePlans}
                        mappings={mappings}
                        mappingOptions={mappingOptions}
                        tempMappings={tempMappings}
                        mappingSchema={mappingSchema}
                        onChangeTempMappings={this.handleChangeTempMappings}
                        handlePopupDeleteClick={this.handlePopupDeleteClick}
                        openMappingDialog={this.openMappingDialog}
                        closePopup={this.closePopup}
                        handleApplyMapping={this.handleApplyMapping}
                        removeMapping={this.removeMapping}
                      />
                    ))}
                  </div>
                ))}
              </div>
            ))}
        </>
      </Layouts.WithToolbar>
    );
  }
}

export default withTranslation()(DirectMapping);
