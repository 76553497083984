import * as yup from "yup";

import errorMessages from "config/constants/errors";

export const validationSchema = yup.object().shape({
  title: yup.string().trim().required(errorMessages.required()),
  priceMode: yup.string().trim().required(errorMessages.required()),
  pricePerUnit: yup.number()
    .moreThan(-1, errorMessages.positive())
    .required(errorMessages.required())
    .typeError(errorMessages.number()),
});
