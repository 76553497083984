import React from "react";
import { useTranslation } from "react-i18next";

import List from "components/list";

export default function Room({ room }) {
  const { t } = useTranslation();

  const {
    capacity,
    content,
    countOfRooms,
    facilities,
    occAdults,
    occChildren,
    occInfants,
    roomKind,
    title,
  } = room;

  const facilitiesList = facilities.join(", ");

  return (
    <List>
      <List.Item term={t("rooms_page:form:title_label")} description={title} />
      <List.Item term={t("rooms_page:form:count_of_rooms_label")} description={countOfRooms} />
      <List.Item term={t("rooms_page:form:capacity_label")} description={capacity} />
      <List.Item term={t("rooms_page:form:occ_adults_label")} description={occAdults} />
      <List.Item term={t("rooms_page:form:occ_children_label")} description={occChildren} />
      <List.Item term={t("rooms_page:form:occ_infants_label")} description={occInfants} />
      <List.Item
        term={t("rooms_page:form:room_kind_label")}
        description={t(`rooms_page:form:room_kind_options:${roomKind}`)}
      />
      <List.Item
        term={t("rooms_page:content:description_label")}
        description={content.description}
      />
      <List.Item term={t("rooms_page:form:facilities_label")} description={facilitiesList} />
    </List>
  );
}
