import { convertToCamelCase } from "store/utils/case_converter";

let transport;
let storage;

const ENDPOINT = "scores";

export default class Scores {
  constructor(container) {
    transport = container.transport;
    storage = container.storage;
  }

  score(id) {
    return transport.send("GET", `${ENDPOINT}/${id}`).then((response) => {
      const data = convertToCamelCase(response.data);
      storage.scoresAdd(data);
      return data;
    });
  }

  scoreDetailed(id) {
    return transport.send("GET", `${ENDPOINT}/${id}/detailed`).then((response) => {
      const data = convertToCamelCase(response.data);
      storage.scoresAdd(data);
      return data;
    });
  }
}
