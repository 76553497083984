import React, { Component } from "react";
import { Field } from "formik";

import { FormRateSelector } from "components/forms/inputs/form_rate_selector/form_rate_selector";

class FormikRateSelector extends Component {
  render() {
    const {
      name,
      ...props
    } = this.props;

    return (
      <Field name={name}>
        {({ field, form }) => {
          return (
            <FormRateSelector
              {...props}
              {...field}
              onBlur={() => form.setFieldTouched(name, true)}
              onChange={(value) => {
                return form.setFieldValue(field.name, value);
              }}
            />
          );
        }}
      </Field>
    );
  }
}

export default FormikRateSelector;
