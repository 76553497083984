import React from "react";
import { useTranslation } from "react-i18next";
import { DeleteOutlined } from "@ant-design/icons";
import { Button } from "antd";

function RemoveButton({ onClick, className }) {
  const { t } = useTranslation();

  return (
    <Button
      danger
      type="primary"
      className={className}
      icon={<DeleteOutlined />}
      onClick={onClick}
    >
      {t("general:action:remove")}
    </Button>
  );
}

export default RemoveButton;
