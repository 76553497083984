import React from "react";
import { Button, Modal } from "antd";
import store from "store";
import attributesExtractor from "store/utils/attributes_extractor";
import { convertToCamelCase } from "store/utils/case_converter";

import CRUDTable from "components/crud_table";
import * as Page from "components/page";

import useDrawer from "hooks/use_drawer";

import UserSelectDrawer from "./user_select_drawer";

const { AdminApplications } = store;

export default function ManageAccessForm({ value }) {
  const [data, setData] = React.useState([]);

  const { isOpen: isDrawerOpen, open: openDrawer, close: closeDrawer } = useDrawer();

  if (!value) {
    return null;
  }

  const loadData = async () => {
    const response = await AdminApplications.listUsers(value.id);

    let users = convertToCamelCase(response.data);
    users = users.map(attributesExtractor);

    setData(users);

    return data;
  };

  const onRemove = (record) => {
    Modal.confirm({
      title: "Revoke Access",
      content: `Are you sure you want to revoke access for ${record.email}?`,
      onOk: async () => {
        await AdminApplications.revokeUserAccess({ applicationId: value.id, userId: record.id });
        loadData();
      },
    });
  };

  return (
    <Page.Main noMargin>
      <Page.Content>
        <CRUDTable.Container>
          <CRUDTable
            data={data}
            actions={(
              <Button type="primary" onClick={() => { openDrawer(); }}>Grant Access</Button>
            )}
            columns={() => [{
              title: "Email",
              dataIndex: "email",
              key: "email",
            }, {
              title: "Name",
              dataIndex: "name",
              key: "name",
            }, {
              title: "Actions",
              dataIndex: "actions",
              key: "actions",
              align: "right",
              render: (_value, record) => (
                <Button type="link" onClick={() => onRemove(record)}>
                  Remove Access
                </Button>
              ),
            }]}
            emptyMessage="No users have access to application"
            showCreateMessage={false}
            loadData={loadData}
          />
        </CRUDTable.Container>
      </Page.Content>

      <UserSelectDrawer
        visible={isDrawerOpen}
        onClose={(result) => {
          if (result) {
            loadData();
          }

          closeDrawer();
        }}
        applicationId={value.id}
      />
    </Page.Main>
  );
}
