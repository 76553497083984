import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { CheckCircleOutlined, EllipsisOutlined, SolutionOutlined } from "@ant-design/icons";
import { Dropdown } from "antd";

import APP_MODES from "config/constants/app_modes";

import useAppMode from "hooks/use_app_mode";

import styles from "./chat_header_drop_down.module.css";

function ChatHeaderDropDown({ handleCloseThread, handleOpenThread, handleOpenBooking, isClosed }) {
  const { t } = useTranslation();
  const { isAppMode } = useAppMode();

  const items = [];

  if (isAppMode(APP_MODES.DEFAULT) && handleOpenBooking) {
    items.push({
      key: "messages_page_dialog_open_booking",
      onClick: handleOpenBooking,
      label: (
        <div>
          <SolutionOutlined />
          {t("messages_page:dialog:open_booking")}
        </div>
      ),
    });
  }

  if (isClosed) {
    items.push({
      key: "messages_page_dialog_open_conversation",
      onClick: handleOpenThread,
      label: (
        <div>
          <CheckCircleOutlined />
          {t("messages_page:dialog:open_conversation")}
        </div>
      ),
    });
  } else {
    items.push({
      key: "messages_page_dialog_close_conversation",
      onClick: handleCloseThread,
      label: (
        <div>
          <CheckCircleOutlined />
          {t("messages_page:dialog:close_conversation")}
        </div>
      ),
    });
  }

  return (
    <Dropdown menu={{ items }} trigger={["click"]}>
      <div className={styles.trigger}>
        <EllipsisOutlined />
      </div>
    </Dropdown>
  );
}

ChatHeaderDropDown.propTypes = {
  handleCloseThread: PropTypes.func,
  handleOpenThread: PropTypes.func,
  handleOpenBooking: PropTypes.func,
  isClosed: PropTypes.bool,
};

export default ChatHeaderDropDown;
