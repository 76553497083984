import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Form } from "antd";
import store from "store";

import SubmitButton from "components/forms/buttons/submit_button";
import GlobalErrors from "components/forms/global_errors";
import FormTransfer from "components/forms/inputs/form_transfer";
import Loading from "components/loading";

import classifyApiErrors from "utils/classify_api_errors";
import parseApiErrors from "utils/parse_api_errors";

import styles from "./styles.module.css";
import sharedStyles from "styles/form_in_drawer.module.css";

const { Groups } = store;

class AssignPropertyForm extends Component {
  state = {
    submitting: false,
    value: [],
    nonRemovableIds: [],
    loading: true,
    formErrors: {},
    globalErrors: {},
  };

  componentDidMount() {
    const { id } = this.props;

    const groupRequest = Groups.find(id).then((group) => {
      this.setState({ value: Object.keys(group.properties) });
    });
    const membershipRulesRequest = Groups.membershipRules(id).then((membershipRules) => {
      this.setState({ nonRemovableIds: membershipRules.nonRemovableIds });
    });

    Promise.allSettled([groupRequest, membershipRulesRequest])
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  getPropertyOptions = () => {
    const { propertiesOptions } = this.props;
    const { nonRemovableIds } = this.state;

    if (!Array.isArray(propertiesOptions)) {
      return [];
    }

    return propertiesOptions.map((el) => {
      return {
        key: el.id,
        title: el.title,
        disabled: nonRemovableIds.includes(el.id),
      };
    });
  };

  handleSubmit = async () => {
    const { id, onClose } = this.props;
    const { value } = this.state;

    this.setState({ submitting: true });

    try {
      await Groups.bulkAssignProperties(id, value);
      onClose();
    } catch (error) {
      if (!error.isValidationError) {
        throw error;
      }

      const parsedErrors = parseApiErrors(error);
      const { formErrors, globalErrors } = classifyApiErrors(parsedErrors, ["groupPropertyIds"]);

      this.setState({ globalErrors, formErrors });
    } finally {
      this.setState({ submitting: false });
    }
  };

  handleChange = (properties) => {
    this.setState({ value: properties });
  };

  render() {
    const { loading, submitting, value, formErrors, globalErrors } = this.state;
    const { t } = this.props;

    if (loading) {
      return <Loading />;
    }

    return (
      <>
        <GlobalErrors errors={globalErrors} />

        <Form onFinish={this.handleSubmit}>
          <FormTransfer
            className={styles.transfer}
            sourceTitle="Unassigned"
            targetTitle="Group"
            view="fullWidthNoLabel"
            search
            items={this.getPropertyOptions()}
            value={value}
            errors={formErrors.groupPropertyIds}
            onChange={this.handleChange}
          />

          <div className={sharedStyles.actions}>
            <SubmitButton loading={submitting}>{t("groups_page:submit_button")}</SubmitButton>
          </div>
        </Form>
      </>
    );
  }
}

const mapStateToProps = ({ properties }) => {
  return {
    propertiesOptions: properties.options,
    loading: !properties.options,
  };
};

export default withTranslation()(connect(mapStateToProps)(AssignPropertyForm));
