export const StepBody = ({ children }) => {
  return (
    <div
      style={{
        overflowY: "auto",
        maxHeight: "calc(100vh - 320px)",
      }}
    >
      {children}
    </div>
  );
};
