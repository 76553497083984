import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Col, Form, Row } from "antd";

import { horizontalFormItemLayout } from "config/constants/layouts/form";

import FormikCheckbox from "components/forms/inputs/formik/form_checkbox";
import FormikInput from "components/forms/inputs/formik/form_input";

import dayjs from "utils/dayjs";

import styles from "./min_nights_input.module.css";

// TODO - rework this component to act as a single field
class MinNightsInputs extends Component {
  render() {
    const { t } = this.props;
    const [sunday, ...restWeek] = dayjs.weekdays();
    const week = [...restWeek, sunday];

    return (
      <Form.Item
        {...horizontalFormItemLayout}
        label={t("channels_page:form:listing:availability_drawer:min_nights_per_week")}
      >
        {week.map((weekday, index) => {
          return (
            <Row key={index}>
              <Col xs={{ span: 12 }} className={styles.dayCheckbox}>
                <FormikCheckbox name={`day_of_week_min_nights[${index}].isChecked`} />
                <div className={styles.dayName}>{weekday}</div>
              </Col>
              <Col xs={{ span: 12 }}>
                <FormikInput name={`day_of_week_min_nights[${index}].value`} />
              </Col>
            </Row>
          );
        })}
      </Form.Item>
    );
  }
}

export default withTranslation()(MinNightsInputs);
