import React from "react";
import { useTranslation } from "react-i18next";

import List from "components/list";

export default function HotelPolicyInfo({ hotelPolicy }) {
  const { t } = useTranslation();
  const {
    checkinFromTime,
    checkinToTime,
    checkoutFromTime,
    checkoutToTime,
    currency,
    internetAccessCoverage,
    internetAccessType,
    isAdultsOnly,
    maxCountOfGuests,
    parkingIsPrivate,
    parkingReservation,
    parkingType,
    petsPolicy,
    smokingPolicy,
    title,
  } = hotelPolicy;

  return (
    <List>
      <List.Item term={t("policies:dict:policy_title")} description={title} />
      <List.Item term={t("policies:general_policy:check_in_from")} description={checkinFromTime} />
      <List.Item term={t("policies:general_policy:check_in_to")} description={checkinToTime} />
      <List.Item
        term={t("policies:general_policy:check_out_from")}
        description={checkoutFromTime}
      />
      <List.Item term={t("policies:general_policy:check_out_to")} description={checkoutToTime} />
      <List.Item term={t("policies:guarantee_payment:currency")} description={currency} />
      <List.Item
        term={t("policies:internet_access:dict:internet_coverage")}
        description={t(`policies:internet_access:coverage_options:${internetAccessCoverage}`)}
      />
      <List.Item
        term={t("policies:internet_access:dict:internet_access")}
        description={t(`policies:internet_access:type_options:${internetAccessType}`)}
      />
      <List.Item
        term={t("policies:general_policy:adults_only")}
        description={t(`policies:general_policy:adults_only_options:${isAdultsOnly}`)}
      />
      <List.Item term={t("policies:general_policy:max_guests")} description={maxCountOfGuests} />
      <List.Item
        term={t("policies:parking_policy:dict:parking_property")}
        description={t(`policies:parking_policy:parking_property_options:${parkingIsPrivate}`)}
      />
      <List.Item
        term={t("policies:parking_policy:dict:parking_reservation")}
        description={t(`policies:parking_policy:parking_reservation_options:${parkingReservation}`)}
      />
      <List.Item
        term={t("policies:parking_policy:dict:parking_type")}
        description={t(`policies:parking_policy:type_options:${parkingType}`)}
      />

      <List.Item
        term={t("policies:pets_policy:title")}
        description={t(`policies:pets_policy:options:${petsPolicy}`)}
      />
      <List.Item
        term={t("policies:smoking_policy:title")}
        description={t(`policies:smoking_policy:options:${smokingPolicy}`)}
      />
    </List>
  );
}
