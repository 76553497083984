import { createStore } from "redux";
// eslint-disable-next-line import/no-extraneous-dependencies
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";

import actions from "./actions";
import rootReducer from "./reducers";

function assignActions(target) {
  Object.keys(actions).forEach((action) => {
    if (typeof target[action] === "undefined") {
      target[action] = actions[action](target);
    } else {
      throw new Error(`${action} is present at storage object`);
    }
  });

  return target;
}

const Storage = (preloadedState) => {
  const storage = createStore(rootReducer, preloadedState, composeWithDevTools());

  return assignActions(storage);
};

export default Storage;
