import React from "react";
import { useTranslation } from "react-i18next";
import { MailOutlined } from "@ant-design/icons";

import FormInput from "components/forms/inputs/formik/form_input";

function EmailInput(props) {
  const { t } = useTranslation();
  return (
    <FormInput
      className="form-input__email"
      view="vertical"
      type="email"
      name="email"
      icon={<MailOutlined />}
      placeholder={t("public_pages:email_field_placeholder")}
      {...props}
    />
  );
}

export default EmailInput;
