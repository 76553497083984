import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Badge } from "antd";

import { Actions } from "components/crud_table/columns/actions/actions";
import { ReactQueryCrudTable } from "components/crud_table/react_query";
import { removeMenuItem } from "components/menu/items/remove";

import useDrawer from "hooks/use_drawer";

import { CreateDrawer } from "./create_drawer/create_drawer";
import { EditDrawer } from "./edit_drawer/edit_drawer";
import { useAdminWlEmailSettings } from "./use_admin_wl_email_settings/use_admin_wl_email_settings";
import { SUPPORTED_PROVIDERS } from "./wl_email_settings_form/wl_email_settings_form";
import { useDeleteAction } from "./use_delete_action";

export function WlEmailSettings({ whiteLabelPartnerId }) {
  const { t } = useTranslation();

  const [tableParams, setTableParams] = useState();
  const { isOpen: isCreateDrawerOpen, open: openCreateDrawer, close: closeCreateDrawer } = useDrawer();
  const { payload: editDrawerPayload, isOpen: isEditDrawerOpen, open: openEditDrawer, close: closeEditDrawer } = useDrawer();
  const { mutateAsync: deleteAction } = useDeleteAction();

  const query = useAdminWlEmailSettings(whiteLabelPartnerId, tableParams);

  const handleEditClick = (record) => () => {
    openEditDrawer(record);
  };

  const columns = () => {
    return [{
      title: "From Email",
      dataIndex: "fromMail",
      key: "fromMail",
    }, {
      title: "From Name",
      dataIndex: "fromName",
      key: "fromName",
    }, {
      title: "Provider",
      dataIndex: "provider",
      key: "provider",
      render: (columnValue) => {
        const provider = SUPPORTED_PROVIDERS.find(({ value }) => value === columnValue);

        return provider?.representation || columnValue;
      },
    }, {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      width: 100,
      render: (value) => {
        return <Badge status={value === true ? "success" : "error"} />;
      },
    }, {
      title: "Actions",
      key: "action",
      align: "right",
      width: 100,
      render: (_value, record) => (
        <Actions
          items={[{
            key: "edit",
            onClick: handleEditClick(record),
            label: t("general:action:edit"),
          },
          removeMenuItem({
            onRemove: () => deleteAction({ id: record.id }),
          }),
          ]}
        />
      ),
    }];
  };

  return (
    <>
      <ReactQueryCrudTable
        updateUrl={false}
        columns={columns}
        query={query}
        onCreateClick={openCreateDrawer}
        onTableParamsChange={setTableParams}
      />
      <CreateDrawer
        wlPartnerId={whiteLabelPartnerId}
        visible={isCreateDrawerOpen}
        onClose={closeCreateDrawer}
      />
      <EditDrawer
        visible={isEditDrawerOpen}
        whiteLabelEmailSettings={editDrawerPayload}
        onClose={closeEditDrawer}
      />
    </>
  );
}
