let transport;

const ENDPOINT = "geocoding";

export default class Geocoding {
  constructor(container) {
    transport = container.transport;
  }

  getByAddress(address = "") {
    return transport.send("GET", ENDPOINT, { address }).then((response) => response.data);
  }

  getByLocation(params = {}) {
    const { lat, lon } = params;
    const location = `${lat}:${lon}`;

    return transport.send("GET", ENDPOINT, { location }).then((response) => response.data);
  }
}
