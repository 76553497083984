import React, { Component } from "react";
import { Field } from "formik";
import _ from "lodash";

import FormTextarea from "components/forms/inputs/form_textarea";

class FormikTextareaInput extends Component {
  handleChange = (value, field, form) => {
    const { onChange } = this.props;

    if (onChange) {
      return onChange(value, field, form);
    }

    return form.setFieldValue(field, value);
  };

  render() {
    const { name, namespace } = this.props;

    const fieldName = namespace ? `${namespace}.${name}` : name;

    return (
      <Field name={fieldName}>
        {({ field, form }) => {
          const isTouched = _.get(form.touched, name, false);

          return (
            <FormTextarea
              {...this.props}
              {...field}
              errors={isTouched && form.errors[name]}
              onChange={(nameField, val) => this.handleChange(val, nameField, form)}
              onBlur={() => form.setFieldTouched(name, true)}
            />
          );
        }}
      </Field>
    );
  }
}

export default FormikTextareaInput;
