import { useContext } from "react";

import PublicAppRoutingContext from "./public_app_routing_context";
import UserAppRoutingContext from "./user_app_routing_context";

export default function useRouting() {
  const userAppRoutes = useContext(UserAppRoutingContext);
  const publicAppRoutes = useContext(PublicAppRoutingContext);

  return {
    userAppRoutes,
    publicAppRoutes,
  };
}
