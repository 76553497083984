import React, { Component } from "react";

import CancelButton from "components/forms/buttons/cancel";
import SaveButton from "components/forms/buttons/save";

import PopoverAdditionalButtons from "./components/popover_additional_buttons";
import PopoverBody from "./components/popover_body";
import PopoverBottom from "./components/popover_bottom";
import PopoverMainButtons from "./components/popover_main_buttons";
import PopoverTitle from "./components/popover_title";

import styles from "./channex_popover_content.module.css";

class PopoverContent extends Component {
  render() {
    const { title, children, additionalButtons, disabled, onSave, onCancel } = this.props;

    return (
      <div className={styles.popoverContent}>
        {title && <PopoverTitle>{title}</PopoverTitle>}
        <PopoverBody>{children}</PopoverBody>
        <PopoverBottom>
          <PopoverAdditionalButtons>{additionalButtons}</PopoverAdditionalButtons>
          <PopoverMainButtons>
            {onSave && <SaveButton disabled={disabled} onClick={onSave} />}
            {onCancel && <CancelButton onClick={onCancel} />}
          </PopoverMainButtons>
        </PopoverBottom>
      </div>
    );
  }
}

export default PopoverContent;
