import React from "react";
import { Col, Row } from "antd";

import ScoreItem from "../../../_common/score_item";

export default function Desktop({ overall, scores }) {
  return (
    <Row justify="space-evenly">
      {[{ category: "overall", score: overall }, ...scores].map((score) => (
        <Col key={score.category}>
          <ScoreItem key={score.category} type={score.category} score={score.score} />
        </Col>
      ))}
    </Row>
  );
}
