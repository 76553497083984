import React from "react";
import { useTranslation } from "react-i18next";
import { LockOutlined } from "@ant-design/icons";

import FormInput from "components/forms/inputs/formik/form_input";

function MFACodeInput(props) {
  const { t } = useTranslation();
  return (
    <FormInput
      className="form-input__code"
      view="vertical"
      type="text"
      name="code"
      icon={<LockOutlined />}
      placeholder={t("public_pages:code_field_placeholder")}
      {...props}
    />
  );
}

export default MFACodeInput;
