import React from "react";
import { Spin } from "antd";
import classNames from "classnames";

import styles from "./spin_container.module.css";

export default function SpinContainer({ className, spinning, children }) {
  const wrapperClassName = classNames(styles.spinContainer, className);

  return (
    <Spin spinning={spinning} wrapperClassName={wrapperClassName}>
      {children}
    </Spin>
  );
}
