import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { DeleteOutlined, UserOutlined } from "@ant-design/icons";
import { Cascader, Col, Radio, Row } from "antd";
import _ from "lodash";

import ChannexPopover from "components/channex_popover";
import RemoveButton from "components/forms/buttons/remove";
import MappingStatus from "components/mapping/mapping_status";
import RatePlanTitle from "components/rates/title";

import styles from "./mapping_row.module.css";

class MappingRow extends Component {
  getRateIdsPath = (rate) => {
    if (!rate) {
      return [];
    }

    return [rate.property_id, rate.room_type_id, rate.id];
  };

  renderRatePlan = (rate, key, className) => {
    const { ratesTree } = this.props;

    const isPropertyDisplayed = ratesTree.length > 1;

    const parentProperty = ratesTree.find((property) => property.id === rate.property_id);
    const parentRoom = parentProperty.children.find((room) => room.id === rate.room_type_id);

    const rateParents = `${isPropertyDisplayed ? `${parentProperty.title}, ` : ""}${
      parentRoom.title
    }, `;

    return (
      <span key={key} className={className}>
        {rateParents}
        <RatePlanTitle rate={rate} />
      </span>
    );
  };

  renderMapping = (mappingDefinition, mappedRates, isMappingConflict) => {
    const { handleOpenResolveConflictDialog, handleOpenMappingDialog } = this.props;

    // TODO - remove when mapping dialog for multiple rates is ready
    if (mappedRates.length > 1 && !isMappingConflict) {
      return (
        <span>
          {mappedRates.map((rate, i) => this.renderRatePlan(rate, i, styles.mappingItemRateTitle))}
        </span>
      );
    }

    return (
      <span>
        <a
          href="#map"
          className={styles.link}
          onClick={() => {
            if (mappedRates.length > 1) {
              handleOpenResolveConflictDialog(mappingDefinition, mappedRates);
            } else {
              handleOpenMappingDialog(mappingDefinition, mappedRates[0]);
            }
          }}
        >
          {mappedRates.map((rate, i) => this.renderRatePlan(rate, i))}
        </a>
      </span>
    );
  };

  renderMappingPopoverContent = (mappingDefinition) => {
    const { mappingPopupData } = this.props;
    const { rate } = mappingPopupData;

    if (_.isArray(rate) && this.checkMappedRatesConflicts(rate)) {
      return this.renderConflictResolverPopupContent();
    }

    return this.renderMappingConfigContent(mappingDefinition, rate);
  };

  renderConflictResolverPopupContent = () => {
    const {
      t,
      handleConflictFormChange,
      mappingPopupData,
      handlePopupDeleteClick,
      handleApplyConflictResolve,
      handleClosePopup,
    } = this.props;
    const { groupedRates, conflictValue } = mappingPopupData;

    return (
      <ChannexPopover.Content
        title={t("mapping:resolve_conflict_title")}
        additionalButtons={<RemoveButton onClick={handlePopupDeleteClick} />}
        onSave={handleApplyConflictResolve}
        onCancel={handleClosePopup}
      >
        <p>{t("mapping:resolve_conflict_message")}</p>
        <Radio.Group onChange={handleConflictFormChange} value={conflictValue}>
          {Object.keys(groupedRates).map((rateGroupId) => {
            const rateGroup = groupedRates[rateGroupId];
            const { parent_rate_plan_id } = rateGroup[0];

            return (
              <Radio className={styles.radioItem} value={rateGroupId} key={parent_rate_plan_id}>
                {rateGroup.map((rate) => (
                  <RatePlanTitle
                    className={styles.mappingItemRateTitle}
                    rate={rate}
                    key={rate.id}
                  />
                ))}
              </Radio>
            );
          })}
        </Radio.Group>
      </ChannexPopover.Content>
    );
  };

  renderMappingConfigContent = (mappingDefinition, mappedRate) => {
    const {
      t,
      ratesTree,
      handlePopupDeleteClick,
      handleApplyMapping,
      handleRateChange,
      handleClosePopup,
    } = this.props;

    let ratePath;

    if (mappedRate) {
      ratePath = this.getRateIdsPath(mappedRate);
    } else {
      ratePath = null;
    }

    const popoverAdditionalButton = mappedRate && <RemoveButton onClick={handlePopupDeleteClick} />;

    return (
      <ChannexPopover.Content
        title={t("mapping:map_rate_title")}
        additionalButtons={popoverAdditionalButton}
        onSave={handleApplyMapping}
        onCancel={handleClosePopup}
      >
        <Cascader
          className={styles.rateSelector}
          options={ratesTree}
          placeholder="Please select rate"
          allowClear={false}
          autoFocus
          fieldNames={{
            label: "label",
            value: "id",
            children: "children",
          }}
          value={ratePath}
          displayRender={(label, selectedOptions) => {
            if (selectedOptions.length === 0) {
              return null;
            }

            const [property, room] = selectedOptions;
            let ratePlan;

            if (selectedOptions.length === 4) {
              // if derived rate plan
              ratePlan = selectedOptions[3];
            } else {
              ratePlan = selectedOptions[2];
            }

            return (
              <>
                {property.title} / {room.title} /{" "}
                <span>
                  {ratePlan.title} <UserOutlined />
                  {ratePlan.occupancy}
                </span>
              </>
            );
          }}
          onChange={handleRateChange}
        />
      </ChannexPopover.Content>
    );
  };

  checkMappedRatesConflicts = (mappedRates) => {
    const [firstRate, ...otherRates] = mappedRates;

    const isMappedRatesHasSameProps = otherRates.every((rate) => {
      const isRoomMatches = rate.room_type_id === firstRate.room_type_id;
      const isParentRateMatches = rate.parent_rate_plan_id === firstRate.parent_rate_plan_id;
      const isPropertyMatches = rate.property_id === firstRate.property_id;

      return isRoomMatches && isParentRateMatches && isPropertyMatches;
    });

    return !isMappedRatesHasSameProps;
  };

  render() {
    const {
      t,
      mapping,
      mappingPopupData,
      handleDeleteMapping,
      handleOpenMappingDialog,
    } = this.props;
    const {
      isDeleted,
      isParentDeleted,
      mappedRates,
      mappingKey,
      mappingDefinition,
      level,
    } = mapping;

    const isDeletedOrParentDeleted = isDeleted || isParentDeleted;

    const isEditMappingFormOpen = mappingPopupData.visible === mappingKey;
    const rates = mappedRates.map((r) => r.title).join(", ");
    const isMappingConflict = this.checkMappedRatesConflicts(mappedRates);
    const isMappingError = isDeletedOrParentDeleted || isMappingConflict;

    const className = [
      styles[`mappingRowLevel${level}`],
      isMappingError ? styles.mappingLevelDeleted : null,
    ].join(" ");

    return (
      <Row className={className}>
        <Col span={11}>
          <RatePlanTitle rate={mapping} />
        </Col>
        <Col span={2}>
          <MappingStatus isError={isMappingError} />
        </Col>
        <Col span={11} className={styles.mappedRateCol}>
          {mappedRates.length !== 0 && (
            <Row>
              <Col span={22}>
                {isDeletedOrParentDeleted ? (
                  <span>{rates}</span>
                ) : (
                  <ChannexPopover
                    visible={isEditMappingFormOpen || false}
                    content={this.renderMappingPopoverContent(mappingDefinition)}
                  >
                    {this.renderMapping(mappingDefinition, mappedRates, isMappingConflict)}
                  </ChannexPopover>
                )}
              </Col>
              <Col span={2}>
                {isParentDeleted ? null : (
                  <a
                    href="#remove"
                    onClick={handleDeleteMapping.bind(this, mappingDefinition)}
                    className={styles.deleteButton}
                  >
                    <DeleteOutlined />
                  </a>
                )}
              </Col>
            </Row>
          )}
          {mappedRates.length === 0 && (
            isDeletedOrParentDeleted ? (
              <span>{t("channels_page:form:not_mapped")}</span>
            ) : (
              <ChannexPopover
                visible={isEditMappingFormOpen || false}
                content={this.renderMappingPopoverContent(mappingDefinition)}
              >
                <a
                  href="#map"
                  onClick={handleOpenMappingDialog.bind(this, mappingDefinition, null)}
                  className={styles.linkNotMapped}
                >
                  {t("channels_page:form:not_mapped")}
                </a>
              </ChannexPopover>
            )
          )}
        </Col>
      </Row>
    );
  }
}

export default withTranslation()(MappingRow);
