import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import SpinContainer from "components/layouts/spin_container/spin_container";

import usePagination from "utils/use_pagination";

import MessageItem from "./message_item";

import styles from "./chat_messages_list.module.css";

const SCROLL_THRESHOLD = "200px";

export default function ChatMessagesList({
  listRef,
  loading,
  items,
  meta,
  sender,
  receiver,
  onLoad,
  onSend,
}) {
  const { nextPage, isLastPage } = usePagination(meta);

  const handleLoad = () => {
    onLoad(nextPage);
  };

  return (
    <SpinContainer className={styles.spinContainer} spinning={loading}>
      <div id="scrollableDiv" className={styles.scrollContainer}>
        <InfiniteScroll
          scrollableTarget="scrollableDiv"
          ref={listRef}
          inverse
          className={styles.scroll}
          scrollThreshold={SCROLL_THRESHOLD}
          dataLength={items.length}
          hasMore={!isLastPage}
          next={handleLoad}
        >
          {items.map((item) => (
            <MessageItem
              key={item.id}
              item={item}
              handleResendMessage={onSend}
              sender={sender}
              receiver={receiver}
            />
          ))}
        </InfiniteScroll>
      </div>
    </SpinContainer>
  );
}
