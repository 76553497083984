import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getAppSettings } from "store/storage/selectors/session_selector";

import SocialNetworkNames from "config/constants/social_network_names";

import Google from "./google";

import styles from "./social_auth.module.css";

export default function SocialAuth() {
  const { t } = useTranslation();
  const appSettings = useSelector(getAppSettings);

  const isGoogleAuth = appSettings?.socialAuth?.includes(SocialNetworkNames.GOOGLE);

  if (!isGoogleAuth) {
    return null;
  }

  return (
    <>
      {isGoogleAuth && <Google />}
      <div className={styles.separator}>
        <span className={styles.separatorContent}>{t("sign_in_page:or")}</span>
      </div>
    </>
  );
}
