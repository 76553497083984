const isUnmappedRoom = (revision) => {
  const { rooms } = revision;

  return rooms.reduce((acc, { room_type_id }) => {
    return acc || !room_type_id;
  }, false);
};

const isUnmappedRate = (revision) => {
  const { rooms } = revision;

  return rooms.reduce((acc, { rate_plan_id }) => {
    return acc || !rate_plan_id;
  }, false);
};

export default (revision) => {
  const errors = [];

  if (isUnmappedRoom(revision)) {
    errors.push("unmapped_room");
  } else if (isUnmappedRate(revision)) {
    errors.push("unmapped_rate");
  }

  return errors;
};
