import React from "react";
import { useTranslation } from "react-i18next";

import BlockedListing from "./blocked_listing";

export default function BlockedListings({ blockedListings, onDisconnect }) {
  const { t } = useTranslation();

  if (!blockedListings.length) {
    return null;
  }

  return (
    <>
      <legend>{t("channels_page:form:blocked_listings_legend")}</legend>
      {blockedListings.map((listing) => (
        <BlockedListing key={listing.id} listing={listing} onDisconnect={onDisconnect} />
      ))}
    </>
  );
}
