import { useMutation, useQueryClient } from "react-query";
import store from "store";

const { AdminWlDomains } = store;

export function useDeleteAction() {
  const queryClient = useQueryClient();

  return useMutation(({ id }) => AdminWlDomains.delete(id), {
    onSuccess: () => {
      queryClient.invalidateQueries("admin_wl_domains");
    },
  });
}
