import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "antd";
import store from "store";

import SubmitButton from "components/forms/buttons/submit_button";
import FormSelect from "components/forms/inputs/form_select";
import Loading from "components/loading";

import styles from "styles/form_in_drawer.module.css";

const { AdminBillingAccounts, AdminChannelAdapters } = store;

export default function ChannelAdaptersForm({ value, billingAccountId, onClose }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [publicChannelAdaptersState, setPublicChannelAdaptersState] = useState(value.relationships.blocked_channel_adapters.data.map(({ id }) => id));
  const [privateChannelAdaptersState, setPrivateChannelAdaptersState] = useState(value.relationships.channel_adapters.data.map(({ id }) => id));
  const [privateChannelAdapters, setPrivateChannelAdapters] = useState([]);
  const [publicChannelAdapters, setPublicChannelAdapters] = useState([]);

  useEffect(
    function loadAllChannelAdapters() {
      AdminChannelAdapters.list().then((response) => {
        setPublicChannelAdapters(response.data.filter(({ access_mode }) => {
          return access_mode === "public";
        }).map(({ id, title }) => ({ value: id, representation: title })));
        setPrivateChannelAdapters(response.data.filter(({ access_mode }) => {
          return access_mode === "private";
        }).map(({ id, title }) => ({ value: id, representation: title })));
        setLoading(false);
      });
    },
    [],
  );

  const handleSubmit = () => () => {
    setSubmitting(true);

    AdminBillingAccounts.update({ id: billingAccountId, channel_adapter_ids: privateChannelAdaptersState, blocked_channel_adapter_ids: publicChannelAdaptersState })
      .then(() => {
        onClose();
      })
      .catch((error) => {
        if (!error.isValidationError) {
          throw error;
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handlePublicChannelAdaptersChange = () => (data) => {
    setPublicChannelAdaptersState(data);
  };

  const handlePrivateChannelAdaptersChange = () => (data) => {
    setPrivateChannelAdaptersState(data);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Form onFinish={handleSubmit()}>
      <FormSelect
        view="horizontal"
        mode="multiple"
        name="channel_adapters"
        allowClear
        placeholder={t("billing_account_drawer:channel_adapters:placeholder")}
        onChange={handlePrivateChannelAdaptersChange()}
        value={privateChannelAdaptersState}
        label={t("billing_account_drawer:channel_adapters:label")}
        options={privateChannelAdapters}
      />
      <FormSelect
        view="horizontal"
        mode="multiple"
        name="blocked_channel_adapters"
        allowClear
        placeholder={t("billing_account_drawer:blocked_channel_adapters:placeholder")}
        onChange={handlePublicChannelAdaptersChange()}
        value={publicChannelAdaptersState}
        label={t("billing_account_drawer:blocked_channel_adapters:label")}
        options={publicChannelAdapters}
      />
      <div className={styles.actions}>
        <SubmitButton loading={submitting}>{t("billing_account_drawer:channel_adapters:submit_button")}</SubmitButton>
      </div>
    </Form>
  );
}
