import React from "react";
import { useTranslation } from "react-i18next";

import ChannexDrawer from "drawers/channex_drawer";

import DrawerFormWrapper from "components/drawer_form_wrapper";

import { CreateExtraCategoryForm } from "./create_form/create_form";

export function CreateCategoryDrawer({ propertyId, visible, onClose }) {
  const { t } = useTranslation();
  const title = t("property_extras:titles:create_extra_category");

  return (
    <ChannexDrawer title={title} visible={visible} onClose={onClose}>
      <DrawerFormWrapper withActions>
        <CreateExtraCategoryForm propertyId={propertyId} onFinish={onClose} />
      </DrawerFormWrapper>
    </ChannexDrawer>
  );
}
