import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import ChannexModal from "components/channex_modal";

import ShowCardMFAConfirmationContent from "./show_card_mfa_confirmation_content";

export default function ShowCardMFAConfirmationModal({ visible, onClose, onConfirm }) {
  const { t } = useTranslation();
  const [code, setCode] = useState("");
  const [error, setError] = useState(null);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleChange = (value) => {
    setCode(value);
    setError(null);
  };

  const resetState = () => {
    setCode("");
    setError(null);
    setConfirmLoading(false);
  };

  const handleConfirm = () => {
    if (code === "") {
      setError(t("applications_page:channex_pci:mfa_confirmation_error"));

      return;
    }

    setConfirmLoading(true);
    onConfirm(code).then(() => {
      onClose();
      resetState();
    });
  };

  return (
    <ChannexModal
      title={t("applications_page:channex_pci:mfa_confirmation_title")}
      visible={visible}
      onOk={handleConfirm}
      confirmLoading={confirmLoading}
      onCancel={onClose}
    >
      <ShowCardMFAConfirmationContent
        value={code}
        error={error}
        onChange={handleChange}
        onEnter={handleConfirm}
      />
    </ChannexModal>
  );
}
