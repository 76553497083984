import React from "react";

import BookingComOnboarding from "./booking_com_onboarding";
import ChannelReadiness from "./channel_readiness";
import SignTermsAndConditionsAgreement from "./sign_terms_and_conditions_agreement";

export default function GlobalComponents() {
  return (
    <>
      <SignTermsAndConditionsAgreement />
      <BookingComOnboarding />
      <ChannelReadiness />
    </>
  );
}
