import React from "react";
import { useTranslation } from "react-i18next";

import FormWeekdays from "components/forms/inputs/form_weekdays";

export default function InputClosedToDeparture({ model, disabled, isDerived, onChange }) {
  const { t } = useTranslation();
  const isInherited = model.inherit_closed_to_departure === true && isDerived;

  return (
    <FormWeekdays
      name="closed_to_departure"
      label={t("rates_page:form:closed_to_departure_label")}
      value={model.closed_to_departure}
      disabled={isInherited || disabled}
      onChange={(value, name) => onChange(name, value)}
    />
  );
}
