import React from "react";
import { useTranslation } from "react-i18next";

import ChannexDrawerRoutable from "drawers/channex_drawer_routable";

import DrawerFormWrapper from "components/drawer_form_wrapper";

import RoomView from "./room_view";

export default function RoomViewDrawer({ params }) {
  const { t } = useTranslation();
  const { roomId } = params;

  return (
    <ChannexDrawerRoutable title={t("rooms_page:view_dialog_header")}>
      {() => (
        <DrawerFormWrapper>
          <RoomView roomId={roomId} />
        </DrawerFormWrapper>
      )}
    </ChannexDrawerRoutable>
  );
}
