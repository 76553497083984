import React, { useEffect, useState } from "react";
import store from "store";

import Loading from "components/loading/loading";

export const InitWsConnection = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function connectWS() {
      await store.ws.connect();
      setLoading(false);
    }

    connectWS();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return children;
};
