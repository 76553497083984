import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";
import i18n from "i18next";
import store from "store";

import TaxEditDrawer from "drawers/tax_edit_drawer";
import TaxSetDrawer from "drawers/tax_set_drawer_controlled";

import ErrorBoundary from "components/error_boundary";
import TabTable from "components/tab_table";

import alphabetSort from "utils/alphabet_sort";
import showErrorMessageFromResponse from "utils/show_error_message_from_response";
import useBoolState from "utils/use_bool_state";

import TaxActions from "./tax_actions";

const { TaxSets, Taxes } = store;

const ACTION_COLUMNS_WIDTH = 200;

const TAX_SPECIFIC_DATA = {
  taxSet: {
    CreateDrawer: TaxSetDrawer,
    EditDrawer: TaxSetDrawer,
    actions: TaxSets,
    selectable: true,
    getEmptyMessage: () => i18n.t("properties_page:policies:tax_set:empty_message"),
  },
  tax: {
    CreateDrawer: TaxEditDrawer,
    EditDrawer: TaxEditDrawer,
    actions: Taxes,
    selectable: false,
    getEmptyMessage: () => i18n.t("properties_page:policies:tax:empty_message"),
  },
};

export default function TaxTable({ type, property, selectedId, onChange, dataCy }) {
  const { t } = useTranslation();
  const [isCreateDrawerOpen, setCreateDrawerOpen, setCreateDrawerClosed] = useBoolState(false);
  const [isEditDrawerOpen, setEditDrawerOpen, setEditDrawerClosed] = useBoolState(false);
  const [isLoading, setLoading, setLoadingComplete] = useBoolState(false);
  const [activePolicyId, setActivePolicyId] = useState(null);
  const [taxOptions, setTaxOptions] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const { handleError } = useContext(ErrorBoundary.Context);
  const { id, currency } = property;
  const { CreateDrawer, EditDrawer, actions, selectable, getEmptyMessage } = TAX_SPECIFIC_DATA[
    type
  ];

  const loadTaxOptions = useCallback(async () => {
    try {
      setLoading();

      const data = await actions.options({ property_id: id });
      setTaxOptions(data);
      setLoadingComplete();
    } catch (error) {
      handleError(error);
    }
  }, [id, actions, setLoading, setLoadingComplete, handleError]);

  const handlePolicyEdited = useCallback(() => {
    setEditDrawerClosed();
    loadTaxOptions();
  }, [loadTaxOptions, setEditDrawerClosed]);

  const handlePolicyCreated = useCallback(
    (data) => {
      if (!selectedId && selectable) {
        onChange(data.id);
      }

      setCreateDrawerClosed();
      loadTaxOptions();
    },
    [selectedId, selectable, loadTaxOptions, setCreateDrawerClosed, onChange],
  );

  const filteredData = useMemo(() => {
    return taxOptions
      .filter((policy) => {
        return policy.title.toLowerCase().includes(searchQuery);
      })
      .sort((a) => {
        if (a.id === selectedId) {
          return -1;
        }
        return 0;
      });
  }, [searchQuery, taxOptions, selectedId]);

  const handleEdit = useCallback(
    (policyId) => {
      setActivePolicyId(policyId);
      setEditDrawerOpen();
    },
    [setEditDrawerOpen],
  );

  const handleDelete = useCallback(
    async (policyId) => {
      try {
        await actions.remove({ id: policyId });

        const updatedTaxes = taxOptions.filter((policy) => policy.id !== policyId);

        setTaxOptions(updatedTaxes);
      } catch (error) {
        showErrorMessageFromResponse(error);
      }
    },
    [actions, taxOptions],
  );

  const handleSelect = useCallback(
    (policyId) => {
      return onChange(policyId);
    },
    [onChange],
  );

  const renderTaxActions = useCallback(
    (_text, policy) => (
      <TaxActions
        isDefault={selectedId === policy.id}
        selectable={selectable}
        id={policy.id}
        onEdit={handleEdit}
        onDelete={handleDelete}
        onSelect={handleSelect}
      />
    ),
    [selectedId, selectable, handleEdit, handleSelect, handleDelete],
  );

  const columns = useMemo(() => {
    return [
      {
        title: t("general:hotel"),
        key: "title",
        dataIndex: "title",
        sorter: alphabetSort("title"),
        ellipsis: true,
      },
      {
        title: t("general:actions"),
        key: "actions",
        align: "right",
        width: ACTION_COLUMNS_WIDTH,
        render: renderTaxActions,
      },
    ];
  }, [t, renderTaxActions]);

  useEffect(
    function initFormData() {
      loadTaxOptions();
    },
    [loadTaxOptions],
  );

  return (
    <>
      <TabTable
        actions={
          <Button
            data-cy={`create_${dataCy}_button`}
            icon={<PlusCircleOutlined />}
            type="primary"
            onClick={setCreateDrawerOpen}
          >
            {t("general:action:create")}
          </Button>
        }
        emptyMessage={getEmptyMessage()}
        loading={isLoading}
        searchQuery={searchQuery}
        rowKey="id"
        columns={columns}
        dataSource={filteredData}
        onCreate={setCreateDrawerOpen}
        onSearchChange={setSearchQuery}
      />
      <EditDrawer
        id={activePolicyId}
        currency={currency}
        propertyId={id}
        visible={isEditDrawerOpen}
        onClose={setEditDrawerClosed}
        onCreate={handlePolicyEdited}
      />
      <CreateDrawer
        currency={currency}
        propertyId={id}
        visible={isCreateDrawerOpen}
        onClose={setCreateDrawerClosed}
        onCreate={handlePolicyCreated}
      />
    </>
  );
}
