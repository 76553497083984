import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import dayjs from "dayjs";
import _ from "lodash";
import store from "store";

const { TaxSets } = store;

const DEFAULT_PRICE = "100";
const DEBOUNCE_TIME = 500;

const withLogic = (FormComponent) => {
  class HOC extends Component {
    state = {
      results: null,
      date: dayjs().toISOString().substring(0, 10),
      days: [
        DEFAULT_PRICE,
      ],
      price: DEFAULT_PRICE,
      lastTaxesValue: "",
      loading: false,
      errors: null,
    };

    onPriceChangeCallback = _.debounce(() => {
      const { value } = this.props;
      const { taxes, currency } = value;

      if (taxes.length && currency) {
        this.calculate(taxes, currency);
      }
    }, DEBOUNCE_TIME);

    handlePriceChange = (price, name) => {
      const index = parseInt(name.replace("raw_price_", ""));

      this.setState(
        (prevState) => {
          const days = prevState.days;
          days[index] = price;

          return { days };
        },
        () => this.onPriceChangeCallback(),
      );
    };

    handleDateChange = (date) => {
      this.setState({ date }, () => this.onPriceChangeCallback());
    };

    handleAddDate = () => {
      const { days } = this.state;
      this.setState({ days: [...days, DEFAULT_PRICE] }, () => this.onPriceChangeCallback());
    };

    handleRemoveDate = () => {
      const { days } = this.state;
      if (days.length > 1) {
        this.setState({ days: days.slice(0, -1) }, () => this.onPriceChangeCallback());
      }
    };

    calculate = (taxes, currency) => {
      const { t } = this.props;
      const { date, days } = this.state;

      this.setState({ loading: true });

      TaxSets.test({ taxes, date, days, currency })
        .then((results) => this.setState({
          loading: false,
          results,
          errors: null,
        }))
        .catch(() => this.setState({
          loading: false,
          results: {},
          errors: [t("taxes:tax_set:form:tax_calculator:calculation_error")],
        }));
    };

    onChange = (newValue) => {
      const { lastTaxesValue } = this.state;
      const { value, onChange } = this.props;

      const { taxes, currency } = newValue;
      const { currency: oldCurrency } = value;

      const stringifiedTaxes = JSON.stringify(taxes);
      const hasTaxes = taxes.length;
      const dataChanged = stringifiedTaxes !== lastTaxesValue || currency !== oldCurrency;

      if (currency && hasTaxes && dataChanged) {
        this.onPriceChangeCallback.cancel();

        this.calculate(taxes, currency);

        this.setState({ lastTaxesValue: stringifiedTaxes });
      }

      onChange(newValue);
    };

    render() {
      const { date, days, price, results, loading, errors } = this.state;
      const {
        value: { currency },
      } = this.props;

      return (
        <FormComponent
          {...this.props}
          taxSetCalculatorProps={{
            price,
            days,
            date,
            errors,
            loading,
            results,
            currency,
            handlePriceChange: this.handlePriceChange,
            handleDateChange: this.handleDateChange,
            handleAddDate: this.handleAddDate,
            handleRemoveDate: this.handleRemoveDate,
          }}
          onChange={this.onChange}
        />
      );
    }
  }

  return withTranslation()(HOC);
};

export default withLogic;
