import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Modal } from "antd";
import { FormikProvider, useFormik } from "formik";
import _ from "lodash";
import store from "store";

import FormikFormSelect from "components/forms/inputs/formik/form_select";

const { AdminBillingAccounts } = store;

function AssignBillingAccountModal({ userId, billingAccountId, role, onSave, onClose }) {
  const { t } = useTranslation();
  const [billingAccounts, setBillingAccounts] = useState([
    {
      value: billingAccountId,
      representation: t("users_sub_page:assign_billing_account_form:loading"),
    },
  ]);
  const [loading, setLoading] = useState(true);

  const formik = useFormik({
    initialValues: {
      userId,
      billingAccountId,
      role,
    },
  });

  useEffect(() => {
    AdminBillingAccounts.options().then((response) => {
      setBillingAccounts(
        response.map((el) => {
          return { value: el.id, representation: el.companyName };
        }),
      );
      setLoading(false);
    });
  }, []);

  const onOk = () => {
    onSave(formik.values);
  };

  const onCancel = () => {
    onClose();
  };

  const roles = [
    {
      value: "user",
      representation: t("users_sub_page:assign_billing_account_form:role:options:user"),
    },
    {
      value: "owner",
      representation: t("users_sub_page:assign_billing_account_form:role:options:owner"),
    },
  ];

  return (
    (
      <Modal
        title={t("users_sub_page:assign_billing_account_form:title")}
        open="true"
        onOk={onOk}
        onCancel={onCancel}
        okButtonProps={{
          disabled: loading,
        }}
      >
        <FormikProvider value={formik}>
          <Form>
            <FormikFormSelect
              name="billingAccountId"
              placeholder={t(
                "users_sub_page:assign_billing_account_form:billing_account:placeholder",
              )}
              label={t("users_sub_page:assign_billing_account_form:billing_account:label")}
              options={billingAccounts}
              loading={loading}
              disabled={loading}
            />
            <FormikFormSelect
              name="role"
              placeholder={t("users_sub_page:assign_billing_account_form:role:placeholder")}
              label={t("users_sub_page:assign_billing_account_form:role:label")}
              options={roles}
              disabled={loading}
            />
          </Form>
        </FormikProvider>
      </Modal>
    )
  );
}

export default AssignBillingAccountModal;
