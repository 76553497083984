import React from "react";
import { useTranslation } from "react-i18next";
import { Select } from "antd";

import { formatToLocal } from "../../../utils/dates";
import { BookingRevisionStatusTag } from "../../booking_revision/booking_revision_status_tag";
import BookingRevisionTags from "../../booking_revision/booking_revision_tags/booking_revision_tags";

import { SelectTagContainer } from "./select_tag_container";

const RevisionOptionLabel = ({ revision }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div style={{ fontWeight: 600 }}>
        {t("bookings_page:booking_view_dialog:system_id")}: {revision.system_id}
        <br />
        <small>{formatToLocal(revision.inserted_at, t("formats:date_time_with_seconds"))}</small>
      </div>
      <div>
        <BookingRevisionTags revision={revision} />
      </div>
    </div>
  );
};

export const RevisionSelect = ({ value, disabledValue, revisions, onChange }) => {
  const { t } = useTranslation();

  return (
    <Select
      style={{ width: "100%", maxWidth: 400 }}
      value={value}
      labelRender={({ value: renderValue }) => {
        const selectedRevision = revisions.find((revision) => revision.id === renderValue);
        return (
          <>
            <SelectTagContainer>
              <BookingRevisionStatusTag status={selectedRevision.status} />
            </SelectTagContainer>
            {formatToLocal(selectedRevision.inserted_at, t("formats:date_time_with_seconds"))}
          </>
        );
      }}
      options={revisions.map((revision) => ({
        value: revision.id,
        label: <RevisionOptionLabel revision={revision} />,
        disabled: revision.id === disabledValue,
      }))}
      onChange={(newValue) => {
        const selectedRevision = revisions.find((revision) => revision.id === newValue);
        onChange(selectedRevision);
      }}
    />
  );
};
