import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, Typography } from "antd";
import { Formik } from "formik";
import * as yup from "yup";

import errors from "config/constants/errors";

import Input from "components/forms/inputs/formik/form_input";

import styles from "../onboarding.module.css";

const { Text } = Typography;

const INITIAL_FORM_VALUE = { url: "" };

const getSchema = () => {
  return yup.object({
    url: yup.string().trim().required(errors.required()),
  });
};

export default function OnboardingUrlStep({
  onSubmit,
  formRef,
  onboardingData,
  onOnboardingRetry,
}) {
  const { t } = useTranslation();

  const handleSubmit = useCallback(
    (values) => {
      onSubmit(values);
    },
    [onSubmit],
  );

  useEffect(() => {
    if (formRef.current) {
      formRef.current.resetForm();
    }
  }, [formRef]);

  const error = onboardingData?.onboardingProcess?.error;

  if (error) {
    return (
      <div className={styles.urlStep_error}>
        <Text type="danger" className={styles.urlStep_error_text}>
          {t(`properties_page:booking_com_onboarding:onboarding_failed_modal:errors:${error}`)}
        </Text>
        <Button onClick={onOnboardingRetry} htmlType="submit" type="primary">
          {t("properties_page:booking_com_onboarding:onboarding_failed_modal:launch_new")}
        </Button>
      </div>
    );
  }

  return (
    <Formik
      innerRef={formRef}
      initialValues={INITIAL_FORM_VALUE}
      validationSchema={getSchema}
      onSubmit={handleSubmit}
    >
      <Input
        label={t("properties_page:booking_com_onboarding:onboarding_form:url_label")}
        placeholder={t("properties_page:booking_com_onboarding:onboarding_form:url_placeholder")}
        name="url"
        view="fullWidth"
      />
    </Formik>
  );
}
