import { Skeleton } from "antd";

export const Card = ({ title, value, isLoading }) => {
  return (
    <div style={{ flex: 1, whiteSpace: "nowrap", padding: "0 12px" }}>
      <div style={{ fontSize: "16px", fontWeight: "200", lineHeight: "12px" }}>{title}</div>

      {isLoading && (
        <div style={{ paddingTop: "12px" }}>
          <Skeleton.Button active size="small" shape="square" />
        </div>
      )}

      {!isLoading && (
        <div style={{ fontSize: "24px", color: "#707070" }}>{value}</div>
      )}
    </div>
  );
};
