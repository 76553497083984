import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Actions } from "components/crud_table/columns/actions/actions";
import { ReactQueryCrudTable } from "components/crud_table/react_query";
import { removeMenuItem } from "components/menu/items/remove";

import useDrawer from "hooks/use_drawer";

import { CreateDrawer } from "./create_drawer/create_drawer";
import { EditDrawer } from "./edit_drawer/edit_drawer";
import { useAdminWlDomains } from "./use_admin_wl_domains/use_admin_wl_domains";
import { useDeleteAction } from "./use_delete_action";

export function WlDomains({ whiteLabelPartnerId }) {
  const { t } = useTranslation();

  const [tableParams, setTableParams] = useState();
  const { isOpen: isCreateDrawerOpen, open: openCreateDrawer, close: closeCreateDrawer } = useDrawer();
  const { payload: editDrawerPayload, isOpen: isEditDrawerOpen, open: openEditDrawer, close: closeEditDrawer } = useDrawer();
  const { mutateAsync: deleteAction } = useDeleteAction();

  const query = useAdminWlDomains(whiteLabelPartnerId, tableParams);

  const handleEditClick = (record) => () => {
    openEditDrawer(record);
  };

  const columns = () => {
    return [{
      title: "Domain",
      dataIndex: "domain",
      key: "domain",
      sorter: true,
    }, {
      title: "Title",
      dataIndex: "title",
      key: "title",
    }, {
      title: "Actions",
      key: "action",
      align: "right",
      width: 100,
      render: (_value, record) => (
        <Actions
          items={[{
            key: "edit",
            onClick: handleEditClick(record),
            label: t("general:action:edit"),
          },
          removeMenuItem({
            onRemove: () => deleteAction({ id: record.id }),
          }),
          ]}
        />
      ),
    }];
  };

  return (
    <>
      <ReactQueryCrudTable
        updateUrl={false}
        columns={columns}
        query={query}
        onCreateClick={openCreateDrawer}
        onTableParamsChange={setTableParams}
      />
      <CreateDrawer
        wlPartnerId={whiteLabelPartnerId}
        visible={isCreateDrawerOpen}
        onClose={closeCreateDrawer}
      />
      <EditDrawer
        visible={isEditDrawerOpen}
        whiteLabelDomain={editDrawerPayload}
        onClose={closeEditDrawer}
      />
    </>
  );
}
