import attributesExtractor from "../utils/attributes_extractor";
import { convertToCamelCase, convertToSnakeCase } from "../utils/case_converter";

let transport;

const ENDPOINT = "admin/wl_partners";

export default class AdminWlPartners {
  constructor(container) {
    transport = container.transport;
  }

  list(filter = {}, pagination = { page: 1, limit: 10 }, order) {
    return transport
      .send("GET", ENDPOINT, { filter, pagination, order })
      .then(convertToCamelCase)
      .then(({ data, meta }) => {
        return {
          data: attributesExtractor(data),
          meta,
        };
      });
  }

  create(attrs) {
    const convertedAttrs = convertToSnakeCase(attrs);

    return transport
      .send("POST", ENDPOINT, convertedAttrs)
      .then(convertToCamelCase)
      .then((response) => {
        return attributesExtractor(response.data);
      });
  }

  update(id, attrs) {
    const body = convertToSnakeCase({
      wlPartner: attrs,
    });

    return transport
      .send("PUT", `${ENDPOINT}/${id}`, body)
      .then(convertToCamelCase)
      .then((response) => {
        return attributesExtractor(response.data);
      });
  }

  delete(id) {
    return transport.send("DELETE", `${ENDPOINT}/${id}`);
  }
}
