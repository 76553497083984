import React from "react";
import { Outlet } from "react-router-dom";
import classNames from "classnames";

import { useMobile } from "../use_mobile";

import ReviewList from "./reviews_list";

import styles from "./review_app.module.css";

export const ReviewApp = () => {
  const isMobile = useMobile();

  const className = classNames(styles.container, {
    [styles.mobile]: isMobile,
    [styles.desktop]: !isMobile,
  });

  return (
    <div className={className}>
      {isMobile && (
        <>
          <Outlet />
          <ReviewList />
        </>
      )}
      {!isMobile && (
        <>
          <ReviewList />
          <Outlet />
        </>
      )}
    </div>
  );
};
