import React from "react";
import { useTranslation } from "react-i18next";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Tag } from "antd";

import SectionHeader from "../../../../../../_common/section_header/section_header";
import Text from "../../../../../../_common/text";

const LOCK_ICON = (
  <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd" opacity=".65">
      <path d="M0 0h18v18H0z" />
      <path d="M14.486 4.959a6.326 6.326 0 0 0-1.278-1.994 5.927 5.927 0 0 0-1.891-1.351 5.647 5.647 0 0 0-4.636.001 5.914 5.914 0 0 0-1.891 1.35A6.3 6.3 0 0 0 3.512 4.96a6.47 6.47 0 0 0-.47 2.436v8.265c0 .675.523 1.224 1.166 1.224h9.579c.643 0 1.165-.549 1.161-1.224V8.365l.004-.335v-.635a6.426 6.426 0 0 0-.466-2.436zM7.15 2.72h.004l.022-.009a4.366 4.366 0 0 1 1.823-.393c.631 0 1.247.133 1.825.39.56.253 1.07.617 1.512 1.08a5.13 5.13 0 0 1 1.035 1.616c.255.63.383 1.298.383 1.99v.52h-9.51v-.522c0-.685.129-1.353.384-1.988A5.106 5.106 0 0 1 5.662 3.79c.438-.46.935-.821 1.487-1.069zm6.61 6.392v6.546c0 .01-.002.019-.004.024H4.247a.081.081 0 0 1-.004-.024V9.113h9.515zm-5.314 2.7v1.573a.557.557 0 0 0 1.114 0v-1.577c.295-.19.473-.51.473-.865a1.032 1.032 0 0 0-2.064 0c.002.35.183.68.477.869z" fill="#000" fillRule="nonzero" />
    </g>
  </svg>
);

const tagIsPositive = (tag) => tag.includes("positive");

const getTagColor = (tag) => {
  if (tagIsPositive(tag)) {
    return "green";
  }
  return "red";
};

const getTagIcon = (tag) => {
  if (tagIsPositive(tag)) {
    return <PlusCircleOutlined />;
  }
  return <MinusCircleOutlined />;
};

export default function PrivateFeedback({ review }) {
  const { t } = useTranslation();

  const { attributes: { rawContent, tags } } = review;

  const { privateFeedback } = rawContent || {};

  if (!privateFeedback) {
    return null;
  }

  return (
    <div style={{ display: "flex" }}>
      <div style={{ lineHeight: "32px", marginRight: 12 }}>
        {LOCK_ICON}
      </div>
      <div>
        <SectionHeader header={t("reviews_page:feedback:private_feedback")} />
        <Text>{privateFeedback}</Text>
        {
          tags.map((tag) => {
            return (
              <Tag key={tag} color={getTagColor(tag)} icon={getTagIcon(tag)} style={{ marginTop: "4px", marginBottom: "4px" }}>
                {t(`reviews_page:tags:${tag}`)}
              </Tag>
            );
          })
        }
      </div>
    </div>
  );
}
