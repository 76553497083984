export const getParamsFromUrl = () => {
  const url = new URL(window.location.href);

  const orderBy = url.searchParams.get("orderBy");
  const orderDir = url.searchParams.get("orderDir");

  const order = orderBy && orderDir ? { [orderBy]: orderDir } : null;

  const page = url.searchParams.get("page");
  const limit = url.searchParams.get("limit");
  const query = url.searchParams.get("searchQuery");

  const params = {
    page: page > 0 ? Number(page) : null,
    limit: limit > 0 ? Number(limit) : null,
    searchQuery: query || null,
    order,
  };

  Object.keys(params).forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });

  return params;
};

export const setParamsToUrl = (params, navigate) => {
  // This is a workaround to prevent navigation in Cypress tests which breaks routing
  if (import.meta.env.BASE_URL?.includes("cypress")) {
    return;
  }

  const urlPaginationParams = ["orderBy", "orderDir", "page", "limit", "searchQuery"];

  const originalUrl = new URL(window.location.href);
  const updatedUrl = new URL(window.location.href);
  updatedUrl.search = "";

  Object.entries(params).forEach(([key, value]) => {
    updatedUrl.searchParams.set(key, value);
  });

  originalUrl.searchParams.forEach((value, key) => {
    if (!urlPaginationParams.includes(key)) {
      updatedUrl.searchParams.set(key, value);
    }
  });

  navigate(`${updatedUrl.pathname}${updatedUrl.search}`);
};
