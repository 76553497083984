import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { App as AntApp } from "antd";
import store from "store";
import { getInstalledApps } from "store/storage/selectors/applications_selector";
import { getSession, getUser, getUserRole } from "store/storage/selectors/session_selector";

import PublicApp from "apps/public_app/public_app";
import UserApp from "apps/user_app";

import Loading from "components/loading";

import initOnboardingApp from "utils/init_onboarding_app";
import initSupportApp from "utils/init_support_app";

import useUnhandledRejectionHandler from "./hooks/use_unhandled_rejection_handler";

import "styles/global.css";
import "antd/dist/reset.css";

const { App: AppActions, Applications } = store;

window.store = store;

const ROLE_TO_APP_MAPPING = {
  null: <PublicApp />,
  user: <UserApp />,
  admin: <UserApp />,
};

let message; // eslint-disable-line import/no-mutable-exports
let notification; // eslint-disable-line import/no-mutable-exports
let modal; // eslint-disable-line import/no-mutable-exports

export default function App() {
  const user = useSelector(getUser);
  const systemRole = useSelector(getUserRole);
  const session = useSelector(getSession);
  const installedApps = useSelector(getInstalledApps);
  const { appSettings } = session;
  const isInstalledAppsMissing = user && !installedApps;
  const isDesktop = useMediaQuery({ minWidth: 599 });

  const app = AntApp.useApp();
  message = app.message;
  notification = app.notification;
  modal = app.modal;

  useUnhandledRejectionHandler((errorMessage) => {
    message.error(
      <span data-cy="error_message">
        {errorMessage}
      </span>,
    );
  });

  useEffect(function loadRequiredAppSettings() {
    AppActions.loadAppSettings();
  }, []);

  useEffect(() => {
    if (!appSettings) {
      return;
    }

    document.title = appSettings.title || "Channex.io";
    initSupportApp(session, user, isDesktop);
    initOnboardingApp(session);
  }, [user, session, appSettings, isDesktop]);

  useEffect(
    function loadInstalledApplications() {
      if (!user || installedApps) {
        return;
      }

      Applications.loadInstalled();
    },
    [user, installedApps],
  );

  if (!appSettings || isInstalledAppsMissing) {
    return <Loading />;
  }

  return ROLE_TO_APP_MAPPING[systemRole];
}

export { message, notification, modal };
