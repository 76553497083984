import { REVIEWS_ADD, REVIEWS_LOAD, REVIEWS_RESET, REVIEWS_SET_REQUEST_ID } from "../constants";

export function reviewsLoad(storage) {
  return function (reviews, meta, requestId) {
    storage.dispatch({
      type: REVIEWS_LOAD,
      payload: { reviews, meta, requestId },
    });
  };
}

export function reviewsAdd(storage) {
  return function (review) {
    storage.dispatch({
      type: REVIEWS_ADD,
      payload: review,
    });
  };
}

export function reviewsSetRequestId(storage) {
  return function (id) {
    storage.dispatch({ type: REVIEWS_SET_REQUEST_ID, payload: id });
  };
}

export function reviewsReset(storage) {
  return function () {
    storage.dispatch({ type: REVIEWS_RESET });
  };
}
