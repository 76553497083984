export default [
  "apart_hotel",
  "apartment",
  "boat",
  "camping",
  "capsule_hotel",
  "chalet",
  "country_house",
  "farm_stay",
  "guest_house",
  "holiday_home",
  "holiday_park",
  "homestay",
  "hostel",
  "hotel",
  "inn",
  "lodge",
  "motel",
  "resort",
  "riad",
  "ryokan",
  "tent",
  "villa",
];
