import React from "react";
import { Tabs } from "antd";
import { notification } from "app";

import { EditWhiteLabelPartnerForm } from "./edit_form/edit_form";
import { WlDomains } from "./wl_domains/wl_domains";
import { WlEmailSettings } from "./wl_email_settings/wl_email_settings";

import styles from "drawers/channex_drawer.module.css";

export function ManageWhiteLabelPartner({ whiteLabelPartner }) {
  const handleSuccessEdit = () => {
    notification.success({
      message: "Success",
      description: "Changes successfully saved",
      duration: 1,
    });
  };

  const items = [{
    label: <span data-cy="white_label_partner_tab">White Label Partner</span>,
    key: "white_label_partner",
    children: (
      <EditWhiteLabelPartnerForm values={whiteLabelPartner} onFinish={handleSuccessEdit} />
    ),
  }, {
    className: [styles.noActionsInDrawer, styles.noPaddings],
    label: <span data-cy="domains_tab">Domains</span>,
    key: "domains",
    children: (
      <WlDomains whiteLabelPartnerId={whiteLabelPartner.id} />
    ),
  }, {
    className: [styles.noActionsInDrawer, styles.noPaddings],
    label: <span data-cy="email_settings_tab">Email Settings</span>,
    key: "email_settings",
    children: (
      <WlEmailSettings whiteLabelPartnerId={whiteLabelPartner.id} />
    ),
  }];

  return (
    <Tabs
      items={items}
      destroyInactiveTabPane
      defaultActiveKey={items[0].key}
    />
  );
}
