import attributesExtractor from "../utils/attributes_extractor";
import { convertToCamelCase, convertToSnakeCase } from "../utils/case_converter";
import { extractRelationshipIds } from "../utils/relationships_extractor";

let transport;

const ENDPOINT = "extras";

export class Extras {
  constructor(container) {
    transport = container.transport;
  }

  list(filter = {}) {
    const normalizedFilters = convertToSnakeCase(filter);

    return transport
      .send("GET", ENDPOINT, { filter: normalizedFilters })
      .then(({ data, meta }) => {
        return {
          data: data
            .map(extractRelationshipIds)
            .map(attributesExtractor)
            .map(convertToCamelCase),
          meta: convertToCamelCase(meta),
        };
      });
  }

  create(attrs) {
    const convertedAttrs = convertToSnakeCase({
      extra: attrs,
    });

    return transport
      .send("POST", ENDPOINT, convertedAttrs)
      .then(convertToCamelCase)
      .then((response) => {
        return attributesExtractor(response.data);
      });
  }

  update(id, attrs) {
    const body = convertToSnakeCase({
      extra: attrs,
    });

    return transport
      .send("PUT", `${ENDPOINT}/${id}`, body)
      .then(convertToCamelCase)
      .then((response) => {
        return attributesExtractor(response.data);
      });
  }

  delete(id) {
    return transport.send("DELETE", `${ENDPOINT}/${id}`);
  }
}
