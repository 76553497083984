import React from "react";
import _ from "lodash";

import { WhiteLabelPartnerForm } from "../../../white_label_partner_form/white_label_partner_form";

import { useUpdateAction } from "./use_update_action";

export function EditWhiteLabelPartnerForm({ values, onFinish }) {
  const { mutateAsync, isLoading } = useUpdateAction();

  const handleSubmit = async (formValues) => {
    const permittedAttrs = _.omit(formValues, ["id", "status", "internalSettings", "settings"]);

    const result = await mutateAsync({ id: formValues.id, attrs: permittedAttrs });
    onFinish(result);
  };

  return (
    <WhiteLabelPartnerForm
      values={values}
      isLoading={isLoading}
      onSubmit={handleSubmit}
    />
  );
}
