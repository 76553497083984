import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button } from "antd";

import BookingViewControlledDrawer from "drawers/booking_view_controlled_drawer";
import RevisionMessageDrawer from "drawers/revision_message_drawer";

import BookingRevision from "components/booking_revision";

import withRouter from "routing/with_router";

import { formatToLocal } from "utils/dates";

import styles from "./unmapped_room_content.module.css";

class UnmappedRoomContent extends Component {
  state = {
    rawMessageDrawer: false,
    bookingViewDrawer: false,
  };

  toggleDrawer = (name) => () => this.setState(({ [name]: drawerName }) => ({ [name]: !drawerName }));

  render() {
    const { t, issue, details } = this.props;
    const { rawMessageDrawer, bookingViewDrawer } = this.state;
    const { payload } = issue;
    const { booking_id, booking_revision_id } = payload;
    const {
      raw_message,
      room_types,
      rate_plans,
      channel,
      properties,
      booking_revisions = {},
    } = details;

    const revision = booking_revisions[booking_revision_id];

    if (!revision) {
      return null;
    }

    const updatedRevision = { ...revision, channel, properties };
    const { inserted_at, system_id } = revision;

    return (
      <>
        <div className={styles.header}>
          <div>
            <strong>
              {t("bookings_page:booking_view_dialog:system_id")}: {system_id}
            </strong>
            <br />
            <small>{formatToLocal(inserted_at, t("formats:date_time_with_seconds"))}</small>
          </div>
          <div>
            <strong>{`${t("bookings_page:booking_view_dialog:booking_id")}: `}</strong>
            <Button
              type="link"
              className={styles.bookingLink}
              onClick={this.toggleDrawer("bookingViewDrawer")}
            >
              {booking_id}
            </Button>
          </div>
        </div>

        <BookingRevision revision={updatedRevision} ratePlans={rate_plans} roomTypes={room_types} />
        <BookingRevision.RawLink onClick={this.toggleDrawer("rawMessageDrawer")} />

        <RevisionMessageDrawer
          visible={rawMessageDrawer}
          rawMessage={raw_message}
          onClose={this.toggleDrawer("rawMessageDrawer")}
        />

        <BookingViewControlledDrawer
          bookingId={booking_id}
          visible={bookingViewDrawer}
          onClose={this.toggleDrawer("bookingViewDrawer")}
        />
      </>
    );
  }
}

export default withTranslation()(withRouter(UnmappedRoomContent));
