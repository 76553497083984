import React from "react";

import { EventAttribute } from "./event_attribute";
import { EventDescription } from "./event_description";
import { EventTitle } from "./event_title";

export function BookingWebhookSent({ event }) {
  return (
    <>
      <EventTitle event={event} />
      <EventDescription>
        <EventAttribute label="URL" value={event.notes?.url} />
        <EventAttribute label="Is Success" value={event.notes?.success === true ? "Yes" : "No"} />
      </EventDescription>
    </>
  );
}
