import React, { useEffect, useState } from "react";
import store from "store";

import BookingRevision from "components/booking_revision";

const { Properties, RatePlans, RoomTypes } = store;

export default function ReservationBookingRequestContent({ eventData }) {
  const bms = eventData.payload.bms || eventData.payload;
  const [properties, setProperties] = useState({});
  const [ratePlans, setRatePlans] = useState({});
  const [roomTypes, setRoomTypes] = useState({});

  bms.status = "reservation_request";
  bms.ota_reservation_code = bms.raw_message.reservation.confirmation_code;
  bms.properties = properties;

  useEffect(
    function loadProperty() {
      Properties.find(bms.property_id).then((property) => {
        const output = {};
        output[bms.property_id] = property;
        setProperties(output);
      });
    },
    [bms.property_id],
  );

  useEffect(
    function loadRoomTypes() {
      RoomTypes.options({ property_id: bms.property_id }).then((roomTypesAnswer) => {
        setRoomTypes(
          roomTypesAnswer.reduce((acc, el) => {
            acc[el.id] = el;
            return acc;
          }, {}),
        );
      });
    },
    [bms.property_id],
  );

  useEffect(
    function loadRatePlans() {
      RatePlans.options({ property_id: bms.property_id }).then((ratePlansAnswer) => {
        setRatePlans(
          ratePlansAnswer.reduce((acc, el) => {
            acc[el.id] = el;
            return acc;
          }, {}),
        );
      });
    },
    [bms.property_id],
  );

  return <BookingRevision revision={bms} roomTypes={roomTypes} ratePlans={ratePlans} />;
}
