import {
  WHITE_LABEL_DOMAINS_ADD,
  WHITE_LABEL_DOMAINS_DROP,
  WHITE_LABEL_DOMAINS_LOAD,
} from "../constants";

export function whiteLabelDomainsLoad(storage) {
  return function (whiteLabelDomains) {
    storage.dispatch({ type: WHITE_LABEL_DOMAINS_LOAD, payload: whiteLabelDomains });
  };
}

export function whiteLabelDomainsAdd(storage) {
  return function (whiteLabelDomain) {
    storage.dispatch({ type: WHITE_LABEL_DOMAINS_ADD, payload: whiteLabelDomain });
  };
}

export function whiteLabelDomainsDrop(storage) {
  return function (whiteLabelDomain) {
    storage.dispatch({ type: WHITE_LABEL_DOMAINS_DROP, payload: whiteLabelDomain });
  };
}
