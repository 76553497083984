import React from "react";
import { UserOutlined } from "@ant-design/icons";

export default function (_label, selectedOptions) {
  if (!selectedOptions.length) {
    return null;
  }

  const [property, room] = selectedOptions;
  let ratePlan;

  if (selectedOptions.length === 4) {
    // if derived rate plan
    ratePlan = selectedOptions[3];
  } else {
    ratePlan = selectedOptions[2];
  }

  if (!property || !room || !ratePlan) {
    return null;
  }

  return (
    <>
      {property.title} / {room.title} /{" "}
      <span>
        {ratePlan.title} <UserOutlined />
        {ratePlan.occupancy}
      </span>
    </>
  );
}
