import AppStorageManager from "utils/app_storage_manager";

import { USER_ADD, USER_UPDATE } from "../constants";

const USER_KEY = "CHANNEX_USER";

const initialUser = AppStorageManager.getItem(USER_KEY);

const ACTION_HANDLERS = {
  [USER_ADD]: (state, action) => {
    return action.payload;
  },
  [USER_UPDATE]: (state, action) => {
    return { ...state, ...action.payload };
  },
};

export default function userReducer(state = initialUser, action) {
  const handler = ACTION_HANDLERS[action.type];
  const updatedState = handler ? handler(state, action) : state;

  AppStorageManager.setItem(USER_KEY, updatedState);

  return updatedState;
}
