import { useTranslation } from "react-i18next";

import { Workflow } from "components/workflow/workflow";

import { WebSocketProvider } from "../../web_socket_context";

import { AuthStep } from "./steps/auth_step";
import { ConnectPropertyStep } from "./steps/connect_property_step";
import { ResultStep } from "./steps/result_step";
import { SettingsStep } from "./steps/settings_step";

export function ApaleoImportWorkflow({ initialStep, initialData, appName }) {
  const { t } = useTranslation();

  const steps = [{
    id: "auth",
    title: t("apaleo_import:titles:authenticate"),
    render: (workflowProps) => (
      <AuthStep {...workflowProps} appName={appName} />
    ),
  }, {
    id: "selectProperty",
    title: t("apaleo_import:titles:connect_property"),
    nextButtonTitle: t("apaleo_import:buttons:import"),
    render: (workflowProps) => (
      <ConnectPropertyStep {...workflowProps} appName={appName} />
    ),
  }, {
    id: "settings",
    title: t("apaleo_import:titles:settings"),
    backButton: false,
    render: (workflowProps) => (
      <SettingsStep {...workflowProps} />
    ),
  }, {
    id: "result",
    title: t("apaleo_import:titles:result"),
    render: (workflowProps) => (
      <ResultStep {...workflowProps} appName={appName} />
    ),
  }];

  return (
    <WebSocketProvider>
      <Workflow
        initialStep={initialStep}
        initialData={initialData}
        steps={steps}
        showStartAgainButton
        startAgainButtonTitle={t("apaleo_import:buttons:start_again")}
      />
    </WebSocketProvider>
  );
}
