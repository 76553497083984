import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button, Form, Tabs } from "antd";
import { Formik } from "formik";
import _ from "lodash";
import store from "store";
import * as yup from "yup";

import APP_MODES from "config/constants/app_modes";
import channelCodes from "config/constants/channels/channel_codes";
import currencyRestrictedChannels from "config/constants/channels/currency_restricted_channels";
import errorMessages from "config/constants/errors";

import ErrorBoundary from "components/error_boundary";
import SubmitButton from "components/forms/buttons/submit_button";
import Loading from "components/loading";

import withComponentRef from "containers/with_component_ref";

import confirmDirtyStateSave from "utils/confirm_dirty_state_save";
import convertToHashmap from "utils/convert_to_hashmap";
import EventEmitter from "utils/event_emitter";
import handleActionError from "utils/handle_action_error";
import showErrorMessage from "utils/show_error_message";

import AirBnbRatePlans from "./components/airbnb/rate_plans";
import ChannelSettings from "./components/channel_settings";
import ChannelSubForm from "./components/channel_sub_form/channel_sub_form_component";
import ConfirmUnmappedModal from "./components/confirm_unmapped_modal/confirm_unmapped_modal";
import AirBNBListing from "./components/listing/air_bnb";
import Mapping, { CHANNELS_TO_MAPPING } from "./components/mapping";
import MissingContent from "./components/missing_content";
import AirBNBOpportunities from "./components/opportunities/air_bnb";
import { buildOrderedMappingSchema } from "./utils/build_ordered_mapping_schema";
import getChannelMultiOccupancyStatus from "./utils/get_channel_multi_occupancy_status";
import loadChannelWithMapping from "./utils/load_channel_with_mapping";
import { getUnusedMappings } from "./utils/mapping";
import { getChannelTabs } from "./channels";

import styles from "styles/form_in_drawer.module.css";

const { Channels, RatePlans, RoomTypes, Properties } = store;

const SETTINGS_TAB = "settings_tab";
const MAPPING_TAB = "mapping_tab";
const CHANNEL_DERIVED_SETTINGS_TAB = "channel_derived_settings_tab";
const MISSING_CONTENT_TAB = "missing_content_tab";
const LISTING_TAB = "listing_tab";
const AIRBNB_RATE_PLANS_TAB = "airbnb_rate_plans_tab";
const OPPORTUNITIES_TAB = "opportunities_tab";

const DEFAULT_TAB = SETTINGS_TAB;

const DEFAULT_CONNECTION_SETTINGS_VALUES = {
  number: null,
  string: "",
  boolean: false,
  file: null,
};

const PLAIN_MAPPING_CHANNELS = [
  "BookingCom",
  "Expedia",
  "AirBNB",
  "OpenChannel",
  "Hostelworld",
  "LocalOTA",
  "RoomCloud",
  "HyperGuest",
  "Agoda",
  "CultBooking",
  "Cultbooking",
  "TheSquare",
  "AdvertisingOnline",
  "Hotelbeds",
  "OpenGDS",
  "Despegar",
  "CTrip",
  "Webrooms",
  "Inntopia",
  "Travia",
  "Bakuun",
  "stayforRewards",
  "Traveloka",
  "Spot2Nite",
  "VRBO",
  "Tripla",
  "OTASync",
  "TiketCom",
  "MGBedbank",
  "Travelgatex",
  "Feratel",
  "DreamIreland",
  "MitchellCorp",
  "HRS",
  "Goibibo",
  "OmniHotelier",
  "Guestaps",
  "Hertz",
  "Avis",
  "Budget",
  "Payless",
  "Europcar",
  "AtlantisORS",
  "Hipcamp",
  "ACEBookingEngine",
  "Lido",
  "HotelNetwork",
  "Getaroom",
  "PegiPegi",
  "Ostrovok",
  "PriceTravel",
  "Szallas",
  "MrAndMrsSmith",
  "Wink",
  "Webready",
  "Hookusbookus",
  "Bookeasy",
  "RoomBeast",
  "Reconline",
  "STC",
  "Roibos",
  "BookOutdoors",
  "HostelHop",
  "Hoterip",
  "DidaTravel",
  "HotelTonight",
  "TheTPMGroup",
  "SleepRest",
  "Moverii",
  "TabletHotels",
  "SeminyakVillas",
  "SissaeLiving",
  "B2BGlobal",
  "RVPARKGURU",
  "CampingVision",
  "Bookola",
];

const CONTRACT_SEQUENCE_CHANNELS = ["Hotelbeds", "Roibos"];

const GOOGLE_VR_ACCOUNT_TYPE = "VR";
const GOOGLE_HOTEL_ACCOUNT_TYPE = "Hotel";
const DEFAULT_GOOGLE_VR_PARTNER_ACCOUNT = "Channex";
const DEFAULT_GOOGLE_HOTEL_PARTNER_ACCOUNT = "Channex ARI";

class ChannelManagement extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    id: PropTypes.string,
    availableGroupsOptions: PropTypes.array,
    availablePropertiesOptions: PropTypes.array,
  };

  static contextType = ErrorBoundary.Context;

  schema = yup.object({
    settings: yup
      .object({
        derived_option: yup.object({
          rate: yup.array().of(
            yup
              .array()
              .nullable()
              .test("check array data", errorMessages.required(), (value) => {
                const [modificator, amount] = value || [];
                const isParamsIncomplete = Boolean(modificator) + Boolean(amount) === 1;

                return !isParamsIncomplete;
              }),
          ),
        }),
      })
      .nullable(),
  });

  state = {
    isLoading: true,
    mappingStatus: null,
    submitInProgress: false,
    confirmUnmappedModalVisible: false,
    mappingChanged: false,
    connectionSettings: {},
    restoredFormValue: null,
    activeTab: this.props.defaultTab || DEFAULT_TAB,
    isRatesSelectEnabled: false,
    isSubmitEnabled: true,
    ratePlans: null,
    roomTypes: null,
    propertiesOptions: null,
    propertiesOptionsById: {},
    bookingComPricingChangeInProgress: false,
  };

  form = React.createRef();

  componentDidMount() {
    const { availablePropertiesOptions, availableGroupsOptions } = this.props;

    if (availablePropertiesOptions && availableGroupsOptions) {
      this.loadChannel();
    }

    EventEmitter.bind("channel_management:invalid_token", this.handleResponseErrors);
  }

  componentDidUpdate(prevProps) {
    const { availableGroupsOptions, availablePropertiesOptions, id } = this.props;

    if (id !== prevProps.id) {
      this.loadChannel().then(() => {
        this.form.current.resetForm({ values: this.state.model });
        this.setState({ isSubmitEnabled: true });
      });

      return;
    }

    if (
      availableGroupsOptions
      && availablePropertiesOptions
      && (prevProps.availableGroupsOptions === null || prevProps.availablePropertiesOptions === null)
    ) {
      this.loadChannel();
    }
  }

  componentWillUnmount() {
    EventEmitter.unbind("channel_management:invalid_token", this.handleResponseErrors);
  }

  loadChannel = () => {
    const {
      id,
      activeGroup,
      activeProperty,
      availableGroupsOptions,
      availablePropertiesOptions,
    } = this.props;
    const { restoredFormValue } = this.state;

    return loadChannelWithMapping(
      id,
      activeGroup,
      activeProperty,
      availableGroupsOptions,
      availablePropertiesOptions,
      restoredFormValue,
    )
      .then(this.prepareState)
      .catch(this.onLoadingError);
  };

  onLoadingError = (error) => {
    const { handleError } = this.context;
    handleError(error);
  };

  setVRTypeForGoogle = () => {
    const { values } = this.form.current;
    if (values.properties) {
      const property_type_group = this.props.availablePropertiesOptions.filter(
        (property) => property.id === values.properties[0],
      )[0]?.property_type_group || "hotel";
      values.settings.account_type = property_type_group === "vacation_rental"
        ? GOOGLE_VR_ACCOUNT_TYPE
        : GOOGLE_HOTEL_ACCOUNT_TYPE;
    }
  };

  setChannexVRAccountForGoogle = () => {
    const { values } = this.form.current;
    values.settings.partner_account = values.settings.account_type === GOOGLE_VR_ACCOUNT_TYPE
      ? DEFAULT_GOOGLE_VR_PARTNER_ACCOUNT
      : DEFAULT_GOOGLE_HOTEL_PARTNER_ACCOUNT;
  };

  loadMappingDetails = (settings, channel) => {
    const { selectedChannel, model } = this.state;
    const { token_invalid } = model.settings || {};

    this.setState({ mappingStatus: "loading" });

    if (selectedChannel && selectedChannel.code === "GoogleHotelARI") {
      this.setVRTypeForGoogle();
    }

    if (!selectedChannel || token_invalid) {
      this.setState({
        mappingStatus: null,
        mappingOptions: null,
      });

      return Promise.resolve();
    }

    if (selectedChannel.mappingSource === "user") {
      if (_.isEmpty(settings.mapping)) {
        this.setState({ mappingStatus: null });
      } else {
        this.setState({
          mappingStatus: "loaded",
          mappingOptions: settings.mapping,
        });
      }

      return Promise.resolve();
    }

    return Channels.getMappingDetails({ settings, channel })
      .then((response) => {
        const { data } = response;
        const { errors } = data;

        if (errors) {
          this.handleResponseErrors(errors);
          return;
        }

        let mappingOptions;

        if (PLAIN_MAPPING_CHANNELS.includes(selectedChannel.code)) {
          mappingOptions = data;
        } else {
          // tree mapping channels
          mappingOptions = Object.keys(data).reduce((acc, el) => {
            acc[el] = data[el].reduce(
              (valuesAcc, value) => {
                valuesAcc[value.id] = value.title;
                return valuesAcc;
              },
              { values: data[el] },
            );

            return acc;
          }, {});
        }

        this.setState({
          mappingStatus: "loaded",
          mappingOptions,
        });

        if (CONTRACT_SEQUENCE_CHANNELS.includes(selectedChannel.code) && settings.contract_sequence) {
          this.setState({ isRatesSelectEnabled: true });
        }
      })
      .catch(() => {
        this.setState({
          mappingStatus: "error",
          mappingOptions: null,
        });
      });
  };

  loadConnectionSettings = (settings, channel) => {
    Channels.getConnectionsDetails({ settings, channel })
      .then(({ attributes }) => {
        const currency = attributes.currency || null;
        this.form.current.setFieldValue("currency", currency);

        this.setState((state) => ({
          connectionSettings: attributes,
          model: {
            ...state.model,
            currency,
          },
        }), this.loadRatePlans);
      })
      .catch((error) => {
        if (!error.isBadRequest) {
          throw error;
        }

        this.setState({
          mappingStatus: "error",
          mappingOptions: null,
          ratePlans: [],
        });
      });
  };

  loadRatePlans = async () => {
    const { values = {} } = this.form.current;
    const { properties, channel } = values;

    if (!properties || !properties.length) {
      this.setState({ ratePlans: [] });
      return;
    }

    const multiOccupancy = getChannelMultiOccupancyStatus(channel);

    const filter = { property_id: properties };
    const options = { multi_occupancy: multiOccupancy };

    const ratePlans = await RatePlans.options(filter, options);

    this.setState({ ratePlans });
  };

  handleResponseErrors = (errors) => {
    const { t } = this.props;
    const { selectedChannel } = this.state;
    const { credentialsSource } = selectedChannel;
    const { error } = errors;

    if (error === "invalid_token" && credentialsSource === "oauth") {
      showErrorMessage(t("general:errors:invalid_token"));

      this.setState({
        mappingStatus: "error",
        mappingOptions: null,
        activeTab: DEFAULT_TAB,
        isRatesSelectEnabled: false,
      });

      this.loadChannel();
      return;
    }

    this.setState({
      mappingStatus: "error",
      mappingOptions: null,
    });
  };

  getGroupProperties = (id, propertiesOptions) => {
    return propertiesOptions.filter((prop) => prop.group_ids.includes(id));
  };

  getChannelProperties = ({ properties }) => {
    const { availablePropertiesOptions } = this.props;

    const missingPropertyIds = properties.filter((propertyId) => {
      const isPropertyPresent = availablePropertiesOptions.find(
        (property) => property.id === propertyId,
      );

      return !isPropertyPresent;
    });

    if (!missingPropertyIds.length) {
      return Promise.resolve(availablePropertiesOptions);
    }

    const promises = _.chunk(missingPropertyIds, 10).map((ids) => {
      return Properties.options({ id: ids }).then((missingProperties) => missingProperties);
    }, []);

    return Promise.all(promises).then((results) => {
      return [..._.flatten(results), ...availablePropertiesOptions];
    });
  };

  prepareState = async (payload) => {
    const { allowedChannels, appMode } = this.props;

    const {
      model,
      ratePlans,
      roomTypes,
      availableChannels,
      isRatesSelectEnabled,
      selectedChannel,
      mappingStatus,
      connectionSettings,
    } = payload;

    const isHeadlessMode = appMode === APP_MODES.HEADLESS;
    const isChannelRestricted = Object.keys(allowedChannels).length;

    const filteredChannels = isHeadlessMode && isChannelRestricted
      ? availableChannels.filter(({ code }) => allowedChannels[code])
      : availableChannels;

    const propertiesOptions = await this.getChannelProperties(model);
    const propertiesOptionsById = convertToHashmap(propertiesOptions);
    const groupProperties = this.getGroupProperties(model.group_id, propertiesOptions);

    return new Promise((resolve) => {
      this.setState({
        isLoading: false,
        isRatesSelectEnabled,
        selectedChannel,
        model,
        connectionSettings,
        ratePlans,
        roomTypes,
        availableChannels: filteredChannels,
        groupProperties,
        mappingStatus,
        propertiesOptions,
        propertiesOptionsById,
      }, () => {
        this.loadMappingDetails(model.settings, model.channel)
          .then(() => {
            if (isRatesSelectEnabled) {
              this.initMappingSettings();
            }

            resolve();
          });
      });
    });
  };

  onError = (response, form) => {
    const { t } = this.props;

    this.setState({ submitInProgress: false });
    const { errors } = response || {};

    if (errors && errors.code) {
      const isChannelSettingsError = errors.code === "validation_error" && errors.details;

      if (isChannelSettingsError) {
        const { settings, channel, title } = errors.details;
        const formErrors = { channel, title };

        if (settings) {
          settings.forEach((errorMessage) => {
            showErrorMessage(errorMessage);
          });
        }

        form.setTouched(formErrors);
        form.setErrors(formErrors);

        this.setState({ activeTab: DEFAULT_TAB });
      } else {
        showErrorMessage(t(`general:${errors.code}`));
      }
    } else {
      showErrorMessage(t("general:undefined_error"));
    }
  };

  handleSubmit = (value, form) => {
    const { mappingChanged, mappingOptions, selectedChannel, isRatesSelectEnabled } = this.state;

    // skip checking mapping for channels without mapping
    if (!isRatesSelectEnabled) {
      this.submit(value, form);

      return;
    }

    // channels support selecting only rate plans, so we don't check for unmapped options
    if (
      selectedChannel.mapping_mode === "direct"
      || selectedChannel.mapping_mode === "room_rate_multioccupancy"
    ) {
      this.submit(value, form);

      return;
    }

    // we should show unmapped confirmation modal only for direct view
    if (value.settings.mappingSettings && value.settings.mappingSettings.reverseMapping) {
      this.submit(value, form);

      return;
    }

    // if user didn't changed mapping for existing channel we don't show him a modal,
    // because he already confirmed this mapping state
    if (value.id && !mappingChanged) {
      this.submit(value, form);

      return;
    }

    // if user didn't correctly fill connection settings and we don't have mapping options
    if (!mappingOptions) {
      this.submit(value, form);

      return;
    }

    // Airbnb doesn't need to check for unmapped options
    if (selectedChannel.code === "AirBNB") {
      this.submit(value, form);

      return;
    }

    const unusedMappings = getUnusedMappings(
      value.mappings,
      mappingOptions,
      selectedChannel.rate_params,
    );
    if (Object.keys(unusedMappings).length === 0) {
      this.submit(value, form);

      return;
    }

    // submit continues after modal confirmation
    this.setState({
      confirmUnmappedModalVisible: true,
      unusedMappings,
    });
  };

  filterDerivedOptions = (derivedOption) => {
    const { rate = [] } = derivedOption || {};

    const filteredRates = rate.filter((rateParams) => rateParams && rateParams[0] && rateParams[1]);

    if (!filteredRates.length) {
      return {};
    }

    return { rate: filteredRates };
  };

  submit = (values, form) => {
    const { mappingOptions } = this.state;
    const { onClose } = this.props;
    const { mappings, settings, ...model } = values;
    const { pricing_type } = mappingOptions || {};

    const derivedOption = this.filterDerivedOptions(settings?.derived_option);
    const updatedSettings = { ...settings, derived_option: derivedOption };

    const ratePlans = Object.keys(mappings || {}).reduce((acc, el) => {
      const mapping = mappings[el];

      if (Array.isArray(mapping)) {
        mapping.forEach((entry) => {
          if (entry) {
            entry = {
              ...entry,
              ...(pricing_type ? { pricing_type } : {}),
            };

            if (model.channel === channelCodes.Hostelworld.code) {
              const externalRoom = mappingOptions.rooms.find(
                (room) => room.id === entry.room_type_code,
              );

              if (externalRoom) {
                entry.occupancy = externalRoom.occupancy;
                entry.type = externalRoom.type;
              }
            }

            if (model.channel === channelCodes.Feratel.code) {
              const externalRoom = mappingOptions.rooms.find(
                (room) => room.id === entry.room_type_code,
              );

              if (externalRoom) {
                const externalRate = externalRoom.rates.find(
                  (rate) => rate.id === entry.rate_plan_code,
                );

                if (externalRate) {
                  entry.pricing_type = externalRate.pricing_type;
                }
              }
            }
          }

          acc.push({
            rate_plan_id: el,
            settings: entry,
          });
        });

        return acc;
      }

      const ratePlan = {
        rate_plan_id: el,
        settings: mapping,
        derived_option: derivedOption,
      };

      if (model.channel === channelCodes.AirBNB.code) {
        ratePlan.id = mapping ? mapping.id : null;
      }

      if (model.channel === channelCodes.VRBO.code) {
        const options = mappingOptions.property_id_dictionary?.values || [];
        const option = options.filter((opt) => opt.id === ratePlan.settings?.property_id)[0];

        if (option) {
          ratePlan.settings.property_url = option.url;
        }
      }

      return [...acc, ratePlan];
    }, []);

    this.setState({ submitInProgress: true });

    const modelAttrs = {
      ...model,
      settings: updatedSettings,
      rate_plans: ratePlans,
      is_active: model.is_active || false,
    };

    const request = modelAttrs.id ? Channels.update : Channels.create;

    request(modelAttrs)
      .then(() => onClose())
      .catch((response) => this.onError(response, form));
  };

  nextTab = () => {
    this.setState({
      activeTab: MAPPING_TAB,
      focusField: null,
    });
  };

  handleTabChange = (activeTab) => {
    this.setState({
      activeTab,
      focusField: null,
    });
  };

  beforeClose = () => {
    if (!this.form.current) {
      return Promise.reject();
    }

    const { values, dirty } = this.form.current;

    // for new airbnb channel we don't need to show confirmation save
    if (values.channel === "AirBNB" && !values.id) {
      return Promise.reject();
    }

    return confirmDirtyStateSave(dirty);
  };

  handleChannelChange = (newChannel, field, form) => {
    const { availableChannels, model, ratePlans } = this.state;
    const oldChannel = form.values[field.name];

    let isSubmitEnabled = true;

    // set channel field value
    form.setFieldValue(field.name, newChannel);

    const selectedChannel = availableChannels.find((channel) => channel.code === newChannel);

    let isRatesSelectEnabled = selectedChannel.rate_params !== null || selectedChannel.mapping_mode !== null;

    // hotelbeds and roibos also should have defined contract to enable mappings tab
    if (CONTRACT_SEQUENCE_CHANNELS.includes(selectedChannel.code)) {
      isRatesSelectEnabled = isRatesSelectEnabled && form.values.settings.contract_sequence;
    }

    if (selectedChannel.credentialsSource === "oauth") {
      isSubmitEnabled = false;
      isRatesSelectEnabled = false;
    }

    const settings = selectedChannel.params;

    // if selected channel have settings for connection then initialize them according to type
    if (settings) {
      const settingsAttrs = Object.keys(settings).reduce((acc, key) => {
        const { [key]: existingSettingsParam } = acc;

        if (existingSettingsParam !== undefined) {
          return acc;
        }

        const { [key]: newSettingsParam } = settings;
        const defaultValue = DEFAULT_CONNECTION_SETTINGS_VALUES[newSettingsParam.type];
        const { default: initialValue = defaultValue } = newSettingsParam;

        return { ...acc, [key]: initialValue };
      }, model.settings);

      if (
        !settingsAttrs.mappingSettings.orderedMappingSchema
        || settingsAttrs.mappingSettings.orderedMappingSchema.length === 0
      ) {
        settingsAttrs.mappingSettings.orderedMappingSchema = buildOrderedMappingSchema(
          selectedChannel.rate_params,
        );
      }

      form.setFieldValue("settings", settingsAttrs);
    } else {
      form.setFieldValue("settings", undefined);
    }

    // set new selectedChannel and mapping presence flag
    this.setState(
      {
        selectedChannel,
        mappingStatus: null,
        mappingOptions: null,
        connectionSettings: {},
        isRatesSelectEnabled,
        isSubmitEnabled,
      },
      () => {
        const isOldChannelCurrencyRestricted = currencyRestrictedChannels.includes(oldChannel);
        const isNewChannelCurrencyRestricted = currencyRestrictedChannels.includes(newChannel);
        const oldChannelMultiOccypancyStatus = getChannelMultiOccupancyStatus(oldChannel);
        const newChannelMultiOccupancyStatus = getChannelMultiOccupancyStatus(newChannel);

        const isMultiOccupancyStatusChanged = oldChannelMultiOccypancyStatus !== newChannelMultiOccupancyStatus;
        const isRatesNotLoaded = ratePlans === null;

        const isRatePlansShouldBeLoaded = !isNewChannelCurrencyRestricted
          && (isRatesNotLoaded || isOldChannelCurrencyRestricted || isMultiOccupancyStatusChanged);

        if (isRatePlansShouldBeLoaded) {
          this.loadRatePlans();
        }

        if (newChannel === "GoogleHotelARI") {
          this.setVRTypeForGoogle();
          this.setChannexVRAccountForGoogle();
        }
      },
    );
  };

  handleGroupChange = (value, field, form) => {
    form.setFieldValue(field.name, value);
    const { propertiesOptions } = this.state;

    const groupProperties = this.getGroupProperties(value, propertiesOptions);

    const chosenProperties = form.values.properties;
    const groupPropertiesIds = groupProperties.map((g) => g.id);

    const allowedProperties = _.intersection(chosenProperties, groupPropertiesIds);

    if (allowedProperties.length !== chosenProperties.length) {
      form.setFieldValue("properties", allowedProperties);
    }

    this.setState({ groupProperties });
  };

  loadRoomTypes = async () => {
    const { values = {} } = this.form.current;
    const { properties } = values;

    const roomTypes = await RoomTypes.options({ property_id: properties });

    this.setState({ roomTypes });
  };

  handlePropertiesChange = (value, field, form) => {
    value = Array.isArray(value) ? value : [value];

    form.setFieldValue(field.name, value).then(() => {
      this.loadRatePlans();
      this.loadRoomTypes();

      if (form.values.channel === "GoogleHotelARI") {
        this.setVRTypeForGoogle();
        this.setChannexVRAccountForGoogle();
      }
    });
  };

  handleConnectionChecked = ({ settings, channel }) => () => {
    const { selectedChannel, isRatesSelectEnabled } = this.state;

    if (currencyRestrictedChannels.includes(selectedChannel.code) || CONTRACT_SEQUENCE_CHANNELS.includes(selectedChannel.code)) {
      this.loadConnectionSettings(settings, channel);
    }

    if (!CONTRACT_SEQUENCE_CHANNELS.includes(selectedChannel.code)) {
      this.loadMappingDetails(settings, channel);
    }

    if (isRatesSelectEnabled) {
      this.initMappingSettings();
    }
  };

  handleConfirmUnmappedModalOk = () => {
    const { values } = this.form.current;

    this.setState({
      confirmUnmappedModalVisible: false,
    });

    this.submit(values);
  };

  handleConfirmUnmappedModalCancel = () => {
    this.setState({
      confirmUnmappedModalVisible: false,
    });
  };

  handleMappingOptionsChange = (newMappingOptions) => this.setState({ mappingOptions: newMappingOptions });

  initMappingSettings = () => {
    const { selectedChannel, model, ratePlans } = this.state;

    model.settings = model.settings || {};
    model.settings.mappingSettings = model.settings.mappingSettings || {};

    if (
      !model.settings.mappingSettings.orderedMappingSchema
      || model.settings.mappingSettings.orderedMappingSchema.length === 0
    ) {
      model.settings.mappingSettings.orderedMappingSchema = buildOrderedMappingSchema(selectedChannel.rate_params);
    }

    if (CHANNELS_TO_MAPPING[model.channel] === "ExternalRoomRate") {
      this._ensureRoomMappingSettingExistsForMappedRates(model, ratePlans);
    }
  };

  // `channel.settings.mappingSettings.rooms` contains mapping information between external room and channex room type.
  // This is used to limit available for mapping rate plans from selected room type only.
  //
  // If channel was created through channex api it would not contain mappingSettings.rooms, because this field is created
  // and managed by UI. We should ensure that all mapped rates have its room in mappingSettings.rooms.
  // Cases when for some reason rates mapped from different rooms within single external rooms are not supported,
  // first rate in list would win and set its room as mapped room to external room
  _ensureRoomMappingSettingExistsForMappedRates = (channel, ratePlans) => {
    if (channel.rate_plans.length > 0 && !channel.settings.mappingSettings.rooms) {
      channel.settings.mappingSettings.rooms = {};
    }

    channel.rate_plans.forEach((mapping) => {
      const { rate_plan_id: ratePlanId, settings: { room_type_code: roomTypeCode } } = mapping;

      if (channel.settings.mappingSettings.rooms[roomTypeCode]) {
        return;
      }

      const mappedRatePlan = ratePlans.find((rp) => rp.id === ratePlanId);
      channel.settings.mappingSettings.rooms[roomTypeCode] = mappedRatePlan.room_type_id;
    });
  };

  _renderListingTab = (form) => {
    const { t } = this.props;
    const { selectedChannel, mappingOptions, isRatesSelectEnabled } = this.state;
    const { code } = selectedChannel || {};

    if (code !== "AirBNB") {
      return null;
    }

    return (
      <Tabs.TabPane
        tab={t("channels_page:form:tabs:listing_tab")}
        key={LISTING_TAB}
        disabled={!isRatesSelectEnabled}
      >
        <AirBNBListing
          form={form}
          onRefresh={this.handleChannelRefresh}
          mappingOptions={mappingOptions}
        />
      </Tabs.TabPane>
    );
  };

  _renderAirbnbRatePlansTab = (form) => {
    const { t, id, allowAirbnbRatePlans } = this.props;
    const { selectedChannel, model, isRatesSelectEnabled } = this.state;
    const { code } = selectedChannel || {};

    if (code !== "AirBNB") {
      return null;
    }

    if (!allowAirbnbRatePlans && !model.settings?.rate_plans_enabled) {
      return null;
    }

    return (
      <Tabs.TabPane
        tab={t("channels_page:form:tabs:airbnb_rate_plans_tab")}
        key={AIRBNB_RATE_PLANS_TAB}
        disabled={!isRatesSelectEnabled}
      >
        <AirBnbRatePlans
          channelId={id}
          settings={model.settings}
          mappings={model.mappings}
          onMappingOptionsReload={this.handleMappingRefresh(form.values)}
          onChannelRefresh={() => {
            this.handleChannelRefresh()
              .then(() => {
                // channel model in state is refreshed, but also we need to update channel model in form
                // logically this should be part of the handleChannelRefresh() but not sure which side effects it might have, for now it's here
                this.form.current.resetForm({ values: this.state.model });
              });
          }}
        />
      </Tabs.TabPane>
    );
  };

  _renderOpportunitiesTab = (form) => {
    const { t } = this.props;
    const { selectedChannel, mappingOptions, isRatesSelectEnabled } = this.state;
    const { code } = selectedChannel || {};

    if (code === "AirBNB") {
      return (
        <Tabs.TabPane
          tab={t("channels_page:form:tabs:opportunities_tab")}
          key={OPPORTUNITIES_TAB}
          disabled={!isRatesSelectEnabled}
        >
          <AirBNBOpportunities
            form={form}
            onRefresh={this.handleChannelRefresh}
            mappingOptions={mappingOptions}
          />
        </Tabs.TabPane>
      );
    }

    return null;
  };

  handleMappingRefresh = ({ settings, channel }) => () => {
    return Promise.all([
      this.loadMappingDetails(settings, channel),
      this.loadRatePlans(),
      this.loadRoomTypes(),
    ]).catch(handleActionError);
  };

  handleChannelRefresh = () => {
    return this.loadChannel();
  };

  handleKnownMappingDelete = (mappingToDelete, form) => {
    const { known_mappings_list } = form.values;

    Channels.removeKnownMapping(mappingToDelete.id)
      .then(() => {
        const updatedKnownMappings = known_mappings_list.filter(
          (mapping) => mapping.id !== mappingToDelete.id,
        );

        form.setFieldValue("known_mappings_list", updatedKnownMappings);
      })
      .catch(handleActionError);
  };

  handleChannelSettingsNavigateClick = ({ tab, focusField }) => {
    this.setState({
      activeTab: tab,
      focusField,
    });
  };

  handleChangePricingTypeForBookingCom = (event) => {
    const { model } = this.state;
    this.setState({ bookingComPricingChangeInProgress: true });
    Channels.change_pricing_type(this.props.id, event.target.value).then(() => {
      this.loadMappingDetails(model.settings, model.channel)
        .then(() => {
          this.setState({ bookingComPricingChangeInProgress: false });
        });
    });
  };

  _renderChannelTabs = (form) => {
    const { activeFeatureFlags } = this.props;
    const { selectedChannel, model } = this.state;
    const { code } = selectedChannel || {};

    const tabs = getChannelTabs(code, activeFeatureFlags);

    return (
      <>
        {tabs.map(({ label, key, enabled, Component: TabComponent }) => (
          <Tabs.TabPane
            style={{ display: "flex", flexDirection: "column" }}
            tab={label}
            key={key}
            disabled={!enabled()}
          >
            <TabComponent
              model={model}
              onChange={(channelAttrs) => {
                Object.entries(channelAttrs).forEach(([attr, value]) => {
                  form.setFieldValue(attr, value);
                });
              }}
            />
          </Tabs.TabPane>
        ))}
      </>
    );
  };

  render() {
    const {
      model,
      submitInProgress,
      confirmUnmappedModalVisible,
      activeTab,
      mappingOptions,
      mappingStatus,
      availableChannels,
      isRatesSelectEnabled,
      roomTypes,
      ratePlans,
      isLoading,
      selectedChannel,
      connectionSettings,
      groupProperties,
      unusedMappings,
      focusField,
      isSubmitEnabled,
      propertiesOptionsById,
      bookingComPricingChangeInProgress,
    } = this.state;

    const {
      t,
      id,
      availableGroupsOptions,
      availablePropertiesOptions,
      appMode,
      activeProperty,
    } = this.props;

    if (isLoading) {
      return <Loading />;
    }

    const mappingSchema = selectedChannel ? selectedChannel.rate_params : null;
    const ratesPlansArray = ratePlans ?? [];
    const roomTypesArray = roomTypes ?? [];

    return (
      <>
        <Formik
          initialValues={model}
          validationSchema={this.schema}
          innerRef={this.form}
          onSubmit={this.handleSubmit}
        >
          {(form) => {
            let isSinglePropertySelect = false;

            if (selectedChannel) {
              const { property_mapping } = selectedChannel;
              isSinglePropertySelect = property_mapping !== "multiple";
            }

            return (
              <Form onFinish={form.handleSubmit}>
                <Tabs activeKey={activeTab} onChange={this.handleTabChange}>
                  <Tabs.TabPane
                    tab={
                      <span data-cy="general_settings_tab">
                        {t("channels_page:form:tabs:settings_tab")}
                      </span>
                    }
                    key={SETTINGS_TAB}
                  >
                    <ChannelSubForm
                      form={form}
                      appMode={appMode}
                      selectedChannel={selectedChannel}
                      isShowPropertySelect={!!selectedChannel}
                      isSinglePropertySelect={isSinglePropertySelect}
                      channelId={form.values.id}
                      availableChannels={availableChannels}
                      properties={groupProperties}
                      activeProperty={activeProperty}
                      propertyOptions={availablePropertiesOptions}
                      groups={availableGroupsOptions}
                      connectionSettings={connectionSettings}
                      settingsSchema={selectedChannel ? selectedChannel.params : null}
                      credentialsSource={selectedChannel ? selectedChannel.credentialsSource : null}
                      mappingSchema={mappingSchema}
                      mappingOptions={mappingOptions}
                      focusField={focusField}
                      onMappingDetailsLoad={this.loadMappingDetails}
                      onChannelChange={this.handleChannelChange}
                      onGroupChange={this.handleGroupChange}
                      onPropertiesChange={this.handlePropertiesChange}
                      onConnectionChecked={this.handleConnectionChecked(form.values)}
                      onChangeBookingComPricingMode={this.handleChangePricingTypeForBookingCom}
                      bookingComPricingChangeInProgress={bookingComPricingChangeInProgress}
                    />
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab={
                      <span data-cy={MAPPING_TAB}>{t("channels_page:form:tabs:mapping_tab")}</span>
                    }
                    key={MAPPING_TAB}
                    disabled={!isRatesSelectEnabled}
                  >
                    {isRatesSelectEnabled && (
                      <Mapping
                        channelId={form.values.id}
                        channelTitle={selectedChannel.title}
                        channelCode={selectedChannel.code}
                        settings={form.values.settings}

                        mappingSchema={mappingSchema}
                        mappingOptions={mappingOptions}
                        mappingStatus={mappingStatus}
                        mappingSettings={form.values.settings?.mappingSettings}
                        mappings={form.values.mappings}
                        knownMappings={form.values.known_mappings_list} // wtf

                        properties={propertiesOptionsById}
                        channelProperties={form.values.properties}
                        roomTypes={roomTypesArray}
                        ratePlans={ratesPlansArray}

                        onMappingOptionsChange={this.handleMappingOptionsChange}
                        onRefresh={this.handleMappingRefresh(form.values)}
                        onChangeMapping={(value) => {
                          this.setState({ mappingChanged: true });
                          form.setFieldValue("mappings", value);
                        }}
                        onChangeSettings={(value) => {
                          form.setFieldValue("settings.mappingSettings", value);
                        }}
                        onKnownMappingsDelete={(mappingToDelete) => {
                          this.handleKnownMappingDelete(mappingToDelete, form);
                        }}
                      />
                    )}
                  </Tabs.TabPane>
                  {this._renderChannelTabs(form)}
                  {this._renderListingTab(form)}
                  {this._renderAirbnbRatePlansTab(form)}
                  {this._renderOpportunitiesTab(form)}
                  <Tabs.TabPane
                    tab={
                      <span data-cy="channel_settings_tab">
                        {t("channels_page:form:tabs:channel_settings_tab")}
                      </span>
                    }
                    key={CHANNEL_DERIVED_SETTINGS_TAB}
                    disabled={!isRatesSelectEnabled}
                  >
                    <ChannelSettings form={form} />
                  </Tabs.TabPane>
                  {id && (
                    <Tabs.TabPane
                      tab={
                        <span data-cy="missing_content_tab">
                          {t("channels_page:form:tabs:missing_content_tab")}
                        </span>
                      }
                      key={MISSING_CONTENT_TAB}
                    >
                      <MissingContent
                        channelId={id}
                        propertiesOptionsById={propertiesOptionsById}
                        onTabNavigate={this.handleChannelSettingsNavigateClick}
                      />
                    </Tabs.TabPane>
                  )}
                </Tabs>
                {(activeTab !== LISTING_TAB) && (activeTab !== AIRBNB_RATE_PLANS_TAB) && (
                  <div className={styles.actions}>
                    {(model.id || activeTab !== SETTINGS_TAB || !isRatesSelectEnabled) && (
                      <SubmitButton disabled={!isSubmitEnabled} loading={submitInProgress}>
                        {t("channels_page:submit_button")}
                      </SubmitButton>
                    )}
                    {!model.id && activeTab === SETTINGS_TAB && isRatesSelectEnabled && (
                      <Button
                        block
                        type="primary"
                        size="large"
                        onClick={this.nextTab}
                        data-cy="next"
                      >
                        Next
                      </Button>
                    )}
                  </div>
                )}
              </Form>
            );
          }}
        </Formik>
        {confirmUnmappedModalVisible && (
          <ConfirmUnmappedModal
            onOk={this.handleConfirmUnmappedModalOk}
            onCancel={this.handleConfirmUnmappedModalCancel}
            unusedMappings={unusedMappings}
            mappingOptions={mappingOptions}
            mappingSchema={mappingSchema}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = ({ session, properties, groups, user }) => ({
  appMode: session.appMode,
  activeGroup: session.activeGroup,
  activeProperty: session.activeProperty,
  activeFeatureFlags: session.activeFeatureFlags,
  allowedChannels: session.allowedChannels,
  availablePropertiesOptions: properties.options,
  availableGroupsOptions: groups.options,
  allowAirbnbRatePlans: user.settings?.allow_airbnb_rate_plans,
});

export default withTranslation()(connect(mapStateToProps)(withComponentRef(ChannelManagement)));
