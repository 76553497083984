import React from "react";

import ChannexDrawer from "drawers/channex_drawer";

import DrawerFormWrapper from "components/drawer_form_wrapper";

import { CreateWhiteLabelEmailSettingsForm } from "./create_form/create_form";

export function CreateDrawer({ wlPartnerId, visible, onClose }) {
  const title = "Create White Label Email Settings";

  return (
    <ChannexDrawer title={title} visible={visible} onClose={onClose}>
      <DrawerFormWrapper withActions>
        <CreateWhiteLabelEmailSettingsForm wlPartnerId={wlPartnerId} onFinish={onClose} />
      </DrawerFormWrapper>
    </ChannexDrawer>
  );
}
