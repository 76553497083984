import React from "react";

import CancellationPolicy from "./cancellation_policy";
import HotelPolicy from "./hotel_policy";

export default function PolicyView({
  property,
  handleHotelPolicyChange,
  handleCancellationPolicyChange,
}) {
  return (
    <>
      <HotelPolicy property={property} handleHotelPolicyChange={handleHotelPolicyChange} />

      <CancellationPolicy
        property={property}
        handleCancellationPolicyChange={handleCancellationPolicyChange}
      />
    </>
  );
}
