import {
  PROPERTIES_ADD,
  PROPERTIES_DROP,
  PROPERTIES_LOAD,
  PROPERTIES_OPTIONS_ADD,
  PROPERTIES_OPTIONS_LOAD,
} from "../constants";

const initialState = {
  entities: null,
  options: null,
};

const ACTION_HANDLERS = {
  [PROPERTIES_LOAD]: (state, action) => {
    return {
      ...state,
      entities: action.payload.properties,
      meta: action.payload.meta,
    };
  },
  [PROPERTIES_ADD]: (state, action) => {
    const state_entities = state && state.entities ? state.entities : {};
    const entities = {
      ...(state_entities || {}),
      [action.payload.id]: action.payload,
    };

    return { ...(state || {}), entities };
  },
  [PROPERTIES_DROP]: (state, action) => {
    if (state && state.entities) {
      delete state.entities[action.payload.id];
    }
    return { ...(state || {}) };
  },
  [PROPERTIES_OPTIONS_LOAD]: (state, action) => {
    return {
      ...state,
      options: action.payload,
    };
  },
  [PROPERTIES_OPTIONS_ADD]: (state, action) => {
    const existingOptions = state.options || [];

    const propertyWithGroupIds = {
      ...action.payload,
      group_ids: action.payload.groups ? Object.keys(action.payload.groups) : [],
    };

    return {
      ...state,
      options: [...existingOptions, propertyWithGroupIds],
    };
  },
};

export default function propertiesReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
