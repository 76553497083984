import React from "react";
import { Form } from "antd";

import CopyToClipboard from "../../buttons/copy_to_clipboard";
import getFieldLayout from "../utils/get_field_layout";

const FormItem = Form.Item;

export const TextValue = ({ label, value, copy }) => {
  const formItemLayout = getFieldLayout();

  return (
    <FormItem
      {...formItemLayout}
      label={label}
    >
      {value}
      {copy && <CopyToClipboard text={value} />}
    </FormItem>
  );
};
