import React from "react";
import { useTranslation } from "react-i18next";
import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown } from "antd";

import styles from "./styles.module.css";

const DROPDOWN_TRIGGER = ["click"];

export const Actions = ({ items }) => {
  const { t } = useTranslation();

  return (
    <Dropdown menu={{ items }} trigger={DROPDOWN_TRIGGER}>
      <Button className={styles.actionsButton} type="link">
        {t("general:actions")} <DownOutlined />
      </Button>
    </Dropdown>
  );
};
