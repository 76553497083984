import * as yup from "yup";

import errorMessages from "config/constants/errors";

export const validationSchema = yup.object().shape({
  title: yup.string().trim().required(errorMessages.required()),
  contactName: yup.string().trim(),
  contactEmail: yup.string().trim(),
  contactPhone: yup.string().trim(),
  agreementContentUrl: yup.string().trim(),
  agreementVersion: yup.string().trim(),
});
