import React from "react";
import classnames from "classnames";

import styles from "./text.module.css";

export default function Text({ disabled, children }) {
  const className = classnames(styles.text, {
    [styles.disabled]: disabled,
  });

  return (
    <p className={className}>
      {children}
    </p>
  );
}
