import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import ChannexDrawer from "drawers/channex_drawer";

import ChannelManagement from "components/channel_management";

function ChannelDrawerControlled({ visible, channelId, defaultTab, onClose }) {
  const { t } = useTranslation();
  const title = t(
    channelId ? "channels_page:edit_dialog_header" : "channels_page:create_dialog_header",
  );

  return (
    <ChannexDrawer title={title} visible={visible} onClose={onClose}>
      {({ componentRef, handleCloseFromContent }) => (
        <ChannelManagement
          id={channelId}
          defaultTab={defaultTab}
          componentRef={componentRef}
          onClose={handleCloseFromContent}
        />
      )}
    </ChannexDrawer>
  );
}

ChannelDrawerControlled.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  channelId: PropTypes.string,
};

export default ChannelDrawerControlled;
