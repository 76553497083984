import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import InfiniteList from "components/infinite_list";

import ThreadsItem from "./threads_item";

import styles from "../threads.module.css";

function ThreadsList({ threadsList, meta, loading, loadMore, defaultCacheProps }) {
  const listClassName = classNames(styles.list, {
    [`${styles.list__loading}`]: loading,
  });

  return (
    <div className={styles.listWrapper}>
      <InfiniteList
        list={threadsList}
        meta={meta}
        loading={loading}
        loadMore={loadMore}
        className={listClassName}
        defaultCacheProps={defaultCacheProps}
        itemComponent={<ThreadsItem />}
        loadBottomScroll
      />
    </div>
  );
}

ThreadsList.propTypes = {
  threadsList: PropTypes.array,
  meta: PropTypes.object,
  loading: PropTypes.bool,
  defaultCacheProps: PropTypes.object,
};

export default ThreadsList;
