let transport;
let storage;

const ENDPOINT = "applications/pci";

export default class PCICards {
  constructor(container) {
    transport = container.transport;
    storage = container.storage;
  }

  request_card_data(booking_revision_id) {
    return transport.send(
      "POST",
      `${ENDPOINT}/request_card_data`,
      {
        pci_data_request: {
          booking_revision_id,
        },
      },
    ).then(({ data }) => {
      return data;
    });
  }

  get_card_url(code, request_id) {
    return transport.send(
      "POST",
      `${ENDPOINT}/get_card_url`,
      {
        card_url_request: {
          request_session_id: request_id,
          confirmation_code: code,
        },
      },
    ).then(({ data }) => {
      return data;
    });
  }

  logs(filter = {}, pagination = {}, order = { inserted_at: "desc" }) {
    return transport.send("GET", `${ENDPOINT}/logs`, { filter, pagination, order }).then((response) => {
      storage.pciLogsLoad(response.data, response.meta);
      return response;
    });
  }
}
