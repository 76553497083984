import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.css";

export function EventTitle({ event }) {
  const { t } = useTranslation();

  return (
    <div className={styles.title}>{t(`bookings_page:booking_view_dialog:booking_events:${event.event}`)}</div>
  );
}
