import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, Modal, notification, Typography } from "antd";
import { FormikProvider, useFormik } from "formik";
import store from "store";

import FormikFormInput from "components/forms/inputs/formik/form_input";

import AppStorageManager from "utils/app_storage_manager";

import styles from "./remove_user_account.module.css";

const { Text } = Typography;
const { Profiles } = store;

export default function RemoveUserAccount() {
  const [requestCodeIsLoading, setRequestCodeIsLoading] = useState(false);
  const [removeDialogIsVisible, setRemoveDialogIsVisible] = useState(false);
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: {
      code: "",
    },
  });

  const handleRemoveUserClick = () => {
    setRequestCodeIsLoading(true);
    Profiles.requestDeleteCode().then(() => {
      setRequestCodeIsLoading(false);
      setRemoveDialogIsVisible(true);
    }, (error) => {
      if (error.errors.code === "validation_error") {
        notification.open({
          message: t("user_profile_page:remove:error_has_active_subscription_title"),
          description: t("user_profile_page:remove:error_has_active_subscription_description"),
        });
      } else {
        notification.open({
          message: t("user_profile_page:remove:unknown_error_title"),
          description: t("user_profile_page:remove:unknown_error_description"),
        });
      }
      setRequestCodeIsLoading(false);
    });
  };

  const handleModalOk = () => {
    const { values } = formik;
    Profiles.delete(values.code).then(
      () => {
        AppStorageManager.clear();
        window.location.assign("/");
      },
      () => {
        notification.open({
          message: t("user_profile_page:remove:error_notification"),
          description: t("user_profile_page:remove:error_notification_description"),
        });
        formik.resetForm();
        setRemoveDialogIsVisible(false);
      },
    );
  };

  const handleModalCancel = () => {
    formik.resetForm();
    setRemoveDialogIsVisible(false);
  };

  return (
    (
      <main className={styles.profileSection}>
        <div className={styles.header}>
          <Text type="danger">
            <span className={styles.title}>{t("user_profile_page:remove:legend")}</span>
          </Text>
        </div>
        <div className={styles.formWrapper}>
          <Button
            danger
            type="primary"
            size="large"
            className={styles.removeButton}
            onClick={handleRemoveUserClick}
            loading={requestCodeIsLoading}
          >
            {t("user_profile_page:remove:button")}
          </Button>
          <Modal
            title={t("user_profile_page:remove:modal:title")}
            open={removeDialogIsVisible}
            okText={t("user_profile_page:remove:modal:confirm")}
            cancelText={t("user_profile_page:remove:modal:cancel")}
            onOk={handleModalOk}
            onCancel={handleModalCancel}
          >
            <FormikProvider value={formik}>
              <Form>
                <FormikFormInput
                  name="code"
                  placeholder={t("user_profile_page:remove:modal:code_placeholder")}
                  label={t("user_profile_page:remove:modal:code_label")}
                />
              </Form>
            </FormikProvider>
          </Modal>
        </div>
      </main>
    )
  );
}
