import {
  WHITE_LABEL_EMAIL_SETTINGS_ADD,
  WHITE_LABEL_EMAIL_SETTINGS_DROP,
  WHITE_LABEL_EMAIL_SETTINGS_LOAD,
} from "../constants";

export function whiteLabelEmailSettingsLoad(storage) {
  return function (whiteLabelEmailSettings) {
    storage.dispatch({ type: WHITE_LABEL_EMAIL_SETTINGS_LOAD, payload: whiteLabelEmailSettings });
  };
}

export function whiteLabelEmailSettingsAdd(storage) {
  return function (whiteLabelEmailSetting) {
    storage.dispatch({ type: WHITE_LABEL_EMAIL_SETTINGS_ADD, payload: whiteLabelEmailSetting });
  };
}

export function whiteLabelEmailSettingsDrop(storage) {
  return function (whiteLabelEmailSetting) {
    storage.dispatch({ type: WHITE_LABEL_EMAIL_SETTINGS_DROP, payload: whiteLabelEmailSetting });
  };
}
