import React from "react";
import { useTranslation } from "react-i18next";
import { DownloadOutlined } from "@ant-design/icons";
import { Button, Empty, Skeleton, Table } from "antd";

import Flexbox from "components/flexbox";

import { formatValue } from "../format_value";

export const ChannelsBreakdown = ({ isLoading, data, currency, onDownloadCSVClick }) => {
  const { t } = useTranslation();

  const columns = [{
    key: "channel",
    dataIndex: "channel",
    title: t("reports:labels:channel"),
  }, {
    key: "totalRevenue",
    dataIndex: "totalRevenue",
    title: `${t("reports:labels:revenue")} (${currency})`,
    render: (value) => value,
  }, {
    key: "reservationsCount",
    dataIndex: "reservationsCount",
    title: t("reports:labels:reservations"),
    render: (value) => formatValue(value),
  }, {
    key: "roomNightsCount",
    dataIndex: "roomNightsCount",
    title: t("reports:labels:room_nights"),
    render: (value) => formatValue(value),
  }, {
    key: "avgLengthOfStay",
    dataIndex: "avgLengthOfStay",
    title: t("reports:labels:avg_length_of_stay"),
    render: (value) => value,
  }, {
    key: "avgLeadTime",
    dataIndex: "avgLeadTime",
    title: t("reports:labels:avg_lead_time"),
    render: (value) => value,
  }, {
    key: "avgDailyRate",
    dataIndex: "avgDailyRate",
    title: `${t("reports:labels:avg_daily_rate")} (${currency})`,
    render: (value) => value,
  }, {
    key: "cancelledReservationsCount",
    dataIndex: "cancelledReservationsCount",
    title: t("reports:labels:cancellations"),
    render: (value) => formatValue(value),
  }];

  const isDataPresent = data?.length > 0;

  return (
    <div>
      <Table
        columns={columns}
        dataSource={isLoading ? [] : data}
        pagination={false}
        locale={{
          emptyText: isLoading
            ? (
              <div style={{ paddingTop: 10 }}>
                <Skeleton active paragraph={false} />
              </div>
            )
            : <Empty />,
        }}
        rowKey="channel"
      />
      {isDataPresent && (
        <Flexbox justifyContent="end">
          <Button key="download" icon={<DownloadOutlined />} type="link" onClick={onDownloadCSVClick}>
            {t("reports:labels:download_csv")}
          </Button>
        </Flexbox>
      )}
    </div>
  );
};
