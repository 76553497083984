import showErrorMessage from "utils/show_error_message";

export default (route, attrs = {}) => {
  return route._path.replace(/(:[\w]+)/g, (match) => {
    const value = attrs[match.substr(1)];

    if (value === undefined) {
      showErrorMessage(`Missing value for url path template: ${match}`);
    }

    return attrs[match.substr(1)];
  });
};
