import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import ChannexDrawer from "drawers/channex_drawer";

import DrawerFormWrapper from "components/drawer_form_wrapper";
import TaxSetFormWrapper from "components/tax_set/form/tax_set_form";

class TaxSetDrawerControlled extends Component {
  render() {
    const { t, visible, id, propertyId, onClose, onCreate } = this.props;

    return (
      <ChannexDrawer
        title={t("taxes:tax_set:title")}
        visible={visible}
        onClose={onClose}
        dataCy="tax_set_drawer"
      >
        {({ handleCloseFromContent, componentRef }) => (
          <DrawerFormWrapper withActions>
            <TaxSetFormWrapper
              entityId={id}
              propertyId={propertyId}
              componentRef={componentRef}
              onClose={handleCloseFromContent}
              onCreate={onCreate}
            />
          </DrawerFormWrapper>
        )}
      </ChannexDrawer>
    );
  }
}

export default withTranslation()(TaxSetDrawerControlled);
