import React, { Component } from "react";
import { Field } from "formik";

import FormWeekdays from "components/forms/inputs/form_weekdays";

class FormikFormWeekdays extends Component {
  render() {
    const { name, namespace, onChange } = this.props;

    const fieldName = namespace ? `${namespace}.${name}` : name;

    return (
      <Field name={fieldName}>
        {({ field, form }) => (
          <FormWeekdays
            {...this.props}
            {...field}
            defaultValue={field.value}
            errors={form.touched[name] && form.errors[name]}
            onChange={(value) => (onChange ? onChange(value, field, form) : form.setFieldValue(name, value))}
          />
        )}
      </Field>
    );
  }
}

export default FormikFormWeekdays;
