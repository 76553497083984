import React from "react";
import Avatar from "react-avatar";
import { useTranslation } from "react-i18next";
import classnames from "classnames";

import channelCodes from "config/constants/channels/channel_codes";

import { useMobile } from "../../../../use_mobile";

import styles from "../../styles.module.css";

export default function BookingSummary({ review }) {
  const isMobile = useMobile();
  const { t } = useTranslation();

  const { attributes } = review;
  const { guestName, ota } = attributes;
  const { title: channelTitle, icon } = channelCodes[ota];

  const titleClassname = classnames(styles.headerTitle, {
    [styles.headerTitleMobile]: isMobile,
  });

  const wrapperClassname = classnames(styles.headerTitleWrapper, {
    [styles.headerTitleWrapperMobile]: isMobile,
  });

  return (
    <div className={wrapperClassname}>
      <p className={titleClassname}>
        {guestName || t("reviews_page:dialog:unknown_guest")}
      </p>
      <div style={{ display: "flex" }}>
        <Avatar
          name={channelTitle}
          size={24}
          src={icon}
          className={styles.headerProviderLogo}
          round
        />
        <p className={styles.headerProvider}>{channelTitle}</p>
      </div>
    </div>
  );
}
