import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Typography } from "antd";
import { Form, Formik } from "formik";
import store from "store";
import * as yup from "yup";

import yupErrors from "config/constants/errors";

import ChannexModal from "components/channex_modal";
import CopyToClipboard from "components/forms/buttons/copy_to_clipboard/copy_to_clipboard";
import SubmitButton from "components/forms/buttons/submit_button";
import FormCheckbox from "components/forms/inputs/formik/form_checkbox";
import Input from "components/forms/inputs/formik/form_input";
import FormTableSelect from "components/forms/inputs/formik/form_table_select";

import useBoolState from "utils/use_bool_state";

import styles from "../user_api_keys_table/user_api_keys.module.css";

const { Text } = Typography;

const INITIAL_FORM_VALUE = { title: "", properties: [], allProperties: true };
const MODAL_WIDTH = 620;

const { Properties } = store;

export default function UserApiKeysCreateModal({ visible, onCreate, onClose }) {
  const [apiKey, setApiKey] = useState(null);
  const [propertiesList, setProperties] = useState([]);
  const [loading, setLoading, setLoaded] = useBoolState(false);
  const { t } = useTranslation();

  useEffect(function loadProperties() {
    Properties.options({}).then((properties) => {
      setProperties(
        properties.map((el) => {
          return { key: el.id, title: el.title };
        }),
      );
    });
  }, []);

  const handleCreate = useCallback(
    ({ title, properties, allProperties }) => {
      setLoading();
      const newValue = {
        userApiKey: {
          title,
          properties: allProperties === false && properties.length > 0 ? properties : null,
        },
      };

      onCreate(newValue)
        .then(({ key }) => {
          setApiKey(key);
        })
        .finally(setLoaded);
    },
    [onCreate, setApiKey, setLoading, setLoaded],
  );

  const handleClose = useCallback(() => {
    setApiKey(null);
    onClose();
  }, [setApiKey, onClose]);

  const schema = useMemo(() => {
    return yup.object({
      title: yup.string().trim().required(yupErrors.required()),
      allProperties: yup.boolean().required(),
      properties: yup.array().when("allProperties", {
        is: (allProperties) => allProperties === false,
        then: () => yup.array().min(1).required(),
      }),
    });
  }, []);

  const onPropertiesChange = (value, field, form) => {
    value = Array.isArray(value) ? value : [value];

    form.setFieldValue(field.name, value);
  };

  return (
    <ChannexModal
      visible={visible}
      title={t("user_api_keys:modal_title")}
      footer={null}
      onCancel={handleClose}
      width={MODAL_WIDTH}
    >
      {apiKey ? (
        <div>
          <p className={styles.apiKey__title}>{t("user_api_keys:api_key_description")}</p>
          <div className={styles.apiKey__inner}>
            <Text code>{apiKey}</Text>
            <CopyToClipboard text={apiKey} />
          </div>

          <Button onClick={handleClose} type="default" className={styles.apiKey__closeBtn}>
            {t("general:action:close")}
          </Button>
        </div>
      ) : (
        <Formik
          initialValues={INITIAL_FORM_VALUE}
          validationSchema={schema}
          onSubmit={handleCreate}
        >
          {({ errors, touched, values }) => (
            <Form>
              <>
                <Input
                  label={t("user_api_keys:form:title_label")}
                  placeholder={t("user_api_keys:form:title_placeholder")}
                  name="title"
                />
                <FormCheckbox
                  name="allProperties"
                  view="horizontal"
                  placeholder={t("user_api_keys:form:all_properties_placeholder")}
                  label={t("user_api_keys:form:all_properties_label")}
                />
                {values.allProperties ? null : (
                  <>
                    <FormTableSelect
                      title={t("user_api_keys:form:affected_properties_title")}
                      label={t("user_api_keys:form:affected_properties_placeholder")}
                      name="properties"
                      items={propertiesList}
                      onChange={onPropertiesChange}
                    />
                    {errors.properties && touched.properties && (
                      <div className="ant-form-item-explain ant-form-item-explain-error ant-col-xs-24 ant-col-sm-16 ant-col-sm-offset-8">
                        {errors.properties}
                      </div>
                    )}
                  </>
                )}

                <br />

                <SubmitButton loading={loading}>{t("general:action:create")}</SubmitButton>
              </>
            </Form>
          )}
        </Formik>
      )}
    </ChannexModal>
  );
}
