import React from "react";
import { useTranslation } from "react-i18next";

import AuthFormTitle from "components/auth_form_title";

import withFormSubmission from "containers/with_form_submission";

import withRouter from "routing/with_router";

import AccountDetailsForm from "./components/account_details_form";

function ConfirmInvite() {
  const { t } = useTranslation();

  return (
    <>
      <AuthFormTitle isOnly>{t("confirm_invite_page:header")}</AuthFormTitle>
      <AccountDetailsForm />
    </>
  );
}

export default withFormSubmission(withRouter(ConfirmInvite));
