import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Actions } from "components/crud_table/columns/actions/actions";
import { ReactQueryCrudTable } from "components/crud_table/react_query";

import { useAdminChannelAdapters } from "./use_admin_channel_adapters";
import { useSetAccessModeAction } from "./use_set_access_mode_action";

export function ChannelAdaptersSubPage() {
  const { t } = useTranslation();

  const [tableParams, setTableParams] = useState();
  const { mutateAsync: setAccessModeAction } = useSetAccessModeAction();

  const query = useAdminChannelAdapters(tableParams);

  const columns = () => {
    return [{
      title: "Title",
      dataIndex: "title",
      key: "title",
      sorter: true,
    }, {
      title: "Access Mode",
      dataIndex: "access_mode",
      key: "access_mode",
      sorter: true,
      render: (value) => {
        return t(`channel_adapters_sub_page:access_mode:${value}`);
      },
    },
    {
      title: "Actions",
      key: "action",
      align: "right",
      width: 100,
      render: (_value, record) => {
        const items = [];

        if (record.access_mode !== "private") {
          items.push({
            key: "channel_adapters_sub_page:actions_set_as_private",
            onClick: () => setAccessModeAction({ id: record.id, attrs: { access_mode: "private" } }),
            label: (
              <div>
                {t("channel_adapters_sub_page:actions:set_as_private")}
              </div>
            ),
          });
        }

        if (record.access_mode !== "public") {
          items.push({
            key: "channel_adapters_sub_page:actions_set_as_public",
            onClick: () => setAccessModeAction({ id: record.id, attrs: { access_mode: "public" } }),
            label: (
              <div>
                {t("channel_adapters_sub_page:actions:set_as_public")}
              </div>
            ),
          });
        }

        return (
          <Actions
            items={items}
          />
        );
      },
    }];
  };

  return (
    <ReactQueryCrudTable
      columns={columns}
      query={query}
      showCreateMessage={false}
      onTableParamsChange={setTableParams}
      hideCreateAction
      defaultOrder={{ title: "asc" }}
    />
  );
}
