import React from "react";
import _ from "lodash";

import { WhiteLabelDomainForm } from "../../white_label_domain_form/white_label_domain_form";

import { useUpdateAction } from "./use_update_action";

export function EditWhiteLabelDomainForm({ values, closeDrawer }) {
  const { mutateAsync, isLoading } = useUpdateAction();

  const handleSubmit = async (formValues) => {
    const permittedAttrs = _.omit(formValues, ["id", "settings"]);

    ["favicon", "logoBig", "logoSmall"].forEach((imageField) => {
      if (permittedAttrs[imageField]?.startsWith("http")) {
        delete permittedAttrs[imageField];
      }
    });

    const result = await mutateAsync({ id: formValues.id, attrs: permittedAttrs });
    closeDrawer(result);
  };

  return (
    <WhiteLabelDomainForm
      values={values}
      isLoading={isLoading}
      onSubmit={handleSubmit}
    />
  );
}
