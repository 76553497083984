import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./transition.module.css";

const DEFAULT_TIMEOUT = 300;
const DEFAULT_HTML_ELEMENT = "div";
const DEFAULT_EFFECT = "fade";

function Transition({
  children,
  className,
  htmlElement = DEFAULT_HTML_ELEMENT,
  effect = DEFAULT_EFFECT,
}) {
  const wrapperClassName = classNames(styles.container, className);

  return (
    <TransitionGroup component={htmlElement} className={wrapperClassName}>
      {children && (
        <CSSTransition timeout={DEFAULT_TIMEOUT} classNames={effect}>
          {children}
        </CSSTransition>
      )}
    </TransitionGroup>
  );
}

Transition.propTypes = {
  htmlElement: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default Transition;
