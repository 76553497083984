import store from "store";

const { Attachments } = store;

function readFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = function () {
      resolve(reader.result);
    };

    reader.onerror = function (error) {
      reject(error);
    };
  });
}

export default async function uploadAttachment(file, updateFileStatus) {
  const id = `${file.name}${file.lastModified}`;
  const fileName = file.name;
  const fileType = file.type;

  updateFileStatus({ id }, "uploading");

  try {
    const fileData = await readFile(file);

    const controller = new AbortController();
    const signal = controller.signal;

    updateFileStatus({ id, data: fileData, requestController: controller }, "uploading");

    const uploadedFile = await Attachments.upload({
      file: fileData,
      fileName,
      fileType,
    }, {
      signal,
    });

    updateFileStatus({ id, data: fileData, attachmentId: uploadedFile.id }, "success");

    return uploadedFile;
  } catch (error) {
    if (error.name === "AbortError") {
      return updateFileStatus({ id }, "abort");
    }

    updateFileStatus({ id }, "error");
  }
}
