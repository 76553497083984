import { useTranslation } from "react-i18next";

export function DateRangeValue({ from, to }) {
  const { t } = useTranslation();

  if (from && !to) {
    return `${t("general:date_range:from")} ${from}`;
  }

  return `${from} - ${to}`;
}
