import { TAX_SETS_ADD, TAX_SETS_DROP, TAX_SETS_LOAD } from "../constants";

export function taxSetsLoad(storage) {
  return function (records, meta) {
    storage.dispatch({ type: TAX_SETS_LOAD, payload: { records, meta } });
  };
}

export function taxSetsAdd(storage) {
  return function (payload) {
    storage.dispatch({ type: TAX_SETS_ADD, payload });
  };
}

export function taxSetsDrop(storage) {
  return function (payload) {
    storage.dispatch({ type: TAX_SETS_DROP, payload });
  };
}
