import { useState } from "react";
import { useMutation } from "react-query";
import store from "store";

import useBookingExportListener from "./use_booking_export_listener";

const { Bookings } = store;

export const EXPORT_STATES = {
  INITIAL: "initial",
  STARTING: "starting",
  IN_PROGRESS: "in_progress",
  FINISHED: "finished",
  FAILED: "failed",
};

export default function useExport() {
  const [exportState, setExportState] = useState(EXPORT_STATES.INITIAL);
  const [downloadLink, setDownloadLink] = useState(null);
  const [filename, setFilename] = useState(null);

  const [totalCount, setTotalCount] = useState(0);
  const [exportedCount, setExportedCount] = useState(0);

  const generateExportTokenMutation = useMutation(({ filters }) => Bookings.exportCSV(filters));

  const { join, subscribe, leave } = useBookingExportListener({
    onStarted: (total) => {
      setTotalCount(total);
      setExportState(EXPORT_STATES.IN_PROGRESS);
    },
    onProgress: (count) => {
      setExportedCount(count);
    },
    onComplete: (token) => {
      leave();
      const link = Bookings.buildDownloadExportLink(token);
      setDownloadLink(link);
      setExportState(EXPORT_STATES.FINISHED);
    },
    onFailed: () => {
      leave();
      setExportState(EXPORT_STATES.FAILED);
    },
    onTimeout: () => {
      leave();
      setExportState(EXPORT_STATES.FAILED);
    },
  });

  const cleanState = () => {
    leave();
    setTotalCount(0);
    setExportedCount(0);
    setDownloadLink(null);
    setFilename(null);
    setExportState(EXPORT_STATES.INITIAL);
  };

  const download = () => {
    const link = document.createElement("a");
    link.href = downloadLink;
    link.download = filename;
    link.target = "_blank";
    link.click();
  };

  const startExport = async (filters, exportFilename) => {
    setFilename(exportFilename);
    setExportState(EXPORT_STATES.STARTING);

    try {
      join();
      const token = await generateExportTokenMutation.mutateAsync({ filters });
      subscribe(token);
    } catch (e) {
      leave();
      setExportState(EXPORT_STATES.FAILED);
      throw e;
    }
  };

  return {
    exportState,
    totalCount,
    exportedCount,
    download,
    startExport,
    cleanState,
  };
}
