import qs from "qs";

import { convertToSnakeCase } from "../store/utils/case_converter";

export const parseQueryParams = (search = window.location.search) => {
  return qs.parse(search, {
    ignoreQueryPrefix: true,
    comma: true,
    plainObjects: true,
  });
};

export const stringifyQueryParams = (params = {}) => {
  return qs.stringify(convertToSnakeCase(params), {
    addQueryPrefix: true,
    arrayFormat: "comma",
    skipNulls: true,
    encode: false,
  });
};
