import { formatDate } from "utils/dates";

import attributesExtractor from "../utils/attributes_extractor";
import { convertToCamelCase, convertToSnakeCase } from "../utils/case_converter";

import { httpClient } from "./http_client";

export const reportsApiClient = {
  channelsBreakdown: async ({ dateType, dateFrom, dateTo, propertyId, format = "json" }) => {
    const body = convertToSnakeCase({
      dateType,
      dateFrom: formatDate(dateFrom),
      dateTo: formatDate(dateTo),
      propertyId: [propertyId],
      format,
    });

    const response = await httpClient.send("POST", "reports/channels_breakdown", body);

    if (format === "csv") {
      return response;
    }

    return convertToCamelCase(attributesExtractor(response.data));
  },
};
