import React, { Component } from "react";
import _ from "lodash";
import store from "store";

import withComponentRef from "containers/with_component_ref";

import confirmDirtyStateSave from "utils/confirm_dirty_state_save";
import EventEmitter from "utils/event_emitter";
import parseApiErrors from "utils/parse_api_errors";

import ListingPriceForm from "./listing_price_form";

const { Channels } = store;

const DEFAULT_FEE_PROPERTIES = {
  amount: 0,
  amount_type: "flat",
  fee_unit_type: null,
  charge_type: "PER_GROUP",
};

const NULLABLE_FEES = ["pass_through_security_deposit", "pass_through_pet_fee"];

const DEFAULT_FEES = {
  pass_through_resort_fee: DEFAULT_FEE_PROPERTIES,
  pass_through_management_fee: DEFAULT_FEE_PROPERTIES,
  pass_through_community_fee: DEFAULT_FEE_PROPERTIES,
  pass_through_linen_fee: DEFAULT_FEE_PROPERTIES,
  pass_through_pet_fee: DEFAULT_FEE_PROPERTIES,
  pass_through_cleaning_fee: DEFAULT_FEE_PROPERTIES,
  pass_through_short_term_cleaning_fee: DEFAULT_FEE_PROPERTIES,
  pass_through_security_deposit: DEFAULT_FEE_PROPERTIES,
};

class ListingPriceFormWrapper extends Component {
  state = {
    loading: false,
    submitting: false,
    value: {},
    errors: {},
    globalErrors: {},
  };

  formRef = React.createRef();

  componentDidMount() {
    const { listing } = this.props;

    this.initFormValue(listing?.pricing_setting);
  }

  componentDidUpdate(prevProps) {
    const { listing } = this.props;

    if (listing !== prevProps.listing) {
      this.initFormValue(listing?.pricing_setting);
    }
  }

  initFormValue = (pricingSettings) => {
    if (!pricingSettings) {
      return;
    }

    const standardFees = pricingSettings.standard_fees || [];
    const standardFeesByType = standardFees.reduce((acc, fee) => {
      const feeId = fee.fee_type.toLowerCase();
      const enrichedFee = { ...DEFAULT_FEE_PROPERTIES, ...fee };

      return { ...acc, [feeId]: enrichedFee };
    }, DEFAULT_FEES);

    const value = {
      ...pricingSettings,
      standard_fees: standardFeesByType,
    };

    this.setState({ loading: false, value });
  };

  handleListingRefresh = () => {
    const { listing, channelId } = this.props;
    const { listing_id } = listing;

    return Channels.airbnbLoadListingPricing(channelId, { listing_id }).then(this.initFormValue);
  };

  getFormValue = () => {
    const { value } = this.state;
    const { standard_fees: standardFees } = value;

    const standardFeesArray = Object.keys(standardFees)
      .filter((key) => _.isNumber(standardFees[key].amount))
      .filter((key) => !(NULLABLE_FEES.includes(key) && standardFees[key].amount === 0))
      .map((key) => {
        const fee = standardFees[key];

        return { ...DEFAULT_FEE_PROPERTIES, ...fee, fee_type: key.toUpperCase() };
      });

    return { ...value, standard_fees: standardFeesArray };
  };

  handleSubmit = () => {
    const { listing, channelId, onPricingUpdate, onClose } = this.props;
    const { id } = listing;
    const formValue = this.getFormValue();

    this.setState({ submitting: true });

    this.formRef.current
      .validate()
      .then(() => {
        const data = { channel_rate_plan_id: id, data: formValue };

        Channels.airbnbUpdateListingPricing(channelId, data)
          .then((response) => {
            this.setState({ submitting: false, errors: {}, globalErrors: {} });
            onPricingUpdate(response);
            onClose();
          })
          .catch((error) => {
            if (!error.isValidationError) {
              throw error;
            }

            const { details } = error.errors;

            if (details.error === "invalid_token") {
              this.handleInvalidTokenError(details);
              return;
            }

            const { pricing_setting, ...errors } = parseApiErrors(error);

            this.setState({ submitting: false, errors, globalErrors: { pricing_setting } });
          });
      })
      .catch(() => {
        this.setState({ submitting: false });
      });
  };

  handleInvalidTokenError = (errorDetails) => {
    EventEmitter.trigger("channel_management:invalid_token", errorDetails);
    this.setState({ submitting: false });
  };

  handleChange = (value) => {
    this.setState({ value });
  };

  beforeClose = () => {
    const { current } = this.formRef;

    if (current) {
      const { dirty } = current.formik.current;
      return confirmDirtyStateSave(dirty);
    }

    return Promise.reject();
  };

  render() {
    const { value, loading, submitting, errors, globalErrors } = this.state;

    return (
      <ListingPriceForm
        value={value}
        loading={loading}
        errors={errors}
        globalErrors={globalErrors}
        submitting={submitting}
        componentRef={this.formRef}
        onRefresh={this.handleListingRefresh}
        onChange={this.handleChange}
        onSubmit={this.handleSubmit}
      />
    );
  }
}

export default withComponentRef(ListingPriceFormWrapper);
