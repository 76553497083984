import React from "react";

import ChannexDrawer from "drawers/channex_drawer";

import DrawerFormWrapper from "components/drawer_form_wrapper";

import { CreateWhiteLabelPartnerForm } from "./create_form/create_form";

export function CreateDrawer({ visible, onClose }) {
  const title = "Create White Label Partner";

  return (
    <ChannexDrawer title={title} visible={visible} onClose={onClose}>
      <DrawerFormWrapper withActions>
        <CreateWhiteLabelPartnerForm onFinish={onClose} />
      </DrawerFormWrapper>
    </ChannexDrawer>
  );
}
