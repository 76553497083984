import {
  fullWidthFormItemLayout,
  fullWidthNoLabelFormItemLayout,
  horizontalFormItemLayout,
  tailFormItemLayout,
  verticalFormItemLayout,
} from "config/constants/layouts/form";

export default function getFieldLayout(view = "horizontal") {
  const layout = {
    vertical: verticalFormItemLayout,
    horizontal: horizontalFormItemLayout,
    fullWidth: fullWidthFormItemLayout,
    fullWidthNoLabel: fullWidthNoLabelFormItemLayout,
    tail: tailFormItemLayout,
  };

  return layout[view] || verticalFormItemLayout;
}
