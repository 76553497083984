import { useMutation, useQueryClient } from "react-query";
import store from "store";

const { AdminWlPartners } = store;

export function useCreateAction() {
  const queryClient = useQueryClient();

  return useMutation(({ attrs }) => AdminWlPartners.create(attrs), {
    onSuccess: () => {
      queryClient.invalidateQueries("admin_wl_partners");
    },
  });
}
