import React from "react";

import FormInput from "components/forms/inputs/formik/form_input";

export default function SlugField({ t, schema, namespace, field, inputRef, handleFieldChange }) {
  const handleChange = (value, affectedField, form) => {
    const parsedValue = value.toLowerCase().replace(/[^a-z\d-]/gm, "");

    handleFieldChange(parsedValue, affectedField, form);
  };

  return (
    <FormInput
      namespace={namespace}
      name={field}
      view="horizontal"
      placeholder={t(`channels_page:form:settings_${field}_placeholder`)}
      label={t(`channels_page:form:settings_${field}_label`)}
      type={schema.type}
      inputRef={inputRef}
      onChange={handleChange}
    />
  );
}
