import React, { Component } from "react";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { Form, Input } from "antd";
import { Field } from "formik";

import getFieldLayout from "../../utils/get_field_layout";

import styles from "./form_secret_input.module.css";

export default class FormSecretInput extends Component {
  state = {
    visible: false,
  };

  toggleVisibility = () => this.setState(({ visible }) => ({ visible: !visible }));

  handleChange = (field, form) => (event) => {
    const { onChange } = this.props;

    onChange(event.target.value, field, form);
  };

  renderAddonIcon = () => {
    const { visible } = this.state;

    const iconProps = {
      className: styles.addonAfter,
      onClick: this.toggleVisibility,
    };

    return visible ? <EyeOutlined {...iconProps} /> : <EyeInvisibleOutlined {...iconProps} />;
  };

  render() {
    const { namespace, name, label, placeholder, view, inputRef } = this.props;
    const { visible } = this.state;

    const fieldName = namespace ? `${namespace}.${name}` : name;
    const inputClass = visible ? null : styles.password;
    const inputView = getFieldLayout(view);
    const addonAfterIcon = this.renderAddonIcon();

    return (
      <Field name={fieldName}>
        {({ field, form }) => (
          <Form.Item {...inputView} label={label} data-cy="input_secret">
            <Input
              className={inputClass}
              autoComplete="off"
              spellCheck={false}
              placeholder={placeholder}
              value={field.value}
              addonAfter={addonAfterIcon}
              inputRef={inputRef}
              onChange={this.handleChange(field, form)}
            />
          </Form.Item>
        )}
      </Field>
    );
  }
}
