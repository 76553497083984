import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { MailOutlined } from "@ant-design/icons";
import { Form } from "antd";

import AuthFormSubTitle from "components/auth_form_sub_title";
import SubmitButton from "components/forms/buttons/submit_button";
import EmailInput from "components/forms/inputs/email";

import styles from "./password_recovery_form.module.css";

function PasswordRecoveryFormComponent({ handleSubmit, isSubmitting, status }) {
  const { t } = useTranslation();

  const renderForm = () => {
    return (
      <>
        <AuthFormSubTitle>
          {t("password_recovery_page:sub_title")}
        </AuthFormSubTitle>
        <Form onFinish={handleSubmit}>
          <EmailInput />

          <SubmitButton loading={isSubmitting}>
            <MailOutlined />
            {t("password_recovery_page:submit_button")}
          </SubmitButton>
        </Form>
      </>
    );
  };

  const renderMessage = () => {
    return (
      <div className={`recovery-message ${styles.wrapperMessage}`}>
        {t("password_recovery_page:success_message_1")}
        <br />
        {t("password_recovery_page:success_message_2")}
        <br />
        <br />
      </div>
    );
  };

  return status && status.sent ? renderMessage() : renderForm();
}

PasswordRecoveryFormComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  status: PropTypes.object,
};

export default PasswordRecoveryFormComponent;
