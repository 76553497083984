import attributesExtractor from "../utils/attributes_extractor";

let transport;

const ENDPOINT = "room_facilities";

export default class RoomFacilities {
  constructor(container) {
    transport = container.transport;
  }

  options() {
    return transport.send("GET", `${ENDPOINT}/options`).then(({ data }) => {
      return attributesExtractor(data);
    });
  }
}
