import React from "react";

import styles from "./sections.module.css";

export default function ({ children }) {
  return (
    <div className={styles.container}>
      {children}
    </div>
  );
}
