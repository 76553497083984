export default [
  {
    value: "DECLINE_REASON_HOST_DOUBLE|DECLINE_REASON_HOST_EMERGENCY",
    representation: "The host has an extenuating circumstance.",
  },
  {
    value: "DECLINE_REASON_HOST_DOUBLE|DECLINE_REASON_HOST_HOST_UNAVAILABLE",
    representation: "The host cannot host on these dates anymore.",
  },
  {
    value: "DECLINE_REASON_HOST_DOUBLE|DECLINE_REASON_HOST_DOUBLE_BOOKED",
    representation: "Another guest is already staying there on these dates.",
  },
  {
    value: "DECLINE_REASON_HOST_CHANGE|DECLINE_REASON_HOST_RESERVATION_LENGTH",
    representation: "The host wants a shorter or longer reservation.",
  },
  {
    value: "DECLINE_REASON_HOST_CHANGE|DECLINE_REASON_HOST_DIFFERENT_PRICE",
    representation: "The host wants a different price.",
  },
  {
    value: "DECLINE_REASON_HOST_CHANGE|DECLINE_REASON_HOST_CHANGE_RESERVATION",
    representation: "The host wants to change the reservation.",
  },
  {
    value: "DECLINE_REASON_HOST_BAD_REVIEWS_SPARSE_PROFILE|",
    representation:
      "The guest has negative reviews indicating they have broken house rules in the past. The information provided to Airbnb won't be shared with the guest.",
  },
  {
    value: "DECLINE_REASON_COVID19_HOST|",
    representation: "The host needs to cancel for reasons related to the Covid-19 pandemic.",
  },
  {
    value: "DECLINE_REASON_HOST_UNAUTHORIZED_PARTY|DECLINE_REASON_HOST_PARTY_REVIEWS",
    representation:
      "The guest has negative reviews indicating that they have thrown a party in the past.",
  },
  {
    value: "DECLINE_REASON_HOST_UNAUTHORIZED_PARTY|DECLINE_REASON_HOST_PARTY_INDICATION",
    representation:
      "The guest indicated that they are going to throw a party in our message thread.",
  },
  {
    value: "DECLINE_REASON_HOST_UNAUTHORIZED_PARTY|DECLINE_REASON_HOST_PARTY_UNRESPONSIVE",
    representation:
      "The guest has not answered my questions about their trip including trip purpose and cotravelers.",
  },
  {
    value: "DECLINE_REASON_HOST_UNAUTHORIZED_PARTY|DECLINE_REASON_HOST_PARTY_AD",
    representation:
      "The guest has advertised a party or event on social media during the reservation dates.",
  },
  {
    value: "DECLINE_REASON_HOST_UNAUTHORIZED_PARTY|DECLINE_REASON_HOST_PARTY_OTHER",
    representation: "I have other concerns about this guest throwing a party.",
  },
  {
    value: "DECLINE_REASON_HOST_BEHAVIOR|DECLINE_REASON_HOST_BEHAVIOR_REVIEWS",
    representation:
      "The guest has negative reviews indicating they have broken house rules in the past.",
  },
  {
    value: "DECLINE_REASON_HOST_BEHAVIOR|DECLINE_REASON_HOST_BEHAVIOR_INDICATION",
    representation:
      "The guest indicated that they are going to break my house rules in our message thread.",
  },
  {
    value: "DECLINE_REASON_HOST_BEHAVIOR|DECLINE_REASON_HOST_BEHAVIOR_UNRESPONSIVE",
    representation:
      "The guest has not answered my questions about their trip including trip purpose and cotravelers.",
  },
  {
    value: "DECLINE_REASON_HOST_BEHAVIOR|DECLINE_REASON_HOST_BEHAVIOR_OTHER",
    representation: "I have other concerns about this guest's behavior.",
  },
  {
    value: "DECLINE_REASON_HOST_BEHAVIOR|DECLINE_REASON_HOST_GUEST_PROFILE",
    representation: "The guest has bad reviews or not enough profile information.",
  },
  {
    value: "DECLINE_REASON_HOST_BEHAVIOR|DECLINE_REASON_HOST_NOT_FIT",
    representation: "The guest broke a house rule or listing does not fit their needs.",
  },
  {
    value: "DECLINE_REASON_HOST_BEHAVIOR|DECLINE_REASON_HOST_OTHER_CONCERN",
    representation: "I have other concerns about this guest.",
  },
  {
    value: "DECLINE_REASON_HOST_ASKED|DECLINE_REASON_HOST_GUEST_WRONG_BOOKING",
    representation:
      "The guest asked the host to cancel. The booking information is wrong, and they want to rebook.",
  },
  {
    value: "DECLINE_REASON_HOST_ASKED|DECLINE_REASON_HOST_GUEST_PERSONAL_REASON",
    representation:
      "The guest asked the host to cancel due to itinerary changes due to the guest's personal reasons.",
  },
  {
    value: "DECLINE_REASON_HOST_ASKED|DECLINE_REASON_HOST_GUEST_EXTENUATING_REASON",
    representation:
      "The guest asked the host to cancel due to special circumstances (disasters, diseases, etc.)",
  },
  {
    value: "DECLINE_REASON_HOST_ASKED|DECLINE_REASON_HOST_GUEST_OTHER_REASON",
    representation: "The guest asked the host to cancel due to other reasons.",
  },
  {
    value: "DECLINE_REASON_HOST_GUEST_MUTUAL_CANCELLATION|",
    representation: "The host wants to initiate a mutual cancellation.",
  },
  {
    value: "DECLINE_REASON_HOST_OTHER|",
    representation: "The host wants to cancel for a reason other than what was listed above.",
  },
];
