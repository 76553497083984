import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ReloadOutlined } from "@ant-design/icons";
import { Button, Select } from "antd";

import DateRange from "./date_range";

const FILTERS = {
  dateRange: ({ value, onChange }) => (
    <DateRange
      value={value}
      onChange={onChange}
    />
  ),
  select: ({ value, options, placeholder, onChange }) => (
    <Select
      style={{ width: "100%" }}
      mode="multiple"
      showSearch
      allowClear
      maxTagCount="responsive"
      placeholder={placeholder}
      optionFilterProp="children"
      value={value}
      onChange={onChange}
      filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
      options={options}
    />
  ),
};

export default function FiltersTopBar({ value = {}, filters = {}, onChange, onReload }) {
  const { t } = useTranslation();

  const handleChange = useCallback((name) => (fieldValue) => {
    onChange({
      ...value,
      [name]: fieldValue,
    });
  }, [value, onChange]);

  const filterNames = Object.keys(filters);

  const filtersComponents = useMemo(() => {
    return filterNames.map((name) => {
      const { type, options, placeholder, ...styleOptions } = filters[name];
      const Filter = FILTERS[type];

      const style = {};
      if (Object.keys(styleOptions).length) {
        Object.assign(style, styleOptions);
      }

      return (
        <div key={name} style={{ flex: 1, ...style }}>
          <Filter
            options={options}
            placeholder={placeholder}
            value={value[name]}
            onChange={handleChange(name)}
          />
        </div>
      );
    });
  }, [filters, filterNames, value, handleChange]);

  return (
    <div style={{ padding: "8px", gap: "8px", backgroundColor: "#fafafa", display: "flex", justifyContent: "space-between" }}>
      <div style={{ display: "flex", flex: 1, gap: "8px" }} data-cy="crud_table_filter_bar">
        {filtersComponents}
      </div>
      <div>
        <Button icon={<ReloadOutlined />} onClick={onReload}>{t("general:action:refresh")}</Button>
      </div>
    </div>
  );
}
