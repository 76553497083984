import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import classNames from "classnames";

import dayjs from "utils/dayjs";
import getChannelInfoByCode from "utils/get_channel_info_by_code";

import { MainSection, RightSection } from "./common";

import styles from "./events_list.module.css";

const EVENT_UNMAPPED_ROOM = "booking_unmapped_room";

const getChannelIcon = (booking_unique_id) => {
  if (booking_unique_id) {
    const channelCode = booking_unique_id.substr(0, 3);

    const channel = getChannelInfoByCode(channelCode);

    if (channel && channel.icon) {
      return <img src={channel.icon} className={styles.icon} alt={channel.title} />;
    }
    return <div className={styles.emptyIcon}>{channelCode}</div>;
  }
  return null;
};

function EventsListItemReservationRequest({ item, isMobile }) {
  const { t } = useTranslation();
  const { event, payload } = item;
  const bms = payload.bms || payload;
  const { amount, arrival_date, unique_id, rooms, currency, customer, inserted_at } = bms;

  const customer_name = customer ? `${customer.name} ${customer.surname}` : bms.customer_name;
  const count_of_rooms = rooms ? rooms.length : 0;
  const count_of_nights = rooms ? Object.keys(rooms[0].days).length : 0;

  const eventTitle = useMemo(() => {
    return t("live_feed_events_widget:events:reservation_request").toUpperCase();
  }, [t]);

  const arrivalDateValue = useMemo(() => {
    const date = event === EVENT_UNMAPPED_ROOM ? inserted_at : arrival_date;
    return dayjs(date).format(t("formats:date_time_with_weekday"));
  }, [t, event, inserted_at, arrival_date]);

  const nightPostFix = count_of_nights > 1 ? "s" : "";
  const roomPostFix = count_of_rooms > 1 ? "s" : "";

  const channelIcon = getChannelIcon(unique_id);
  const eventTitleClassName = classNames(styles.event, styles[`event--${event}`]);

  return (
    <>
      <MainSection>
        <p className={eventTitleClassName}>{eventTitle}</p>
        <p className={styles.arrivalDate}>{arrivalDateValue}</p>

        <p className={styles.description}>
          {customer_name} <span>|</span> {count_of_rooms} {t("live_feed_events_widget:room")}
          {roomPostFix} <span>x</span> {count_of_nights} {t("live_feed_events_widget:night")}
          {nightPostFix}
        </p>
      </MainSection>
      <RightSection
        isMobile={isMobile}
        channelIcon={channelIcon}
        amount={amount}
        currency={currency}
      />
    </>
  );
}

EventsListItemReservationRequest.propTypes = {
  item: PropTypes.shape({}),
};

export default EventsListItemReservationRequest;
