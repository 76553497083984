import { useWindowSize } from "./use_window_size";

export const withWindowSize = (options) => {
  const WithWindowSize = (Component) => {
    const WithWindowSizeHoc = (props) => {
      const windowSize = useWindowSize(options);

      return <Component windowSize={windowSize} {...props} />;
    };

    return WithWindowSizeHoc;
  };

  return WithWindowSize;
};
