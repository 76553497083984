import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button, Select } from "antd";
import classNames from "classnames";

import RatePlanTitle from "components/rates/title";

import selectStyles from "./mapping_select.module.css";

class MappingSelect extends Component {
  state = {
    isOpen: false,
    sortedOptions: [],
  };

  containerRef = React.createRef();

  componentDidMount() {
    this.setSortedOptions();
  }

  componentDidUpdate(prevProps) {
    const { options } = this.props;

    if (prevProps.options !== options) {
      this.setSortedOptions();
    }
  }

  setSortedOptions = () => {
    const { options = [] } = this.props;
    const sortedOptions = [...options];

    sortedOptions.sort((rateA, rateB) => {
      const isTitleEqual = rateA.title.localeCompare(rateB.title);

      if (isTitleEqual) {
        return isTitleEqual;
      }

      return rateA.occupancy - rateB.occupancy;
    });

    this.setState({ sortedOptions });
  };

  renderValue = () => {
    const { value } = this.props;

    if (Array.isArray(value)) {
      return this.renderArrayValue();
    }

    return this.renderSingleValue();
  };

  renderSingleValue = () => {
    const { t, options, value } = this.props;
    const placeholder = t("channels_page:form:not_mapped");

    const selectedOption = options.find((rt) => rt.id === value);

    if (!selectedOption) {
      return placeholder;
    }

    return <RatePlanTitle className={selectStyles.rateTitle} rate={selectedOption} />;
  };

  renderArrayValue = () => {
    const { t, options, value } = this.props;
    const placeholder = t("channels_page:form:not_mapped");

    const selectedOptions = options.filter((rt) => value.includes(rt.id));
    const optionsLength = selectedOptions.length;

    if (!optionsLength) {
      return placeholder;
    }

    const optionsClass = optionsLength > 1 ? selectStyles.conflictingOptions : "";

    return selectedOptions.map((option, index) => {
      return (
        <div className={optionsClass} key={index}>
          <RatePlanTitle className={selectStyles.rateTitle} rate={option} />
        </div>
      );
    });
  };

  getOptions = () => {
    const { sortedOptions } = this.state;

    return sortedOptions.map((option) => (
      <Select.Option key={option.id} label={option.title}>
        <RatePlanTitle className={selectStyles.rateTitle} rate={option} />
      </Select.Option>
    ));
  };

  toggleSelect = () => this.setState(({ isOpen }) => ({ isOpen: !isOpen }));

  handleSelect = (value) => {
    const { onChange } = this.props;

    onChange(value);
    this.toggleSelect();
  };

  handleBlur = () => this.toggleSelect();

  getPopupContainer = () => this.containerRef && this.containerRef.current;

  render() {
    const { t, value } = this.props;
    const { isOpen } = this.state;
    const selectValue = value || undefined;
    const isDanger = (selectValue && selectValue.length === 0) || !selectValue;
    const buttonClass = classNames(selectStyles.button, isOpen ? selectStyles.transparent : null);

    return (
      <div className={selectStyles.container} ref={this.containerRef}>
        <Button
          type="link"
          className={buttonClass}
          onClick={this.toggleSelect}
          danger={isDanger}
        >
          {this.renderValue()}
        </Button>
        {isOpen && (
          <Select
            className={selectStyles.select}
            placeholder={t("channels_page:form:not_mapped")}
            value={selectValue}
            defaultOpen
            autoFocus
            getPopupContainer={this.getPopupContainer}
            size="small"
            showSearch
            onSelect={this.handleSelect}
            onBlur={this.handleBlur}
            filterOption={(input, option) => (option?.label || "").toLowerCase().includes(input.toLowerCase())}
          >
            {this.getOptions()}
          </Select>
        )}
      </div>
    );
  }
}

export default withTranslation()(MappingSelect);
