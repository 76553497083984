import React from "react";
import Highlighter from "react-highlight-words";

export default function FormTableSelectItem({ isSearched, searchQuery, text }) {
  if (!isSearched) {
    return text;
  }

  const formattedText = text ? text.toString() : "";

  return <Highlighter textToHighlight={formattedText} autoEscape searchWords={[searchQuery]} />;
}
