import { useQueryParams } from "hooks/use_query_params/use_query_params";

import { formatDate, safeParseDate } from "utils/dates";
import dayjs from "utils/dayjs";

const DATE_TYPE_VALUES = ["booking", "checkin"];
const DEFAULT_DATE_TYPE = "booking";
const DEFAULT_DATE_RANGE = [
  dayjs().subtract(1, "months").startOf("day"),
  dayjs().startOf("day"),
];

export const useReportQueryParams = () => useQueryParams({
  from: {
    parse: (value) => safeParseDate(value, { native: false }),
    format: formatDate,
    default: DEFAULT_DATE_RANGE[0],
  },
  to: {
    parse: (value) => safeParseDate(value, { native: false }),
    format: formatDate,
    default: DEFAULT_DATE_RANGE[1],
  },
  dateType: {
    parse: (value) => {
      if (DATE_TYPE_VALUES.includes(value)) {
        return value;
      }
      return null;
    },
    default: DEFAULT_DATE_TYPE,
  },
}, {
  // do not expose default params values to url
  beforeUpdate: ({ from, to, dateType }) => {
    const newParams = {};

    if (dateType !== DEFAULT_DATE_TYPE) {
      newParams.dateType = dateType;
    }

    if (from && to) {
      const isDefaultDatesRange = from.isSame(DEFAULT_DATE_RANGE[0]) && to.isSame(DEFAULT_DATE_RANGE[1]);

      if (!isDefaultDatesRange) {
        newParams.from = from;
        newParams.to = to;
      }
    }

    return newParams;
  },
});
