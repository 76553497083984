import attributesExtractor from "../utils/attributes_extractor";
import relationshipsExtractor from "../utils/relationships_extractor";

let transport;
let storage;

const ENDPOINT = "room_types";
const PROPERTIES_LIMIT = 15;

export default class RoomTypes {
  constructor(container) {
    transport = container.transport;
    storage = container.storage;
  }

  list(filter = {}, pagination = {}, order = {}) {
    return transport.send("GET", ENDPOINT, { filter, pagination, order }).then((response) => {
      storage.roomTypesLoad(response.data, response.meta);
      return {
        entities: response.data.map((el) => el.attributes),
        meta: response.meta,
      };
    });
  }

  options(filter = {}) {
    if (!Array.isArray(filter.property_id)) {
      return transport.send("GET", `${ENDPOINT}/options`, { filter }).then(({ data }) => {
        return attributesExtractor(data);
      });
    }

    // TODO - replace with more universal solution
    const selectedProperties = [];

    for (let i = 0; i < filter.property_id.length; i += PROPERTIES_LIMIT) {
      selectedProperties.push(filter.property_id.slice(i, i + PROPERTIES_LIMIT));
    }

    const roomOptionsRequests = selectedProperties.map((propertiesArray) => {
      const updatedFilter = { ...filter, property_id: propertiesArray };

      return transport.send("GET", `${ENDPOINT}/options`, { filter: updatedFilter });
    });

    return Promise.all(roomOptionsRequests).then((response) => {
      const optionsByID = response
        .map(({ data }) => attributesExtractor(data))
        .flat()
        .reduce((acc, option) => ({ ...acc, [option.id]: option }), {});

      return Object.values(optionsByID);
    });
  }

  find(id) {
    return transport.send("GET", `${ENDPOINT}/${id}`).then(({ data }) => {
      return relationshipsExtractor(data);
    });
  }

  create(attrs) {
    return transport.send("POST", ENDPOINT, { room_type: attrs }).then((response) => {
      storage.roomTypesAdd(response.data);
      return response;
    });
  }

  update(attrs, force = false) {
    return transport
      .send("PUT", `${ENDPOINT}/${attrs.id}?force=${force}`, { room_type: attrs })
      .then((response) => {
        storage.roomTypesAdd(response.data);
        return response;
      });
  }

  remove(attrs) {
    return transport.send("DELETE", `${ENDPOINT}/${attrs.id}`).then((response) => {
      storage.roomTypesDrop(attrs);
      return response;
    });
  }

  forceRemove(attrs) {
    return transport.send("DELETE", `${ENDPOINT}/${attrs.id}?force=true`).then((response) => {
      storage.roomTypesDrop(attrs);
      return response;
    });
  }

  updatePositions(positions) {
    return transport.send("POST", `${ENDPOINT}/update_positions`, {
      room_types: positions,
    });
  }
}
