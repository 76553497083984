// there is no channel_id for non-channel rate plans

const isNonChannelRatePlan = (ratePlan) => ratePlan.channel_id === null || ratePlan.channel_id === undefined;

const filterRatePlansAsArray = (ratePlans) => ratePlans.filter(isNonChannelRatePlan);

export default function filterChannelRatePlans(ratePlans) {
  if (Array.isArray(ratePlans)) {
    return filterRatePlansAsArray(ratePlans);
  }

  const asArray = Object.entries(ratePlans);
  const filtered = asArray.filter(([_, ratePlan]) => isNonChannelRatePlan(ratePlan));

  return Object.fromEntries(filtered);
}
