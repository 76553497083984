import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import * as Sentry from "@sentry/react";
import { Formik } from "formik";
import _ from "lodash";

import GlobalErrors from "components/forms/global_errors";

import withComponentRef from "containers/with_component_ref";
import withDebounceContainer from "containers/with_debounce";

class ChannexForm extends Component {
  constructor(props) {
    super(props);

    const { value } = props;
    this.initialValues = this.getValue(value);

    this.state = {
      apiErrors: {},
    };
  }

  initialValues = {};

  formik = React.createRef();

  shouldComponentUpdate(newProps) {
    const { value, errors } = this.props;

    const oldValue = this.getValue(value);
    const newValue = this.getValue(newProps.value);

    const valueChanged = !_.isEqual(oldValue, newValue);
    const errorsChanged = newProps.errors !== errors;

    return valueChanged || errorsChanged;
  }

  componentDidMount() {
    this.initForm();
  }

  componentDidUpdate({ errors: oldErrors, value: oldValue }) {
    this.handleFormPropsUpdate(oldErrors, oldValue);
  }

  initForm = async () => {
    await this.updateFormValue();
    await this.updateFormErrors();
  };

  handleFormPropsUpdate = async (oldErrors, oldValue) => {
    const { errors, value } = this.props;

    if (!_.isEqual(value, oldValue)) {
      await this.updateFormValue();
    }

    if (!_.isEqual(errors, oldErrors)) {
      await this.updateFormErrors();
    }
  };

  updateFormValue = () => {
    const { value } = this.props;

    const updatedFormValue = this.getValue(value);

    return this.setValue(updatedFormValue);
  };

  updateFormErrors = () => {
    return new Promise((resolve) => {
      const apiErrors = this.getErrors();

      const fieldToTouch = Object.keys(apiErrors).reduce(
        (acc, field) => ({ ...acc, [field]: true }),
        {},
      );

      this.setState({ apiErrors }, () => this.formik.current.setTouched(fieldToTouch).then(resolve));
    });
  };

  setValue = (newValue) => this.formik.current.setValues(newValue);

  getValue = (value) => {
    const { defaultValue } = this.props;
    const formattedValue = this.getFormRelatedData(value);

    return { ...defaultValue, ...formattedValue };
  };

  getErrors = () => {
    const { errors } = this.props;
    const formattedErrors = this.getFormRelatedData(errors);

    return formattedErrors;
  };

  getFields = () => {
    const { fields } = this.props;

    return fields;
  };

  getFormRelatedData = (value = {}) => {
    const { fields } = this.props;

    if (!fields) {
      return value;
    }

    return fields.reduce((resultingValue, fieldName) => {
      const fieldValue = value[fieldName];

      if (fieldValue !== undefined && fieldValue !== null) {
        resultingValue[fieldName] = fieldValue;
      }

      return resultingValue;
    }, {});
  };

  validate = () => {
    const { onChange, withDebounce } = this.props;

    if (!this.formik.current) {
      return Promise.reject();
    }

    if (withDebounce) {
      onChange.flush();
    }

    return this.formik.current
      .validateForm()
      .then((errors) => {
        if (Object.keys(errors).length === 0) {
          return Promise.resolve();
        }

        Object.keys(errors).forEach((name) => this.formik.current.setFieldTouched(name, true));

        return Promise.reject(errors);
      });
  };

  handleChange = (newValue) => {
    const { onChange, value, validationSchema } = this.props;
    const { apiErrors } = this.state;

    onChange(newValue);

    const updatedField = Object.keys(newValue).find((key) => newValue[key] !== value[key]);
    let newApiErrors = apiErrors;

    if (updatedField) {
      // we should reset error in both camelCase and snake_case from api, as set in src/utils/parse_api_errors.js
      newApiErrors = _.omit(apiErrors, [updatedField, _.camelCase(updatedField), _.snakeCase(updatedField)]);
      this.setState({ apiErrors: newApiErrors });
    }

    if (!validationSchema) {
      return Promise.resolve();
    }

    return validationSchema
      .validate(newValue, { abortEarly: false })
      .then(() => ({}))
      .catch((error) => {
        // if validation throw exception from yup or custom validation
        // for now just capture it to sentry and pass validation to not break existing forms
        if (error.name !== "ValidationError") {
          Sentry.captureException(error);
          return {};
        }

        const innerErrors = error.inner?.reduce((acc, validationError) => {
          const { path, message } = validationError;

          return { ...acc, [path]: message };
        }, {});

        return innerErrors;
      })
      .then((errors) => {
        const errorsWithApi = { ...newApiErrors, ...errors };

        return Object.keys(errorsWithApi).length === 0
          ? Promise.resolve({})
          : Promise.resolve(errorsWithApi);
      });
  };

  render() {
    const { children, globalErrors } = this.props;

    return (
      <Formik
        initialValues={this.initialValues}
        innerRef={this.formik}
        validate={this.handleChange}
      >
        {(form) => (
          <>
            <GlobalErrors errors={globalErrors} />
            {children(form)}
          </>
        )}
      </Formik>
    );
  }
}

export default withTranslation()(withDebounceContainer(withComponentRef(ChannexForm)));
