import React from "react";
import { useTranslation } from "react-i18next";

import SectionHeader from "../../../../../_common/section_header/section_header";
import Text from "../../../../../_common/text";

const isPresent = (val) => val !== "" && val !== null && val !== undefined;

export const Feedback = ({ review }) => {
  const { t } = useTranslation();

  const { rawContent } = review.attributes;

  const { title, body } = rawContent || {};

  const isTitlePresent = isPresent(title);
  const isFeedbackPresent = isTitlePresent || isPresent(body);

  return (
    <>
      {isTitlePresent && <SectionHeader header={title} />}
      {isFeedbackPresent && <Text>{body}</Text>}
      {!isFeedbackPresent && <Text disabled>{t("reviews_page:feedback:no_content")}</Text>}
    </>
  );
};
