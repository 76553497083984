import UnmappedRoomActionBar from "./action_bar";
import UnmappedRoomContent from "./content";
import loadDetails from "./loadDetails";

const UnmappedRoom = {
  ActionBar: UnmappedRoomActionBar,
  Content: UnmappedRoomContent,
  loadDetails,
};

export default UnmappedRoom;
