import React from "react";

import useRatePlans from "./use_rate_plans";

export default function withRatePlans(options = {}) {
  const {
    multiOccupancy = false,
    groupResults = false,
  } = options;

  return function withRatePlansHOC(Component) {
    function WithRatePlans(props) {
      const { ratePlans, error, isLoading } = useRatePlans(props.propertyId, {
        multiOccupancy,
        groupResults,
      });

      return <Component {...props} ratePlans={ratePlans} ratePlansError={error} isRatePlansLoading={isLoading} />;
    }

    return WithRatePlans;
  };
}
