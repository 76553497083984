import React, { useCallback, useEffect, useState } from "react";

import MappingRow from "components/mapping/mapping_row";
import RateTitleWithId from "components/rates/title_with_id";

export default function RateMapping({ mapping, rateOptions, ratePlans, onDelete }) {
  const [rateData, setRateData] = useState({});
  const [mappedRateData, setMappedRateData] = useState({});

  const handleRateDelete = useCallback(() => {
    onDelete(mapping);
  }, [mapping, onDelete]);

  useEffect(() => {
    const { rate_plan_code: ratePlanCode, rate_plan_id: ratePlanId } = mapping;

    const rate = rateOptions.find((rateOption) => rateOption.id === ratePlanCode) || {
      title: ratePlanCode,
    };
    const mappedRate = ratePlans.find((ratePlan) => ratePlan.id === ratePlanId) || {
      title: ratePlanId,
    };

    setRateData(rate);
    setMappedRateData(mappedRate);
  }, [rateOptions, ratePlans, mapping]);

  return (
    <MappingRow
      type="rate"
      isMapped
      disabled={mapping.type === "auto"}
      title={<RateTitleWithId rate={rateData} />}
      action={<RateTitleWithId rate={mappedRateData} />}
      onDelete={handleRateDelete}
    />
  );
}
