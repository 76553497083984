import { useQuery } from "react-query";
import store from "store";

const { Integrations: { Apaleo } } = store;

export const useApaleoRoomRates = ({ apaleoProperty, appName, enabled }) => {
  return useQuery(["apaleo_room_rates", apaleoProperty], () => Apaleo.getPropertyRoomRates({ appName, apaleoProperty }), {
    enabled: !!apaleoProperty && enabled,
  });
};
