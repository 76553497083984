import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, Modal, notification } from "antd";
import { Formik } from "formik";
import store from "store";

import FormikFormInput from "components/forms/inputs/formik/form_input";
import FormikSelect from "components/forms/inputs/formik/form_select";

import dayjs from "utils/dayjs";

import CancellationReasons from "./cancellation_reasons";

import utilStyles from "styles/utils.module.css";

const { Channels } = store;

export default function (props) {
  const { t } = useTranslation();
  const { revision } = props;
  const { channel, ota_name, status, arrival_date } = revision;
  const dateDiff = dayjs(arrival_date).diff(dayjs(), "days");
  const [visible, setVisible] = useState(false);
  const bookingCancellationValue = {};
  const bookingCancellationFormRef = React.createRef();

  const cancelBooking = () => {
    setVisible(true);
  };

  const onCloseCancelBooking = () => {
    setVisible(false);
  };

  const onCancelBooking = () => {
    const values = bookingCancellationFormRef.current.values;
    const [reason, sub_reason] = values.reason.split("|");
    const { channel_id, ota_reservation_code } = revision;
    const { message_to_guest, message_to_airbnb } = values;
    const cancellation = {
      ota_reservation_code,
      reason,
      message_to_guest,
      message_to_airbnb,
    };

    if (sub_reason !== "") {
      cancellation.sub_reason = sub_reason;
    } else {
      cancellation.sub_reason = null;
    }

    Channels.airbnbCancelBooking(channel_id, { cancellation })
      .then(() => {
        notification.success({
          message: "Cancellation message sent",
          description: "Cancellation message sent",
        });
        onCloseCancelBooking();
      })
      .catch((error) => {
        notification.error({
          message: "Cancellation error",
          description: error.errors?.error_message || "Unknown Error",
        });
      });
  };

  const renderCancelBookingModal = () => {
    const cancellationFee = (
      revision.notes
        .split("\n")
        .filter((item) => item.startsWith("Listing Cancellation Host Fee"))[0] || ""
    ).split(": ")[1] || "";

    return (
      (
        <Modal
          title={t("bookings_page:booking_view_dialog:cancellation:modal_title")}
          open={visible}
          onOk={onCancelBooking}
          onCancel={onCloseCancelBooking}
        >
          <p>
            {t("bookings_page:booking_view_dialog:cancellation:message")}
            <br />
            {t("bookings_page:booking_view_dialog:cancellation:fee", { cancellationFee })}
          </p>
          <Formik initialValues={bookingCancellationValue} innerRef={bookingCancellationFormRef}>
            <Form>
              <FormikSelect
                key="reason"
                name="reason"
                placeholder={t("bookings_page:booking_view_dialog:cancellation:reason")}
                label={t("bookings_page:booking_view_dialog:cancellation:reason")}
                options={CancellationReasons}
              />
              <FormikFormInput
                key="message_to_guest"
                name="message_to_guest"
                placeholder={t("bookings_page:booking_view_dialog:cancellation:message_to_guest")}
                label={t("bookings_page:booking_view_dialog:cancellation:message_to_guest")}
              />
              <FormikFormInput
                key="message_to_airbnb"
                name="message_to_airbnb"
                placeholder={t("bookings_page:booking_view_dialog:cancellation:message_to_airbnb")}
                label={t("bookings_page:booking_view_dialog:cancellation:message_to_airbnb")}
              />
            </Form>
          </Formik>
        </Modal>
      )
    );
  };

  if (channel && ota_name === "Airbnb" && status !== "cancelled" && dateDiff >= 0) {
    return (
      <>
        <div className={utilStyles.textRight}>
          <Button danger type="primary" onClick={cancelBooking}>
            {t("bookings_page:booking_view_dialog:cancellation:button_text")}
          </Button>
        </div>
        {visible ? renderCancelBookingModal() : null}
      </>
    );
  }

  return null;
}
