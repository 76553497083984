import React from "react";
import { useTranslation } from "react-i18next";

import FormSelect from "../form_select";

export const RoomTypeSelect = ({ name, label, placeholder, roomTypes, isLoading, isDisabled, control, ...rest }) => {
  const { t } = useTranslation();

  const DEFAULT_LABEL = t("room_type:input:label");
  const DEFAULT_PLACEHOLDER = t("room_type:input:select_placeholder");

  return (
    <FormSelect
      control={control}
      name={name || "roomTypeId"}
      label={label || DEFAULT_LABEL}
      placeholder={placeholder || DEFAULT_PLACEHOLDER}
      options={roomTypes?.map((r) => ({ label: r.title, value: r.id }))}
      loading={isLoading}
      disabled={isDisabled}
      {...rest}
    />
  );
};
