import React from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useMatch, useMatches, useParams } from "react-router-dom";

import ChannexDrawerRoutable from "drawers/channex_drawer_routable";

import PropertyForm from "components/property_form";

import useRouting from "routing/use_routing";

const getBasePath = (propertyId, isOnGroupsPage, userAppRoutes) => {
  const propertiesPath = {
    create: userAppRoutes.properties.create,
    edit: userAppRoutes.properties.edit,
  };

  const groupsPath = {
    create: userAppRoutes.groups.createProperty,
    edit: userAppRoutes.groups.editProperty,
  };

  const pageRelatedRoutes = isOnGroupsPage ? groupsPath : propertiesPath;
  return propertyId ? pageRelatedRoutes.edit : pageRelatedRoutes.create;
};

function PropertyFormDrawer() {
  const { t } = useTranslation();
  const { userAppRoutes } = useRouting();
  const { propertyId } = useParams();
  const title = t(
    propertyId ? "properties_page:edit_dialog_header" : "properties_page:create_dialog_header",
  );
  const isOnGroupsPage = useMatch({
    path: userAppRoutes.groups.editProperty._path,
    end: false,
  });

  const routeMatches = useMatches();
  const prevRoute = routeMatches[routeMatches.length - 2];

  let closePath;

  if (prevRoute) {
    closePath = prevRoute.pathname;
  }

  const drawerBasePath = getBasePath(propertyId, isOnGroupsPage, userAppRoutes);
  const userInvitePath = drawerBasePath.userInvite;
  const userEditPath = drawerBasePath.userEdit;

  return (
    <ChannexDrawerRoutable title={title} dataCy="property_form_drawer">
      {({ handleCloseFromContent }) => (
        <>
          <PropertyForm
            propertyId={propertyId}
            userInvitePath={userInvitePath}
            userEditPath={userEditPath}
            onClose={handleCloseFromContent}
          />
          {propertyId && <Outlet context={{ closePath }} />}
        </>
      )}
    </ChannexDrawerRoutable>
  );
}

export default PropertyFormDrawer;
