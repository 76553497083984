import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  LinkOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, DatePicker, Tooltip } from "antd";

import READ_ONLY_RESTRICTIONS from "config/constants/inventory/read_only_restrictions";

import RoomFormDrawerControlled from "drawers/room_form_drawer_controlled";

import BulkUpdateButton from "components/inventory/inventory_filters/bulk_update_button";
import RestrictionFilter from "components/inventory/inventory_filters/restriction_filter";
import SaveButton from "components/inventory/inventory_filters/save_button";
import { InventoryTable } from "components/inventory/inventory_table/inventory_table";
import getRestrictionValue from "components/inventory/utils/get_restriction_value";
import Loading from "components/loading";
import NoDataPlaceholder from "components/no_data_placeholder";

import dayjs from "utils/dayjs";

import { RulesTooltip } from "../../inventory/inventory_table/availability_rules/rules_tooltip/rules_tooltip";
import { ChannelAvailabilityRules } from "../../inventory/inventory_table/channel_availability_rules";
import { formatNumber } from "../../inventory/utils/format_number";

import styles from "./mobile_inventory.module.css";
import inventoryTableStyles from "components/inventory/inventory_table/inventory_table.module.css";

class MobileInventory extends Component {
  static propTypes = {
    t: PropTypes.func,
    roomTypes: PropTypes.array,
    ratePlans: PropTypes.array,
    dates: PropTypes.array,
    onValueClick: PropTypes.func,
  };

  state = {
    isTouchMoved: false,
    ratesWithVisibleChannels: [],
    roomDrawer: {
      visible: false,
      roomTypeId: null,
    },
  };

  constructor(props) {
    super(props);

    const { availabilityRules, dates, ratePlans } = props;

    this.channelAvailabilityRules = new ChannelAvailabilityRules({ availabilityRules, dates, ratePlans });
  }

  componentDidUpdate(prevProps) {
    const { availabilityRules, dates, ratePlans } = this.props;

    if (availabilityRules !== prevProps.availabilityRules || dates !== prevProps.dates || ratePlans !== prevProps.ratePlans) {
      this.channelAvailabilityRules = new ChannelAvailabilityRules({ availabilityRules, dates, ratePlans });
    }
  }

  onTouchMove = () => this.setState({ isTouchMoved: true });

  onTouchEnd = (ratePlanId, restriction, date, value) => {
    const { onValueClick } = this.props;

    return () => {
      const { isTouchMoved } = this.state;

      const isAllDataPresent = ratePlanId && restriction && date;

      if (!isTouchMoved && isAllDataPresent) {
        onValueClick(ratePlanId, restriction, [date, date], [value])();
      }

      this.setState({ isTouchMoved: false });
    };
  };

  renderAvailability(roomTypeId) {
    const { availability, dates, changes } = this.props;

    const cells = dates.map((date) => {
      const ariValue = InventoryTable.getAvailability(availability, roomTypeId, date);
      const changedValue = InventoryTable.getValue(changes, roomTypeId, date, "availability");
      const value = InventoryTable.getFinalValue(ariValue, changedValue);
      const isSold = InventoryTable.isSold(value);
      const isChanged = InventoryTable.isChanged(changedValue);
      const isWeekend = InventoryTable.isWeekend(date);

      return this.renderValueCell({
        date,
        value,
        restriction: "availability",
        id: roomTypeId,
        classes: [inventoryTableStyles.table__value, isSold, isChanged, isWeekend].join(" "),
      });
    });

    return <div className={[inventoryTableStyles.table__valuesCell]}>{cells}</div>;
  }

  renderValues(ratePlanId, restriction) {
    const { ari, dates, changes } = this.props;

    if (restriction === "rate_availability") {
      restriction = "availability";
    }

    const cells = dates.map((date) => {
      const ariValue = InventoryTable.getValue(ari, ratePlanId, date, restriction);
      const changedValue = InventoryTable.getValue(changes, ratePlanId, date, restriction);
      const value = InventoryTable.getFinalValue(ariValue, changedValue);
      const isChanged = InventoryTable.isChanged(changedValue);
      const isWeekend = InventoryTable.isWeekend(date);

      const classes = [inventoryTableStyles.table__value, isChanged, isWeekend];

      let tooltip;

      if (restriction === "rate") {
        const isStopSell = InventoryTable.isStopSell(ari, ratePlanId, date);
        classes.push(isStopSell);

        const overrides = this.channelAvailabilityRules.getOverride({ ratePlanId, date });

        tooltip = (
          <RulesTooltip showText={false} overrides={overrides} color={isStopSell ? "red" : "blue"} />
        );
      } else {
        const overrideType = restriction === "stop_sell_manual" ? "close_out" : restriction;
        const overrides = this.channelAvailabilityRules.getOverride({ ratePlanId, date, type: overrideType });
        tooltip = (
          <RulesTooltip showText={false} overrides={overrides} color="blue" />
        );
      }

      return this.renderValueCell({
        date,
        value,
        restriction,
        id: ratePlanId,
        classes: classes.join(" "),
        tooltip,
      });
    });

    return <div className={inventoryTableStyles.table__valuesCell}>{cells}</div>;
  }

  renderValueCell({ date, value, restriction, id, classes, tooltip }) {
    const isLoaded = typeof value !== "undefined";
    const restrictionsValue = getRestrictionValue(restriction, value);

    return (
      <div
        key={date}
        className={classes}
        onTouchMove={this.onTouchMove}
        onTouchEnd={this.onTouchEnd(id, restriction, date, value)}
      >
        {isLoaded && (
          <>
            <span className={inventoryTableStyles.table__valueSpan}>
              {formatNumber(restrictionsValue)}
            </span>
            {tooltip}
          </>
        )}
        {!isLoaded && <span className="ant-skeleton-title">***</span>}
      </div>
    );
  }

  renderRates(roomTypeId) {
    const { ratePlans, filters } = this.props;
    const { selectedRates } = filters;

    return ratePlans
      .filter((ratePlan) => {
        return (
          ratePlan.room_type_id === roomTypeId
          && (selectedRates.length ? selectedRates.indexOf(ratePlan.title) !== -1 : true)
          && !ratePlan.channel_id
        );
      })
      .sort((a, b) => {
        if (`${a.title.toLowerCase()}${a.occupancy}` < `${b.title.toLowerCase()}${b.occupancy}`) {
          return -1;
        }
        if (`${a.title.toLowerCase()}${a.occupancy}` > `${b.title.toLowerCase()}${b.occupancy}`) {
          return 1;
        }
        return 0;
      })
      .map(this.renderRatePlan);
  }

  getTooltipText(ratePlan) {
    const { roomTypesById, ratePlansById } = this.props;

    const parentRatePlan = ratePlansById[ratePlan.parent_rate_plan_id];

    if (!parentRatePlan) {
      return null;
    }

    return (
      <>
        Rates derived from
        <br />
        {ratePlansById[ratePlan.parent_rate_plan_id].title}
        &nbsp; ({ratePlansById[ratePlan.parent_rate_plan_id].occupancy}
        &nbsp; Persons ) &nbsp;/&nbsp;
        {roomTypesById[ratePlansById[ratePlan.parent_rate_plan_id].room_type_id].title}
      </>
    );
  }

  getRatePlanTitle = (ratePlan) => {
    const { channel_id, title } = ratePlan;

    if (channel_id) {
      return title.split(" - ").reverse()[0];
    }

    return title;
  };

  renderRatePlan = (ratePlan) => {
    const { t, filters } = this.props;
    const { ratesWithVisibleChannels } = this.state;
    const { selectedRestrictions } = filters;
    const filteredRestrictions = selectedRestrictions.filter(
      (restriction) => restriction !== "availability",
    );

    if (!filteredRestrictions.length) {
      return null;
    }

    return (
      <React.Fragment key={ratePlan.id}>
        <div className={styles.roomRate}>
          <span>
            {this.getRatePlanTitle(ratePlan)}
            &nbsp;
            <small className={styles.roomRateLabel}>
              <UserOutlined />
              {ratePlan.occupancy}
            </small>
            <small>
              {ratePlan.count_of_channels > 0 && (
                <a href="#toggleChannelsList" onClick={this.toggleChannelsVisibility(ratePlan.id)}>
                  {ratePlan.count_of_channels} {t("inventory_page:connected_channels")}
                </a>
              )}
            </small>
          </span>
        </div>

        {filteredRestrictions.map((restriction, index) => {
          const isInherit = ratePlan[`inherit_${restriction}`];
          const classes = [
            index === 0
              ? inventoryTableStyles.table__rateRow
              : inventoryTableStyles.table__subRateRow,
          ];

          if (isInherit || READ_ONLY_RESTRICTIONS.includes(restriction)) {
            classes.push(inventoryTableStyles.table__inheritRow);
          }

          if (ratePlan.channel_id) {
            classes.push(inventoryTableStyles.table__channelRateRow);
          }

          classes.push(styles.rateRow);

          return (
            <div key={`${ratePlan.id}_${restriction}_${index}`} className={classes.join(" ")}>
              <div className={styles.restrictionTitle}>
                <Tooltip placement="right" title={t(`general:restrictions:${restriction}`)}>
                  {t(`general:restrictions_abbr:${restriction}`)}
                </Tooltip>
                {isInherit && (
                  <small className={inventoryTableStyles.table__rowLabel}>
                    <Tooltip placement="right" title={this.getTooltipText(ratePlan)}>
                      <LinkOutlined />
                    </Tooltip>
                  </small>
                )}
              </div>
              <div className={styles.mobileValuesRowContainer}>
                {this.renderValues(ratePlan.id, restriction)}
              </div>
            </div>
          );
        })}
        {ratesWithVisibleChannels.indexOf(ratePlan.id) !== -1
          && this.renderChannelRates(ratePlan.id)}
      </React.Fragment>
    );
  };

  renderChannelRates = (ratePlanId) => {
    const { ratePlans } = this.props;

    return ratePlans
      .filter((ratePlan) => {
        return ratePlan.channel_id && ratePlan.parent_rate_plan_id === ratePlanId;
      })
      .sort((a, b) => {
        if (`${a.title.toLowerCase()}${a.occupancy}` < `${b.title.toLowerCase()}${b.occupancy}`) {
          return -1;
        }
        if (`${a.title.toLowerCase()}${a.occupancy}` > `${b.title.toLowerCase()}${b.occupancy}`) {
          return 1;
        }
        return 0;
      })
      .map(this.renderRatePlan);
  };

  renderRows() {
    const { t, roomTypes, isLoadingRoomTypes } = this.props;

    if (isLoadingRoomTypes) {
      return <Loading className={styles.table__loading} />;
    }

    if (!roomTypes.length) {
      return (
        <NoDataPlaceholder
          emptyMessage={t("inventory_page:empty_property_message")}
          type="button"
          createMessageText={t("general:create_first_before")}
          createMessageActionText={t("general:create_first_link")}
          onClick={this.handleRoomDrawerOpen(null)}
          className={styles.table__empty}
        />
      );
    }

    return roomTypes.map((roomType) => (
      <div className={styles.roomTypeContainer} key={roomType.id}>
        <div className={styles.roomType}>
          <Button
            className={styles.roomLink}
            type="link"
            onClick={this.handleRoomDrawerOpen(roomType.id)}
          >
            {roomType.title}
          </Button>
        </div>
        <div className={styles.availabilityTitle}>AVL</div>
        <div className={styles.mobileValuesRowContainer}>
          {this.renderAvailability(roomType.id)}
        </div>

        <div>{this.renderRates(roomType.id)}</div>
      </div>
    ));
  }

  handleRoomDrawerOpen = (roomTypeId) => () => {
    const roomDrawer = {
      roomTypeId,
      visible: true,
    };

    this.setState({ roomDrawer });
  };

  handleRoomDrawerClose = () => {
    const roomDrawer = {
      roomTypeId: null,
      visible: false,
    };

    this.setState({ roomDrawer });
  };

  toggleChannelsVisibility = (ratePlanId) => {
    return (event) => {
      event.preventDefault();
      let { ratesWithVisibleChannels } = this.state;

      if (ratesWithVisibleChannels.indexOf(ratePlanId) === -1) {
        ratesWithVisibleChannels.push(ratePlanId);
      } else {
        ratesWithVisibleChannels = ratesWithVisibleChannels.filter((el) => el !== ratePlanId);
      }

      this.setState({ ratesWithVisibleChannels });
    };
  };

  disabledDate(current) {
    return current && current < dayjs().startOf("day");
  }

  render() {
    const { roomDrawer } = this.state;
    const {
      dates,
      filters,
      currentDate,
      hasChanges,
      saveChangesInProgress,
      onOpenBulkUpdate,
      onChangeDate,
      onScrollDates,
      onChange,
      onSaveChanges,
    } = this.props;

    const isToday = dayjs().format("YYYY-MM-DD") === dayjs(currentDate).format("YYYY-MM-DD");
    const currentDateValue = dayjs(currentDate);

    return (
      <>
        <div className={styles.tableFilters}>
          <RestrictionFilter
            className={styles.restrictionFilter}
            filters={filters}
            onChange={onChange}
          />
          <DatePicker
            className={styles.datePicker}
            allowClear={false}
            disabledDate={this.disabledDate}
            value={currentDateValue}
            onChange={onChangeDate}
          />
        </div>
        <div className={styles.bulkUpdate}>
          <BulkUpdateButton onClick={onOpenBulkUpdate} />
          <SaveButton
            disabled={!hasChanges}
            loading={saveChangesInProgress}
            onClick={onSaveChanges}
          />
        </div>
        <div className={[inventoryTableStyles.table__header, styles.tableHeader].join(" ")}>
          <a
            className={styles.prevDateButton}
            href="#prev"
            onClick={onScrollDates(-1)}
            disabled={isToday}
          >
            <ArrowLeftOutlined />
          </a>

          {InventoryTable.renderDates(dates)}

          <a className={styles.nextDateButton} href="#next" onClick={onScrollDates(1)}>
            <ArrowRightOutlined />
          </a>
        </div>
        <div className={styles.rowContainer}>{this.renderRows()}</div>
        <RoomFormDrawerControlled
          visible={roomDrawer.visible}
          roomTypeId={roomDrawer.roomTypeId}
          onClose={this.handleRoomDrawerClose}
        />
      </>
    );
  }
}

const mapStateToProps = ({ ratePlans }) => {
  return {
    ratePlansById: ratePlans,
  };
};

export default connect(mapStateToProps)(MobileInventory);
