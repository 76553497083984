import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Select } from "antd";
import store from "store";
import { getPropertiesOptions } from "store/storage/selectors/properties_selector";
import { getSession } from "store/storage/selectors/session_selector";

const { Auth } = store;

const { Option } = Select;

export const PropertySelector = () => {
  const { t } = useTranslation();

  const session = useSelector(getSession);
  const unfilteredPropertiesOptions = useSelector(getPropertiesOptions);
  const { activeGroup } = session;

  const propertiesOptions = useMemo(() => {
    let options = [];

    if (unfilteredPropertiesOptions) {
      options = unfilteredPropertiesOptions.filter((property) => (
        activeGroup
          ? property.group_ids.indexOf(activeGroup) !== -1
          : true
      ));
    }

    return options;
  }, [unfilteredPropertiesOptions, activeGroup]);

  const handleChangeProperty = useCallback((selectedId) => {
    Auth.chooseProperty(selectedId);
  }, []);

  const isLoading = unfilteredPropertiesOptions?.length === 0;

  if (isLoading) {
    return null;
  }

  return (
    <Select
      style={{ width: "100%" }}
      onChange={handleChangeProperty}
      placeholder={t("general:select_property")}
      showSearch
      filterOption
      optionFilterProp="children"
    >
      {propertiesOptions?.map((property) => (
        <Option value={property.id} key={property.id}>
          {property.title}
        </Option>
      ))}
    </Select>
  );
};
