import React from "react";
import { useTranslation } from "react-i18next";

import styles from "../onboarding.module.css";

export default function OnboardingCreateRatePlansStep() {
  const { t } = useTranslation();

  return (
    <div>
      <p className={styles.createPlanStep_text}>
        {t("properties_page:booking_com_onboarding:connect_channel_manager")}
      </p>
    </div>
  );
}
