import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import ChannexDrawerRoutable from "drawers/channex_drawer_routable";

import withRouter from "routing/with_router";

import AssignGroupForm from "./assign_group_form";

class AssignGroupDrawer extends Component {
  render() {
    const { t, params } = this.props;
    const { propertyId } = params;
    const title = t(
      propertyId ? "groups_page:edit_dialog_header" : "groups_page:create_dialog_header",
    );

    return (
      <ChannexDrawerRoutable title={title}>
        {({ handleCloseFromContent }) => (
          <AssignGroupForm id={propertyId} onClose={handleCloseFromContent} />
        )}
      </ChannexDrawerRoutable>
    );
  }
}

export default withRouter(withTranslation()(AssignGroupDrawer));
