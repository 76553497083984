import dayjs from "dayjs";

const ARCHIVE_DAYS = 66;
const GUEST_REPLY_DAYS = 14;
const CANCELLATION_REPLY_DAYS = 7;
const BOOKING_REPLY_DAYS = 7;

const today = () => dayjs();

const calculateDaysDifference = (date1, date2) => dayjs(date1).diff(dayjs(date2), "day");

const getDaysSinceLastGuestMessage = (messages) => {
  const guestLastMessage = messages.find((message) => message.attributes.sender === "guest");

  return guestLastMessage
    ? calculateDaysDifference(today(), guestLastMessage.attributes.insertedAt)
    : null;
};

const getCancellationDate = (booking) => {
  const cancellationRevision = Object.values(booking.booking_revisions).find((revision) => revision.status === "cancelled");

  return cancellationRevision
    ? dayjs(cancellationRevision.inserted_at)
    : null;
};

const daysSinceDeparture = (departureDate) => calculateDaysDifference(today(), departureDate);

// From airbnb: After reservation got archived (66 days after checkout), partners cannot see the reservation in both Extranet and Pulse and hence are not able to see any communication.
const isArchivedBooking = (booking) => daysSinceDeparture(booking.departure_date) > ARCHIVE_DAYS;

const canReplyToCancelledBooking = (booking) => {
  const cancellationDate = getCancellationDate(booking);

  if (!cancellationDate) {
    return false;
  }

  const daysSinceCancellation = calculateDaysDifference(today(), cancellationDate);

  return daysSinceCancellation <= CANCELLATION_REPLY_DAYS;
};

const canReplyToBooking = (booking) => daysSinceDeparture(booking.departure_date) <= BOOKING_REPLY_DAYS;

const canReplyToGuestMessage = (messages) => {
  const daysSinceGuestLastMessage = getDaysSinceLastGuestMessage(messages);

  return daysSinceGuestLastMessage !== null && daysSinceGuestLastMessage <= GUEST_REPLY_DAYS;
};

export const calcCanReplyToBooking = (booking, messages) => {
  if (!booking || !messages) {
    return false;
  }

  if (isArchivedBooking(booking)) {
    return false;
  }

  if (canReplyToGuestMessage(messages)) {
    return true;
  }

  if (booking.status === "cancelled") {
    return canReplyToCancelledBooking(booking);
  }

  return canReplyToBooking(booking);
};
