import React from "react";

// Crud table needs parent height to be defined, because crud table uses AutoSizer which calculates height of table based on parent height.
// If parent height is not defined, height of crud table is calculated as 0, which is cached in table state as calculated page size
// and it is not recalculated when parent height becomes defined. So on initial crud table render, we need to have parent height.
export default function Container({ children }) {
  return (
    <div style={{ height: "100%" }}>
      {children}
    </div>
  );
}
