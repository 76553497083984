import React from "react";

import RateTitle from "components/rates/title/title";

import alphabetSort from "utils/alphabet_sort";

const getRooms = (roomTypes, propertyId) => {
  return roomTypes.filter((el) => el.property_id === propertyId).sort(alphabetSort("title"));
};

const getRates = (ratePlans, propertyId, roomId) => {
  const roomRatePlans = ratePlans.filter(
    (el) => !el.channel_id && el.property_id === propertyId && el.room_type_id === roomId,
  );

  return roomRatePlans
    .map((roomPrimaryRatePlan) => ({
      ...roomPrimaryRatePlan,
      label: <RateTitle rate={roomPrimaryRatePlan} />,
    }))
    .sort((a, b) => {
      if (a.title === b.title) {
        return a.occupancy - b.occupancy;
      }

      return a.title > b.title ? 1 : -1;
    });
};

const buildRatesTree = (channelProperties, properties, roomTypes, ratePlans) => {
  const ratesTree = channelProperties
    .map((propertyId) => {
      const rooms = getRooms(roomTypes, propertyId);
      const channelProperty = properties[propertyId];

      return {
        ...channelProperty,
        label: channelProperty.title,
        children: rooms.map((room) => {
          const rates = getRates(ratePlans, propertyId, room.id);

          return {
            ...room,
            label: room.title,
            children: rates,
          };
        }),
      };
    })
    .sort(alphabetSort("title"));

  return ratesTree;
};

export default buildRatesTree;
