import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { UserOutlined } from "@ant-design/icons";
import { Form } from "antd";

import SubmitButton from "components/forms/buttons/submit_button";
import EmailInput from "components/forms/inputs/email";
import FormLegalCheckbox from "components/forms/inputs/form_legal_checkbox";
import NameInput from "components/forms/inputs/name";
import PasswordInput from "components/forms/inputs/password";

function SignUpFormComponent({ handleSubmit, isSubmitting, values }) {
  const { t } = useTranslation();

  const icon = <UserOutlined />;
  return (
    <Form onFinish={handleSubmit}>
      <EmailInput />
      <NameInput />
      <PasswordInput />
      <PasswordInput
        name="password_confirmation"
        className="form-input__password_confirmation"
        placeholder={t("public_pages:password_confirmation_field_placeholder")}
      />
      <FormLegalCheckbox />
      <SubmitButton loading={isSubmitting} icon={icon} disabled={!values.policy_agreement}>
        {t("sign_up_page:submit_button")}
      </SubmitButton>
    </Form>
  );
}

SignUpFormComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  values: PropTypes.object,
};

export default SignUpFormComponent;
