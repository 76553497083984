import React from "react";
import { Tabs } from "antd";

import Properties from "./properties/properties";
import UserEditForm from "./user_edit_form";

import styles from "../../../../../drawers/channex_drawer.module.css";

const DEFAULT_TAB = "general";

export default function ManageUser({ userId, closeDrawer }) {
  const items = [{
    label: <span data-cy="general_tab">General</span>,
    key: "general",
    children: (
      <UserEditForm userId={userId} onFinish={closeDrawer} />
    ),
  }, {
    className: styles.noActionsInDrawer,
    label: <span data-cy="properties_tab">Properties</span>,
    key: "properties",
    children: (
      <Properties userId={userId} />
    ),
  }];

  return (
    <Tabs
      items={items}
      destroyInactiveTabPane
      defaultActiveKey={DEFAULT_TAB}
    />
  );
}
