import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import Input from "components/forms/inputs/form_input";

export default function ShowCardMFAConfirmationContent({ value, error, onChange, onEnter }) {
  const { t } = useTranslation();
  const inputRef = useRef();

  useEffect(
    function setFocus() {
      inputRef.current?.focus();
    },
    [inputRef],
  );

  return (
    <>
      <>
        {t("applications_page:channex_pci:mfa_confirmation_text")}
      </>
      <Input
        auto
        inputRef={inputRef}
        view="vertical"
        name="property_name"
        label={t("applications_page:channex_pci:mfa_confirmation_code_label")}
        placeholder={t("applications_page:channex_pci:mfa_confirmation_code_placeholder")}
        value={value}
        errors={error}
        onChange={onChange}
        onPressEnter={onEnter}
      />
    </>
  );
}
