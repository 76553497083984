import {
  ADMIN_BILLING_ACCOUNTS_ADD,
  ADMIN_BILLING_ACCOUNTS_DROP,
  ADMIN_BILLING_ACCOUNTS_LOAD,
} from "../constants";

export function adminBillingAccountsLoad(storage) {
  return function (billing_accounts, meta) {
    storage.dispatch({ type: ADMIN_BILLING_ACCOUNTS_LOAD, payload: { billing_accounts, meta } });
  };
}

export function adminBillingAccountsAdd(storage) {
  return function (billing_account) {
    storage.dispatch({ type: ADMIN_BILLING_ACCOUNTS_ADD, payload: billing_account });
  };
}

export function adminBillingAccountsDrop(storage) {
  return function (billing_account) {
    storage.dispatch({ type: ADMIN_BILLING_ACCOUNTS_DROP, payload: billing_account });
  };
}
