import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Form } from "antd";

import SubmitButton from "components/forms/buttons/submit_button";
import FormSelect from "components/forms/inputs/form_select";

import withLogic from "containers/assign_property_form_logic";

import showErrorMessage from "utils/show_error_message";

import styles from "styles/form_in_drawer.module.css";

class AssignGroupForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func,
    t: PropTypes.func.isRequired,
    id: PropTypes.string,
    groupOptions: PropTypes.array,
  };

  state = {
    submitting: false,
    value: null,
    errors: {},
  };

  getGroupOptions() {
    const { groupOptions } = this.props;

    if (!groupOptions) {
      return [];
    }

    return groupOptions.map((el) => {
      return {
        value: el.id,
        representation: el.title,
      };
    });
  }

  onSuccess = () => {
    const { onClose } = this.props;

    onClose();
  };

  onError = (response) => {
    const { t } = this.props;

    this.setState({ submitting: false });

    if (response && response.errors && response.errors.code) {
      if (response.errors.code === "validation_error") {
        this.setState({
          errors: response.errors.details,
        });
      } else {
        showErrorMessage(t(`general:${response.errors.code}`));
      }
    } else {
      showErrorMessage(t("general:undefined_error"));
    }
  };

  handleSubmit = () => {
    const { onSubmit } = this.props;
    const { value } = this.state;

    this.setState({ submitting: true });

    const { id } = this.props;

    onSubmit(
      {
        groupId: value,
        propertyId: id,
      },
      this.onSuccess,
      this.onError,
    );
  };

  handleChange = (groupId) => {
    this.setState({ value: groupId });
  };

  render() {
    const { submitting, errors } = this.state;
    const { t, loading } = this.props;

    return (
      <Form onFinish={this.handleSubmit}>
        <FormSelect
          view="horizontal"
          loading={loading}
          placeholder={t("groups_page:form:group_placeholder")}
          label={t("groups_page:form:group_label")}
          errors={errors ? errors.property_id : null}
          onChange={this.handleChange}
          options={this.getGroupOptions()}
        />

        <div className={styles.actions}>
          <SubmitButton loading={submitting}>{t("groups_page:submit_button")}</SubmitButton>
        </div>
      </Form>
    );
  }
}

const mapStateToProps = ({ groups }) => {
  return {
    groupOptions: groups.options,
    loading: !groups.options,
  };
};

export default withTranslation()(withLogic(connect(mapStateToProps)(AssignGroupForm)));
