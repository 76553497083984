import React from "react";
import { useTranslation } from "react-i18next";

import FormWeekdays from "components/forms/inputs/form_weekdays";

export default function InputStopSell({ model, disabled, isDerived, onChange }) {
  const { t } = useTranslation();
  const isInherited = model.inherit_stop_sell === true && isDerived;

  return (
    <FormWeekdays
      name="stop_sell"
      label={t("rates_page:form:stop_sell_label")}
      value={model.stop_sell}
      disabled={isInherited || disabled}
      onChange={(value, name) => onChange(name, value)}
    />
  );
}
