import React, { Component } from "react";
import store from "store";

import EventEmitter from "utils/event_emitter";

const { GroupUsers } = store;

const hoc = (WrappedComponet) => {
  class WithUsersAccessesHOC extends Component {
    state = {
      userAccesses: [],
    };

    listReloadListener = () => {
      this.loadUserAccesses();
    };

    componentDidMount() {
      EventEmitter.bind("group_users:reload", this.listReloadListener);

      this.loadUserAccesses();
    }

    componentWillUnmount() {
      EventEmitter.unbind("group_users:reload", this.listReloadListener);
    }

    loadUserAccesses = () => {
      const { id } = this.props;

      // FIXME: If there is no id, then we are creating a new group, but we should not attempt to load it
      if (!id) {
        return;
      }

      GroupUsers.list({ group_id: id }).then((result) => {
        const userAccesses = Object.values(result).map((groupUser) => {
          return {
            id: groupUser.id,
            overrides: groupUser.overrides,
            role: groupUser.role,
            user: groupUser.user,
            group: groupUser.group,
          };
        });

        this.setState({
          userAccesses,
        });
      });
    };

    render() {
      const { userAccesses } = this.state;

      return <WrappedComponet {...this.props} userAccesses={userAccesses} />;
    }
  }

  return WithUsersAccessesHOC;
};

export default hoc;
