import {
  PROPERTIES_ADD,
  PROPERTIES_LOAD,
  PROPERTIES_OPTIONS_ADD,
  PROPERTIES_OPTIONS_LOAD,
} from "../constants";

export function propertiesLoad(storage) {
  return function (properties, meta) {
    storage.dispatch({ type: PROPERTIES_LOAD, payload: { properties, meta } });
  };
}

export function propertiesAdd(storage) {
  return function (property) {
    storage.dispatch({ type: PROPERTIES_ADD, payload: property });
  };
}

export function propertiesOptionsLoad(storage) {
  return function (options) {
    storage.dispatch({ type: PROPERTIES_OPTIONS_LOAD, payload: options });
  };
}

export function propertiesOptionsAdd(storage) {
  return function (newOption) {
    storage.dispatch({ type: PROPERTIES_OPTIONS_ADD, payload: newOption });
  };
}
