import React from "react";

import RemovedRate from "./removed_rate";

export default function RemovedRoom({ ratePlans, onMappingDelete }) {
  return (
    <div>
      {ratePlans.map((ratePlan) => {
        const { occupancy, rate_plan_code, id, channexRate } = ratePlan;
        const key = `${rate_plan_code}_${occupancy}_${id}_${channexRate?.occupancy}`;

        return <RemovedRate key={key} ratePlan={ratePlan} onMappingDelete={onMappingDelete} />;
      })}
    </div>
  );
}
