import {
  ADMIN_BILLING_ACCOUNTS_ADD,
  ADMIN_BILLING_ACCOUNTS_DROP,
  ADMIN_BILLING_ACCOUNTS_LOAD,
} from "../constants";

const initialState = {
  entities: {},
  meta: {},
};
const ACTION_HANDLERS = {
  [ADMIN_BILLING_ACCOUNTS_LOAD]: (state, action) => {
    const entities = action.payload.billing_accounts.reduce((acc, el) => {
      acc[el.id] = el.attributes;

      if (el.relationships) {
        Object.keys(el.relationships).forEach((key) => {
          acc[el.id][`${key}_id`] = el.relationships[key].data.id;
        });
      }

      return acc;
    }, {});

    return {
      entities,
      meta: action.payload.meta,
    };
  },
  [ADMIN_BILLING_ACCOUNTS_ADD]: (state, action) => {
    const item = {};

    item[action.payload.id] = action.payload.attributes;

    if (action.payload.relationships) {
      Object.keys(action.payload.relationships).forEach((key) => {
        item[action.payload.id][`${key}_id`] = action.payload.relationships[key].data.id;
      });

      item[action.payload.id].relationships = action.payload.relationships;
    }

    const entities = { ...state.entities, ...item };
    return { ...state, entities };
  },
  [ADMIN_BILLING_ACCOUNTS_DROP]: (state, action) => {
    const { entities } = state;
    delete entities[action.payload.id];
    return { ...(state || { entities }) };
  },
};

export default function adminBillingAccountsReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
