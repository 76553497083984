import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DownOutlined, LinkOutlined, MoreOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, Modal, notification, Table } from "antd";
import store from "store";

import Loading from "components/loading";
import NoDataPlaceholder from "components/no_data_placeholder";

import useDrawer from "hooks/use_drawer";

import transformMappingsToArray from "../../../mapping/airbnb_mapping/utils/transform_mappings_to_array";

import AirBnbManageRatePlanDrawer from "./manage_drawer";

import styles from "./styles.module.css";
import sharedStyles from "components/property_form/components/policy_view/policy_view.module.css";
import generalStyles from "styles/general.module.css";

const { confirm } = Modal;

const { AirbnbRatePlans: AirbnbRatePlansCollection, Channels } = store;

const DROPDOWN_TRIGGER = ["click"];

export default function RatePlansManagement({ channelId, mappings, onChannelRefresh, onMappingOptionsReload }) {
  const { t } = useTranslation();

  const [data, setData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isActionProcessing, setIsActionProcessing] = React.useState(false);

  const { isOpen: isDrawerOpen, open: openManageDrawer, close: closeManageDrawer, payload: managePayload } = useDrawer();

  const loadData = useCallback(() => {
    setIsLoading(true);

    return AirbnbRatePlansCollection.list(channelId)
      .then(setData)
      .finally(() => setIsLoading(false));
  }, [
    channelId,
  ]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const deleteAirbnbRatePlan = async (airbnbRatePlanId) => {
    await AirbnbRatePlansCollection.remove(channelId, airbnbRatePlanId);
    onMappingOptionsReload();
    loadData();
  };

  const addOrUpdateAirbnbRatePlan = (airbnbRatePlan) => {
    if (!airbnbRatePlan) {
      return;
    }

    onMappingOptionsReload();
    loadData();
  };

  // is initial loading
  if (!data.length && isLoading) {
    return <Loading />;
  }

  const items = [{
    label: t("airbnb_rate_plans:actions:disable"),
    onClick: () => {
      // when rate plans are disabled backend would erase all non listing (top) level mappings, we should warn user about it
      const mappingsAsArray = transformMappingsToArray(mappings);
      const mappingExistsOnRatePlanLevel = mappingsAsArray.some(({ mapping }) => !!mapping.airbnb_rate_plan_id);

      const disableRatePlans = () => {
        setIsActionProcessing(true);

        const errorNotificationMessage = t("airbnb_rate_plans:error_messages:unable_to_disable");

        Channels.airbnbDisableRatePlans(channelId)
          .then(() => {
            onChannelRefresh();
          })
          .catch(() => {
            setIsActionProcessing(false);
            notification.error({ message: "Error", description: errorNotificationMessage });
          })
          .finally(() => {
            onChannelRefresh();
          });
      };

      const confirmationText = mappingExistsOnRatePlanLevel
        ? t("airbnb_rate_plans:confirmation_messages:disable_with_rate_plans")
        : t("airbnb_rate_plans:confirmation_messages:disable");

      Modal.confirm({
        title: t("airbnb_rate_plans:actions:disable"),
        content: confirmationText,
        onOk: () => {
          disableRatePlans();
        },
      });
    },
  }];

  const menu = <Menu items={items} />;

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Button
          icon={<PlusCircleOutlined />}
          type="link"
          onClick={() => { openManageDrawer(); }}
        >
          {t("general:action:create")}
        </Button>
        <Dropdown disabled={isActionProcessing} overlay={menu}>
          <Button loading={isActionProcessing} type="text" icon={<MoreOutlined />} />
        </Dropdown>
      </div>

      <div className={sharedStyles.tableContainer}>
        {data.length ? (
          <Table
            loading={isLoading}
            rowClassName={sharedStyles.tableRow}
            pagination={false}
            showHeader={false}
            dataSource={data}
            columns={[{
              dataIndex: "name",
              key: "name",
              render: (value, record) => (
                <span>
                  {value}
                  {record.derivedRatePlanData && (
                    <small style={{ marginLeft: 8 }}>
                      <LinkOutlined />
                    </small>
                  )}
                </span>
              ),
            }, {
              dataIndex: "id",
              key: "id",
            }, {
              key: "action",
              align: "right",
              render: (value, record) => (
                <Dropdown
                  overlay={(
                    <Menu
                      items={[{
                        key: "airbnb_rate_plans_crud_actions_edit",
                        onClick: () => { openManageDrawer(record); },
                        label: (
                          <div>
                            {t("airbnb_rate_plans_crud:actions:edit")}
                          </div>
                        ),
                      }, {
                        key: "airbnb_rate_plans_crud_actions_remove",
                        onClick: () => {
                          confirm({
                            title: t("general:remove_dialog:title"),
                            content: t("general:remove_dialog:description"),
                            onOk: () => {
                              deleteAirbnbRatePlan(record.id);
                            },
                          });
                        },
                        label: (
                          <div>
                            {t("airbnb_rate_plans_crud:actions:remove")}
                          </div>
                        ),
                      }]}
                    />
                  )}
                  trigger={DROPDOWN_TRIGGER}
                >
                  <Button
                    type="link"
                    data-cy="crud_entry_actions_menu"
                    className={generalStyles.actionsToggle}
                  >
                    {t("general:actions")} <DownOutlined />
                  </Button>
                </Dropdown>
              ),
            }]}
            rowKey="id"
          />
        ) : (
          <NoDataPlaceholder
            type="button"
            onClick={() => { openManageDrawer(); }}
            emptyMessage={t("airbnb_rate_plans:empty_message")}
            createMessageText={t("general:create_first_before")}
            createMessageActionText={t("general:create_first_link")}
          />
        )}
      </div>

      <AirBnbManageRatePlanDrawer
        visible={isDrawerOpen}
        channelId={channelId}
        airBnbRatePlans={data}
        value={managePayload}
        onClose={(value) => {
          addOrUpdateAirbnbRatePlan(value);
          closeManageDrawer();
        }}
      />
    </div>
  );
}
