import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import classNames from "classnames";

import eventTypeOptions from "config/constants/event_type_options";

import dayjs from "utils/dayjs";
import getChannelInfoByCode from "utils/get_channel_info_by_code";

import { MainSection, RightSection } from "./common";

import styles from "./events_list.module.css";

const EVENT_UNMAPPED_ROOM = "booking_unmapped_room";

const getChannelIcon = (bookingUniqueId) => {
  if (!bookingUniqueId) {
    return null;
  }

  const channelCode = bookingUniqueId.substr(0, 3);

  const channel = getChannelInfoByCode(channelCode);

  if (channel?.icon) {
    return <img src={channel.icon} alt={channel.title} />;
  }

  return <div className={styles.emptyIcon}>{channelCode}</div>;
};

function EventsListItem({ item, isMobile }) {
  const { t } = useTranslation();
  const { event, payload } = item;
  const {
    amount,
    arrival_date,
    booking_unique_id,
    count_of_nights,
    count_of_rooms,
    currency,
    customer_name,
    inserted_at,
  } = payload;

  const eventTitle = useMemo(() => {
    const findEventOption = eventTypeOptions.find((i) => i === event);
    if (findEventOption) {
      return t(`live_feed_events_widget:events:${findEventOption}`).toUpperCase();
    }
    return null;
  }, [t, event]);

  const arrivalDateValue = useMemo(() => {
    const date = event === EVENT_UNMAPPED_ROOM ? inserted_at : arrival_date;
    return dayjs(date).format(t("formats:date_time_with_weekday"));
  }, [t, event, inserted_at, arrival_date]);

  const nightPostFix = count_of_nights > 1 ? "s" : "";
  const roomPostFix = count_of_rooms > 1 ? "s" : "";

  const channelIcon = getChannelIcon(booking_unique_id);
  const eventTitleClassName = classNames(styles.event, styles[`event--${event}`]);
  const isDescriptionPresent = Boolean(customer_name && count_of_rooms && count_of_nights);
  const isCancellation = arrival_date === null && count_of_rooms === 0;

  return (
    <>
      <MainSection>
        <p className={eventTitleClassName}>{eventTitle}</p>
        <p className={styles.arrivalDate}>
          {isCancellation ? t("live_feed_events_widget:cancellation") : arrivalDateValue}
        </p>

        <p className={styles.description}>
          {isDescriptionPresent && !isCancellation && (
            <>
              {customer_name} <span>|</span> {count_of_rooms} {t("live_feed_events_widget:room")}
              {roomPostFix} <span>x</span> {count_of_nights} {t("live_feed_events_widget:night")}
              {nightPostFix}
            </>
          )}
        </p>
      </MainSection>
      <RightSection
        isMobile={isMobile}
        channelIcon={channelIcon}
        amount={amount}
        currency={currency}
      />
    </>
  );
}

EventsListItem.propTypes = {
  item: PropTypes.shape({}),
};

export default EventsListItem;
