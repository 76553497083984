import React from "react";
import { useTranslation } from "react-i18next";
import { WarningOutlined } from "@ant-design/icons";

export default function BookingEngineLinkWarning({ visible }) {
  const { t } = useTranslation();

  if (!visible) {
    return null;
  }

  return (
    <span>
      <WarningOutlined />
      {` ${t("channels_page:form:booking_engine_link_warning")}`}
    </span>
  );
}
