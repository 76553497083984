import React from "react";
import classNames from "classnames";

import styles from "./onboarding.module.css";

const steps = [0, 1, 2, 3, 4];

export default function OnboardingSteps({ step }) {
  return (
    <div className={styles.stepsWrapper}>
      {steps.map((item) => {
        const classNameStep = classNames(styles.step, {
          [`${styles.step__prev}`]: step > item,
          [`${styles.step__active}`]: step === item,
          [`${styles.step__next}`]: step < item,
        });
        return <div key={item} className={classNameStep} />;
      })}
    </div>
  );
}
