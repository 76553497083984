let transport;
let storage;

const ENDPOINT = "admin/properties";

export default class AdminProperties {
  constructor(container) {
    transport = container.transport;
    storage = container.storage;
  }

  list(filter = {}, pagination = { page: 1, limit: 10 }, order = { title: "desc" }) {
    return transport.send("GET", ENDPOINT, { filter, pagination, order }).then((response) => {
      storage.adminPropertiesLoad(response.data, response.meta);

      return response;
    });
  }

  find(id) {
    return transport.send("GET", `${ENDPOINT}/${id}`).then((response) => {
      storage.adminPropertiesAdd(response.data);
      return response;
    });
  }

  update(attrs) {
    return transport
      .send("PUT", `${ENDPOINT}/${attrs.id}`, { property: attrs })
      .then((response) => {
        storage.adminPropertiesAdd(response.data);
        return response;
      });
  }
}
