import { camelCase } from "lodash";

const parseApiErrors = (error, options = {}) => {
  const { errors: { details = {} } = {} } = error;

  const { camelCaseFields } = options;

  return Object.keys(details).reduce((errors, fieldName) => {
    const [errorText] = details[fieldName];

    const result = {
      ...errors,
    };

    if (camelCaseFields) {
      result[camelCase(fieldName)] = errorText;
    } else {
      result[camelCase(fieldName)] = errorText;
      result[fieldName] = errorText;
    }

    return result;
  }, {});
};

export default parseApiErrors;
