import { SCORES_ADD } from "../constants";

export function scoresAdd(storage) {
  return function (score) {
    storage.dispatch({
      type: SCORES_ADD,
      payload: score,
    });
  };
}
