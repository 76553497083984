import React from "react";
import { Col, Row } from "antd";

import ScoreItem from "../../../_common/score_item";

const MAX_ITEMS_PER_ROW = 3;

export default function Mobile({ overall, scores }) {
  const cols = [...[...Array(MAX_ITEMS_PER_ROW)].map(() => [])];

  scores.forEach((score, i) => {
    const colIndex = (i % MAX_ITEMS_PER_ROW);

    cols[colIndex].push(score);
  });

  return (
    <Row justify="space-evenly">
      <Col key="overall" style={{ alignContent: "center", display: "flex", flexWrap: "wrap" }}>
        <ScoreItem type="overall" score={overall} />
      </Col>
      {cols.map((scoreCol, i) => (
        <Col key={i}>
          {scoreCol.map((score) => (
            <ScoreItem key={score.category} type={score.category} score={score.score} />
          ))}
        </Col>
      ))}
    </Row>
  );
}
