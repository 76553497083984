import i18n from "i18next";
import _ from "lodash";

import showErrorMessage from "./show_error_message";

export default (error, customHandlers) => {
  if (!error.isValidationError) {
    throw error;
  }

  const { errors } = error;

  const errorMessages = {
    property: {
      "has channel(s)": i18n.t("properties_page:errors:has_channel"),
    },
    channel: {
      "is active": i18n.t("channels_page:remove_dialog:errors:is_active_channel"),
      "has active channels": i18n.t("groups_page:remove_dialog:errors:is_active_channel"),
    },
    id: {
      "should have at least one group": i18n.t(
        "groups_page:remove_dialog:errors:should_have_at_least_one_group",
      ),
    },
    default: i18n.t("general:undefined_error"),
  };

  const errorDetails = errors?.details || {};
  const [errorField = "default"] = Object.keys(errorDetails);
  const [errorType = null] = errorDetails[errorField] || [];
  const errorText = _.get(errorMessages, `${errorField}.${errorType}`, errorMessages.default);

  console.log(customHandlers);
  if (customHandlers && customHandlers[errorField] && customHandlers[errorField][errorType]) {
    customHandlers[errorField][errorType](error);
  } else {
    showErrorMessage(errorText);
  }
};
