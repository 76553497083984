import React from "react";
import { Sector } from "recharts";

import styles from "./sources_chart.module.css";

const OUTER_RADIUS_OFFSET = 5;

function ActiveShape(props) {
  const { outerRadius } = props;
  const newOuterRadius = outerRadius + OUTER_RADIUS_OFFSET;
  return <Sector {...props} outerRadius={newOuterRadius} className={styles.sector} />;
}

export default ActiveShape;
