import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import ChannexDrawerRoutable from "drawers/channex_drawer_routable";

import IssueView from "components/issue_view";

import withRouter from "routing/with_router";

class IssueDrawerRoutable extends Component {
  render() {
    const { t, params } = this.props;
    const { issueId } = params;

    return (
      <ChannexDrawerRoutable title={t("issues_drawer:header")}>
        {({ handleCloseFromContent }) => (
          <IssueView issueId={issueId} onClose={handleCloseFromContent} />
        )}
      </ChannexDrawerRoutable>
    );
  }
}

export default withTranslation()(withRouter(IssueDrawerRoutable));
