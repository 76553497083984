import React from "react";
import { useTranslation } from "react-i18next";
import { PaperClipOutlined, SendOutlined } from "@ant-design/icons";
import { Button, Upload } from "antd";

import uploadAttachment from "../upload_attachment";

import styles from "./styles.module.css";

export default function ActionsBar({ canSend, onSend, onFileAttachmentStatusChange }) {
  const { t } = useTranslation();

  return (
    <div className={styles.actionsBar}>
      <Upload
        accept="image/*"
        multiple
        showUploadList={false}
        customRequest={({ file }) => uploadAttachment(file, onFileAttachmentStatusChange)}
      >
        <Button
          className={styles.attachButton}
          type="text"
          icon={<PaperClipOutlined />}
        >
          {t("messages:actions:attach")}
        </Button>
      </Upload>
      <span className={styles.sendHintMessage}>
        {t("messages:text:send_hint")}
      </span>
      <Button
        disabled={!canSend}
        className={styles.sendButton}
        type="text"
        icon={<SendOutlined />}
        onClick={onSend}
      >
        {t("messages:actions:send")}
      </Button>
    </div>
  );
}
