import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { Empty } from "antd";
import store from "store";
import { sortBookingStatsByDesc } from "store/storage/selectors/booking_stats_selector";
import { getActiveGroup, getActivePropertyId } from "store/storage/selectors/session_selector";

import ErrorMessage from "components/error_boundary/error_message";

import useBoolState from "utils/use_bool_state";

import WidgetLoader from "../../common/widget_loader";

import SourcesChart from "./sources_chart";

import styles from "./styles.module.css";

const { Bookings } = store;

export const Content = ({ dateFrom, dateTo, isDefaultDateRange }) => {
  const bookingStats = useSelector(sortBookingStatsByDesc);
  const [loading, setTrueLoading, setFalseLoading] = useBoolState(false);
  const [isError, setTrueError, setFalseError] = useBoolState(false);
  const activePropertyId = useSelector(getActivePropertyId);
  const activeGroup = useSelector(getActiveGroup);

  const loadBookingSources = useCallback(() => {
    setTrueLoading();
    let meta = {};

    if (activePropertyId) {
      meta = { property_id: activePropertyId, ...meta };
    }

    if (activeGroup) {
      meta = { group_id: activeGroup, ...meta };
    }

    if (dateFrom) {
      meta = { from: dateFrom.startOf("day").utc().format(), ...meta };
    }

    if (dateTo) {
      meta = { to: dateTo.endOf("day").utc().format(), ...meta };
    }

    Bookings.stats(meta)
      .then(() => {
        setFalseLoading();
        setFalseError();
      })
      .catch(() => {
        setFalseLoading();
        setTrueError();
      });
  }, [
    setFalseLoading,
    setTrueLoading,
    dateTo,
    dateFrom,
    activePropertyId,
    activeGroup,
    setFalseError,
    setTrueError,
  ]);

  useEffect(loadBookingSources, [loadBookingSources]);

  const handleReload = useCallback(() => {
    return loadBookingSources();
  }, [loadBookingSources]);

  if (loading && !bookingStats) {
    return (
      <div className={styles.center}>
        <WidgetLoader />
      </div>
    );
  }

  if (isError) {
    return (
      <div className={styles.center}>
        <ErrorMessage onReload={handleReload} />
      </div>
    );
  }

  if (bookingStats?.length === 0) {
    return (
      <div className={styles.center}>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </div>
    );
  }

  return (
    <SourcesChart
      data={bookingStats}
      dateFrom={dateFrom}
      dateTo={dateTo}
      isDefaultDateRange={isDefaultDateRange}
    />
  );
};
