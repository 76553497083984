import React from "react";
import { Checkbox, Col, Row } from "antd";

import styles from "./room_rate_selector.module.css";

function RoomRateSelector(props) {
  const {
    roomType,
    selected,
    disabled,
    indeterminate,
    onRoomTypeToggle,
  } = props;

  return (
    <Row className={styles.roomTypeRow}>
      <Col>
        <Checkbox
          onChange={onRoomTypeToggle(roomType.id)}
          checked={selected}
          indeterminate={indeterminate}
          disabled={disabled}
        >
          {roomType.title}
        </Checkbox>
      </Col>
    </Row>
  );
}

export default React.memo(RoomRateSelector);
