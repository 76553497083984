import React from "react";
import PropTypes from "prop-types";

import FormInput from "components/forms/inputs/form_input";

function handleChangeCallback(callback, field) {
  return (value) => {
    callback(field, value);
  };
}

function InputChildrenFee(props) {
  const { t, model, disabled, onChange } = props;
  const onHandleChange = handleChangeCallback(onChange, "children_fee");

  return (
    <FormInput
      name="children_fee"
      view="horizontal"
      type="text"
      placeholder={t("rates_page:form:children_fee_placeholder")}
      label={t("rates_page:form:children_fee_label")}
      defaultValue={model.children_fee}
      addonAfter={model.currency}
      onChange={onHandleChange}
      disabled={Boolean(model.rate_category_id) || disabled}
    />
  );
}

InputChildrenFee.propTypes = {
  t: PropTypes.func,
  model: PropTypes.object,
  onChange: PropTypes.func,
};

export default InputChildrenFee;
