import {
  CANCELLATION_POLICIES_ADD,
  CANCELLATION_POLICIES_DROP,
  CANCELLATION_POLICIES_LOAD,
} from "../constants";

export function cancellationPoliciesLoad(storage) {
  return function (records, meta) {
    storage.dispatch({ type: CANCELLATION_POLICIES_LOAD, payload: { records, meta } });
  };
}

export function cancellationPoliciesAdd(storage) {
  return function (cancellationPolicy) {
    storage.dispatch({ type: CANCELLATION_POLICIES_ADD, payload: cancellationPolicy });
  };
}

export function cancellationPoliciesDrop(storage) {
  return function (cancellationPolicy) {
    storage.dispatch({ type: CANCELLATION_POLICIES_DROP, payload: cancellationPolicy });
  };
}
