import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";

import RateTitle from "components/rates/title";

import styles from "./extra_values_mapping_editor/styles.module.css";

export const MappingValue = ({ mapping, ratePlansById, isInvalid, onClick }) => {
  const { t } = useTranslation();

  const ratePlanId = mapping?.ratePlanId;
  const ratePlan = ratePlansById[ratePlanId];

  if (!ratePlan) {
    return (
      <Button type="link" onClick={onClick} data-cy="mapping_select_button" danger>
        {t("channels_page:form:not_mapped")}
      </Button>
    );
  }

  return (
    <Button type="link" className={styles.button} onClick={onClick} data-cy="mapping_select_button" disabled={isInvalid}>
      <RateTitle rate={ratePlan} />
    </Button>
  );
};
