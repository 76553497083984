import React from "react";

import channelNames from "config/constants/channels/channel_codes";

import ChannelLogo from "./channel_logo";

import styles from "./card.module.css";

export default function Card({ score, property }) {
  const { ota, overallScore } = score;

  const { title, color } = channelNames[ota] || channelNames.Unknown;

  return (
    <div className={styles.scoreCard} style={{ background: color }}>
      <div className={styles.header} style={{ backgroundColor: "rgba(0, 0, 0, 0.14)" }}>
        <ChannelLogo channel={ota} />
        <div className={styles.channelTitle}>
          {title}
        </div>
      </div>
      <div className={styles.body}>
        <div className={styles.propertyName}>{property.title}</div>
        <div className={styles.score}>{overallScore}</div>
        <div className={styles.maxScore}>out of 10</div>
      </div>
    </div>
  );
}
