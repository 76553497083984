import React from "react";
import PropTypes from "prop-types";
import { Checkbox, Form } from "antd";

import { horizontalFormItemLayout } from "config/constants/layouts/form";

import InputParentRatePlan from "../items/input_parent_rate_plan";

import styles from "styles/form_in_drawer.module.css";

const INHERIT_RULES = [
  "rate",
  "min_stay_arrival",
  "min_stay_through",
  "max_stay",
  "closed_to_arrival",
  "closed_to_departure",
  "stop_sell",
];

function handleChangeCallback(callback, field) {
  return (event) => {
    callback(field, event.target.checked);
  };
}

function inheritRuleTemplate(t, model, disabled, onChange) {
  return function (rule) {
    const onHandleChange = handleChangeCallback(onChange, `inherit_${rule}`);

    return (
      <Checkbox
        key={rule}
        checked={model[`inherit_${rule}`]}
        onChange={onHandleChange}
        disabled={Boolean(model.rate_category_id) || disabled}
      >
        {t(`rates_page:form:inherit_${rule}`)}
      </Checkbox>
    );
  };
}

function GroupDerivedRate(props) {
  const { t, model, disabled, onChange } = props;
  const renderInheritRule = inheritRuleTemplate(t, model, disabled, onChange);
  let result = null;

  if (model.is_derived) {
    result = (
      <>
        <legend>{t("rates_page:form:derived_options")}</legend>

        <InputParentRatePlan {...props} />

        {model.parent_rate_plan_id && (
          <Form.Item
            {...horizontalFormItemLayout}
            className={styles.checkbox__column}
            label={t("rates_page:form:inherit_label")}
          >
            {INHERIT_RULES.map(renderInheritRule)}
          </Form.Item>
        )}
      </>
    );
  }

  return result;
}

GroupDerivedRate.propTypes = {
  t: PropTypes.func,
  model: PropTypes.object,
  errors: PropTypes.object,
  onChange: PropTypes.func,
  ratePlans: PropTypes.object,
  roomTypes: PropTypes.array,
};

export default GroupDerivedRate;
