import React from "react";
import classNames from "classnames";

import styles from "./content.module.css";

export function Content({ children, scrollable = true, paddingTop = true, paddingBottom = true }) {
  const className = classNames(styles.content, {
    [styles.scrollable]: scrollable,
    [styles.paddingTop]: paddingTop,
    [styles.paddingBottom]: paddingBottom,
  });

  return (
    <div className={className}>
      {children}
    </div>
  );
}
