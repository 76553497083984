import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import store from "store";
import * as yup from "yup";

import ChannexModal from "components/channex_modal";
import SubmitButton from "components/forms/buttons/submit_button";
import FormTableSelect from "components/forms/inputs/formik/form_table_select";

import useBoolState from "utils/use_bool_state";

const MODAL_WIDTH = 620;

const { Properties, UserApiKeys } = store;

export default function ManageApiKeyPropertiesModal({ apiKeyID, visible, onSave, onClose }) {
  const [apiKey, setApiKey] = useState(null);
  const [propertiesList, setProperties] = useState([]);
  const [loading, setLoading, setLoaded] = useBoolState(false);
  const { t } = useTranslation();

  useEffect(function loadProperties() {
    Properties.options({}).then((proeprties) => {
      setProperties(
        proeprties.map((el) => {
          return { key: el.id, title: el.title };
        }),
      );
    });
  }, []);

  useEffect(
    function loadAPIKey() {
      if (apiKeyID) {
        UserApiKeys.find(apiKeyID).then((result) => {
          setApiKey(result);
        });
      }
    },
    [apiKeyID],
  );

  const handleSave = useCallback(
    (args, form) => {
      setLoading();
      const newValue = {
        userApiKey: args,
      };

      onSave(newValue)
        .then(
          (_success) => {},
          (error) => {
            form.setErrors(error.errors.details);
          },
        )
        .finally(setLoaded);
    },
    [onSave, setLoading, setLoaded],
  );

  const handleClose = useCallback(() => {
    setApiKey(null);
    onClose();
  }, [setApiKey, onClose]);

  const schema = useMemo(() => {
    return yup.object({
      // properties: yup.array().min(1).required(),
    });
  }, []);

  const onPropertiesChange = (value, field, form) => {
    value = Array.isArray(value) ? value : [value];

    form.setFieldValue(field.name, value);
  };

  return (
    <ChannexModal
      visible={visible}
      title={t("manage_api_key_properties:modal_title")}
      footer={null}
      onCancel={handleClose}
      width={MODAL_WIDTH}
    >
      {apiKey && propertiesList ? (
        <Formik initialValues={apiKey} validationSchema={schema} onSubmit={handleSave}>
          {({ errors, touched, _values }) => (
            <Form>
              <>
                <>
                  <FormTableSelect
                    title={t("user_api_keys:form:affected_properties_title")}
                    label={t("user_api_keys:form:affected_properties_placeholder")}
                    name="properties"
                    items={propertiesList}
                    onChange={onPropertiesChange}
                  />
                  {errors.properties && touched.properties && (
                    <div className="ant-form-item-explain ant-form-item-explain-error ant-col-xs-24 ant-col-sm-16 ant-col-sm-offset-8">
                      {errors.properties}
                    </div>
                  )}
                </>

                <br />

                <SubmitButton loading={loading}>{t("general:action:save")}</SubmitButton>
              </>
            </Form>
          )}
        </Formik>
      ) : (
        "Loading..."
      )}
    </ChannexModal>
  );
}
