import { isPlainObject } from "lodash";

export default function cleanObject(obj) {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([_key, value]) => {
        return value !== undefined;
      })
      .map(([key, value]) => {
        return [key, isPlainObject(value) ? cleanObject(value) : value];
      }),
  );
}
