import React from "react";
import classnames from "classnames";

import styles from "./styles.module.css";

export default function Legend({ children, marginBottom = true }) {
  const className = classnames(styles.legend, {
    [styles.woBottomMargin]: !marginBottom,
  });

  return (
    <legend className={className}>{children}</legend>
  );
}
