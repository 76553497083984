import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "antd";

import logout from "utils/logout";

import useLatestAgreementContent from "./use_latest_agreement_content";
import useLatestAgreementVersion from "./use_latest_agreement_version";
import useSignAgreementAction from "./use_sign_agreement_action";
import useSignedAgreementVersion from "./use_signed_agreement_version";

export default function SignTermsAndConditionsAgreement() {
  const { t } = useTranslation();
  const signedAgreementVersion = useSignedAgreementVersion();
  const latestAgreementVersion = useLatestAgreementVersion();
  const [triedToSign, setTriedToSign] = useState(false);

  const shouldSignNewAgreement = (latestAgreementVersion !== signedAgreementVersion) && !triedToSign;

  const { data: latestAgreementContent } = useLatestAgreementContent({ enabled: shouldSignNewAgreement });

  const { mutate: sign, isLoading: isSignProcessing } = useSignAgreementAction();

  const handleOk = () => {
    sign({ version: latestAgreementVersion });
    setTriedToSign(true);
  };

  const handleCancel = () => {
    logout();
  };

  return (
    <Modal
      open={shouldSignNewAgreement}
      confirmLoading={isSignProcessing}
      keyboard={false}
      width={800}
      zIndex={10000}
      maskClosable={false}
      footer={(
        <Button type="primary" loading={isSignProcessing} onClick={handleOk}>{t("general:action:accept")}</Button>
      )}
      onCancel={handleCancel}
    >
      <div>
        <h1>{t("terms_and_conditions:title")}</h1>
        <div style={{ overflow: "auto", height: "calc(100vh - 340px)" }} dangerouslySetInnerHTML={{ __html: latestAgreementContent }} />
      </div>
    </Modal>
  );
}
