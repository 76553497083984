import React, { Component } from "react";
import store from "store";

import withRouter from "routing/with_router";

const { Properties } = store;

const withLogic = (FormComponent) => {
  class HOC extends Component {
    onSubmit = (values, success, error) => {
      const method = values.id ? Properties.update : Properties.create;

      return method(values).then(
        (response) => {
          if (success && typeof success === "function") {
            success(response);
          }
        },
        (response) => {
          if (error && typeof error === "function") {
            error(response);
          }
        },
      );
    };

    render() {
      return <FormComponent {...this.props} onSubmit={this.onSubmit} />;
    }
  }

  return withRouter(HOC);
};

export default withLogic;
