import React from "react";
import { useTranslation } from "react-i18next";

import InputClosedToArrival from "components/forms/items/input_closed_to_arrival";
import InputClosedToDeparture from "components/forms/items/input_closed_to_departure";
import InputMaxStay from "components/forms/items/input_max_stay";
import InputMinStayArrival from "components/forms/items/input_min_stay_arrival";
import InputMinStayThrough from "components/forms/items/input_min_stay_through";
import InputStopSell from "components/forms/items/input_stop_sell";

export default function GroupRestrictions({ model, errors, disabled, onChange }) {
  const { t } = useTranslation();
  const isDerived = model.is_derived;

  const inputProps = {
    model,
    errors,
    disabled,
    isDerived,
    onChange,
  };

  return (
    <>
      <legend>{t("rates_page:form:restrictions_legend")}</legend>
      <InputMinStayArrival {...inputProps} />
      <InputMinStayThrough {...inputProps} />
      <InputMaxStay {...inputProps} />
      <InputClosedToArrival {...inputProps} />
      <InputClosedToDeparture {...inputProps} />
      <InputStopSell {...inputProps} />
    </>
  );
}
