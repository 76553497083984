import { useMutation, useQueryClient } from "react-query";
import store from "store";

const { AdminWlDomains } = store;

export function useCreateAction(wlPartnerId) {
  const queryClient = useQueryClient();

  return useMutation(({ attrs }) => AdminWlDomains.create(wlPartnerId, attrs), {
    onSuccess: () => {
      queryClient.invalidateQueries("admin_wl_domains");
    },
  });
}
