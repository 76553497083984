import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Radio } from "antd";
import { getGroupsOptions } from "store/storage/selectors/groups_selector";
import { getPropertiesOptions } from "store/storage/selectors/properties_selector";
import { getActiveGroup, getUser } from "store/storage/selectors/session_selector";

import FormSelect from "components/forms/inputs/form_select";

import { useWebSocketContext } from "../../../../web_socket_context";
import { StepTitle } from "../../step_title";

import { ApaleoRoomRatesSelector } from "./apaleo_room_rates_selector";
import { useApaleoProperties } from "./use_apaleo_properties";
import { useApaleoRoomRates } from "./use_apaleo_room_rates";
import { useConnectPropertyAction } from "./use_connect_property_action";

export const ConnectPropertyStep = forwardRef((props, ref) => {
  const { t } = useTranslation();

  const { data, setData, go, enableNextButton, appName } = props;

  const groupsOptions = useSelector(getGroupsOptions);
  const propertyOptions = useSelector(getPropertiesOptions);
  const activeGroupId = useSelector(getActiveGroup);
  const user = useSelector(getUser);

  const ws = useWebSocketContext();

  const [importInProgress, setImportInProgress] = useState(false);
  const [apaleoProperty, setApaleoProperty] = useState(null);
  const [action, setAction] = useState("connectExistingProperty");
  const [channexGroup, setChannexGroup] = useState(activeGroupId || null);
  const [channexProperty, setChannexProperty] = useState(null);
  const [chosenRooms, setChosenRooms] = useState([]);
  const [formErrors, setFormErrors] = useState({});

  const apaleoPropertiesQuery = useApaleoProperties({ appName });
  const apaleoPropertyRoomsQuery = useApaleoRoomRates({ apaleoProperty, appName, enabled: action === "createProperty" });
  const connectMutation = useConnectPropertyAction();

  useEffect(() => {
    if (!apaleoProperty) {
      return enableNextButton(false);
    }

    if (action === "connectExistingProperty" && !channexProperty) {
      return enableNextButton(false);
    }

    if (action === "createProperty" && !channexGroup) {
      return enableNextButton(false);
    }

    if (action === "createProperty" && channexGroup && chosenRooms.length === 0) {
      return enableNextButton(false);
    }

    enableNextButton(true);
  }, [action, apaleoProperty, channexGroup, channexProperty, chosenRooms, enableNextButton]);

  const handleApaleoPropertyChange = useCallback((property) => {
    setApaleoProperty(property);
  }, [setApaleoProperty]);

  const handleActionChange = (e) => {
    setAction(e.target.value);
    setFormErrors((errors) => ({
      ...errors,
      channexProperty: null,
    }));
  };

  const cleanup = () => {
    setFormErrors({});
    setImportInProgress(false);
    setApaleoProperty(null);
    setAction("connectExistingProperty");
    setChannexGroup(activeGroupId || null);
    setChannexProperty(null);
    setChosenRooms([]);

    ws.leave();
  };

  useImperativeHandle(ref, () => ({
    proceed: () => {
      return new Promise(async (resolve, reject) => {
        setImportInProgress(true);

        const channelName = `apaleo_connect:${user.id}`;

        try {
          await ws.join({
            channel: channelName,
            events: ["property:connected", "property:failed"],
          });

          const result = await connectMutation.mutateAsync({ action, apaleoProperty, channexProperty, channexGroup, chosenRooms, appName });

          ws.subscribe(result.token, (eventName, payload) => {
            if (eventName === "property:failed" && payload.message === "application_exists") {
              setFormErrors({
                channexProperty: t("apaleo_import:messages:application_exists"),
              });
              setImportInProgress(false);
              setTimeout(() => {
                ws.leave();
                reject();
              }, 0);

              return;
            }

            if (eventName === "property:failed") {
              setData({
                error: "property_failed_connect",
              });

              setTimeout(() => {
                cleanup();
                resolve("result");
              }, 0);

              return;
            }

            setData({
              ...data,
              action,
              applicationInstallationId: payload.application_installation_id,
              propertyId: payload.property_id,
            });

            setTimeout(() => {
              cleanup();
              resolve();
            }, 0);
          });
        } catch (e) {
          setImportInProgress(false);
          ws.leave();
          reject(e);
        }
      });
    },
  }));

  useEffect(() => {
    return () => {
      ws.leave();
    };
  }, [ws]);

  const apaleoPropertyOptionGroups = (apaleoPropertiesQuery?.data?.data || []).reduce((acc, { attributes }) => {
    if (attributes.connected) {
      acc.connectedGroup.push({
        value: attributes.hotelCode,
        label: attributes.title,
        disabled: true,
      });
    } else {
      acc.availableGroup.push({
        value: attributes.hotelCode,
        label: attributes.title,
      });
    }

    return acc;
  }, {
    availableGroup: [],
    connectedGroup: [],
  });

  const apaleoPropertyOptions = [];

  if (apaleoPropertyOptionGroups.availableGroup.length) {
    apaleoPropertyOptions.push({
      label: t("apaleo_import:labels:ready_to_connect"),
      options: apaleoPropertyOptionGroups.availableGroup,
    });
  }
  if (apaleoPropertyOptionGroups.connectedGroup.length) {
    apaleoPropertyOptions.push({
      label: t("apaleo_import:labels:already_connected"),
      options: apaleoPropertyOptionGroups.connectedGroup,
    });
  }

  const isActionEnabled = apaleoProperty !== null;

  useEffect(() => {
    if (apaleoPropertiesQuery.isLoading === false && apaleoPropertyOptionGroups.availableGroup.length === 0 && apaleoPropertyOptionGroups.connectedGroup.length !== 0) {
      setData({
        ...data,
        error: "all_properties_connected",
      });
      setTimeout(() => { go("result"); }, 0);
    }

    if (apaleoPropertiesQuery.isLoading === false && apaleoPropertiesQuery.data.data.length === 0) {
      setData({
        ...data,
        error: "no_properties",
      });
      setTimeout(() => { go("result"); }, 0);
    }
  }, [apaleoPropertiesQuery.isLoading, apaleoPropertyOptionGroups.availableGroup, apaleoPropertyOptionGroups.connectedGroup, apaleoPropertiesQuery.data, data, go, setData]);

  return (
    <div>
      <StepTitle>{t("apaleo_import:titles:connect_property")}</StepTitle>
      <div style={{ paddingBottom: 20 }}>
        <FormSelect
          name="apaleoProperty"
          errors={formErrors.apaleoProperty}
          label={t("apaleo_import:labels:apaleo_property")}
          disabled={importInProgress}
          nativeOptions
          options={apaleoPropertyOptions}
          loading={apaleoPropertiesQuery.isLoading}
          onChange={handleApaleoPropertyChange}
        />
      </div>
      <Radio.Group
        disabled={!isActionEnabled || importInProgress}
        onChange={handleActionChange}
        value={action}
        style={{ width: "100%" }}
      >
        <div>
          <Radio
            data-cy="radio_connectExistingProperty"
            value="connectExistingProperty"
          >
            {t("apaleo_import:labels:connect_to_existing_property")}
          </Radio>
          <FormSelect
            disabled={action === "createProperty" || !isActionEnabled || importInProgress}
            name="channexProperty"
            label={t("apaleo_import:labels:channex_property")}
            valueKey="id"
            labelKey="title"
            errors={formErrors.channexProperty}
            options={propertyOptions}
            loading={apaleoPropertiesQuery.isLoading}
            onChange={(value) => {
              setChannexProperty(value);
              setFormErrors((errors) => ({
                ...errors,
                channexProperty: null,
              }));
            }}
          />
        </div>
        <div>
          <Radio
            data-cy="radio_createProperty"
            value="createProperty"
          >
            {t("apaleo_import:labels:create_new_property")}
          </Radio>
          <FormSelect
            disabled={action === "connectExistingProperty" || !isActionEnabled || importInProgress}
            name="channexGroup"
            label={t("apaleo_import:labels:channex_group")}
            defaultValue={channexGroup}
            valueKey="id"
            labelKey="title"
            errors={formErrors.channexGroup}
            options={groupsOptions}
            loading={apaleoPropertiesQuery.isLoading}
            onChange={setChannexGroup}
          />
          {apaleoPropertyRoomsQuery.isFetched && (
            <ApaleoRoomRatesSelector
              rooms={apaleoPropertyRoomsQuery.data.data.rooms}
              onChange={setChosenRooms}
            />
          )}
        </div>
      </Radio.Group>
    </div>
  );
});
