import React from "react";
import { useTranslation } from "react-i18next";

import { DateRangeValue } from "../../../../value_presentation/range";
import { CellTooltip } from "../../components/cell_tooltip/cell_tooltip";

import styles from "./styles.module.css";

export function RulesTooltip({ showText = true, overrides, color }) {
  const { t } = useTranslation();

  if (!overrides || Object.keys(overrides).length === 0) {
    return null;
  }

  const rules = Object.values(overrides)
    .flatMap((o) => o)
    .map((override) => {
      return (
        <div
          key={`${override.type}-${override.startDate}-${override.endDate}`}
          className={styles.ruleRow}
        >
          {override.title}: <DateRangeValue from={override.startDate} to={override.endDate} />
        </div>
      );
    });

  return (
    <CellTooltip
      title={showText && (
        <div>
          <div className={styles.tooltipTitle}>{t("availability_rules:titles:availability_rules")}</div>
          {rules}
        </div>
      )}
      color={color}
    />
  );
}
