import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import styles from "./raw_link.module.css";

class RawLink extends Component {
  render() {
    const { t, onClick } = this.props;

    return (
      <div className={styles.raw_link}>
        <a href="#raw_message" onClick={onClick}>
          {t("bookings_page:booking_view_dialog:raw_message:link")}
        </a>
      </div>
    );
  }
}

export default withTranslation()(RawLink);
