import { ADMIN_PROPERTIES_ADD, ADMIN_PROPERTIES_DROP, ADMIN_PROPERTIES_LOAD } from "../constants";

export function adminPropertiesLoad(storage) {
  return function (properties, meta) {
    storage.dispatch({ type: ADMIN_PROPERTIES_LOAD, payload: { properties, meta } });
  };
}

export function adminPropertiesAdd(storage) {
  return function (property) {
    storage.dispatch({ type: ADMIN_PROPERTIES_ADD, payload: property });
  };
}

export function adminPropertiesDrop(storage) {
  return function (property) {
    storage.dispatch({ type: ADMIN_PROPERTIES_DROP, payload: property });
  };
}
