import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Formik } from "formik";

import ChannexPopover from "components/channex_popover";
import FormInput from "components/forms/inputs/formik/form_input";
import FormSelect from "components/forms/inputs/formik/form_select";

import styles from "./photo_card_meta_form.module.css";

const PHOTO_KINDS = {
  ad: "Ad",
  menu: "Menu",
  photo: "Photo",
};

const MAX_DESCRIPTION_LENGTH = 255;

class PhotoCardMetaForm extends Component {
  formRef = React.createRef();

  handleSave = () => {
    this.formRef.current.submitForm();
  };

  onSubmit = (values) => {
    const { onChange, onClose } = this.props;

    onChange(values);
    onClose();
  };

  getSelectOptions = () => {
    return Object.entries(PHOTO_KINDS).map(([value, representation]) => ({
      value,
      representation,
    }));
  };

  validate = (values) => {
    const { t } = this.props;
    const { description } = values;
    const errors = {};

    if (!description) {
      errors.description = [t("validation_messages:required")]; // TODO - remove array when we are ready for it
    } else if (description.length > MAX_DESCRIPTION_LENGTH) {
      errors.description = [t("validation_messages:max_length", { max: MAX_DESCRIPTION_LENGTH })];
    }

    return errors;
  };

  render() {
    const { t, photo, onClose } = this.props;
    const { description, author, kind } = photo;

    const initialValues = {
      kind,
      description,
      author,
    };

    return (
      <Formik
        initialValues={initialValues}
        innerRef={this.formRef}
        validate={this.validate}
        onSubmit={this.onSubmit}
        render={(form) => (
          <ChannexPopover.Content
            title={t("photo_card:meta:title")}
            disabled={!form.isValid}
            onSave={this.handleSave}
            onCancel={onClose}
          >
            <FormInput
              className={styles.input}
              name="description"
              view="horizontal"
              placeholder={t("photo_card:meta:description_placeholder")}
              label={t("photo_card:meta:description_label")}
            />
            <FormSelect
              className={styles.input}
              name="kind"
              view="horizontal"
              placeholder={t("photo_card:meta:type_placeholder")}
              label={t("photo_card:meta:type_label")}
              options={this.getSelectOptions()}
            />
            <FormInput
              className={styles.input}
              name="author"
              view="horizontal"
              placeholder={t("photo_card:meta:author_placeholder")}
              label={t("photo_card:meta:author_label")}
            />
          </ChannexPopover.Content>
        )}
      />
    );
  }
}

export default withTranslation("common", { wait: false })(PhotoCardMetaForm);
