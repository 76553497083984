import _ from "lodash";

import cleanObject from "utils/clean_object";

export const fromApi = (value) => {
  const transformedValue = _.cloneDeep(value || {});

  if (!transformedValue?.derivedRatePlanData?.parentRatePlanId) {
    transformedValue.mode = "manual";
    return transformedValue;
  }

  const apiAmountType = transformedValue.derivedRatePlanData.amountType;
  delete transformedValue.derivedRatePlanData.amountType;

  const apiAmount = transformedValue.derivedRatePlanData.amount;
  const formAmount = Math.abs(transformedValue.derivedRatePlanData.amount);

  let formModifierType;
  if (apiAmountType === "FLAT" && apiAmount < 0) {
    formModifierType = "decreaseByAmount";
  } else if (apiAmountType === "FLAT" && apiAmount >= 0) {
    formModifierType = "increaseByAmount";
  } else if (apiAmountType === "PERCENT" && apiAmount < 0) {
    formModifierType = "decreaseByPercentage";
  } else if (apiAmountType === "PERCENT" && apiAmount >= 0) {
    formModifierType = "increaseByPercentage";
  }

  transformedValue.derivedRatePlanData = {
    ...transformedValue.derivedRatePlanData,
    amount: formAmount,
    modifierType: formModifierType,
  };
  transformedValue.mode = "derived";

  return transformedValue;
};

export const toApi = (value) => {
  const transformedValue = _.cloneDeep(value);

  // ui only field
  const { mode } = transformedValue;
  delete transformedValue.mode;

  if (mode === "manual") {
    delete transformedValue.derivedRatePlanData;

    return transformedValue;
  }

  const types = {
    increaseByAmount: "FLAT",
    decreaseByAmount: "FLAT",
    increaseByPercentage: "PERCENT",
    decreaseByPercentage: "PERCENT",
  };
  const formModifierType = transformedValue.derivedRatePlanData.modifierType;
  delete transformedValue.derivedRatePlanData.modifierType;
  const apiAmountType = types[formModifierType];

  transformedValue.derivedRatePlanData.amountType = apiAmountType;

  if (formModifierType === "decreaseByAmount" || formModifierType === "decreaseByPercentage") {
    transformedValue.derivedRatePlanData.amount = -transformedValue.derivedRatePlanData.amount;
  }

  return cleanObject(transformedValue);
};
