import i18n from "i18next";

import showErrorMessage from "./show_error_message";

export default (response) => {
  if (response && response.errors && response.errors.code) {
    if (response.errors.code === "validation_error") {
      const {
        errors: { details },
      } = response;
      const keys = Object.keys(details);

      keys.map((item) => {
        if (details[item].length > 0) {
          return response.errors.details[item].map((i) => showErrorMessage(i));
        }
        return null;
      });
    }

    if (response.errors.code === "internal_server_error") {
      showErrorMessage(response.errors.title);
    }
  } else {
    showErrorMessage(i18n.t("general:undefined_error"));
  }
};
