import React from "react";

import { usePrintPdf } from "./use_print_pdf";

export function withPrintPdf(Component) {
  function WithPrintPdf(props) {
    const { printPdf, isLoading } = usePrintPdf();

    const printPdfPayload = {
      actions: {
        printPdf,
      },
      state: {
        isLoading,
      },
    };

    return <Component {...props} printPdf={printPdfPayload} />;
  }

  return WithPrintPdf;
}
