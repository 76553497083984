import React from "react";

import { WhiteLabelPartnerForm } from "../../white_label_partner_form/white_label_partner_form";

import { useCreateAction } from "./use_create_action";

export function CreateWhiteLabelPartnerForm({ onFinish }) {
  const { mutateAsync, isLoading } = useCreateAction();

  const handleSubmit = async (values) => {
    const result = await mutateAsync({ attrs: values });
    onFinish(result);
  };

  return (
    <WhiteLabelPartnerForm
      isLoading={isLoading}
      onSubmit={handleSubmit}
    />
  );
}
