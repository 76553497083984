import React from "react";
import Avatar from "react-avatar";

import channelNames from "config/constants/channels/channel_codes";

import styles from "./channel_logo.module.css";

export default function ChannelLogo({ channel }) {
  const { icon, title, color } = channelNames[channel] || channelNames.Unknown;

  return (
    icon
      ? <img className={styles.channelLogo} src={icon} alt={title} />
      : <Avatar round name={title} color={color} size={32} textSizeRatio={2} />
  );
}
