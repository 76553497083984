import React from "react";

import types from "../types";

const MAX_SCORE = 10;
const RADIUS = 24;
const CIRCUMFERENCE = 2 * Math.PI * RADIUS;
const COLORS = {
  low: "#ff4d4f",
  med: "#fbad15",
  high: "#52c41a",
};

export default function Arc({ type, score }) {
  const config = types[type] || types.unknown;

  const progressPercentage = score / MAX_SCORE;
  const strokeDashoffset = CIRCUMFERENCE * (1 - progressPercentage);

  let color;

  if (score <= 5) {
    color = COLORS.low;
  } else if (score <= 7) {
    color = COLORS.med;
  } else {
    color = COLORS.high;
  }

  return (
    <div style={{ position: "relative" }}>
      <svg style={{ overflow: "visible" }} width={2 * RADIUS} height={2 * RADIUS}>
        <circle
          cx={RADIUS}
          cy={RADIUS}
          r={RADIUS}
          stroke="#eee"
          strokeWidth={3}
          fill="transparent"
        />
        <circle
          cx={RADIUS}
          cy={RADIUS}
          r={RADIUS}
          stroke={color}
          strokeWidth={3}
          strokeDasharray={CIRCUMFERENCE}
          strokeDashoffset={strokeDashoffset}
          transform={`rotate(-90 ${RADIUS} ${RADIUS})`}
          fill="transparent"
        />
      </svg>
      <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
        {config.icon}
      </div>
    </div>
  );
}
