import { withTranslation } from "react-i18next";
import { withFormik } from "formik";
import { compose } from "recompose";
import store from "store";
import * as yup from "yup";

import errors from "config/constants/errors";

import showErrorMessage from "utils/show_error_message";

import FormComponent from "./mfa_challenge_form_component";

const { Auth } = store;

const getSchema = () => yup.object({
  code: yup.string().required(errors.required()),
});

const withFormContainer = withFormik({
  validateOnBlur: false,
  validateOnChange: false,
  validationSchema: getSchema,
  mapPropsToValues: () => ({
    code: "",
  }),
  handleSubmit: (values, { props, setSubmitting }) => {
    const { t, mfaToken } = props;

    Auth.mfaChallenge({ token: mfaToken, ...values }).catch((error) => {
      if (!error.errors || !error.errors.code) {
        setSubmitting(false);
        showErrorMessage(t("general:undefined_error"));
        return;
      }

      setSubmitting(false);
      showErrorMessage(t("general:errors:invalid_user_credentials"));
    });
  },
});

const enhance = compose(withTranslation(), withFormContainer);

export default enhance(FormComponent);
