import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button } from "antd";
import store from "store";

import BookingViewControlledDrawer from "drawers/booking_view_controlled_drawer";

import CRUDTable from "components/crud_table";
import * as Page from "components/page";
import PageHeader from "components/page_header";

import { formatToLocal } from "utils/dates";

import ErrorImage from "static/too-many-properties.svg";

const { PCICards } = store;
const DEFAULT_Z_INDEX_DRAWER = 1001;

class ChannexPCIPage extends Component {
  static propTypes = {
    data: PropTypes.array,
    t: PropTypes.func.isRequired,
  };

  state = {
    bookingId: null,
  };

  loadData = (_query, pagination, order) => {
    const { propertyId } = this.props;

    return PCICards.logs({ property_id: propertyId }, pagination, order);
  };

  setBookingId = (bookingId) => {
    return () => {
      this.setState({ bookingId });
    };
  };

  resetBookingId = () => {
    this.setState({ bookingId: null });
  };

  columns = () => {
    const { t } = this.props;

    const dataColumns = [
      {
        title: t("applications_page:channex_pci:columns:time"),
        dataIndex: "inserted_at",
        key: "inserted_at",
        sorter: true,
        render: (timestamp) => formatToLocal(timestamp, t("formats:iso_date_with_time")),
      },
      {
        title: t("applications_page:channex_pci:columns:operation"),
        dataIndex: "operation",
        key: "operation",
        sorter: true,
        render: (value) => t(`applications_page:channex_pci:values:operations:${value}`),
      },
      {
        title: t("applications_page:channex_pci:columns:username"),
        dataIndex: "username",
        key: "username",
        render: (_, data) => {
          return `${data.username} (${data.email})`;
        },
      },
      {
        title: t("applications_page:channex_pci:columns:ip_address"),
        dataIndex: "ip_address",
        key: "ip_address",
      },
      {
        title: t("applications_page:channex_pci:columns:location"),
        dataIndex: "location",
        key: "location",
      },
      {
        title: t("applications_page:channex_pci:columns:booking_reference"),
        dataIndex: "booking_reference",
        key: "booking_reference",
        render: (_, data) => {
          return <Button type="link" onClick={this.setBookingId(data.booking_id)}>{data.booking_reference}</Button>;
        },
      },
      {
        title: t("applications_page:channex_pci:columns:card_last_4"),
        dataIndex: "card_last_4",
        key: "card_last_4",
        render: (value) => {
          return `...${value}`;
        },
      },
    ];

    return dataColumns;
  };

  emptyMessage() {
    const { t } = this.props;
    return t("applications_page:channex_pci:empty_message");
  }

  render() {
    const { data, t, pciApp } = this.props;

    if (!pciApp) {
      return (
        <Page.Main>
          <Page.ErrorMessage text={t("applications_page:channex_pci:application_is_not_available")} icon={ErrorImage} />
        </Page.Main>
      );
    }

    return (
      <Page.Main>
        <Page.Content>
          <PageHeader title={t("applications_page:channex_pci:header")} />
          <CRUDTable.Container>
            <CRUDTable
              data={data}
              columns={this.columns}
              emptyMessage={this.emptyMessage()}
              showCreateMessage={false}
              searchField="operation"
              defaultOrder={{ inserted_at: "desc" }}
              loadData={this.loadData}
            />
          </CRUDTable.Container>
        </Page.Content>

        <BookingViewControlledDrawer
          bookingId={this.state.bookingId}
          visible={Boolean(this.state.bookingId)}
          onClose={this.resetBookingId}
          zIndex={DEFAULT_Z_INDEX_DRAWER}
        />
      </Page.Main>
    );
  }
}

const mapStateToProps = ({ pciLogs, session: { activeProperty }, applications: { installed: installedApps } }) => {
  const { entities } = pciLogs;
  const pciApp = Object.values(installedApps).find((app) => app.applicationCode === "channex_pci" && app.propertyId === activeProperty);

  return {
    data: entities ? Object.values(entities) : null,
    propertyId: activeProperty,
    pciApp,
  };
};

export default withTranslation()(connect(mapStateToProps)(ChannexPCIPage));
