export default [{
  label: "Flexible",
  value: "flexible",
}, {
  label: "Moderate",
  value: "moderate",
}, {
  label: "Better Strict with Grace Period",
  value: "better_strict_with_grace_period",
}, {
  label: "Strict 14 with Grace Period",
  value: "strict_14_with_grace_period",
}, {
  label: "Super Strict 30",
  value: "super_strict_30",
}, {
  label: "Super Strict 60",
  value: "super_strict_60",
}, {
  label: "Luxury Moderate",
  value: "luxury_moderate",
}, {
  label: "Luxury Super Strict 95",
  value: "luxury_super_strict_95",
}, {
  label: "Luxury Super Strict 125",
  value: "luxury_super_strict_125",
}, {
  label: "Luxury No Refund",
  value: "luxury_no_refund",
}, {
  label: "Luxury Moderate 14",
  value: "luxury_moderate_14",
}];
