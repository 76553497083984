import {
  adminBillingAccountsAdd,
  adminBillingAccountsDrop,
  adminBillingAccountsLoad,
} from "./admin_billing_account_actions";
import {
  adminPropertiesAdd,
  adminPropertiesDrop,
  adminPropertiesLoad,
} from "./admin_property_actions";
import { adminUsersAdd, adminUsersDrop, adminUsersLoad } from "./admin_user_actions";
import {
  applicationsAddInstalled,
  applicationsLoadInstalled,
  applicationsRemoveInstalled,
} from "./applications";
import { bookingsAdd, bookingsLoad } from "./booking_actions";
import { bookingStatsLoad } from "./booking_stats_actions";
import {
  cancellationPoliciesAdd,
  cancellationPoliciesDrop,
  cancellationPoliciesLoad,
} from "./cancellation_policies_actions";
import {
  channelsActivate,
  channelsAdd,
  channelsDeactivate,
  channelsDrop,
  channelsLoad,
} from "./channel_actions";
import { channelEventsLoad } from "./channel_events_actions";
import { channelsHealthLoad } from "./channel_health_actions";
import {
  channelMessagesAdd,
  channelMessagesAddThread,
  channelMessagesCloseThread,
  channelMessagesLoad,
  channelMessagesOpenThread,
  channelMessagesReset,
  channelMessagesResetThreads,
  channelMessagesThreadsLoad,
  channelMessagesThreadsSetRequestId,
  channelMessagesUpdateThreadByLatestMessage,
} from "./channel_messages_threads_actions";
import { userAdd, userUpdate } from "./current_user_actions";
import {
  emailTemplatesAdd,
  emailTemplatesDrop,
  emailTemplatesLoad,
} from "./email_template_actions";
import { groupsAdd, groupsDrop, groupsLoad, groupsOptionsLoad } from "./group_actions";
import { hotelPoliciesAdd, hotelPoliciesDrop, hotelPoliciesLoad } from "./hotel_policies_actions";
import {
  liveFeedEventAdd,
  liveFeedEventsLoad,
  liveFeedEventsReset,
  liveFeedSetRequestId,
} from "./live_feed_events_actions";
import { logout } from "./logout";
import { pciLogsLoad } from "./pci_logs_actions";
import {
  propertiesAdd,
  propertiesLoad,
  propertiesOptionsAdd,
  propertiesOptionsLoad,
} from "./property_actions";
import { propertiesHealthLoad } from "./property_health_actions";
import { rateCategoriesAdd, rateCategoriesDrop, rateCategoriesLoad } from "./rate_category_actions";
import { ratePlansAdd, ratePlansDrop, ratePlansLoad } from "./rate_plan_actions";
import { reviewsAdd, reviewsLoad, reviewsReset, reviewsSetRequestId } from "./review_actions";
import { roomTypesAdd, roomTypesDrop, roomTypesLoad } from "./room_type_actions";
import { scoresAdd } from "./score_actions";
import {
  chooseGroup,
  chooseProperty,
  sessionAdd,
  setAppMode,
  setAppSettings,
  setUiSettings,
} from "./session_actions";
import { tasksLoad } from "./tasks_actions";
import { taxSetsAdd, taxSetsDrop, taxSetsLoad } from "./tax_sets_actions";
import { taxesAdd, taxesDrop, taxesLoad } from "./taxes_actions";
import { userApiKeySet, userApiKeysLoad } from "./user_api_keys_actions";
import { webhookLogsLoad, webhooksAdd, webhooksDrop, webhooksLoad } from "./webhooks_actions";
import {
  whiteLabelDomainsAdd,
  whiteLabelDomainsDrop,
  whiteLabelDomainsLoad,
} from "./white_label_domain_actions";
import {
  whiteLabelEmailSettingsAdd,
  whiteLabelEmailSettingsDrop,
  whiteLabelEmailSettingsLoad,
} from "./white_label_email_settings_actions";
import {
  whiteLabelPartnersAdd,
  whiteLabelPartnersDrop,
  whiteLabelPartnersLoad,
} from "./white_label_partner_actions";

export default {
  sessionAdd,
  chooseProperty,
  chooseGroup,
  setAppMode,
  setAppSettings,
  setUiSettings,

  propertiesLoad,
  propertiesHealthLoad,
  propertiesAdd,
  propertiesOptionsLoad,
  propertiesOptionsAdd,

  roomTypesLoad,
  roomTypesAdd,
  roomTypesDrop,

  ratePlansLoad,
  ratePlansAdd,
  ratePlansDrop,

  rateCategoriesLoad,
  rateCategoriesAdd,
  rateCategoriesDrop,

  channelsLoad,
  channelsAdd,
  channelsDrop,
  channelsHealthLoad,
  channelEventsLoad,
  channelsActivate,
  channelsDeactivate,

  emailTemplatesLoad,
  emailTemplatesAdd,
  emailTemplatesDrop,

  userAdd,
  userUpdate,

  adminUsersLoad,
  adminUsersAdd,
  adminUsersDrop,

  adminBillingAccountsAdd,
  adminBillingAccountsDrop,
  adminBillingAccountsLoad,

  adminPropertiesAdd,
  adminPropertiesDrop,
  adminPropertiesLoad,

  whiteLabelPartnersLoad,
  whiteLabelPartnersAdd,
  whiteLabelPartnersDrop,

  whiteLabelDomainsLoad,
  whiteLabelDomainsAdd,
  whiteLabelDomainsDrop,

  whiteLabelEmailSettingsLoad,
  whiteLabelEmailSettingsAdd,
  whiteLabelEmailSettingsDrop,

  tasksLoad,

  taxesAdd,
  taxesDrop,
  taxesLoad,

  taxSetsAdd,
  taxSetsDrop,
  taxSetsLoad,

  groupsLoad,
  groupsAdd,
  groupsDrop,
  groupsOptionsLoad,

  bookingsLoad,
  bookingsAdd,
  bookingStatsLoad,

  hotelPoliciesLoad,
  hotelPoliciesAdd,
  hotelPoliciesDrop,

  cancellationPoliciesLoad,
  cancellationPoliciesAdd,
  cancellationPoliciesDrop,

  liveFeedEventsLoad,
  liveFeedEventAdd,
  liveFeedEventsReset,
  liveFeedSetRequestId,

  channelMessagesThreadsLoad,
  channelMessagesUpdateThreadByLatestMessage,
  channelMessagesLoad,
  channelMessagesReset,
  channelMessagesAdd,
  channelMessagesCloseThread,
  channelMessagesOpenThread,
  channelMessagesResetThreads,
  channelMessagesThreadsSetRequestId,
  channelMessagesAddThread,

  applicationsLoadInstalled,
  applicationsAddInstalled,
  applicationsRemoveInstalled,

  userApiKeysLoad,
  userApiKeySet,

  webhooksAdd,
  webhooksDrop,
  webhooksLoad,
  webhookLogsLoad,

  reviewsLoad,
  reviewsAdd,
  reviewsSetRequestId,
  reviewsReset,

  scoresAdd,

  pciLogsLoad,

  logout,
};
