import React, { forwardRef, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { StepTitle } from "../step_title";

export const ResultStep = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { data, showButtons, setStartAgainButtonTitle } = props;

  const isError = !!data.error;

  useEffect(() => {
    if (data.error === "all_properties_connected" || data.error === "no_properties") {
      setTimeout(() => {
        showButtons(false);
      }, 0);
    }

    if (data.error === "oauth_failed") {
      setStartAgainButtonTitle(t("apaleo_import:buttons:try_againas"));
    }
  }, [data.error, showButtons, setStartAgainButtonTitle, t]);

  if (data.error === "all_properties_connected") {
    return (
      <div>
        <StepTitle>{t("apaleo_import:titles:connect_property")}</StepTitle>
        {t("apaleo_import:messages:all_connected")}
      </div>
    );
  }

  if (data.error === "no_properties") {
    return (
      <div>
        <StepTitle>{t("apaleo_import:titles:connect_property")}</StepTitle>
        {t("apaleo_import:messages:no_properties")}
      </div>
    );
  }

  if (data.error === "oauth_failed") {
    return (
      <div>
        <StepTitle>{t("apaleo_import:titles:oauth_failed")}</StepTitle>
        {t("apaleo_import:messages:oauth_failed")}
      </div>
    );
  }

  return (
    <div ref={ref}>
      {isError && (
        <>
          <StepTitle>{t("apaleo_import:titles:property_failed_connect")}</StepTitle>
          <p>
            {t("apaleo_import:messages:property_failed_connect")}
          </p>
        </>
      )}
      {!isError && (
        <>
          <StepTitle>{t("apaleo_import:titles:property_successfully_connected")}</StepTitle>
          <p>
            {t("apaleo_import:messages:property_successfully_connected")}
          </p>
        </>
      )}
    </div>
  );
});
