import React, { createContext, useMemo } from "react";

const ErrorBoundaryContext = createContext();

function ErrorBoundaryContextProvider({ children, handleError }) {
  const value = useMemo(() => ({ handleError }), [handleError]);

  return (
    <ErrorBoundaryContext.Provider value={value}>
      {children}
    </ErrorBoundaryContext.Provider>
  );
}

export { ErrorBoundaryContextProvider, ErrorBoundaryContext };
