export default function transformMappingsToObject(mappingsAsArray) {
  const mappingsByRatePlanId = {};

  mappingsAsArray.forEach((item) => {
    mappingsByRatePlanId[item.ratePlanId] = mappingsByRatePlanId[item.ratePlanId] || [];

    mappingsByRatePlanId[item.ratePlanId].push({
      id: item.mappingId,
      ...item.mapping,
    });
  });

  return mappingsByRatePlanId;
}
