import React from "react";

import { useProperty } from "./use_property";

export default function withProperty(Component) {
  function WithProperty(props) {
    const { property, error, isLoading } = useProperty(props.propertyId);

    return <Component {...props} property={property} propertyError={error} isPropertyLoading={isLoading} />;
  }

  return WithProperty;
}
