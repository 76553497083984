import React from "react";
import * as yup from "yup";

import errorMessages from "config/constants/errors";

import HookForm from "components/hook_form";
import Loading from "components/loading/loading";

import useUser from "../../../use_user";
import useUserUpdateAction from "../../../use_user_update_action";

export default function UserEditForm({ userId, onFinish }) {
  const { data: user, isLoading: isUserLoading } = useUser(userId);
  const { mutateAsync: updateUser, isLoading: isUpdating } = useUserUpdateAction();

  const validationSchema = yup.object().shape({
    name: yup.string().trim().required(errorMessages.required()),
    email: yup.string().trim().required(errorMessages.required()),
  });

  if (isUserLoading) {
    return <Loading />;
  }

  return (
    <HookForm
      value={user?.data?.attributes}
      validationSchema={validationSchema}
      fields={
        [{
          field: "name",
          type: "text",
          label: "Name",
        }, {
          field: "email",
          type: "text",
          label: "Email",
        }]
      }
      submit={({ name, email }) => updateUser({
        id: userId,
        name,
        email,
      })}
      isSubmitting={isUpdating}
      onFinish={onFinish}
    />
  );
}
