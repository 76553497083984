import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { UserOutlined } from "@ant-design/icons";
import { Form } from "antd";

import SubmitButton from "components/forms/buttons/submit_button";
import MFACodeInput from "components/forms/inputs/mfa_code";

function SignInFormComponent({ handleSubmit, isSubmitting }) {
  const { t } = useTranslation();

  return (
    <Form onFinish={handleSubmit}>
      <center>
        {t("sign_in_page:confirmation_help")}
      </center>
      <MFACodeInput />

      <SubmitButton loading={isSubmitting}>
        <UserOutlined />
        {t("sign_in_page:submit_button")}
      </SubmitButton>
    </Form>
  );
}

SignInFormComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
};

export default SignInFormComponent;
