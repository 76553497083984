import React from "react";
import _ from "lodash";

import { WhiteLabelEmailSettingsForm } from "../../wl_email_settings_form/wl_email_settings_form";

import { useUpdateAction } from "./use_update_action";

export function EditWhiteLabelEmailSettingsForm({ values, closeDrawer }) {
  const { mutateAsync, isLoading } = useUpdateAction();

  const handleSubmit = async (formValues) => {
    const permittedAttrs = _.omit(formValues, ["id", "settings"]);

    const result = await mutateAsync({ id: formValues.id, attrs: permittedAttrs });
    closeDrawer(result);
  };

  return (
    <WhiteLabelEmailSettingsForm
      values={values}
      isLoading={isLoading}
      onSubmit={handleSubmit}
    />
  );
}
