import React, { useState } from "react";
import { useSelector } from "react-redux";
import store from "store";
import { getUserUiSettings } from "store/storage/selectors/session_selector";

import UserUiSettingsView from "./user_ui_settings_view";

const { Profiles } = store;

export default function UserUiSettings() {
  const defaultValue = useSelector(getUserUiSettings);
  const [value, setValue] = useState({});

  const handleSubmit = (formValue) => {
    return Profiles.updateUiSettings(formValue);
  };

  return (
    <UserUiSettingsView
      value={value}
      defaultValue={defaultValue}
      onChange={setValue}
      onSubmit={handleSubmit}
    />
  );
}
