import React from "react";
import { withTranslation } from "react-i18next";

import FormInput from "components/forms/inputs/formik/form_input";

function NameInput(props) {
  return (
    <FormInput
      view="horizontal"
      type="text"
      name="title"
      placeholder={props.t("groups_page:form:title_placeholder")}
      label={props.t("groups_page:form:title_label")}
      {...props}
    />
  );
}

export default withTranslation()(NameInput);
