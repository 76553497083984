import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, Input, Modal, Select } from "antd";

import useBoolState from "utils/use_bool_state";

const { Option } = Select;

export default function ReservationRequestActionButtons({ onResolve }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading, setLoadingComplete] = useBoolState(false);
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const handleAccept = () => {
    setLoading();

    onResolve({ accept: true }).finally(setLoadingComplete);
  };

  const handleDecline = (values) => {
    setLoading();

    onResolve({ accept: false, ...values }).finally(setLoadingComplete);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    form.validateFields().then((values) => {
      setIsModalOpen(false);
      handleDecline(values);
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button onClick={handleAccept} disabled={loading} loading={loading} type="primary">
        {t("general:action:accept")}
      </Button>
      <Button onClick={showModal} disabled={loading} type="primary" danger>
        {t("general:action:decline")}
      </Button>
      <Modal title={t("decline_airbnb_reservation:modal:title")} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          form={form}
          autoComplete="off"
        >
          <Form.Item name="reason" label={t("decline_airbnb_reservation:modal:reason")} rules={[{ required: true }]}>
            <Select>
              <Option value="dates_not_available">{t("decline_airbnb_reservation:modal:reasons:dates_not_available")}</Option>
              <Option value="not_a_good_fit">{t("decline_airbnb_reservation:modal:reasons:not_a_good_fit")}</Option>
              <Option value="waiting_for_better_reservation">{t("decline_airbnb_reservation:modal:reasons:waiting_for_better_reservation")}</Option>
              <Option value="not_comfortable">{t("decline_airbnb_reservation:modal:reasons:not_comfortable")}</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={t("decline_airbnb_reservation:modal:decline_message_to_guest")}
            name="decline_message_to_guest"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t("decline_airbnb_reservation:modal:decline_message_to_airbnb")}
            name="decline_message_to_airbnb"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
