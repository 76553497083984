export default (policy, isLoading, t) => {
  let title = t("properties_page:policies:assign_hotel_policy");

  if (policy) {
    title = policy.title;
  }

  if (isLoading) {
    title = t("general:loading");
  }
  return title;
};
