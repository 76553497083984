import { createSelector } from "reselect";

export const getChannelMessagesThreads = (state) => state.channelMessagesThreads.threads.list;
export const getChannelMessagesThreadsMeta = (state) => state.channelMessagesThreads.threads.meta;
export const getChannelMessages = (threadId) => (state) => state.channelMessagesThreads.messages[threadId]?.list;
export const getChannelMessagesMeta = (threadId) => (state) => state.channelMessagesThreads.messages[threadId]?.meta;

export const getChannelMessagesThreadById = (threadId) => createSelector([getChannelMessagesThreads], (threads) => {
  return threads?.find(({ id }) => threadId === id);
});
