import React, { Component } from "react";
import { Button, Col, Row, Steps } from "antd";
import invariant from "invariant";

import styles from "components/wizard/wizard.module.css";

class Wizard extends Component {
  state = {
    currentStepIndex: 0,
    wizardValue: {},
    wizardErrors: {},
  };

  stepRefs = [];

  getIsWizardDirty = () => {
    return this.stepRefs.map(({ form }) => form.current.formik.current).some(({ dirty }) => dirty);
  };

  componentDidMount() {
    const { children, initialStep, initialValues = {} } = this.props;

    if (!initialStep) {
      return;
    }

    const index = React.Children.toArray(children).findIndex(
      (step) => step.props.name === initialStep,
    );
    invariant(index !== -1, `cannot find step with name ${initialStep}`);

    this.setState(({ currentStepIndex }) => {
      if (index !== -1 && index !== 0) {
        currentStepIndex = index;
      }

      return {
        currentStepIndex,
        wizardValue: initialValues,
      };
    });
  }

  componentDidUpdate({ errors: oldErrors }) {
    const { errors = {} } = this.props;

    if (oldErrors !== errors) {
      this.processWizardErrors(errors);
    }
  }

  processWizardErrors = (errors) => {
    const { currentStepIndex } = this.state;

    const wizardErrors = {};
    const globalErrors = { ...errors };
    let stepWithError = null;

    this.stepRefs.forEach((stepRef, index) => {
      const stepFields = stepRef.getFields ? stepRef.getFields() : [];
      const containsError = stepFields.findIndex((field) => globalErrors[field]);

      if (containsError !== -1) {
        stepWithError = stepWithError || index;

        stepFields.forEach((field) => {
          const { [field]: error } = globalErrors;

          if (error) {
            delete globalErrors[field];
            wizardErrors[field] = error;
          }
        });
      }
    });

    stepWithError = stepWithError !== null ? stepWithError : currentStepIndex;

    return this.setState({ currentStepIndex: stepWithError, wizardErrors });
  };

  injectStepProps = (stepComponent, index) => {
    const { wizardValue, wizardErrors } = this.state;
    const { contentProps = {} } = this.props;

    return React.cloneElement(stepComponent, {
      errors: wizardErrors,
      contentProps,
      value: wizardValue,
      ref: (el) => {
        this.stepRefs[index] = el;
      },
      onChange: this.handleChange,
    });
  };

  prepareWizardSteps = () => {
    const { children } = this.props;

    return React.Children.toArray(children).map((child, index) => ({
      title: child.props.title,
      description: child.props.description,
      component: this.injectStepProps(child, index),
    }));
  };

  handleChange = (values) => {
    const { onChange = () => {} } = this.props;

    this.setState(
      ({ wizardValue }) => ({
        wizardValue: {
          ...wizardValue,
          ...values,
        },
      }),
      () => {
        const { wizardValue } = this.state;
        onChange(wizardValue);
      },
    );
  };

  handlePrevClick = () => {
    this.setState(({ currentStepIndex }) => ({
      currentStepIndex: currentStepIndex - 1,
    }));
  };

  handleNextClick = () => {
    const { currentStepIndex } = this.state;
    const { beforeLeave } = this.stepRefs[currentStepIndex];
    const beforeNext = beforeLeave ? beforeLeave() : Promise.resolve();

    beforeNext.then(this.incrementCurrentStep).catch((wizardErrors) => {
      this.setState({ wizardErrors });
    });
  };

  incrementCurrentStep = () => this.setState(({ currentStepIndex }) => ({ currentStepIndex: currentStepIndex + 1 }));

  handleFinishClick = () => {
    const { onFinish } = this.props;
    const { wizardValue } = this.state;

    onFinish(wizardValue);
  };

  renderNavigation = (wizardSteps) => {
    return wizardSteps.map((step) => (
      <Steps.Step key={step.title} title={step.title} description={step.description} />
    ));
  };

  renderWizardSteps = (wizardSteps) => {
    const { currentStepIndex } = this.state;

    return wizardSteps.map(({ component }, index) => {
      if (index === currentStepIndex) {
        return (
          <div className={styles.activeStep} key={index}>
            {component}
          </div>
        );
      }

      return (
        <div className={styles.hiddenStep} key={index}>
          {component}
        </div>
      );
    });
  };

  render() {
    const { currentStepIndex } = this.state;
    const { actionsClassName, loading } = this.props;

    const wizardSteps = this.prepareWizardSteps();

    const isPreviousVisible = currentStepIndex !== 0 && wizardSteps.length > 1;
    const isNextVisible = currentStepIndex < wizardSteps.length - 1;
    const isFinishVisible = currentStepIndex === wizardSteps.length - 1;

    return (
      <>
        <Row>
          <Col xs={6}>
            <Steps current={currentStepIndex} direction="vertical" size="small">
              {this.renderNavigation(wizardSteps)}
            </Steps>
          </Col>
          <Col xs={18}>
            <div className="wizard__step">{this.renderWizardSteps(wizardSteps)}</div>
          </Col>
        </Row>
        <Row className={actionsClassName} type="flex" justify="end">
          <Col xs={24}>
            <div className={styles.buttonContainer}>
              {isPreviousVisible && (
                <Button
                  size="large"
                  className={styles.buttonContainer__button}
                  onClick={this.handlePrevClick}
                  data-cy="previous_button"
                >
                  Previous
                </Button>
              )}
              {isNextVisible && (
                <Button
                  size="large"
                  className={styles.buttonContainer__button}
                  type="primary"
                  onClick={this.handleNextClick}
                  data-cy="next_button"
                >
                  Next
                </Button>
              )}
              {isFinishVisible && (
                <Button
                  size="large"
                  className={styles.buttonContainer__button}
                  type="primary"
                  loading={loading}
                  disabled={loading}
                  onClick={this.handleFinishClick}
                  data-cy="finish_button"
                >
                  Finish
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

export default Wizard;
