import React from "react";
import { Controller } from "react-hook-form";
import { Col, Row } from "antd";

import FormTextareaRaw from "components/forms/inputs/form_textarea";

export function FormTextarea({ name, label, control, errors, grouped, ...inputProps }) {
  if (grouped) {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <FormTextareaRaw
            hookForm
            label={label}
            errors={errors}
            grouped={grouped}
            {...inputProps}
            {...field}
          />
        )}
      />
    );
  }

  return (
    <Row>
      <Col span={24}>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <FormTextareaRaw
              hookForm
              label={label}
              errors={errors}
              grouped={grouped}
              {...inputProps}
              {...field}
            />
          )}
        />
      </Col>
    </Row>
  );
}
