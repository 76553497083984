import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from "react-virtualized";
import _ from "lodash";

import CHANNELS_WITHOUT_SETTINGS from "config/constants/channels_without_settings";

import RefreshButton from "components/forms/buttons/refresh_button";
import Layouts from "components/layouts";
import MappingDirection from "components/mapping/mapping_direction";
import MappingListSettings from "components/mapping/mapping_list_settings";
import NoDataPlaceholder from "components/no_data_placeholder";

import { withWindowSize } from "hooks/use_window_size/hoc";

import MappingHeader from "./components/mapping_header";
import MappingRow from "./components/mapping_row";

import styles from "./reverse_mapping.module.css";

const BASE_ITEM_SIZE = 28;

class ReverseMapping extends Component {
  state = {
    mappingPopup: {
      mappingDefinition: {},
      visible: "",
      rate: null,
      conflictValue: null,
    },
    mappingList: [],
  };

  cache = new CellMeasurerCache({
    defaultHeight: BASE_ITEM_SIZE,
    fixedWidth: true,
    minHeight: BASE_ITEM_SIZE,
  });

  componentDidMount() {
    this.buildMappingList();
  }

  componentDidUpdate({ orderedMappingSchema, mappingOptions, mappings, windowSize }) {
    const {
      orderedMappingSchema: updatedMappingSchema,
      mappings: updatedMappings,
      mappingOptions: updatedMappingOptions,
      windowSize: updatedWindowSize,
    } = this.props;

    const mappingSchemaUpdated = !_.isEqual(orderedMappingSchema, updatedMappingSchema);
    const mappingsUpdated = updatedMappings !== mappings;
    const mappingOptionsUpdated = mappingOptions !== updatedMappingOptions;

    if (updatedWindowSize.height !== windowSize.height || updatedWindowSize.width !== windowSize.width) {
      this.cache.clearAll();
      this.listRef.current?.forceUpdateGrid();
    }

    if (mappingSchemaUpdated || mappingsUpdated || mappingOptionsUpdated) {
      this.buildMappingList();
    }
  }

  buildKey = (mappingDefinition) => {
    return _.reduce(mappingDefinition, (acc, value, key) => `${acc}_${key}_${value}`, "");
  };

  handleRateChange = (ids) => {
    const { ratePlans } = this.props;

    const rateId = ids.pop();
    const rate = ratePlans.find((rp) => rp.id === rateId);

    this.setState(
      ({ mappingPopup }) => ({
        mappingPopup: {
          ...mappingPopup,
          rate,
        },
      }),
      this.updateList,
    );
  };

  openMappingDialog = (mappingDefinition, mappedRate) => {
    const key = this.buildKey(mappingDefinition);

    this.setState(
      {
        mappingPopup: {
          mappingDefinition,
          visible: key,
          prevRate: mappedRate,
          rate: mappedRate,
        },
      },
      this.updateList,
    );
  };

  groupConflictingMappings = (rates) => {
    const groupedRates = rates.reduce((acc, rate) => {
      const rateGroupId = `${rate.parent_rate_plan_id}${rate.property_id}${rate.room_type_id}`;

      const rateGroup = acc[rateGroupId] || [];
      rateGroup.push(rate);
      acc[rateGroupId] = rateGroup;

      return acc;
    }, {});

    return groupedRates;
  };

  openResolveConflictDialog = (mappingDefinition, mappedRates) => {
    const key = this.buildKey(mappingDefinition);
    const groupedRates = this.groupConflictingMappings(mappedRates);
    const conflictValue = Object.keys(groupedRates)[0];

    this.setState(
      {
        mappingPopup: {
          mappingDefinition,
          visible: key,
          prevRate: mappedRates,
          rate: mappedRates,
          groupedRates,
          conflictValue,
        },
      },
      this.updateList,
    );
  };

  deleteMapping = (mappingDefinition) => {
    return this._deleteMappingByPredicate((m) => _.isEqual(m, mappingDefinition));
  };

  deleteMappingBySubMatch = (mappingDefinition) => {
    return this._deleteMappingByPredicate(_.matches(mappingDefinition));
  };

  closePopup = () => {
    this.setState(
      ({ mappingPopup }) => ({
        mappingPopup: {
          ...mappingPopup,
          visible: "",
        },
      }),
      this.updateList,
    );

    // when popup closes there is an animation duration which shows form
    // we need to delay form values erase by that animation duration to prevent form being empty while popup is still closing
    setTimeout(() => {
      this.setState(({ mappingPopup }) => ({
        mappingPopup: {
          ...mappingPopup,
          mappingDefinition: {},
          rate: null,
        },
      }));
    }, 200);
  };

  handleApplyConflictResolve = () => {
    const { mappings, onChangeMapping } = this.props;
    const { mappingPopup } = this.state;
    const { rate, groupedRates, conflictValue, mappingDefinition } = mappingPopup;

    const resolvedMappings = groupedRates[conflictValue].reduce((acc, singleRate) => {
      return { ...acc, [singleRate.id]: mappingDefinition };
    }, {});

    const changeSet = {
      ...mappings,
      ...rate.reduce((acc, r) => {
        acc[r.id] = null;
        return acc;
      }, {}),
      ...resolvedMappings,
    };

    onChangeMapping(changeSet);

    this.closePopup();
  };

  handleApplyMapping = () => {
    const { mappings, mappingOptions, channelCode, onChangeMapping } = this.props;
    const { mappingPopup } = this.state;

    if (!mappingPopup.rate) {
      return this.closePopup();
    }

    const prevRate = mappingPopup.prevRate;
    const rateId = mappingPopup.rate.id;
    const mappingDefinition = mappingPopup.mappingDefinition;

    if (channelCode === "VRBO") {
      const id = mappingDefinition.property_id;
      const url = mappingOptions.property_id_dictionary.values.find((p) => p.id === id)
        .url;

      mappingDefinition.property_url = url;
    }

    const changeSet = {
      ...mappings,
      [rateId]: mappingDefinition,
    };

    if (prevRate) {
      changeSet[prevRate.id] = null;
    }

    onChangeMapping(changeSet);
    return this.closePopup();
  };

  handlePopupDeleteClick = () => {
    const { mappingDefinition } = this.state.mappingPopup;

    this.deleteMapping(mappingDefinition);
    this.closePopup();
  };

  handleConflictFormChange = (e) => {
    this.setState(
      ({ mappingPopup }) => ({
        mappingPopup: {
          ...mappingPopup,
          conflictValue: e.target.value,
        },
      }),
      this.updateList,
    );
  };

  _deleteMappingByPredicate = (predicate) => {
    const { mappings, onChangeMapping } = this.props;
    const newMappings = { ...mappings };

    Object.keys(newMappings)
      .filter((key) => predicate(newMappings[key]))
      .forEach((key) => {
        newMappings[key] = null;
      });

    onChangeMapping({ ...newMappings });
  };

  getItem = ({ index, key, parent, style }) => {
    const { ratesTree } = this.props;
    const { mappingList, mappingPopup } = this.state;

    const mapping = mappingList[index];
    const { hasChildren } = mapping;

    const ListItem = hasChildren ? MappingHeader : MappingRow;
    const className = index ? null : styles.firstElement;

    return (
      <CellMeasurer cache={this.cache} columnIndex={0} key={key} parent={parent} rowIndex={index}>
        {({ registerChild }) => (
          // 'style' attribute required to position cell (within parent List)
          <div ref={registerChild} style={style} className={className}>
            <ListItem
              mapping={mapping}
              mappingPopupData={mappingPopup}
              ratesTree={ratesTree}
              handleRateChange={this.handleRateChange}
              handleOpenResolveConflictDialog={this.openResolveConflictDialog}
              handleOpenMappingDialog={this.openMappingDialog}
              handleDeleteMapping={this.deleteMapping}
              handleDeleteMappingBySubMatch={this.deleteMappingBySubMatch}
              handleConflictFormChange={this.handleConflictFormChange}
              handlePopupDeleteClick={this.handlePopupDeleteClick}
              handleApplyMapping={this.handleApplyMapping}
              handleApplyConflictResolve={this.handleApplyConflictResolve}
              handleClosePopup={this.closePopup}
            />
          </div>
        )}
      </CellMeasurer>
    );
  };

  updateList = () => {
    const { current } = this.listRef;

    if (current) {
      this.cache.clearAll();
      current.forceUpdateGrid();
    }
  };

  buildMappingList = () => {
    const { mappingOptions, orderedMappingSchema } = this.props;

    const mappingList = [];

    this.fillMappingList(mappingList, orderedMappingSchema, mappingOptions);

    this.setState({ mappingList }, this.updateList);
  };

  fillMappingList = (
    mappingList,
    orderedMappingSchema,
    mappingOptions = {},
    isParentDeleted = false,
    level = 0,
    mappingDefinition = {},
  ) => {
    const mappingParam = orderedMappingSchema[level];

    if (!mappingParam || !mappingOptions) {
      return;
    }

    const { id } = mappingParam;
    const hasChildren = Boolean(orderedMappingSchema[level + 1]);

    const mappingDictionary = mappingOptions[`${id}_dictionary`];
    const mappingValues = mappingDictionary ? mappingDictionary.values : [];

    for (let i = 0; i < mappingValues.length; i++) {
      const mapping = mappingValues[i];

      mappingDefinition = {
        ...mappingDefinition,
        [id]: mapping.id,
      };

      const mappedRates = this.getMappings(mappingDefinition);
      const mappingKey = this.buildKey(mappingDefinition);
      const mappedRatesCount = mappedRates.length;
      const mappedRatesOffsetMultiplier = mappedRatesCount && !hasChildren ? mappedRatesCount - 1 : 0;

      const nodeDeleted = isParentDeleted || mapping.isDeleted;
      const hasNoMappedRates = !mappedRatesCount || !this.isSubLevelHaveMappings(mappingDefinition);

      if (nodeDeleted && hasNoMappedRates) {
        // eslint-disable-next-line no-continue
        continue;
      }

      mappingList.push({
        ...mapping,
        level,
        hasChildren,
        mappedRates,
        isParentDeleted,
        mappingKey,
        mappingDefinition,
        mappedRatesOffsetMultiplier,
      });

      this.fillMappingList(
        mappingList,
        orderedMappingSchema,
        mappingOptions,
        nodeDeleted,
        level + 1,
        mappingDefinition,
      );
    }
  };

  isSubLevelHaveMappings = (mappingDefinition) => {
    const { mappings } = this.props;

    const filteredMappings = _.filter(mappings, _.matches(mappingDefinition));

    return !_.isEmpty(filteredMappings);
  };

  getMappings = (mappingDefinition) => {
    const { ratePlans, mappings } = this.props;

    const rateIds = _.keys(_.pickBy(mappings, mappingDefinition));

    return ratePlans.filter((rp) => rateIds.includes(rp.id));
  };

  listRef = React.createRef();

  renderToolbar = () => {
    const {
      mappingSettings,
      onChangeSettings,
      onRefresh,
      channelCode,
    } = this.props;
    const { reverseMapping } = mappingSettings;

    return (
      <>
        <RefreshButton onClick={onRefresh} />
        {CHANNELS_WITHOUT_SETTINGS.includes(channelCode) ? null : (
          <MappingListSettings
            mappingSettings={mappingSettings}
            onChangeSettings={onChangeSettings}
            reorderEnabled={reverseMapping}
          />
        )}
      </>
    );
  };

  render() {
    const { t, loading, channelTitle, mappingSettings } = this.props;
    const { mappingList } = this.state;
    const totalItemsCount = mappingList.length;
    const { reverseMapping } = mappingSettings;

    if (!totalItemsCount) {
      return (
        <NoDataPlaceholder emptyMessage={t("channels_page:form:no_mapping_data_placeholder")} />
      );
    }

    return (
      <Layouts.WithToolbar loading={loading} toolbar={this.renderToolbar()}>
        <div className={styles.virtualListContainer}>
          <MappingDirection channelTitle={channelTitle} reverse={reverseMapping} />
          <div className={styles.listContainer}>
            <AutoSizer>
              {({ height, width }) => (
                <List
                  className={styles.listInnerContainer}
                  height={height}
                  width={width}
                  rowCount={mappingList.length}
                  ref={this.listRef}
                  deferredMeasurementCache={this.cache}
                  rowHeight={this.cache.rowHeight}
                  rowRenderer={this.getItem}
                />
              )}
            </AutoSizer>
          </div>
        </div>
      </Layouts.WithToolbar>
    );
  }
}

export default withWindowSize()(withTranslation()(ReverseMapping));
