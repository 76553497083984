import React from "react";
import { useTranslation } from "react-i18next";
import { Divider } from "antd";

import FULL_RESTRICTIONS from "config/constants/inventory/full_restrictions";

import styles from "../inventory_filters.module.css";

export default function RestrictionFilterDropdown({ menu, onChange, minStayType }) {
  const { t } = useTranslation();

  const allRestrictions = FULL_RESTRICTIONS.filter((el) => {
    switch (minStayType) {
      case "arrival":
        return el !== "min_stay_through" && el !== "min_stay_arrival";

      case "through":
        return el !== "min_stay_arrival" && el !== "min_stay_through";

      default:
        return el !== "min_stay";
    }
  });

  const handleRestrictionPresetChoose = (preset) => {
    return () => {
      switch (preset) {
        case "all":
          onChange("selectedRestrictions")(allRestrictions);
          break;
        case "rate_and_availability":
          onChange("selectedRestrictions")(["rate", "availability"]);
          break;
        case "availability_only":
          onChange("selectedRestrictions")(["availability"]);
          break;

        default:
          break;
      }
    };
  };

  return (
    <div>
      <div
        onMouseDown={handleRestrictionPresetChoose("all")}
        className={styles.filter__preset}
        data-cy="restriction_preset_all"
      >
        {t("inventory_page:restriction_presets:all")}
      </div>
      <div
        onMouseDown={handleRestrictionPresetChoose("availability_only")}
        className={styles.filter__preset}
        data-cy="restriction_preset_availability_only"
      >
        {t("inventory_page:restriction_presets:availability_only")}
      </div>
      <div
        onMouseDown={handleRestrictionPresetChoose("rate_and_availability")}
        className={styles.filter__preset}
        data-cy="restriction_preset_rate_and_availability"
      >
        {t("inventory_page:restriction_presets:rate_and_availability")}
      </div>
      <Divider className={styles.filter__optionsDivider} />
      <div data-cy="dropdown_scrollable_menu">{menu}</div>
    </div>
  );
}
