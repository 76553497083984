import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Form } from "antd";

import SubmitButton from "components/forms/buttons/submit_button";
import PasswordInput from "components/forms/inputs/password";

function RestorePasswordFormComponent({ handleSubmit, isSubmitting, errors }) {
  const { t } = useTranslation();

  return (
    <Form onFinish={handleSubmit}>
      <PasswordInput />
      <PasswordInput
        name="password_confirmation"
        className="form-input__password_confirmation"
        placeholder={t("public_pages:password_confirmation_field_placeholder")}
      />

      {errors && errors.restore_password_token && (
        <div style={{ color: "#ff4d4f", marginBottom: "10px" }}>
          {t("restore_password_page:invalid_token")}
        </div>
      )}

      <SubmitButton loading={isSubmitting}>{t("restore_password_page:submit_button")}</SubmitButton>
    </Form>
  );
}

RestorePasswordFormComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
};

export default RestorePasswordFormComponent;
