import { withTranslation } from "react-i18next";
import { notification } from "antd";
import { withFormik } from "formik";
import { compose } from "recompose";
import store from "store";

import FormComponent from "./sign_up_form_component";

const { Auth } = store;

const withFormContainer = withFormik({
  validateOnBlur: false,
  validateOnChange: false,
  mapPropsToValues: () => ({
    email: "",
    name: "",
    password: "",
    password_confirmation: "",
  }),
  handleSubmit: (values, { props, setSubmitting, setErrors }) => {
    const { t, navigate } = props;

    return Auth.signUp(values)
      .then(() => {
        notification.open({
          message: t("sign_up_page:success_message_header"),
          description: t("sign_up_page:success_message_description"),
        });

        navigate("/");
      })
      .catch((error) => {
        if (!error.isValidationError) {
          throw error;
        }

        setErrors(error.body.errors.details);
        setSubmitting(false);
      });
  },
});

const enhance = compose(withTranslation(), withFormContainer);

export default enhance(FormComponent);
