import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import ChannexDrawerRoutable from "drawers/channex_drawer_routable";
import GroupUserAccess from "drawers/group_user_access/group_user_access";

import DrawerFormWrapper from "components/drawer_form_wrapper/drawer_form_wrapper";

import withRouter from "routing/with_router";

class GroupUserAccessDrawer extends Component {
  render() {
    const { t, params } = this.props;
    const userId = params.userId;
    const groupId = params.groupId;

    return (
      <ChannexDrawerRoutable
        title={t(userId ? "Edit User" : "Invite User")}
        dataCy="invite_user_drawer"
      >
        {({ componentRef, handleCloseFromContent }) => (
          <DrawerFormWrapper withActions>
            <GroupUserAccess
              userId={userId}
              groupId={groupId}
              componentRef={componentRef}
              onClose={handleCloseFromContent}
            />
          </DrawerFormWrapper>
        )}
      </ChannexDrawerRoutable>
    );
  }
}

export default withTranslation()(withRouter(GroupUserAccessDrawer));
