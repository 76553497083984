import { ADMIN_USERS_ADD, ADMIN_USERS_DROP, ADMIN_USERS_LOAD } from "../constants";

export function adminUsersLoad(storage) {
  return function (users, meta) {
    storage.dispatch({ type: ADMIN_USERS_LOAD, payload: { users, meta } });
  };
}

export function adminUsersAdd(storage) {
  return function (user) {
    storage.dispatch({ type: ADMIN_USERS_ADD, payload: user });
  };
}

export function adminUsersDrop(storage) {
  return function (user) {
    storage.dispatch({ type: ADMIN_USERS_DROP, payload: user });
  };
}
