import React from "react";
import { withTranslation } from "react-i18next";
import { Form } from "antd";

import { horizontalFormItemLayout } from "config/constants/layouts/form";

import CopyToClipboard from "components/forms/buttons/copy_to_clipboard/copy_to_clipboard";

function Id({ t, id }) {
  return (
    <Form.Item {...horizontalFormItemLayout} label={t("general:id")}>
      {id}
      <CopyToClipboard text={id} />
    </Form.Item>
  );
}

export default withTranslation()(Id);
