import { convertToCamelCase, convertToSnakeCase } from "../utils/case_converter";

let transport;
let storage;

const ENDPOINT = "profiles";

export default class Profiles {
  constructor(container) {
    transport = container.transport;
    storage = container.storage;
  }

  get() {
    return transport.send("GET", ENDPOINT).then(({ data }) => {
      const profile = data.attributes;
      storage.userUpdate(profile);

      return profile;
    });
  }

  update(attrs) {
    const user = convertToSnakeCase(attrs);

    return transport.send("PUT", ENDPOINT, { user }).then(({ data }) => {
      const profile = data.attributes;
      storage.userUpdate(profile);

      return profile;
    });
  }

  requestDeleteCode() {
    return transport.send("GET", `${ENDPOINT}/request_delete`).then(({ data }) => {
      return data;
    });
  }

  delete(code) {
    return transport.send("POST", `${ENDPOINT}/delete`, { code }).then(({ data }) => {
      return data;
    });
  }

  updateUiSettings(params) {
    const formattedParams = convertToSnakeCase({
      userUiSetting: params,
    });

    return transport.send("PUT", `${ENDPOINT}/ui_settings`, formattedParams).then(({ data }) => {
      const uiSettings = convertToCamelCase(data.attributes);
      storage.setUiSettings(uiSettings);

      return uiSettings;
    });
  }

  signAgreement(agreementVersion) {
    const body = convertToSnakeCase({
      agreementVersion,
    });

    return transport.send("POST", `${ENDPOINT}/sign_agreement`, body).then(() => {
      const { user } = storage.getState();
      user.signed_agreement_version = agreementVersion;

      storage.userUpdate(user);

      return user;
    });
  }
}
