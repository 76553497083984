import React from "react";

import Avatar from "components/users/avatar/avatar";

import styles from "components/user/user.module.css";

export default function (props) {
  return (
    <div className={styles.container}>
      <Avatar {...props} />
      <div className={styles.name}>
        <div>
          {props.name} {props.role ? `(${props.role})` : null}
        </div>
        <div>{props.email}</div>
      </div>
    </div>
  );
}
