import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Form } from "antd";

import { horizontalFormItemLayout } from "config/constants/layouts/form";

import RateModificator from "components/forms/inputs/rate_modificator";

const DEFAULT_RATE = [];

function InputRate({ model, onChange, errors, disabled }) {
  const { t } = useTranslation();

  const rateValue = useMemo(() => model?.derived_option?.rate || DEFAULT_RATE, [model]);

  const handleChange = useCallback(
    (rate) => {
      const newFieldValue = { ...model?.derived_option, rate };

      onChange("derived_option", newFieldValue);
    },
    [model, onChange],
  );

  const onAddModificator = useCallback(
    (event) => {
      event.preventDefault();
      const newRateValue = [...rateValue, []];

      handleChange(newRateValue);
    },
    [rateValue, handleChange],
  );

  const onChangeModificator = useCallback(
    (index, column) => (value) => {
      rateValue[index][column] = value;

      handleChange(rateValue);
    },
    [rateValue, handleChange],
  );

  const onDropModificator = useCallback(
    (index) => () => {
      const updatedRates = [...rateValue];
      updatedRates[index] = null;

      handleChange(updatedRates);
    },
    [rateValue, handleChange],
  );

  return (
    <Form.Item {...horizontalFormItemLayout} label={t("rates_page:form:rate_modification_logic")}>
      <RateModificator
        errors={errors?.derived_option?.rate}
        disabled={disabled}
        modificators={rateValue}
        onAddModificator={onAddModificator}
        onChangeModificator={onChangeModificator}
        onDropModificator={onDropModificator}
      />
    </Form.Item>
  );
}

InputRate.propTypes = {
  model: PropTypes.object,
  errors: PropTypes.object,
  onChange: PropTypes.func,
};

export default InputRate;
