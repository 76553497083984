const mapPropertyErrorToUi = (error) => {
  if (error.type === "field") {
    const tab = "1";
    const fieldsMapping = {
      property_type_group: "property_type",
    };

    const field = fieldsMapping[error.field] ? fieldsMapping[error.field] : error.field;

    return {
      defaultTab: tab,
      focusField: field,
      propertyId: error.id,
    };
  }

  if (error.type === "entity") {
    if (error.relation === "Photo") {
      const tab = "3";

      return ({
        defaultTab: tab,
        propertyId: error.id,
      });
    }
  }
};

const mapHotelPolicyErrorToUi = (error) => {
  return {
    id: error.id,
    focusField: error.field,
  };
};

const mapChannelErrorToUi = (error) => {
  let tab = "settings_tab";
  if (["Mapping", "RoomMapping", "RateMapping"].includes(error.relation)) {
    tab = "mapping_tab";
  }

  return {
    tab,
    focusField: error.field,
  };
};

export const mapErrorToUi = (error) => {
  const entityErrorMappers = {
    Property: mapPropertyErrorToUi,
    HotelPolicy: mapHotelPolicyErrorToUi,
    Channel: mapChannelErrorToUi,
  };

  const entityErrorMapper = entityErrorMappers[error.entity];

  if (!entityErrorMapper) {
    throw new Error(`unknown error entity: ${error.entity}`);
  }

  return entityErrorMapper(error);
};
