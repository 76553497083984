import React, { Component } from "react";
import { Outlet } from "react-router-dom";

import * as Page from "components/page";

import { pathBuilder } from "routing";
import withRouter from "routing/with_router";

import ChannelsTable from "./channels_table";

class ChannelsPage extends Component {
  state = {
    tableQuery: "",
  };

  getClosePath = () => {
    const { routes } = this.props;
    const { tableQuery } = this.state;
    const basePath = pathBuilder(routes.userAppRoutes.channels);
    const closePath = [basePath, tableQuery].join("?");

    return closePath;
  };

  render() {
    const closePath = this.getClosePath();

    return (
      <Page.Main>
        <ChannelsTable onTableQueryChange={(tableQuery) => this.setState({ tableQuery })} />
        <Outlet context={{ closePath }} />
      </Page.Main>
    );
  }
}

export default withRouter(ChannelsPage);
