import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { LinkOutlined, UserOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

import channelNames from "config/constants/channels/channel_codes";

import styles from "./inventory_table_rate_plan.module.css";

class InventoryTableRatePlan extends Component {
  getRatePlanTitle = () => {
    const { ratePlan, onClick, channel } = this.props;

    let title = ratePlan.title;

    if (ratePlan.channel_id && channel) {
      const { title: channelTitle, icon, shortCode } = channelNames[channel.channel];

      title = (
        <div className={styles.channelName}>
          {icon ? (
            <Tooltip title={channelTitle}>
              <img src={icon} alt={channelTitle} className={styles.channelLogo} />
            </Tooltip>
          ) : (
            <Tooltip title={channelTitle}>
              <div className={styles.channelLogoEmpty}>{shortCode}</div>
            </Tooltip>
          )}
          <div>{channel.title}</div>
        </div>
      );
    }

    if (onClick) {
      return (
        <a href="#toggleChannelsList" onClick={onClick}>
          {title}
        </a>
      );
    }

    return title;
  };

  renderChannelLink = () => {
    const { t, ratePlan, onToggleChannelsList } = this.props;
    const { id, count_of_channels } = ratePlan;

    if (!count_of_channels) {
      return null;
    }

    return (
      <Tooltip title={t("inventory_page:connected_channels")}>
        <a href="#toggleChannelsList" onClick={onToggleChannelsList(id)}>
          <LinkOutlined /> {count_of_channels}
        </a>
        &nbsp;
      </Tooltip>
    );
  };

  render() {
    const { t, ratePlan } = this.props;
    const { occupancy } = ratePlan;

    const title = this.getRatePlanTitle();
    const channelLink = this.renderChannelLink();

    return (
      <div>
        <span className={styles.table__rateNameCell__title}>{title}</span>
        <small className={styles.table__rowLabel}>
          {channelLink}
          <Tooltip title={t("inventory_page:occupancy")}>
            <UserOutlined />
            {occupancy}
          </Tooltip>
        </small>
      </div>
    );
  }
}

export default withTranslation()(InventoryTableRatePlan);
