import React from "react";
import { useTranslation } from "react-i18next";

import EmptyIcon from "static/empty_search_icon.svg";
import styles from "./empty_search_result_placeholder.module.css";

export default function EmptySearchResultPlaceholder() {
  const { t } = useTranslation();
  return (
    <div className={styles.wrapper}>
      <img src={EmptyIcon} className={styles.image} alt="Empty search" />
      <p className={styles.title}>{t("general:empty_search_result_title")}</p>
      <p className={styles.subTitle}>{t("general:empty_search_result_subTitle")}</p>
    </div>
  );
}
