import attrubutesExtractor from "../../utils/attributes_extractor";
import {
  CHANNELS_ACTIVATE,
  CHANNELS_ADD,
  CHANNELS_DEACTIVATE,
  CHANNELS_DROP,
  CHANNELS_LOAD,
} from "../constants";

const initialState = null;
const ACTION_HANDLERS = {
  [CHANNELS_LOAD]: (state, action) => {
    return action.payload.reduce((acc, el) => {
      acc[el.id] = el.attributes;
      if (el.relationships) {
        Object.keys(el.relationships).forEach((key) => {
          acc[el.id][`${key}_id`] = el.relationships[key].data.id;
        });
      }
      return acc;
    }, {});
  },
  [CHANNELS_ADD]: (state, action) => {
    const item = {};
    const { id } = action.payload;

    item[action.payload.id] = action.payload.attributes;
    if (action.payload.relationships) {
      Object.keys(action.payload.relationships).forEach((key) => {
        const relationship = action.payload.relationships[key].data;

        if (Array.isArray(relationship)) {
          item[id][`${key}_list`] = attrubutesExtractor(relationship);
          return;
        }

        item[id][`${key}_id`] = relationship.id;
      });
    }
    return {
      ...state,
      ...item,
    };
  },
  [CHANNELS_ACTIVATE]: (state, action) => {
    const channelId = action.payload;
    const isChannel = state[channelId];

    if (isChannel) {
      return {
        ...state,
        [channelId]: {
          ...state[channelId],
          is_active: true,
        },
      };
    }
    return {
      ...state,
    };
  },
  [CHANNELS_DEACTIVATE]: (state, action) => {
    const channelId = action.payload;
    const isChannel = state[channelId];

    if (isChannel) {
      return {
        ...state,
        [channelId]: {
          ...state[channelId],
          is_active: false,
        },
      };
    }
    return {
      ...state,
    };
  },
  [CHANNELS_DROP]: (state, action) => {
    delete state[action.payload.id];
    return { ...state };
  },
};

export default function channelsReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
