import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import EventActionBar from "components/event/action_bar";

import { formatToLocal } from "utils/dates";

class ActionBar extends Component {
  render() {
    const { t, header, issue, resolveAction } = this.props;
    const { payload = {} } = issue;
    const { resolved_at, user } = payload;

    let defaultHeader = t("issues_drawer:action_bar:open");

    if (resolved_at) {
      const formattedDate = formatToLocal(resolved_at, t("formats:date_time_with_seconds"));
      const userName = user && user.name;

      defaultHeader = (
        <>
          {userName && (
            <div>
              {t("issues_drawer:action_bar:resolved_by")} {userName}
            </div>
          )}
          <div>
            {t("issues_drawer:action_bar:resolved_at")} {formattedDate}
          </div>
        </>
      );
    }

    return (
      <EventActionBar
        title={header || defaultHeader}
        actions={resolveAction}
      />
    );
  }
}

export default withTranslation()(ActionBar);
