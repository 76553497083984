import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Col, Row } from "antd";
import _ from "lodash";
import memoizeOne from "memoize-one";
import store from "store";

import withProperty from "data/use_property/hoc";

import CancellationPolicyForm from "components/cancellation_policy/forms/cancellation_policy_form";
import GeneralForm from "components/cancellation_policy/forms/general_form";
import GuaranteePaymentForm from "components/cancellation_policy/forms/guarantee_payment_form";
import NonShowPolicyForm from "components/cancellation_policy/forms/non_show_policy_form";
import SubmitButton from "components/forms/buttons/submit_button";
import Loading from "components/loading";
import RatePlanAssignSubform from "components/rate_plans/rate_plan_assign_subform";

import withComponentRef from "containers/with_component_ref";

import confirmDirtyStateSave from "utils/confirm_dirty_state_save";
import parseApiErrors from "utils/parse_api_errors";

import styles from "styles/form_in_drawer.module.css";

const { CancellationPolicies } = store;

class CancellationPolicyEditForm extends Component {
  state = {
    loading: true,
    submitting: false,
    value: {},
    errors: {},
  };

  formRefs = {
    generalFormRef: React.createRef(),
    guaranteeFormRef: React.createRef(),
    cancellationPolicyFormRef: React.createRef(),
    nonShowPolicyRef: React.createRef(),
    ratePlanAssignSubFrom: React.createRef(),
  };

  componentDidMount() {
    const { policyId } = this.props;

    this.setState({ loading: true });

    if (policyId) {
      CancellationPolicies.find(policyId).then((policy) => {
        const value = policy.data.attributes;
        value.allow_deadline_based_logic = value.cancellation_policy_logic === "deadline" || value.cancellation_policy_logic === "non_refundable";
        value.guarantee_collected_at_booking_time = value.guarantee_collected_at_days === -1;

        this.setState({
          loading: false,
          value,
        });

        this.loadTextRepresentation();
      });
    } else {
      this.setState({
        loading: false,
        value: {
          property_id: this.props.propertyId,
          after_reservation_cancellation_logic: "free",
        },
      });
    }
  }

  onChange = (subFormValue) => {
    if (subFormValue.guarantee_payment_policy) {
      if (subFormValue.guarantee_collected_at_booking_time) {
        subFormValue.guarantee_collected_at_days = -1;
      } else {
        subFormValue.guarantee_collected_at_days = subFormValue.guarantee_collected_at_days === -1 ? 1 : subFormValue.guarantee_collected_at_days;
      }
    }

    this.setState(({ value }) => ({
      value: { ...value, ...subFormValue },
    }));

    this.loadTextRepresentation();
  };

  validate = () => {
    const validationHandlers = Object.values(this.formRefs).map((formComponent) => {
      return formComponent.current.validate();
    });

    return Promise.all(validationHandlers);
  };

  onSubmit = () => {
    const { onClose, onCreate = () => {} } = this.props;
    const { value } = this.state;

    this.setState({ submitting: true });

    this.validate()
      .then(() => {
        if (value.id) {
          CancellationPolicies.update(value)
            .then((response) => {
              onClose();
              onCreate(response);
            })
            .catch((error) => {
              if (!error.isValidationError) {
                throw error;
              }

              this.setState({ errors: parseApiErrors(error) });
            })
            .finally(() => {
              this.setState({ submitting: false });
            });
        } else {
          CancellationPolicies.create(value)
            .then((response) => {
              onClose();
              onCreate(response);
            })
            .catch((error) => {
              if (!error.isValidationError) {
                throw error;
              }

              this.setState({ errors: parseApiErrors(error) });
            })
            .finally(() => {
              this.setState({ submitting: false });
            });
        }
      })
      .catch(() => {
        this.setState({ submitting: false });
      });
  };

  beforeClose = () => {
    const isChanged = Object.values(this.formRefs)
      .map(({ current }) => current.formik.current)
      .some(({ dirty }) => dirty);

    return confirmDirtyStateSave(isChanged);
  };

  loadTextRepresentation = _.debounce(() => {
    CancellationPolicies.convertToText(this.state.value).then((textRepresentation) => {
      this.setState({ textRepresentation: textRepresentation.data.text });
    });
  }, 500);

  // param as invalidate cache key;
  getInitialAssociatedRatePlanIds = memoizeOne((_policyId) => {
    const { value } = this.state;

    return [...value.associated_rate_plan_ids || []] || [];
  });

  render() {
    const {
      t,
      propertyId,
      property,
      isPropertyLoading,
    } = this.props;

    const { value, loading, submitting, errors, textRepresentation } = this.state;

    const defaultFormProps = {
      onChange: this.onChange,
      value,
      errors,
    };

    if (loading || isPropertyLoading) {
      return <Loading />;
    }

    // when we deal with cancellation policy which is the default for property we should not allow to
    // deselect currently associated rate plans, because it will lead to the situation when
    // rate plan will lose any associated cancellation policy when default cancellation policy gets deassociated
    const isDefault = property.default_cancellation_policy_id === value.id;
    const disabledRatePlans = isDefault ? this.getInitialAssociatedRatePlanIds(value.id) : [];

    return (
      <div>
        <GeneralForm componentRef={this.formRefs.generalFormRef} {...defaultFormProps} />
        <RatePlanAssignSubform
          componentRef={this.formRefs.ratePlanAssignSubFrom}
          {...defaultFormProps}
          label={t("policies:assign:associated_rate_plans_label")}
          propertyId={propertyId}
          disabledRatePlans={disabledRatePlans}
        />
        <GuaranteePaymentForm componentRef={this.formRefs.guaranteeFormRef} {...defaultFormProps} />
        <CancellationPolicyForm
          componentRef={this.formRefs.cancellationPolicyFormRef}
          {...defaultFormProps}
        />
        <NonShowPolicyForm componentRef={this.formRefs.nonShowPolicyRef} {...defaultFormProps} />
        <br />
        <Row className={styles.cancellationPolicyRepresentation}>
          <Col className={styles.cancellationPolicyRepresentation_label} xs={24} sm={8}>
            Policy Representation
          </Col>
          <Col className={styles.cancellationPolicyRepresentation_text} xs={24} sm={16}>
            {textRepresentation}
          </Col>
        </Row>

        <div className={styles.actions}>
          <SubmitButton loading={submitting} onClick={this.onSubmit}>
            {t("general:action:save")}
          </SubmitButton>
        </div>
      </div>
    );
  }
}

export default (
  withTranslation()(
    withProperty(
      withComponentRef(
        CancellationPolicyEditForm,
      ),
    ),
  )
);
