import React from "react";
import { useTranslation } from "react-i18next";

import FormInput from "components/forms/inputs/hook_form/form_input";
import FormInputNumber from "components/forms/inputs/hook_form/form_input_number";

const InputField = FormInput;
const IntegerField = FormInputNumber;

export const Field = ({ control, name, type }) => {
  const { t } = useTranslation();
  const types = {
    integer: IntegerField,
  };

  const FieldComponent = types[type] || InputField;

  return (
    <FieldComponent
      control={control}
      label={t(`mapping:field:label:${name}`)}
      placeholder={t(`mapping:field:placeholder:${name}`)}
      name={name}
    />
  );
};
