import React, { createRef, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import SettingField from "./setting_field";

export default function ConnectionSettingsForm({
  settingsSchema,
  mappingSchema,
  form,
  namespace,
  onChange,
  focusField,
}) {
  const { t } = useTranslation();
  const refs = useRef(Object.keys(settingsSchema).reduce((acc, field) => ({ ...acc, [field]: createRef() }), {}));

  useEffect(() => {
    if (focusField) {
      const fieldName = focusField.split(".")[1];
      setTimeout(() => {
        refs?.current?.[fieldName]?.current?.focus();
      }, 0);
    }
  }, [focusField, refs]);

  const settings = form.values[namespace];

  return Object.keys(settingsSchema)
    .sort((a, b) => settingsSchema[a].position - settingsSchema[b].position)
    .map((field) => (
      <SettingField
        inputRef={refs.current[field]}
        key={field}
        field={field}
        schema={settingsSchema[field]}
        mappingSchema={mappingSchema}
        t={t}
        form={form}
        namespace={namespace}
        settings={settings}
        handleFieldChange={onChange}
      />
    ));
}
