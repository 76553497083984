import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { DeleteOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";

import styles from "./mapping_header.module.css";

class MappingHeader extends Component {
  render() {
    const { mapping, handleDeleteMappingBySubMatch } = this.props;
    const { mappingDefinition, level, isDeleted, title } = mapping;

    const style = isDeleted ? styles.mappingLevelDeleted : null;

    return (
      <div className={[styles[`mappingLevel${level}`], style].join(" ")}>
        <Row>
          <Col md={22}>{level === 0 ? <h3>{title}</h3> : <h4>{title}</h4>}</Col>

          <Col md={2}>
            {isDeleted && (
              <a
                href="#remove"
                onClick={handleDeleteMappingBySubMatch.bind(this, mappingDefinition)}
                className={styles.deleteButton}
              >
                <DeleteOutlined />
              </a>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default withTranslation()(MappingHeader);
