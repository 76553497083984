import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "antd";
import store from "store";

import GroupsColumn from "components/properties/table_columns/groups_column";
import LocationColumn from "components/properties/table_columns/location";
import UsersColumn from "components/properties/table_columns/users_column";

import EventEmitter from "utils/event_emitter";
import setProperyLocation from "utils/set_property_location";

import PropertyActions from "./property_actions";

import style from "./expanded_group.module.css";
import generalStyles from "styles/general.module.css";

const { Properties } = store;

const TABLE_LIMIT = 5;

export default function ExpandedGroup({ parentGroup }) {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [properties, setProperties] = useState([]);
  const [total, setTotal] = useState(null);
  const [loading, setLoading] = useState(false);

  const loadProperties = useCallback(() => {
    setLoading(true);

    Properties.list({ group_id: parentGroup.id }, { page: currentPage, limit: TABLE_LIMIT }).then(
      (payload) => {
        const { entities, meta } = payload;
        const newProperties = Object.values(entities).map(setProperyLocation);

        setProperties(newProperties);
        setTotal(meta.total);
        setLoading(false);
      },
    );
  }, [parentGroup, currentPage]);

  useEffect(loadProperties, [loadProperties]);

  useEffect(() => {
    EventEmitter.bind("property_users:reload", loadProperties);
    EventEmitter.bind("property:deleted", loadProperties);
    EventEmitter.bind("property:created", loadProperties);
    EventEmitter.bind("property:updated", loadProperties);
    EventEmitter.bind("group:updated", loadProperties);
    EventEmitter.bind("group:properties_updated", loadProperties);

    return () => {
      EventEmitter.unbind("property_users:reload", loadProperties);
      EventEmitter.unbind("property:deleted", loadProperties);
      EventEmitter.unbind("property:created", loadProperties);
      EventEmitter.unbind("property:updated", loadProperties);
      EventEmitter.bind("group:updated", loadProperties);
      EventEmitter.unbind("group:properties_updated", loadProperties);
    };
  }, [loadProperties]);

  const columns = [
    {
      title: t("properties_page:columns:title"),
      dataIndex: "title",
      key: "title",
      className: style.titleColumn,
    },
    {
      title: t("properties_page:columns:location"),
      dataIndex: "location",
      key: "location",
      className: style.locationColumn,
      render: LocationColumn,
    },
    {
      title: t("general:users"),
      dataIndex: "users",
      key: "users",
      className: generalStyles.narrowTableCell,
      render: UsersColumn,
    },
    {
      title: t("properties_page:columns:groups"),
      dataIndex: "groups",
      key: "groups",
      render: GroupsColumn,
    },
    {
      title: t("properties_page:columns:channels_connected"),
      dataIndex: "acc_channels_count",
      key: "acc_channels_count",
    },
    {
      title: t("properties_page:columns:actions"),
      key: "action",
      align: "right",
      render: (_value, record) => (
        <PropertyActions group={parentGroup} property={record} />
      ),
    },
  ];

  const onTableChange = ({ current }) => {
    setCurrentPage(current);
  };

  return (
    <Table
      columns={columns}
      dataSource={properties}
      loading={loading}
      pagination={{
        current: currentPage,
        pageSize: TABLE_LIMIT,
        total,
        size: "small",
        showSizeChanger: false,
      }}
      onChange={onTableChange}
      rowKey="id"
      showHeader={false}
    />
  );
}
