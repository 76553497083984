import {
  APPLICATIONS_ADD_INSTALLED,
  APPLICATIONS_LOAD_INSTALLED,
  APPLICATIONS_REMOVE_INSTALLED,
} from "../constants";

export function applicationsLoadInstalled(storage) {
  return (installedApplications) => {
    storage.dispatch({ type: APPLICATIONS_LOAD_INSTALLED, payload: installedApplications });
  };
}

export function applicationsAddInstalled(storage) {
  return (installedApplication) => {
    storage.dispatch({ type: APPLICATIONS_ADD_INSTALLED, payload: installedApplication });
  };
}

export function applicationsRemoveInstalled(storage) {
  return (removedApplicationId) => {
    storage.dispatch({ type: APPLICATIONS_REMOVE_INSTALLED, payload: removedApplicationId });
  };
}
