import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "antd";

import RatesSubtableMenu from "./rates_subtable_menu";
import RatesSubtableTitle from "./rates_subtable_title";

export default function RatesSubtable({ ratePlans, parent }) {
  const { t } = useTranslation();

  const renderTitle = useCallback(
    (_text, record) => <RatesSubtableTitle record={record} />,
    [],
  );

  const columns = useMemo(
    () => [
      {
        title: t("rates_page:columns:title"),
        dataIndex: "title",
        key: "title",
        render: renderTitle,
      },
      {
        title: t("rates_page:columns:actions"),
        key: "action",
        align: "right",
        render: (_value, record) => (
          <RatesSubtableMenu record={record} />
        ),
      },
    ],
    [t, renderTitle],
  );

  const tableData = useMemo(() => {
    let parentRatePlans = ratePlans.entities[parent.id];

    if (parentRatePlans) {
      parentRatePlans = parentRatePlans.filter(
        (ratePlan) => !ratePlan.channel_id,
      );
    }

    return parentRatePlans;
  }, [ratePlans, parent]);

  const loading = ratePlans.loading[parent.id];

  return (
    <Table
      columns={columns}
      dataSource={tableData}
      pagination={false}
      loading={loading}
      rowKey="id"
      locale={{
        emptyText: loading
          ? t("rooms_page:nested_table:loading")
          : t("rooms_page:nested_table:empty"),
      }}
      showHeader={false}
    />
  );
}
