import { memo } from "react";
import { Checkbox, Col, Row } from "antd";

import styles from "./item_selector.module.css";

export const ItemSelector = memo((props) => {
  const {
    item,
    label,
    selected,
    onToggle,
  } = props;

  return (
    <Row className={styles.itemRow}>
      <Col>
        <Checkbox
          onChange={() => onToggle(item)}
          checked={selected}
        >
          {label}
        </Checkbox>
      </Col>
    </Row>
  );
});
