import React from "react";

export default function Flexbox({
  children,
  className,
  style,
  display = "flex",
  flex = "1",
  flexDirection,
  flexWrap,
  flexFlow,
  order,
  flexGrow,
  flexShrink,
  flexBasis,
  justifyContent,
  alignItems,
  alignSelf,
  alignContent,
  fullHeight = false,
  ...divProps
}) {
  const flexStyles = {
    display,
    flex,
    flexDirection,
    flexWrap,
    flexFlow,
    order,
    flexGrow,
    flexShrink,
    flexBasis,
    justifyContent,
    alignItems,
    alignSelf,
    alignContent,
  };

  const cleanFlexStyles = Object.keys(flexStyles).reduce((acc, key) => {
    if (flexStyles[key]) {
      acc[key] = flexStyles[key];
    }

    return acc;
  }, {});

  return (
    <div
      className={className}
      style={{
        ...cleanFlexStyles,
        ...(fullHeight ? { height: "100%" } : {}),
        ...style,
      }}
      {...divProps}
    >
      {children}
    </div>
  );
}
