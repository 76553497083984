import React from "react";

import { DoubleBlind } from "./double_blind";
import { ReplyToGuestFeedback } from "./reply_to_guest_feedback";

const byOta = {
  AirBNB: DoubleBlind,
};
const DEFAULT = ReplyToGuestFeedback;

export const Body = ({ review }) => {
  const { ota } = review.attributes;

  const ReviewBody = byOta[ota] ?? DEFAULT;

  return (
    <ReviewBody review={review} />
  );
};
