import React from "react";

import { WhiteLabelDomainForm } from "../../white_label_domain_form/white_label_domain_form";

import { useCreateAction } from "./use_create_action";

export function CreateWhiteLabelDomainForm({ wlPartnerId, onFinish }) {
  const { mutateAsync, isLoading } = useCreateAction(wlPartnerId);

  const handleSubmit = async (values) => {
    const result = await mutateAsync({ attrs: values });
    onFinish(result);
  };

  return (
    <WhiteLabelDomainForm
      isLoading={isLoading}
      onSubmit={handleSubmit}
    />
  );
}
