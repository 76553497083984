import React from "react";

import EnableRatePlans from "./enable_rate_plans";
import RatePlansManagement from "./rate_plans_management";

export default function AirBnbRatePlans({ channelId, settings, mappings, onChannelRefresh, onMappingOptionsReload }) {
  if (!settings.rate_plans_enabled) {
    return (
      <EnableRatePlans
        channelId={channelId}
        onChannelRefresh={onChannelRefresh}
      />
    );
  }

  return (
    <RatePlansManagement
      channelId={channelId}
      mappings={mappings}
      onChannelRefresh={onChannelRefresh}
      onMappingOptionsReload={onMappingOptionsReload}
    />
  );
}
