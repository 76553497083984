const updateMappedRatesReadonlyStatus = (mappedRates, externalRatesPerRoomId) => {
  const updatedMappedRates = mappedRates.map((mappedRate) => {
    // mappedRate is null when mapping was deleted from UI
    if (!mappedRate) {
      return mappedRate;
    }

    const externalRoomRates = externalRatesPerRoomId[mappedRate.room_type_code] || [];

    const originalRate = externalRoomRates.find(
      (externalRoomRate) => mappedRate.rate_plan_code === externalRoomRate.id && mappedRate.occupancy === externalRoomRate.occupancy,
    );

    if (!originalRate || typeof originalRate.readonly === "undefined") {
      return mappedRate;
    }

    return { ...mappedRate, readonly: originalRate.readonly };
  });

  return updatedMappedRates;
};

export const updateReadonlyStatuses = (mappings, externalRatesPerRoomId) => {
  const updatedMappings = Object.keys(mappings).reduce((acc, ratePlanId) => {
    const mappedRates = mappings[ratePlanId];

    const updatedMappedRates = updateMappedRatesReadonlyStatus(mappedRates, externalRatesPerRoomId);

    return { ...acc, [ratePlanId]: updatedMappedRates };
  }, {});

  return updatedMappings;
};
