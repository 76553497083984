import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import ChannexDrawerRoutable from "drawers/channex_drawer_routable";

import InventorySettingsForm from "./inventory_settings_form";

class InventorySettingsDrawer extends Component {
  render() {
    const { t, activePropertyId } = this.props;
    const title = t("inventory_settings_drawer:title");

    return (
      <ChannexDrawerRoutable title={title}>
        {({ handleCloseFromContent }) => (
          <InventorySettingsForm propertyId={activePropertyId} onClose={handleCloseFromContent} />
        )}
      </ChannexDrawerRoutable>
    );
  }
}

const mapStateToProps = ({ session }) => ({
  activePropertyId: session.activeProperty,
});

export default withTranslation()(connect(mapStateToProps)(InventorySettingsDrawer));
