import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";

import Flexbox from "components/flexbox";

export default function FailedState({ onCloseClick }) {
  const { t } = useTranslation();

  return (
    <div>
      <p>{t("bookings_export:failed_message")}</p>
      <Flexbox justifyContent="end">
        <Button onClick={onCloseClick}>{t("general:action:close")}</Button>
      </Flexbox>
    </div>
  );
}
