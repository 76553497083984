import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Form, notification, Row, Tag } from "antd";
import store from "store";

import SubmitButton from "components/forms/buttons/submit_button";
import FormCheckbox from "components/forms/inputs/form_checkbox";
import FormRate from "components/forms/inputs/form_rate";
import FormTextarea from "components/forms/inputs/form_textarea";

import Text from "../../../../../_common/text";

import styles from "./guest_review_form.module.css";

const { Reviews } = store;

const review_tags = {
  cleanliness: [
    "host_review_guest_positive_neat_and_tidy",
    "host_review_guest_positive_kept_in_good_condition",
    "host_review_guest_positive_took_care_of_garbage",
    "host_review_guest_negative_ignored_checkout_directions",
    "host_review_guest_negative_garbage",
    "host_review_guest_negative_messy_kitchen",
    "host_review_guest_negative_damage",
    "host_review_guest_negative_ruined_bed_linens",
  ],
  respect_house_rules: [
    "host_review_guest_negative_arrived_early",
    "host_review_guest_negative_stayed_past_checkout",
    "host_review_guest_negative_unapproved_guests",
    "host_review_guest_negative_unapproved_pet",
    "host_review_guest_negative_did_not_respect_quiet_hours",
    "host_review_guest_negative_unapproved_filming",
    "host_review_guest_negative_unapproved_event",
    "host_review_guest_negative_smoking",
  ],
  communication: [
    "host_review_guest_positive_helpful_messages",
    "host_review_guest_positive_respectful",
    "host_review_guest_positive_always_responded",
    "host_review_guest_negative_unhelpful_messages",
    "host_review_guest_negative_disrespectful",
    "host_review_guest_negative_unreachable",
    "host_review_guest_negative_slow_responses",
  ],
};

export default function GuestReviewForm({ review }) {
  const { t } = useTranslation();

  const [guestReview, setGuestReview] = useState({});
  const [selectedTags, setSelectedTags] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(
    function resetGuestReview() {
      setGuestReview({});
      setLoading(false);
    },
    [review],
  );

  const submitGuestReview = () => {
    setLoading(true);

    Reviews.guestReview(review.id, guestReview).then(
      (_repliedReview) => {
        setLoading(false);
        Reviews.review(review.id);
      },
      (error) => {
        setLoading(false);
        notification.error({
          message: "Error",
          description: error.errors?.title || "N/A",
        });
      },
    );
  };

  const handleChange = (field, fieldValue) => {
    setGuestReview({ ...guestReview, [field]: fieldValue });
  };

  const handleCheckBoxChange = (field) => (fieldValue) => {
    handleChange(field, fieldValue);
  };

  const handleTagChange = (tag, checked) => {
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter((storedTag) => storedTag !== tag);

    setSelectedTags(nextSelectedTags);
    handleChange("tags", nextSelectedTags);
  };

  const canSubmitGuestReview = () => {
    return !(
      guestReview.publicReview
      && guestReview.respectHouseRules
      && guestReview.cleanliness
      && guestReview.communication
    );
  };

  return (
    <Form className={styles.narrowForm} onFinish={submitGuestReview}>
      <FormTextarea
        autosize={{ minRows: 3, maxRows: 5 }}
        label={<Text>{t("reviews_page:feedback:private_review")}</Text>}
        placeholder={t("reviews_page:feedback:private_review")}
        name="privateReview"
        value={guestReview.privateReview}
        onChange={handleChange}
      />
      <FormTextarea
        autosize={{ minRows: 3, maxRows: 5 }}
        label={<Text>{t("reviews_page:feedback:public_review")}</Text>}
        placeholder={t("reviews_page:feedback:public_review")}
        name="publicReview"
        value={guestReview.publicReview}
        onChange={handleChange}
      />
      <FormCheckbox
        label={<Text>{t("reviews_page:feedback:recommend")}</Text>}
        value={guestReview.isRevieweeRecommended}
        onChange={handleCheckBoxChange("isRevieweeRecommended")}
      />
      <FormRate
        label={<Text>{t("reviews_page:feedback:respect_house_rules")}</Text>}
        name="respectHouseRules"
        value={guestReview.respectHouseRules}
        onChange={handleChange}
      />

      <Row style={{ marginTop: "-18px", marginBottom: "18px" }}>
        <Col span={16} offset={8}>
          {review_tags.respect_house_rules.map((tag) => (
            <Tag.CheckableTag
              key={tag}
              checked={selectedTags.includes(tag)}
              onChange={(checked) => handleTagChange(tag, checked)}
              style={{ marginTop: "4px", marginBottom: "4px" }}
            >
              {t(`reviews_page:tags:${tag}`)}
            </Tag.CheckableTag>
          ))}
        </Col>
      </Row>

      <FormRate
        label={<Text>{t("reviews_page:feedback:communication")}</Text>}
        name="communication"
        value={guestReview.communication}
        onChange={handleChange}
      />

      <Row style={{ marginTop: "-18px", marginBottom: "18px" }}>
        <Col span={16} offset={8}>
          {review_tags.communication.map((tag) => (
            <Tag.CheckableTag
              key={tag}
              checked={selectedTags.includes(tag)}
              onChange={(checked) => handleTagChange(tag, checked)}
              style={{ marginTop: "4px", marginBottom: "4px" }}
            >
              {t(`reviews_page:tags:${tag}`)}
            </Tag.CheckableTag>
          ))}
        </Col>
      </Row>

      <FormRate
        label={<Text>{t("reviews_page:feedback:cleanliness")}</Text>}
        name="cleanliness"
        value={guestReview.cleanliness}
        onChange={handleChange}
      />

      <Row style={{ marginTop: "-18px", marginBottom: "18px" }}>
        <Col span={16} offset={8}>
          {review_tags.cleanliness.map((tag) => (
            <Tag.CheckableTag
              key={tag}
              checked={selectedTags.includes(tag)}
              onChange={(checked) => handleTagChange(tag, checked)}
              style={{ marginTop: "4px", marginBottom: "4px" }}
            >
              {t(`reviews_page:tags:${tag}`)}
            </Tag.CheckableTag>
          ))}
        </Col>
      </Row>

      <SubmitButton disabled={canSubmitGuestReview()} loading={loading}>
        {t("reviews_page:feedback:send")}
      </SubmitButton>
    </Form>
  );
}
