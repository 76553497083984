import React from "react";

import { ExtraCategoryForm } from "../../extra_category_form/extra_category_form";

import { useCreateAction } from "./use_create_action";

export function CreateExtraCategoryForm({ propertyId, onFinish }) {
  const { mutateAsync, isLoading } = useCreateAction(propertyId);

  const handleSubmit = async (formValues) => {
    const attrs = { ...formValues };
    attrs.propertyId = propertyId;

    const result = await mutateAsync({ attrs });
    onFinish(result);
  };

  return (
    <ExtraCategoryForm
      isLoading={isLoading}
      onSubmit={handleSubmit}
    />
  );
}
