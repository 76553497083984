import React from "react";

import styles from "./styles.module.css";

export function Actions({ children }) {
  return (
    <div className={styles.actions}>
      {children}
    </div>
  );
}
