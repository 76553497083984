import React from "react";
import { Button, Form } from "antd";

import { tailFormItemLayout } from "config/constants/layouts/form";

const FormItem = Form.Item;

function SubmitButton({ loading, icon, children, disabled, onClick = () => {} }) {
  return (
    <FormItem {...tailFormItemLayout}>
      <Button
        block
        icon={icon}
        type="primary"
        loading={loading}
        size="large"
        htmlType="submit"
        disabled={disabled}
        onClick={onClick}
        data-cy="submit"
      >
        {children}
      </Button>
    </FormItem>
  );
}

export default SubmitButton;
