import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import InfiniteList from "components/infinite_list";

import Item from "./item";

import styles from "../reviews.module.css";

function List({ reviewsList, meta, loading, loadMore, defaultCacheProps }) {
  const listClassName = classNames(styles.list, {
    [`${styles.list__loading}`]: loading,
  });

  return (
    <div className={styles.listWrapper}>
      <InfiniteList
        list={reviewsList}
        meta={meta}
        loading={loading}
        loadMore={loadMore}
        className={listClassName}
        defaultCacheProps={defaultCacheProps}
        itemComponent={<Item />}
        loadBottomScroll
      />
    </div>
  );
}

List.propTypes = {
  reviewsList: PropTypes.array,
  meta: PropTypes.object,
  loading: PropTypes.bool,
  defaultCacheProps: PropTypes.object,
};

export default List;
