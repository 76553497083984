import React, { Component } from "react";
import { Form, Transfer } from "antd";

import getFieldLayout from "./utils/get_field_layout";

const FormItem = Form.Item;

class FormTransfer extends Component {
  static validation(errors) {
    if (errors) {
      return {
        validateStatus: "error",
        help: errors.join(" "),
      };
    }
    return {};
  }

  constructor(props) {
    super(props);

    this.state = {
      selectedItems: [],
    };
  }

  onChange = (value) => {
    const { onChange } = this.props;

    if (onChange && typeof onChange === "function") {
      onChange(value);
    }
  };

  onSelectionChange = (sourceSelection, targetSelection) => {
    this.setState({ selectedItems: [...sourceSelection, ...targetSelection] });
  };

  render() {
    const {
      className,
      items,
      sourceTitle,
      targetTitle,
      value,
      label,
      view,
      errors,
      search = false,
      onBlur = () => {},
    } = this.props;
    const { selectedItems } = this.state;
    const formItemLayout = getFieldLayout(view);

    return (
      <FormItem
        {...formItemLayout}
        {...FormTransfer.validation(errors)}
        label={label}
        data-cy={`transfer_${this.props.name}`}
      >
        <Transfer
          className={className}
          dataSource={items}
          titles={[sourceTitle, targetTitle]}
          targetKeys={value}
          selectedKeys={selectedItems}
          onChange={this.onChange}
          onBlur={onBlur}
          onSelectChange={this.onSelectionChange}
          render={(item) => item.title}
          showSearch={search}
          filterOption={(inputValue, option) => option.title.toLowerCase().indexOf(inputValue.toLowerCase()) > -1}
        />
      </FormItem>
    );
  }
}

export default FormTransfer;
