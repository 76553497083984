import RATE_ACTION_SUB_TYPES from "config/constants/rate_action_sub_types";

const { PERCENT, CURRENCY } = RATE_ACTION_SUB_TYPES;

export default (t) => {
  return [
    {
      value: PERCENT,
      label: "%",
    },
    {
      value: CURRENCY,
      label: t("inventory_page:inventory_value_override_controller:amount_option_type"),
    },
  ];
};
