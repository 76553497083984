import React from "react";

import ScoreItem from "../../../_common/score_item";

import styles from "./details.module.css";

const ITEMS_PER_ROW = 3;

const sliceArray = (array, size) => {
  const result = [];

  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }

  return result;
};

export default function Details({ score }) {
  const rows = sliceArray(Object.entries(score.scores), ITEMS_PER_ROW);

  return (
    <div className={styles.container}>
      {rows.map((row, i) => (
        <div key={i} className={styles.row}>
          {row.map(([key, value]) => <ScoreItem key={key} type={key} score={value.score} />)}
        </div>
      ))}
    </div>
  );
}
