import React from "react";
import { Tag } from "antd";

export const EventCreditCard = ({ isIncluded }) => {
  if (!isIncluded) {
    return null;
  }

  return (
    <Tag style={{ margin: "2px 0 0 0", lineHeight: "16px", paddingInline: 5 }} color="red">CC Included</Tag>
  );
};
