import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button } from "antd";

class UnmappedRoomResolveAction extends Component {
  render() {
    const { t, onClick } = this.props;

    return (
      <Button type="primary" onClick={onClick}>
        {t("issues_drawer:unmapped_room:action_button")}
      </Button>
    );
  }
}

export default withTranslation()(UnmappedRoomResolveAction);
