import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Tabs } from "antd";
import store from "store";

import Loading from "components/loading";

import withComponentRef from "containers/with_component_ref";

import confirmDirtyStateSave from "utils/confirm_dirty_state_save";
import parseApiErrors from "utils/parse_api_errors";

import ChannelAdaptersForm from "../channel_adapters";
import BillingAccountForm from "../form/form";
import Memberships from "../memberships";
import Properties from "../properties";

const { AdminBillingAccounts } = store;

class FormWrapper extends Component {
  state = {
    activeTab: "0",
    loading: true,
    submitting: false,
    value: {},
    errors: {},
  };

  formRef = React.createRef();

  componentDidMount() {
    const { entityId } = this.props;

    if (entityId) {
      this.load();
    } else {
      this.setState({ loading: false });
    }
  }

  load = () => {
    const { entityId } = this.props;

    AdminBillingAccounts.find(entityId).then((value) => {
      this.setState({ value: value.data.attributes, loading: false });
    });
  };

  setActiveTab = (tab) => {
    this.setState({ activeTab: tab });
  };

  handleSubmit = () => {
    const { entityId, onClose, onCreate = () => {} } = this.props;
    const { value } = this.state;

    const submitHandler = entityId ? AdminBillingAccounts.update : AdminBillingAccounts.create;

    this.setState({ submitting: true });

    this.formRef.current
      .validate()
      .then(() => {
        submitHandler({ ...value })
          .then((result) => {
            onCreate(result);
            onClose();
          })
          .catch((error) => {
            if (!error.isValidationError) {
              throw error;
            }

            this.setState({ errors: parseApiErrors(error) });
          })
          .finally(() => {
            this.setState({ submitting: false });
          });
      })
      .catch(() => {
        this.setState({ submitting: false });
      });
  };

  handleChange = (value) => {
    this.setState({ value });
  };

  beforeClose = () => {
    const { current } = this.formRef;

    if (!current) {
      return Promise.resolve();
    }

    const { dirty } = current.formik.current;

    return confirmDirtyStateSave(dirty);
  };

  render() {
    const { value, loading, submitting, errors, activeTab } = this.state;
    const { t, entityId, onClose } = this.props;

    if (loading) {
      return <Loading />;
    }

    const items = [{
      label: <span data-cy="edit_tab">{t("billing_account_drawer:tab_keys:edit")}</span>,
      key: "0",
      children: (
        <BillingAccountForm
          value={value}
          errors={errors}
          submitting={submitting}
          componentRef={this.formRef}
          onChange={this.handleChange}
          onSubmit={this.handleSubmit}
        />
      ),
    }, {
      label: <span data-cy="users_tab">{t("billing_account_drawer:tab_keys:users")}</span>,
      key: "1",
      children: (
        <Memberships billingAccountId={entityId} />
      ),
    }, {
      label: <span data-cy="properties_tab">{t("billing_account_drawer:tab_keys:properties")}</span>,
      key: "2",
      children: (
        <Properties billingAccountId={entityId} />
      ),
    },
    {
      label: <span data-cy="channel_adapters_tab">{t("billing_account_drawer:tab_keys:channel_adapters")}</span>,
      key: "3",
      children: (
        <ChannelAdaptersForm value={value} billingAccountId={entityId} onClose={onClose} />
      ),
    }];

    return (
      <Tabs
        items={items}
        destroyInactiveTabPane
        defaultActiveKey={activeTab}
        onChange={this.setActiveTab}
      />
    );
  }
}

export default withComponentRef(withTranslation()(FormWrapper));
