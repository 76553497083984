import { useMutation } from "react-query";
import store from "store";

const { Integrations: { Apaleo } } = store;

export const useConnectPropertyAction = () => useMutation(({ action, apaleoProperty, channexProperty, channexGroup, chosenRooms, appName }) => {
  if (action === "connectExistingProperty") {
    return Apaleo.connectExistingProperty({ apaleoProperty, channexProperty, appName });
  }

  return Apaleo.createProperty({ apaleoProperty, channexGroup, chosenRooms, appName });
});
