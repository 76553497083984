import React from "react";

import ChannexDrawer from "drawers/channex_drawer";

import DrawerFormWrapper from "components/drawer_form_wrapper";

import { EditWhiteLabelDomainForm } from "./edit_form/edit_form";

export function EditDrawer({ visible, whiteLabelDomain, onClose }) {
  const title = "Edit White Label Domain";

  return (
    <ChannexDrawer title={title} visible={visible} onClose={onClose}>
      <DrawerFormWrapper withActions>
        <EditWhiteLabelDomainForm values={whiteLabelDomain} closeDrawer={onClose} />
      </DrawerFormWrapper>
    </ChannexDrawer>
  );
}
