import React from "react";
import { useTranslation } from "react-i18next";

import SectionHeader from "../../../../../../_common/section_header/section_header";
import Text from "../../../../../../_common/text";

import Feedback from "./feedback";

export default function BookingCom({ review }) {
  const { t } = useTranslation();

  const { attributes: { content } } = review;

  const isFeedbackPresent = !!content;

  return (
    <div>
      {isFeedbackPresent && (
        <Feedback review={review} />
      )}

      {!isFeedbackPresent && (
        <div>
          <SectionHeader header={t("reviews_page:feedback:public_feedback")} />
          <Text disabled>{t("reviews_page:feedback:no_content")}</Text>
        </div>
      )}
    </div>
  );
}
