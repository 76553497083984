import React from "react";
import { useTranslation } from "react-i18next";

import Container from "../container";

import CrudTable from "./crud_table";

export const ReactQueryCrudTable = (props) => {
  const { t } = useTranslation();

  return (
    <Container>
      <CrudTable
        emptyMessage={t("generic:empty_message")}
        showCreateMessage
        {...props}
      />
    </Container>
  );
};
