import React, { Component } from "react";

import countries from "config/constants/countries";

import FormSelect from "components/forms/inputs/form_select";

import alphabetSort from "utils/alphabet_sort";

class FormCountrySelect extends Component {
  state = {
    options: [],
  };

  componentDidMount() {
    this.buildCountryOptions();
  }

  buildCountryOptions = () => {
    const options = Object.values(countries)
      .map((el) => {
        return {
          value: el[2],
          representation: `${el[0]} (${el[2]})`,
        };
      })
      .sort(alphabetSort("representation"));

    this.setState({ options });
  };

  render() {
    const { options } = this.state;

    return <FormSelect {...this.props} options={options} />;
  }
}

export default FormCountrySelect;
