import React from "react";

const EMPTY_VALUE_PLACEHOLDER = "-";

export default function ({ value, currency }) {
  const formattedCurrency = currency ? ` ${currency}` : "";
  const content = value !== undefined ? `${value}${formattedCurrency}` : EMPTY_VALUE_PLACEHOLDER;

  return <span>{content}</span>;
}
