import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Form, Row } from "antd";
import _ from "lodash";
import store from "store";

import SubmitButton from "components/forms/buttons/submit_button";
import GlobalErrors from "components/forms/global_errors";
import FormCheckbox from "components/forms/inputs/form_checkbox";
import Loading from "components/loading";

import classifyApiErrors from "utils/classify_api_errors";
import getSortedArray from "utils/get_sorted_array";
import parseApiErrors from "utils/parse_api_errors";

import styles from "./shared.module.css";

const { Applications, PropertyUsers } = store;

export default function ChannexPCISettings({ installation, onClose }) {
  const { t } = useTranslation();
  const [users, setUsers] = useState(null);
  const [settings, setSettings] = useState(null);
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [errors, setErrors] = useState(null);
  const loading = !(users !== null);
  const { propertyId } = installation;

  useEffect(() => {
    if (!loading && settings === null) {
      setSettings(installation.settings || {});
    }
  }, [loading, settings, installation, users]);

  useEffect(() => {
    const loadUsers = () => {
      PropertyUsers.all(propertyId).then((result) => {
        setUsers(getSortedArray(result, "name"));
      });
    };

    loadUsers();
  }, [propertyId]);

  const submit = () => {
    setSaveInProgress(true);

    Applications.update({
      ...installation,
      settings,
    })
      .then(onClose)
      .catch((error) => {
        if (!error.isValidationError) {
          throw error;
        }

        const parsedErrors = parseApiErrors(error);
        const { globalErrors } = classifyApiErrors(parsedErrors, []);

        setErrors(globalErrors);
      })
      .finally(() => setSaveInProgress(false));
  };

  const handleUserChange = (userId) => {
    return (fieldValue) => {
      settings[userId] = fieldValue;
      setSettings({ ...settings });
    };
  };

  if (loading || settings === null) {
    return <Loading />;
  }

  return (
    <div>
      <Form>
        <GlobalErrors errors={errors} />

        <Row className={styles.columnHeaders}>
          <Col span={24}>
            {t("applications_page:channex_pci:users_with_access")}
          </Col>
        </Row>

        {users.map((user) => (
          <div key={user.id}>
            <Row>
              <Col span={1} className={styles.arrow}>
                <FormCheckbox
                  name={`user_code[${user.id}]`}
                  defaultValue={settings[user.id] || false}
                  onChange={handleUserChange(user.id)}
                />
              </Col>
              <Col span={23} className={styles.roomTitle}>
                {user.name} ({user.email})
              </Col>
            </Row>
          </div>
        ))}
      </Form>

      <div className={styles.actions}>
        <SubmitButton onClick={submit} loading={saveInProgress}>
          {t("applications_page:channex_pci:save")}
        </SubmitButton>
      </div>
    </div>
  );
}
