import React from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import { modal } from "app";
import i18n from "i18next";

const { Text } = Typography;

export const removeMenuItem = (options = {}) => {
  const { t } = i18n;

  const {
    key = "actions_menu_remove",
    text = t("general:action:remove"),
    icon = <DeleteOutlined />,
    title = t("general:remove_dialog:title"),
    content = (
      <div data-cy="modal_content">
        {t("general:remove_dialog:description")}
      </div>
    ),
    danger = true,
    disabled = false,
    confirm = true,
    onRemove,
  } = options;

  const type = danger ? "danger" : null;

  return {
    key,
    disabled,
    onClick: () => {
      if (!confirm) {
        return onRemove();
      }

      modal.confirm({
        title,
        content,
        okButtonProps: {
          "data-cy": "modal_ok",
        },
        onOk: onRemove,
      });
    },
    label: (
      <div data-cy={key}>
        <Text type={type}>
          {icon} {text}
        </Text>
      </div>
    ),
  };
};
