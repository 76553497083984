import { convertToCamelCase } from "../utils/case_converter";

let storage;
let transport;

const SETTINGS_ENDPOINT = "white_label/settings";
const AGREEMENT_ENDPOINT = "white_label/agreement_content";

export default class App {
  constructor(container) {
    storage = container.storage;
    transport = container.transport;
  }

  loadAppSettings = () => {
    return transport.send("GET", SETTINGS_ENDPOINT).then(({ data }) => {
      const settings = convertToCamelCase(data.attributes);

      storage.setAppSettings(settings);
    });
  };

  setAppMode = (newMode) => {
    storage.setAppMode(newMode);
  };

  loadLatestAgreementContent = () => {
    return transport.send("GET", AGREEMENT_ENDPOINT);
  };
}
