import { useQuery } from "react-query";
import store from "store";

const { App } = store;

export default function useLatestAgreementContent(options = {}) {
  return useQuery("latest_agreement_content", () => App.loadLatestAgreementContent(), {
    ...options,

    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    cacheTime: 0,
  });
}
