const flattenPerPersonRates = (ratePlans) => {
  if (!ratePlans) {
    return ratePlans;
  }

  return Object.values(ratePlans).reduce((acc, ratePlan) => {
    if (ratePlan.sell_mode !== "per_person") {
      acc[ratePlan.id] = ratePlan;

      return acc;
    }

    ratePlan.options.forEach((option) => {
      const updatedOption = { ...option, is_primary: true };
      const newRate = { ...ratePlan, ...updatedOption, options: [updatedOption] };
      const isDerivedPerPersonRate = newRate.inherit_rate && !newRate.channel_id;

      if (isDerivedPerPersonRate) {
        newRate.parent_rate_plan_id = ratePlan.id;
      }

      acc[newRate.id] = newRate;
    });

    return acc;
  }, {});
};

export default flattenPerPersonRates;
