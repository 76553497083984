import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import classnames from "classnames";

import MappingStatus from "components/mapping/mapping_status";

import MappingDeleteButton from "./mapping_row_delete_button";
import MappingRowLoader from "./mapping_row_loader";
import PrimaryOccToggle from "./primary_occ_toggle";

import styles from "./mapping_row.module.css";

const ROOM_STYLES = {
  row: styles.roomRow,
  title: styles.roomTitle,
};

const RATE_STYLES = {
  row: styles.rateRow,
  title: styles.rateTitle,
};

export default function MappingRow({
  className,
  type,
  title,
  action,
  loading,
  disabled,
  isMapped,
  isError,
  isPrimary,
  onDelete,
  onPrimaryOccToggle,
}) {
  const [activeStyleSet, setActiveStyleSet] = useState(ROOM_STYLES);

  useEffect(() => {
    let styleSet = type === "rate" ? RATE_STYLES : ROOM_STYLES;

    if (disabled) {
      styleSet = { ...styleSet, row: [styleSet.row, styles.disabledRow].join(" ") };
    }

    setActiveStyleSet(styleSet);
  }, [type, disabled]);

  const rowClassName = classnames(activeStyleSet.row, className, isError && styles.mappingRowError);

  const rateToggleClass = isPrimary
    ? styles.primarySelectActive
    : styles.primarySelect;

  const isToggable = isMapped && type === "rate";

  return (
    <Row className={rowClassName} data-cy="mapping_row">
      <Col span={isToggable ? 9 : 11} className={activeStyleSet.title}>
        {title}
      </Col>
      {isToggable && (
        <Col span={2} className={rateToggleClass}>
          <PrimaryOccToggle
            isPrimary={isPrimary}
            onClick={onPrimaryOccToggle}
          />
        </Col>
      )}
      <Col span={1}>
        <MappingStatus isError={isError} />
      </Col>
      <Col span={11} className={styles.mappedRateCol}>
        {action}
      </Col>
      <Col span={1}>
        {loading ? (
          <MappingRowLoader />
        ) : (
          <MappingDeleteButton disabled={disabled} visible={isMapped} onClick={onDelete} />
        )}
      </Col>
    </Row>
  );
}
