import { useTranslation } from "react-i18next";

import Flexbox from "components/flexbox";

import { formatValue } from "../format_value";

import { Card } from "./card";

export const Totals = ({ isLoading, data, currency }) => {
  const { t } = useTranslation();

  return (
    <Flexbox flexDirection="column" style={{ padding: "24px 36px" }}>
      <Flexbox style={{ padding: "0 0 24px 0" }} flexWrap="wrap">
        <Card title={`${t("reports:labels:revenue")} (${currency})`} isLoading={isLoading} value={data?.totalRevenue} />
        <Card title={t("reports:labels:reservations")} isLoading={isLoading} value={formatValue(data?.reservationsCount)} />
        <Card title={t("reports:labels:room_nights")} isLoading={isLoading} value={formatValue(data?.roomNightsCount)} />
        <Card title={t("reports:labels:cancellations")} isLoading={isLoading} value={formatValue(data?.cancelledReservationsCount)} />
      </Flexbox>
      <Flexbox flexWrap="wrap">
        <Card title={`${t("reports:labels:avg_daily_rate")} (${currency})`} isLoading={isLoading} value={data?.avgDailyRate} />
        <Card title={t("reports:labels:avg_length_of_stay")} isLoading={isLoading} value={data?.avgLengthOfStay} />
        <Card title={t("reports:labels:avg_lead_time")} isLoading={isLoading} value={data?.avgLeadTime} />
        <Card />
      </Flexbox>
    </Flexbox>
  );
};
