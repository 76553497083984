import React from "react";

import Text from "../../../../../../../_common/text";

import styles from "./feedback.module.css";

const POSITIVE_ICON = (
  <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 0a9 9 0 0 0-9 9 9 9 0 0 0 9 9 9 9 0 0 0 9-9 9 9 0 0 0-9-9zm5.469 14.469a7.694 7.694 0 0 1-2.46 1.657A7.66 7.66 0 0 1 9 16.734a7.69 7.69 0 0 1-5.469-2.264 7.694 7.694 0 0 1-1.657-2.459A7.675 7.675 0 0 1 1.266 9 7.69 7.69 0 0 1 3.53 3.531a7.694 7.694 0 0 1 2.459-1.657A7.675 7.675 0 0 1 9 1.266a7.69 7.69 0 0 1 5.469 2.264c.71.71 1.269 1.538 1.657 2.459.404.954.608 1.967.608 3.011a7.69 7.69 0 0 1-2.265 5.469zm-3.214-4.534a.632.632 0 0 0-.633.633c0 .895-.727 1.622-1.622 1.622a1.624 1.624 0 0 1-1.622-1.622.632.632 0 1 0-1.266 0A2.891 2.891 0 0 0 9 13.456a2.891 2.891 0 0 0 2.888-2.888.632.632 0 0 0-.633-.633zM3.414 6.722a1.055 1.055 0 1 0 2.109 0 1.055 1.055 0 0 0-2.11 0zm9.058 0a1.055 1.055 0 1 0 2.11 0 1.055 1.055 0 0 0-2.11 0z" fill="#000" fillRule="nonzero" opacity=".65" />
  </svg>
);

const NEGATIVE_ICON = (
  <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 0a9 9 0 0 0-9 9 9 9 0 0 0 9 9 9 9 0 0 0 9-9 9 9 0 0 0-9-9zm5.469 14.469a7.694 7.694 0 0 1-2.46 1.657A7.66 7.66 0 0 1 9 16.734a7.69 7.69 0 0 1-5.469-2.264 7.694 7.694 0 0 1-1.657-2.459A7.675 7.675 0 0 1 1.266 9 7.69 7.69 0 0 1 3.53 3.531a7.694 7.694 0 0 1 2.459-1.657A7.675 7.675 0 0 1 9 1.266a7.69 7.69 0 0 1 5.469 2.264c.71.71 1.269 1.538 1.657 2.459.404.954.608 1.967.608 3.011a7.69 7.69 0 0 1-2.265 5.469zm-3.214-1.013a.632.632 0 0 1-.633-.633c0-.894-.727-1.622-1.622-1.622-.895 0-1.622.728-1.622 1.622a.632.632 0 1 1-1.266 0A2.891 2.891 0 0 1 9 9.935a2.891 2.891 0 0 1 2.888 2.888c0 .35-.283.633-.633.633zM3.414 6.722a1.055 1.055 0 1 0 2.109 0 1.055 1.055 0 0 0-2.11 0zm9.058 0a1.055 1.055 0 1 0 2.11 0 1.055 1.055 0 0 0-2.11 0z" fill="#000" fillRule="nonzero" opacity=".65" />
  </svg>
);

export default function Feedback({ review }) {
  const { attributes: { rawContent } } = review;

  const { headline, negative, positive } = rawContent || {};

  return (
    <div>
      <div className={styles.feedbackHeadline}>{headline ? `“${headline}”` : "N/A"}</div>
      <div className={styles.feedbackBlock}>
        <div className={styles.feedbackBlock__icon}>{POSITIVE_ICON}</div>
        <Text>{positive || "N/A"}</Text>
      </div>
      <div className={styles.feedbackBlock}>
        <div className={styles.feedbackBlock__icon}>{NEGATIVE_ICON}</div>
        <Text>{negative || "N/A"}</Text>
      </div>
    </div>
  );
}
