import currencies from "world-currencies";

import { formatNumber } from "../../../components/inventory/utils/format_number";

export const formatValue = (value, currency) => {
  if (value === null || value === undefined) {
    return 0;
  }

  const currencySymbol = currencies[currency]?.units?.major?.symbol;
  const formattedNumber = formatNumber(Number(value));

  const formattedValue = currencySymbol ? `${formattedNumber}${currencySymbol}` : formattedNumber;

  return formattedValue;
};
