import React from "react";
import { useTranslation } from "react-i18next";

import SubmitButton from "components/forms/buttons/submit_button";
import GlobalErrors from "components/forms/global_errors";
import FormInput from "components/forms/inputs/hook_form/form_input";
import FormInputNumber from "components/forms/inputs/hook_form/form_input_number";
import FormSelect from "components/forms/inputs/hook_form/form_select";
import BottomActions from "components/forms/layout/drawer/bottom_actions/bottom_actions";
import Legend from "components/forms/layout/legend/legend";
import useAppForm from "components/hook_form/use_app_form";

import CANCELLATION_POLICY_CATEGORIES from "./cancellation_policy_categories";
import DerivedAirbnbRatePlanInput from "./derived_airbnb_rate_plan_input";
import useCreateAction from "./use_create_action";
import useUpdateAction from "./use_update_action";
import validationSchema from "./validation_schema";
import { fromApi, toApi } from "./value_transformations";

export default function AirBnbRatePlanForm({ channelId, airBnbRatePlans, value, onFinish }) {
  const { t } = useTranslation();

  const { mutateAsync: create, isLoading: isCreateLoading } = useCreateAction();
  const { mutateAsync: update, isLoading: isUpdateLoading } = useUpdateAction();

  const fromApiValue = fromApi(value);

  const { handleSubmit, errors, control, resetField, watch } = useAppForm({
    validationSchema,
    defaultValue: fromApiValue,
    fieldNames: ["name", "cancellationPolicyCategory", "minLengthOfStay", "maxLengthOfStay", "minBookingLeadTimeHours", "maxBookingLeadTimeHours", "mode"],
    submitHandler: async (formValue) => {
      const toApiValue = toApi(formValue);

      const submit = toApiValue.id ? update : create;

      const result = await submit({
        channelId,
        attrs: toApiValue,
      });

      onFinish(result.data.attributes);
    },
  });

  const mode = watch("mode");

  return (
    <div>
      <div>
        <GlobalErrors hookForm errors={errors} />

        <FormInput
          name="name"
          label="Name"
          errors={errors?.name?.message}
          control={control}
        />
        <FormSelect
          name="cancellationPolicyCategory"
          label="Cancellation Policy Category"
          errors={errors?.cancellationPolicyCategory?.message}
          options={CANCELLATION_POLICY_CATEGORIES}
          control={control}
        />
        <FormInputNumber
          name="minLengthOfStay"
          label="Min Length of Stay"
          errors={errors?.minLengthOfStay?.message}
          control={control}
        />
        <FormInputNumber
          name="maxLengthOfStay"
          label="Max Length of Stay"
          errors={errors?.maxLengthOfStay?.message}
          control={control}
        />
        <FormInputNumber
          name="minBookingLeadTimeHours"
          label="Min Booking Lead Time Hours"
          errors={errors?.minBookingLeadTimeHours?.message}
          control={control}
        />
        <FormInputNumber
          name="maxBookingLeadTimeHours"
          label="Max Booking Lead Time Hours"
          errors={errors?.maxBookingLeadTimeHours?.message}
          control={control}
        />

        <FormSelect
          name="mode"
          label="Rate plan mode"
          errors={errors?.mode?.message}
          options={[{ value: "derived", label: "Derived" }, { value: "manual", label: "Manual" }]}
          control={control}
        />
        {mode === "derived" && (
          <>
            <Legend>Derived Rate Plan</Legend>
            <DerivedAirbnbRatePlanInput
              name="derivedRatePlanData"
              errors={errors?.derivedRatePlanData}
              airBnbRatePlans={airBnbRatePlans}
              resetField={resetField}
              control={control}
            />
          </>
        )}
      </div>

      <BottomActions>
        <SubmitButton onClick={() => { handleSubmit(); }} loading={isCreateLoading || isUpdateLoading}>
          {t("general:action:save")}
        </SubmitButton>
      </BottomActions>
    </div>
  );
}
