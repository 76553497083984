import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import ChannexDrawer from "drawers/channex_drawer";

import IssueView from "components/issue_view";

class IssueDrawerControlled extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired,
    issueId: PropTypes.string,
    onClose: PropTypes.func.isRequired,
  };

  render() {
    const { t, visible, issueId, onIgnore, onResolve, onClose } = this.props;

    return (
      <ChannexDrawer title={t("issues_drawer:header")} visible={visible} onClose={onClose}>
        {({ handleCloseFromContent }) => (
          <IssueView
            issueId={issueId}
            onIgnore={onIgnore}
            onResolve={onResolve}
            onClose={handleCloseFromContent}
          />
        )}
      </ChannexDrawer>
    );
  }
}

export default withTranslation()(IssueDrawerControlled);
