import React from "react";
import { useTranslation } from "react-i18next";

import Placeholder from "components/no_data_placeholder";

import styles from "./non_selected_property_placeholder.module.css";

export default function NonSelectedPropertyPlaceholder() {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <Placeholder
        className={styles.placeholder}
        emptyMessage={<div>{t("reviews_page:select_property_to_display")}</div>}
      />
    </div>
  );
}
