import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Form, Typography } from "antd";

import ratePlanDerivedOptions from "config/constants/inventory/rate_plan_derived_options";

import combineRoomTypesWithRatePlans from "data/utils/combine_room_types_with_rate_plans";
import filterChannelRatePlans from "data/utils/filter_channel_rate_plans/filter_channel_rate_plans";
import groupRatePlansByRoomTypeId from "data/utils/group_rate_plans_by_room_type_id/group_rate_plans_by_room_type_id";

import SubmitButton from "components/forms/buttons/submit_button";
import DateRanges from "components/inventory/bulk_update/date_ranges";
import RestrictionFields from "components/inventory/bulk_update/restriction_fields";
import RateSelector from "components/rate_plans/rate_selector";

import dayjs from "utils/dayjs";
import validateRestriction from "utils/validate_restriction";

import bulk_update_styles from "./inventory_bulk_update.module.css";
import styles from "styles/form_in_drawer.module.css";

const { Text } = Typography;
const DAY_VALUES = ["mo", "tu", "we", "th", "fr", "sa", "su"];
const DATE_FORMAT = "YYYY-MM-DD";

const DEFAULT_STATE = {
  loading: false,
  model: {
    rate: "0",
    stop_sell: false,
    stop_sell_manual: false,
    closed_to_arrival: false,
    closed_to_departure: false,
    min_stay_arrival: "1",
    min_stay_through: "1",
    max_stay: "0",
  },
  dateRanges: [[undefined, undefined]],
  dateWeekdays: [[true, true, true, true, true, true, true]],
  allowChange: {
    rate: false,
    stop_sell: false,
    stop_sell_manual: false,
    closed_to_arrival: false,
    closed_to_departure: false,
    min_stay_arrival: false,
    min_stay_through: false,
    max_stay: false,
  },
  allSelected: false,
  allIndeterminate: false,
  selectedRoomTypes: {},
  selectedRatePlans: {},
  isNonUpdatableRatePlanMessageShown: false,
};

class InventoryBulkUpdate extends Component {
  static propTypes = {
    isMobile: PropTypes.bool,
    t: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmitBulkUpdate: PropTypes.func.isRequired,
    roomTypes: PropTypes.array,
    rateOptionsById: PropTypes.object,
    defaultCurrentDate: PropTypes.instanceOf(Date),
    minStayType: PropTypes.string,
  };

  state = { ...DEFAULT_STATE };

  onSave = () => {
    const { onSubmitBulkUpdate, onClose } = this.props;

    this.setState({
      loading: true,
    });

    const changes = this.getAffectedRestrictions();
    const models = this.generateModels(changes);

    onSubmitBulkUpdate(models, onClose);
  };

  getAffectedRestrictions = () => {
    const { allowChange, model } = this.state;
    const changes = [];

    Object.keys(allowChange).forEach((restriction) => {
      const normalizedRestriction = restriction === "stop_sell_manual" ? "stop_sell" : restriction;
      if (allowChange[restriction]) {
        changes.push([normalizedRestriction, model[restriction]]);
      }
    });

    return changes;
  };

  getSelectedDays = (rangeIndex) => {
    const { dateWeekdays } = this.state;

    const weekdays = dateWeekdays[rangeIndex];

    return DAY_VALUES.filter((item, index) => weekdays.find((weekdayItem, weekdayIndex) => {
      return weekdayIndex === index;
    }));
  };

  generateModels = (changes) => {
    const models = [];
    const { rateOptionsById } = this.props;
    const { selectedRatePlans, dateRanges } = this.state;
    const changedRatePlans = Object.keys(selectedRatePlans).filter(
      (el) => selectedRatePlans[el] === true,
    );

    const changesByRestriction = changes.reduce((acc, change) => {
      acc[change[0]] = change[1];
      return acc;
    }, {});

    dateRanges.forEach((range, rangeIndex) => {
      const days = this.getSelectedDays(rangeIndex);
      const date_from = dayjs(dateRanges[rangeIndex][0]).format(DATE_FORMAT);
      const date_to = dayjs(dateRanges[rangeIndex][1]).format(DATE_FORMAT);

      changedRatePlans.forEach((ratePlanId) => {
        models.push({
          property_id: rateOptionsById[ratePlanId].property_id,
          rate_plan_id: ratePlanId,
          date_from,
          date_to,
          days,
          ...changesByRestriction,
        });
      });
    });

    return models;
  };

  onAddRange = (event) => {
    event.preventDefault();
    const { dateRanges, dateWeekdays } = this.state;

    this.setState({
      dateRanges: [...dateRanges, [undefined, undefined]],
      dateWeekdays: [...dateWeekdays, [true, true, true, true, true, true, true]],
    });
  };

  onRemoveRange = (indexForRemove) => {
    return (event) => {
      event.preventDefault();
      const { dateRanges } = this.state;

      this.setState({
        dateRanges: dateRanges.filter((el, index) => index !== indexForRemove),
      });
    };
  };

  onChangeAllowedRestriction = (restriction) => {
    return () => {
      const { allowChange } = this.state;

      this.setState({
        allowChange: {
          ...allowChange,
          [restriction]: !allowChange[restriction],
        },
      });
    };
  };

  onFieldChange = (restriction) => {
    return (event) => {
      const { model } = this.state;
      let value;

      if (typeof event === "boolean") {
        value = event;
      } else {
        switch (event.target.type) {
          case "number":
            value = event.target.value;
            break;
          case "checkbox":
            value = event.target.checked;
            break;
          default:
            break;
        }
      }

      this.setState({
        model: {
          ...model,
          [restriction]: value,
        },
      });
    };
  };

  onDateChange = (indexForChange) => {
    return (value) => {
      const { dateRanges } = this.state;
      const newDateRanges = [...dateRanges];
      newDateRanges[indexForChange] = value;
      this.setState({
        dateRanges: newDateRanges,
      });
    };
  };

  onWeekdayChange = (rangeIndex, value) => {
    const { dateWeekdays } = this.state;
    const newDateWeekdays = [...dateWeekdays];
    newDateWeekdays[rangeIndex] = value;

    this.setState({
      dateWeekdays: newDateWeekdays,
    });
  };

  getIsRatePlanValueNonUpdatable = (selectedRatePlans) => {
    const { rateOptionsById } = this.props;
    const selectedRatePlansIds = Object.keys(selectedRatePlans).filter(
      (key) => selectedRatePlans[key],
    );

    return selectedRatePlansIds.some((i) => {
      const selectedRate = rateOptionsById[i];
      return ratePlanDerivedOptions.some((item) => selectedRate[item]);
    });
  };

  onAllToggle = (event) => {
    const value = event.target.checked;
    const { roomTypes } = this.props;
    const newSelectedRatePlans = {};
    const newSelectedRoomTypes = {};

    roomTypes.forEach((roomType) => {
      newSelectedRoomTypes[roomType.id] = {
        selected: value,
        indeterminate: false,
      };

      roomType.ratePlans.forEach((ratePlan) => {
        newSelectedRatePlans[ratePlan.id] = value;
      });
    });

    const isNonUpdatableRatePlanMessageShown = this.getIsRatePlanValueNonUpdatable(
      newSelectedRatePlans,
    );

    this.setState({
      selectedRatePlans: newSelectedRatePlans,
      selectedRoomTypes: newSelectedRoomTypes,
      allSelected: value,
      allIndeterminate: false,
      isNonUpdatableRatePlanMessageShown,
    });
  };

  onRoomTypeToggle = (roomTypeId) => {
    return (event) => {
      const { selectedRatePlans, selectedRoomTypes } = this.state;
      const { roomTypes } = this.props;
      const roomType = roomTypes.filter((el) => el.id === roomTypeId)[0];

      const newSelectedRoomTypes = {
        ...selectedRoomTypes,
        [roomTypeId]: {
          selected: event.target.checked,
          indeterminate: false,
        },
      };

      const newSelectedRatePlans = {
        ...selectedRatePlans,
      };

      roomType.ratePlans.forEach((el) => {
        newSelectedRatePlans[el.id] = event.target.checked;
      });

      const newAllSelected = roomTypes
        .map((el) => (newSelectedRoomTypes[el.id] ? newSelectedRoomTypes[el.id].selected : false))
        .reduce((acc, el) => {
          return acc && el;
        }, true);

      const newAllIndeterminate = roomTypes.length
          !== roomTypes
            .map((el) => (newSelectedRoomTypes[el.id] ? newSelectedRoomTypes[el.id].selected : false))
            .filter((el) => el === newAllSelected).length
        || roomTypes
          .map((el) => (newSelectedRoomTypes[el.id] ? newSelectedRoomTypes[el.id].indeterminate : false))
          .reduce((acc, el) => {
            return acc || el;
          }, false);

      const isNonUpdatableRatePlanMessageShown = this.getIsRatePlanValueNonUpdatable(
        newSelectedRatePlans,
      );

      this.setState({
        selectedRatePlans: newSelectedRatePlans,
        selectedRoomTypes: newSelectedRoomTypes,
        allSelected: newAllSelected,
        allIndeterminate: newAllIndeterminate,
        isNonUpdatableRatePlanMessageShown,
      });
    };
  };

  onRatePlanToggle = (ratePlanId, roomTypeId) => {
    return (event) => {
      const { selectedRatePlans, selectedRoomTypes } = this.state;
      const { roomTypes } = this.props;
      const roomType = roomTypes.filter((el) => el.id === roomTypeId)[0];

      const newSelectedRatePlans = {
        ...selectedRatePlans,
        [ratePlanId]: event.target.checked,
      };

      const ratePlanSelections = roomType.ratePlans.map(
        (el) => newSelectedRatePlans[el.id] || false,
      );

      const roomTypeSelected = ratePlanSelections.reduce((acc, el) => {
        return acc && el;
      }, true);

      const newSelectedRoomTypes = {
        ...selectedRoomTypes,
        [roomTypeId]: {
          selected: roomTypeSelected,
          indeterminate:
            roomType.ratePlans.length
            !== ratePlanSelections.filter((el) => el === roomTypeSelected).length,
        },
      };

      const newAllSelected = roomTypes
        .map((el) => (newSelectedRoomTypes[el.id] ? newSelectedRoomTypes[el.id].selected : false))
        .reduce((acc, el) => {
          return acc && el;
        }, true);

      const newAllIndeterminate = roomTypes.length
          !== roomTypes
            .map((el) => (newSelectedRoomTypes[el.id] ? newSelectedRoomTypes[el.id].selected : false))
            .filter((el) => el === newAllSelected).length
        || roomTypes
          .map((el) => (newSelectedRoomTypes[el.id] ? newSelectedRoomTypes[el.id].indeterminate : false))
          .reduce((acc, el) => {
            return acc || el;
          }, false);

      const isNonUpdatableRatePlanMessageShown = this.getIsRatePlanValueNonUpdatable(
        newSelectedRatePlans,
      );

      this.setState({
        selectedRatePlans: newSelectedRatePlans,
        selectedRoomTypes: newSelectedRoomTypes,
        allSelected: newAllSelected,
        allIndeterminate: newAllIndeterminate,
        isNonUpdatableRatePlanMessageShown,
      });
    };
  };

  validate = () => {
    const { allowChange, model } = this.state;
    const errors = {};

    const valid = Object.keys(allowChange)
      .map((restriction) => {
        if (allowChange[restriction]) {
          const [isValid, error] = validateRestriction(restriction, model[restriction]);
          errors[restriction] = error;
          return isValid;
        }
        return true;
      })
      .reduce((acc, el) => {
        return acc && el;
      }, true);

    return [valid, errors];
  };

  render() {
    const { t, roomTypesNotChannelRatePlans, isMobile, defaultCurrentDate, minStayType } = this.props;

    const {
      loading,
      dateRanges,
      dateWeekdays,
      allowChange,
      selectedRatePlans,
      selectedRoomTypes,
      allIndeterminate,
      allSelected,
      isNonUpdatableRatePlanMessageShown,
    } = this.state;

    const [isValid, errors] = this.validate();
    return (
      <Form onFinish={this.onSave}>
        <DateRanges
          t={t}
          isMobile={isMobile}
          dateRanges={dateRanges}
          dateWeekdays={dateWeekdays}
          onDateChange={this.onDateChange}
          onRemoveRange={this.onRemoveRange}
          onWeekdayChange={this.onWeekdayChange}
          onAddRange={this.onAddRange}
          defaultCurrentDate={defaultCurrentDate}
        />

        <RestrictionFields
          t={t}
          allowChange={allowChange}
          onChangeAllowedRestriction={this.onChangeAllowedRestriction}
          onFieldChange={this.onFieldChange}
          errors={errors}
          minStayType={minStayType}
        />

        <RateSelector
          roomTypes={roomTypesNotChannelRatePlans}
          allSelected={allSelected}
          allIndeterminate={allIndeterminate}
          selectedRoomTypes={selectedRoomTypes}
          selectedRatePlans={selectedRatePlans}
          onAllToggle={this.onAllToggle}
          onRoomTypeToggle={this.onRoomTypeToggle}
          onRatePlanToggle={this.onRatePlanToggle}
        />

        {isNonUpdatableRatePlanMessageShown && (
          <div className={bulk_update_styles.nonUpdatableMessage}>
            <Text type="danger">
              {t("inventory_page:bulk_update:non_updatable_rate_plan_message")}
            </Text>
          </div>
        )}
        <div className={styles.actions}>
          <SubmitButton loading={loading} disabled={!isValid}>
            {t("inventory_page:bulk_update:submit_button")}
          </SubmitButton>
        </div>
      </Form>
    );
  }
}

const extractRateOptions = (entities) => {
  const records = [];
  const options = [];

  entities
    .filter((rate) => !rate.ui_read_only && !rate.channel)
    .forEach((ratePlan) => {
      ratePlan.options.forEach((option) => {
        options.push({
          id: option.id,
          property_id: ratePlan.property_id,
          parent_rate_plan_id: ratePlan.id,
          is_option: true,
          occupancy: option.occupancy,
          title: ratePlan.title,
          options: [{ ...option, is_primary: true }],
          room_type_id: ratePlan.room_type_id,
          inherit_availability_offset: option.inherit_availability_offset,
          inherit_closed_to_arrival: option.inherit_closed_to_arrival,
          inherit_closed_to_departure: option.inherit_closed_to_departure,
          inherit_max_stay: option.inherit_max_stay,
          inherit_min_stay_arrival: option.inherit_min_stay_arrival,
          inherit_min_stay_through: option.inherit_min_stay_through,
          inherit_rate: option.inherit_rate,
          inherit_stop_sell: option.inherit_stop_sell,
        });
      });
    });

  options.forEach((option) => records.push(option));

  return records;
};

const mapStateToProps = ({ ratePlans }, { roomTypes }) => {
  const ratePlansAsList = Object.values(ratePlans);

  const rateOptionsById = extractRateOptions(ratePlansAsList).reduce(
    (acc, option) => ({ ...acc, [option.id]: option }),
    {},
  );

  const updatedRoomTypes = roomTypes.map((roomType) => {
    const roomRelatedRates = ratePlansAsList.filter((rate) => rate.room_type_id === roomType.id);
    const updatedRatePlans = extractRateOptions(roomRelatedRates);

    return { ...roomType, ratePlans: updatedRatePlans };
  });

  return {
    rateOptionsById,
    roomTypes: updatedRoomTypes,
    roomTypesNotChannelRatePlans: combineRoomTypesWithRatePlans(
      roomTypes,
      groupRatePlansByRoomTypeId(
        filterChannelRatePlans(ratePlans),
      ),
    ),
  };
};

export default withTranslation()(connect(mapStateToProps)(InventoryBulkUpdate));
