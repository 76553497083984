import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DeleteOutlined, DownOutlined } from "@ant-design/icons";
import { Dropdown, Table } from "antd";
import _ from "lodash";
import store from "store";

import Loading from "components/loading";
import User from "components/user/user";

import tableStyles from "styles/tables.module.css";

const { AdminBillingAccounts } = store;

export default function Memberships({ billingAccountId }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [memberships, setMemberships] = useState([]);

  useEffect(
    function loadBillingAccountUsers() {
      AdminBillingAccounts.memberships(billingAccountId).then((response) => {
        setMemberships(response.data.map((el) => el.attributes));
        setLoading(false);
      });
    },
    [billingAccountId],
  );

  const handleRevokeAccessClick = (record) => {
    return (_event) => {
      AdminBillingAccounts.removeMembership(billingAccountId, record.id).then((_response) => {
        setMemberships(_.without(memberships, record));
      });
    };
  };

  const columns = [
    {
      title: t("general:user"),
      key: "avatar",
      render: (_text, record) => {
        return <User size={42} name={record.user.name} email={record.user.email} />;
      },
    },
    {
      title: t("general:role"),
      key: "role",
      render: (_text, record) => {
        return t(`billing_account_drawer:users:roles:${record.role}`);
      },
    },
    {
      title: t("general:actions"),
      key: "action",
      align: "right",
      render: (_text, record) => {
        const items = [{
          key: "billing_account_drawer_users_actions_remove",
          onClick: handleRevokeAccessClick(record),
          label: (
            <div>
              <DeleteOutlined /> {t("billing_account_drawer:users:actions:remove")}
            </div>
          ),
        }];

        return (
          <Dropdown menu={{ items }} trigger={["click"]}>
            <a onClick={(event) => event.preventDefault()}>
              {t("general:actions")} <DownOutlined />
            </a>
          </Dropdown>
        );
      },
    },
  ];

  if (loading) {
    return <Loading />;
  }

  return (
    <Table
      columns={columns}
      className={tableStyles.fullTabTable}
      dataSource={memberships}
      pagination={false}
      showHeader={false}
      rowKey="id"
    />
  );
}
