import React, { Component } from "react";
import { Field } from "formik";
import _ from "lodash";

import FormInputNumber from "components/forms/inputs/form_input_number";
import getFieldErrors from "components/forms/inputs/utils/get_field_errors";

class FormikFormInputNumber extends Component {
  handleFieldChange = (value, field, form) => {
    const { precision, onChange } = this.props;
    // TODO - remove when antd fix input number with 0 precision
    const parsedValue = parseFloat(Number(value)?.toFixed(precision));

    if (onChange) {
      return onChange(parsedValue, field, form);
    }

    return form.setFieldValue(field.name, parsedValue);
  };

  render() {
    const { name, namespace } = this.props;

    const fieldName = namespace ? `${namespace}.${name}` : name;

    return (
      <Field name={fieldName}>
        {({ field, form }) => {
          return (
            <FormInputNumber
              {...this.props}
              {...field}
              errors={getFieldErrors(this.props, form)}
              onChange={(value) => this.handleFieldChange(value, field, form)}
              onBlur={() => form.setFieldTouched(fieldName, true)}
            />
          );
        }}
      </Field>
    );
  }
}

export default FormikFormInputNumber;
