import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Form } from "antd";

import SubmitButton from "components/forms/buttons/submit_button";

import TitleInput from "./inputs/title";
import IdItem from "./items/id";

import styles from "styles/form_in_drawer.module.css";

class GroupForm extends Component {
  render() {
    const { t, id, isSubmitting, handleSubmit } = this.props;

    return (
      <Form onFinish={handleSubmit}>
        {id && <IdItem id={id} />}
        <TitleInput />

        <div className={styles.actions}>
          <SubmitButton loading={isSubmitting}>{t("groups_page:submit_button")}</SubmitButton>
        </div>
      </Form>
    );
  }
}

export default withTranslation()(GroupForm);
