import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import ChannexModal from "components/channex_modal";

import styles from "./confirm_unmapped_modal.module.css";

class ConfirmUnmappedModal extends Component {
  getMappingOptionTitles = (param, ids) => {
    const { mappingOptions } = this.props;
    const optionValues = mappingOptions[`${param}_dictionary`].values;

    return ids
      .map((id) => {
        return optionValues.find((v) => v.id === id).title;
      })
      .join(", ");
  };

  render() {
    const { t, onOk, onCancel, unusedMappings, mappingSchema } = this.props;

    return (
      <ChannexModal
        title={t("general:unmapped_items_dialog:title")}
        width={600}
        onOk={onOk}
        onCancel={onCancel}
        visible
      >
        <div className={styles.unmappedMessage}>
          {t("general:unmapped_items_dialog:description")}
        </div>
        <div className={styles.unusedMappingsContainer}>
          {Object.entries(unusedMappings).map(([param, ids]) => (
            <div key={param} className={styles.unmappedSection}>
              <div className={styles.paramTitle}>{mappingSchema[param].title}</div>
              <div>{this.getMappingOptionTitles(param, ids)}</div>
            </div>
          ))}
        </div>
      </ChannexModal>
    );
  }
}

export default withTranslation()(ConfirmUnmappedModal);
