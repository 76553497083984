import _ from "lodash";

const getFieldErrors = (props, form) => {
  // captured errors sounds lame, need more specific name. Maybe could take name proerties from addonBefore/addonAfter
  const { name, namespace, capturedErrors } = props;
  const fieldName = namespace ? `${namespace}.${name}` : name;

  if (!Array.isArray(capturedErrors)) {
    const isTouched = _.get(form.touched, fieldName, false);

    return isTouched && form.errors[fieldName];
  }

  const fieldsToCaptureErrors = [...capturedErrors, fieldName].filter((field) => _.get(form.touched, field, false));

  const errorsArray = fieldsToCaptureErrors.reduce((acc, field) => {
    const error = form.errors[field];

    if (!error) {
      return acc;
    }

    return [...acc, error];
  }, []);

  if (!errorsArray.length) {
    return null;
  }

  return errorsArray;
};

export default getFieldErrors;
