import React, { useCallback, useMemo, useState } from "react";
import { Checkbox, Form } from "antd";

import { horizontalFormItemLayout, verticalFormItemLayout } from "config/constants/layouts/form";

import styles from "./form_weekdays_v2.module.css";

const WEEKDAYS = {
  mo: "Mo",
  tu: "Tu",
  we: "We",
  th: "Th",
  fr: "Fr",
  sa: "Sa",
  su: "Su",
};

export const WEEKDAY_VALUES = Object.keys(WEEKDAYS);

const valueToSelectedState = (value) => {
  return Object.keys(WEEKDAYS).reduce((acc, key) => {
    if (!value) {
      return { ...acc, [key]: true };
    }

    return { ...acc, [key]: value.includes(key) };
  }, {});
};

export const FormWeekdaysV2 = ({ name, label, value, errors, disabled, onChange, grouped, view = "horizontal" }) => {
  const [selectedWeekdays, setSelectedWeekdays] = useState(valueToSelectedState(value));

  const validationStatus = useMemo(() => {
    if (!errors) {
      return {};
    }

    return {
      validateStatus: "error",
      help: errors,
    };
  }, [errors]);

  const layout = useMemo(() => {
    if (grouped) {
      return {};
    }

    return view === "horizontal" ? horizontalFormItemLayout : verticalFormItemLayout;
  }, [grouped, view]);

  const handleChange = useCallback((e) => {
    const { checked, value: weekdayValue } = e.target;

    const newSelectedWeekdays = {
      ...selectedWeekdays,
      [weekdayValue]: checked,
    };

    setSelectedWeekdays(newSelectedWeekdays);

    // preserve the order of the weekdays
    const newValue = Object.keys(WEEKDAYS).filter((key) => newSelectedWeekdays[key]);

    onChange(newValue);
  }, [selectedWeekdays, setSelectedWeekdays, onChange]);

  return (
    <Form.Item
      className={styles.weekdaysContainer}
      {...layout}
      {...validationStatus}
      label={label}
      data-cy={`weekdays_${name}`}
    >
      {Object.entries(WEEKDAYS).map(([weekdayValue, weekdayName]) => {
        const checked = selectedWeekdays[weekdayValue];

        return (
          <Checkbox
            data-cy={`day_${weekdayValue}`}
            className={styles.weekday}
            key={weekdayValue}
            checked={checked}
            disabled={disabled}
            value={weekdayValue}
            onChange={handleChange}
          >
            {weekdayName}
          </Checkbox>
        );
      })}
    </Form.Item>
  );
};
