import React, { Component } from "react";
import store from "store";

import Loading from "components/loading";

import withComponentRef from "containers/with_component_ref";

import confirmDirtyStateSave from "utils/confirm_dirty_state_save";
import parseApiErrors from "utils/parse_api_errors";

import events from "../form/events";
import WebhookForm from "../form/form";

const { Webhooks } = store;

class FormWrapper extends Component {
  state = {
    loading: true,
    submitting: false,
    value: {},
    errors: {},
  };

  formRef = React.createRef();

  componentDidMount() {
    const { entityId } = this.props;

    if (entityId) {
      this.load();
    } else {
      this.setState({ loading: false });
    }
  }

  load = () => {
    const { entityId } = this.props;

    Webhooks.find(entityId).then((entity) => {
      const value = { ...entity };

      value.headers = value.headers ? JSON.stringify(value.headers || {}, null, 4) : null;
      value.request_params = value.request_params
        ? JSON.stringify(value.request_params, null, 4)
        : null;
      value.event_mask = value.event_mask === "*" ? events : value.event_mask.split(";");

      this.setState({ value, loading: false });
    });
  };

  handleSubmit = () => {
    const { entityId, onClose, onCreate = () => {} } = this.props;
    const { value } = this.state;

    const submitHandler = entityId ? Webhooks.update : Webhooks.create;

    this.setState({ submitting: true });

    this.formRef.current
      .validate()
      .then(() => {
        const apiValue = { ...value };
        apiValue.headers = apiValue.headers ? JSON.parse(apiValue.headers) : null;
        apiValue.request_params = apiValue.request_params ? JSON.parse(apiValue.request_params) : null;
        apiValue.event_mask = apiValue.event_mask.length === events.length ? "*" : apiValue.event_mask.join(";");

        this.setState({ errors: {} });

        submitHandler({ ...apiValue })
          .then((result) => {
            onCreate(result);
            onClose();
          })
          .catch((error) => {
            if (!error.isValidationError) {
              throw error;
            }

            this.setState({ errors: parseApiErrors(error) });
          })
          .finally(() => {
            this.setState({ submitting: false });
          });
      })
      .catch(() => {
        this.setState({ submitting: false });
      });
  };

  handleChange = (value) => {
    this.setState({ value });
  };

  beforeClose = () => {
    const { current } = this.formRef;

    if (!current) {
      return Promise.resolve();
    }

    const { dirty } = current.formik.current;

    return confirmDirtyStateSave(dirty);
  };

  render() {
    const { value, loading, submitting, errors } = this.state;

    if (loading) {
      return <Loading />;
    }

    return (
      <WebhookForm
        value={value}
        errors={errors}
        submitting={submitting}
        componentRef={this.formRef}
        onChange={this.handleChange}
        onSubmit={this.handleSubmit}
      />
    );
  }
}

export default withComponentRef(FormWrapper);
