import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useExtras } from "drawers/property_manage_extras_drawer/use_extras";

import { FormNestedListSelector } from "components/forms/inputs/form_nested_list_selector";
import Loading from "components/loading";

export const Extras = ({ model, onChange }) => {
  const { t } = useTranslation();

  const propertyId = model.properties[0];
  const extrasValue = model.extras;

  const [value, setValue] = useState(extrasValue);
  const extrasTree = useExtras(propertyId);

  if (extrasTree.isLoading) {
    return <Loading />;
  }

  const tree = [];
  const disabled = [];
  extrasTree.data?.forEach(({ title, extras, ...category }) => {
    const isEmpty = extras.length === 0;

    if (isEmpty) {
      disabled.push(category.id);
    }

    tree.push({
      ...category,
      title: isEmpty ? `${title} - ${t("property_extras:messages:disabled_empty_category")}` : title,
      children: extras,
    });
  });

  return (
    <FormNestedListSelector
      standalone
      value={value}
      disabledValue={disabled}
      tree={tree}
      onChange={(newExtras) => {
        setValue(newExtras);
        onChange({ extras: newExtras });
      }}
    />
  );
};
