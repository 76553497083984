import React, { useCallback, useMemo, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Form, Table } from "antd";

import { horizontalFormItemLayout } from "config/constants/layouts/form";

import alphabetSort from "utils/alphabet_sort";

import FormTableSelectFilterDropdown from "./form_table_select_filter_dropdown";
import FormTableSelectItem from "./form_table_select_item";
import FormTableSelectLabel from "./form_table_select_label";

import styles from "./form_table_select.module.css";

const TABLE_HEIGHT = 240;

const sortItems = (value) => (a, b) => {
  const priorityDiff = value.includes(b.key) - value.includes(a.key);

  if (priorityDiff !== 0) {
    return priorityDiff;
  }

  return alphabetSort("title")(a, b);
};

export default function FormTableSelectInner(props) {
  const [searchQuery, setSearchQuery] = useState("");
  const [dataIndex, setDataIndex] = useState("");
  const { label, title, items, form, field, onChange } = props;

  const handleFilter = useCallback(
    (query, record) => {
      const fieldValueToSort = record[dataIndex];

      if (!fieldValueToSort) {
        return false;
      }

      const formattedFieldValue = fieldValueToSort.toString().toLowerCase();
      const formattedQuery = query.toLowerCase();

      return formattedFieldValue.includes(formattedQuery);
    },
    [dataIndex],
  );

  const handleSearch = useCallback((selectedKeys, newDataIndex) => {
    setSearchQuery(selectedKeys);
    setDataIndex(newDataIndex);
  }, []);

  const handleReset = useCallback(() => setSearchQuery(""), []);

  const getFilterDropdown = useCallback(
    (columnDataIndex) => function (filterDropdownProps) {
      return (
        <FormTableSelectFilterDropdown
          dataIndex={columnDataIndex}
          filterDropdownProps={filterDropdownProps}
          onSearch={handleSearch}
          onReset={handleReset}
        />
      );
    },
    [handleSearch, handleReset],
  );

  const renderTableItem = useCallback(
    (column) => function (text) {
      const isSearched = dataIndex === column;

      return <FormTableSelectItem isSearched={isSearched} searchQuery={searchQuery} text={text} />;
    },
    [searchQuery, dataIndex],
  );

  const columns = useMemo(() => {
    return [
      {
        title,
        key: "title",
        dataIndex: "title",
        render: renderTableItem("title"),
        filterDropdown: getFilterDropdown("title"),
        sorter: alphabetSort("title"),
        sortDirections: ["ascend", "descend"],
        onFilter: handleFilter,
        className: styles.column,
        filterIcon: <SearchOutlined />,
      },
    ];
  }, [title, getFilterDropdown, handleFilter, renderTableItem]);

  const handleSelectionChange = useCallback(
    (newValue) => {
      if (onChange) {
        onChange(newValue, field, form);

        return;
      }

      form.setFieldValue(field.name, newValue);
    },
    [field, form, onChange],
  );

  const sortedItems = useMemo(() => {
    return items.sort(sortItems(field.value));
  }, [items, field]);

  return (
    <Form.Item
      {...horizontalFormItemLayout}
      label={
        <FormTableSelectLabel text={label} total={items.length} selected={field.value?.length} />
      }
      data-cy={`table_select_${field.name}`}
    >
      <Table
        rowSelection={{
          onChange: handleSelectionChange,
          selectedRowKeys: field.value,
        }}
        rowKey="key"
        className={styles.table}
        rowClassName={styles.row}
        dataSource={sortedItems}
        columns={columns}
        pagination={false}
        scroll={{ y: TABLE_HEIGHT }}
      />
    </Form.Item>
  );
}
