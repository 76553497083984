import React from "react";

import Flexbox from "components/flexbox";

import { ExtraValuesMappingEditor } from "./mapping_editors/extra_values_mapping_editor";
import { MappingDirection } from "./mapping_direction";
import { RateMappingRow } from "./rate_mapping_row";
import { RoomMappingRow } from "./room_mapping_row";
import { useMultiOccupancyExtraValuesMapping } from "./use_multi_occupancy_extra_values_mapping";
import { VirtualList } from "./virtual_list";

export const Experimental = ({
  channelTitle,
  roomTypes,
  ratePlans,
  mappings,
  mappingOptions,
  mappingSettings,
  mappingSchema,
  RateMappingEditor = ExtraValuesMappingEditor,
  onChangeSettings,
  onChangeMapping,
}) => {
  const {
    mappingItems,
    handleRoomMappingChange,
    handleRoomMappingDelete,
    handlePrimaryOccChange,
    handleRateMappingChange,
    handleRateMappingDelete,
  } = useMultiOccupancyExtraValuesMapping({
    mappings,
    mappingOptions,
    mappingSettings,
    ratePlans,
    roomTypes,
    onChangeSettings,
    onChangeMapping,
  });

  return (
    <Flexbox flexDirection="column" fullHeight>
      <MappingDirection channelTitle={channelTitle} />
      <Flexbox flexDirection="column">
        <VirtualList
          itemDataCy="mapping_row"
          items={mappingItems}
          renderItem={(mappingItem) => {
            if (mappingItem.type === "room") {
              return (
                <RoomMappingRow
                  mappingItem={mappingItem}
                  onChange={handleRoomMappingChange}
                  onDelete={handleRoomMappingDelete}
                />
              );
            }

            if (mappingItem.type === "rate") {
              return (
                <RateMappingRow
                  mappingItem={mappingItem}
                  mappingSchema={mappingSchema}
                  MappingEditor={RateMappingEditor}
                  onPrimaryOccChange={handlePrimaryOccChange}
                  onChange={handleRateMappingChange}
                  onDelete={handleRateMappingDelete}
                />
              );
            }

            throw new Error(`unsupported mapping item: ${mappingItem}`);
          }}
        />
      </Flexbox>
    </Flexbox>
  );
};

Experimental.MAPPING_TYPE = "ExternalRoomRate";
