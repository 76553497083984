import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import ChannexModal from "components/channex_modal";

import DeletePropertyModalContent from "./delete_property_modal_content";

export default function DeletePropertyModal({ property, visible, onClose, onConfirm }) {
  const { t } = useTranslation();
  const [propertyTitle, setPropertyTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (value) => {
    setPropertyTitle(value);
    setError(null);
  };

  const resetState = () => {
    setPropertyTitle("");
    setError(null);
  };

  const handleConfirm = async () => {
    if (propertyTitle !== property.title) {
      setError(t("properties_page:delete_property_modal:error_message"));

      return;
    }

    try {
      setIsLoading(true);

      await onConfirm(property.id);

      if (onClose) {
        onClose();
      }

      resetState();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ChannexModal
      title={t("general:action:confirm")}
      visible={visible}
      confirmLoading={isLoading}
      onOk={handleConfirm}
      onCancel={onClose}
    >
      <DeletePropertyModalContent
        value={propertyTitle}
        error={error}
        onChange={handleChange}
        onEnter={handleConfirm}
      />
    </ChannexModal>
  );
}
