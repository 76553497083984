import { HOTEL_POLICIES_ADD, HOTEL_POLICIES_DROP, HOTEL_POLICIES_LOAD } from "../constants";

export function hotelPoliciesLoad(storage) {
  return function (records, meta) {
    storage.dispatch({ type: HOTEL_POLICIES_LOAD, payload: { records, meta } });
  };
}

export function hotelPoliciesAdd(storage) {
  return function (hotelPolicy) {
    storage.dispatch({ type: HOTEL_POLICIES_ADD, payload: hotelPolicy });
  };
}

export function hotelPoliciesDrop(storage) {
  return function (hotelPolicy) {
    storage.dispatch({ type: HOTEL_POLICIES_DROP, payload: hotelPolicy });
  };
}
