import { convertToCamelCase, convertToSnakeCase } from "../utils/case_converter";

let transport;

const ENDPOINT = "onboarding";

export default class Onboarding {
  constructor(container) {
    transport = container.transport;
  }

  init(onboardingArguments) {
    const payload = convertToSnakeCase({ onboardingArguments });

    return transport
      .send("POST", ENDPOINT, payload)
      .then(convertToCamelCase)
      .then(({ data }) => data);
  }

  get(id) {
    return transport
      .send("GET", `${ENDPOINT}/${id}`)
      .then(convertToCamelCase)
      .then(({ data }) => data);
  }

  confirm(id) {
    return transport
      .send("POST", `${ENDPOINT}/${id}/confirm`, {})
      .then(convertToCamelCase)
      .then(({ data }) => data);
  }

  createRatePlans(args) {
    const convertArgs = convertToSnakeCase(args);

    return transport
      .send("POST", `${ENDPOINT}/create_rate_plans`, convertArgs)
      .then(({ meta }) => meta);
  }

  updateInventory(args) {
    const convertArgs = convertToSnakeCase(args);

    return transport
      .send("POST", `${ENDPOINT}/update_inventory`, convertArgs)
      .then(({ meta }) => meta);
  }

  decline(id) {
    return transport.send("DELETE", `${ENDPOINT}/${id}/decline`, {}).then(convertToCamelCase);
  }
}
