import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Spin } from "antd";

import styles from "./photo_card_upload_button.module.css";

class PhotoCardUploadButton extends Component {
  render() {
    const { t, loading, className, onClick = () => {} } = this.props;
    const buttonClassName = [styles.photoCardContainer, className].join(" ");

    return (
      <Spin spinning={loading} onClick={onClick}>
        <Button className={buttonClassName}>
          {!loading && (
            <div className={styles.photoCardContent}>
              <PlusCircleOutlined />
              <div>{t("general:action:add_new_photo")}</div>
            </div>
          )}
        </Button>
      </Spin>
    );
  }
}

export default withTranslation()(PhotoCardUploadButton);
