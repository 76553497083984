import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Card } from "antd";
import classnames from "classnames";
import store from "store";
import { getSession } from "store/storage/selectors/session_selector";

import WidgetLoader from "../common/widget_loader";

import Title from "./title";

import announcementsStyles from "./announcements_widget.module.css";
import styles from "styles/dashboard.module.css";

const ENDPOINT = "announcements";

export default function AnnouncementsWidget() {
  const [initialLoading, setInitialLoading] = useState(true);
  const [announcements, setAnnouncements] = useState(null);
  const session = useSelector(getSession);

  const loadData = () => {
    store.http
      ._get(ENDPOINT)
      .then((done) => done.text())
      .then((response) => {
        setInitialLoading(false);
        setAnnouncements(response);
      });
  };

  useEffect(
    function load() {
      loadData();
    },
    [session],
  );

  const cardClassName = classnames(
    styles.dashboard__widget,
    styles.dashboard__widget__borderless,
    announcementsStyles.wrapper,
  );

  return (
    <Card title={<Title />} className={cardClassName}>
      {initialLoading && (
        <div className={announcementsStyles.loader}>
          <WidgetLoader />
        </div>
      )}

      {!initialLoading && (
        <div
          className={announcementsStyles.content}
          dangerouslySetInnerHTML={{ __html: announcements }}
        />
      )}
    </Card>
  );
}
