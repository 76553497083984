import { createSelector } from "reselect";

export const getGroups = (state) => state.groups;

export const getGroupsList = createSelector(getGroups, ({ entities }) => {
  return entities ? Object.values(entities) : null;
});

export const getGroupsOptions = createSelector(getGroups, ({ options }) => {
  if (!Array.isArray(options)) {
    return options;
  }

  return options.sort((a, b) => `${a.title}`.localeCompare(b.title));
});
