import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";

import { buildError } from "./error_mapping/build_error";

const useErrorMessageText = ({ error, propertiesOptionsById }) => {
  const { t } = useTranslation();

  const uiError = buildError(error);
  let errorMsg;

  if (uiError.payload.fieldI18n) {
    uiError.payload.field = t(uiError.payload.fieldI18n);
  }

  if (error.entity === "Property") {
    const property = propertiesOptionsById[error.id];
    const propertyTitle = property.title;

    errorMsg = `${t("general:property")} "${propertyTitle}": ${t(uiError.slug, uiError.payload)}`;
  }

  if (error.entity === "HotelPolicy") {
    errorMsg = `${t("properties_page:policies:policy_label")}: ${t(uiError.slug, uiError.payload)}`;
  }

  if (error.entity === "Channel") {
    errorMsg = `${t("channels_page:form:channel_label")}: ${t(uiError.slug, uiError.payload)}`;
  }

  return errorMsg;
};

export const ErrorMessage = ({ error, propertiesOptionsById, onClick }) => {
  const errorText = useErrorMessageText({ error, propertiesOptionsById });

  const handleClick = useCallback(() => { onClick(error); }, [onClick, error]);

  return (
    <Button style={{ display: "block" }} type="link" onClick={handleClick}>
      {errorText}
    </Button>
  );
};
