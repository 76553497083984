import React from "react";
import { useSelector } from "react-redux";
import { getAppMode } from "store/storage/selectors/session_selector";

import APP_MODES from "config/constants/app_modes";

import styles from "./main.module.css";

export default function Main({ noMargin = false, children }) {
  const appMode = useSelector(getAppMode);

  const className = [appMode === APP_MODES.HEADLESS ? styles.contentBorderless : styles.content];

  if (noMargin) {
    className.push(styles.content_noMargin);
  }

  return <main className={className.join(" ")}>{children}</main>;
}
