import React from "react";

const withComponentRef = (Component) => {
  const handle = ({ componentRef, ...props }) => {
    return <Component {...props} ref={componentRef} />;
  };

  const name = Component.displayName || Component.name;
  handle.displayName = `withComponentRef(${name})`;

  return handle;
};

export default withComponentRef;
