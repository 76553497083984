import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";

export default function IssueDropdown({ issue, onIssueClick }) {
  const { t } = useTranslation();

  const handleClick = () => {
    onIssueClick(issue.id);
  };

  return (
    <Button type="link" onClick={handleClick}>
      {t("bookings_page:booking_view_dialog:info:issue_resolve")}
    </Button>
  );
}
