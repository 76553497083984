import React from "react";

import styles from "./section_header.module.css";

export default function SectionHeader({ header }) {
  return (
    <div className={styles.sectionHeader}>
      {header}
    </div>
  );
}
