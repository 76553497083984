import React, { Component } from "react";

import currencyOptions from "config/constants/currency_options";

import FormSelect from "components/forms/inputs/form_select";

class FormCurrencySelect extends Component {
  state = {
    options: [],
  };

  componentDidMount() {
    this.buildCurrencyOptions();
  }

  componentDidUpdate(prevProps) {
    const { availableCurrencies } = this.props;

    if (prevProps.availableCurrencies !== availableCurrencies) {
      this.buildCurrencyOptions();
    }
  }

  buildCurrencyOptions = () => {
    const { availableCurrencies } = this.props;

    let options = currencyOptions();

    if (availableCurrencies) {
      options = options.filter((currency) => availableCurrencies.includes(currency.value));
    }

    this.setState({ options });
  };

  render() {
    const { options } = this.state;

    return <FormSelect {...this.props} options={options} />;
  }
}

export default FormCurrencySelect;
