import React from "react";

import ChannexDrawer from "drawers/channex_drawer";

import DrawerFormWrapper from "components/drawer_form_wrapper";

import { EditWhiteLabelEmailSettingsForm } from "./edit_form/edit_form";

export function EditDrawer({ visible, whiteLabelEmailSettings, onClose }) {
  const title = "Edit White Label Email Settings";

  return (
    <ChannexDrawer title={title} visible={visible} onClose={onClose}>
      <DrawerFormWrapper withActions>
        <EditWhiteLabelEmailSettingsForm values={whiteLabelEmailSettings} closeDrawer={onClose} />
      </DrawerFormWrapper>
    </ChannexDrawer>
  );
}
