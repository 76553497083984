import attributesExtractor from "../utils/attributes_extractor";

let transport;
let storage;

const ENDPOINT = "tasks";

export default class Tasks {
  constructor(container) {
    transport = container.transport;
    storage = container.storage;
  }

  list(filter = {}, pagination = {}, order = {}) {
    return transport.send("GET", ENDPOINT, { filter, pagination, order }).then((response) => {
      storage.tasksLoad(response.data, response.meta);
      return response;
    });
  }

  find(id) {
    return transport.send("GET", `${ENDPOINT}/${id}`).then(({ data }) => {
      return attributesExtractor(data);
    });
  }
}
