import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Row } from "antd";
import store from "store";
import { convertToCamelCase } from "store/utils/case_converter";

import BillingAccountUsage from "components/billing_account/usage";

import styles from "./billing.module.css";

const { ws, BillingAccounts } = store;

function Billing({ user }) {
  const { t } = useTranslation();
  const [subscription, setSubscription] = useState(null);
  const [billingAccount, setBillingAccount] = useState(null);
  const [config, setConfig] = useState(null);

  const [state, setState] = useState({
    loading: true,
  });

  useEffect(() => {
    BillingAccounts.config().then((receivedConfig) => {
      setConfig(receivedConfig);
      const script = document.createElement("script");
      script.src = "https://checkoutlib.billsby.com/checkout.min.js";
      script.setAttribute("data-billsby-company", receivedConfig.company_domain);
      script.async = true;
      script.onload = () => {
        BillingAccounts.usage().then((billingAccountData) => {
          setSubscription(billingAccountData.subscription);
          setBillingAccount(billingAccountData);
          setState({ loading: false });

          window.billsbyData = {
            firstName: user.name,
            email: user.email,
          };

          window.scanDomBillsby();

          if (billingAccountData) {
            const billingAccountChannel = ws.socket.channel(
              `billing_account:${billingAccountData.id}`,
            );
            billingAccountChannel.join();
            billingAccountChannel.on("connected", (newSubscription) => {
              setSubscription(convertToCamelCase(newSubscription));
              window.scanDomBillsby();
            });

            billingAccountChannel.on("cancelled", () => {
              setSubscription(null);
              window.scanDomBillsby();
            });
          }
        });
      };

      document.body.appendChild(script);
    });
  }, [user.email, user.name]);

  useEffect(() => {
    if (!state.loading) {
      window.scanDomBillsby();
    }
  }, [state.loading]);

  return state.loading ? null : (
    <main className={styles.profileSection}>
      <div className={styles.header}>
        <p className={styles.title}>{t("user_profile_page:subscription:title")}</p>
      </div>
      <div className={styles.wrapper}>
        <Row>
          <Col xs={24} sm={8}>
            <div className={styles.label}>{t("user_profile_page:subscription:current_plan")}</div>
          </Col>
          <Col xs={24} sm={16}>
            {t("user_profile_page:subscription:plans:free")}
          </Col>
        </Row>

        {billingAccount?.usage ? (
          <BillingAccountUsage usage={billingAccount.usage} billingAccountId={billingAccount.id} />
        ) : null}

        <div className={styles.container}>
          {subscription ? (
            <>
              <p>{t("user_profile_page:subscription:invoice_notes")}</p>
              <Button
                size="large"
                data-billsby-type="account"
                data-billsby-customer={subscription.subscriptionSettings.customerUniqueId}
                data-billsby-subscription={subscription.subscriptionSettings.subscriptionUniqueId}
              >
                {t("user_profile_page:manage_subscription")}
              </Button>
            </>
          ) : (
            <>
              <p>{t("user_profile_page:subscription:setup_request")}</p>
              <Button
                size="large"
                data-billsby-type="checkout"
                data-billsby-product={config.circle_id}
                data-billsby-plan={config.plan_id}
                data-billsby-cycle={config.circle_id}
                data-billsby-allowances={config.allowance}
              >
                {t("user_profile_page:create_subscription")}
              </Button>
            </>
          )}
        </div>
      </div>
    </main>
  );
}

export default Billing;
