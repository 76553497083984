import React from "react";
import { useTranslation } from "react-i18next";

import FormCheckbox from "components/forms/inputs/formik/form_checkbox";

import styles from "./form_legal_checkbox.module.css";

export default function FormLegalCheckbox() {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <FormCheckbox name="policy_agreement" />
      <div className={styles.label}>
        {t("sign_up_page:policy_agreement")}
        <a href="https://channex.io/privacy-policy/" target="_blank" rel="noopener noreferrer">
          {` ${t("sign_up_page:privacy_policy")},`}
        </a>
        <a
          href="https://channex.io/acceptable-use-policy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {` ${t("sign_up_page:acceptable_use_policy")} `}
        </a>
        &
        <a
          href="https://channex.io/customer-agreement-policy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {` ${t("sign_up_page:customer_agreement_policy")}`}
        </a>
      </div>
    </div>
  );
}
