import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Input, notification, Radio, Row, Space } from "antd";
import store from "store";
import * as yup from "yup";

const { Channels } = store;

export default function VRBO({ form, onMappingDetailsLoad }) {
  const [credentialsIsValid, setCredentialsIsValid] = useState(false);
  const [authenticationLoading, setAuthenticationLoading] = useState(false);
  const [token, setToken] = useState(form.values.settings.token || null);
  const [additionalData, setAdditionalData] = useState(null);
  const [phone, setPhone] = useState(null);
  const [code, setCode] = useState(null);
  const settings = form.values.settings;

  const credentialsSchema = yup.object({
    username: yup.string().required(),
    password: yup.string().required(),
  });

  const { t } = useTranslation();

  useEffect(
    function validate() {
      credentialsSchema
        .validate(settings)
        .then(
          () => {
            setCredentialsIsValid(true);
          },
          () => {
            setCredentialsIsValid(false);
          },
        );
    },
    [settings, credentialsSchema],
  );

  const handleSuccessRequest = (data) => {
    const settingsForUpdate = form.values.settings;
    settingsForUpdate.token = data.token;
    form.setFieldValue("settings", settingsForUpdate);
    setToken(data.token);
    if (data.token.status === "verified") {
      onMappingDetailsLoad(settingsForUpdate, form.values.channel);
    }
    if (data.additional_data) {
      setAdditionalData(data.additional_data);
    }
  };

  const handleFailedRequest = (_error) => {
    notification.error({
      message: t("vrbo:invalid_credentials"),
    });
  };

  const authenticate = () => {
    setAuthenticationLoading(true);
    Channels.vrboAuthenticate({
      username: form.values.settings.username,
      password: form.values.settings.password,
    })
      .then(handleSuccessRequest)
      .catch(handleFailedRequest)
      .finally(() => {
        setAuthenticationLoading(false);
      });
  };

  const requestVerificationCode = () => {
    setAuthenticationLoading(true);

    Channels.vrboRequestAuthCode({
      token,
      phone,
    })
      .then(handleSuccessRequest)
      .catch(handleFailedRequest)
      .finally(() => {
        setAuthenticationLoading(false);
      });
  };

  const verifyCode = () => {
    setAuthenticationLoading(true);

    Channels.vrboVerifyCode({
      token,
      code,
    })
      .then(handleSuccessRequest)
      .catch(handleFailedRequest)
      .finally(() => {
        setAuthenticationLoading(false);
      });
  };

  const onPhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const onCodeChange = (e) => {
    setCode(e.target.value);
  };

  const resetToken = () => {
    handleSuccessRequest({
      token: null,
      additionalData: null,
    });
  };

  if (token === null) {
    return (
      <Row>
        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 16, offset: 8 }}>
          <Button
            disabled={!credentialsIsValid}
            onClick={authenticate}
            loading={authenticationLoading}
          >
            {t("vrbo:authenticate")}
          </Button>
        </Col>
      </Row>
    );
  }

  if (token.status === "await_mfa_request") {
    return (
      <Row>
        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 16, offset: 8 }}>
          <strong>{t("vrbo:choose_phone")}</strong>
          <br />
          <Radio.Group onChange={onPhoneChange} value={phone}>
            <Space direction="vertical">
              {additionalData.phones.map((phoneItem) => (
                <Radio key={phoneItem.phone} value={phoneItem}>
                  +{phoneItem.countryCode} {phoneItem.phone}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
          <br />
          <br />
          <Button
            disabled={!phone}
            onClick={requestVerificationCode}
            loading={authenticationLoading}
          >
            {t("vrbo:request_verification_code")}
          </Button>
          &nbsp;
          <Button onClick={resetToken}>
            {t("vrbo:reset")}
          </Button>
        </Col>
      </Row>
    );
  }

  if (token.status === "await_mfa_code") {
    return (
      <Row>
        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 16, offset: 8 }}>
          <strong>{t("vrbo:enter_verification_code")}</strong>
          <br />
          <Input placeholder="Code" onChange={onCodeChange} />
          <br />
          <br />
          <Button
            disabled={!code}
            onClick={verifyCode}
            loading={authenticationLoading}
          >
            {t("vrbo:verify_code")}
          </Button>
          &nbsp;
          <Button onClick={resetToken}>
            {t("vrbo:reset")}
          </Button>
        </Col>
      </Row>
    );
  }

  if (token.status === "verified") {
    return (
      <Row>
        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 16, offset: 8 }}>
          <Button onClick={resetToken}>
            {t("vrbo:reset")}
          </Button>
        </Col>
      </Row>
    );
  }

  return "Unhandled state";
}
