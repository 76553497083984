export const buildOrderedMappingSchema = (mappingSchema) => {
  if (!mappingSchema) {
    return [];
  }

  return Object.keys(mappingSchema)
    .sort((a, b) => mappingSchema[a].position - mappingSchema[b].position)
    .map((attrName) => ({
      id: attrName,
      type: mappingSchema[attrName].type,
      title: mappingSchema[attrName].title,
    }));
};
