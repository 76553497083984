import React from "react";
import PropTypes from "prop-types";
import { Select } from "antd";

import FormInput from "components/forms/inputs/form_input";

function handleChangeCallback(callback, field) {
  return (value) => {
    callback(field, value);
  };
}

function increaseModeSelect(model, disabled, handleModeChangeCallback) {
  return (
    <Select
      defaultValue={model.increase_mode}
      style={{ width: 80 }}
      onChange={handleModeChangeCallback}
      disabled={Boolean(model.rate_category_id) || disabled}
    >
      <Select.Option value="$">{model.currency}</Select.Option>
      <Select.Option value="%">%</Select.Option>
    </Select>
  );
}

function InputIncreaseBy(props) {
  const { t, model, disabled, onChange } = props;
  const onHandleChange = handleChangeCallback(onChange, "increase_value");
  const select = increaseModeSelect(
    model,
    disabled,
    handleChangeCallback(onChange, "increase_mode"),
  );

  return (
    <FormInput
      name="increase_value"
      view="horizontal"
      type="text"
      placeholder={t("rates_page:form:increase_by_placeholder")}
      label={t("rates_page:form:increase_by_label")}
      defaultValue={model.increase_value}
      addonAfter={select}
      onChange={onHandleChange}
      disabled={Boolean(model.rate_category_id) || disabled}
    />
  );
}

InputIncreaseBy.propTypes = {
  t: PropTypes.func,
  model: PropTypes.object,
  onChange: PropTypes.func,
};

export default InputIncreaseBy;
