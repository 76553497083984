import alphabetSort from "utils/alphabet_sort";

export default (object) => {
  let result = {};

  if (object) {
    result = Object.values(object)
      .sort(alphabetSort("title"))
      .reduce((acc, el) => {
        if (!acc[el.title]) {
          acc[el.title] = [];
        }
        acc[el.title].push(el.id);
        return acc;
      }, {});
  }

  return result;
};
