export default function getFieldStyle(props) {
  const { type, style } = props;

  switch (type) {
    case "hidden":
      return { ...style, display: "none" };
    default:
      return { ...style };
  }
}
