import { useCallback, useEffect, useRef } from "react";
import * as Sentry from "@sentry/react";

import EventEmitter from "utils/event_emitter";

const NEXT_EVENT_TIMEOUT_BEFORE_CLOSE = 30_000;
const EVENTS = {
  STARTED: "bookings_export_started",
  PROGRESS: "bookings_export_progress",
  COMPLETE: "bookings_export_complete",
  FAILED: "bookings_export_failed",
};

export default function useBookingExportListener({ onStarted, onProgress, onComplete, onFailed, onTimeout }) {
  const nextEventTimeoutRef = useRef(null);
  const isTimedOutSubscribedTokenEventStream = useRef(false);
  const subscribedToken = useRef(null);
  const buffer = useRef({});

  const nextEventTimeout = useCallback(() => {
    clearTimeout(nextEventTimeoutRef.current);

    nextEventTimeoutRef.current = setTimeout(() => {
      isTimedOutSubscribedTokenEventStream.current = true;
      onTimeout();
    }, NEXT_EVENT_TIMEOUT_BEFORE_CLOSE);
  }, [onTimeout]);

  const cleanEventTimeout = useCallback(() => {
    clearTimeout(nextEventTimeoutRef.current);
  }, []);

  const processEventWithoutSubscription = useCallback(({ name, eventToken, payload }) => {
    if (name === EVENTS.STARTED) {
      buffer.current[eventToken] = [];
      buffer.current[eventToken].push({ name, payload });

      return;
    }

    const isBufferForEventsStreamStarted = !!buffer.current[eventToken];
    if (!isBufferForEventsStreamStarted) {
      return;
    }

    buffer.current[eventToken].push({ name, payload });
  }, []);

  const bufferedEventRunner = useCallback((name, payload, callback) => {
    const eventToken = payload.token;
    if (!eventToken) {
      Sentry.captureMessage("useBookingExportListener: no token in event payload");
      return;
    }

    const isSubscribed = !!subscribedToken.current;
    if (!isSubscribed) {
      processEventWithoutSubscription({ name, eventToken, payload });
      return;
    }
    const isEventFromSubscription = subscribedToken.current === eventToken;
    if (!isEventFromSubscription) {
      return;
    }

    if (isTimedOutSubscribedTokenEventStream.current) {
      return;
    }

    callback(payload);
  }, [processEventWithoutSubscription]);

  const handleOnStarted = useCallback(
    (data) => {
      bufferedEventRunner(EVENTS.STARTED, data, () => {
        nextEventTimeout();
        onStarted(data.total_count);
      });
    },
    [onStarted, bufferedEventRunner, nextEventTimeout],
  );

  const handleOnProgress = useCallback(
    (data) => {
      bufferedEventRunner(EVENTS.PROGRESS, data, () => {
        nextEventTimeout();
        onProgress(data.count);
      });
    },
    [onProgress, bufferedEventRunner, nextEventTimeout],
  );

  const handleOnComplete = useCallback(
    (data) => {
      bufferedEventRunner(EVENTS.COMPLETE, data, () => {
        cleanEventTimeout();
        onComplete(data.token);
      });
    },
    [onComplete, bufferedEventRunner, cleanEventTimeout],
  );

  const handleOnFailed = useCallback(
    (data) => {
      bufferedEventRunner(EVENTS.FAILED, data, () => {
        cleanEventTimeout();
        onFailed();
      });
    },
    [onFailed, bufferedEventRunner, cleanEventTimeout],
  );

  const subscribe = useCallback((token) => {
    subscribedToken.current = token;
    buffer.current[token] = [];
  }, []);

  useEffect(() => {
    EventEmitter.bind("bookings_export_started", handleOnStarted);

    return () => EventEmitter.unbind("bookings_export_started", handleOnStarted);
  }, [handleOnStarted]);

  useEffect(() => {
    EventEmitter.bind("bookings_export_progress", handleOnProgress);

    return () => EventEmitter.unbind("bookings_export_progress", handleOnProgress);
  }, [handleOnProgress]);

  useEffect(() => {
    EventEmitter.bind("bookings_export_complete", handleOnComplete);

    return () => EventEmitter.unbind("bookings_export_complete", handleOnComplete);
  }, [handleOnComplete]);

  useEffect(() => {
    EventEmitter.bind("bookings_export_failed", handleOnFailed);

    return () => EventEmitter.unbind("bookings_export_failed", handleOnFailed);
  }, [handleOnFailed]);

  const join = useCallback(() => {
    nextEventTimeout();
  }, [nextEventTimeout]);

  const leave = useCallback(() => {
    buffer.current = {};
    subscribedToken.current = null;
    isTimedOutSubscribedTokenEventStream.current = false;
    cleanEventTimeout();
  }, [cleanEventTimeout]);

  return {
    join,
    subscribe,
    leave,
  };
}
