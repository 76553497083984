let transport;

const ENDPOINT = "custom_closed_to_arrival";

export default class CustomClosedToArrivals {
  constructor(container) {
    transport = container.transport;
  }

  list(filters = {}) {
    return transport.send("GET", ENDPOINT, { filter: filters });
  }

  create(attrs) {
    return transport.send("POST", ENDPOINT, { value: attrs });
  }

  remove(filters = {}) {
    return transport.send("DELETE", ENDPOINT, { filter: filters });
  }
}
