import { useQuery } from "react-query";
import store from "store";

const { AdminChannelAdapters } = store;

export function useAdminChannelAdapters(tableParams) {
  const { searchQuery, pagination, order } = tableParams || {};

  let filter;

  if (searchQuery) {
    filter = { title: { has: searchQuery } };
  }

  return useQuery(["admin_channel_adapters", tableParams], () => AdminChannelAdapters.list(filter, pagination, order), {
    enabled: !!tableParams,
  });
}
