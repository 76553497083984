import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Form } from "antd";
import * as yup from "yup";

import errorMessages from "config/constants/errors";

import ChannexForm from "components/channex_form";
import RadioInput from "components/forms/inputs/formik/radio";

class NonShowPolicyForm extends Component {
  VALIDATION_SCHEMA = yup.object().shape({
    non_show_policy: yup.string().required(errorMessages.required()),
  });

  DEFAULT_VALUE = {
    non_show_policy: "default",
  };

  static FIELDS = ["non_show_policy"];

  getPolicyTypeOptions = () => {
    const { t } = this.props;

    return [
      {
        value: "default",
        representation: t("policies:non_show_policy:policy_type_options:default"),
      },
      {
        value: "total_price",
        representation: t("policies:non_show_policy:policy_type_options:total_price"),
      },
    ];
  };

  render() {
    const { t, onChange, value, componentRef, errors } = this.props;

    return (
      <ChannexForm
        defaultValue={this.DEFAULT_VALUE}
        value={value}
        errors={errors}
        componentRef={componentRef}
        validationSchema={this.VALIDATION_SCHEMA}
        fields={NonShowPolicyForm.FIELDS}
        onChange={onChange}
      >
        {({ handleSubmit }) => (
          <Form onFinish={handleSubmit}>
            <legend>Non Show policy</legend>
            <RadioInput
              name="non_show_policy"
              label={t("policies:non_show_policy:policy_type_label")}
              options={this.getPolicyTypeOptions()}
            />
          </Form>
        )}
      </ChannexForm>
    );
  }
}

export default withTranslation()(NonShowPolicyForm);
