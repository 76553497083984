import { RATE_CATEGORIES_ADD, RATE_CATEGORIES_DROP, RATE_CATEGORIES_LOAD } from "../constants";

export function rateCategoriesLoad(storage) {
  return function (rateCategories) {
    storage.dispatch({ type: RATE_CATEGORIES_LOAD, payload: rateCategories });
  };
}

export function rateCategoriesAdd(storage) {
  return function (rateCategory) {
    storage.dispatch({ type: RATE_CATEGORIES_ADD, payload: rateCategory });
  };
}

export function rateCategoriesDrop(storage) {
  return function (rateCategory) {
    storage.dispatch({ type: RATE_CATEGORIES_DROP, payload: rateCategory });
  };
}
