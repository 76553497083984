import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { DownOutlined, EditOutlined, LinkOutlined } from "@ant-design/icons";
import { Dropdown } from "antd";

import { removeMenuItem } from "components/menu/items/remove";

import useBoolState from "utils/use_bool_state";

import TaxActionsIcon from "./tax_actions_icon";

import generalStyles from "styles/general.module.css";

export default function TaxActions({ id, selectable, isDefault, onEdit, onSelect, onDelete }) {
  const [loading, setLoading, setComplete] = useBoolState(false);
  const { t } = useTranslation();

  const handleEdit = useCallback(() => {
    onEdit(id);
  }, [id, onEdit]);

  const handleSelect = useCallback(() => {
    setLoading();
    onSelect(id).finally(setComplete);
  }, [id, onSelect, setLoading, setComplete]);

  const handleDelete = useCallback(() => {
    setLoading();
    onDelete(id).finally(setComplete);
  }, [id, onDelete, setLoading, setComplete]);

  const items = [{
    key: "actions_menu_edit",
    onClick: handleEdit,
    label: (
      <div data-cy="actions_menu_edit">
        <EditOutlined /> {t("general:action:edit")}
      </div>
    ),
  }];

  if (!isDefault) {
    if (selectable) {
      items.push({ type: "divider" });
      items.push({
        key: "actions_menu_set_as_default",
        disabled: isDefault,
        onClick: handleSelect,
        label: (
          <div data-cy="actions_menu_set_as_default">
            <LinkOutlined /> {t("properties_page:policies:set_as_default")}
          </div>
        ),
      });
    }

    items.push({ type: "divider" });

    items.push(removeMenuItem({
      onRemove: handleDelete,
    }));
  }

  return (
    <>
      <TaxActionsIcon isDefault={isDefault} isLoading={loading} />
      <Dropdown menu={{ items }} trigger={["click"]}>
        <a
          data-cy="crud_entry_actions_menu"
          className={generalStyles.actionsToggle}
          onClick={(e) => e.preventDefault()}
        >
          {t("general:actions")} <DownOutlined />
        </a>
      </Dropdown>
    </>
  );
}
