import React from "react";
import PropTypes from "prop-types";

import styles from "./auth_form_sub_title.module.css";

function AuthFormSubTitle({ children }) {
  return <p className={styles.formSubTitle}>{children}</p>;
}

AuthFormSubTitle.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default AuthFormSubTitle;
