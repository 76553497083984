import React from "react";
import PropTypes from "prop-types";

import ChooseRoomTypeMessage from "../items/choose_room_type_message";
import InputDecreaseBy from "../items/input_decrease_by";
import InputDerivedRate from "../items/input_derived_rate";
import InputIncreaseBy from "../items/input_increase_by";
import InputPrimaryOccupancy from "../items/input_primary_occupancy";
import InputRate from "../items/input_rate";

function GroupPerPersonAutoRate(props) {
  const { t, model, hideAutoOptions } = props;

  let result = <ChooseRoomTypeMessage t={t} />;

  if (model.room_type_id) {
    result = (
      <>
        <InputPrimaryOccupancy {...props} />
        {model.inherit_rate && model.is_derived ? (
          <InputDerivedRate {...props} />
        ) : (
          <InputRate {...props} />
        )}
        {hideAutoOptions ? null : (
          <>
            <InputIncreaseBy {...props} />
            <InputDecreaseBy {...props} />
          </>
        )}
      </>
    );
  }

  return result;
}

GroupPerPersonAutoRate.propTypes = {
  t: PropTypes.func,
  model: PropTypes.object,
  errors: PropTypes.object,
  onChange: PropTypes.func,
};

export default GroupPerPersonAutoRate;
