import React from "react";
import Avatar from "react-avatar";

import styles from "./avatar.module.css";

const DEFAULT_SIZE = 32;

export default function (props) {
  const formattedUserName = props.name ? props.name.toLowerCase().replace(" ", "_") : null;
  const dataCy = `gravatar_container_${formattedUserName}`;

  return (
    <span data-cy={dataCy}>
      <Avatar className={styles.avatar} round size={DEFAULT_SIZE} {...props} />
    </span>
  );
}
