import React from "react";
import { useTranslation } from "react-i18next";

export default function PrimaryOccToggleTooltipContent({ isPrimary }) {
  const { t } = useTranslation();

  const message = isPrimary
    ? t("channels_page:form:booking_com_tooltip:primary_rate")
    : t("channels_page:form:booking_com_tooltip:secondary_rate");

  return <div>{message}</div>;
}
