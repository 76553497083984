import React, { useEffect, useState } from "react";

import MappingRow from "components/mapping/mapping_row";
import RoomTitle from "components/rooms/room_title";

import RateMapping from "./rate_mapping";

export default function RoomMapping({
  roomId,
  knownMappingsPerRoom,
  mappingOptions,
  knownMappings,
  roomTypes,
  ratePlans,
  onDelete,
}) {
  const [roomData, setRoomData] = useState({});
  const [mappedRoomTitle, setMappedRoomTitle] = useState("");
  const { rates: rateOptions = [] } = roomData;

  useEffect(() => {
    const { room_type_id: roomTypeId } = knownMappingsPerRoom[0];
    const roomsOptions = mappingOptions.rooms || [];

    const room = roomsOptions.find((roomOption) => String(roomOption.id) === roomId) || {
      title: roomId,
    };
    const mappedRoom = roomTypes.find((roomType) => roomType.id === roomTypeId) || {
      title: roomTypeId,
    };

    setRoomData(room);
    setMappedRoomTitle(mappedRoom.title);
  }, [knownMappingsPerRoom, mappingOptions, roomTypes, roomId]);

  return (
    <>
      <MappingRow
        type="room"
        title={<RoomTitle room={roomData} />}
        action={<RoomTitle room={{ title: mappedRoomTitle }} />}
      />
      {knownMappingsPerRoom.map((knownMapping) => (
        <RateMapping
          key={knownMapping.id}
          mapping={knownMapping}
          rateOptions={rateOptions}
          ratePlans={ratePlans}
          knownMappings={knownMappings}
          onDelete={onDelete}
        />
      ))}
    </>
  );
}
