import React from "react";

import ApplicationsViewItem from "./applications_view_item";

import styles from "./applications_view_section.module.css";

export default function ApplicationsViewSection({
  title,
  items,
  onInstall,
  onUninstall,
  canManageApplications,
}) {
  const itemCount = items.length;

  if (!itemCount) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <h3 className={styles.sectionTitle}>{`${title} (${itemCount})`}</h3>
      <div className={styles.itemsContainer}>
        {items.map((item) => (
          <ApplicationsViewItem
            key={item.id}
            item={item}
            onInstall={onInstall}
            onUninstall={onUninstall}
            canManageApplications={canManageApplications}
          />
        ))}
      </div>
    </div>
  );
}
