export const features = {
  BOOKING_SOURCES_WIDGET: "bookingSourcesWidget",
  DISABLE_USER_MANAGEMENT: "disableUserManagement",
  ENABLE_USER_MANAGEMENT: "enableUserManagement",
  TRIPLA_IMPORT: "triplaImportEnabled",
  EXTRAS_MANAGEMENT: "extrasManagement",
};

export default {
  [features.BOOKING_SOURCES_WIDGET]: true,
  [features.DISABLE_USER_MANAGEMENT]: false,
  [features.ENABLE_USER_MANAGEMENT]: false,
  [features.TRIPLA_IMPORT]: false,
  [features.EXTRAS_MANAGEMENT]: false,
};
