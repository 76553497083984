import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import FormInput from "components/forms/inputs/form_input";

export default function InputMinStayArrival({ model, errors, disabled, isDerived, onChange }) {
  const { t } = useTranslation();
  const isInherited = model.inherit_min_stay_arrival === true && isDerived;
  const hasRateCategory = Boolean(model.rate_category_id);
  const isReadonly = isInherited || hasRateCategory || disabled;

  const onHandleChange = useCallback(
    (value) => {
      onChange("min_stay_arrival", value);
    },
    [onChange],
  );

  return (
    <FormInput
      name="min_stay_arrival"
      view="horizontal"
      type="number"
      placeholder={t("rates_page:form:min_stay_arrival_placeholder")}
      label={t("rates_page:form:min_stay_arrival_label")}
      defaultValue={model.min_stay_arrival[0]}
      errors={errors ? errors.min_stay_arrival : null}
      onChange={onHandleChange}
      disabled={isReadonly}
    />
  );
}
