import React from "react";
import { useTranslation } from "react-i18next";
import { DownloadOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import _ from "lodash";

import downloadReport from "./methods/download_report";

import styles from "./usage.module.css";

export default function Usage({ billingAccountId, usage = {} }) {
  const { t } = useTranslation();

  return Object.keys(usage).map((key) => {
    const snakeCaseKey = _.snakeCase(key);
    return (
      <Row key={key}>
        <Col xs={24} sm={8}>
          <div className={styles.label}>{t(`user_profile_page:subscription:${snakeCaseKey}`)}</div>
        </Col>
        <Col xs={24} sm={16}>
          <div>
            {usage[key]}{" "}
            <span onClick={downloadReport(billingAccountId, snakeCaseKey)} className={styles.link}>
              <DownloadOutlined /> {t("user_profile_page:subscription:download")}
            </span>
          </div>

          <div className="ant-form-item-extra">
            {t(`user_profile_page:subscription:${snakeCaseKey}_description`)}
          </div>
        </Col>
      </Row>
    );
  });
}
