import React from "react";

import alphabetSort from "utils/alphabet_sort";

import GroupTag from "./group_tag";
import HiddenGroupPopover from "./hidden_groups_popover";

import styles from "./groups_column.module.css";

const MAX_SHOWN_GROUPS_AMOUNT = 2;
const SORTER_FIELD = "title";

export default function GroupsColumn(_data, record) {
  const groupsList = Object.values(record.groups).sort(alphabetSort(SORTER_FIELD));

  const hiddenGroups = groupsList.slice(MAX_SHOWN_GROUPS_AMOUNT);

  const groupTags = groupsList
    .slice(0, MAX_SHOWN_GROUPS_AMOUNT)
    .map(({ id, title }) => <GroupTag key={id} id={id} title={title} />);

  return (
    <div className={styles.groups}>
      {groupTags}
      <HiddenGroupPopover list={hiddenGroups} />
    </div>
  );
}
