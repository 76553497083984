import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import styles from "./mapping_value.module.css";

const HIDDEN_VALUES = ["extra_adult_price", "extra_child_price"];

class MappingValue extends Component {
  getMappingOption = (el, mapping) => {
    const { mappingOptions } = this.props;

    if (!mappingOptions[`${el}_dictionary`]) {
      return null;
    }

    return mappingOptions[`${el}_dictionary`].values.find((v) => v.id === mapping[el]).title;
  };

  buildMappingTitle = () => {
    const { mapping } = this.props;

    const mappingTitle = Object.keys(mapping)
      .reduce((acc, el) => {
        const option = this.getMappingOption(el, mapping);
        const representation = option || mapping[el];

        if (!HIDDEN_VALUES.includes(el)) {
          acc.push(representation);
        }

        return acc;
      }, [])
      .join(", ");

    return mappingTitle;
  };

  render() {
    const { t, mapping, mappingOptions, onClick } = this.props;

    if (!mappingOptions) {
      return null;
    }

    return (
      <a href="#map" onClick={onClick} className={styles.link}>
        {mapping ? this.buildMappingTitle() : t("channels_page:form:not_mapped")}
      </a>
    );
  }
}

export default withTranslation()(MappingValue);
