import { modal } from "app";
import i18n from "i18next";

export default function (isDirty) {
  return new Promise((resolve, reject) => {
    if (!isDirty) {
      reject();
      return;
    }

    modal.confirm({
      title: i18n.t("general:unsaved_form_dialog:title"),
      content: i18n.t("general:unsaved_form_dialog:description"),
      okText: i18n.t("general:action:cancel"),
      cancelText: i18n.t("general:action:confirm"),
      onCancel: () => reject(),
      onOk: () => resolve(),
    });
  });
}
