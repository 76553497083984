import React from "react";

import { ExtraForm } from "../../extra_form/extra_form";

import { useUpdateAction } from "./use_update_action";

export function EditExtraForm({ propertyId, extraCategoryId, value, closeDrawer }) {
  const { mutateAsync, isLoading } = useUpdateAction(propertyId);

  const handleSubmit = async (formValues) => {
    const { id, ...attrs } = formValues;
    attrs.propertyId = propertyId;
    attrs.extraCategoryId = extraCategoryId;

    const result = await mutateAsync({ id, attrs });
    closeDrawer(result);
  };

  return (
    <ExtraForm
      value={value}
      currency={value?.currency}
      isLoading={isLoading}
      onSubmit={handleSubmit}
    />
  );
}
