import { Outlet } from "react-router-dom";

import AppLayout from "./app_layout";
import DataLoader from "./data_loader";
import GlobalComponents from "./global_components";
import Header from "./header";
import { InitWsConnection } from "./init_ws_connection";
import Redirects from "./redirects";

export default function EntryPoint() {
  return (
    <InitWsConnection>
      <DataLoader>
        <Redirects>
          <AppLayout>
            <GlobalComponents />
            <Header />
            <Outlet />
          </AppLayout>
        </Redirects>
      </DataLoader>
    </InitWsConnection>
  );
}
