import { GROUPS_ADD, GROUPS_DROP, GROUPS_LOAD, GROUPS_LOAD_OPTIONS } from "../constants";

export function groupsLoad(storage) {
  return function (groups) {
    storage.dispatch({ type: GROUPS_LOAD, payload: groups });
  };
}

export function groupsAdd(storage) {
  return function (group) {
    storage.dispatch({ type: GROUPS_ADD, payload: group });
  };
}

export function groupsDrop(storage) {
  return function (group) {
    storage.dispatch({ type: GROUPS_DROP, payload: group });
  };
}

export function groupsOptionsLoad(storage) {
  return function (options) {
    storage.dispatch({ type: GROUPS_LOAD_OPTIONS, payload: options });
  };
}
