import { useSelector } from "react-redux";

import { getActiveFeatureFlags } from "../../store/storage/selectors/session_selector";

export default function useFeatures() {
  const activeFeatureFlags = useSelector(getActiveFeatureFlags);

  const isFeatureEnabled = (feature) => activeFeatureFlags[feature];

  return { isFeatureEnabled };
}
