import appStorage from "../utils/app_storage_manager";

const KEY_NAME = "CHANNEX_AUTH";
const NULL_VALUE = {
  authToken: null,
  refreshToken: null,
};

export const getTokensFromOldPlace = () => {
  const data = appStorage.getItem("CHANNEX_SESSION");

  if (!data) {
    return NULL_VALUE;
  }

  if (!data.authToken || !data.refreshToken) {
    return NULL_VALUE;
  }

  return {
    authToken: data.authToken,
    refreshToken: data.refreshToken,
  };
};

export const writeTokensToOldPlace = ({ authToken, refreshToken }) => {
  const data = appStorage.getItem("CHANNEX_SESSION");

  if (!data) {
    return;
  }

  data.authToken = authToken;
  data.refreshToken = refreshToken;

  appStorage.setItem("CHANNEX_SESSION", data);
};

export const removeTokensFromOldPlace = () => {
  const data = appStorage.getItem("CHANNEX_SESSION");

  if (!data) {
    return;
  }

  delete data.authToken;
  delete data.refreshToken;

  appStorage.setItem("CHANNEX_SESSION", data);
};

export const getAuthTokens = () => {
  return appStorage.getItem(KEY_NAME) || NULL_VALUE;
};

export const writeAuthTokens = ({ authToken, refreshToken } = NULL_VALUE) => {
  appStorage.setItem(KEY_NAME, { authToken, refreshToken });
};

export const removeAuthTokens = () => {
  removeTokensFromOldPlace();
  return appStorage.setItem(KEY_NAME, NULL_VALUE);
};
