import React, { useEffect, useRef } from "react";
import GoogleMapReact from "google-map-react";

import config from "config/index";

import styles from "./map.module.css";

const ZOOM_COORDS = 12;
const ZOOM_NO_COORDS = 2;

const DEFAULT_LATITUDE = 0;
const DEFAULT_LONGITUDE = 0;

const isValidCoordinates = (lat, lng) => {
  return !isNaN(parseFloat(lat)) && !isNaN(parseFloat(lng));
};

const getCoords = (lat, lng) => {
  if (isValidCoordinates(lat, lng)) {
    return {
      lat: parseFloat(lat),
      lng: parseFloat(lng),
      zoom: ZOOM_COORDS,
    };
  }

  return {
    lat: DEFAULT_LATITUDE,
    lng: DEFAULT_LONGITUDE,
    zoom: ZOOM_NO_COORDS,
  };
};

export default function Map({ lat, lng, onChangeCoords }) {
  const marker = useRef(null);

  const coords = getCoords(lat, lng);

  useEffect(() => {
    marker.current?.setPosition({ lat: coords.lat, lng: coords.lng });
  }, [coords.lat, coords.lng]);

  useEffect(() => {
    return () => {
      if (marker.current) {
        window.google.maps.event.clearInstanceListeners(marker.current);
      }
    };
  }, []);

  const initMap = (map, maps) => {
    const newMarker = new maps.Marker({
      position: { lat: coords.lat, lng: coords.lng },
      map,
      draggable: true,
    });

    newMarker.addListener("dragend", () => {
      const position = newMarker.getPosition();

      const newCoords = {
        lat: position.lat(),
        lng: position.lng(),
      };

      onChangeCoords(newCoords);
    });

    marker.current = newMarker;
  };

  return (
    <div className={styles.mapContainer}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: config.GOOGLE_MAP_API_KEY }}
        center={coords}
        zoom={coords.zoom}
        draggable
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => initMap(map, maps)}
      />
    </div>
  );
}
