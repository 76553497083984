import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useLocation, useMatch } from "react-router-dom";
import classnames from "classnames";
import store from "store";
import {
  getChannelMessagesThreads,
  getChannelMessagesThreadsMeta,
} from "store/storage/selectors/channel_messages_threads_selector";
import { getActivePropertyId, getIframeFlags } from "store/storage/selectors/session_selector";

import defaultPaginationVars from "config/constants/default_pagination_variables";

import Loading from "components/loading";
import NoDataPlaceholder from "components/no_data_placeholder";

import useRouting from "routing/use_routing";

import calculateLimitFromRef from "utils/calculate_limit_from_ref";
import useBoolState from "utils/use_bool_state";

import ThreadsList from "./threads_list/threads_list";
import ThreadsHeader from "./threads_header";

import styles from "./threads.module.css";

const DEFAULT_CACHE_PROPS = {
  fixedWidth: true,
  defaultHeight: 72,
  minHeight: 72,
};

const DEFAULT_FILTER_VALUE = true;

const getFilterValueFormUrl = (search) => {
  const urlParams = new URLSearchParams(search);
  return search ? urlParams.get("is_active") === "true" : DEFAULT_FILTER_VALUE;
};

const { ChannelMessagesThreads } = store;

function Threads({ isMobile }) {
  const listRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { userAppRoutes } = useRouting();
  const match = useMatch({ path: userAppRoutes.messages.dialog._path, end: false });
  const [isThreadActive, setThreadActive] = useState(null);
  const [loading, setTrueLoading, setFalseLoading] = useBoolState(false);
  const threadsList = useSelector(getChannelMessagesThreads);
  const threadsMeta = useSelector(getChannelMessagesThreadsMeta);
  const activeProperty = useSelector(getActivePropertyId);
  const { search, pathname } = location;
  const threadId = match?.params?.threadId;

  const loadThreadsList = useCallback(
    (nextPage) => {
      setTrueLoading();

      const limit = calculateLimitFromRef(listRef, DEFAULT_CACHE_PROPS.minHeight);

      const filter = {
        is_closed: !isThreadActive,
      };

      if (activeProperty) {
        filter.property_id = activeProperty;
      }

      const meta = { ...defaultPaginationVars, page: nextPage, limit };

      ChannelMessagesThreads.threadsList(filter, meta).finally(setFalseLoading);
    },
    [listRef, activeProperty, isThreadActive, setTrueLoading, setFalseLoading],
  );

  useEffect(
    function handleFilterChange() {
      if (isThreadActive === null) {
        return;
      }

      loadThreadsList(1);
    },
    [listRef, loadThreadsList, isThreadActive],
  );

  const loadPreselectedThread = useCallback((id, isActive) => {
    if (!id) {
      return Promise.resolve(isActive);
    }

    return ChannelMessagesThreads.thread(id)
      .then((data) => {
        const {
          attributes: { isClosed },
        } = data;

        return !isClosed;
      })
      .catch(() => {
        return isActive;
      });
  }, []);

  useEffect(
    function initThreads() {
      if (isThreadActive !== null || loading) {
        return;
      }

      setTrueLoading();
      const activeFilterValue = getFilterValueFormUrl(search);

      ChannelMessagesThreads.resetThreads();

      loadPreselectedThread(threadId, activeFilterValue).then((isActive) => {
        navigate(`${pathname}?is_active=${isActive}`);

        setThreadActive(isActive);
        setFalseLoading();
      });
    },
    [
      isThreadActive,
      threadId,
      search,
      navigate,
      loading,
      pathname,
      setTrueLoading,
      setFalseLoading,
      loadPreselectedThread,
    ],
  );

  const onSetFilter = useCallback(
    (val) => {
      if (val === isThreadActive) {
        return;
      }

      ChannelMessagesThreads.resetThreads();
      setThreadActive(val);
      navigate(`/messages?is_active=${val}`);
    },
    [navigate, isThreadActive],
  );

  const renderContent = useMemo(() => {
    if (!threadsList) {
      return (
        <div className={styles.center}>
          <Loading />
        </div>
      );
    }

    if (!threadsList?.length) {
      return (
        <div className={styles.center}>
          <NoDataPlaceholder emptyMessage={t("general:no_data_placeholder")} />
        </div>
      );
    }

    return (
      <ThreadsList
        threadsList={threadsList}
        meta={threadsMeta}
        loading={loading}
        loadMore={loadThreadsList}
        defaultCacheProps={DEFAULT_CACHE_PROPS}
      />
    );
  }, [t, loading, threadsList, threadsMeta, loadThreadsList]);

  const { hideMessagesList } = useSelector(getIframeFlags);

  const isThreadsHidden = (isMobile && threadId) || hideMessagesList;
  const containerClass = classnames(
    styles.wrapper,
    isMobile && styles.mobile,
    isThreadsHidden && styles.hidden,
  );

  return (
    <div className={containerClass}>
      <ThreadsHeader isThreadActive={isThreadActive} handleSetFilter={onSetFilter} />
      <div ref={listRef} className={styles.content}>
        {renderContent}
      </div>
    </div>
  );
}

export default Threads;
