import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Card, Empty } from "antd";
import classNames from "classnames";
import _ from "lodash";
import store from "store";
import {
  getLiveFeedEvents,
  getLiveFeedEventsMeta,
} from "store/storage/selectors/live_feed_events_selector";
import { getActiveGroup, getActivePropertyId } from "store/storage/selectors/session_selector";

import defaultPaginationVars from "config/constants/default_pagination_variables";
import eventTypesOptions from "config/constants/event_type_options";

import ErrorMessage from "components/error_boundary/error_message";

import EventEmitter from "utils/event_emitter";
import useBoolState from "utils/use_bool_state";

import WidgetLoader from "../common/widget_loader";

import EventsList from "./events_list";
import Title from "./title";

import stylesWidget from "./events_list/events_list.module.css";
import styles from "styles/dashboard.module.css";

const DEBOUNCE_TIME = 300;

const DEFAULT_EVENT_TYPE = ["all_events"];

const { LiveFeedEvents } = store;

function LiveFeedEventsWidget() {
  const [eventTypes, setEventTypes] = useState(DEFAULT_EVENT_TYPE);
  const activeProperty = useSelector(getActivePropertyId);
  const activeGroup = useSelector(getActiveGroup);
  const liveFeedEvents = useSelector(getLiveFeedEvents);
  const liveFeedEventsMeta = useSelector(getLiveFeedEventsMeta);
  const [loading, setTrueLoading, setFalseLoading] = useBoolState(false);
  const [isError, setTrueError, setFalseError] = useBoolState(false);
  const { t } = useTranslation();

  useEffect(
    function resetEventsByActiveProperty() {
      LiveFeedEvents.reset();
    },
    [activeProperty, activeGroup],
  );

  const handleAddEvent = useCallback(
    (data) => {
      const {
        attributes: { event, property_id },
      } = data;
      const validProperty = !activeProperty || activeProperty === property_id;
      const isIncludeEventInFilter = eventTypes.includes(event) || _.isEqual(eventTypes, DEFAULT_EVENT_TYPE);
      if (validProperty && isIncludeEventInFilter) {
        LiveFeedEvents.addEvent(data);
      }
    },
    [eventTypes, activeProperty],
  );

  useEffect(() => {
    EventEmitter.bind("live_feed_event:created", handleAddEvent);

    return () => EventEmitter.unbind("live_feed_event:created", handleAddEvent);
  }, [handleAddEvent]);

  const handleSetEventTypes = useCallback((type) => {
    LiveFeedEvents.reset();
    let newEventTypes = type;
    if (type?.length === 0 || !type) {
      newEventTypes = DEFAULT_EVENT_TYPE;
    }
    setEventTypes(newEventTypes);
  }, []);

  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  const loadFeedEvents = useCallback(
    _.debounce((property, group, types, nextPage) => {
      setTrueLoading();
      let filter = {};

      let meta = { ...defaultPaginationVars, limit: 20 };

      if (types?.length > 0 && types[0] !== "all_events") {
        filter = { event: types };

        if (types.length === eventTypesOptions.length) {
          filter = {};
        }
      }
      if (property) {
        filter.property_id = property;
      }
      if (group) {
        filter.group_id = group;
      }

      if (nextPage) {
        meta = { ...meta, page: nextPage };
      }

      LiveFeedEvents.list(filter, meta)
        .then(() => {
          setFalseLoading();
          setFalseError();
        })
        .catch(() => {
          setFalseLoading();
          setTrueError();
        });
    }, DEBOUNCE_TIME),
    [],
  );

  useEffect(
    function loadEvents() {
      loadFeedEvents(activeProperty, activeGroup, eventTypes);
    },
    [activeProperty, activeGroup, eventTypes, loadFeedEvents],
  );

  const handleReload = useCallback(() => {
    return loadFeedEvents(activeProperty, activeGroup, eventTypes);
  }, [activeProperty, activeGroup, eventTypes, loadFeedEvents]);

  const cardClassName = classNames(
    styles.dashboard__widget,
    styles.dashboard__widget__emptyPadding,
    styles.dashboard__widget__withBorder,
    stylesWidget.liveFeedEventsWidget,
  );

  const renderEmptySection = () => {
    return (
      <div className={stylesWidget.emptyList}>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("live_feed_events_widget:no_events")} />
      </div>
    );
  };

  const renderLoading = () => {
    return (
      <div className={stylesWidget.emptyList}>
        <WidgetLoader />
      </div>
    );
  };

  const renderList = () => {
    return (
      <EventsList
        list={liveFeedEvents}
        handleLoadFeedEvents={loadFeedEvents}
        meta={liveFeedEventsMeta}
        loading={loading}
        activeProperty={activeProperty}
        activeGroup={activeGroup}
        eventTypes={eventTypes}
      />
    );
  };

  const renderError = () => {
    return (
      <div className={stylesWidget.error}>
        <ErrorMessage onReload={handleReload} />
      </div>
    );
  };

  const renderContent = () => {
    let result = renderEmptySection();
    if ((loading && !liveFeedEvents) || liveFeedEvents === null) {
      result = renderLoading();
    }

    if (liveFeedEvents?.length > 0) {
      result = renderList();
    }

    if (isError) {
      result = renderError();
    }

    return result;
  };

  if (!eventTypes) {
    return null;
  }

  return (
    <Card
      title={<Title handleFilterChange={handleSetEventTypes} eventTypes={eventTypes} />}
      className={cardClassName}
    >
      {renderContent()}
    </Card>
  );
}

export default LiveFeedEventsWidget;
