import React from "react";
import PropTypes from "prop-types";

import currencyOptions from "config/constants/currency_options";

import FormSelect from "components/forms/inputs/form_select";

function handleChangeCallback(callback, field) {
  return (value) => {
    callback(field, value);
  };
}

function InputCurrency(props) {
  const { t, model, errors, disabled, onChange } = props;
  const onHandleChange = handleChangeCallback(onChange, "currency");
  const isDisabled = Boolean(model.rate_category_id) || Boolean(model.id) || disabled;

  return (
    <FormSelect
      name="currency"
      view="horizontal"
      placeholder={t("rates_page:form:currency_placeholder")}
      label={t("rates_page:form:currency_label")}
      onChange={onHandleChange}
      defaultValue={model.currency || undefined}
      value={model.currency || undefined}
      errors={errors ? errors.currency : null}
      options={currencyOptions()}
      disabled={isDisabled}
    />
  );
}

InputCurrency.propTypes = {
  t: PropTypes.func,
  model: PropTypes.object,
  errors: PropTypes.object,
  onChange: PropTypes.func,
};

export default InputCurrency;
