import React from "react";
import { useTranslation } from "react-i18next";

import ConfirmRegistration from "pages/confirm_registration_page/components/confirm_registration";

import AuthFormTitle from "components/auth_form_title";

function ConfirmRegistrationPage() {
  const { t } = useTranslation();

  return (
    <>
      <AuthFormTitle isOnly>{t("confirm_registration_page:header")}</AuthFormTitle>
      <ConfirmRegistration />
    </>
  );
}

export default ConfirmRegistrationPage;
