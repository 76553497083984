import _ from "lodash";

import transformMappingsToArray from "../airbnb_mapping/utils/transform_mappings_to_array";

const addDeletedOptionsInMappings = (mappingSchema, mappings, mappingOptions) => {
  if (!mappingOptions) {
    return mappingOptions;
  }

  const newMappingOptions = _.cloneDeep(mappingOptions);
  const mappingsAsArray = transformMappingsToArray(mappings);

  mappingsAsArray.forEach(({ mapping }) => {
    Object.entries(mapping).forEach(([paramName, value]) => {
      const optionName = `${paramName}_dictionary`;

      if (!newMappingOptions[optionName]) {
        return;
      }

      const isExists = newMappingOptions[optionName].values.find((v) => v.id === value);

      if (isExists) {
        return;
      }

      const settingTitle = mappingSchema[paramName].title;
      newMappingOptions[optionName].values.push({
        id: value,
        title: `${settingTitle}: ${value} (deleted)`,
        isDeleted: true,
      });
    });
  });

  return newMappingOptions;
};

export default addDeletedOptionsInMappings;
