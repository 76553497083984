import getThreads from "utils/merge_two_arrays";

import {
  CHANNEL_MESSAGES_ADD,
  CHANNEL_MESSAGES_ADD_THREAD,
  CHANNEL_MESSAGES_LOAD,
  CHANNEL_MESSAGES_RESET,
  CHANNEL_MESSAGES_RESET_THREADS,
  CHANNEL_MESSAGES_THREAD_CLOSE,
  CHANNEL_MESSAGES_THREAD_OPEN,
  CHANNEL_MESSAGES_THREADS_LOAD,
  CHANNEL_MESSAGES_THREADS_SET_REQUEST_ID,
  CHANNEL_MESSAGES_UPDATE_THREAD_BY_LATEST_MESSAGE,
} from "../constants";

const initialState = {
  threads: {
    list: null,
    meta: null,
  },
  messages: {},
};

const ACTION_HANDLERS = {
  [CHANNEL_MESSAGES_THREADS_LOAD]: (state, action) => {
    const data = action.payload.threads;
    const payloadRequestId = action.payload.requestId;
    const actualRequestId = state.threads.requestId;
    const isStaleRequest = payloadRequestId !== actualRequestId;

    if (isStaleRequest) {
      return state;
    }

    const threads = state.threads.list || [];

    const updatedThreads = getThreads(data, threads).sort(
      (a, b) => new Date(b.attributes.lastMessageReceivedAt) - new Date(a.attributes.lastMessageReceivedAt),
    );

    return {
      ...state,
      threads: {
        list: updatedThreads,
        meta: action.payload.meta,
      },
    };
  },
  [CHANNEL_MESSAGES_ADD_THREAD]: (state, action) => {
    const thread = action.payload;
    const threads = state.threads.list || [];

    const updatedThreads = getThreads([thread], threads);

    return {
      ...state,
      threads: {
        ...state.threads,
        list: updatedThreads,
        meta: {
          ...state.threads.meta,
        },
      },
    };
  },
  [CHANNEL_MESSAGES_THREAD_CLOSE]: (state, action) => {
    const threadId = action.payload;

    const updateThreads = state.threads.list.filter((thread) => thread.id !== threadId);

    return {
      ...state,
      threads: {
        list: updateThreads,
        meta: {
          ...state.threads.meta,
          total: state.threads.meta.total - 1,
        },
      },
    };
  },
  [CHANNEL_MESSAGES_THREAD_OPEN]: (state, action) => {
    const threadId = action.payload;

    const updateThreads = state.threads.list.filter((thread) => thread.id !== threadId);

    return {
      ...state,
      threads: {
        list: updateThreads,
        meta: {
          ...state.threads.meta,
          total: state.threads.meta.total - 1,
        },
      },
    };
  },
  [CHANNEL_MESSAGES_RESET_THREADS]: (state) => {
    return {
      ...state,
      threads: {
        list: null,
        meta: null,
      },
    };
  },
  [CHANNEL_MESSAGES_THREADS_SET_REQUEST_ID]: (state, action) => {
    return {
      ...state,
      threads: {
        ...state.threads,
        requestId: action.payload,
      },
    };
  },
  [CHANNEL_MESSAGES_UPDATE_THREAD_BY_LATEST_MESSAGE]: (state, action) => {
    const { threadId, message } = action.payload;

    const newThreads = state.threads.list
      .map((thread) => {
        const isThread = thread.id === threadId;
        if (isThread) {
          const newThread = {
            ...thread,
            attributes: {
              ...thread.attributes,
              lastMessage: {
                ...thread.attributes.lastMessage,
                insertedAt: new Date(),
                message,
              },
            },
          };
          return newThread;
        }

        return thread;
      })
      .sort(
        (a, b) => new Date(b.attributes.lastMessage.insertedAt)
          - new Date(a.attributes.lastMessage.insertedAt),
      );

    return {
      ...state,
      threads: {
        ...state.threads,
        list: newThreads,
      },
    };
  },
  [CHANNEL_MESSAGES_LOAD]: (state, action) => {
    const { threadId, messages, meta } = action.payload;

    const oldMessages = state.messages[threadId]?.list || [];

    const updatedMessages = messages.reduce((acc, message) => {
      const isMessagePresent = acc.find(({ id }) => id === message.id);

      if (isMessagePresent) {
        return acc;
      }

      return [...acc, message];
    }, oldMessages);

    return {
      ...state,
      messages: {
        ...state.messages,
        [threadId]: {
          list: updatedMessages,
          meta,
        },
      },
    };
  },
  [CHANNEL_MESSAGES_ADD]: (state, action) => {
    const { threadId, message } = action.payload;
    const { list = [], meta = {} } = state.messages[threadId] || {};

    let newMessageList = [];
    let newTotal = meta.total;

    const messagePositionById = list.findIndex((msg) => msg.id === message.id);
    const messagePositionByRequestId = list.findIndex((msg) => msg.requestId === message.requestId);
    const messagePosition = Math.max(messagePositionById, messagePositionByRequestId);

    if (messagePosition === -1) {
      newMessageList = [message, ...list];
      newTotal += 1;
    } else {
      newMessageList = [
        ...list.slice(0, messagePosition),
        message,
        ...list.slice(messagePosition + 1, list.length),
      ];
    }

    return {
      ...state,
      messages: {
        ...state.messages,
        [threadId]: {
          list: newMessageList,
          meta: {
            ...meta,
            total: newTotal,
          },
        },
      },
    };
  },
  [CHANNEL_MESSAGES_RESET]: (state, action) => {
    const { threadId } = action.payload;

    const updatedMessages = { ...state.messages, [threadId]: {} };

    return {
      ...state,
      messages: updatedMessages,
    };
  },
};

export default function channelMessagesThreadsReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
