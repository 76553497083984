import store from "store";

export default function (billingAccountId, report) {
  return (_event) => {
    store.transport
      ._get(`billing_accounts/${billingAccountId}/report/${report}`)
      .then((data) => data.blob())
      .then((blob) => {
        const anchor = document.createElement("a");
        const objectUrl = window.URL.createObjectURL(blob);

        document.body.appendChild(anchor);

        anchor.href = objectUrl;
        anchor.download = "report.csv";
        anchor.click();
        document.body.removeChild(anchor);

        window.URL.revokeObjectURL(objectUrl);
      });
  };
}
