import React from "react";
import { SortableElement } from "react-sortable-hoc";
import { MenuOutlined } from "@ant-design/icons";

import styles from "./sortable_item.module.css";

export default SortableElement(({ title }) => (
  <div className={styles.sortableContainer}>
    <MenuOutlined className={styles.sortableIcon} /> {title}
  </div>
));
