import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import store from "store";
import { getUser } from "store/storage/selectors/session_selector";

import Billing from "./billing";
import ChangePassword from "./change_password";
import RemoveUserAccount from "./remove_user_account";
import Sections from "./sections";
import UserApiKeysTable from "./user_api_keys_table";
import UserProfile from "./user_profile";
import UserUiSettings from "./user_ui_settings";

const { Profiles } = store;

export default function UserProfilePage() {
  const user = useSelector(getUser);
  const [apiKeyAccessEnabled, setApiKeyAccessEnabled] = useState(false);

  useEffect(function loadUserProfile() {
    Profiles.get().then((result) => setApiKeyAccessEnabled(result.is_api_key_allowed));
  }, []);

  return (
    <Sections>
      <UserProfile />
      <ChangePassword />
      <UserUiSettings />
      <Billing user={user} />
      {apiKeyAccessEnabled && <UserApiKeysTable />}
      <RemoveUserAccount />
    </Sections>
  );
}
