import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Alert } from "antd";

import NoDataPlaceholder from "components/no_data_placeholder";

class NoListingPlaceholder extends Component {
  render() {
    const { t } = this.props;

    return (
      <>
        <Alert type="info" showIcon description={t("channels_page:form:listing:empty_message")} />
        <NoDataPlaceholder emptyMessage={t("channels_page:form:listing:empty_placeholder")} />
      </>
    );
  }
}

export default withTranslation()(NoListingPlaceholder);
