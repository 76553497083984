import React from "react";
import { useTranslation } from "react-i18next";

import ChannexDrawer from "drawers/channex_drawer";

import DrawerFormWrapper from "components/drawer_form_wrapper/drawer_form_wrapper";

import ManageSubscriptionForm from "./manage_subscription_form";

export default function ManageSubscriptionDrawer({ visible, billingAccountId, onClose }) {
  const { t } = useTranslation();

  const title = t("billing_accounts_sub_page:create_subscription:header");

  return (
    <ChannexDrawer title={title} visible={visible} onClose={onClose}>
      {() => (
        <DrawerFormWrapper>
          <ManageSubscriptionForm billingAccountId={billingAccountId} onFinish={onClose} />
        </DrawerFormWrapper>
      )}
    </ChannexDrawer>
  );
}
