import attributesExtractor from "../utils/attributes_extractor";
import { convertToCamelCase, convertToSnakeCase } from "../utils/case_converter";

let transport;

const ENDPOINT = "admin/wl_email_settings";

export default class AdminWlEmailSettings {
  constructor(container) {
    transport = container.transport;
  }

  list(filter = {}, pagination = { page: 1, limit: 10 }, order) {
    const normalizedFilters = convertToSnakeCase(filter);

    return transport
      .send("GET", ENDPOINT, { filter: normalizedFilters, pagination, order })
      .then(convertToCamelCase)
      .then(({ data, meta }) => {
        return {
          data: attributesExtractor(data),
          meta,
        };
      });
  }

  create(whiteLabelPartnerId, attrs) {
    const convertedAttrs = convertToSnakeCase({
      ...attrs,
      wlPartnerId: whiteLabelPartnerId,
    });

    return transport
      .send("POST", ENDPOINT, convertedAttrs)
      .then(convertToCamelCase)
      .then((response) => {
        return attributesExtractor(response.data);
      });
  }

  update(id, attrs) {
    const body = convertToSnakeCase({
      wlEmailSetting: attrs,
    });

    return transport
      .send("PUT", `${ENDPOINT}/${id}`, body)
      .then(convertToCamelCase)
      .then((response) => {
        return attributesExtractor(response.data);
      });
  }

  delete(id) {
    return transport.send("DELETE", `${ENDPOINT}/${id}`);
  }
}
