const getInstalledApp = (installedApps, app) => {
  return installedApps.find((installedApp) => {
    return installedApp.applicationId === app.id;
  });
};

const mergeAppInfo = (app, installedApp = {}) => {
  const { propertyId, id: installationId } = installedApp;

  return {
    ...app,
    applicationId: app.id,
    propertyId,
    installationId,
  };
};

const mergeAppLists = (allApps, installedApps) => {
  return allApps.map((app) => {
    const installedApp = getInstalledApp(installedApps, app);

    return mergeAppInfo(app, installedApp);
  });
};

export default mergeAppLists;
