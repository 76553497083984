const Left = {
  postfix: "left",
  position: {
    left: 0,
    right: "auto",
  },
};

const Right = {
  postfix: "right",
  position: {
    left: "auto",
    right: 0,
  },
};

const Center = {
  postfix: "center",
  position: {
    left: "auto",
    right: "auto",
  },
};

export default {
  guest: Left,
  property: Right,
  system: Center,
};
