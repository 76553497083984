import React, { useCallback } from "react";

import MappingRow from "components/mapping/mapping_row";
import RateTitleWithId from "components/rates/title_with_id";

import useBoolState from "utils/use_bool_state";

export default function BlockedListing({ listing, onDisconnect }) {
  const [loading, setLoading, setLoaded] = useBoolState(false);

  const handleListingDisconnect = useCallback(() => {
    setLoading();

    onDisconnect(listing.id).finally(setLoaded);
  }, [listing, setLoading, setLoaded, onDisconnect]);

  return (
    <MappingRow
      isMapped
      isError
      loading={loading}
      disabled={loading}
      title={<RateTitleWithId rate={listing} />}
      onDelete={handleListingDisconnect}
    />
  );
}
