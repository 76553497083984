import React from "react";
import { useTranslation } from "react-i18next";
import { Select } from "antd";

import RatePlanTitle from "components/rates/title";

import FormSelect from "../form_select";

export const RatePlanSelect = ({ name, label, placeholder, ratePlans, isLoading, isDisabled, control, ...rest }) => {
  const { t } = useTranslation();

  const DEFAULT_LABEL = t("rate_plans:input:label");
  const DEFAULT_PLACEHOLDER = t("rate_plans:input:select_placeholder");

  return (
    <FormSelect
      control={control}
      name={name || "ratePlanId"}
      label={label || DEFAULT_LABEL}
      placeholder={placeholder || DEFAULT_PLACEHOLDER}
      showSearch
      options={ratePlans}
      optionRenderer={
        (ratePlan) => (
          <Select.Option key={ratePlan.id} label={ratePlan.title}>
            <RatePlanTitle inSelect rate={ratePlan} />
          </Select.Option>
        )
      }
      filterOption={(input, option) => (option?.label || "").toLowerCase().includes(input.toLowerCase())}
      loading={isLoading}
      disabled={isDisabled}
      {...rest}
    />
  );
};
