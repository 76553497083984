import dayjs from "utils/dayjs";

export default (startDate, endDate) => {
  const days = [];
  const dateStart = dayjs(startDate);
  const dateEnd = dayjs(endDate);
  let day = dateStart;

  while (day <= dateEnd) {
    days.push(day.format("YYYY-MM-DD"));
    day = day.add(1, "d");
  }

  return days;
};
