import React from "react";
import { isMobile } from "react-device-detect";
import { CloseCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { Image } from "antd";

import styles from "./styles.module.css";

const AttachmentImage = ({ src, loading }) => {
  const img = (
    <Image
      className={styles.attachedImage}
      preview={!loading}
      src={src}
      alt="file"
      width={60}
      height={60}
    />
  );

  if (loading) {
    return (
      <div className={styles.loadingImageContainer}>
        <div className={styles.loadingOverlay}>
          <LoadingOutlined style={{ color: "#fff" }} />
        </div>
        {img}
      </div>
    );
  }

  return img;
};

export default function AttachedFile({ file, onDelete }) {
  const [isHover, setIsHover] = React.useState(false);

  const showDeleteButton = isHover || isMobile;

  return (
    <div
      className={styles.attachedFile}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <span>
        {file.status === "uploading" && (
          <div>
            <AttachmentImage src={file.data} loading />
          </div>
        )}
        {file.status === "success" && (
          <AttachmentImage src={file.data} />
        )}
      </span>

      {showDeleteButton && (
        <CloseCircleOutlined
          className={styles.deleteAttachmentButton}
          onClick={() => onDelete(file)}
        />
      )}
    </div>
  );
}
