import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Modal } from "antd";
import classnames from "classnames";

import Avatar from "components/users/avatar/avatar";

import { toLocalFromNow } from "utils/dates";

import { MessageContent } from "./message_content";
import MessageStatus from "./message_status";
import messagesDirections from "./messages_directions";

import styles from "./message.module.css";

const LEFT_DIRECTION = "left";
const RIGHT_DIRECTION = "right";
const MODAL_WIDTH = "fit-content";

function MessagesItem({ item, style, handleResendMessage, sender, receiver }) {
  const [imgUrl, setImgUrl] = useState(null);

  const { attributes, requestId } = item;

  const { message, insertedAt, sender: senderType, status } = attributes;
  const insertedAtValue = toLocalFromNow(insertedAt);

  const onCloseModal = () => setImgUrl(null);

  const avatarName = useMemo(() => {
    const direction = messagesDirections[senderType].postfix;
    if (direction === LEFT_DIRECTION) {
      return sender;
    }
    if (direction === RIGHT_DIRECTION) {
      return receiver;
    }
    return "";
  }, [sender, receiver, senderType]);

  const wrapperClassName = classnames(
    styles.messageWrapper,
    styles[`messageWrapper__${messagesDirections[senderType].postfix}`],
  );

  const onResendMessage = useCallback(() => {
    handleResendMessage({ message }, requestId);
  }, [message, requestId, handleResendMessage]);

  const wrapperStyle = { ...style, ...messagesDirections[senderType].position };

  return (
    <>
      <div className={wrapperClassName} style={wrapperStyle}>
        <p className={styles.messageInsertedAtValue}>{insertedAtValue}</p>
        <div className={styles.messageInner}>
          <div className={styles.messageAvatar}>
            <Avatar name={avatarName} />
          </div>
          <MessageContent
            messageItem={item}
            onShowImage={setImgUrl}
          />
          <MessageStatus status={status} handleResendMessage={onResendMessage} />
        </div>
      </div>
      <Modal
        width={MODAL_WIDTH}
        className={styles.attachment_modal}
        open={!!imgUrl}
        destroyOnClose
        footer={null}
        onCancel={onCloseModal}
      >
        <img src={imgUrl} className={styles.attachment_modal_img} alt="attachment" />
      </Modal>
    </>
  );
}

MessagesItem.propTypes = {
  item: PropTypes.object,
  style: PropTypes.object,
  handleResendMessage: PropTypes.func,
  sender: PropTypes.string,
  receiver: PropTypes.string,
};

export default MessagesItem;
