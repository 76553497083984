import { USER_API_KEY_SET, USER_API_KEYS_LOAD } from "../constants";

export function userApiKeysLoad(storage) {
  return function (keys, meta) {
    storage.dispatch({ type: USER_API_KEYS_LOAD, payload: { keys, meta } });
  };
}

export function userApiKeySet(storage) {
  return function (data) {
    storage.dispatch({ type: USER_API_KEY_SET, payload: data });
  };
}
