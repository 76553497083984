import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Alert } from "antd";

import ChannexModal from "components/channex_modal";

import ErrorSection from "./error_section";

import styles from "./inventory_error_modal.module.css";

const getErrorSectionTitles = (t, key) => {
  const titles = {
    restrictionErrors: `${t("inventory_page:error_modal:restriction_errors")}:`,
    availabilityErrors: `${t("inventory_page:error_modal:availability_errors")}:`,
  };

  return titles[key];
};

export default function InventoryErrorModal({ errors, ratePlansById, visible, onClose }) {
  const { t } = useTranslation();

  const errorSections = useMemo(() => {
    return Object.keys(errors)
      .filter((sectionKey) => Boolean(errors[sectionKey]))
      .map((sectionKey) => (
        <ErrorSection
          key={sectionKey}
          errors={errors[sectionKey]}
          ratePlansById={ratePlansById}
          title={getErrorSectionTitles(t, sectionKey)}
        />
      ));
  }, [t, errors, ratePlansById]);

  return (
    <ChannexModal
      title={t("inventory_page:error_modal:title")}
      onOk={onClose}
      visible={visible}
      cancelButtonProps={{
        className: styles.cancelButton,
      }}
    >
      <>
        <Alert message={t("inventory_page:error_modal:description")} type="warning" />
        <div className={styles.errorSection}>{errorSections}</div>
      </>
    </ChannexModal>
  );
}
