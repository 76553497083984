import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
  CrownOutlined,
  DeleteOutlined,
  DownOutlined,
  EnvironmentOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { Button, Col, Dropdown, Form, Modal, Row, Table, Tabs } from "antd";
import { isUndefined } from "lodash";
import store from "store";

import APP_MODES from "config/constants/app_modes";
import { horizontalFormItemLayout } from "config/constants/layouts/form";
import PROPERTY_CATEGORIES from "config/constants/property_categories";
import PROPERTY_TYPE_OPTIONS from "config/constants/property_type_options";
import { features } from "config/feature_flags";

import WebhooksCRUD from "pages/webhooks_page/components/webhooks_crud";

import CRUDTopBar from "components/crud_table/crud_top_bar";
import CopyToClipboard from "components/forms/buttons/copy_to_clipboard/copy_to_clipboard";
import SubmitButton from "components/forms/buttons/submit_button";
import FormCountrySelect from "components/forms/inputs/form_country_select";
import FormCurrencySelect from "components/forms/inputs/form_currency_select";
import FormInput from "components/forms/inputs/form_input";
import FormSelect from "components/forms/inputs/form_select";
import Loading from "components/loading";
import Map from "components/map/map";
import User from "components/user/user";

import { withTimezones } from "hooks/use_timezones/hoc";

import { pathBuilder } from "routing";

import alphabetSort from "utils/alphabet_sort";
import EventEmitter from "utils/event_emitter";

import ContentForm from "./components/content_form";
import PolicyView from "./components/policy_view";
import SettingsForm from "./components/settings_form";
import TaxTable from "./components/tax_table";
import withLogic from "./property_form_logic";
import withGeoCoding from "./with_geo_coding";

import styles from "./property_form.module.css";
import drawerStyles from "drawers/channex_drawer.module.css";
import formStyles from "styles/form_in_drawer.module.css";
import generalStyles from "styles/general.module.css";
import tableStyles from "styles/tables.module.css";

const { PropertyUsers, Properties, PropertyFacilities, Auth, BillingAccounts } = store;

const DEFAULT_MODEL = {
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  settings: {
    allow_availability_autoupdate: true,
    allow_availability_autoupdate_on_confirmation: true,
    allow_availability_autoupdate_on_modification: true,
    allow_availability_autoupdate_on_cancellation: true,
    min_price: null,
    max_price: null,
  },
};

const SORTER_FACILITIES = "title";

const DEFAULT_TAB = "1";

class PropertyForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func,
    model: PropTypes.object,
    t: PropTypes.func.isRequired,
    id: PropTypes.string,
  };

  state = {
    loading: false,
    loaded: false,
    searchQuery: "",
    facilitiesOptions: [],
    errors: {},
    formValue: DEFAULT_MODEL,
    userAccesses: [],
    activeTab: this.props.defaultTab || DEFAULT_TAB,
    filteredUserAccesses: [],
    billingAccount: null,
  };

  inputRefs = {
    title: React.createRef(),
    group_id: React.createRef(),
    currency: React.createRef(),
    property_type: React.createRef(),
    billing_type: React.createRef(),
    email: React.createRef(),
    phone: React.createRef(),
    website: React.createRef(),
    country: React.createRef(),
    timezone: React.createRef(),
    zip_code: React.createRef(),
    state: React.createRef(),
    city: React.createRef(),
    address: React.createRef(),
    latitude: React.createRef(),
    longitude: React.createRef(),
    location_precision: React.createRef(),
    max_count_of_room_types: React.createRef(),
    max_count_of_rate_plans: React.createRef(),
    max_count_of_occupancies: React.createRef(),
    max_count_of_users: React.createRef(),
  };

  componentDidMount() {
    EventEmitter.bind("property_users:reload", this.listReloadListener);

    this.loadData();
  }

  componentDidUpdate(prevProps) {
    const { focusField } = this.props;

    if (focusField && (prevProps.focusField !== focusField)) {
      this.inputRefs?.[focusField]?.current?.focus();
    }
  }

  componentWillUnmount() {
    EventEmitter.unbind("property_users:reload", this.listReloadListener);
  }

  listReloadListener = () => {
    this.setState({
      searchQuery: "",
    });

    this.loadUserAccesses();
  };

  loadData = () => {
    const { id } = this.props;
    const { focusField } = this.props;

    this.loadOptions();
    this.loadBillingAccount();
    this.loadUserAccesses();

    if (!id) {
      this.setState({ loaded: true }, () => {
        this.inputRefs?.[focusField]?.current?.focus();
      });

      return;
    }

    Properties.find(id).then((property) => {
      const facilities = Object.keys(property.facilities);
      const settings = { min_stay_type: property.min_stay_type, ...property.settings };

      const formValue = {
        ...property,
        settings,
        facilities,
      };

      this.setState({ loaded: true, formValue }, () => {
        this.inputRefs?.[focusField]?.current?.focus();
      });
    });
  };

  loadOptions = () => {
    this.loadFacilities();
  };

  loadFacilities = () => {
    return PropertyFacilities.options().then((facilitiesOptions) => {
      const sortOptions = facilitiesOptions.sort(alphabetSort(SORTER_FACILITIES));

      this.setState({ facilitiesOptions: sortOptions });
    });
  };

  loadUserAccesses = () => {
    const { id } = this.props;

    if (!id) {
      return;
    }

    PropertyUsers.list({ property_id: id }).then((result) => {
      const userAccesses = Object.values(result).map((propertyUser) => {
        return {
          id: propertyUser.id,
          overrides: propertyUser.overrides,
          role: propertyUser.role,
          user: propertyUser.user,
          property: propertyUser.property,
        };
      });

      this.setState({
        userAccesses,
        filteredUserAccesses: userAccesses,
      });
    });
  };

  loadBillingAccount = () => {
    BillingAccounts.info().then(
      (billingAccount) => {
        if (isUndefined(this.state.formValue.settings.min_stay_type)) {
          this.setState((prevState) => ({
            formValue: {
              ...prevState.formValue,
              settings: {
                ...prevState.formValue.settings,
                min_stay_type: billingAccount.minStayType,
              },
            },
          }));
        }
        this.setState({ billingAccount });
      },
      (_error) => {},
    );
  };

  onSuccess = (newProperty) => {
    const { onClose, id } = this.props;

    if (!id) {
      this.updateActiveProperty(newProperty);
    }

    onClose();
  };

  updateActiveProperty = (newProperty) => {
    const { activeProperty, activeGroup } = this.props;
    const { id, groups } = newProperty;

    const assignedGroupIds = Object.keys(groups);
    const isMismatchedGroupSelected = activeGroup && !assignedGroupIds.includes(activeGroup);

    if (activeProperty || isMismatchedGroupSelected) {
      Auth.chooseProperty(id);
      Auth.chooseGroup(null);
    }
  };

  onError = (error) => {
    this.setState({ loading: false });

    if (!error.isValidationError) {
      throw error;
    }

    this.setState({
      errors: error.body.errors.details,
    });
  };

  handleSubmit = () => {
    const { onSubmit } = this.props;

    this.setState({ loading: true });
    const { formValue } = this.state;

    onSubmit(formValue, this.onSuccess, this.onError);
  };

  handleActiveTabChange = (activeTab) => {
    this.setState({ activeTab });
  };

  getGroupOptions = () => {
    const { groupsOptions } = this.props;

    return groupsOptions.map((el) => {
      return {
        value: el.id,
        representation: el.title,
      };
    });
  };

  getTypeOptions = () => {
    const { t } = this.props;

    return PROPERTY_TYPE_OPTIONS.map((type) => ({
      value: type,
      representation: t(`property_types:${type}`),
    }));
  };

  getFacilitiesOptions() {
    const { t } = this.props;
    const { facilitiesOptions } = this.state;

    return facilitiesOptions.map(({ id, title, category }) => ({
      value: id,
      representation: title,
      category: t(`facility:${category}`),
    }));
  }

  getTimezoneOptions = () => {
    const { timezones } = this.props;

    return timezones.map((tz) => ({
      value: tz.value,
      representation: tz.label.replace("_", " "),
    }));
  };

  getForm = () => {
    const { t, geocoding, groupsOptions } = this.props;
    const { loading, errors, formValue } = this.state;

    const country = formValue.country || undefined;
    const timezone = formValue.timezone || undefined;
    const isAdmin = this.isAdmin();

    const isGroupsLoaded = Array.isArray(groupsOptions);
    const isGroupSelectShown = isGroupsLoaded && groupsOptions.length > 1 && !formValue.id;

    if (isGroupsLoaded && groupsOptions.length === 1) {
      formValue.group_id = groupsOptions[0].id;
    }

    return (
      <Form onFinish={this.handleSubmit}>
        {formValue.id && (
          <Form.Item data-cy="property_id" {...horizontalFormItemLayout} label={t("general:id")}>
            {formValue.id}
            <CopyToClipboard text={formValue.id} />
          </Form.Item>
        )}
        <FormInput
          view="horizontal"
          type="text"
          name="title"
          placeholder={t("properties_page:form:title_placeholder")}
          label={t("properties_page:form:title_label")}
          defaultValue={formValue.title}
          inputRef={this.inputRefs.title}
          onChange={this.handleChange}
          errors={errors ? errors.title : null}
        />
        {isGroupSelectShown && (
          <FormSelect
            view="horizontal"
            name="group_id"
            placeholder={t("properties_page:form:group_placeholder")}
            onChange={this.handleChange}
            label={t("properties_page:form:group_label")}
            defaultValue={formValue.group_id}
            value={formValue.group_id}
            inputRef={this.inputRefs.group_id}
            errors={errors ? errors.group_id : null}
            options={this.getGroupOptions()}
          />
        )}
        <FormCurrencySelect
          view="horizontal"
          name="currency"
          placeholder={t("properties_page:form:currency_placeholder")}
          onChange={this.handleChange}
          value={formValue.currency}
          label={t("properties_page:form:currency_label")}
          defaultValue={formValue.currency}
          inputRef={this.inputRefs.currency}
          errors={errors ? errors.currency : null}
        />
        <FormSelect
          view="horizontal"
          name="property_type"
          placeholder={t("properties_page:form:type_placeholder")}
          onChange={this.handleChange}
          value={formValue.property_type}
          label={t("properties_page:form:type_label")}
          defaultValue={formValue.property_type}
          options={this.getTypeOptions()}
          inputRef={this.inputRefs.property_type}
          errors={errors ? errors.property_type : null}
        />
        <FormInput
          view="horizontal"
          name="billing_type"
          type="text"
          disabled
          label={t("properties_page:form:billing_type")}
          value={t(`properties_page:form:${PROPERTY_CATEGORIES[formValue.property_type]}`)}
          inputRef={this.inputRefs.billing_type}
        />
        <legend>{t("properties_page:form:contacts_legend")}</legend>
        <FormInput
          view="horizontal"
          name="email"
          type="text"
          placeholder={t("properties_page:form:email_placeholder")}
          onChange={this.handleChange}
          label={t("properties_page:form:email_label")}
          defaultValue={formValue.email}
          inputRef={this.inputRefs.email}
          errors={errors ? errors.email : null}
        />
        <FormInput
          view="horizontal"
          name="phone"
          type="text"
          placeholder={t("properties_page:form:phone_placeholder")}
          onChange={this.handleChange}
          label={t("properties_page:form:phone_label")}
          defaultValue={formValue.phone}
          inputRef={this.inputRefs.phone}
          errors={errors ? errors.phone : null}
        />
        <FormInput
          view="horizontal"
          name="website"
          type="text"
          placeholder={t("properties_page:form:website_placeholder")}
          onChange={this.handleChange}
          label={t("properties_page:form:website_label")}
          defaultValue={formValue.website}
          inputRef={this.inputRefs.website}
          errors={errors ? errors.website : null}
        />
        {isAdmin && (
          <>
            <legend>{t("properties_page:form:limits_legend")}</legend>
            <FormInput
              view="horizontal"
              name="max_count_of_room_types"
              type="number"
              placeholder={t("properties_page:form:max_count_of_room_types_placeholder")}
              onChange={this.handleChange}
              label={t("properties_page:form:max_count_of_room_types_label")}
              defaultValue={formValue.max_count_of_room_types}
              inputRef={this.inputRefs.max_count_of_room_types}
              errors={errors ? errors.max_count_of_room_types : null}
            />
            <FormInput
              view="horizontal"
              name="max_count_of_rate_plans"
              type="number"
              placeholder={t("properties_page:form:max_count_of_rate_plans_placeholder")}
              onChange={this.handleChange}
              label={t("properties_page:form:max_count_of_rate_plans_label")}
              defaultValue={formValue.max_count_of_rate_plans}
              inputRef={this.inputRefs.max_count_of_rate_plans}
              errors={errors ? errors.max_count_of_rate_plans : null}
            />
            <FormInput
              view="horizontal"
              name="max_count_of_occupancies"
              type="number"
              placeholder={t("properties_page:form:max_count_of_occupancies_placeholder")}
              onChange={this.handleChange}
              label={t("properties_page:form:max_count_of_occupancies_label")}
              defaultValue={formValue.max_count_of_occupancies}
              inputRef={this.inputRefs.max_count_of_occupancies}
              errors={errors ? errors.max_count_of_occupancies : null}
            />
            <FormInput
              view="horizontal"
              name="max_count_of_users"
              type="number"
              placeholder={t("properties_page:form:max_count_of_users_placeholder")}
              onChange={this.handleChange}
              label={t("properties_page:form:max_count_of_users_label")}
              defaultValue={formValue.max_count_of_users}
              inputRef={this.inputRefs.max_count_of_users}
              errors={errors ? errors.max_count_of_users : null}
            />
          </>
        )}
        <legend>{t("properties_page:form:location_legend")}</legend>
        <FormCountrySelect
          view="horizontal"
          name="country"
          placeholder={t("properties_page:form:country_placeholder")}
          label={t("properties_page:form:country_label")}
          value={country}
          inputRef={this.inputRefs.country}
          onChange={this.handleChange}
          errors={errors ? errors.country : null}
        />
        <FormSelect
          view="horizontal"
          name="timezone"
          placeholder={t("properties_page:form:timezone_placeholder")}
          label={t("properties_page:form:timezone_label")}
          value={timezone}
          onChange={this.handleChange}
          errors={errors ? errors.timezone : null}
          inputRef={this.inputRefs.timezone}
          options={this.getTimezoneOptions()}
        />
        <FormInput
          view="horizontal"
          name="zip_code"
          type="text"
          placeholder={t("properties_page:form:zip_code_placeholder")}
          label={t("properties_page:form:zip_code_label")}
          value={formValue.zip_code}
          inputRef={this.inputRefs.zip_code}
          onChange={this.handleChange}
          errors={errors ? errors.zip_code : null}
        />
        <FormInput
          view="horizontal"
          name="state"
          type="text"
          placeholder={t("properties_page:form:state_placeholder")}
          label={t("properties_page:form:state_label")}
          value={formValue.state}
          inputRef={this.inputRefs.state}
          onChange={this.handleChange}
          errors={errors ? errors.state : null}
        />
        <FormInput
          view="horizontal"
          name="city"
          type="text"
          placeholder={t("properties_page:form:city_placeholder")}
          label={t("properties_page:form:city_label")}
          value={formValue.city}
          inputRef={this.inputRefs.city}
          onChange={this.handleChange}
          errors={errors ? errors.city : null}
        />
        <FormInput
          view="horizontal"
          name="address"
          type="text"
          className={styles.addressInput}
          placeholder={t("properties_page:form:address_placeholder")}
          label={t("properties_page:form:address_label")}
          value={formValue.address}
          inputRef={this.inputRefs.address}
          onChange={this.handleChange}
          errors={errors ? errors.address : null}
        />
        <Row>
          <Col xs={{ span: 24, offset: 0 }} sm={{ span: 16, offset: 8 }}>
            <Button
              type="primary"
              onClick={this.handleGeoCodeAddressClick}
              icon={<EnvironmentOutlined />}
              loading={geocoding.isLoading}
              className={styles.findLocationButton}
            >
              {t("properties_page:form:find_location")}
            </Button>
          </Col>
        </Row>
        <FormInput
          view="horizontal"
          name="latitude"
          type="number"
          placeholder={t("properties_page:form:latitude_placeholder")}
          label={t("properties_page:form:latitude_label")}
          value={formValue.latitude}
          inputRef={this.inputRefs.latitude}
          onChange={this.handleChange}
          errors={errors ? errors.address : null}
        />
        <FormInput
          view="horizontal"
          name="longitude"
          type="number"
          placeholder={t("properties_page:form:longitude_placeholder")}
          label={t("properties_page:form:longitude_label")}
          value={formValue.longitude}
          inputRef={this.inputRefs.longitude}
          onChange={this.handleChange}
          errors={errors ? errors.address : null}
        />
        {PROPERTY_CATEGORIES[formValue.property_type] === "vacation_rental" && (
          <FormInput
            view="horizontal"
            name="location_precision"
            type="number"
            min={0}
            max={2000}
            placeholder={t("properties_page:form:location_precision_placeholder")}
            label={t("properties_page:form:location_precision_label")}
            value={formValue.location_precision}
            inputRef={this.inputRefs.location_precision}
            onChange={this.handleChange}
            errors={errors ? errors.location_precision : null}
          />
        )}
        <Map
          onChangeCoords={this.handleCoordsChange}
          lat={formValue.latitude}
          lng={formValue.longitude}
        />
        <div className={formStyles.actions}>
          <SubmitButton loading={loading}>{t("properties_page:submit_button")}</SubmitButton>
        </div>
      </Form>
    );
  };

  handleSearchChange = (searchQuery) => {
    const { userAccesses } = this.state;

    const filteredUserAccesses = userAccesses.filter((userAccess) => {
      const { user } = userAccess;
      const email = (user.email || "").toLowerCase();
      const name = (user.name || "").toLowerCase();

      return email.includes(searchQuery) || name.includes(searchQuery);
    });

    this.setState({
      searchQuery,
      filteredUserAccesses,
    });
  };

  handleRevokeAccessClick = (user) => {
    const { t } = this.props;

    Modal.confirm({
      title: t("general:revoke_access_dialog:title"),
      content: t("general:revoke_access_dialog:description"),
      onOk: () => {
        PropertyUsers.revokeAccess(user.id)
          .then(() => {
            EventEmitter.trigger("property_users:reload");
          });
      },
    });
  };

  handleMakeOwnerClick = (propertyUser) => {
    const { t } = this.props;

    Modal.confirm({
      title: t("general:make_owner_dialog:title"),
      content: t("general:make_owner_dialog:description"),
      onOk: () => {
        PropertyUsers.makeOwner(propertyUser.id)
          .then(() => {
            EventEmitter.trigger("property_users:reload");
          });
      },
    });
  };

  handleGeoCodeAddressClick = () => {
    const { country, zip_code, state, city, address } = this.state.formValue;
    const { geocoding } = this.props;

    geocoding
      .getByAddress({ country, zip_code, state, city, address })
      .then(({ location }) => {
        this.setState(({ formValue }) => ({
          formValue: {
            ...formValue,
            latitude: location.lat,
            longitude: location.lon,
          },
        }));
      })
      .catch(() => {});
  };

  handleCoordsChange = ({ lat, lng }) => {
    this.setState(({ formValue }) => ({
      formValue: {
        ...formValue,
        latitude: lat,
        longitude: lng,
      },
    }));
  };

  handleChange = (fieldValue, key) => {
    let parsedValue = fieldValue;

    if ((key === "latitude" || key === "longitude") && !isNaN(fieldValue) && fieldValue !== "") {
      parsedValue = parseFloat(fieldValue);
    }

    if (key === "settings") {
      this.setState(({ formValue }) => ({
        formValue: {
          ...formValue,
          min_stay_type: parsedValue.min_stay_type,
        },
      }));
    }

    this.setState(({ formValue }) => ({
      formValue: {
        ...formValue,
        [key]: parsedValue,
      },
    }));
  };

  handleCancellationPolicyChange = (policyId) => {
    const { onSubmit } = this.props;
    const { formValue } = this.state;

    const updatedFormValue = { ...formValue, default_cancellation_policy_id: policyId };

    return onSubmit(
      updatedFormValue,
      this.handlePolicyChangeSuccess(updatedFormValue),
      this.onError,
    );
  };

  handleHotelPolicyChange = (policyId) => {
    const { onSubmit } = this.props;
    const { formValue } = this.state;

    const updatedFormValue = { ...formValue, hotel_policy_id: policyId };

    return onSubmit(
      updatedFormValue,
      this.handlePolicyChangeSuccess(updatedFormValue),
      this.onError,
    );
  };

  handleTaxSetChange = (policyId) => {
    const { onSubmit } = this.props;
    const { formValue } = this.state;

    const updatedFormValue = { ...formValue, default_tax_set_id: policyId };

    return onSubmit(
      updatedFormValue,
      this.handlePolicyChangeSuccess(updatedFormValue),
      this.onError,
    );
  };

  handlePolicyChangeSuccess = (updatedFormValue) => () => {
    this.setState({ formValue: updatedFormValue });
  };

  usersTabIsVisible = () => {
    const { appMode } = this.props;

    if (appMode === APP_MODES.HEADLESS) {
      return this.userManagementIsEnabled();
    }

    return (this.userManagementNotDisabled() && this.hasActiveSubscription()) || this.isAdmin();
  };

  userManagementNotDisabled = () => {
    const { activeFeatureFlags } = this.props;

    return activeFeatureFlags[features.DISABLE_USER_MANAGEMENT] !== true;
  };

  userManagementIsEnabled = () => {
    const { activeFeatureFlags } = this.props;
    return activeFeatureFlags[features.ENABLE_USER_MANAGEMENT];
  };

  isAdmin = () => {
    const { user } = this.props;

    return user.system_role === "admin";
  };

  hasActiveSubscription = () => {
    return this.state.billingAccount?.subscription?.isActive === true;
  };

  showMakeOwnerAction = (propertyUser) => {
    return (
      this.isAdmin()
      && propertyUser.role !== "owner"
      && propertyUser.user.email.indexOf("@api.channex.io") === -1
    );
  };

  renderUsersTab() {
    const { t, id, userInvitePath } = this.props;
    const { searchQuery, filteredUserAccesses } = this.state;

    const columns = [
      {
        title: t("general:user"),
        key: "avatar",
        render: (_text, record) => {
          return (
            <User size={42} name={record.user.name} email={record.user.email} role={record.role} />
          );
        },
      },
      {
        title: t("general:actions"),
        key: "action",
        align: "right",
        render: (_text, record) => {
          const items = [];

          if (this.showMakeOwnerAction(record)) {
            items.push({
              key: "access_make_owner",
              onClick: () => this.handleMakeOwnerClick(record),
              label: (
                <div>
                  <CrownOutlined /> {t("access:make_owner")}
                </div>
              ),
            });
          }

          items.push({
            key: "access_revoke_access",
            onClick: () => this.handleRevokeAccessClick(record),
            label: (
              <div>
                <DeleteOutlined /> {t("access:revoke_access")}
              </div>
            ),
          });

          return (
            <Dropdown menu={{ items }} trigger={["click"]}>
              <a
                className={generalStyles.actionsToggle}
                href="#actions"
                onClick={(event) => event.preventDefault()}
              >
                {t("general:actions")} <DownOutlined />
              </a>
            </Dropdown>
          );
        },
      },
    ];

    return (
      <>
        <CRUDTopBar
          borderless
          actions={
            <Link to={pathBuilder(userInvitePath, { propertyId: id })}>
              <Button data-cy="invite_button" icon={<PlusCircleOutlined />} type="primary">
                {t("access:invite")}
              </Button>
            </Link>
          }
          searchQuery={searchQuery}
          onSearchChange={this.handleSearchChange}
        />
        <Table
          className={tableStyles.fullTabTable}
          columns={columns}
          dataSource={filteredUserAccesses}
          pagination={false}
          showHeader={false}
          rowKey="id"
        />
      </>
    );
  }

  render() {
    const { loaded, formValue, errors, loading, activeTab } = this.state;
    const { t, id, focusField, userInvitePath } = this.props;
    const facilitiesOptions = this.getFacilitiesOptions();

    if (!loaded) {
      return <Loading />;
    }

    const items = [{
      key: "1",
      label: <span data-cy="edit_tab">{t("properties_page:tab_keys:edit")}</span>,
      children: this.getForm(),
    }];

    if (id) {
      if (userInvitePath && this.usersTabIsVisible()) {
        items.push({
          key: "2",
          label: <span data-cy="users_tab">{t("properties_page:tab_keys:users")}</span>,
          children: this.renderUsersTab(),
        });
      }

      items.push({
        key: "3",
        label: <span data-cy="content_tab">{t("properties_page:tab_keys:content")}</span>,
        children: (
          <ContentForm
            value={formValue}
            errors={errors}
            loading={loading}
            facilitiesOptions={facilitiesOptions}
            focusField={focusField}
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
          />
        ),
      });
    }

    items.push({
      key: "4",
      label: <span data-cy="settings_tab">{t("properties_page:tab_keys:settings")}</span>,
      children: (
        <SettingsForm
          value={formValue}
          errors={errors}
          loading={loading}
          focusField={focusField}
          isAdmin={this.isAdmin()}
          onChange={this.handleChange}
          onSubmit={this.handleSubmit}
        />
      ),
    });

    if (id) {
      items.push({
        key: "5",
        label: <span data-cy="policies_tab">{t("properties_page:tab_keys:policies")}</span>,
        children: (
          <PolicyView
            property={formValue}
            handleHotelPolicyChange={this.handleHotelPolicyChange}
            handleCancellationPolicyChange={this.handleCancellationPolicyChange}
          />
        ),
      });

      items.push({
        key: "6",
        label: <span data-cy="tax_sets_tab">{t("properties_page:tab_keys:tax_sets")}</span>,
        children: (
          <TaxTable
            dataCy="tax_set"
            type="taxSet"
            selectedId={formValue.default_tax_set_id}
            property={formValue}
            onChange={this.handleTaxSetChange}
          />
        ),
      });

      items.push({
        key: "7",
        label: <span data-cy="taxes_tab">{t("properties_page:tab_keys:taxes")}</span>,
        children: (
          <TaxTable dataCy="tax" type="tax" property={formValue} />
        ),
      });

      items.push({
        key: "8",
        label: <span data-cy="webhooks_tab">{t("properties_page:tab_keys:webhooks")}</span>,
        className: drawerStyles.noPaddings,
        children: (
          <WebhooksCRUD dataCy="webhooks" property={formValue} />
        ),
      });
    }

    return (
      <Tabs
        items={items}
        destroyInactiveTabPane
        defaultActiveKey={activeTab}
        onChange={this.handleActiveTabChange}
      />
    );
  }
}

const mapStateToProps = ({ groups, session, user }, { propertyId }) => {
  const { activeProperty, activeGroup, activeFeatureFlags, appMode } = session;

  return {
    activeGroup,
    activeProperty,
    appMode,
    user,
    activeFeatureFlags,
    id: propertyId,
    groupsOptions: groups.options,
  };
};

export default withTranslation()(
  connect(mapStateToProps)(
    withGeoCoding(
      withTimezones(
        withLogic(PropertyForm),
      ),
    ),
  ),
);
