import React from "react";
import { SortableContainer } from "react-sortable-hoc";

export default SortableContainer(({ items, renderItem }) => {
  return (
    <div>
      {items.map((value, index) => (renderItem(value, index)))}
    </div>
  );
});
