import React from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { LoadingOutlined, SyncOutlined } from "@ant-design/icons";
import { Button, DatePicker, Radio, Space } from "antd";

import Flexbox from "components/flexbox";

import dayjs from "utils/dayjs";
import rangePresets from "utils/range_presets";

const getDatesRestrictions = (type, property) => {
  if (type === "booking") {
    const minDate = dayjs().subtract(90, "days").startOf("day");
    const maxDate = dayjs().endOf("day");

    return [minDate, maxDate];
  }

  if (type === "checkin") {
    if (!property) {
      return [];
    }

    const minDate = dayjs().subtract(90, "days").startOf("day");
    const maxDate = dayjs().startOf("day").add(property.settings.state_length, "days");

    return [minDate, maxDate];
  }

  throw new Error(`unknown type: ${type}`);
};

export const ControlsBar = ({ property, dateType, dateRange, isFetching, isLoading, onDateTypeChange, onDateRangeChange, onRefreshClick }) => {
  const { t } = useTranslation();

  const hideButtonTitle = useMediaQuery({ query: "(max-width: 730px) and (min-width: 649px)" });
  const isMobileToolbar = useMediaQuery({ query: "(max-width: 650px)" });

  const [minDate, maxDate] = getDatesRestrictions(dateType, property);

  const rangePickerProps = {};
  if (minDate && maxDate) {
    rangePickerProps.disabledDate = (date) => date.isBefore(minDate) || date.isAfter(maxDate);
  }

  return (
    <Flexbox style={{ padding: "12px", background: "#fafafa" }}>
      <Space size={isMobileToolbar ? 10 : 24} wrap direction={isMobileToolbar ? "vertical" : "horizontal"} style={{ width: "100%" }}>
        <Radio.Group
          style={{
            whiteSpace: "nowrap",
            width: isMobileToolbar ? "100%" : "auto",
            display: "flex",
          }}
          value={dateType}
          onChange={(e) => {
            onDateTypeChange(e.target.value);
          }}
        >
          <Radio.Button style={{ flex: 1 }} value="booking">{t("reports:labels:booked_on_date")}</Radio.Button>
          <Radio.Button style={{ flex: 1 }} value="checkin">{t("reports:labels:checkin_date")}</Radio.Button>
        </Radio.Group>

        <DatePicker.RangePicker
          style={{ width: "100%" }}
          value={dateRange}
          allowClear={false}
          presets={rangePresets()}
          onChange={onDateRangeChange}
          {...rangePickerProps}
        />
        {isLoading && <LoadingOutlined /> }

        {!isLoading && (
          <Button
            type="link"
            icon={
              <SyncOutlined spin={isFetching} />
            }
            onClick={onRefreshClick}
          >
            {hideButtonTitle || t("general:action:refresh")}
          </Button>
        )}
      </Space>
    </Flexbox>
  );
};
