import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";

import { pathBuilder } from "routing";
import useRouting from "routing/use_routing";

import { toLocalFromNow } from "utils/dates";
import getChannelInfoByKey from "utils/get_channel_info_by_key";
import useInterval from "utils/use_interval";

import styles from "../reviews.module.css";

function Item({ item, style }) {
  const { id, attributes } = item;
  const { t } = useTranslation();
  const { userAppRoutes } = useRouting();

  const { insertedAt, ota, isReplied, guestName, overallScore } = attributes;
  const [insertedAtValue, setUpdatedAtValue] = useState(toLocalFromNow(insertedAt));
  const channel = getChannelInfoByKey(ota);
  let avatar = null;

  useEffect(
    function updateUpdatedAtValue() {
      setUpdatedAtValue(toLocalFromNow(insertedAt));
    },
    [insertedAt],
  );

  useInterval(() => {
    setUpdatedAtValue(toLocalFromNow(insertedAt));
  }, 60000);

  const path = pathBuilder(userAppRoutes.reviews.review, {
    reviewId: id,
  });

  if (channel && channel.icon) {
    avatar = <img src={channel.icon} className={styles.legendIcon} alt={channel.title} />;
  }

  return (
    <div style={style}>
      <NavLink
        to={`${path}?is_replied=${isReplied}`}
        className={({ isActive }) => {
          return classNames(styles.item, {
            [styles.item__active]: isActive,
          });
        }}
      >
        <div className={styles.itemInner}>
          <div className={styles.icon}>{avatar}</div>
          <div className={styles.right}>
            <div className={styles.titleWrapper}>
              <p className={styles.title}>{guestName || t("reviews_page:unknown_guest")}</p>
              <p className={styles.insertedAt}>{insertedAtValue}</p>
            </div>
            <p className={styles.message}>{overallScore} / 10</p>
          </div>
        </div>
      </NavLink>
    </div>
  );
}

Item.propTypes = {
  item: PropTypes.object,
};

export default Item;
