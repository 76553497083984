import React from "react";
import { useMediaQuery } from "react-responsive";

export default function withMediaQuery(options = {}) {
  const breakpoints = {};

  return function withMediaQueryHoc(Component) {
    function WithMediaQuery(props) {
      Object.entries(options).forEach(([name, config]) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        breakpoints[name] = useMediaQuery(config);
      });

      return <Component {...breakpoints} {...props} />;
    }

    return WithMediaQuery;
  };
}
