import queryString from "query-string";

import appModes from "config/constants/app_modes";
import channelCodes from "config/constants/channels/channel_codes";

export default () => {
  const query = queryString.parse(window.location.search);
  const {
    app_mode: appMode = appModes.DEFAULT,
    property_id: propertyId,
    group_id: groupId,
    channels = "",
    allow_notifications_edit: allowNotificationsEdit,
    read_only_availability: readOnlyAvailability,
    hide_messages_list: hideMessagesList,
  } = query;
  const allowedChannelsShortCodes = channels.split(",");
  const allowedChannels = Object.values(channelCodes)
    .filter(({ shortCode }) => allowedChannelsShortCodes.includes(shortCode))
    .reduce((acc, channel) => ({ ...acc, [channel.code]: channel }), {});

  return {
    appMode,
    propertyId,
    groupId,
    allowedChannels,
    allowNotificationsEdit: Boolean(allowNotificationsEdit),
    readOnlyAvailability: Boolean(readOnlyAvailability),
    hideMessagesList: Boolean(hideMessagesList),
  };
};
