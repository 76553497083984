import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import _ from "lodash";

import itemsPerPage from "components/crud_table/items_per_page";

import withComponentRef from "containers/with_component_ref";
import withSizes from "containers/with_sizes";

import withRouter from "routing/with_router";

import { getParamsFromUrl, setParamsToUrl } from "utils/crud_table_url_pagination";

import FiltersTopBar from "./filters_top_bar/filters_top_bar";
import InnerTable from "./inner_table";

const DEFAULT_PAGE = 1;
const DEFAULT_ORDER = { inserted_at: "desc" };
const DEBOUNCE_TIME = 300;

class CRUDTableWithFilters extends Component {
  static propTypes = {
    columns: PropTypes.func.isRequired,
    emptyMessage: PropTypes.string.isRequired,
    showCreateMessage: PropTypes.bool,
    msgCreateLink: PropTypes.string,
    loadData: PropTypes.func,
    expandedMethod: PropTypes.func,
    showTableWithoutResults: PropTypes.bool,
    internalLoading: PropTypes.bool,
    rowHeight: PropTypes.number,
  };

  state = {
    isPreventUpdateStateFromUrl: false,
    page: DEFAULT_PAGE,
    limit: null,
    order: this.props.defaultOrder || DEFAULT_ORDER,
    isInitialDataLoaded: false,
    loading: false,
    isPaginated: false,
    total: null,
    filters: {},
  };

  componentDidMount() {
    this.updateStateFromUrl();
  }

  componentDidUpdate() {
    const {
      location: { search, pathname },
      basePath,
      data,
      loadData,
    } = this.props;

    const { isPreventUpdateStateFromUrl, limit, loading, isPaginated } = this.state;

    if (isPreventUpdateStateFromUrl) {
      return;
    }

    const isEntriesOverflowLimit = data && data.length > limit;

    if (isPaginated && isEntriesOverflowLimit && !loading && loadData) {
      this.loadData();
      return;
    }

    const paramsFromState = this.getTableParamsFromState();
    const defaultParams = this.getDefaultTableParams();

    const urlParams = {};
    new URLSearchParams(search).forEach((value, key) => {
      urlParams[key] = value;
    });

    const stateDiff = this.getChangedTableParams(paramsFromState, urlParams);
    const defaultDiff = this.getChangedTableParams(stateDiff, defaultParams);

    const ifStateDiffersFromUrl = Object.keys(defaultDiff).length;

    if (pathname === basePath && ifStateDiffersFromUrl) {
      this.updateStateFromUrl();
    }
  }

  updateStateFromUrl = () => {
    const { height, rowHeight } = this.props;

    const tableParamsFromUrl = getParamsFromUrl();
    const defaultOrder = this.getDefaultOrder();

    const fullTableState = {
      page: DEFAULT_PAGE,
      limit: itemsPerPage(height, rowHeight),
      order: defaultOrder,
      ...tableParamsFromUrl,
    };

    const { page, limit, order } = fullTableState;

    this.setState({
      page,
      limit,
      order,
      isPreventUpdateStateFromUrl: true,
    }, this.applyChanges);
  };

  resetTable = () => {
    const { height, rowHeight } = this.props;

    this.setState({
      page: DEFAULT_PAGE,
      limit: itemsPerPage(height, rowHeight),
      order: this.getDefaultOrder(),
      isPreventUpdateStateFromUrl: true,
    }, this.applyChanges);
  };

  reloadTable = () => {
    this.loadData();
  };

  applyChanges = () => {
    this.updateUrl();
    this.loadData();
  };

  loadData = _.debounce(() => {
    const { filters, page, limit } = this.state;
    const { loadData } = this.props;

    if (!loadData) {
      return;
    }

    this.setState({ loading: true }, () => {
      const pagination = {
        page,
        limit,
      };

      const { orderBy, orderDir } = this.getTableParamsFromState();

      loadData(filters, pagination, { [orderBy]: orderDir }).then(({ meta }) => {
        let total = null;
        let isPaginated = false;

        if (meta) {
          isPaginated = true;
          total = meta.total;
        }

        this.setState({
          total,
          isPaginated,
          loading: false,
          isInitialDataLoaded: true,
        });
      });
    });
  }, DEBOUNCE_TIME);

  getTableParamsFromState = () => {
    const { filters, order, page, limit } = this.state;
    const [orderBy, orderDir] = Object.entries(order)[0];

    return {
      page,
      limit,
      orderBy,
      orderDir,
      filters,
    };
  };

  getDefaultTableParams = () => {
    const { height, rowHeight } = this.props;
    const { page } = this.state;

    const defaultOrder = this.getDefaultOrder();

    const [orderBy, orderDir] = Object.entries(defaultOrder)[0];
    const limit = page > 1 ? null : itemsPerPage(height, rowHeight);

    return {
      page: DEFAULT_PAGE,
      limit,
      orderBy,
      orderDir,
      filters: {},
    };
  };

  getChangedTableParams = (a, b) => {
    const delta = {};

    const { orderBy, orderDir, ...rest } = a;
    const hasOrder = orderBy && orderDir;
    const isOrderChanged = orderBy !== b.orderBy || orderDir !== b.orderDir;

    if (hasOrder && isOrderChanged) {
      delta.orderBy = orderBy;
      delta.orderDir = orderDir;
    }

    Object.keys(rest).forEach((key) => {
      if (`${a[key]}` !== `${b[key]}`) {
        delta[key] = a[key];
      }
    });

    return delta;
  };

  getUrlParams = () => {
    const tableParams = this.getTableParamsFromState();
    const defaultTableParams = this.getDefaultTableParams();

    return this.getChangedTableParams(tableParams, defaultTableParams);
  };

  updateUrl = () => {
    const { navigate, onTableQueryChange = () => {} } = this.props;

    const urlParams = this.getUrlParams();
    const queryString = this.getQueryString();

    onTableQueryChange(queryString);
    setParamsToUrl(urlParams, navigate);

    this.setState({ isPreventUpdateStateFromUrl: false });
  };

  getQueryString = () => {
    const urlParams = this.getUrlParams();

    const queryParams = new URLSearchParams();
    Object.entries(urlParams).forEach(([key, value]) => queryParams.set(key, value));

    return queryParams.toString();
  };

  getDefaultOrder = () => {
    const { defaultOrder = DEFAULT_ORDER } = this.props;
    return defaultOrder;
  };

  generateOrder = (sorter) => {
    if (sorter && sorter.field && sorter.order) {
      return { [sorter.field]: sorter.order === "ascend" ? "asc" : "desc" };
    }

    return this.getDefaultOrder();
  };

  onTableChange = ({ current, pageSize }, _filters, sorter) => {
    const order = this.generateOrder(sorter);

    this.setState({
      page: current,
      limit: pageSize,
      order,
      isPreventUpdateStateFromUrl: true,
    }, this.applyChanges);
  };

  onFiltersChange = (filters) => {
    this.setState({
      page: DEFAULT_PAGE,
      filters,
      isPreventUpdateStateFromUrl: true,
    }, this.applyChanges);
  };

  handleReload = () => {
    this.reloadTable();
  };

  render() {
    const {
      columns,
      data,
      loadData,
      emptyMessage,
      internalLoading,
      msgCreateLink,
      showCreateMessage,
      showTableWithoutResults,
      expandedMethod,
      onExpand,
      filters: barFilters,
    } = this.props;

    const {
      limit,
      total,
      page,
      order,
      isInitialDataLoaded,
      loading,
      filters,
    } = this.state;

    return (
      <InnerTable
        style={{ height: this.props.height, width: this.props.width }}
        bar={(
          <FiltersTopBar
            value={filters}
            filters={barFilters}
            onChange={this.onFiltersChange}
            onReload={this.handleReload}
          />
        )}
        limit={limit}
        total={total}
        page={page}
        order={order}
        isInitialDataLoaded={isInitialDataLoaded}
        loading={loading}

        data={data}
        columns={columns}
        isAsyncData={!!loadData}
        internalLoading={internalLoading}

        emptyMessage={emptyMessage}
        msgCreateLink={msgCreateLink}
        showCreateMessage={showCreateMessage}
        showTableWithoutResults={showTableWithoutResults}

        expandedMethod={expandedMethod}
        onExpand={onExpand}

        onTableChange={this.onTableChange}
      />
    );
  }
}

export default withSizes(withRouter(withTranslation()(withComponentRef(CRUDTableWithFilters))));
