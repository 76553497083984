const TABLE_ITEMS_MIN_COUNT = 1;
const DEFAULT_ITEM_ROW_HEIGHT = 55;

const PAGINATION_ROW_HEIGHT = 56;
const TABLE_HEADER_HEIGHT = 55;

const TOP_BAR_HEIGHT = 48;
const BORDERS_HEIGHT = 4;

export default (height, rowHeight = DEFAULT_ITEM_ROW_HEIGHT, { topBar = true } = {}) => {
  let itemsContentHeight = height - PAGINATION_ROW_HEIGHT - TABLE_HEADER_HEIGHT - BORDERS_HEIGHT;

  if (topBar) {
    itemsContentHeight -= TOP_BAR_HEIGHT;
  }

  const itemsPerPage = Math.floor(itemsContentHeight / rowHeight);

  return itemsPerPage < TABLE_ITEMS_MIN_COUNT ? TABLE_ITEMS_MIN_COUNT : itemsPerPage;
};
