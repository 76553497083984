import React from "react";
import { Form } from "antd";
import memoizeOne from "memoize-one";
import * as yup from "yup";

import useRatePlans from "data/use_rate_plans";
import useRoomTypes from "data/use_room_types";
import combineRoomTypesWithRatePlans from "data/utils/combine_room_types_with_rate_plans";

import ChannexForm from "components/channex_form";
import FormRateSelector from "components/forms/inputs/formik/form_rate_selector";
import Loading from "components/loading";

const DEFAULT_VALUE = {
  associated_rate_plan_ids: [],
};

const VALIDATION_SCHEMA = yup.object().shape({
  associated_rate_plan_ids: yup.array(),
});

const FIELDS = ["associated_rate_plan_ids"];

const memoizedCombine = memoizeOne(combineRoomTypesWithRatePlans);

export default function RatePlanAssignSubform(props) {
  const {
    onChange,
    value,
    componentRef,
    errors,
    disabledRatePlans,
    label,
    propertyId,
  } = props;

  const { roomTypes, isLoading: isRoomTypesLoading } = useRoomTypes(propertyId, { sorted: true });
  const { ratePlans, isLoading: isRatePlansLoading } = useRatePlans(propertyId, { groupResults: true });

  if (isRatePlansLoading || isRoomTypesLoading) {
    return <Loading />;
  }

  const roomTypesWithRatePlans = memoizedCombine(roomTypes, ratePlans);

  return (
    <ChannexForm
      defaultValue={DEFAULT_VALUE}
      value={value}
      errors={errors}
      componentRef={componentRef}
      validationSchema={VALIDATION_SCHEMA}
      fields={FIELDS}
      onChange={onChange}
    >
      {(form) => (
        <Form onFinish={form.handleSubmit}>
          <FormRateSelector
            disabledRatePlans={disabledRatePlans}
            label={label}
            name="associated_rate_plan_ids"
            roomTypes={roomTypesWithRatePlans}
          />
        </Form>
      )}
    </ChannexForm>
  );
}
