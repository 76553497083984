import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { DrawerBody } from "drawers/_layout/body/body";

import Flexbox from "components/flexbox";
import * as Page from "components/page";
import PageHeader from "components/page_header";

import { ApaleoImportWorkflow } from "./workflow/apaleo_import_workflow";

export function ApaleoImportPage({ appName }) {
  const { t } = useTranslation();

  const location = useLocation();

  let initialStep = 0;
  let initialData = {};
  if (location.state) {
    initialStep = location.state.oauthSuccess ? 1 : 3;
    initialData = location.state.oauthSuccess ? {} : {
      error: "oauth_failed",
    };
    window.history.replaceState({}, document.title);
  }

  return (
    <Page.Main>
      <Page.Content>
        <PageHeader title={t("applications_page:apaleo:header")} />
        <DrawerBody>
          <Flexbox>
            <ApaleoImportWorkflow initialStep={initialStep} initialData={initialData} appName={appName} />
          </Flexbox>
        </DrawerBody>
      </Page.Content>
    </Page.Main>
  );
}
