import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import GroupRateSettings from "components/forms/groups/group_rate_settings";
import InputCalculationMode from "components/forms/items/input_calculation_mode";
import InputCurrency from "components/forms/items/input_currency";
import InputSellMode from "components/forms/items/input_sell_mode";

function GroupPriceSettings(props) {
  const { t } = useTranslation();
  const { warnings, errors, model, disabled, onChange } = props;

  return (
    <>
      <legend>{t("rates_page:form:rate_legend")}</legend>
      <InputCurrency t={t} errors={errors} disabled={disabled} model={model} onChange={onChange} />
      <InputSellMode t={t} errors={errors} disabled={disabled} model={model} onChange={onChange} />
      <InputCalculationMode errors={errors} disabled={disabled} model={model} onChange={onChange} />
      <GroupRateSettings errors={errors} warnings={warnings} disabled={disabled} model={model} onChange={onChange} />
    </>
  );
}

GroupPriceSettings.propTypes = {
  model: PropTypes.object,
  errors: PropTypes.object,
  onChange: PropTypes.func,
};

export default GroupPriceSettings;
