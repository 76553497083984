import { ROOM_TYPES_ADD, ROOM_TYPES_DROP, ROOM_TYPES_LOAD } from "../constants";

export function roomTypesLoad(storage) {
  return function (records, meta) {
    storage.dispatch({ type: ROOM_TYPES_LOAD, payload: { records, meta } });
  };
}

export function roomTypesAdd(storage) {
  return function (roomType) {
    storage.dispatch({ type: ROOM_TYPES_ADD, payload: roomType });
  };
}

export function roomTypesDrop(storage) {
  return function (roomType) {
    storage.dispatch({ type: ROOM_TYPES_DROP, payload: roomType });
  };
}
