import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { LoadingOutlined, SyncOutlined } from "@ant-design/icons";
import { Spin, Tooltip } from "antd";
import classnames from "classnames";

import messageStatuses from "config/constants/message_statuses";

import styles from "./message.module.css";

function MessageStatus({ status, handleResendMessage }) {
  const { t } = useTranslation();

  const renderStatus = () => {
    const { PENDING, FAILED } = messageStatuses;

    const className = classnames(
      styles.messageSpinnerWrapper,
      styles[`messageSpinnerWrapper--${status}`],
    );

    switch (status) {
      case PENDING:
        return (
          <div className={className}>
            <Spin indicator={<LoadingOutlined />} />
          </div>
        );

      case FAILED:
        return (
          <div className={className}>
            <Tooltip title={t("general:action:retry")}>
              <SyncOutlined onClick={handleResendMessage} />
            </Tooltip>
          </div>
        );

      default:
        return null;
    }
  };

  return renderStatus();
}

MessageStatus.propTypes = {
  status: PropTypes.string,
  handleResendMessage: PropTypes.func,
};

export default MessageStatus;
