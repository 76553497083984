import React from "react";
import { useTranslation } from "react-i18next";

import List from "components/list";

export default function PropertyInfo({ property }) {
  const { t } = useTranslation();
  const {
    content,
    facilities,
    address,
    city,
    country,
    currency,
    latitude,
    longitude,
    state,
    title,
    zipCode,
  } = property;

  const facilitiesList = facilities.join(", ");

  return (
    <List>
      <List.Item term={t("properties_page:form:title_label")} description={title} />
      <List.Item term={t("properties_page:form:country_label")} description={country} />
      <List.Item term={t("properties_page:form:state_label")} description={state} />
      <List.Item term={t("properties_page:form:city_label")} description={city} />
      <List.Item term={t("properties_page:form:address_label")} description={address} />
      <List.Item term={t("properties_page:form:zip_code_label")} description={zipCode} />
      <List.Item term={t("properties_page:form:currency_label")} description={currency} />
      <List.Item term={t("properties_page:form:latitude_label")} description={latitude} />
      <List.Item term={t("properties_page:form:longitude_label")} description={longitude} />
      <List.Item
        term={t("properties_page:content:description_label")}
        description={content.description}
      />
      <List.Item term={t("properties_page:form:facilities_label")} description={facilitiesList} />
    </List>
  );
}
