import React, { Component } from "react";

import MappingRow from "components/mapping/mapping_row";
import RoomTitle from "components/rooms/room_title";

import MappingSelect from "./mapping_select";
import RoomRatesMapping from "./room_rates_mapping";

import roomStyles from "./room_mapping.module.css";

class RoomMapping extends Component {
  state = {
    activeRoom: null,
    roomOptions: [],
  };

  componentDidMount() {
    this.updateRoomsOptions();
  }

  componentDidUpdate(prevProps) {
    const { room, roomTypes, mappingSettings, unmappedRooms } = this.props;

    const isRoomTypesUpdated = roomTypes !== prevProps.roomTypes;
    const isUnmappedRoomsUpdated = unmappedRooms !== prevProps.unmappedRooms;
    const isRoomsUpdated = mappingSettings.rooms !== prevProps.mappingSettings.rooms;
    const isRoomUpdated = room !== prevProps.room;

    if (isRoomTypesUpdated || isUnmappedRoomsUpdated || isRoomsUpdated || isRoomUpdated) {
      this.updateRoomsOptions();
    }
  }

  updateRoomsOptions = () => {
    const { room, roomTypes, mappingSettings, unmappedRooms } = this.props;
    const { rooms } = mappingSettings;

    const activeRoom = rooms && rooms[room.id];
    const currentRoomOption = roomTypes.find((roomType) => roomType.id === activeRoom);

    const rawRoomOptions = currentRoomOption
      ? [currentRoomOption, ...unmappedRooms]
      : unmappedRooms;

    const roomOptions = rawRoomOptions.map((roomOption) => ({
      ...roomOption,
      occupancy: roomOption.default_occupancy,
    }));

    this.setState({ activeRoom, roomOptions });
  };

  handleRoomMappingChange = (roomTypeId) => {
    const { mappingSettings, room, onChangeSettings } = this.props;

    let roomsSettings = mappingSettings.rooms || {};
    roomsSettings = { ...roomsSettings };
    const oldRoomTypeId = roomsSettings[room.id];

    if (oldRoomTypeId !== roomTypeId) {
      this.clearRoomRelatedRates(room.id);
    }

    roomsSettings[`${room.id}`] = roomTypeId;

    const newMappingSettings = {
      ...mappingSettings,
      rooms: roomsSettings,
    };

    onChangeSettings(newMappingSettings);
  };

  handleRoomMappingDelete = () => {
    const { mappingSettings, room, onChangeSettings } = this.props;

    let rooms = mappingSettings.rooms || {};
    rooms = { ...rooms };

    rooms[`${room.id}`] = null;

    const newMappingSettings = {
      ...mappingSettings,
      rooms,
    };

    onChangeSettings(newMappingSettings);

    this.clearRoomRelatedRates(room.id);
  };

  clearRoomRelatedRates = (roomId) => {
    const { mappings, onChangeMapping } = this.props;

    Object.keys(mappings).forEach((mappingId) => {
      const mappingArray = mappings[mappingId] || [];

      mappings[mappingId] = mappingArray.map((mapping) => {
        if (mapping && mapping.room_type_code === roomId) {
          return null;
        }

        return mapping;
      });
    });

    onChangeMapping(mappings);
  };

  renderRoomTypeMapping = () => {
    const { room } = this.props;
    const { activeRoom, roomOptions } = this.state;

    return (
      <MappingRow
        isMapped={activeRoom}
        title={<RoomTitle room={room} />}
        action={
          <MappingSelect
            value={activeRoom}
            options={roomOptions}
            onChange={this.handleRoomMappingChange}
          />
        }
        onDelete={this.handleRoomMappingDelete}
      />
    );
  };

  render() {
    const {
      room,
      ratePlans,
      mappings,
      mappingSettings,
      isOccupancyBased,
      onChangeMapping,
    } = this.props;

    return (
      <div className={roomStyles.roomRowContainer}>
        {this.renderRoomTypeMapping()}
        <RoomRatesMapping
          room={room}
          ratePlans={ratePlans}
          mappings={mappings}
          isOccupancyBased={isOccupancyBased}
          mappingSettings={mappingSettings}
          onChangeMapping={onChangeMapping}
        />
      </div>
    );
  }
}

export default RoomMapping;
