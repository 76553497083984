import React, { Component } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Form, Select } from "antd";

import { horizontalFormItemLayout, verticalFormItemLayout } from "config/constants/layouts/form";

import styles from "./form_select_modifiable.module.css";

class FormSelectModifiable extends Component {
  static validation(errors) {
    if (errors) {
      return {
        validateStatus: "error",
        help: Array.isArray(errors) ? errors.join(" ") : errors,
      };
    }

    return {};
  }

  static getLayout(view) {
    return view === "horizontal" ? horizontalFormItemLayout : verticalFormItemLayout;
  }

  handleChange = (value) => {
    const { onChange, name } = this.props;

    onChange(value, name);
  };

  render() {
    const {
      errors,
      label,
      placeholder,
      view = "horizontal",
      value,
      options = [],
      className,
      name,
      loading,
      onNew,
      disabled,
      onBlur = () => {},
    } = this.props;

    const formItemLayout = FormSelectModifiable.getLayout(view);

    return (
      <Form.Item
        className={[styles.selectModifiable, className].join(" ")}
        {...formItemLayout}
        {...FormSelectModifiable.validation(errors)}
        label={label}
        data-cy={`select_modifiable_${name}`}
      >
        <Select
          className={styles.select}
          showSearch
          value={value}
          placeholder={placeholder}
          onChange={this.handleChange}
          onBlur={onBlur}
          disabled={disabled}
          loading={loading}
          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {options.map((option) => (
            <Select.Option key={option.value} disabled={disabled} value={option.value}>
              {option.representation}
            </Select.Option>
          ))}
        </Select>
        <Button onClick={onNew} type="button" className={styles.addButton}>
          <PlusOutlined />
        </Button>
      </Form.Item>
    );
  }
}

export default FormSelectModifiable;
