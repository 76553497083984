import { BookingCom } from "./booking_com/booking_com";

const ACTIONS_BY_VENDOR = {
  BookingCom,
};

export function ChatActions({ thread, messages, onThreadReload }) {
  const vendor = thread?.attributes.provider;

  const VendorActions = ACTIONS_BY_VENDOR[vendor];

  if (!VendorActions) {
    return null;
  }

  return <VendorActions thread={thread} messages={messages} onThreadReload={onThreadReload} />;
}
