import React from "react";
import { useTranslation } from "react-i18next";

import amex from "static/amex.png";
import mastercard from "static/mastercard.png";
import visa from "static/visa.png";
import styles from "./booking_revision_card.module.css";

const CARD_EMITTER_NAME_BY_TYPE = {
  VI: "visa",
  visa: "visa",
  MC: "mastercard",
  mastercard: "mastercard",
  AX: "amex",
  amex: "amex",
  MasterCard: "mastercard",
  DS: "discover",
  Discover: "discover",
};

const CARD_ICON_BY_NAME = {
  visa,
  mastercard,
  amex,
};

export default function BookingRevisionCard({ type }) {
  const { t } = useTranslation();

  const emitterName = CARD_EMITTER_NAME_BY_TYPE[type];

  if (!emitterName) {
    return t("general:not_applicable");
  }

  const cardName = t(`card_types:${emitterName}`);
  const cardIcon = CARD_ICON_BY_NAME[emitterName];

  return (
    <div>
      {cardIcon && <img src={cardIcon} className={styles.icon} alt={cardName} />}
      {cardName}
    </div>
  );
}
