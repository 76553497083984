import React, { useCallback, useEffect, useState } from "react";
import store from "store";

import { BookingEventsTimeline } from "./booking_events_timeline/booking_events_timeline";

const { Bookings } = store;

export default function BookingEvents({ booking }) {
  const [isLoading, setIsLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const [nextPage, setNextPage] = useState(null);
  const [hasMore, setHasMore] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const loadBookingEvents = useCallback((page, loadedEvents) => {
    Bookings.events(booking.id, page).then((response) => {
      setEvents(loadedEvents.concat(response.data.map((event) => event.attributes)));
      setIsLoading(false);
      setIsLoadingMore(false);
      setHasMore((response.meta.total / response.meta.limit) > page);
      setNextPage(page + 1);
    });
  }, [booking]);

  useEffect(
    () => { loadBookingEvents(1, []); },
    [booking, loadBookingEvents],
  );

  const loadMore = () => {
    setIsLoadingMore(true);
    loadBookingEvents(nextPage, events);
  };

  return (
    <BookingEventsTimeline
      isLoading={isLoading}
      events={events}
      hasMore={hasMore}
      isLoadingMore={isLoadingMore}
      onLoadMore={loadMore}
    />
  );
}
