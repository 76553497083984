import React from "react";
import { useTranslation } from "react-i18next";
import { Collapse } from "antd";

import HotelPolicyInfo from "./modal_content/hotel_policy_info";
import PropertyInfo from "./modal_content/property_info";
import RoomTypes from "./modal_content/room_types";

export default function OnboardingConfirmStep({ onboardingData }) {
  const { t } = useTranslation();

  const { payload } = onboardingData?.attributes || {};

  if (!payload) {
    return null;
  }

  const { hotelPolicy, property, roomTypes } = payload;

  const items = [{
    key: "hotel_policy",
    label: t("properties_page:booking_com_onboarding:onboarding_success_modal:sections:hotel_policy"),
    children: <HotelPolicyInfo hotelPolicy={hotelPolicy} />,
  }, {
    key: "property",
    label: t("properties_page:booking_com_onboarding:onboarding_success_modal:sections:property"),
    children: <PropertyInfo property={property} />,
  }, {
    key: "room_types",
    label: t("properties_page:booking_com_onboarding:onboarding_success_modal:sections:room_types"),
    children: <RoomTypes roomTypes={roomTypes} />,
  }];

  return (
    <Collapse items={items} accordion />
  );
}
