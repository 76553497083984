import React from "react";

import useRoomTypes from "./use_room_types";

export default function withRoomTypes(options = {}) {
  const {
    sorted = false,
  } = options;

  return function withRoomTypesHOC(Component) {
    function WithRoomTypes(props) {
      const { roomTypes, error, isLoading } = useRoomTypes(props.propertyId, {
        sorted,
      });

      return <Component {...props} roomTypes={roomTypes} roomTypesError={error} isRoomTypesLoading={isLoading} />;
    }

    return WithRoomTypes;
  };
}
