const FULL_RESTRICTIONS = [
  "rate",
  "stop_sell_manual",
  "closed_to_arrival",
  "closed_to_departure",
  "min_stay_arrival",
  "min_stay_through",
  "min_stay",
  "max_stay",
  "availability",
  "max_availability",
  "availability_offset",
  "rate_availability",
];

export default FULL_RESTRICTIONS;
