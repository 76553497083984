import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Media from "react-media";
import { connect } from "react-redux";
import { Outlet } from "react-router-dom";
import { DownOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Dropdown, notification, Typography } from "antd";
import store from "store";

import CRUDTable from "components/crud_table";
import { removeMenuItem } from "components/menu/items/remove";
import * as Page from "components/page";

import { pathBuilder } from "routing";
import withRouter from "routing/with_router";

import convertToHashmap from "utils/convert_to_hashmap";
import EventEmitter from "utils/event_emitter";
import numericSort from "utils/numeric_sort";

import RatesSubtable from "./rates_subtable";

import generalStyles from "styles/general.module.css";

const { RatePlans, RoomTypes } = store;
const { Paragraph } = Typography;

class RoomsPage extends Component {
  state = {
    ratePlans: {
      loading: {},
      entities: {},
    },
    roomTypes: null,
    columnsToHide: 0,
    tableQuery: "",
  };

  tableRef = React.createRef();

  componentDidMount() {
    EventEmitter.bind("room_type:created", this.triggerLoadData);
    EventEmitter.bind("rate_plan:created", this.reloadRatePlans);
    EventEmitter.bind("rate_plan:removed", this.reloadRatePlans);
    EventEmitter.bind("onboarding:confirmed", this.triggerLoadData);
  }

  componentDidUpdate(prevProps) {
    const { activeProperty, activeGroup } = this.props;

    if (prevProps.activeGroup !== activeGroup || prevProps.activeProperty !== activeProperty) {
      this.tableRef.current.resetTable();
    }
  }

  componentWillUnmount() {
    EventEmitter.unbind("room_type:created", this.triggerLoadData);
    EventEmitter.unbind("rate_plan:created", this.reloadRatePlans);
    EventEmitter.unbind("rate_plan:removed", this.reloadRatePlans);
    EventEmitter.unbind("onboarding:confirmed", this.triggerLoadData);
  }

  expandedDataRender = () => (parent) => {
    const { ratePlans } = this.state; // eslint-disable-line react/no-this-in-sfc

    return <RatesSubtable ratePlans={ratePlans} parent={parent} />;
  };

  onForceRemove = (record) => {
    const { t } = this.props;

    notification.destroy();

    return RoomTypes.forceRemove(record)
      .then(() => {
        this.triggerLoadData();
      })
      .catch((error) => {
        if (!error.isValidationError) {
          throw error;
        }

        const reason = error.errors.details.channel ? "channel" : "undefined";

        notification.error({
          message: t("rooms_page:remove_error_message"),
          description: (
            <div>{t(`rooms_page:remove_error_description:${reason}`)}</div>
          ),
          duration: 0,
        });
      });
  };

  handleRemoveClick = (record) => {
    const { t } = this.props;

    RoomTypes.remove(record)
      .then(() => {
        this.triggerLoadData();
      })
      .catch((error) => {
        if (!error.isValidationError) {
          throw error;
        }

        const reason = error.errors.details.channel ? "channel" : "undefined";
        const relationships = error.errors.relationships?.channels || [];
        const links = relationships.map((relationship) => (
          <li key={relationship.id}>
            <a href={`/channels/${relationship.id}/edit`}>
              {relationship.channel} - {relationship.title}
            </a>
          </li>
        ));

        notification.error({
          message: t("rooms_page:remove_error_message"),
          description: (
            <>
              <div>{t(`rooms_page:remove_error_description:${reason}`)}</div>
              {links ? (
                <Paragraph>
                  <strong>{t("inventory_page:connected_channels")}:</strong>
                  <ul>{links}</ul>
                  <Button onClick={() => this.onForceRemove(record)} type="link" danger>
                    {t("rooms_page:remove_rate_unmap")}
                  </Button>
                </Paragraph>
              ) : null}
            </>
          ),
          duration: 0,
        });
      });
  };

  actions = (record) => {
    const { t, navigate, routes } = this.props;

    const items = [{
      key: "actions_menu_edit",
      onClick: () => navigate(pathBuilder(routes.userAppRoutes.rooms.edit, { roomId: record.id, section: "general" })),
      label: (
        <div data-cy="actions_menu_edit">
          <EditOutlined /> {t("general:action:edit")}
        </div>
      ),
    }, {
      type: "divider",
    }, {
      key: "actions_menu_create_rate",
      onClick: () => navigate(pathBuilder(routes.userAppRoutes.rooms.createRate, { roomId: record.id })),
      label: (
        <div data-cy="actions_menu_create_rate">
          <PlusOutlined /> {t("general:action:create_rate")}
        </div>
      ),
    }];

    items.push({ type: "divider" });

    items.push(removeMenuItem({
      onRemove: () => this.handleRemoveClick(record),
    }));

    return (
      <Dropdown menu={{ items }} trigger="click">
        <a
          className={generalStyles.actionsToggle}
          onClick={(event) => event.preventDefault()}
        >
          {t("general:actions")} <DownOutlined />
        </a>
      </Dropdown>
    );
  };

  loadData = (query, pagination, order) => {
    const { activeProperty, activeGroup } = this.props;

    let filter = { title: { has: query } };

    if (activeGroup) {
      filter = { ...filter, group_id: activeGroup };
    }

    if (activeProperty) {
      filter = { ...filter, property_id: activeProperty };
    }

    return RoomTypes.list(filter, pagination, order).then((response) => {
      const { entities } = response;
      const roomTypes = entities ? Object.values(entities) : null;

      this.setState({ roomTypes });

      return response;
    });
  };

  triggerLoadData = () => {
    return this.tableRef.current.reloadTable();
  };

  reloadRatePlans = (record) => {
    this.onExpand(true, { id: record.room_type_id }, true);
  };

  onExpand = (expanded, room_type, force = false) => {
    if (expanded && (!this.state.ratePlans.entities[room_type.id] || force)) {
      this.setState(({ ratePlans }) => ({
        ratePlans: { ...ratePlans, loading: { ...ratePlans.loading, [room_type.id]: true } },
      }));

      RatePlans.list({ room_type_id: room_type.id }).then((newRatesPlans) => {
        this.setState(({ ratePlans }) => ({
          ratePlans: {
            loading: {
              ...ratePlans.loading,
              [room_type.id]: false,
            },
            entities: {
              ...ratePlans.entities,
              [room_type.id]: newRatesPlans.map((el) => el.attributes),
            },
          },
        }));
      });
    }
  };

  columns = () => {
    const { t, activeGroup, activeProperty, propertiesOptionsById } = this.props;

    const selectedAllProperties = !activeGroup && !activeProperty;
    const isSeveralPropertiesSelected = selectedAllProperties && Object.keys(propertiesOptionsById).length > 1;
    const isPropertyTitleShown = isSeveralPropertiesSelected || activeGroup;
    const getRecordMessage = (record) => `A:${record.occ_adults} C:${record.occ_children} I:${record.occ_infants}`;

    let dataColumns = [
      {
        title: t("rooms_page:columns:title"),
        dataIndex: "title",
        key: "title",
        sorter: true,
      },
      {
        title: t("rooms_page:columns:count_of_rooms"),
        dataIndex: "count_of_rooms",
        key: "count_of_rooms",
        sorter: numericSort("count_of_rooms"),
      },
      {
        title: t("rooms_page:columns:occupancy"),
        dataIndex: "default_occupancy",
        key: "default_occupancy",
        render: (_text, record) => {
          return getRecordMessage(record);
        },
      },
    ];

    if (isPropertyTitleShown) {
      dataColumns.push({
        title: t("rooms_page:columns:property"),
        dataIndex: "property_title",
        key: "property_title",
        render: (_text, { property_id }) => {
          const matchingProperty = propertiesOptionsById[property_id] || {};

          return matchingProperty.title;
        },
      });
    }

    const actionColumns = [
      {
        title: t("rooms_page:columns:actions"),
        key: "action",
        align: "right",
        render: this.actions,
      },
    ];

    dataColumns = dataColumns.slice(0, dataColumns.length - this.state.columnsToHide);

    return [...dataColumns, ...actionColumns];
  };

  emptyMessage() {
    const { t } = this.props;
    return t("rooms_page:empty_message");
  }

  pageTitle() {
    const { t } = this.props;
    return t("rooms_page:header");
  }

  handleMediaChange = (columnsToHide) => (matches) => {
    if (!matches) {
      return;
    }

    this.setState({
      columnsToHide,
    });
  };

  handleTableQueryChange = (tableQuery) => {
    this.setState({ tableQuery });
  };

  getClosePath = () => {
    const { routes } = this.props;
    const { tableQuery } = this.state;
    const basePath = pathBuilder(routes.userAppRoutes.rooms);
    const closePath = [basePath, tableQuery].join("?");

    return closePath;
  };

  render() {
    const { routes } = this.props;
    const { roomTypes } = this.state;
    const closePath = this.getClosePath();

    return (
      <Page.Main>
        <Media query="(max-width: 759px)" onChange={this.handleMediaChange(2)} />
        <Media
          query="(min-width: 760px) and (max-width: 800px)"
          onChange={this.handleMediaChange(1)}
        />
        <Media query="(min-width: 801px)" onChange={this.handleMediaChange(0)} />

        <CRUDTable
          data={roomTypes}
          componentRef={this.tableRef}
          emptyMessage={this.emptyMessage()}
          createLink={pathBuilder(routes.userAppRoutes.rooms.create)}
          msgCreateLink={pathBuilder(routes.userAppRoutes.rooms.create)}
          searchField="title"
          expandedMethod={this.expandedDataRender}
          columns={this.columns}
          onTableQueryChange={this.handleTableQueryChange}
          loadData={this.loadData}
          onExpand={this.onExpand}
        />

        <Outlet context={{ closePath }} />
      </Page.Main>
    );
  }
}

const mapStateToProps = ({ session, properties }) => {
  const { activeProperty, activeGroup } = session;

  return {
    activeGroup,
    activeProperty,
    propertiesOptionsById: convertToHashmap(properties.options),
  };
};

export default withRouter(withTranslation()(connect(mapStateToProps)(RoomsPage)));
