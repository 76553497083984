import React, { Component } from "react";
import { Field } from "formik";
import _ from "lodash";

import FormSelect from "components/forms/inputs/form_select";

class FormikFormSelect extends Component {
  render() {
    const { name, namespace, onChange } = this.props;
    const fieldName = namespace ? `${namespace}.${name}` : name;

    return (
      <Field name={fieldName}>
        {({ field, form }) => {
          const isTouched = _.get(form.touched, fieldName, false);

          return (
            <FormSelect
              {...this.props}
              {...field}
              errors={isTouched && form.errors[fieldName]}
              onChange={(value) => (onChange ? onChange(value, field, form) : form.setFieldValue(fieldName, value))}
              onBlur={() => form.setFieldTouched(fieldName, true)}
            />
          );
        }}
      </Field>
    );
  }
}

export default FormikFormSelect;
