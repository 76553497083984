import React, { Component } from "react";
import { DeleteOutlined, DownOutlined, EditOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Dropdown } from "antd";

import TabTable from "components/tab_table";
import User from "components/user/user";

import generalStyles from "styles/general.module.css";

export default class UsersList extends Component {
  render() {
    const {
      t,
      searchQuery,
      userAccesses,
      handleRevokeAccessClick,
      handleUserEditClick,
      handleInviteClick,
      handleSearchChange,
    } = this.props;

    const columns = [
      {
        title: t("general:user"),
        key: "avatar",
        render: (_text, record) => {
          return <User size={42} name={record.user.name} email={record.user.email} />;
        },
      },
      {
        title: t("general:actions"),
        key: "action",
        align: "right",
        render: (text, record) => {
          const userId = record.id;

          const items = [{
            key: "users_edit_action",
            onClick: () => handleUserEditClick(userId),
            label: (
              <div data-cy="users_edit_action">
                <EditOutlined /> {t("general:action:edit")}
              </div>
            ),
          }, {
            type: "divider",
          }, {
            key: "users_revoke_access_action",
            onClick: () => handleRevokeAccessClick(record),
            label: (
              <div data-cy="users_revoke_access_action">
                <DeleteOutlined /> {t("access:revoke_access")}
              </div>
            ),
          }];

          return (
            <Dropdown menu={{ items }} trigger={["click"]}>
              <a
                data-cy="users_actions_menu"
                className={generalStyles.actionsToggle}
                href="#actions"
                onClick={(event) => event.preventDefault()}
              >
                {t("general:actions")} <DownOutlined />
              </a>
            </Dropdown>
          );
        },
      },
    ];

    return (
      <TabTable
        actions={
          <Button
            data-cy="invite_button"
            onClick={handleInviteClick}
            icon={<PlusCircleOutlined />}
            type="primary"
          >
            {t("access:invite")}
          </Button>
        }
        emptyMessage={t("groups_page:users_tab:empty_message")}
        createMessageText={t("groups_page:users_tab:create_message_text")}
        searchQuery={searchQuery}
        rowKey="id"
        columns={columns}
        dataSource={userAccesses}
        onSearchChange={handleSearchChange}
        onCreate={handleInviteClick}
      />
    );
  }
}
