import { useQuery } from "react-query";
import store from "store";

const { Applications } = store;

export default function useApplicationAction({ appCode, action, query }, options = {}) {
  return useQuery(
    ["room_spaces", appCode, action, query],
    () => Applications.action({ appCode, action, query }),
    options,
  );
}
