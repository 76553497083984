import { features } from "config/feature_flags";

import { Extras } from "./openshopping/extras";

export function getChannelTabs(channelCode, activeFeatureFlags) {
  const channelTabs = {};

  if (activeFeatureFlags[features.EXTRAS_MANAGEMENT]) {
    channelTabs.OpenShopping = {
      extras: {
        label: "Extras",
        enabled: () => true,
        Component: Extras,
      },
    };
  }

  if (!channelCode) {
    return [];
  }

  if (!channelTabs[channelCode]) {
    return [];
  }

  const tabs = Object.entries(channelTabs[channelCode]).map(([key, value]) => {
    return ({
      key,
      ...value,
    });
  });

  return tabs;
}
