import React from "react";
import { useTranslation } from "react-i18next";
import { SearchOutlined } from "@ant-design/icons";

import FormInput from "components/forms/inputs/form_input";

import styles from "./input_search.module.css";

export default function InputSearch({ value, total, onChange }) {
  const { t } = useTranslation();

  return (
    <>
      <FormInput
        className={styles.input}
        type="text"
        allowClear
        value={value}
        icon={<SearchOutlined />}
        placeholder={t("general:search_field_placeholder")}
        onChange={onChange}
      />
      <div className={styles.total}>
        {`${t("channels_page:form:listing_total_label")}: ${total}`}
      </div>
    </>
  );
}
