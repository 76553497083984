import React from "react";

import MappingRow from "components/mapping/mapping_row";

export default function RemovedRoom({ externalId, id, roomTypes, onDelete }) {
  const handleDelete = () => onDelete(externalId);

  const room = roomTypes.find((roomType) => roomType.id === id) || {};

  const { title = id } = room;

  return <MappingRow isMapped title={externalId} action={title} onDelete={handleDelete} />;
}
