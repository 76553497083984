import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import ChannexDrawerRoutable from "drawers/channex_drawer_routable";

import DrawerFormWrapper from "components/drawer_form_wrapper/drawer_form_wrapper";

import withRouter from "routing/with_router";

import TaskViewDetails from "./components/task_view_details";

class TaskView extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  render() {
    const { t, params } = this.props;
    const { taskId } = params;

    return (
      <ChannexDrawerRoutable title={t("tasks_page:view_dialog_header")}>
        {({ handleCloseFromContent }) => (
          <DrawerFormWrapper>
            <TaskViewDetails taskId={taskId} onClose={handleCloseFromContent} />
          </DrawerFormWrapper>
        )}
      </ChannexDrawerRoutable>
    );
  }
}

export default withRouter(withTranslation()(TaskView));
