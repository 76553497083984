import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Col, Form, Row } from "antd";
import * as yup from "yup";

import currencyOptions from "config/constants/currency_options";
import errorMessages from "config/constants/errors";

import ChannexForm from "components/channex_form";
import SubmitButton from "components/forms/buttons/submit_button";
import FormikFormDatepicker from "components/forms/inputs/formik/form_datepicker";
import FormikFormInput from "components/forms/inputs/formik/form_input";
import FormikFormSelect from "components/forms/inputs/formik/form_select";
import FormikRadio from "components/forms/inputs/formik/radio";

import styles from "styles/form_in_drawer.module.css";

class TaxForm extends Component {
  VALIDATION_SCHEMA = yup.object().shape({
    title: yup.string().trim().required(errorMessages.required()),
    is_inclusive: yup.string().required(errorMessages.required()),
    logic: yup.string().required(errorMessages.required()),
    type: yup.string().required(errorMessages.required()),
    currency: yup
      .string()
      .nullable()
      .when("logic", {
        is: (val) => val !== "percent",
        then: () => yup.string().required(errorMessages.required()),
      }),
    rate: yup
      .number(errorMessages.number())
      .required(errorMessages.required())
      .typeError(errorMessages.number())
      .min(0, errorMessages.greaterOrEqual(0))
      .when("logic", {
        is: "percent",
        then: () => yup
          .number(errorMessages.number())
          .max(100, errorMessages.lessOrEqual(100))
          .typeError(errorMessages.number()),
      }),
    applicable_date_ranges: yup.array().of(
      yup.object().shape({
        after: yup.string().nullable(),
        before: yup.string().nullable(),
      }),
    ).nullable(),
    max_nights: yup
      .number(errorMessages.number())
      .positive(errorMessages.positive())
      .integer(errorMessages.integer())
      .typeError(errorMessages.number())
      .nullable(),
    skip_nights: yup
      .number(errorMessages.number())
      .positive(errorMessages.positive())
      .integer(errorMessages.integer())
      .typeError(errorMessages.number())
      .nullable(),
  });

  DEFAULT_VALUE = {
    is_inclusive: false,
  };

  getLogicOptions = () => {
    const { t } = this.props;

    return [
      {
        value: "percent",
        representation: t("taxes:tax:form:logic_options:percent"),
      },
      {
        value: "per_booking",
        representation: t("taxes:tax:form:logic_options:per_booking"),
      },
      {
        value: "per_room",
        representation: t("taxes:tax:form:logic_options:per_room"),
      },
      {
        value: "per_night",
        representation: t("taxes:tax:form:logic_options:per_night"),
      },
      {
        value: "per_person",
        representation: t("taxes:tax:form:logic_options:per_person"),
      },
      {
        value: "per_room_per_night",
        representation: t("taxes:tax:form:logic_options:per_room_per_night"),
      },
      {
        value: "per_person_per_night",
        representation: t("taxes:tax:form:logic_options:per_person_per_night"),
      },
    ];
  };

  getTypeOptions = () => {
    const { t } = this.props;

    return [
      {
        value: "tax",
        representation: t("taxes:tax:form:type_options:tax"),
      },
      {
        value: "city_tax",
        representation: t("taxes:tax:form:type_options:city_tax"),
      },
      {
        value: "fee",
        representation: t("taxes:tax:form:type_options:fee"),
      },
    ];
  };

  getInclusivityOptions = () => {
    const { t } = this.props;

    return [
      {
        value: true,
        representation: t("taxes:tax:form:is_inclusive_options:inclusive"),
      },
      {
        value: false,
        representation: t("taxes:tax:form:is_inclusive_options:exclusive"),
      },
    ];
  };

  shouldRenderCurrency = () => {
    const { value } = this.props;
    const { logic } = value;

    return logic !== "percent";
  };

  handleAddApplicableRange = (value, onChange) => () => {
    onChange({ ...value, applicable_date_ranges: [...value.applicable_date_ranges || [], { after: null, before: null }] });
  };

  handleRemoveApplicableRange = (value, index, onChange) => () => {
    onChange({ ...value, applicable_date_ranges: value.applicable_date_ranges.filter((_, i) => i !== index) });
  };

  render() {
    const { t, componentRef, value, submitting, errors, onChange, onSubmit } = this.props;

    return (
      <>
        <ChannexForm
          defaultValue={this.DEFAULT_VALUE}
          value={value}
          errors={errors}
          componentRef={componentRef}
          validationSchema={this.VALIDATION_SCHEMA}
          onChange={onChange}
        >
          {({ handleSubmit }) => (
            <Form onFinish={handleSubmit}>
              <FormikFormInput
                name="title"
                placeholder={t("taxes:tax:form:title")}
                label={t("taxes:tax:form:title")}
              />
              <FormikRadio
                name="is_inclusive"
                label={t("taxes:tax:form:is_inclusive")}
                options={this.getInclusivityOptions()}
              />
              <FormikFormSelect
                name="logic"
                placeholder={t("taxes:tax:form:logic")}
                label={t("taxes:tax:form:logic")}
                options={this.getLogicOptions()}
              />
              <FormikFormSelect
                name="type"
                placeholder={t("taxes:tax:form:type")}
                label={t("taxes:tax:form:type")}
                options={this.getTypeOptions()}
              />
              {this.shouldRenderCurrency() && (
                <FormikFormSelect
                  name="currency"
                  placeholder={t("taxes:tax:form:currency")}
                  label={t("taxes:tax:form:currency")}
                  options={currencyOptions()}
                />
              )}
              <FormikFormInput
                name="rate"
                placeholder={t("taxes:tax:form:rate")}
                label={t("taxes:tax:form:rate")}
              />

              <legend>{t("taxes:tax:form:additional_information_legend")}</legend>
              <FormikFormInput
                type="number"
                min="0"
                name="max_nights"
                placeholder={t("taxes:tax:form:max_nights")}
                label={t("taxes:tax:form:max_nights")}
              />
              <FormikFormInput
                type="number"
                min="0"
                name="skip_nights"
                placeholder={t("taxes:tax:form:skip_nights")}
                label={t("taxes:tax:form:skip_nights")}
              />

              <legend>
                <Row>
                  <Col xs={14}>
                    {t("taxes:tax:form:applicable_dates")}
                  </Col>
                  <Col xs={10} style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button type="link" size="small" icon={<PlusCircleOutlined />} onClick={this.handleAddApplicableRange(value, onChange)}>
                      {t("taxes:tax:form:add_date_range")}
                    </Button>
                  </Col>
                </Row>
              </legend>
              {
                value.applicable_date_ranges?.map((range, index) => (
                  <div key={index} className={styles.dateRange}>
                    <Row>
                      <Col xs={8} />
                      <Col xs={10}>
                        <strong>{`Date range ${index + 1}`}</strong>
                      </Col>
                      <Col xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button type="link" size="small" icon={<DeleteOutlined />} onClick={this.handleRemoveApplicableRange(value, index, onChange)}>
                          {t("taxes:tax:form:remove_date_range")}
                        </Button>
                      </Col>
                    </Row>
                    <FormikFormDatepicker
                      name={`applicable_date_ranges[${index}].after`}
                      placeholder={t("taxes:tax:form:applicable_after")}
                      label={t("taxes:tax:form:applicable_after")}
                    />
                    <FormikFormDatepicker
                      name={`applicable_date_ranges[${index}].before`}
                      placeholder={t("taxes:tax:form:applicable_before")}
                      label={t("taxes:tax:form:applicable_before")}
                    />
                  </div>
                ))
              }
              {(value.applicable_date_ranges || []).length === 0 && (
                <Row className={styles.helper}>
                  <Col xs={24}>
                    {t("taxes:tax:form:applicable_dates_empty")}
                  </Col>
                </Row>
              )}
            </Form>
          )}
        </ChannexForm>

        <div className={styles.actions}>
          <SubmitButton loading={submitting} onClick={onSubmit}>
            {t("general:action:save")}
          </SubmitButton>
        </div>
      </>
    );
  }
}

export default withTranslation()(TaxForm);
