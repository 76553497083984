import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import CHANNELS_WITHOUT_SETTINGS from "config/constants/channels_without_settings";

import addDeletedOptionsInMappings from "components/channel_management/components/mapping/utils/add_deleted_options_in_mappings";
import buildRatesTree from "components/channel_management/components/mapping/utils/build_rates_tree";
import FormMessage from "components/forms/message/form_message";

import { buildOrderedMappingSchema } from "../../../utils/build_ordered_mapping_schema";

import DirectMapping from "./components/direct_mapping/direct_mapping";
import ReverseMapping from "./components/reverse_mapping/reverse_mapping";
import KnownMappings from "./known_mappings";

class TreeMapping extends Component {
  static propTypes = {
    t: PropTypes.func,
    properties: PropTypes.object,
    roomTypes: PropTypes.array,
    ratePlans: PropTypes.array,
    mappingStatus: PropTypes.string,
  };

  state = {
    loading: false,
  };

  getMappingSettings = () => {
    const { mappingSettings, mappingSchema } = this.props;
    const result = mappingSettings || {};

    if (CHANNELS_WITHOUT_SETTINGS.includes(this.props.channelCode)) {
      result.reverseMapping = true;
    }

    if (!result.orderedMappingSchema) {
      result.orderedMappingSchema = buildOrderedMappingSchema(mappingSchema);
    }

    return result;
  };

  handleRefresh = () => {
    const { onRefresh } = this.props;

    this.setState({ loading: true });

    return onRefresh().finally(() => this.setState({ loading: false }));
  };

  render() {
    const { loading } = this.state;
    const {
      t,
      channelCode,
      mappingStatus,
      ratePlans,
      roomTypes,
      properties,
      mappingSchema,
      mappingOptions,
      mappings,
      knownMappings,
      channelTitle,
      channelProperties,
      onChangeSettings,
      onChangeMapping,
      onKnownMappingsDelete,
    } = this.props;

    const mappingSettings = this.getMappingSettings();
    const { reverseMapping, orderedMappingSchema } = mappingSettings;

    const mappingOptionsWithDeleted = addDeletedOptionsInMappings(
      mappingSchema,
      mappings,
      mappingOptions,
    );
    const ratesTree = buildRatesTree(channelProperties, properties, roomTypes, ratePlans);

    const MappingComponent = reverseMapping ? ReverseMapping : DirectMapping;

    if (mappingStatus === null) {
      return <FormMessage message={t("channels_page:form:message:fill_settings")} />;
    }

    if (mappingStatus === "error") {
      return (
        <FormMessage icon="api" message={t("channels_page:form:message:service_unavailable")} />
      );
    }

    if (mappingStatus === "loaded" && channelProperties.length === 0) {
      return <FormMessage message={t("channels_page:form:message:choose_property")} />;
    }

    return (
      <>
        <MappingComponent
          channelCode={channelCode}
          channelTitle={channelTitle}
          channelProperties={channelProperties}
          loading={loading}
          ratesTree={ratesTree}
          mappingOptions={mappingOptionsWithDeleted}
          orderedMappingSchema={[...orderedMappingSchema]}
          ratePlans={ratePlans}
          mappingSettings={mappingSettings}
          mappings={mappings}
          mappingSchema={mappingSchema}
          onRefresh={this.handleRefresh}
          onChangeMapping={onChangeMapping}
          onChangeSettings={onChangeSettings}
        />
        <KnownMappings
          knownMappings={knownMappings}
          mappingOptions={mappingOptions}
          roomTypes={roomTypes}
          ratePlans={ratePlans}
          onDelete={onKnownMappingsDelete}
        />
      </>
    );
  }
}

const MappingComponent = withTranslation()(TreeMapping);
MappingComponent.MAPPING_TYPE = "TreeMapping";

export default MappingComponent;
