import React, { Component } from "react";
import { Checkbox, Form } from "antd";

import { horizontalFormItemLayout, verticalFormItemLayout } from "config/constants/layouts/form";
import WEEKDAYS from "config/constants/weekdays";

import styles from "./form_weekdays.module.css";

const DEFAULT_VALUE = [false, false, false, false, false, false, false];

class FormWeekdays extends Component {
  state = {
    stateValue: DEFAULT_VALUE,
  };

  validation(errors) {
    if (errors) {
      return {
        validateStatus: "error",
        help: Array.isArray(errors) ? errors.join(" ") : errors,
      };
    }

    return {};
  }

  getLayout = (view) => {
    return view === "horizontal" ? horizontalFormItemLayout : verticalFormItemLayout;
  };

  onChange = (index) => () => {
    const { onChange, name, value } = this.props;
    const { stateValue } = this.state;

    const currentValue = [...(onChange ? value : stateValue)];
    currentValue[index] = !currentValue[index];

    this.setState({ stateValue: currentValue });

    if (onChange) {
      onChange(currentValue, name);
    }
  };

  render() {
    const { name, label, value, errors, disabled, onChange, view = "horizontal" } = this.props;
    const { stateValue } = this.state;

    return (
      <Form.Item
        className={styles.weekdaysContainer}
        {...this.getLayout(view)}
        {...this.validation(errors)}
        label={label}
        data-cy={`weekdays_${this.props.name}`}
      >
        {new Array(7).fill(false).map((_el, index) => {
          const dayValue = onChange ? value[index] : stateValue[index];

          return (
            <Checkbox
              data-cy={`day_${index}`}
              name={`${name}${index}`}
              className={styles.weekday}
              key={WEEKDAYS[index]}
              checked={dayValue}
              disabled={disabled}
              onChange={this.onChange(index)}
            >
              {WEEKDAYS[index]}
            </Checkbox>
          );
        })}
      </Form.Item>
    );
  }
}

export default FormWeekdays;
