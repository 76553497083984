import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { LinkOutlined } from "@ant-design/icons";

export default function RatesSubtableTitle({ record }) {
  const { t } = useTranslation();
  const {
    sell_mode: sellMode,
    currency,
    options,
    title,
    parent_rate_plan_id: parentRatePlanId,
  } = record;

  const rateInfo = useMemo(() => {
    let occupancy;

    if (sellMode === "per_person") {
      occupancy = t("rates_page:occupancy:multiple");
    } else {
      occupancy = options[0].occupancy;
    }

    return `${t(`rates_page:sell_mode:${sellMode}`)}, ${occupancy} Persons, ${currency}`;
  }, [t, sellMode, options, currency]);

  return (
    <>
      <span>
        {title} ({rateInfo})
      </span>
      &nbsp;
      {parentRatePlanId && <LinkOutlined />}
    </>
  );
}
