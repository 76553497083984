import React from "react";
import PropTypes from "prop-types";

import FormSelect from "components/forms/inputs/form_select";

function handleChangeCallback(callback, field) {
  return (value) => {
    callback(field, value);
  };
}

function optionsGenerator(values) {
  return values.map((el) => {
    return {
      value: el.occupancy,
      representation: el.occupancy,
    };
  });
}

function InputPrimaryOccupancy(props) {
  const { t, model, onChange } = props;
  const onHandleChange = handleChangeCallback(onChange, "primary_occupancy");
  const options = optionsGenerator(model.options);

  return (
    <FormSelect
      name="primary_occupancy"
      view="horizontal"
      placeholder={t("rates_page:form:primary_occupancy_placeholder")}
      label={t("rates_page:form:primary_occupancy_label")}
      defaultValue={model.primary_occupancy ? String(model.primary_occupancy) : undefined}
      value={model.primary_occupancy ? String(model.primary_occupancy) : undefined}
      onChange={onHandleChange}
      options={options}
      disabled={Boolean(model.id)}
    />
  );
}

InputPrimaryOccupancy.propTypes = {
  t: PropTypes.func,
  model: PropTypes.object,
  onChange: PropTypes.func,
};

export default InputPrimaryOccupancy;
