import { StarFilled, StarOutlined, StopOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

import PrimaryToggleTooltipContent from "./primary_toggle_tooltip_content";

export function PrimaryToggle({ isPrimary, isReadonly, isTogglable, onClick }) {
  if (!isPrimary && !isTogglable) {
    return null;
  }

  let icon = <StopOutlined />;

  if (!isReadonly) {
    icon = isPrimary
      ? (<StarFilled onClick={onClick} />)
      : (<StarOutlined onClick={onClick} />);
  }

  return (
    <Tooltip
      placement="top"
      title={<PrimaryToggleTooltipContent isPrimary={isPrimary} isReadonly={isReadonly} />}
    >
      {icon}
    </Tooltip>
  );
}
