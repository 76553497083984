import {
  CHANNEL_MESSAGES_ADD,
  CHANNEL_MESSAGES_ADD_THREAD,
  CHANNEL_MESSAGES_LOAD,
  CHANNEL_MESSAGES_RESET,
  CHANNEL_MESSAGES_RESET_THREADS,
  CHANNEL_MESSAGES_THREAD_CLOSE,
  CHANNEL_MESSAGES_THREAD_OPEN,
  CHANNEL_MESSAGES_THREADS_LOAD,
  CHANNEL_MESSAGES_THREADS_SET_REQUEST_ID,
  CHANNEL_MESSAGES_UPDATE_THREAD_BY_LATEST_MESSAGE,
} from "../constants";

export function channelMessagesThreadsLoad(storage) {
  return function (threads, meta, requestId) {
    storage.dispatch({
      type: CHANNEL_MESSAGES_THREADS_LOAD,
      payload: { threads, meta, requestId },
    });
  };
}

export function channelMessagesCloseThread(storage) {
  return function (threadId) {
    storage.dispatch({
      type: CHANNEL_MESSAGES_THREAD_CLOSE,
      payload: threadId,
    });
  };
}

export function channelMessagesOpenThread(storage) {
  return function (threadId) {
    storage.dispatch({
      type: CHANNEL_MESSAGES_THREAD_OPEN,
      payload: threadId,
    });
  };
}

export function channelMessagesAddThread(storage) {
  return function (thread) {
    storage.dispatch({
      type: CHANNEL_MESSAGES_ADD_THREAD,
      payload: thread,
    });
  };
}

export function channelMessagesUpdateThreadByLatestMessage(storage) {
  return function (threadId, message) {
    storage.dispatch({
      type: CHANNEL_MESSAGES_UPDATE_THREAD_BY_LATEST_MESSAGE,
      payload: { threadId, message },
    });
  };
}

export function channelMessagesResetThreads(storage) {
  return function () {
    storage.dispatch({ type: CHANNEL_MESSAGES_RESET_THREADS });
  };
}

export function channelMessagesThreadsSetRequestId(storage) {
  return function (id) {
    storage.dispatch({ type: CHANNEL_MESSAGES_THREADS_SET_REQUEST_ID, payload: id });
  };
}

export function channelMessagesLoad(storage) {
  return function (threadId, messages, meta) {
    storage.dispatch({ type: CHANNEL_MESSAGES_LOAD, payload: { threadId, messages, meta } });
  };
}

export function channelMessagesAdd(storage) {
  return function (threadId, message) {
    storage.dispatch({ type: CHANNEL_MESSAGES_ADD, payload: { threadId, message } });
  };
}

export function channelMessagesReset(storage) {
  return function (threadId) {
    storage.dispatch({ type: CHANNEL_MESSAGES_RESET, payload: { threadId } });
  };
}
