import * as Sentry from "@sentry/react";

import appModes from "config/constants/app_modes";

import AppStorageManager from "utils/app_storage_manager";
import getFeatureFlagsConfig from "utils/get_feature_flags_config";

import { getAuthTokens, getTokensFromOldPlace, writeAuthTokens } from "../../../auth/tokens";
import getSessionParamsFromUrl from "../../utils/get_session_params_from_url";
import {
  CHOOSE_GROUP,
  CHOOSE_PROPERTY,
  SESSION_ADD,
  SET_ACTIVE_FEATURE_FLAGS,
  SET_ALLOWED_CHANNELS,
  SET_APP_MODE,
  SET_APP_SETTINGS,
  SET_UI_SETTINGS,
} from "../constants";

const CACHE_KEY = "CHANNEX_SESSION";

const { appMode, propertyId, groupId, allowedChannels, allowNotificationsEdit, readOnlyAvailability, hideMessagesList } = getSessionParamsFromUrl();
const activeFeatureFlags = getFeatureFlagsConfig();
const cache = AppStorageManager.getItem(CACHE_KEY);

const getActiveProperty = () => {
  if (appMode === appModes.HEADLESS) {
    return ["url", propertyId];
  }

  if (propertyId) {
    return ["url", propertyId];
  }

  return ["store", cache?.activeProperty];
};

const getActiveGroup = (activePropertySource) => {
  if (appMode === appModes.HEADLESS) {
    return groupId;
  }

  if (activePropertySource === "url") {
    return null;
  }

  return cache?.activeGroup;
};

const [activePropertySource, activeProperty] = getActiveProperty();
const activeGroup = getActiveGroup(activePropertySource);

const initialState = {
  ...cache,
  appMode,
  activeProperty,
  activeGroup,
  activeFeatureFlags,
  allowedChannels,
  allowNotificationsEdit,
  readOnlyAvailability,
  hideMessagesList,
};

const ACTION_HANDLERS = {
  [SESSION_ADD]: (state, action) => {
    const newSession = {
      authToken: action.payload.token,
      refreshToken: action.payload.refresh_token,
    };

    return { ...state, ...newSession };
  },
  [CHOOSE_PROPERTY]: (state, action) => {
    let result = null;

    switch (state) {
      case null:
        result = state;
        break;

      default:
        result = { ...state, activeProperty: action.payload };
        break;
    }

    return result;
  },
  [CHOOSE_GROUP]: (state, action) => {
    let result = null;

    switch (state) {
      case null:
        result = state;
        break;

      default:
        result = { ...state, activeGroup: action.payload };
        break;
    }

    return result;
  },
  [SET_APP_MODE]: (state, action) => {
    return { ...state, appMode: action.payload };
  },
  [SET_APP_SETTINGS]: (state, action) => {
    return { ...state, appSettings: action.payload };
  },
  [SET_ALLOWED_CHANNELS]: (state, action) => {
    return { ...state, allowedChannels: action.payload };
  },
  [SET_UI_SETTINGS]: (state, action) => {
    return { ...state, uiSettings: action.payload };
  },
  [SET_ACTIVE_FEATURE_FLAGS]: (state, action) => {
    return { ...state, activeFeatureFlags: action.payload };
  },
};

// tmp auth tokens migration code
const oldAuthTokens = getTokensFromOldPlace();
const newAuthTokens = getAuthTokens();
if (oldAuthTokens.authToken && (oldAuthTokens.authToken !== newAuthTokens.authToken)) {
  writeAuthTokens(oldAuthTokens);
}

export default function sessionReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  const updated_state = handler ? handler(state, action) : state;

  // tmp auth tokens migration code
  const authTokens = getAuthTokens();
  if (authTokens.authToken) {
    updated_state.authToken = authTokens.authToken;
    updated_state.refreshToken = authTokens.refreshToken;
  }

  const isWritten = AppStorageManager.setItem(CACHE_KEY, updated_state);

  if (!isWritten) {
    Sentry.captureException(new Error("session information not written to storage"));
  }

  return updated_state;
}
