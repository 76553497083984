const numberFormatter = new Intl.NumberFormat();

export const formatNumber = (value) => {
  let formattedValue = value;

  if (typeof value === "number") {
    formattedValue = numberFormatter.format(value);
  }

  return formattedValue;
};
