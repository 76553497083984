import React from "react";
import { useTranslation } from "react-i18next";

import FormCheckbox from "components/forms/inputs/formik/form_checkbox";

import styles from "./form_trusted_device_checkbox.module.css";

export default function FormTrustedDeviceCheckbox() {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <FormCheckbox name="trusted_device" />
      <div className={styles.label}>
        {t("sign_in_page:trusted_device")}
      </div>
    </div>
  );
}
