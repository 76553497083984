import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Form } from "antd";
import compose from "recompose/compose";
import * as yup from "yup";

import errorMessages from "config/constants/errors";

import ChannexForm from "components/channex_form";
import FormikFormInput from "components/forms/inputs/formik/form_input";
import FormikFormSelect from "components/forms/inputs/formik/form_select";
import RadioInput from "components/forms/inputs/formik/radio";

class ParkingPolicyForm extends Component {
  VALIDATION_SCHEMA = yup.object().shape({
    parking_type: yup.string().required(errorMessages.required()),
    parking_reservation: yup.string().when("parking_type", {
      is: (val) => val !== "none",
      then: () => yup.string().required(errorMessages.required()),
    }),
    parking_is_private: yup.boolean().when("parking_type", {
      is: (val) => val !== "none",
      then: () => yup.boolean().required(errorMessages.required()),
    }),
    parking_cost_type: yup.string(),
    parking_cost: yup
      .number()
      .nullable()
      .when("parking_cost_type", {
        is: "paid",
        then: () => yup
          .number()
          .positive(errorMessages.positive())
          .required(errorMessages.required())
          .typeError(errorMessages.number()),
      }),
  });

  DEFAULT_VALUE = {
    parking_type: "none",
    parking_reservation: "not_available",
    parking_is_private: false,
    parking_cost_type: "free",
  };

  static FIELDS = [
    "parking_type",
    "parking_reservation",
    "parking_is_private",
    "parking_cost_type",
    "parking_cost",
  ];

  inputRefs = {
    parking_type: React.createRef(),
    parking_reservation: React.createRef(),
    parking_is_private: React.createRef(),
    parking_cost_type: React.createRef(),
    parking_cost: React.createRef(),
  };

  componentDidMount() {
    const { value = {}, focusField, onChange } = this.props;
    const { parking_cost, parking_cost_type } = value;

    if ((parking_cost !== null && parking_cost !== undefined) && parking_cost_type !== "paid") {
      onChange({ ...value, parking_cost_type: "paid" });
    }

    if (focusField) {
      this.inputRefs?.[focusField]?.current?.focus();
    }
  }

  componentDidUpdate() {
    const { value = {}, focusField, onChange } = this.props;
    const { parking_cost, parking_cost_type } = value;

    if ((parking_cost !== null && parking_cost !== undefined) && parking_cost_type !== "paid") {
      onChange({ ...value, parking_cost_type: "paid" });
    }

    if (focusField) {
      this.inputRefs?.[focusField]?.current?.focus();
    }
  }

  getTypeOptions = () => {
    const { t } = this.props;

    return [
      {
        value: "none",
        representation: t("policies:parking_policy:type_options:none"),
      },
      {
        value: "on_site",
        representation: t("policies:parking_policy:type_options:on_site"),
      },
      {
        value: "nearby",
        representation: t("policies:parking_policy:type_options:nearby"),
      },
    ];
  };

  getParkingReservationOptions = () => {
    const { t } = this.props;

    return [
      {
        value: "not_available",
        representation: t("policies:parking_policy:parking_reservation_options:not_available"),
      },
      {
        value: "not_needed",
        representation: t("policies:parking_policy:parking_reservation_options:not_needed"),
      },
      {
        value: "needed",
        representation: t("policies:parking_policy:parking_reservation_options:needed"),
      },
    ];
  };

  getParkingPropertyOptions = () => {
    const { t } = this.props;

    return [
      {
        value: false,
        representation: t("policies:parking_policy:parking_property_options:false"),
      },
      {
        value: true,
        representation: t("policies:parking_policy:parking_property_options:true"),
      },
    ];
  };

  getCostOptions = () => {
    const { t } = this.props;

    return [
      {
        value: "free",
        representation: t("policies:parking_policy:cost_options:free"),
      },
      {
        value: "paid",
        representation: t("policies:parking_policy:cost_options:paid"),
      },
    ];
  };

  isParkingEnabled = (values) => {
    const { parking_type } = values;

    return parking_type && parking_type !== "none";
  };

  isPaidParking = (values) => {
    const { parking_cost_type } = values;

    return parking_cost_type === "paid";
  };

  handleChange = (values) => {
    const { onChange } = this.props;

    const resultingValue = this.isPaidParking(values)
      ? values
      : { ...values, parking_cost: null };

    onChange(resultingValue);
  };

  render() {
    const { t, value, componentRef, errors } = this.props;

    return (
      <ChannexForm
        defaultValue={this.DEFAULT_VALUE}
        value={value}
        errors={errors}
        componentRef={componentRef}
        validationSchema={this.VALIDATION_SCHEMA}
        fields={ParkingPolicyForm.FIELDS}
        onChange={this.handleChange}
      >
        {({ values, handleSubmit }) => (
          <Form onFinish={handleSubmit}>
            <FormikFormSelect
              name="parking_type"
              inputRef={this.inputRefs.parking_type}
              view="horizontal"
              placeholder={t("policies:parking_policy:title")}
              label={t("policies:parking_policy:title")}
              options={this.getTypeOptions()}
            />
            {this.isParkingEnabled(values) && (
              <>
                <FormikFormSelect
                  name="parking_reservation"
                  inputRef={this.inputRefs.parking_reservation}
                  view="horizontal"
                  placeholder={t("policies:parking_policy:dict:parking_reservation")}
                  label={t("policies:parking_policy:dict:parking_reservation")}
                  options={this.getParkingReservationOptions()}
                />
                <RadioInput
                  name="parking_is_private"
                  inputRef={this.inputRefs.parking_is_private}
                  label={t("policies:parking_policy:dict:parking_property")}
                  options={this.getParkingPropertyOptions()}
                />
                <RadioInput
                  label={t("policies:parking_policy:dict:is_paid")}
                  name="parking_cost_type"
                  inputRef={this.inputRefs.parking_cost_type}
                  options={this.getCostOptions()}
                />
                {this.isPaidParking(values) && (
                  <FormikFormInput
                    name="parking_cost"
                    inputRef={this.inputRefs.parking_cost}
                    view="horizontal"
                    placeholder={t("policies:parking_policy:dict:cost")}
                    label={t("policies:parking_policy:dict:cost")}
                    addonAfter={value.currency}
                  />
                )}
              </>
            )}
          </Form>
        )}
      </ChannexForm>
    );
  }
}

const enhance = compose(withTranslation());

export default enhance(ParkingPolicyForm);
