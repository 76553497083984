import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";
import { getPropertyOptionById } from "store/storage/selectors/properties_selector";

import getChannelInfoByCode from "utils/get_channel_info_by_code";

import { MainSection, RightSection } from "./common";

import styles from "./events_list.module.css";

const getChannelIcon = (booking_unique_id) => {
  if (booking_unique_id) {
    const channelCode = booking_unique_id.substr(0, 3);

    const channel = getChannelInfoByCode(channelCode);

    if (channel && channel.icon) {
      return <img src={channel.icon} className={styles.icon} alt={channel.title} />;
    }
    return <div className={styles.emptyIcon}>{channelCode}</div>;
  }
  return null;
};

function EventsListItemAirbnbEvent({ item, isMobile }) {
  const { t } = useTranslation();
  const { event, payload } = item;
  const property = useSelector(getPropertyOptionById(item.property_id));

  const eventTitle = useMemo(() => {
    return t("live_feed_events_widget:events:airbnb_notification").toUpperCase();
  }, [t]);

  const channelIcon = getChannelIcon("ABB");
  const eventTitleClassName = classNames(styles.event, styles[`event--${event}`]);

  return (
    <>
      <MainSection>
        <p className={eventTitleClassName}>{eventTitle}</p>
        <p className={styles.arrivalDate}>{payload.title}</p>
        <p className={styles.description}>{property ? property.title : t("live_feed_events_widget:unknown_property")} {payload.subtitle ? "|" : ""} {payload.subtitle}</p>
      </MainSection>
      <RightSection
        isMobile={isMobile}
        icon={channelIcon}
        amount={payload.expected_payout_amount_accurate}
      />
    </>
  );
}

EventsListItemAirbnbEvent.propTypes = {
  item: PropTypes.shape({}),
};

export default EventsListItemAirbnbEvent;
