import { createSelector } from "reselect";

import { features } from "config/feature_flags";

export const getUser = (state) => state.user;
export const getSession = (state) => state.session;
export const getAppSettings = (state) => state.session.appSettings;
export const getAppMode = (state) => state.session.appMode;
export const getActivePropertyId = (state) => state.session.activeProperty;
export const getActiveGroup = (state) => state.session.activeGroup;
export const getActiveFeatureFlags = (state) => state.session.activeFeatureFlags;
export const getDisableUserManagement = (state) => state.session.activeFeatureFlags[features.DISABLE_USER_MANAGEMENT];
export const getEnableUserManagement = (state) => state.session.activeFeatureFlags[features.ENABLE_USER_MANAGEMENT];
export const getAllowNotificationsEdit = (state) => state.session.allowNotificationsEdit;
export const getIframeFlags = (state) => ({
  allowNotificationsEdit: state.session.allowNotificationsEdit,
  readOnlyAvailability: state.session.readOnlyAvailability,
  hideMessagesList: state.session.hideMessagesList,
});

export const getUserRole = createSelector(getUser, (user) => {
  return user?.system_role || null;
});

export const getWhiteLabelFlag = (flag) => createSelector(getAppSettings, ({ features: appFeatures }) => {
  return appFeatures.includes(flag);
});

export const getUserUiSettings = createSelector(getSession, (session) => {
  return session.uiSettings || null;
});
