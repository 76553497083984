import React from "react";
import { Typography } from "antd";

export const StepTitle = ({ children }) => {
  return (
    <Typography.Title
      level={4}
      style={{ paddingBottom: 20, marginBottom: 0 }}
    >
      {children}
    </Typography.Title>
  );
};
