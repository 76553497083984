import React from "react";
import { useTranslation } from "react-i18next";
import { LinkOutlined } from "@ant-design/icons";
import { Alert } from "antd";

import CopyToClipboard from "components/forms/buttons/copy_to_clipboard";

import styles from "./air_bnb.module.css";

export default function AirBnbAuthLink({ authUrl, visible }) {
  const { t } = useTranslation();

  if (!visible) {
    return null;
  }

  return (
    <Alert
      showIcon
      type="info"
      icon={<LinkOutlined className={styles.icon} />}
      message={
        <>
          {t("channels_page:connection_test:or")}
          <CopyToClipboard text={authUrl} showIcon={false}>
            {t("channels_page:connection_test:copy_to_share")}
          </CopyToClipboard>
        </>
      }
      action={
        <CopyToClipboard type="button" text={authUrl} showIcon={false}>
          {t("general:action:copy")}
        </CopyToClipboard>
      }
    />
  );
}
