import { useMemo } from "react";

const usePagination = ({ total, limit, page }) => {
  const totalPages = useMemo(() => Math.ceil(total / limit), [limit, total]);

  const isFirstPage = page <= 1;
  const isLastPage = page >= totalPages;

  const nextPage = isLastPage ? page : page + 1;

  const prevPage = isFirstPage ? page : page - 1;

  return {
    nextPage,
    prevPage,
    isLastPage,
  };
};

export default usePagination;
