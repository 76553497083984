import {
  LIVE_FEED_EVENT_ADD,
  LIVE_FEED_EVENTS_LOAD,
  LIVE_FEED_EVENTS_RESET,
  LIVE_FEED_SET_REQUEST_ID,
} from "../constants";

export function liveFeedEventsLoad(storage) {
  return function (entities, meta, filter, requestId) {
    storage.dispatch({
      type: LIVE_FEED_EVENTS_LOAD,
      payload: { entities, meta, filter, requestId },
    });
  };
}

export function liveFeedEventAdd(storage) {
  return function (event) {
    storage.dispatch({
      type: LIVE_FEED_EVENT_ADD,
      payload: event,
    });
  };
}

export function liveFeedEventsReset(storage) {
  return function () {
    storage.dispatch({ type: LIVE_FEED_EVENTS_RESET });
  };
}

export function liveFeedSetRequestId(storage) {
  return function (id) {
    storage.dispatch({ type: LIVE_FEED_SET_REQUEST_ID, payload: id });
  };
}
