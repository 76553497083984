import React from "react";
import { SortableContainer } from "react-sortable-hoc";

import SortableItem from "components/mapping/mapping_list_settings/components/sortable_item";

export default SortableContainer(({ items }) => {
  return (
    <div>
      {items.map((value, index) => (
        <SortableItem key={`item-${index}`} index={index} title={value.title} />
      ))}
    </div>
  );
});
