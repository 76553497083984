export default function getEnabledApplications(applications, filters) {
  if (!Array.isArray(applications) || !filters) {
    return [];
  }

  const enabledApps = applications.filter((app) => {
    const filterKeys = Object.keys(filters);

    const isEnabled = filterKeys.every((key) => app[key] === filters[key]);

    return isEnabled;
  });

  return enabledApps;
}
