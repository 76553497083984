import React from "react";
import { withTranslation } from "react-i18next";

import showErrorMessage from "utils/show_error_message";

export default (WrappedComponent) => {
  class WithFormSubmission extends React.Component {
    state = {
      isSubmitting: false,
      errors: {},
    };

    handleError = (error) => {
      const { t } = this.props;

      this.setState({
        isSubmitting: false,
      });

      if (!error.errors || !error.errors.code) {
        showErrorMessage(t("general:undefined_error"));
        return Promise.reject({});
      }

      if (error.errors.code !== "validation_error") {
        showErrorMessage(t(`general:${error.errors.code}`));
        return Promise.reject({});
      }

      return Promise.reject(error.errors.details);
    };

    makeRequest = (request) => {
      this.setState({
        isSubmitting: true,
      });

      return request
        .then((response) => {
          this.setState({
            errors: {},
            isSubmitting: false,
          });

          return response;
        })
        .catch(this.handleError);
    };

    render() {
      const { isSubmitting, errors } = this.state;

      return (
        <WrappedComponent
          makeRequest={this.makeRequest}
          isSubmitting={isSubmitting}
          errors={errors}
          {...this.props}
        />
      );
    }
  }

  return withTranslation()(WithFormSubmission);
};
