import React from "react";
import { useTranslation } from "react-i18next";
import { DownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";

import Flexbox from "components/flexbox";

export default function FinishedState({ handleDownloadClick }) {
  const { t } = useTranslation();

  return (
    <div>
      <p>{t("bookings_export:success_message")}</p>
      <Flexbox justifyContent="end">
        <Button key="download" icon={<DownloadOutlined />} type="primary" onClick={handleDownloadClick}>
          {t("bookings_export:action:download")}
        </Button>
      </Flexbox>
    </div>
  );
}
