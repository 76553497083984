import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Result } from "antd";
import store from "store";

import Loading from "components/loading";

import showErrorMessageFromResponse from "utils/show_error_message_from_response";
import useBoolState from "utils/use_bool_state";

import HotelPolicyEditDrawer from "../../../../drawers/hotel_policy_edit_drawer";
import PropertyFormDrawerControlled from "../../../../drawers/property_form_drawer_controlled";

import { mapErrorToUi } from "./error_mapping/map_error_to_ui";
import { ErrorMessage } from "./error_message";

const { Channels } = store;

export default function MissingContent({ channelId, propertiesOptionsById, onTabNavigate }) {
  const { t } = useTranslation();

  const [loading, setLoading, setLoadingComplete] = useBoolState(true);
  const [isPropertyDrawerOpen, openPropertyDrawer, closePropertyDrawer] = useBoolState(false);
  const [isHotelPolicyDrawerOpen, openHotelPolicyDrawer, closeHotelPolicyDrawer] = useBoolState(false);
  const [drawerProps, setDrawerProps] = useState({});
  const [missedContent, setMissedContent] = useState([]);

  const loadReadinessStatus = useCallback(() => {
    setLoading();

    Channels.checkReadiness(channelId)
      .then((errors) => {
        setMissedContent(errors);
      })
      .catch(showErrorMessageFromResponse)
      .finally(setLoadingComplete);
  }, [channelId, setLoading, setLoadingComplete]);

  useEffect(
    function initReadinessInfo() {
      loadReadinessStatus();
    },
    [loadReadinessStatus],
  );

  const handleErrorClick = useCallback((error) => {
    if (error.entity === "Property") {
      const uiPayload = mapErrorToUi(error);
      setDrawerProps(uiPayload);

      openPropertyDrawer();
    }

    if (error.entity === "HotelPolicy") {
      const uiPayload = mapErrorToUi(error);
      setDrawerProps(uiPayload);

      openHotelPolicyDrawer();
    }

    if (error.entity === "Channel") {
      const uiPayload = mapErrorToUi(error);

      onTabNavigate(uiPayload);
    }
  }, [onTabNavigate, openHotelPolicyDrawer, openPropertyDrawer]);

  if (loading) {
    return <Loading />;
  }

  if (missedContent.length === 0) {
    return (
      <Result
        status="success"
        title={t("channels_page:form:missing_content:success_message")}
      />
    );
  }

  return (
    <div>
      {missedContent.map((error, i) => (
        <ErrorMessage
          key={i}
          error={error}
          propertiesOptionsById={propertiesOptionsById}
          onClick={handleErrorClick}
        />
      ))}

      <PropertyFormDrawerControlled
        visible={isPropertyDrawerOpen}
        onClose={closePropertyDrawer}
        {...drawerProps}
      />

      <HotelPolicyEditDrawer
        visible={isHotelPolicyDrawerOpen}
        onClose={closeHotelPolicyDrawer}
        {...drawerProps}
      />
    </div>
  );
}
