import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import ChannexDrawerRoutable from "drawers/channex_drawer_routable";

import ChannelView from "./channel_view";

class ChannelViewDrawer extends Component {
  render() {
    const { t } = this.props;

    return (
      <ChannexDrawerRoutable title={t("channels_page:view_dialog_header")}>
        {() => <ChannelView />}
      </ChannexDrawerRoutable>
    );
  }
}

export default withTranslation()(ChannelViewDrawer);
