import { useCallback, useEffect, useState } from "react";

import { useDebouncedValue } from "../use_debounced_value/use_debounced_value";

export function useWindowSize(options = {}) {
  const {
    debounce,
  } = options;

  const [windowSize, setWindowSize] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  const handleResize = useCallback(() => {
    setWindowSize({
      height: window.innerHeight,
      width: window.innerWidth,
    });
  }, [setWindowSize]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  const debouncedWindowSize = useDebouncedValue(windowSize, debounce);

  return debouncedWindowSize;
}
