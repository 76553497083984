import attributesExtractor from "../utils/attributes_extractor";
import { convertToCamelCase, convertToSnakeCase } from "../utils/case_converter";

import { httpClient } from "./http_client";

export const bookingsApiClient = {
  noShowReport: async ({ bookingId, collectFee, feeAmount }) => {
    const params = {
      waivedFees: collectFee,
    };

    if (typeof feeAmount !== "undefined" && feeAmount !== null) {
      params.penaltyAmount = feeAmount;
    }

    const body = convertToSnakeCase({ noShowReport: params });
    const path = "bookings/:bookingId/no_show".replace(":bookingId", bookingId);

    const response = await httpClient.send("POST", path, body);

    return convertToCamelCase(attributesExtractor(response.data));
  },
};
