import React from "react";
import { useTranslation } from "react-i18next";
import { Tag } from "antd";

const STATUS_COLORS = {
  new: "green",
  cancelled: "red",
  modified: "orange",
};

export const BookingRevisionStatusTag = ({ status }) => {
  const { t } = useTranslation();

  return (
    <Tag color={STATUS_COLORS[status]}>
      {t(`bookings_page:booking_view_dialog:status:${status}`)}
    </Tag>
  );
};
