let transport;

export default class ARI {
  constructor(container) {
    transport = container.transport;
  }

  get(filters) {
    return transport.send("GET", "restrictions", { filter: filters });
  }

  availability(filters) {
    return transport.send("GET", "availability", { filter: filters });
  }

  update(attrs) {
    return transport.send("POST", "restrictions", { values: attrs });
  }

  updateAvailability(attrs) {
    return transport.send("POST", "availability", { values: attrs });
  }
}
