import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import InputDerivedRate from "components/forms/items/input_derived_rate";

export default function ChannelDerivedRateForm({ form }) {
  const { t } = useTranslation();

  const handleChange = useCallback(
    (field, value) => {
      form.setFieldValue(`settings.${field}`, value);
    },
    [form],
  );

  return (
    <>
      <legend>{t("channels_page:form:derived_rates_settings_legend")}</legend>
      <InputDerivedRate
        errors={form.errors.settings}
        model={form.values.settings}
        onChange={handleChange}
      />
    </>
  );
}
