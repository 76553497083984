import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { Button, Col, Modal, Row } from "antd";

import { Currency, Percent } from "components/value_presentation";

import TaxAddButton from "../tax_add_button";

import styles from "./tax_tree_node.module.css";

const PADDING_SIZE = 16;

class TaxTreeNode extends Component {
  handleDelete = (taxToDelete) => {
    const { value, onChange, t } = this.props;
    const { taxes = [] } = value;
    const { id, title } = taxToDelete;

    Modal.confirm({
      title: t("taxes:tax_set:form:delete_modal:title", { title }),
      content: t("taxes:tax_set:form:delete_modal:content"),
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        const updatedTaxes = taxes.filter((tax) => tax.id !== id);
        value.taxes = updatedTaxes;

        onChange(value);
      },
    });
  };

  handleChange = (childNode) => {
    const { value, onChange } = this.props;
    const { taxes } = value;

    const taxIndex = taxes.findIndex((node) => node.id === childNode.id);

    value.taxes[taxIndex] = childNode;

    onChange(value);
  };

  handleRowAdd = (newTax) => {
    const { value, level, onChange } = this.props;
    const { taxes = [] } = value;

    newTax.level = level;

    const updatedTaxes = [...taxes, newTax];
    const updatedValue = { ...value, taxes: updatedTaxes };

    onChange(updatedValue);
  };

  handleRowEdit = () => {
    const { value, onEdit } = this.props;

    onEdit(value.id);
  };

  handleRowDelete = () => {
    const { value, onDelete = () => {} } = this.props;

    onDelete(value);
  };

  handleMoveLevelDown = () => {
    const { value, onChange } = this.props;

    value.level = value.level !== 0 ? value.level - 1 : value.level;

    onChange(value);
  };

  handleMoveLevelUp = () => {
    const { value, onChange } = this.props;

    value.level += 1;

    onChange(value);
  };

  render() {
    const { t, value, route = [], propertyId, onEdit, level } = this.props;
    const { title, id, taxes, is_inclusive, rate, logic, currency } = value;

    const nodes = taxes
      && taxes
        .filter((tax) => tax.level === level)
        .map((node, key) => {
          const newRoute = [...route, key];
          return (
            <TaxTreeNode
              t={t}
              key={`${id}_${String(newRoute)}`}
              propertyId={propertyId}
              value={node}
              route={newRoute}
              onEdit={onEdit}
              onChange={this.handleChange}
              onDelete={this.handleDelete}
              level={level}
            />
          );
        });

    if (!route.length) {
      return nodes;
    }

    const nodeDepth = route.length - 1;
    const paddingLeft = (nodeDepth - 1) * PADDING_SIZE;
    const taxRate = logic === "percent" ? (
      <Percent value={rate} />
    ) : (
      <Currency value={rate} currency={currency} />
    );

    const spacer = nodeDepth ? (
      <span style={{ paddingLeft }} className={styles.spacer}>
        └
      </span>
    ) : null;

    return (
      <>
        <Row>
          <Col xs={10} className={styles.title}>
            {spacer}
            {title}
          </Col>
          <Col xs={4} className={styles.inclusive}>
            {is_inclusive && <CheckCircleOutlined />}
          </Col>
          <Col xs={4}>{taxRate}</Col>
          <Col xs={6} className={styles.buttons}>
            <Button
              data-cy="move_level_up"
              type="link"
              size="small"
              icon={<ArrowUpOutlined />}
              onClick={this.handleMoveLevelUp}
            />
            <Button
              data-cy="move_level_down"
              type="link"
              size="small"
              icon={<ArrowDownOutlined />}
              onClick={this.handleMoveLevelDown}
            />
            <Button
              data-cy="edit_tax"
              type="link"
              size="small"
              icon={<EditOutlined />}
              onClick={this.handleRowEdit}
            />
            <TaxAddButton
              dataCy="add_derived_tax"
              parentTax={value}
              propertyId={propertyId}
              onSelect={this.handleRowAdd}
            />
            <Button
              data-cy="delete_tax"
              type="link"
              size="small"
              icon={<DeleteOutlined />}
              onClick={this.handleRowDelete}
            />
          </Col>
        </Row>
        {nodes}
      </>
    );
  }
}

export default withTranslation()(TaxTreeNode);
