import React, { useState } from "react";
import ReactDiffViewer from "react-diff-viewer";
import { Checkbox, Flex } from "antd";
import jsonpath from "jsonpath";
import _ from "lodash";

import { RevisionSelect } from "./revision_select";

const Container = ({ children }) => (
  <div style={{ width: "50%", padding: "12px 0" }}>
    {children}
  </div>
);

const FIELDS_TO_REMOVE = [
  "id",
  "system_id",
  "inserted_at",
  "raw_message",
  "meta.ruid",
  "meta.previous_system_id",
  "meta.last_action",
  "rooms[*].booking_room_id",
];

export const BookingRevisionsDiff = ({ booking }) => {
  const { booking_revisions: bookingRevisions } = booking;
  const [removeAlwaysChangedFields, setRemoveAlwaysChangedFields] = useState(true);

  const toggleAlwaysChangedFields = () => {
    setRemoveAlwaysChangedFields((prev) => !prev);
  };

  const revisions = Object.values(bookingRevisions).reverse();
  const [revision1, revision2] = revisions.slice(-2);

  const [oldRevision, setOldRevision] = useState(revision1);
  const [newRevision, setNewRevision] = useState(revision2);

  const prepareRevision = (revision) => {
    if (removeAlwaysChangedFields) {
      revision = _.cloneDeep(revision);

      FIELDS_TO_REMOVE.forEach((field) => {
        const nodes = jsonpath.nodes(revision, field);

        nodes.forEach((node) => {
          const lodashPath = node.path.slice(1).join(".");
          _.unset(revision, lodashPath);
        });
      });
    }

    return revision;
  };

  return (
    <Flex flex={1} vertical>
      <Flex style={{ justifyContent: "flex-end" }}>
        <Checkbox data-cy="removeAlwaysChangedFieldsCheckbox" checked={removeAlwaysChangedFields} onClick={toggleAlwaysChangedFields}>Remove always changed fields</Checkbox>
      </Flex>
      <Flex>
        <Container>
          <RevisionSelect
            value={oldRevision.id}
            disabledValue={newRevision.id}
            revisions={revisions}
            onChange={setOldRevision}
          />
        </Container>
        <Container>
          <RevisionSelect
            value={newRevision.id}
            disabledValue={oldRevision.id}
            revisions={revisions}
            onChange={setNewRevision}
          />
        </Container>
      </Flex>
      <div style={{ overflowY: "auto", flex: 1 }}>
        <ReactDiffViewer
          styles={{
            line: {
              fontSize: "12px",
            },
            lineNumber: {
              lineHeight: "20px",
            },
            contentText: {
              wordBreak: "break-all",
            },
          }}
          disableWordDiff
          oldValue={JSON.stringify(prepareRevision(oldRevision), null, 2)}
          newValue={JSON.stringify(prepareRevision(newRevision), null, 2)}
          splitView
        />
      </div>
    </Flex>
  );
};
