import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import ApplicationLinks from "./components/application_links";
import NavigationItem from "./components/navigation_item";
import Overlay from "./components/overlay";
import LINKS from "./nav_links";

import styles from "./navigation.module.css";

export default function Navigation({ isOpened, onCloseMenu, onLogoClick }) {
  const { t } = useTranslation();

  const classNameWrapper = classNames(styles.appNavigation, {
    [`${styles.appNavigation__opened}`]: isOpened,
  });

  const navLinks = [...LINKS];

  return (
    <div className={classNameWrapper}>
      <img
        src="/assets/logo_small.svg"
        onClick={onLogoClick}
        className={styles.appNavigation__Logo}
        alt="Logo"
      />
      {navLinks.map(([url, code]) => (
        <NavigationItem
          key={url}
          to={url}
          title={t(`general:links:${code}`)}
          onClick={onCloseMenu}
        />
      ))}
      <ApplicationLinks handleCloseMenu={onCloseMenu} />

      <Overlay onClick={onCloseMenu} />
    </div>
  );
}
