import React from "react";
import PropTypes from "prop-types";

import AdvancedSettingsButton from "../items/advanced_settings_button";

import GroupRestrictions from "./group_restrictions";

function GroupAdvancedSettings(props) {
  const { model } = props;
  return (
    <>
      <AdvancedSettingsButton {...props} />
      {model.is_advanced_rate && (
        <GroupRestrictions {...props} />
      )}
    </>
  );
}

GroupAdvancedSettings.propTypes = {
  t: PropTypes.func,
  model: PropTypes.object,
  errors: PropTypes.object,
  onChange: PropTypes.func,
};

export default GroupAdvancedSettings;
