import React from "react";

import { GuestReview } from "./guest_review";
import { ReplyToGuestFeedback } from "./reply_to_guest_feedback";

export const DoubleBlind = ({ review }) => {
  const { isHidden } = review.attributes;

  const ReviewBody = isHidden ? GuestReview : ReplyToGuestFeedback;

  return (
    <ReviewBody review={review} />
  );
};
