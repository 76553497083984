import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Progress, Space } from "antd";

import Flexbox from "components/flexbox";

import formatNumber from "utils/format_number";

export default function InProgressState({ totalCount, exportedCount, onCloseClick }) {
  const { t } = useTranslation();

  const percent = Math.round((exportedCount / totalCount) * 100);

  return (
    <div>
      <p>{t("bookings_export:in_progress_message", { count: formatNumber(totalCount) })}</p>
      <Progress percent={percent} status="active" />
      <Flexbox justifyContent="end">
        <Space>
          <Button onClick={onCloseClick}>{t("general:action:close")}</Button>
          <Button type="primary" loading>{t("bookings_export:action_state:exporting")}</Button>
        </Space>
      </Flexbox>
    </div>
  );
}
