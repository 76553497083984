import { useEffect, useState } from "react";
import { captureException } from "@sentry/react";
import store from "store";

import groupRatePlansByRoomTypeId from "../utils/group_rate_plans_by_room_type_id";

const { RatePlans } = store;

export default function useRatePlans(propertyId, { multiOccupancy = false, groupResults = false } = {}) {
  const [ratePlans, setRatePlans] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadRatePlans = async () => {
      let loadedRatePlans;

      try {
        loadedRatePlans = await RatePlans.options({ property_id: propertyId }, { multi_occupancy: multiOccupancy });

        if (groupResults) {
          loadedRatePlans = groupRatePlansByRoomTypeId(loadedRatePlans);
        }

        setRatePlans(loadedRatePlans);
      } catch (apiError) {
        setError(apiError);
      } finally {
        setIsLoading(false);
      }
    };

    loadRatePlans().catch(captureException);
  }, [propertyId, multiOccupancy, groupResults]);

  return { ratePlans, error, isLoading };
}
