import messageStatuses from "config/constants/message_statuses";

import { convertToCamelCase, convertToSnakeCase } from "../utils/case_converter";
import extractRelationships from "../utils/relationships_extractor";

let transport;
let storage;

const ENDPOINT = "bookings";

export default class Bookings {
  constructor(container) {
    transport = container.transport;
    storage = container.storage;
  }

  list(filter = {}, pagination = { page: 1, limit: 10 }, order = { inserted_at: "desc" }) {
    return transport.send("GET", `${ENDPOINT}`, { filter, pagination, order }).then((response) => {
      storage.bookingsLoad(response.data, response.meta);

      return response;
    });
  }

  find(id, { relationships } = {}) {
    return transport
      .send("GET", `${ENDPOINT}/${id}`, { relationships })
      .then((response) => extractRelationships(response.data));
  }

  stats(meta = {}, order = { inserted_at: "desc" }) {
    return transport.send("GET", `${ENDPOINT}/stats`, { ...meta, order }).then((response) => {
      const data = convertToCamelCase(response.data.attributes);
      storage.bookingStatsLoad(data, response.meta);
      return response;
    });
  }

  messagesListByBookingId(bookingId, pagination = {}, order = { inserted_at: "desc" }) {
    return transport
      .send("GET", `${ENDPOINT}/${bookingId}/messages`, { pagination, order })
      .then((response) => {
        const data = convertToCamelCase(response.data);
        storage.channelMessagesLoad(bookingId, data, response.meta);
        return response;
      });
  }

  sendMessageByBookingId(bookingId, message) {
    const sendMessage = {
      message: {},
    };

    if (message.attributes.message) {
      sendMessage.message.message = message.attributes.message;
    }

    if (message.attributes.attachmentId) {
      sendMessage.message.attachment_id = message.attributes.attachmentId;
    }

    storage.channelMessagesAdd(bookingId, message);

    return transport
      .send("POST", `${ENDPOINT}/${bookingId}/messages`, sendMessage)
      .then((response) => {
        const newMessage = { ...response.data, requestId: message.requestId };

        storage.channelMessagesAdd(bookingId, newMessage);
        return response;
      })
      .catch((error) => {
        const newMessage = {
          attributes: {
            ...message.attributes,
            status: messageStatuses.FAILED,
          },
          requestId: message.requestId,
        };

        storage.channelMessagesAdd(bookingId, newMessage);
        throw error;
      });
  }

  resend(id) {
    return transport.send("POST", `${ENDPOINT}/${id}/resend`, {});
  }

  exportCSV(filters = {}) {
    const body = convertToSnakeCase({
      bookingExportRequest: {
        format: "CSV",
        searchQuery: {
          filter: filters,
        },
      },
    });

    return transport.send("POST", `${ENDPOINT}/export`, body).then((response) => response.data.attributes.token);
  }

  buildDownloadExportLink(token) {
    return transport.url(`${ENDPOINT}/export/${token}`);
  }

  initPdfBuild(bookingId) {
    return transport.send("POST", `${ENDPOINT}/${bookingId}/export/pdf`).then((response) => response.data.attributes.token);
  }

  pdfBlob(token) {
    return transport._get(`${ENDPOINT}/export/${token}/pdf`).then((data) => data.blob());
  }

  events(bookingId, page) {
    return transport.send("GET", `${ENDPOINT}/${bookingId}/events?pagination[limit]=100&pagination[page]=${page}&order[inserted_at]=asc`);
  }
}
