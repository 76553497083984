import { useMutation, useQueryClient } from "react-query";
import store from "store";

const { AdminWlPartners } = store;

export function useDeleteAction() {
  const queryClient = useQueryClient();

  return useMutation(({ id }) => AdminWlPartners.delete(id), {
    onSuccess: () => {
      queryClient.invalidateQueries("admin_wl_partners");
    },
  });
}
