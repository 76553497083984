import {
  CHOOSE_GROUP,
  CHOOSE_PROPERTY,
  SESSION_ADD,
  SET_APP_MODE,
  SET_APP_SETTINGS,
  SET_UI_SETTINGS,
} from "../constants";

export function sessionAdd(storage) {
  return function (session) {
    storage.dispatch({ type: SESSION_ADD, payload: session });
  };
}

export function chooseProperty(storage) {
  return function (property) {
    storage.dispatch({ type: CHOOSE_PROPERTY, payload: property });
  };
}

export function chooseGroup(storage) {
  return function (group) {
    storage.dispatch({ type: CHOOSE_GROUP, payload: group });
  };
}

export function setAppMode(storage) {
  return (mode) => {
    storage.dispatch({ type: SET_APP_MODE, payload: mode });
  };
}

export function setAppSettings(storage) {
  return (newSettings) => {
    storage.dispatch({ type: SET_APP_SETTINGS, payload: newSettings });
  };
}

export function setUiSettings(storage) {
  return (newSettings) => {
    storage.dispatch({ type: SET_UI_SETTINGS, payload: newSettings });
  };
}
