import React from "react";
import { useTranslation } from "react-i18next";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";

import styles from "./error_message.module.css";

export default function ErrorMessage({ onReload }) {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <ExclamationCircleOutlined className={styles.icon} />
        <div className={styles.caption}>{t("general:errors:error_boundary_message")}</div>
        <Button type="link" onClick={onReload}>
          {t("general:action:reload")}
        </Button>
      </div>
    </div>
  );
}
