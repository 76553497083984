import React from "react";

import * as Page from "components/page";

import WebhooksCRUD from "./components/webhooks_crud";

export default function WebhooksPage() {
  return (
    <Page.Main>
      <WebhooksCRUD />
    </Page.Main>
  );
}
