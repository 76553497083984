import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Form, Radio, Row, Space, Typography } from "antd";
import _ from "lodash";
import store from "store";

import useRatePlans from "data/use_rate_plans";
import useRoomTypes from "data/use_room_types";

import SubmitButton from "components/forms/buttons/submit_button";
import GlobalErrors from "components/forms/global_errors";
import FormCheckbox from "components/forms/inputs/form_checkbox";
import Loading from "components/loading";

import classifyApiErrors from "utils/classify_api_errors";
import parseApiErrors from "utils/parse_api_errors";

import styles from "./shared.module.css";

const { Applications } = store;

const buildSettings = (roomTypes, ratePlans, installation) => {
  return _.reduce(roomTypes, (acc, roomType) => {
    const ratePlanIds = ratePlans[roomType.id]?.map((rP) => rP.id);
    const mappedRatePlans = Object.values(installation.ratePlans).filter((rP) => ratePlanIds?.includes(rP.ratePlanId));

    acc[roomType.id] = {
      code: mappedRatePlans[0]?.settings?.roomTypeCode,
      title: roomType.title,
      ratePlans: {},
    };

    acc[roomType.id].ratePlans = _.reduce(ratePlans[roomType.id], (accRP, ratePlan) => {
      const mappedRatePlan = mappedRatePlans.find((rP) => rP.ratePlanId === ratePlan.id);

      accRP[ratePlan.id] = {
        code: mappedRatePlan?.settings?.ratePlanCode,
        ignoreRate: mappedRatePlan?.settings?.ignoreRate,
        primary: mappedRatePlan?.settings?.primary,
        id: mappedRatePlan?.id,
        title: ratePlan.title,
      };

      return accRP;
    }, acc[roomType.id].ratePlans);

    return acc;
  }, installation.settings || { hotelCode: null });
};

export default function YieldPlanetSettings({ installation, onClose }) {
  const { propertyId } = installation;

  const { t } = useTranslation();
  const [saveInProgress, setSaveInProgress] = useState(false);

  const [settings, setSettings] = useState(null);
  const [errors, setErrors] = useState(null);

  const { ratePlans, isLoading: isRatePlansLoading } = useRatePlans(propertyId, { multiOccupancy: true, groupResults: true });
  const { roomTypes, isLoading: isRoomTypesLoading } = useRoomTypes(propertyId, { sorted: true });

  useEffect(() => {
    if (isRatePlansLoading || isRoomTypesLoading || settings !== null) {
      return;
    }

    const initialSettings = buildSettings(roomTypes, ratePlans, installation);

    setSettings(initialSettings);
  }, [isRatePlansLoading, isRoomTypesLoading, settings, installation, ratePlans, roomTypes]);

  const submit = () => {
    const updatedSettings = _.omitBy(settings, (value, _key) => value?.title);

    const applicationRatePlans = Object.values(settings).reduce((acc, room) => {
      if (!room?.ratePlans) {
        return acc;
      }

      return Object.entries(room.ratePlans).reduce((accRatePlans, [ratePlanId, rP]) => {
        return [
          ...accRatePlans,
          {
            id: rP.id,
            rate_plan_id: ratePlanId,
            settings: {
              ignore_rate: rP.ignoreRate,
              primary: rP.primary,
            },
          },
        ];
      }, []).concat(acc);
    }, []);

    setSaveInProgress(true);

    Applications.update({
      ...installation,
      settings: updatedSettings,
      ratePlans: null,
      rate_plans: applicationRatePlans,
    })
      .then(onClose)
      .catch((error) => {
        if (!error.isValidationError) {
          throw error;
        }

        const parsedErrors = parseApiErrors(error);
        const { globalErrors } = classifyApiErrors(parsedErrors, []);

        setErrors(globalErrors);
      })
      .finally(() => setSaveInProgress(false));
  };

  const handleRatePlanChange = (roomTypeId) => {
    return (event) => {
      Object.keys(settings[roomTypeId].ratePlans).forEach((ratePlanId) => {
        settings[roomTypeId].ratePlans[ratePlanId].primary = false;
      });

      const ratePlanId = event.target.value;
      settings[roomTypeId].ratePlans[ratePlanId].primary = true;

      setSettings({ ...settings });
    };
  };

  const handleRatePlanSettingsChange = (roomTypeId, ratePlanId) => {
    return (checked) => {
      settings[roomTypeId].ratePlans[ratePlanId].ignoreRate = checked;

      setSettings({ ...settings });
    };
  };

  const getPrimaryRatePlan = (roomTypeId) => {
    const roomRatePlans = settings[roomTypeId].ratePlans;

    const primaryRatePlanId = Object.keys(roomRatePlans).findLast((ratePlanId) => roomRatePlans[ratePlanId]?.primary);

    return primaryRatePlanId;
  };

  const getRatePlanSettings = (roomTypeId, ratePlanId) => {
    return settings[roomTypeId].ratePlans[ratePlanId]?.ignoreRate;
  };

  if (isRatePlansLoading || isRoomTypesLoading || settings === null) {
    return <Loading />;
  }

  return (
    <div>
      <Form>
        <GlobalErrors errors={errors} />

        <Row>
          <Col span={24}>
            <strong>{t("applications_page:yield_planet:dialog_subheader")}</strong>
            <br />
            {t("applications_page:yield_planet:description")}
          </Col>
        </Row>

        {roomTypes.map((roomType) => (
          <div key={roomType.id}>
            <Row>
              <Col span={24} className={styles.roomTitle}>
                <strong>{roomType.title}</strong>
              </Col>
            </Row>

            {!ratePlans[roomType.id] && <Typography.Text type="secondary">No Rate Plan</Typography.Text>}

            {ratePlans[roomType.id] && (
              <Radio.Group style={{ width: "100%" }} onChange={handleRatePlanChange(roomType.id)} defaultValue={getPrimaryRatePlan(roomType.id)}>
                <Space style={{ width: "100%" }} direction="vertical">
                  {ratePlans[roomType.id].map((ratePlan) => (
                    <div key={ratePlan.id}>
                      <Radio key={ratePlan.id} value={ratePlan.id}>{ratePlan.title} {ratePlan.occupancy}</Radio>
                      <FormCheckbox
                        view="horizontal"
                        name="ignore_rate"
                        label={t("applications_page:yield_planet:ignore_rate")}
                        onChange={handleRatePlanSettingsChange(roomType.id, ratePlan.id)}
                        defaultValue={getRatePlanSettings(roomType.id, ratePlan.id)}
                      />
                    </div>
                  ))}
                </Space>
              </Radio.Group>
            )}
          </div>
        ))}
      </Form>

      <div className={styles.actions}>
        <SubmitButton onClick={submit} loading={saveInProgress}>
          {t("applications_page:yield_planet:save")}
        </SubmitButton>
      </div>
    </div>
  );
}
