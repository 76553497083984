const entitySpecificFieldErrorTranslations = [];

const getFieldI18n = (entity, field) => {
  const fieldI18n = {
    Property: {
      title: "properties_page:form:title_label",
      group_id: "properties_page:form:group_label",
      currency: "properties_page:form:currency_label",
      property_type: "properties_page:form:type_label",
      billing_type: "properties_page:form:billing_type",
      email: "properties_page:form:email_label",
      phone: "properties_page:form:phone_label",
      website: "properties_page:form:website_label",
      country: "properties_page:form:country_label",
      timezone: "properties_page:form:timezone_label",
      zip_code: "properties_page:form:zip_code_label",
      state: "properties_page:form:state_label",
      city: "properties_page:form:city_label",
      address: "properties_page:form:address_label",
      latitude: "properties_page:form:latitude_label",
      longitude: "properties_page:form:longitude_label",
      location_precision: "properties_page:form:location_precision_label",
    },
    HotelPolicy: {
      title: "policies:general_policy:property_title",
      currency: "policies:general_policy:currency",
      checkin_from_time: "policies:general_policy:check_in_from",
      checkin_to_time: "policies:general_policy:check_in_to",
      checkout_from_time: "policies:general_policy:check_out_from",
      checkout_to_time: "policies:general_policy:check_out_to",
      self_checkin_checkout: "policies:general_policy:self_checkin_checkout",
      max_count_of_guests: "policies:general_policy:max_guests",
      is_adults_only: "policies:general_policy:adults_only",
      infant_max_age: "policies:general_policy:infant_max_age",
      children_max_age: "policies:general_policy:children_max_age",
      internet_access_type: "policies:internet_access:title",
      internet_access_coverage: "policies:internet_access:dict:coverage",
      internet_access_cost_type: "policies:internet_access:dict:is_paid",
      internet_access_cost: "policies:internet_access:dict:cost",
      parking_type: "policies:parking_policy:title",
      parking_reservation: "policies:parking_policy:dict:parking_reservation",
      parking_is_private: "policies:parking_policy:dict:parking_property",
      parking_cost_type: "policies:parking_policy:dict:is_paid",
      parking_cost: "policies:parking_policy:dict:cost",
      pets_policy: "policies:pets_policy:title",
      pets_non_refundable_fee: "policies:pets_policy:pets_fee",
      pets_refundable_deposit: "policies:pets_policy:pets_deposit",
      smoking_policy: "policies:smoking_policy:title",
      enhanced_cleaning_practices: "policies:cleaning_policy:enhanced_cleaning_practices",
      partner_hygiene_link: "policies:cleaning_policy:partner_hygiene_link",
      cleaning_practices_description: "policies:cleaning_policy:cleaning_practices_description",
    },
    Channel: {
      channel: "channels_page:form:channel_label",
      group_id: "channels_page:form:group_label",
      title: "channels_page:form:title_label",
      currency: "channels_page:form:currency_label",
    },
  };

  if (entity === "Channel" && field.indexOf("settings.") !== -1) {
    return `channels_page:form:settings_${field.replaceAll("settings.", "")}_label`;
  }

  return fieldI18n?.[entity]?.[field] || field;
};

const buildFieldError = (error) => {
  const payload = {
    field: error.field,
    fieldI18n: getFieldI18n(error.entity, error.field),
  };

  const isSpecificErrorTranslation = entitySpecificFieldErrorTranslations.includes(`${error.entity}:${error.error_code}:${error.field}`);

  const slug = isSpecificErrorTranslation
    ? `missing_content:error:field:${error.entity}:${error.error_code}`
    : `missing_content:error:field:${error.error_code}`;

  return {
    slug,
    payload,
  };
};

const buildEntityError = (error) => {
  let slug = `missing_content:error:entity:${error.entity}`;
  const payload = {};

  if (error.relation) {
    slug = `${slug}:relation:${error.relation}`;
  }

  if (error.error_code === "invalid_count") {
    slug = typeof error.min_count !== "undefined"
      ? `${slug}:${error.error_code}:min_count`
      : `${slug}:${error.error_code}:max_count`;

    payload.count = error.min_count ?? error.max_count;
  } else {
    slug = `${slug}:${error.error_code}`;
  }

  return {
    slug,
    payload,
  };
};

export const buildError = (error) => {
  if (error.type === "field") {
    return buildFieldError(error);
  }

  if (error.type === "entity") {
    return buildEntityError(error);
  }

  throw new Error(`unknown error type: ${error.type}`);
};
