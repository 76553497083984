import { useMemo } from "react";

export const CHILDREN_VALUE_MODE = "CHILDREN";
export const TREE_VALUE_MODE = "TREE";

function _propagateUpwards(node) {
  while (node.parent) {
    const oldState = {
      selected: node.parent.selected,
      indeterminate: node.parent.indeterminate,
    };

    let allChildrenSelected = true;
    let anyChildrenSelected = false;
    let anyChildrenIndeterminate = false;

    node.parent.children.forEach((child) => {
      if (!child.disabled && !child.selected) {
        allChildrenSelected = false;
      }

      if (!child.disabled && child.selected) {
        anyChildrenSelected = true;
      }

      if (!child.disabled && child.indeterminate) {
        anyChildrenIndeterminate = true;
      }
    });

    node.parent.selected = allChildrenSelected;
    node.parent.indeterminate = !allChildrenSelected && (anyChildrenSelected || anyChildrenIndeterminate);

    if (oldState.selected === node.parent.selected && oldState.indeterminate === node.parent.indeterminate) {
      break; // State didn't change, no need to go further up
    }

    node = node.parent;
  }
}

function _updateParentNodeStates(node) {
  if (!node.children || node.children.size === 0) {
    // It's a leaf node
    return;
  }

  let allChildrenSelected = true;
  let allChildrenDisabled = true;
  let anyChildrenSelected = false;
  let anyChildrenIndeterminate = false;

  node.children.forEach((child) => {
    _updateParentNodeStates(child); // Update child node first

    if (!child.disabled && !child.selected) {
      allChildrenSelected = false;
    }

    if (!child.disabled && child.indeterminate) {
      anyChildrenIndeterminate = true;
    }

    if (!child.disabled && child.selected) {
      anyChildrenSelected = true;
    }

    if (!child.disabled) {
      allChildrenDisabled = false;
    }
  });

  node.disabled = allChildrenDisabled;
  node.selected = allChildrenSelected;
  node.indeterminate = !allChildrenSelected && (anyChildrenSelected || anyChildrenIndeterminate);
}

const flattenValue = (value) => {
  return value.reduce((acc, node) => {
    acc.push(node.id);

    if (node.children) {
      acc.push(...flattenValue(node.children));
    }

    return acc;
  }, []);
};

const _calcTreeState = (tree, value, disabled, valueMode) => {
  const selected = valueMode === CHILDREN_VALUE_MODE
    ? value
    : flattenValue(value);

  const _transformNode = ({
    node,
    parent = null,
    level = 0,
  }) => {
    node.parent = parent;
    node.level = level;
    node.disabled = disabled.includes(node.id);

    if (!node.children || node.children?.length === 0) {
      // It's a leaf node
      node.selected = selected.includes(node.id);
      node.indeterminate = false;
      node.children = new Map();
    } else {
      node.children = node.children.reduce((acc, child) => {
        acc.set(
          child.id,
          _transformNode({
            node: child,
            parent: node,
            level: level + 1,
          }),
        );

        return acc;
      }, new Map());
    }

    return node;
  };

  const treeState = JSON.parse(JSON.stringify(tree))
    .reduce((acc, node) => {
      acc.set(node.id, _transformNode({ node, parent: null, level: 0 }));
      _updateParentNodeStates(acc.get(node.id));

      return acc;
    }, new Map());

  return treeState;
};

const _calcAllState = (treeState) => {
  if (treeState.size === 0) {
    return false;
  }

  let someIndeterminate = false;
  let someSelected = false;
  let selected = true;
  let disabled = true;

  for (const node of treeState.values()) {
    if (!node.disabled) {
      disabled = false;
    }

    if (node.disabled) {
      continue;
    }

    if (node.indeterminate) {
      someIndeterminate = true;
    }

    if (node.selected) {
      someSelected = true;
    }

    if (!node.selected) {
      selected = false;
    }
  }

  const indeterminate = !selected && (someIndeterminate || someSelected);

  return {
    selected,
    indeterminate,
    disabled,
  };
};

const _buildLookupTable = (treeState) => {
  const lookupTable = new Map();

  const traverse = (node) => {
    lookupTable.set(node.id, node);

    node.children.forEach((child) => {
      traverse(child);
    });
  };

  treeState.forEach((node) => {
    traverse(node);
  });

  return lookupTable;
};

export function filterLookupTable({ lookupTable, search }) {
  if (search === null || search === "") {
    return lookupTable;
  }

  const filteredMap = new Map();

  const isNodeMatches = (node) => node.title.toLowerCase().includes(search);
  const isBranchContainsMatch = (node) => {
    if (!node.children || node.children.size === 0) {
      return isNodeMatches(node);
    }

    let haveMatch = false;
    for (const [_key, child] of node.children) {
      if (isBranchContainsMatch(child)) {
        haveMatch = true;
        break;
      }
    }

    return haveMatch;
  };

  const filterMap = (key, node) => {
    if (!node.children || node.children.size === 0) {
      if (isNodeMatches(node)) {
        filteredMap.set(key, node);
      }
      return;
    }

    if (isBranchContainsMatch(node)) {
      filteredMap.set(key, node);
    }

    for (const [childKey, childNode] of node.children) {
      filterMap(childKey, childNode);
    }
  };

  for (const [key, node] of lookupTable) {
    filterMap(key, node);
  }

  return filteredMap;
}

export function useCalcSelectorState({ value, disabled, tree, valueMode = CHILDREN_VALUE_MODE }) {
  const treeState = useMemo(() => _calcTreeState(tree, value, disabled, valueMode), [tree, value, disabled, valueMode]);
  const lookupTable = _buildLookupTable(treeState);
  const allState = _calcAllState(treeState);

  function _getChildrenSelectedIds() {
    const selected = [];

    lookupTable.forEach((node) => {
      // skip parents
      if (node.children.size !== 0) {
        return;
      }

      if (node.selected) {
        selected.push(node.id);
      }
    });

    return selected;
  }

  function _getTreeSelectedIds() {
    const getSelectedOnly = (level, nodes) => {
      return nodes.map((node) => {
        if (node.level === level && (node.selected || node.indeterminate)) {
          const selectedNode = {
            id: node.id,
          };

          const selectedChildren = getSelectedOnly(level + 1, [...node.children.values()]);

          if (selectedChildren.length !== 0) {
            selectedNode.children = selectedChildren;
          }

          return selectedNode;
        }

        return false;
      }).filter(Boolean);
    };

    return getSelectedOnly(0, [...lookupTable.values()]);
  }

  function _getSelectedIds() {
    if (valueMode === CHILDREN_VALUE_MODE) {
      return _getChildrenSelectedIds();
    }

    return _getTreeSelectedIds();
  }

  function _setChildren(nodeId, checked) {
    const node = lookupTable.get(nodeId);

    node.selected = checked;

    if (node.disabled) {
      return;
    }

    node.children.forEach((child) => {
      _setChildren(child.id, checked);
    });
  }

  function toggleAll(isAllChecked) {
    lookupTable.forEach((node) => {
      // skip disabled leafs
      if (node.disabled) {
        return;
      }

      node.selected = isAllChecked;
    });

    return _getSelectedIds();
  }

  function toggleNode(nodeId, checked) {
    const node = lookupTable.get(nodeId);
    if (node.disabled) {
      return; // No-op if the node is disabled
    }

    // Toggle the selected state for leaf nodes, or set all children for parent nodes
    if (node.children.size === 0) {
      node.selected = checked;
    } else {
      _setChildren(node.id, checked);
    }

    // Propagate change upwards
    _propagateUpwards(node);

    return _getSelectedIds();
  }

  return {
    toggleAll,
    toggleNode,
    lookupTable,
    allSelected: allState.selected,
    allIndeterminate: allState.indeterminate,
    allDisabled: allState.disabled,
  };
}
