import { WEBHOOK_LOGS_LOAD, WEBHOOKS_ADD, WEBHOOKS_DROP, WEBHOOKS_LOAD } from "../constants";

export function webhooksLoad(storage) {
  return function (records, meta) {
    storage.dispatch({ type: WEBHOOKS_LOAD, payload: { records, meta } });
  };
}

export function webhooksAdd(storage) {
  return function (payload) {
    storage.dispatch({ type: WEBHOOKS_ADD, payload });
  };
}

export function webhooksDrop(storage) {
  return function (payload) {
    storage.dispatch({ type: WEBHOOKS_DROP, payload });
  };
}

export function webhookLogsLoad(storage) {
  return function (records, meta) {
    storage.dispatch({ type: WEBHOOK_LOGS_LOAD, payload: { records, meta } });
  };
}
