import { useMutation, useQueryClient } from "react-query";
import store from "store";

const { ExtraCategories } = store;

export function useUpdateAction(propertyId) {
  const queryClient = useQueryClient();

  return useMutation(({ id, attrs }) => ExtraCategories.update(id, attrs), {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["extras", propertyId],
      });
    },
  });
}
