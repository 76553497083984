import React, { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Media from "react-media";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import store from "store";
import { getGroupsList } from "store/storage/selectors/groups_selector";
import {
  getAppMode,
  getDisableUserManagement,
  getEnableUserManagement,
} from "store/storage/selectors/session_selector";

import APP_MODES from "config/constants/app_modes";

import CRUDTable from "components/crud_table";
import * as Page from "components/page";
import UsersBar from "components/users_bar/users_bar";

import { pathBuilder } from "routing";
import useRouting from "routing/use_routing";

import alphabetSort from "utils/alphabet_sort";
import handleActionError from "utils/handle_action_error";

import ExtendedGroup from "./expanded_group";
import GroupsEntryMenu from "./groups_entry_menu";

import generalStyles from "styles/general.module.css";

const { Groups } = store;

const SEARCH_FIELD = "propertyTitles";

const DEFAULT_ORDER = { title: "asc" };

export default function GroupsPage() {
  const [searchQuery, setSearchQuery] = useState("");
  const [columnsToHide, setColumnsToHide] = useState(0);
  const groupsList = useSelector(getGroupsList);
  const disableUserManagement = useSelector(getDisableUserManagement);
  const enableUserManagement = useSelector(getEnableUserManagement);
  const appMode = useSelector(getAppMode);
  const isEmbeddedMode = appMode === APP_MODES.HEADLESS;
  const { t } = useTranslation();
  const tableRef = useRef(null);
  const { userAppRoutes } = useRouting();

  const createGroupPath = pathBuilder(userAppRoutes.groups.create);
  const basePath = pathBuilder(userAppRoutes.groups);
  const closePath = `${basePath}?searchQuery=${searchQuery}`;

  const reloadTable = useCallback(() => {
    return tableRef?.current?.reloadTable();
  }, [tableRef]);

  const handleMediaChange = useCallback(
    (value) => (matches) => {
      if (!matches) {
        return;
      }

      setColumnsToHide(value);
    },
    [],
  );

  const handleDataLoad = useCallback((query, pagination, order) => {
    const filter = { title: { has: query } };

    return Groups.list(filter, pagination, order);
  }, []);

  const handleGroupDelete = useCallback(
    (id) => {
      return Groups.remove({ id }).then(reloadTable).catch(handleActionError);
    },
    [reloadTable],
  );

  const getExpandedRow = useCallback(() => function (parent) {
    return <ExtendedGroup parentGroup={parent} />;
  }, []);

  const columns = useCallback(() => {
    let dataColumns = [
      {
        title: t("groups_page:columns:title"),
        dataIndex: "title",
        key: "title",
        sorter: alphabetSort("title"),
        width: 400,
      },
      {
        title: t("groups_page:columns:count"),
        width: 200,
        render: (_text, record) => {
          return Object.keys(record.properties).length;
        },
      },
      {
        title: t("general:users"),
        dataIndex: "users",
        key: "users",
        className: generalStyles.narrowTableCell,
        render: (_data, record) => {
          const users = Object.values(record.users);
          return <UsersBar users={users} />;
        },
      },
    ];

    if (disableUserManagement || (isEmbeddedMode && enableUserManagement !== true)) {
      dataColumns = dataColumns.filter((el) => el.key !== "users");
    }

    const actionColumns = [
      {
        title: t("properties_page:columns:actions"),
        key: "action",
        align: "right",
        render: (_data, record) => (
          <GroupsEntryMenu record={record} onDelete={handleGroupDelete} />
        ),
      },
    ];

    dataColumns = dataColumns.slice(0, dataColumns.length - columnsToHide);

    return [...dataColumns, ...actionColumns];
  }, [
    columnsToHide,
    t,
    handleGroupDelete,
    disableUserManagement,
    isEmbeddedMode,
    enableUserManagement,
  ]);

  return (
    <Page.Main>
      <Media query="(max-width: 399px)" onChange={handleMediaChange(1)} />
      <Media query="(min-width: 400px)" onChange={handleMediaChange(0)} />

      <CRUDTable
        data={groupsList}
        componentRef={tableRef}
        columns={columns}
        defaultOrder={DEFAULT_ORDER}
        emptyMessage={t("properties_page:empty_message")}
        createLink={createGroupPath}
        searchField={SEARCH_FIELD}
        loadData={handleDataLoad}
        onTableQueryChange={setSearchQuery}
        expandedMethod={getExpandedRow}
      />
      <Outlet context={{ closePath }} />
    </Page.Main>
  );
}
