import { EMAIL_TEMPLATES_ADD, EMAIL_TEMPLATES_DROP, EMAIL_TEMPLATES_LOAD } from "../constants";

export function emailTemplatesLoad(storage) {
  return function (emailTemplates) {
    storage.dispatch({ type: EMAIL_TEMPLATES_LOAD, payload: emailTemplates });
  };
}

export function emailTemplatesAdd(storage) {
  return function (emailTemplate) {
    storage.dispatch({ type: EMAIL_TEMPLATES_ADD, payload: emailTemplate });
  };
}

export function emailTemplatesDrop(storage) {
  return function (emailTemplate) {
    storage.dispatch({ type: EMAIL_TEMPLATES_DROP, payload: emailTemplate });
  };
}
