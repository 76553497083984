import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Form } from "antd";
import * as yup from "yup";

import errorMessages from "config/constants/errors";
import SUPPORTED_LANGUAGES from "config/constants/supported_languages";

import ChannexForm from "components/channex_form";
import SubmitButton from "components/forms/buttons/submit_button";
import FormikFormInput from "components/forms/inputs/formik/form_input";
import FormikFormSelect from "components/forms/inputs/formik/form_select";

import styles from "./user_profile_form_view.module.css";

class UserProfileFormView extends Component {
  VALIDATION_SCHEMA = yup.object().shape({
    name: yup.string().required(errorMessages.required()),
  });

  render() {
    const {
      t,
      value,
      errors,
      componentRef,
      loading,
      onChange,
      onSubmit,
    } = this.props;

    const formValue = { ...value, loading };

    return (
      <main className={styles.profileSection}>
        <div className={styles.header}>
          <p className={styles.title}>{t("user_profile_page:general:title")}</p>
        </div>
        <ChannexForm
          value={formValue}
          errors={errors}
          componentRef={componentRef}
          validationSchema={this.VALIDATION_SCHEMA}
          onChange={onChange}
          className={styles.form}
        >
          {({ handlesubmit }) => (
            <Form data-cy="user_profile_settings" className={styles.form} onFinish={handlesubmit}>
              <FormikFormInput
                name="name"
                placeholder={t("user_profile_page:username")}
                label={t("user_profile_page:username")}
              />
              <FormikFormSelect
                name="preferred_language"
                placeholder={t("user_profile_page:preferred_language")}
                label={t("user_profile_page:preferred_language")}
                options={SUPPORTED_LANGUAGES.map((lang) => ({ value: lang.key, representation: lang.value }))}
              />
              <div className={styles.buttonContainer}>
                <SubmitButton loading={loading} onClick={onSubmit}>
                  {t("general:action:save")}
                </SubmitButton>
              </div>
            </Form>
          )}
        </ChannexForm>
      </main>
    );
  }
}

export default withTranslation()(UserProfileFormView);
