import convertToHashmap from "utils/convert_to_hashmap";

import { USER_API_KEY_SET, USER_API_KEYS_LOAD } from "../constants";

const formatApiKey = (key) => {
  const cutKey = key.substr(0, 9);
  return cutKey.replace(/(.{0,3})./g, "*") + cutKey.substr(3, 9);
};

const initialState = {
  entities: null,
  meta: null,
};

const ACTION_HANDLERS = {
  [USER_API_KEYS_LOAD]: (state, action) => {
    const { keys, meta } = action.payload;

    return {
      ...state,
      entities: convertToHashmap(keys),
      meta,
    };
  },
  [USER_API_KEY_SET]: (state, action) => {
    const data = action.payload;
    const currentTotal = state.meta.total;
    const total = state.entities[data.id] ? currentTotal : currentTotal + 1;

    const newEntities = {
      ...state.entities,
      [data.id]: {
        ...data,
        key: formatApiKey(data.key),
      },
    };

    return {
      ...state,
      entities: newEntities,
      meta: {
        ...state.meta,
        total,
      },
    };
  },
};

export default function liveFeedEventsReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
