import RATE_ACTION_TYPES from "config/constants/rate_action_types";

const { SET, INCREASE, DECREASE } = RATE_ACTION_TYPES;

export default (t) => {
  return [
    {
      value: SET,
      label: t("inventory_page:inventory_value_override_controller:set_option_type"),
    },
    {
      value: INCREASE,
      label: "+",
    },
    { value: DECREASE, label: "-" },
  ];
};
