import React from "react";
import { useTranslation } from "react-i18next";

import IssueButton from "./issue_button";
import IssueDropdown from "./issue_dropdown";

import styles from "./booking_issues.module.css";

export default function BookingIssuesMessage({ issues, onIssueClick }) {
  const { t } = useTranslation();

  const action = issues.length > 1 ? (
    <IssueDropdown issues={issues} onIssueClick={onIssueClick} />
  ) : (
    <IssueButton issue={issues[0]} onIssueClick={onIssueClick} />
  );

  return (
    <div className={styles.alertMessage}>
      <span>{t("bookings_page:booking_view_dialog:info:issue_message")}</span>
      {action}
    </div>
  );
}
