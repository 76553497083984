import { useQuery } from "react-query";
import store from "store";

const { AvailabilityRules } = store;

export function useAvailabilityRuleOptions(propertyId) {
  const filter = {
    propertyId,
  };

  return useQuery({
    queryKey: ["availability_rules", propertyId, "options"],
    queryFn: () => AvailabilityRules.all(filter),
    enabled: !!propertyId,
    retry: false,
  });
}
