import { convertToCamelCase, convertToSnakeCase } from "store/utils/case_converter";

let transport;

const ENDPOINT = "attachments";

export default class Attachments {
  constructor(container) {
    transport = container.transport;
  }

  upload(attachment, { signal }) {
    const body = {
      attachment: convertToSnakeCase(attachment),
    };

    return transport
      .send("POST", ENDPOINT, body, { signal })
      .then(convertToCamelCase)
      .then(({ data }) => data);
  }
}
