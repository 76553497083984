import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import ChannexDrawerRoutable from "drawers/channex_drawer_routable";

import BookingView from "components/booking_view";

const withMatch = (WrappedComponent) => {
  function WithMatch(props) {
    const params = useParams();

    return <WrappedComponent {...props} params={params} />;
  }

  return WithMatch;
};

class BookingViewDrawer extends Component {
  state = {
    booking: {},
  };

  onLoad = (booking) => this.setState({ booking });

  render() {
    const { t, params } = this.props;
    const { booking: { unique_id } } = this.state;

    const { bookingId } = params;

    const title = unique_id
      ? `${t("bookings_page:booking_view_dialog:header_loaded")} ${unique_id}`
      : t("bookings_page:booking_view_dialog:header_loading");

    return (
      <ChannexDrawerRoutable title={title}>
        {() => <BookingView bookingId={bookingId} onLoad={this.onLoad} />}
      </ChannexDrawerRoutable>
    );
  }
}

export default withMatch(withTranslation()(BookingViewDrawer));
