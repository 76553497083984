import React, { useEffect, useMemo, useRef } from "react";
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from "react-virtualized";

import { useWindowSize } from "hooks/use_window_size/use_window_size";

const DEFAULT_BASE_ITEM_SIZE = 32;

export const VirtualList = ({ items, renderItem, itemSize = DEFAULT_BASE_ITEM_SIZE }) => {
  const windowSize = useWindowSize();
  const listRef = useRef();

  const cache = useMemo(() => new CellMeasurerCache({
    defaultHeight: itemSize,
    fixedWidth: true,
    minHeight: itemSize,
  }), [itemSize]);

  useEffect(() => {
    cache.clearAll();
    listRef.current?.forceUpdateGrid();
  }, [items, windowSize, listRef, cache]);

  const rowRenderer = ({ key, index, parent, style }) => {
    const item = items[index];

    return (
      <CellMeasurer cache={cache} columnIndex={0} key={key} parent={parent} rowIndex={index}>
        {({ registerChild }) => (
          <div ref={registerChild} style={style}>
            {renderItem(item)}
          </div>
        )}
      </CellMeasurer>
    );
  };

  return (
    <AutoSizer>
      {({ height, width }) => (
        <List
          height={height}
          width={width}
          rowCount={items.length}
          ref={listRef}
          deferredMeasurementCache={cache}
          rowHeight={cache.rowHeight}
          rowRenderer={rowRenderer}
        />
      )}
    </AutoSizer>
  );
};
