import _ from "lodash";

export default function buildNamedRoutes(topLevelRoutes) {
  const parentPath = [];
  const routeName = [];
  const routeTree = {};

  const buildPath = (routes) => {
    return routes.forEach((route) => {
      let currentRoute;

      if (route.index) {
        currentRoute = parentPath.join("/");
      } else if (route.path) {
        parentPath.push(route.path);
        currentRoute = route.path;
      }

      if (route.name) {
        routeName.push(route.name);

        const currentRouteName = routeName.join(".");

        _.set(routeTree, currentRouteName, {
          _path: currentRoute,
        });
      }

      if (route.children) {
        buildPath(route.children);
      }

      parentPath.pop();
      routeName.pop();
    });
  };

  buildPath(topLevelRoutes);

  return routeTree;
}
