import React from "react";
import { Tooltip } from "antd";
import classNames from "classnames";

import styles from "./styles.module.css";

export function CellTooltip({ title, color }) {
  const className = classNames(styles.bottomLine, styles[`bottomLine--${color}`]);

  return (
    <Tooltip title={title}>
      <div className={className} />
    </Tooltip>
  );
}
