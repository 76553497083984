import React from "react";

import { WhiteLabelEmailSettingsForm } from "../../wl_email_settings_form/wl_email_settings_form";

import { useCreateAction } from "./use_create_action";

export function CreateWhiteLabelEmailSettingsForm({ wlPartnerId, onFinish }) {
  const { mutateAsync, isLoading } = useCreateAction(wlPartnerId);

  const handleSubmit = async (values) => {
    const result = await mutateAsync({ attrs: values });
    onFinish(result);
  };

  return (
    <WhiteLabelEmailSettingsForm
      isLoading={isLoading}
      onSubmit={handleSubmit}
    />
  );
}
