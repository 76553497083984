import { withTranslation } from "react-i18next";
import { withFormik } from "formik";
import queryString from "query-string";
import { compose } from "recompose";
import store from "store";

import withRouter from "routing/with_router";

import FormComponent from "./set_password_form_component";

const { Auth } = store;

const withFormContainer = withFormik({
  validateOnBlur: false,
  validateOnChange: false,
  mapPropsToValues: () => ({
    password: "",
    password_confirmation: "",
  }),
  handleSubmit: (values, { props, setErrors, setSubmitting }) => {
    const { t, navigate } = props;

    const query = queryString.parse(window.location.search);

    const { token: invitation_token } = query;
    const data = {
      ...values,
      invitation_token,
    };

    Auth.confirmInvite(data)
      .then(() => {
        navigate("/", {
          state: {
            notification: t("account_confirmation:success"),
          },
        });
      })
      .catch((error) => {
        if (!error.isValidationError) {
          throw error;
        }

        setErrors(error.body.errors.details);
        setSubmitting(false);
      });
  },
});

const enhance = compose(withTranslation(), withRouter, withFormContainer);

export default enhance(FormComponent);
