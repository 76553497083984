import React from "react";
import { useTranslation } from "react-i18next";
import { LockOutlined } from "@ant-design/icons";

import FormInput from "components/forms/inputs/formik/form_input";

function PasswordInput(props) {
  const { t } = useTranslation();
  return (
    <FormInput
      className="form-input__password"
      view="vertical"
      type="password"
      name="password"
      icon={<LockOutlined />}
      placeholder={t("public_pages:password_field_placeholder")}
      {...props}
    />
  );
}

export default PasswordInput;
