import React, { useMemo, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { Form, Select as SelectAntd, Spin } from "antd";
import debounce from "lodash/debounce";

import getFieldLayout from "./utils/get_field_layout";

const FormItem = Form.Item;

export default function Select({ name, label, value, optionsLoader, control }) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (searchTerm) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      optionsLoader(searchTerm).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, 500);
  }, [optionsLoader]);

  const formItemLayout = getFieldLayout();

  return (
    <FormItem
      {...formItemLayout}
      label={label}
    >
      <Controller
        name={name}
        control={control}
        defaultValue={value}
        render={({ field }) => (
          <SelectAntd
            showSearch
            filterOption={false}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            options={options}
            onSearch={debounceFetcher}

            {...field}
          />
        )}
      />
    </FormItem>
  );
}
