import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Form } from "antd";

import SubmitButton from "components/forms/buttons/submit_button";
import FormImageUploader from "components/forms/inputs/form_image_uploader";
import FormSelect from "components/forms/inputs/form_select";
import FormTextarea from "components/forms/inputs/form_textarea";

import PropertyPhotos from "./property_photos";

import elementStyle from "./content_form.module.css";
import styles from "styles/form_in_drawer.module.css";

class ContentForm extends Component {
  inputRefs = {
    description: React.createRef(),
    facilities: React.createRef(),
    important_information: React.createRef(),
  };

  componentDidUpdate(prevProps) {
    const { focusField } = this.props;

    if (focusField && (prevProps.focusField !== focusField)) {
      this.inputRefs?.[focusField]?.current?.focus();
    }
  }

  componentDidMount() {
    const { focusField } = this.props;

    if (focusField) {
      setTimeout(() => { this.inputRefs?.[focusField]?.current?.focus(); }, 0);
    }
  }

  handleContentChange = (field, fieldValue) => {
    const { value, onChange } = this.props;
    const { content: oldContent } = value;
    const content = { ...oldContent, [field]: fieldValue };

    onChange(content, "content");
  };

  render() {
    const { t, loading, value, errors, facilitiesOptions, onSubmit, onChange } = this.props;
    const { content = {}, logo_url } = value;
    const { content: contentErrors } = errors;

    return (
      <Form onFinish={onSubmit}>
        <FormImageUploader
          name="logo_url"
          label={t("properties_page:content:logo")}
          value={logo_url}
          className={elementStyle.logoInput}
          onChange={onChange}
        />
        <FormTextarea
          errors={contentErrors ? contentErrors.description : null}
          label={t("properties_page:content:description_label")}
          placeholder={t("properties_page:content:description_placeholder")}
          name="description"
          value={content.description}
          inputRef={this.inputRefs.description}
          onChange={this.handleContentChange}
        />
        <FormSelect
          view="horizontal"
          mode="multiple"
          allowClear
          name="facilities"
          placeholder={t("properties_page:form:facilities_placeholder")}
          onChange={onChange}
          value={value.facilities}
          label={t("properties_page:form:facilities_label")}
          errors={errors ? errors.facilities : null}
          options={facilitiesOptions}
          isGroup
          inputRef={this.inputRefs.facilities}
        />
        <FormTextarea
          errors={contentErrors ? contentErrors.important_information : null}
          label={t("properties_page:content:important_information_label")}
          placeholder={t("properties_page:content:important_information_placeholder")}
          name="important_information"
          value={content.important_information}
          inputRef={this.inputRefs.important_information}
          onChange={this.handleContentChange}
        />
        <PropertyPhotos name="photos" value={content.photos} onChange={this.handleContentChange} />
        <div className={styles.actions}>
          <SubmitButton loading={loading}>{t("properties_page:submit_button")}</SubmitButton>
        </div>
      </Form>
    );
  }
}

export default withTranslation()(ContentForm);
