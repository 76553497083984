import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Col, Row } from "antd";

import styles from "./mapping_direction.module.css";

class MappingDirection extends Component {
  render() {
    const { t, channelTitle, reverse } = this.props;
    const values = [t("channels_page:form:channel_manager"), channelTitle];
    const destinationStyle = channelTitle === "Booking.com" ? styles.bookingComDestination : null;

    if (reverse) {
      values.reverse();
    }

    return (
      <Row className={styles.row}>
        <Col span={11}>{values[0]}</Col>
        <Col span={1}>&rarr;</Col>
        <Col span={12} className={destinationStyle}>
          {values[1]}
        </Col>
      </Row>
    );
  }
}

export default withTranslation()(MappingDirection);
