import React from "react";
import { useTranslation } from "react-i18next";

import ChannexDrawer from "drawers/channex_drawer";

import DrawerFormWrapper from "components/drawer_form_wrapper";

import HotelPolicyWizard from "./hotel_policy_wizard";

export default function HotelPolicyDrawer({ visible, propertyId, currency, onClose, onCreate }) {
  const { t } = useTranslation();

  return (
    <ChannexDrawer
      dataCy="hotel_policy_drawer"
      title={t("policies:dict:property_policy")}
      visible={visible}
      onClose={onClose}
    >
      {({ handleCloseFromContent, componentRef }) => (
        <DrawerFormWrapper withActions>
          <HotelPolicyWizard
            propertyId={propertyId}
            currency={currency}
            componentRef={componentRef}
            onCreate={onCreate}
            onClose={handleCloseFromContent}
          />
        </DrawerFormWrapper>
      )}
    </ChannexDrawer>
  );
}
