export function transformMappingsToArray(mappings) {
  const mappingArray = [];

  Object.entries(mappings).forEach(([ratePlanId, ratePlanMappings]) => {
    ratePlanMappings = Array.isArray(ratePlanMappings) ? ratePlanMappings : [ratePlanMappings];

    ratePlanMappings.forEach((ratePlanMapping) => {
      const { id: _id, ...mapping } = ratePlanMapping;

      mappingArray.push({
        ratePlanId,
        settings: mapping,
      });
    });
  });

  return mappingArray;
}
