import React from "react";
import { useSelector } from "react-redux";
import { getActivePropertyId } from "store/storage/selectors/session_selector";

import CopyToClipboard from "components/forms/buttons/copy_to_clipboard/copy_to_clipboard";

export default function PropertyID() {
  const propertyId = useSelector(getActivePropertyId);

  return (
    <div>
      Property ID:
      <pre>
        {propertyId}
        <CopyToClipboard text={propertyId} />
      </pre>
    </div>
  );
}
