import { createSelector } from "reselect";

import getEnabledApplications from "utils/get_enabled_applications";

export const getInstalledApps = (state) => state.applications.installed;

export const getInstalledAppsList = createSelector(getInstalledApps, (installedApps) => {
  return installedApps ? Object.values(installedApps) : [];
});

export const getInstalledAppsByPropertyId = (propertyId) => createSelector(getInstalledAppsList, (installedAppsList) => {
  return installedAppsList.filter((app) => app.propertyId === propertyId);
});

export const getInstalledAppsByPropertyIdAndCode = (propertyId, code) => {
  return createSelector(getInstalledAppsList, (installedAppsList) => {
    return installedAppsList
      .filter((app) => app.propertyId === propertyId && app.applicationCode === code)
      .at(0);
  });
};

export const getIsAppInstalled = (filters) => createSelector(getInstalledAppsList, (installedAppsList) => {
  const enabledApplications = getEnabledApplications(installedAppsList, filters);

  return Boolean(enabledApplications.length);
});
