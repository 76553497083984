import React from "react";
import PropTypes from "prop-types";

import FormSelect from "components/forms/inputs/form_select";

function handleChangeCallback(callback, field) {
  return (value) => {
    callback(field, value);
  };
}

function optionsGenerator(values) {
  return values.map((el) => {
    return {
      value: el.id,
      representation: el.title,
    };
  });
}

function InputProperty(props) {
  const { t, model, errors, disabled, properties, loading, onChange } = props;
  const onHandleChange = handleChangeCallback(onChange, "property_id");
  const options = optionsGenerator(properties);

  return (
    <FormSelect
      name="property"
      view="horizontal"
      loading={loading}
      placeholder={t("rates_page:form:property_placeholder")}
      label={t("rates_page:form:property_label")}
      defaultValue={model.property_id}
      value={model.property_id}
      onChange={onHandleChange}
      errors={errors ? errors.property_id : null}
      options={options}
      disabled={Boolean(model.id) || disabled}
    />
  );
}

InputProperty.propTypes = {
  t: PropTypes.func,
  model: PropTypes.object,
  errors: PropTypes.object,
  onChange: PropTypes.func,
  properties: PropTypes.array,
};

export default InputProperty;
