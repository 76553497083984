import React from "react";

import Radio from "components/forms/inputs/formik/radio";

export default function Switch({ t, schema, namespace, field, inputRef, handleFieldChange }) {
  const options = schema.options?.map((option) => ({
    value: option,
    representation: option,
  })) || [];

  return (
    <Radio
      namespace={namespace}
      options={options}
      name={field}
      view="horizontal"
      placeholder={t(`channels_page:form:settings_${field}_placeholder`)}
      label={t(`channels_page:form:settings_${field}_label`)}
      onChange={handleFieldChange}
      inputRef={inputRef}
      type={schema.type}
    />
  );
}
