import React from "react";
import { useTranslation } from "react-i18next";

import ChannexDrawer from "drawers/channex_drawer";

import DrawerFormWrapper from "components/drawer_form_wrapper";

import { EditAvailabilityRuleForm } from "./edit_form/edit_form";

export function EditDrawer({ visible, propertyId, availabilityRule, onClose }) {
  const { t } = useTranslation();
  const title = t("availability_rules:titles:edit");

  return (
    <ChannexDrawer title={title} visible={visible} onClose={onClose}>
      <DrawerFormWrapper withActions>
        <EditAvailabilityRuleForm propertyId={propertyId} value={availabilityRule} closeDrawer={onClose} />
      </DrawerFormWrapper>
    </ChannexDrawer>
  );
}
