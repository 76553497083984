import { withTranslation } from "react-i18next";
import { withFormik } from "formik";
import { compose } from "recompose";
import store from "store";
import * as yup from "yup";

import errors from "config/constants/errors";

import showErrorMessage from "utils/show_error_message";

import FormComponent from "./sign_in_form_component";

const { Auth } = store;

const getSchema = () => yup.object({
  email: yup.string().email(errors.email()).required(errors.required()),
  password: yup.string().required(errors.required()),
});

const withFormContainer = withFormik({
  validateOnBlur: false,
  validateOnChange: false,
  validationSchema: getSchema,
  mapPropsToValues: () => ({
    email: "",
    password: "",
    trusted_device: false,
  }),
  handleSubmit: (values, { props, setSubmitting }) => {
    const { t, setMFAToken } = props;

    Auth.signIn(values)
      .then((response) => {
        if (response.data.mfa_token) {
          setMFAToken(response.data.mfa_token);
        }
      })
      .catch((error) => {
        if (error.isUnauthenticated) {
          showErrorMessage(t("general:errors:invalid_user_credentials"));
          return;
        }

        throw error;
      })
      .finally(() => {
        setSubmitting(false);
      });
  },
});

const enhance = compose(withTranslation(), withFormContainer);

export default enhance(FormComponent);
