import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { DeleteOutlined, SettingOutlined } from "@ant-design/icons";
import { Button, Card } from "antd";
import classnames from "classnames";

import { pathBuilder } from "routing";
import useRouting from "routing/use_routing";

import useBoolState from "utils/use_bool_state";

import ApplicationsViewItemCover from "./applications_view_item_cover";
import ApplicationsViewModal from "./applications_view_modal";

import styles from "./applications_view_item.module.css";

export default function ApplicationsViewItem({
  item,
  onInstall,
  onUninstall,
  canManageApplications,
}) {
  const navigate = useNavigate();
  const [visible, setVisible, setClosed] = useBoolState(false);
  const { userAppRoutes } = useRouting();

  const openSettings = useCallback((e) => {
    e?.stopPropagation();

    navigate(
      pathBuilder(userAppRoutes.applications.settings, { installationId: item.installationId }),
    );
  }, [item.installationId, navigate, userAppRoutes.applications.settings]);
  const cardClassname = classnames(styles.itemCard, item.installationId ? styles.installed : null);

  const handleAppClick = useCallback(() => {
    if (item.installationId && item.isConfigurable) {
      openSettings();
    } else {
      setVisible();
    }
  }, [item.installationId, item.isConfigurable, openSettings, setVisible]);

  const handleRemoveClick = (e) => {
    e.stopPropagation();
    setVisible();
  };

  return (
    <>
      <Card
        data-cy="app_card"
        className={cardClassname}
        hoverable
        onClick={handleAppClick}
        cover={<ApplicationsViewItemCover item={item} />}
        actions={
          item.installationId && [
            <Button
              key="setting"
              type="link"
              onClick={openSettings}
              disabled={!item.isConfigurable}
            >
              <SettingOutlined />
            </Button>,
            <Button key="remove" onClick={handleRemoveClick} danger type="link">
              <DeleteOutlined />
            </Button>,
          ]
        }
      />
      <ApplicationsViewModal
        visible={visible}
        item={item}
        onInstall={onInstall}
        onUninstall={onUninstall}
        canManageApplications={canManageApplications}
        onClose={setClosed}
      />
    </>
  );
}
