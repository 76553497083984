export default function classifyApiErrors(errors, formFieldNames) {
  const formErrors = {};
  const globalErrors = {};

  Object.entries(errors).forEach(([key, value]) => {
    if (formFieldNames.includes(key)) {
      formErrors[key] = value;
    } else {
      globalErrors[key] = value;
    }
  });

  return { formErrors, globalErrors };
}
