export default (rawMinNightsValue) => {
  const formattedMinNightsValue = rawMinNightsValue.map((value) => {
    if (value === -1) {
      return { isChecked: false, value: null };
    }

    return { isChecked: true, value };
  });

  return formattedMinNightsValue;
};
