import { useSelector } from "react-redux";
import classNames from "classnames";
import { getIsAppInstalled } from "store/storage/selectors/applications_selector";
import { getActivePropertyId, getAppMode } from "store/storage/selectors/session_selector";

import appCodes from "config/constants/app_codes";
import appModes from "config/constants/app_modes";

import Application from "components/application";

import NoAppPlaceholder from "./no_app_placeholder";
import { ReviewApp } from "./review_app";
import { useMobile } from "./use_mobile";

import styles from "./reviews_page.module.css";

const applicationParams = { applicationCode: appCodes.messages };

export const ReviewsPage = () => {
  const isMobile = useMobile();
  const activeProperty = useSelector(getActivePropertyId);
  const appMode = useSelector(getAppMode);
  const appParams = { ...applicationParams };

  if (activeProperty) {
    appParams.propertyId = activeProperty;
  }

  const isInstalled = useSelector(getIsAppInstalled(appParams));
  if (!isInstalled) {
    return <NoAppPlaceholder />;
  }

  const containerClassName = classNames(styles.layout, {
    [styles.layout__headless]: appMode === appModes.HEADLESS,
    [styles.layout__mobile]: isMobile,
  });

  return (
    <Application className={containerClassName}>
      <ReviewApp />
    </Application>
  );
};
