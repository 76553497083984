import drawerStyles from "../../channex_drawer.module.css";

// This is needed to apply styles on global components outside drawer, which were created to be used in drawer

export const DrawerBody = ({ children }) => (
  <div className={[drawerStyles.drawer]} style={{ height: "100%" }}>
    <div className="ant-drawer-body" style={{ margin: "unset", height: "100%" }}>
      {children}
    </div>
  </div>
);
