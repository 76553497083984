import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { getAllowNotificationsEdit, getAppMode, getUser } from "store/storage/selectors/session_selector";

import APP_MODES from "config/constants/app_modes";

import CheckboxField from "./checkbox";
import FileField from "./file";
import InputField from "./input";
import NumberField from "./number";
import PasswordField from "./password";
import SelectField from "./select";
import SlugField from "./slug";
import SwitchField from "./switch";

const DEFAULT_FIELD = InputField;

const FIELDS = {
  boolean: CheckboxField,
  file: FileField,
  number: NumberField,
  password: PasswordField,
  select: SelectField,
  switch: SwitchField,
  slug: SlugField,
};

const EmbeddedModeHiddenFields = ["send_email_notifications", "email"];

const getNewRules = (rules, settings) => {
  if (!rules || !settings) {
    return [];
  }

  return rules.filter((rule) => {
    const isRuleApplied = rule.when === settings[rule.influence_field];

    return isRuleApplied;
  });
};

const getModifiers = (appliedRules) => {
  return appliedRules.filter((rule) => "apply" in rule).map((rule) => rule.apply);
};

const getValueOverrides = (appliedRules) => {
  return appliedRules.filter((rule) => "with_value" in rule).map((rule) => rule.with_value);
};

// TODO - Rework when roles added to field params on backend side
const adminOnlyFields = {
  GoogleHotelARI: ["use_built_in_ibe", "partner_account", "booking_link", "brand_id"],
};

const getIsFieldHidden = (user, field, channel, isEmbeddedMode, allowNotificationsEdit) => {
  const { [channel]: channelRelatedFields } = adminOnlyFields;
  const { system_role: userRole, settings } = user;
  const isFieldRoleRestricted = channelRelatedFields?.includes(field);
  const isAdmin = userRole === "admin";
  const isAdminFieldsEnabled = settings?.allow_edit_admin_fields;

  return (
    (isFieldRoleRestricted && !isAdmin && !isAdminFieldsEnabled)
    || ((isEmbeddedMode && !allowNotificationsEdit) && EmbeddedModeHiddenFields.indexOf(field) >= 0)
  );
};

export default function SettingField(props) {
  const { form, field, settings, schema, namespace } = props;

  const [appliedRules, setAppliedRules] = useState([]);

  const user = useSelector(getUser);
  const appMode = useSelector(getAppMode);

  const { channel } = form.values;
  const { rules, type } = schema;
  const updatedSchema = { ...schema };
  const Field = FIELDS[type] || DEFAULT_FIELD;
  const isEmbeddedMode = appMode === APP_MODES.HEADLESS;

  const handleRulesChanged = (newRules) => {
    const valueOverrides = getValueOverrides(newRules);

    if (!valueOverrides.length) {
      return;
    }

    const [newValue] = valueOverrides;
    const currentValue = _.get(form.values, `${namespace}.${field}`);

    if (currentValue !== newValue) {
      form.setFieldValue(`${namespace}.${field}`, newValue);
    }
  };

  useEffect(() => {
    const newRules = getNewRules(rules, settings);
    const isRulesChanged = !_.isEqual(newRules, appliedRules);

    if (!isRulesChanged) {
      return;
    }

    setAppliedRules(newRules);
    handleRulesChanged(newRules);
  }, [appliedRules, rules, settings]); // eslint-disable-line react-hooks/exhaustive-deps

  const modifiers = useMemo(() => getModifiers(appliedRules), [appliedRules]);
  const allowNotificationsEdit = useSelector(getAllowNotificationsEdit);
  const isHiddenByRole = getIsFieldHidden(user, field, channel, isEmbeddedMode, allowNotificationsEdit);

  if (modifiers.includes("hidden") || isHiddenByRole) {
    updatedSchema.type = "hidden";
  }

  return <Field {...props} schema={updatedSchema} />;
}
