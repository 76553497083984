import { BOOKINGS_ADD, BOOKINGS_LOAD } from "../constants";

export function bookingsLoad(storage) {
  return function (bookings, meta) {
    storage.dispatch({ type: BOOKINGS_LOAD, payload: { bookings, meta } });
  };
}

export function bookingsAdd(storage) {
  return function (booking) {
    storage.dispatch({ type: BOOKINGS_ADD, payload: booking });
  };
}
