import React, { Component } from "react";
import PropTypes from "prop-types";
import { Cascader, Form } from "antd";

import getFieldLayout from "./utils/get_field_layout";

const FormItem = Form.Item;

class FormCascader extends Component {
  static propTypes = {
    defaultValue: PropTypes.string,
    onChange: PropTypes.func,
    allowClear: PropTypes.bool,
  };

  static validation(errors) {
    if (errors) {
      return {
        validateStatus: "error",
        help: errors.join(" "),
      };
    }
    return {};
  }

  static flatValues(prefix, values) {
    return values.reduce((acc, el) => {
      const key = [...prefix, el.value];

      if (el.children) {
        FormCascader.flatValues(key, el.children).map((child) => acc.push(child));
      } else {
        acc.push(key);
      }
      return acc;
    }, []);
  }

  static getDefaultValue(options, value) {
    const values = FormCascader.flatValues([], options);
    const selected = values.filter((el) => el[el.length - 1] === value);

    return selected || [];
  }

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);

    this.state = {
      value: null,
    };
  }

  componentDidMount() {
    const { defaultValue } = this.props;

    this.setState({
      value: defaultValue,
    });
  }

  handleChange(value) {
    const { onChange } = this.props;
    this.setState({
      value,
    });

    if (onChange && typeof onChange === "function") {
      if (value?.length > 0) {
        onChange(value[value.length - 1]);
      } else {
        onChange(null);
      }
    }
  }

  render() {
    const {
      placeholder,
      inputRef,
      errors,
      label,
      view,
      extra,
      defaultValue,
      options,
      allowClear,
      disabled,
    } = this.props;
    const { value } = this.state;
    const formItemLayout = getFieldLayout(view);

    return (
      <FormItem
        {...formItemLayout}
        {...FormCascader.validation(errors)}
        label={label}
        extra={extra}
        data-cy={`cascader_${this.props.name}`}
      >
        <Cascader
          allowClear={allowClear}
          showSearch
          defaultValue={FormCascader.getDefaultValue(options, defaultValue)}
          placeholder={placeholder}
          onChange={this.handleChange}
          options={options}
          disabled={disabled}
        />
        <input style={{ display: "none" }} ref={inputRef} defaultValue={value} />
      </FormItem>
    );
  }
}

export default FormCascader;
