import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Select } from "antd";
import classNames from "classnames";
import store from "store";
import { getPropertiesOptions } from "store/storage/selectors/properties_selector";
import { getSession } from "store/storage/selectors/session_selector";

import styles from "./property_filter.module.css";

const { Auth } = store;

const { Option } = Select;

const ANIMATION_DELAY = 300;

export default function PropertyFilter({ isMobile }) {
  const { t } = useTranslation();

  const initialClassName = classNames(styles.propertyFilterContainer, {
    [styles.propertyFilterContainerMobile]: isMobile,
  });

  const [activeClass, setActiveClass] = useState(initialClassName);
  const session = useSelector(getSession);
  const { activeProperty, activeGroup } = session;
  const unfilteredPropertiesOptions = useSelector(getPropertiesOptions);

  const propertiesOptions = useMemo(() => {
    let options = [];
    if (unfilteredPropertiesOptions) {
      options = unfilteredPropertiesOptions.filter((property) => (
        activeGroup
          ? property.group_ids.indexOf(activeGroup) !== -1
          : true
      ));
    }
    return options;
  }, [unfilteredPropertiesOptions, activeGroup]);

  const selectedValue = activeProperty || "";
  const isOnlyOnePropertyAvailable = propertiesOptions?.length === 1;

  const handleVisibilityChange = (newVisibility) => {
    const newActiveClass = classNames(styles.propertyFilterContainer, {
      [styles.propertyFilterContainerActive]: newVisibility,
      [styles.propertyFilterContainerMobile]: isMobile,
    });

    if (newVisibility) {
      setActiveClass(newActiveClass);
      return;
    }

    setTimeout(() => setActiveClass(newActiveClass), ANIMATION_DELAY);
  };

  const handleChangeProperty = useCallback(
    (selectedId) => {
      const selectedProperty = propertiesOptions?.find((property) => property.id === selectedId);

      if (!selectedId) {
        Auth.chooseProperty(selectedId);

        return;
      }

      if (selectedProperty) {
        Auth.chooseProperty(selectedId);
      }
    },
    [propertiesOptions],
  );

  useEffect(
    function resetIfSelectedEntityMissing() {
      const isOptionsPresent = propertiesOptions;
      const isValueSelected = activeProperty;

      if (!isOptionsPresent || !isValueSelected || unfilteredPropertiesOptions === null) {
        return;
      }

      const isSelectedPropertyExist = activeProperty && propertiesOptions.find((property) => property.id === activeProperty);

      if (!isSelectedPropertyExist) {
        handleChangeProperty(null);
      }
    },
    [propertiesOptions, activeProperty, unfilteredPropertiesOptions, handleChangeProperty],
  );

  useEffect(
    function forceSelectIfOneProperty() {
      if (unfilteredPropertiesOptions === null) {
        return;
      }

      if (propertiesOptions.length === 0) {
        handleChangeProperty(null);
        return;
      }
      if (!isOnlyOnePropertyAvailable) {
        return;
      }

      handleChangeProperty(propertiesOptions[0].id);
    },
    [
      propertiesOptions,
      isOnlyOnePropertyAvailable,
      unfilteredPropertiesOptions,
      handleChangeProperty,
    ],
  );

  const isLoading = unfilteredPropertiesOptions?.length === 0;
  const isJustSignedUp = unfilteredPropertiesOptions?.length === 0;

  if (isLoading || isJustSignedUp) {
    return null;
  }

  return (
    <div data-cy="top_filter" className={activeClass}>
      <Select
        disabled={isOnlyOnePropertyAvailable}
        className={styles.propertyFilter}
        value={selectedValue}
        onChange={handleChangeProperty}
        showSearch
        filterOption
        onDropdownVisibleChange={handleVisibilityChange}
        optionFilterProp="children"
        allowClear="true"
      >
        <Option value="">{t("general:all_properties")}</Option>
        {propertiesOptions
          && propertiesOptions.map((property) => (
            <Option value={property.id} key={property.id}>
              {property.title}
            </Option>
          ))}
      </Select>
    </div>
  );
}
