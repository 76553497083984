import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "../navigation.module.css";

function NavigationItem({ to, title, onClick }) {
  return (
    <NavLink
      to={to}
      className={({ isActive }) => classNames(styles.appNavigation__Link, {
        [styles.appNavigation__Link__active]: isActive,
      })}
      onClick={onClick}
    >
      {title}
    </NavLink>
  );
}

NavigationItem.propTypes = {
  to: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default NavigationItem;
