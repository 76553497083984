import store from "store";

import { removeAuthTokens } from "../../../auth/tokens";
import { SESSION_ADD, USER_ADD } from "../constants";

export function logout(storage) {
  return function () {
    store.ws.disconnect();

    storage.dispatch({ type: USER_ADD, payload: null });
    storage.dispatch({ type: SESSION_ADD, payload: {} });

    removeAuthTokens();
  };
}
