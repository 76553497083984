import { useMutation, useQueryClient } from "react-query";
import store from "store";

const { AdminWlEmailSettings } = store;

export function useCreateAction(wlPartnerId) {
  const queryClient = useQueryClient();

  return useMutation(({ attrs }) => AdminWlEmailSettings.create(wlPartnerId, attrs), {
    onSuccess: () => {
      queryClient.invalidateQueries("admin_wl_email_settings");
    },
  });
}
