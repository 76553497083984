import React, { Component } from "react";

import currencyOptions from "config/constants/currency_options";

import FormSelect from "./form_select";

class FormCurrencySelect extends Component {
  state = {
    options: [],
  };

  componentDidMount() {
    this.buildCurrencyOptions();
  }

  buildCurrencyOptions = () => {
    const { availableCurrencies } = this.props;

    let options = currencyOptions();

    if (availableCurrencies) {
      options = options.filter((currency) => availableCurrencies.includes(currency.value));
    }

    this.setState({ options });
  };

  render() {
    const { name, placeholder, label } = this.props;
    const { options } = this.state;

    return <FormSelect name={name} placeholder={placeholder} label={label} options={options} />;
  }
}

export default FormCurrencySelect;
