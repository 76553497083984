import React from "react";
import { Modal } from "antd";

export default function ChannexModal(props) {
  const {
    children,
    title,
    visible,
    okText,
    cancelText,
    destroyOnClose = true,
    closable,
    footer,
    width,
    onOk,
    onCancel,
    confirmLoading,
    cancelButtonProps,
    okButtonProps,
    className,
    centered,
  } = props;

  const extendedCancelProps = {
    ...cancelButtonProps,
    "data-cy": "modal_cancel",
  };

  const extendedOkProps = {
    ...okButtonProps,
    "data-cy": "modal_ok",
  };

  return (
    (
      <Modal
        width={width}
        title={title}
        open={visible}
        footer={footer}
        closable={closable}
        confirmLoading={confirmLoading}
        okText={okText}
        cancelText={cancelText}
        okButtonProps={extendedOkProps}
        cancelButtonProps={extendedCancelProps}
        destroyOnClose={destroyOnClose}
        className={className}
        centered={centered}
        onOk={onOk}
        onCancel={onCancel}
      >
        <div data-cy="modal_content">{children}</div>
      </Modal>
    )
  );
}
