import {
  CHANNELS_ACTIVATE,
  CHANNELS_ADD,
  CHANNELS_DEACTIVATE,
  CHANNELS_DROP,
  CHANNELS_LOAD,
} from "../constants";

export function channelsLoad(storage) {
  return function (channels) {
    storage.dispatch({ type: CHANNELS_LOAD, payload: channels });
  };
}

export function channelsAdd(storage) {
  return function (channel) {
    storage.dispatch({ type: CHANNELS_ADD, payload: channel });
  };
}

export function channelsDrop(storage) {
  return function (channel) {
    storage.dispatch({ type: CHANNELS_DROP, payload: channel });
  };
}

export function channelsActivate(storage) {
  return function (id) {
    storage.dispatch({ type: CHANNELS_ACTIVATE, payload: id });
  };
}

export function channelsDeactivate(storage) {
  return function (id) {
    storage.dispatch({ type: CHANNELS_DEACTIVATE, payload: id });
  };
}
