import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Outlet, useMatch, useNavigate } from "react-router-dom";
import { Button, Typography } from "antd";
import { getAppMode, getWhiteLabelFlag } from "store/storage/selectors/session_selector";

import APP_MODES from "config/constants/app_modes";
import WHITE_LABEL_FLAGS from "config/constants/white_label_flags";

import { pathBuilder } from "routing";
import useRouting from "routing/use_routing";

import LocaleSelect from "./locale_select/locale_select";

import BgImage from "static/auth_bg.svg";
import styles from "./layout.module.css";

const { Text } = Typography;

function Layout() {
  const { t } = useTranslation();
  const { publicAppRoutes } = useRouting();
  const navigate = useNavigate();

  const isSignUpPage = useMatch({ path: publicAppRoutes.signUp._path, end: false });
  const isDisableSignUp = useSelector(getWhiteLabelFlag(WHITE_LABEL_FLAGS.DISABLE_SIGN_UP));
  const appMode = useSelector(getAppMode);

  if (appMode === APP_MODES.HEADLESS) {
    return (
      <div className={styles.wrapper}>
        <Outlet />
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.imageWrapper}>
        <img src={BgImage} alt="channex auth background" />
      </div>

      <div className={styles.body}>
        {!isSignUpPage && !isDisableSignUp && (
          <div className={styles.signUpLinkWrapper}>
            <Text className={styles.signUpLinkLabel}>{t("auth_layout:header_link")}</Text>

            <Button
              data-cy="sign_up_button"
              className={styles.signUpLink}
              type="primary"
              size="large"
              onClick={() => navigate(pathBuilder(publicAppRoutes.signUp))}
            >
              {t("sign_up_page:submit_button")}
            </Button>
          </div>
        )}

        <div className={styles.localeSelector}>
          <LocaleSelect />
        </div>

        <img
          data-cy="logo_big"
          className={styles.logo}
          src="/assets/logo_big.svg"
          alt="Logo"
        />
        <div className={styles.inner}>
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Layout;
