import attributesExtractor from "../utils/attributes_extractor";
import { convertToCamelCase, convertToSnakeCase } from "../utils/case_converter";

let transport;

const ENDPOINT = "room_types/:roomTypeId/spaces";

export default class RoomSpaces {
  constructor(container) {
    transport = container.transport;
  }

  list(roomTypeId, filter = {}, pagination = { page: 1, limit: 10 }, order = { inserted_at: "desc" }) {
    const path = ENDPOINT.replace(":roomTypeId", roomTypeId);

    return transport.send("GET", path, { filter, pagination, order })
      .then(convertToCamelCase)
      .then(({ data }) => attributesExtractor(data));
  }

  find(roomTypeId, id) {
    const path = ENDPOINT.replace(":roomTypeId", roomTypeId);

    return transport.send("GET", `${path}/${id}`)
      .then(convertToCamelCase)
      .then(({ data }) => attributesExtractor(data));
  }

  create(roomTypeId, attrs) {
    const path = ENDPOINT.replace(":roomTypeId", roomTypeId);
    const body = convertToSnakeCase({ roomTypeSpace: attrs });

    return transport.send("POST", path, body)
      .then(convertToCamelCase)
      .then(({ data }) => attributesExtractor(data));
  }

  update(roomTypeId, attrs) {
    const path = ENDPOINT.replace(":roomTypeId", roomTypeId);
    const body = convertToSnakeCase({ roomTypeSpace: attrs });

    return transport.send("PUT", `${path}/${attrs.id}`, body)
      .then(convertToCamelCase)
      .then(({ data }) => attributesExtractor(data));
  }

  remove(roomTypeId, id) {
    const path = ENDPOINT.replace(":roomTypeId", roomTypeId);

    return transport.send("DELETE", `${path}/${id}`);
  }
}
