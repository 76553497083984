import React from "react";
import { Alert } from "antd";

import BookingIssuesMessage from "./booking_issues_message";

import styles from "./booking_issues.module.css";

export default function BookingIssues({ issues, onIssueClick }) {
  return (
    <Alert
      className={styles.alert}
      message={<BookingIssuesMessage issues={issues} onIssueClick={onIssueClick} />}
      type="error"
      showIcon
    />
  );
}
