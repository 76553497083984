import alphabetSort from "utils/alphabet_sort";
import { buildMultiOccupancyRatesList } from "utils/build_multioccupancy_rates_list";

const buildRoomTypeOptionsByExternalRoomId = ({ mappingOptions, roomTypes, externalRoomsMapping }) => {
  const mappedRoomTypes = Object.values(externalRoomsMapping);
  const mappedExternalRoomIds = Object.keys(externalRoomsMapping);

  const mappingState = {
    unmapped: roomTypes.filter((rt) => !mappedRoomTypes.includes(rt.id)),
    mapped: mappedExternalRoomIds.reduce((acc, mappedExternalRoomId) => {
      if (externalRoomsMapping[mappedExternalRoomId]) {
        acc[mappedExternalRoomId] = roomTypes.find((rt) => rt.id === externalRoomsMapping[mappedExternalRoomId]);
      }

      return acc;
    }, {}),
  };

  const externalRooms = mappingOptions.rooms;

  return externalRooms.reduce((acc, externalRoom) => {
    const options = [...mappingState.unmapped];

    if (mappingState.mapped[externalRoom.id]) {
      options.push(mappingState.mapped[externalRoom.id]);
    }

    acc[externalRoom.id] = options
      .sort(alphabetSort("title"));

    return acc;
  }, {});
};

const buildRatePlansByRoomTypeId = ({ ratePlans }) => {
  return ratePlans.reduce((acc, ratePlan) => {
    if (Array.isArray(acc[ratePlan.room_type_id])) {
      acc[ratePlan.room_type_id].push(ratePlan);
    } else {
      acc[ratePlan.room_type_id] = [ratePlan];
    }

    return acc;
  }, {});
};

export const buildMappingItems = ({ mappingOptions, unknownMappingOptions, mappingsPerExternalRoom, mappingSettings, roomTypes, ratePlans, t }) => {
  const externalRoomsMapping = mappingSettings.rooms;
  const roomTypeOptionsByExternalRoomId = buildRoomTypeOptionsByExternalRoomId({ mappingOptions, roomTypes, externalRoomsMapping });
  const ratePlanByRoomTypeId = buildRatePlansByRoomTypeId({ ratePlans });

  const invalidItems = (unknownMappingOptions.rooms || [])
    .sort(alphabetSort("title"))
    .map((room) => {
      const { rates } = room;

      const sortedRates = Array.isArray(rates) ? rates.sort(alphabetSort("title")) : [];

      return { ...room, rates: sortedRates };
    })
    .reduce((acc, room) => {
      const roomMappingItem = {
        type: "room",
        isInvalid: room.isInvalid,
        isRemoved: room.isRemoved,
        externalRoom: {
          id: room.id,
          title: room.title,
        },
        roomTypes: [roomTypes.find((rt) => rt.id === externalRoomsMapping[room.id]) || { id: "unknown", title: "unknown" }],
      };

      const roomTypeId = externalRoomsMapping[room.id];
      if (roomTypeId) {
        roomMappingItem.mapping = {
          roomTypeId,
        };
      }

      acc.push(roomMappingItem);

      room.rates.forEach((rate) => {
        const parentRoomId = room.id;

        const rateMappingItem = {
          type: "rate",
          isInvalid: rate.isInvalid,
          isRemoved: rate.isRemoved,
          externalRate: {
            ...rate,
            roomTypeCode: parentRoomId,
            isParentRoomMapped: !!roomTypeId,
          },
          ratePlans: [],
        };

        const isOccBasedMapping = typeof rate.occupancy !== "undefined";

        const rateMapping = isOccBasedMapping
          ? mappingsPerExternalRoom?.[room.id]?.[rate.id]?.[`occ_${rate.occupancy}`]
          : mappingsPerExternalRoom?.[room.id]?.[rate.id];

        rateMappingItem.mapping = rateMapping;
        rateMappingItem.ratePlans = ratePlans.filter((rp) => rp.id === rateMapping.ratePlanId);

        acc.push(rateMappingItem);
      });

      return acc;
    }, []);

  const items = ([...(mappingOptions.rooms || [])])
    .sort(alphabetSort("title"))
    .map((room) => {
      const { rates } = room;

      const sortedRates = Array.isArray(rates) ? rates.sort(alphabetSort("title")) : [];

      return { ...room, rates: sortedRates };
    })
    .reduce((acc, room) => {
      const roomMappingItem = {
        type: "room",
        externalRoom: {
          id: room.id,
          title: room.title,
        },
        roomTypes: roomTypeOptionsByExternalRoomId[room.id],
      };

      const roomTypeId = externalRoomsMapping[room.id];
      if (roomTypeId) {
        roomMappingItem.mapping = {
          roomTypeId,
        };
      }

      acc.push(roomMappingItem);

      const isOccBasedMapping = room.rates.some((rate) => typeof rate.occupancies !== "undefined");
      const roomRates = isOccBasedMapping
        ? buildMultiOccupancyRatesList(room.rates, t)
        : room.rates;

      roomRates.forEach((rate) => {
        const parentRoomId = room.id;

        const rateMappingItem = {
          type: "rate",
          externalRate: {
            ...rate,
            roomTypeCode: parentRoomId,
            isParentRoomMapped: !!roomTypeId,
          },
          ratePlans: [],
        };

        const rateMapping = isOccBasedMapping
          ? mappingsPerExternalRoom?.[room.id]?.[rate.id]?.[`occ_${rate.occupancy}`]
          : mappingsPerExternalRoom?.[room.id]?.[rate.id];

        if (rateMapping) {
          rateMappingItem.mapping = rateMapping;
        }

        const mappedRoomTypeId = externalRoomsMapping[parentRoomId];
        if (mappedRoomTypeId) {
          const mappableRoomRatePlans = ratePlanByRoomTypeId[mappedRoomTypeId] || [];
          rateMappingItem.ratePlans = mappableRoomRatePlans;
        }

        acc.push(rateMappingItem);
      });

      return acc;
    }, []);

  return [
    ...invalidItems,
    ...items,
  ];
};
