import React, { useMemo } from "react";

import ChannexPopover from "components/channex_popover";

import ConflictResolverComponent from "./conflict_resolver_component";
import MappingComponent from "./mapping_component";

const checkMappedRatesConflicts = (mappedRates) => {
  const [firstRate, ...otherRates] = mappedRates;

  const isMappedRatesHasSameProps = otherRates.every((rate) => {
    const isRoomMatches = rate.room_type_id === firstRate.room_type_id;
    const isParentRateMatches = rate.parent_rate_plan_id === firstRate.parent_rate_plan_id;
    const isPropertyMatches = rate.property_id === firstRate.property_id;

    return isRoomMatches && isParentRateMatches && isPropertyMatches;
  });

  return !isMappedRatesHasSameProps;
};

export default function MappingPopover(props) {
  const {
    children,
    visible,
    ratesTree,
    deleteEnabled = true,
    mappingPopupData,
    handleRateChange,
    handleApplyMapping,
    handleClosePopup,
    handlePopupDeleteClick,
    handleApplyConflictResolve,
    handleConflictFormChange,
  } = props;
  const { rate } = mappingPopupData;

  const hasRatesConflict = useMemo(() => {
    return Array.isArray(rate) && checkMappedRatesConflicts(rate);
  }, [rate]);

  const PopoverContentComponent = hasRatesConflict ? ConflictResolverComponent : MappingComponent;

  return (
    <ChannexPopover
      open={visible}
      content={
        <PopoverContentComponent
          ratesTree={ratesTree}
          mappingPopupData={mappingPopupData}
          deleteEnabled={deleteEnabled}
          handleRateChange={handleRateChange}
          handleApplyMapping={handleApplyMapping}
          handleClosePopup={handleClosePopup}
          handlePopupDeleteClick={handlePopupDeleteClick}
          handleApplyConflictResolve={handleApplyConflictResolve}
          handleConflictFormChange={handleConflictFormChange}
        />
      }
    >
      {children}
    </ChannexPopover>
  );
}
