import React, { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { LoadingOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import store from "store";

import defaultPaginationVars from "config/constants/default_pagination_variables";

import ErrorBoundary from "components/error_boundary";
import NoDataPlaceholder from "components/no_data_placeholder/no_data_placeholder";

import useBoolState from "utils/use_bool_state";
import usePagination from "utils/use_pagination";

import ChannelEventsLog from "./channel_events_log";

import styles from "./channel_events.module.css";

const { ChannelEvents } = store;

const PAGE_LIMIT = 13;
const ITEM_HEIGHT = 48;
const DISPLAYED_ITEMS = 10;
const LIST_HEIGHT = ITEM_HEIGHT * DISPLAYED_ITEMS;

export default function ChannelEventsLogsList({ actionId }) {
  const { t } = useTranslation();
  const [loading, setLoading, setLoadingComplete] = useBoolState(false);
  const { handleError } = useContext(ErrorBoundary.Context);
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState(defaultPaginationVars);
  const { nextPage, isLastPage } = usePagination(meta);

  const loadLogs = useCallback(
    async (page) => {
      setLoading();

      const pagination = { ...meta, page, limit: PAGE_LIMIT };

      try {
        const response = await ChannelEvents.loadLogs(actionId, pagination);

        const newData = [...data, ...response.data.logs];
        setData(newData);

        setMeta(response.meta);
      } catch (error) {
        handleError(error);
      }

      setLoadingComplete();
    },
    [data, meta, actionId, handleError, setLoading, setLoadingComplete],
  );

  const loadNext = useCallback(() => {
    loadLogs(nextPage);
  }, [loadLogs, nextPage]);

  const handlePanelToggle = useCallback(
    (activePanels) => {
      const isOpened = activePanels.length;
      const shouldLoadLogs = isOpened && !data.length && !loading;

      if (!shouldLoadLogs) {
        return;
      }

      loadLogs(1);
    },
    [data, loading, loadLogs],
  );

  const listHeight = data.length > DISPLAYED_ITEMS ? LIST_HEIGHT : "auto";

  const listItems = useMemo(() => {
    if (!Array.isArray(data)) {
      return [];
    }

    return data.map((log) => (
      <ChannelEventsLog log={log} key={`${log.data.query_id}_${log.data.finished_at}`} />
    ));
  }, [data]);

  const renderIcon = useCallback(
    () => (
      <span>
        <LoadingOutlined />
      </span>
    ),
    [],
  );

  const expandIcon = loading ? renderIcon : null;
  const isLogsMissing = !loading && !data.length;
  const content = isLogsMissing ? (
    <NoDataPlaceholder emptyMessage={t("general:no_data_placeholder")} />
  ) : (
    <InfiniteScroll
      dataLength={data.length}
      next={loadNext}
      hasMore={!isLastPage}
      height={listHeight}
    >
      {listItems}
    </InfiniteScroll>
  );

  const items = [{
    key: "logs",
    label: t("channel_events_page:channel_events_view_dialog:logs"),
    className: styles.logsContainer,
    children: content,
  }];

  return (
    <Collapse items={items} bordered={false} expandIcon={expandIcon} onChange={handlePanelToggle} />
  );
}
