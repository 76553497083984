import { convertToCamelCase } from "store/utils/case_converter";
import { v4 as uuidv4 } from "uuid";

let transport;
let storage;

const ENDPOINT = "reviews";

export default class Reviews {
  constructor(container) {
    transport = container.transport;
    storage = container.storage;
  }

  list(
    filter = { is_replied: false },
    pagination = {},
    order = {
      inserted_at: "desc",
    },
  ) {
    const requestId = uuidv4();
    storage.reviewsSetRequestId(requestId);

    return transport.send("GET", ENDPOINT, { filter, pagination, order }).then((response) => {
      const data = convertToCamelCase(response.data);
      storage.reviewsLoad(data, response.meta, requestId);
      return response;
    });
  }

  review(id) {
    return transport.send("GET", `${ENDPOINT}/${id}`).then((response) => {
      const data = convertToCamelCase(response.data);
      storage.reviewsAdd(data);
      return data;
    });
  }

  reply(id, replyContent) {
    return transport
      .send("POST", `${ENDPOINT}/${id}/reply`, { reply: { reply: replyContent } })
      .then((response) => {
        const data = convertToCamelCase(response.data);
        storage.reviewsAdd(data);
        return data;
      });
  }

  archive(id) {
    return transport.send("POST", `${ENDPOINT}/${id}/archive`, {}).then((response) => {
      const data = convertToCamelCase(response.data);
      storage.reviewsAdd(data);
      return data;
    });
  }

  guestReview(id, guestReview) {
    const payload = {
      review: {
        scores: [
          {
            category: "respect_house_rules",
            rating: guestReview.respectHouseRules,
          },
          {
            category: "communication",
            rating: guestReview.communication,
          },
          {
            category: "cleanliness",
            rating: guestReview.cleanliness,
          },
        ],
        private_review: guestReview.privateReview,
        public_review: guestReview.publicReview,
        is_reviewee_recommended: guestReview.isRevieweeRecommended,
      },
    };
    return transport.send("POST", `${ENDPOINT}/${id}/guest_review`, payload).then(
      (response) => {
        return response;
      },
      (error) => {
        return error;
      },
    );
  }

  reset() {
    return storage.reviewsReset();
  }
}
