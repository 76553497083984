import attributesExtractor from "../utils/attributes_extractor";
import relationshipsExtractor from "../utils/relationships_extractor";

let transport;
let storage;

const ENDPOINT = "rate_plans";
const MAX_PAGE_SIZE = 100;
const PROPERTIES_LIMIT = 15;

export default class RatePlans {
  constructor(container) {
    transport = container.transport;
    storage = container.storage;
  }

  // eslint-disable-next-line consistent-return
  async list(filter = {}, pagination) {
    if (pagination) {
      return transport.send("GET", ENDPOINT, { filter, pagination }).then((response) => {
        storage.ratePlansLoad(response.data);
        return response.data;
      });
    }

    const loadedRatePlansChunks = [];
    const initialLoad = await transport.send("GET", ENDPOINT, {
      filter,
      pagination: { page: 1, limit: MAX_PAGE_SIZE },
    });

    const pagesTotal = Math.ceil(initialLoad.meta.total / MAX_PAGE_SIZE);
    loadedRatePlansChunks.push(initialLoad.data);

    for (let i = 2; i <= pagesTotal; i++) {
      // eslint-disable-next-line no-await-in-loop
      const ratePlans = await transport.send("GET", ENDPOINT, {
        filter,
        pagination: { page: i, limit: MAX_PAGE_SIZE },
      });

      loadedRatePlansChunks.push(ratePlans.data);
    }

    const ratePlansArray = loadedRatePlansChunks.flat();

    storage.ratePlansLoad(ratePlansArray);

    return ratePlansArray;
  }

  options(filter = {}, options = {}) {
    if (!Array.isArray(filter.property_id)) {
      return transport
        .send("GET", `${ENDPOINT}/options`, { filter, ...options })
        .then(({ data }) => {
          return attributesExtractor(data);
        });
    }

    // TODO - replace with more universal solution
    const selectedProperties = [];

    for (let i = 0; i < filter.property_id.length; i += PROPERTIES_LIMIT) {
      selectedProperties.push(filter.property_id.slice(i, i + PROPERTIES_LIMIT));
    }

    const roomOptionsRequests = selectedProperties.map((propertiesArray) => {
      const updatedFilter = { ...filter, property_id: propertiesArray };

      return transport.send("GET", `${ENDPOINT}/options`, { filter: updatedFilter, ...options });
    });

    return Promise.all(roomOptionsRequests).then((response) => {
      const optionsByID = response
        .map(({ data }) => attributesExtractor(data))
        .flat()
        .reduce((acc, option) => ({ ...acc, [option.id]: option }), {});

      return Object.values(optionsByID);
    });
  }

  find(id) {
    return transport.send("GET", `${ENDPOINT}/${id}`).then((response) => {
      const result = response.data;

      return relationshipsExtractor(result);
    });
  }

  create(attrs) {
    return transport.send("POST", ENDPOINT, { rate_plan: attrs }).then((response) => {
      storage.ratePlansAdd(response.data);
      return response;
    });
  }

  update(attrs) {
    return transport
      .send("PUT", `${ENDPOINT}/${attrs.id}`, { rate_plan: attrs })
      .then((response) => {
        storage.ratePlansAdd(response.data);
        return response;
      });
  }

  remove(attrs) {
    return transport.send("DELETE", `${ENDPOINT}/${attrs.id}`).then((response) => {
      storage.ratePlansDrop(attrs);
      return response;
    });
  }

  forceRemove(attrs) {
    return transport.send("DELETE", `${ENDPOINT}/${attrs.id}?force=true`).then((response) => {
      storage.ratePlansDrop(attrs);
      return response;
    });
  }
}
