import React from "react";
import { useTranslation } from "react-i18next";

import ChannexDrawer from "drawers/channex_drawer";

import DrawerFormWrapper from "components/drawer_form_wrapper";

import { CreateExtraForm } from "./create_form/create_form";

export function CreateExtraDrawer({ propertyId, currency, extraCategoryId, visible, onClose }) {
  const { t } = useTranslation();
  const title = t("property_extras:titles:create_extra");

  return (
    <ChannexDrawer title={title} visible={visible} onClose={onClose}>
      <DrawerFormWrapper withActions>
        <CreateExtraForm propertyId={propertyId} currency={currency} extraCategoryId={extraCategoryId} onFinish={onClose} />
      </DrawerFormWrapper>
    </ChannexDrawer>
  );
}
