import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import store from "store";

import DrawerFormWrapper from "components/drawer_form_wrapper";
import * as Issue from "components/issue";
import List from "components/list";
import Loading from "components/loading";

import { formatToLocal } from "utils/dates";
import EventEmitter from "utils/event_emitter";

import ChannelLink from "./components/issues/channel_link";
import UnmappedRoom from "./components/issues/unmapped_room";

import styles from "./issue_view.module.css";

const { ChannelEvents } = store;

const SHOW_PAYLOAD = false;

class IssueView extends Component {
  state = {
    loading: true,
    issue: {},
    issueDetails: {},
  };

  componentDidMount() {
    this.load();
  }

  load = () => {
    const { issueId } = this.props;

    return ChannelEvents.find(issueId).then((issue) => {
      const { name } = issue;

      const { loadDetails } = this.getIssueComponentsByName(name);

      return loadDetails(issue)
        .then((issueDetails) => {
          this.setState({
            issueDetails,
            issue,
            loading: false,
          });
        })
        .catch(() => {
          this.setState({
            issue,
            loading: false,
          });
        });
    });
  };

  getIssueComponentsByName = (name) => {
    const { issue, issueDetails } = this.state;

    const issueComponents = {
      unmapped_room: {
        ActionBar: (
          <UnmappedRoom.ActionBar
            issue={issue}
            details={issueDetails}
            onResolve={this.onResolve}
            onIgnore={this.onIgnore}
          />
        ),
        Content: <UnmappedRoom.Content issue={issue} details={issueDetails} />,
        loadDetails: UnmappedRoom.loadDetails,
      },
      unmapped_rate: {
        ActionBar: (
          <UnmappedRoom.ActionBar
            issue={issue}
            details={issueDetails}
            onResolve={this.onResolve}
            onIgnore={this.onIgnore}
          />
        ),
        Content: <UnmappedRoom.Content issue={issue} details={issueDetails} />,
        loadDetails: UnmappedRoom.loadDetails,
      },
      airbnb_sync_error: {
        ActionBar: <Issue.ActionBar issue={issue} resolveAction={null} ignoreAction={null} />,
        Content: <ChannelLink.Content issue={issue} details={issueDetails} />,
        loadDetails: () => Promise.resolve({}),
      },
      expedia_sync_error: {
        ActionBar: <Issue.ActionBar issue={issue} resolveAction={null} ignoreAction={null} />,
        Content: <ChannelLink.Content issue={issue} details={issueDetails} />,
        loadDetails: () => Promise.resolve({}),
      },
      expedia_sync_warnings: {
        ActionBar: <Issue.ActionBar issue={issue} resolveAction={null} ignoreAction={null} />,
        Content: <ChannelLink.Content issue={issue} details={issueDetails} />,
        loadDetails: () => Promise.resolve({}),
      },
      hostelworld_sync_error: {
        ActionBar: <Issue.ActionBar issue={issue} resolveAction={null} ignoreAction={null} />,
        Content: <ChannelLink.Content issue={issue} details={issueDetails} />,
        loadDetails: () => Promise.resolve({}),
      },
      booking_com_sync_error: {
        ActionBar: <Issue.ActionBar issue={issue} resolveAction={null} ignoreAction={null} />,
        Content: <ChannelLink.Content issue={issue} details={issueDetails} />,
        loadDetails: () => Promise.resolve({}),
      },
      default: {
        ActionBar: <Issue.ActionBar issue={issue} resolveAction={null} ignoreAction={null} />,
        Content: null,
        loadDetails: () => Promise.resolve({}),
      },
    };

    return issueComponents[name] || issueComponents.default;
  };

  onResolve = (solutions) => {
    const { issueId, onResolve = () => {}, onClose } = this.props;

    return ChannelEvents.resolveIssue(issueId, solutions)
      .then(() => EventEmitter.trigger("issues:reload"))
      .then(onResolve)
      .then(onClose);
  };

  onIgnore = () => {
    const { issueId, onIgnore = () => {}, onClose } = this.props;

    return ChannelEvents.ignoreIssue(issueId)
      .then(() => EventEmitter.trigger("issues:reload"))
      .then(onIgnore)
      .then(onClose);
  };

  render() {
    const { t } = this.props;
    const { loading, issue } = this.state;
    const { name, id, inserted_at: insertedAt, payload = {} } = issue;
    const { status } = payload;

    const issueComponents = this.getIssueComponentsByName(name);
    const { ActionBar, Content } = issueComponents;
    const insertedAtFormatted = formatToLocal(insertedAt, t("formats:date_time_with_seconds"));

    if (loading) {
      return <Loading />;
    }

    return (
      <div className={styles.issueContainer}>
        {ActionBar}
        <DrawerFormWrapper className={styles.scrollableContent}>
          <div className={styles.info_list}>
            <List>
              <List.Item term={t("general:id")} description={id} />
              <List.Item term={t("general:type")} description={t(`issues:types:${name}`)} />
              {status && (
                <List.Item
                  term={t("general:status")}
                  description={t(`issues:statuses:${status}`)}
                />
              )}
              <List.Item term={t("general:created")} description={insertedAtFormatted} />
            </List>
          </div>
          {SHOW_PAYLOAD
            && (payload.errors || []).map((error, i) => <pre key={i}>{JSON.stringify(error)}</pre>)}
          {Content}
        </DrawerFormWrapper>
      </div>
    );
  }
}

export default withTranslation()(IssueView);
