import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import _ from "lodash";

import ChannexDrawer from "drawers/channex_drawer";

import DrawerFormWrapper from "components/drawer_form_wrapper";

import prettifyXml from "utils/prettify_xml";

class RevisionMessageDrawer extends Component {
  renderRawMessage = () => {
    const { rawMessage } = this.props;

    let trimmedMessage = _.trim(rawMessage, '"');

    if (trimmedMessage.startsWith("\ufeff")) {
      trimmedMessage = this.prettifyEscapedXml(trimmedMessage);
    }

    const message = trimmedMessage.startsWith("<")
      ? prettifyXml(trimmedMessage)
      : JSON.stringify(JSON.parse(rawMessage), true, 4);

    return <pre>{message}</pre>;
  };

  prettifyEscapedXml = (escapedXml) => {
    let prettifiedXml = escapedXml.replace(/\uFEFF/g, "");
    prettifiedXml = prettifiedXml.replace(/\\r\\n/g, "");
    prettifiedXml = prettifiedXml.replace(/\\"/g, '"');

    return prettifiedXml;
  };

  render() {
    const { t, visible, onClose } = this.props;

    return (
      <ChannexDrawer
        title={t("bookings_page:booking_view_dialog:raw_message:header")}
        onClose={onClose}
        visible={visible}
      >
        {() => <DrawerFormWrapper>{this.renderRawMessage()}</DrawerFormWrapper>}
      </ChannexDrawer>
    );
  }
}

export default withTranslation()(RevisionMessageDrawer);
