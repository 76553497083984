import { RATE_PLANS_ADD, RATE_PLANS_DROP, RATE_PLANS_LOAD } from "../constants";

export function ratePlansLoad(storage) {
  return function (ratePlans) {
    storage.dispatch({ type: RATE_PLANS_LOAD, payload: ratePlans });
  };
}

export function ratePlansAdd(storage) {
  return function (ratePlan) {
    storage.dispatch({ type: RATE_PLANS_ADD, payload: ratePlan });
  };
}

export function ratePlansDrop(storage) {
  return function (ratePlan) {
    storage.dispatch({ type: RATE_PLANS_DROP, payload: ratePlan });
  };
}
