import React from "react";

import styles from "./page_header.module.css";

const PageHeader = ({ title, subTitle }) => (
  <div className={styles.pageHeader}>
    <div className={styles.pageHeader__title}>{title}</div>
    {subTitle && (<div className={styles.pageHeader__subTitle}>{subTitle}</div>)}
  </div>
);

export default PageHeader;
