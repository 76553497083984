import extractRelationships from "../../utils/relationships_extractor";
import { WEBHOOKS_ADD, WEBHOOKS_DROP, WEBHOOKS_LOAD } from "../constants";

const initialState = null;
const ACTION_HANDLERS = {
  [WEBHOOKS_LOAD]: (_state, action) => {
    const entities = extractRelationships(action.payload.records);

    return {
      entities,
      meta: action.payload.meta,
    };
  },
  [WEBHOOKS_ADD]: (state, action) => {
    const state_entities = state && state.entities ? state.entities : {};
    const { id, attributes } = action.payload;

    const entities = { ...state_entities, [id]: attributes };

    return { ...state, entities };
  },
  [WEBHOOKS_DROP]: (state, action) => {
    if (state && state.entities) {
      delete state.entities[action.payload.id];
    }
    return { ...(state || {}) };
  },
};

export default function webhooksReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
