export function transformMappingsToObject(mappingsAsArray) {
  const mappingsByRatePlanId = {};

  mappingsAsArray.forEach((item) => {
    mappingsByRatePlanId[item.ratePlanId] = mappingsByRatePlanId[item.ratePlanId] || [];

    mappingsByRatePlanId[item.ratePlanId].push({
      ...item.settings,
    });
  });

  return mappingsByRatePlanId;
}
