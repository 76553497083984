import { USER_ADD, USER_UPDATE } from "../constants";

export function userAdd(storage) {
  return function (user) {
    storage.dispatch({ type: USER_ADD, payload: user });
  };
}

export function userUpdate(storage) {
  return function (payload) {
    storage.dispatch({ type: USER_UPDATE, payload });
  };
}
