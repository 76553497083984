import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Spin } from "antd";
import classNames from "classnames";

import styles from "./onboarding.module.css";

export default function OnboardingFooter({
  message,
  handleSubmitClick,
  step,
  loading,
  onDecline,
  onConfirm,
  onClose,
}) {
  const { t } = useTranslation();

  const classNameWrapper = classNames(styles.footer, {
    [`${styles.footer__only}`]: !message,
  });
  const newMessage = typeof message === "object" && message !== null
    ? `${t("properties_page:booking_com_onboarding:onboarding_form:pull_inventory_information")} ${message.iteration} ${t("properties_page:booking_com_onboarding:onboarding_form:of")} ${message.total}`
    : `${t(`properties_page:booking_com_onboarding:onboarding_ws_messages:${message}`)}...`;

  return (
    <div className={classNameWrapper}>
      {message && <Spin tip={newMessage} />}
      <div>
        {step === 1 ? (
          <>
            <Button onClick={onDecline} type="default" disabled={loading}>
              {t("properties_page:booking_com_onboarding:onboarding_success_modal:decline")}
            </Button>
            <Button onClick={onConfirm} htmlType="submit" type="primary" disabled={loading}>
              {t("properties_page:booking_com_onboarding:onboarding_success_modal:accept")}
            </Button>
          </>
        ) : (
          <>
            {step === 0 && (
              <Button onClick={onClose} type="default" disabled={loading}>
                {t("general:action:close")}
              </Button>
            )}
            <Button onClick={handleSubmitClick} type="primary" disabled={loading}>
              {step === 4
                ? t("properties_page:booking_com_onboarding:onboarding_form:done")
                : t("properties_page:booking_com_onboarding:onboarding_form:next")}
            </Button>
          </>
        )}
      </div>
    </div>
  );
}
