import React, { useEffect, useMemo, useRef } from "react";
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from "react-virtualized";

import { useWindowSize } from "hooks/use_window_size/use_window_size";

import RatePlanSelector from "./rate_plan_selector";
import RoomRateSelector from "./room_rate_selector";

const ROW_SELECTOR_HEIGHT = 32;
const ROOM_TYPE = "roomType";
const RATE_PLAN = "ratePlan";

export default function RoomTypesAndRatePlansSelectableList(props) {
  const {
    rooms,
    selectedRatePlans,
    disabledRatePlans,
    selectedRoomTypes,
    onRoomTypeToggle,
    onRatePlanToggle,
  } = props;

  const listRef = useRef();
  const windowSize = useWindowSize();

  const items = useMemo(
    () => rooms.reduce((acc, room) => {
      return [
        ...acc,
        {
          ...room,
          type: ROOM_TYPE,
        },
        ...room.ratePlans.map((ratePlan) => ({ ...ratePlan, type: RATE_PLAN })),
      ];
    }, []),
    [rooms],
  );

  const cache = useMemo(() => new CellMeasurerCache({
    defaultHeight: ROW_SELECTOR_HEIGHT,
    fixedWidth: true,
    minHeight: ROW_SELECTOR_HEIGHT,
  }), [items]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    cache.clearAll();
    listRef.current?.forceUpdateGrid();
  }, [cache, windowSize]);

  return (
    <AutoSizer>
      {({ width }) => (
        <List
          ref={listRef}
          rowCount={items.length}
          height={214} // probably because of space for "select all" checkbox height is determined wrong, using fixed value for now
          width={width}
          deferredMeasurementCache={cache}
          rowHeight={cache.rowHeight}
          rowRenderer={({ index, columnIndex, key, parent, style }) => {
            const item = items[index];

            if (item.type === ROOM_TYPE) {
              return (
                <CellMeasurer
                  key={key}
                  cache={cache}
                  columnIndex={columnIndex}
                  parent={parent}
                  rowIndex={index}
                >
                  <div style={style}>
                    <RoomRateSelector
                      roomType={item}
                      selected={selectedRoomTypes[item.id]?.selected}
                      disabled={selectedRoomTypes[item.id]?.disabled}
                      indeterminate={selectedRoomTypes[item.id]?.indeterminate}
                      onRoomTypeToggle={onRoomTypeToggle}
                    />
                  </div>
                </CellMeasurer>
              );
            }

            if (item.type === RATE_PLAN) {
              return (
                <CellMeasurer
                  key={key}
                  cache={cache}
                  columnIndex={columnIndex}
                  parent={parent}
                  rowIndex={index}
                >
                  <div style={style}>
                    <RatePlanSelector
                      ratePlan={item}
                      selected={selectedRatePlans[item.id]}
                      disabled={disabledRatePlans[item.id]}
                      onRatePlanToggle={onRatePlanToggle}
                    />
                  </div>
                </CellMeasurer>
              );
            }

            throw new Error("Unknown item type");
          }}
        />
      )}
    </AutoSizer>
  );
}
