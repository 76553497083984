import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Radio } from "antd";

import ChannexPopover from "components/channex_popover";
import RemoveButton from "components/forms/buttons/remove";
import RatePlanTitle from "components/rates/title";

import styles from "./mapping_popover.module.css";

export default function ConflictResolverPopover(props) {
  const { t } = useTranslation();
  const {
    mappingPopupData,
    handleConflictFormChange,
    handlePopupDeleteClick,
    handleApplyConflictResolve,
    handleClosePopup,
  } = props;

  const { groupedRates, conflictValue } = mappingPopupData;

  const resolveOptions = useMemo(() => {
    return Object.keys(groupedRates).map((rateGroupId) => {
      const rateGroup = groupedRates[rateGroupId];
      const { parent_rate_plan_id } = rateGroup[0];

      return (
        <Radio className={styles.radioItem} value={rateGroupId} key={parent_rate_plan_id}>
          {rateGroup.map((rate) => (
            <RatePlanTitle className={styles.mappingItemRateTitle} rate={rate} key={rate.id} />
          ))}
        </Radio>
      );
    });
  }, [groupedRates]);

  return (
    <ChannexPopover.Content
      title={t("mapping:resolve_conflict_title")}
      additionalButtons={<RemoveButton onClick={handlePopupDeleteClick} />}
      onSave={handleApplyConflictResolve}
      onCancel={handleClosePopup}
    >
      <p>{t("mapping:resolve_conflict_message")}</p>
      <Radio.Group onChange={handleConflictFormChange} value={conflictValue}>
        {resolveOptions}
      </Radio.Group>
    </ChannexPopover.Content>
  );
}
