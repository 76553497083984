import React from "react";
import PropTypes from "prop-types";

import FormCascader from "components/forms/inputs/form_cascader";

function handleChangeCallback(callback, field) {
  return (value) => {
    callback(field, value);
  };
}

function optionsGenerator(id, ratePlans, roomTypes, propertyId) {
  const ratePlansArray = Object.values(ratePlans);
  return Object.values(roomTypes)
    .filter((el) => el.property_id === propertyId)
    .map((roomType) => {
      return {
        value: roomType.id,
        label: roomType.title,
        children: ratePlansArray
          .filter(
            (el) => el.room_type_id === roomType.id
              && el.id !== id
              && (el.parent_rate_plan_id === undefined || el.parent_rate_plan_id !== id),
          )
          .map((ratePlan) => {
            return {
              value: ratePlan.id,
              label: `${ratePlan.title} (${ratePlan.occupancy})`,
            };
          }),
      };
    })
    .filter((el) => Boolean(el.children.length));
}

function InputParentRatePlan(props) {
  const { t, model, errors, onChange, roomTypes, ratePlans } = props;
  const onHandleChange = handleChangeCallback(onChange, "parent_rate_plan_id");
  const options = optionsGenerator(model.id, ratePlans, roomTypes, model.property_id);

  return (
    <FormCascader
      name="parent_rate_plan"
      allowClear
      view="horizontal"
      placeholder={t("rates_page:form:parent_rate_placeholder")}
      label={t("rates_page:form:parent_rate_label")}
      defaultValue={model.parent_rate_plan_id}
      errors={errors ? errors.parent_rate_plan_id : null}
      options={options}
      onChange={onHandleChange}
      disabled={Boolean(model.rate_category_id) || Boolean(model.id)}
    />
  );
}

InputParentRatePlan.propTypes = {
  t: PropTypes.func,
  model: PropTypes.object,
  errors: PropTypes.object,
  onChange: PropTypes.func,
  ratePlans: PropTypes.object,
  roomTypes: PropTypes.array,
};

export default InputParentRatePlan;
