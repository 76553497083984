import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Form } from "antd";
import * as yup from "yup";

import errorMessages from "config/constants/errors";

import ChannexForm from "components/channex_form";
import SubmitButton from "components/forms/buttons/submit_button";
import FormikFormInput from "components/forms/inputs/formik/form_input";

import styles from "./change_password_form_view.module.css";

class ChangePasswordFormView extends Component {
  VALIDATION_SCHEMA = yup.object().shape({
    currentPassword: yup.string().required(errorMessages.required()),
    password: yup.string().required(errorMessages.required()),
    passwordConfirmation: yup
      .string()
      .required(errorMessages.required())
      .oneOf([yup.ref("password"), null], errorMessages.passwordMatch()),
  });

  render() {
    const {
      t,
      value,
      errors,
      componentRef,
      loading,
      onChange,
      onSubmit,
    } = this.props;
    const formValue = { ...value, loading };

    return (
      <main className={styles.profileSection}>
        <div className={styles.header}>
          <p className={styles.title}>{t("change_password_section:title")}</p>
        </div>
        <ChannexForm
          value={formValue}
          errors={errors}
          componentRef={componentRef}
          validationSchema={this.VALIDATION_SCHEMA}
          onChange={onChange}
          className={styles.form}
        >
          {({ handlesubmit }) => (
            <Form data-cy="change_password" className={styles.form} onFinish={handlesubmit}>
              <FormikFormInput
                name="currentPassword"
                type="password"
                placeholder={t("user_profile_page:old_password")}
                label={t("user_profile_page:old_password")}
              />
              <FormikFormInput
                name="password"
                type="password"
                placeholder={t("user_profile_page:new_password")}
                label={t("user_profile_page:new_password")}
              />
              <FormikFormInput
                name="passwordConfirmation"
                type="password"
                placeholder={t("user_profile_page:password_confirmation")}
                label={t("user_profile_page:password_confirmation")}
              />
              <div className={styles.buttonContainer}>
                <SubmitButton loading={loading} onClick={onSubmit}>
                  {t("general:action:save")}
                </SubmitButton>
              </div>
            </Form>
          )}
        </ChannexForm>
      </main>
    );
  }
}

export default withTranslation()(ChangePasswordFormView);
