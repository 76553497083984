import React from "react";
import { useTranslation } from "react-i18next";
import { UserOutlined } from "@ant-design/icons";

import FormInput from "components/forms/inputs/formik/form_input";

function NameInput(props) {
  const { t } = useTranslation();

  return (
    <FormInput
      className="form-input__username"
      view="vertical"
      type="text"
      name="name"
      icon={<UserOutlined />}
      placeholder={t("public_pages:name_field_placeholder")}
      {...props}
    />
  );
}

export default NameInput;
