import { useCallback, useState } from "react";

export default function useBoolState(initialState) {
  const [state, setState] = useState(initialState);

  const setTrueState = useCallback(() => setState(true), []);

  const setFalseState = useCallback(() => setState(false), []);

  return [state, setTrueState, setFalseState];
}
