import _ from "lodash";

import { REVIEWS_ADD, REVIEWS_LOAD, REVIEWS_RESET, REVIEWS_SET_REQUEST_ID } from "../constants";

const initialState = {
  entities: {},
  requestId: null,
  meta: {
    total: 0,
    page: 0,
    limit: 0,
  },
};

const ACTION_HANDLERS = {
  [REVIEWS_LOAD]: (state, action) => {
    const payloadRequestId = action.payload.requestId;
    const actualRequestId = state.requestId;
    const isStaleRequest = payloadRequestId !== actualRequestId;

    if (isStaleRequest) {
      return state;
    }

    const new_reviews = action.payload.reviews.reduce((acc, review) => {
      return {
        ...acc,
        [review.id]: review,
      };
    }, {});

    return {
      ...state,
      entities: {
        ...state.entities,
        ...new_reviews,
      },
      meta: action.payload.meta,
    };
  },
  [REVIEWS_ADD]: (state, action) => {
    const state_entities = state && state.entities ? state.entities : {};
    const entities = {
      ...(state_entities || {}),
      [action.payload.id]: action.payload,
    };

    return { ...(state || {}), entities };
  },
  [REVIEWS_SET_REQUEST_ID]: (state, action) => {
    return {
      ...state,
      requestId: action.payload,
    };
  },
  [REVIEWS_RESET]: (state) => {
    return {
      ...state,
      entities: {},
      meta: {
        total: 0,
        page: 0,
        limit: 0,
      },
      requestId: null,
    };
  },
};

export default function reviewsReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
