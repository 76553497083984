import React from "react";
import { useTranslation } from "react-i18next";
import { ClockCircleOutlined } from "@ant-design/icons";
import { Alert, Button } from "antd";

import styles from "./air_bnb.module.css";

export default function AirBnbLoadingCaption({ onCancel }) {
  const { t } = useTranslation();

  return (
    <Alert
      showIcon
      type="warning"
      icon={<ClockCircleOutlined className={styles.icon} />}
      message={t("channels_page:connection_test:connect_airbnb_caption")}
      action={<Button onClick={onCancel}>{t("general:action:cancel")}</Button>}
    />
  );
}
