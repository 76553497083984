export default function filterFlatTree(flatTree, searchTerm) {
  const cleanSearchTerm = searchTerm.trim().toLowerCase();

  let currentTopNode = null;
  let isTopNodeAdded = false;
  const filteredFlatTree = [];

  flatTree.forEach((node) => {
    if (node.level === 0) {
      currentTopNode = node;
      isTopNodeAdded = false;
    }

    const isTitleMatch = node.title.toLowerCase().includes(cleanSearchTerm);
    const isIdMatch = `${node.id}`.toLowerCase().includes(cleanSearchTerm);

    if (!isTitleMatch && !isIdMatch) {
      return;
    }

    // if matched top level node we add it filteredFlatTree and set isTopNodeAdded to true
    // to skip adding it again in child nodes match
    if (node.level === 0) {
      filteredFlatTree.push(node);
      isTopNodeAdded = true;

      return;
    }

    // if matched child node and top level node is not added yet we add it to keep tree structure
    if (!isTopNodeAdded) {
      filteredFlatTree.push(currentTopNode);
      isTopNodeAdded = true;
    }

    // add child node
    filteredFlatTree.push(node);
  });

  return filteredFlatTree;
}
