import React from "react";

import Arc from "./arc";
import types from "./types";

import styles from "./score_item.module.css";

export default function ScoreItem({ type, score }) {
  const item = types[type] || types.unknown;

  const isScorePresent = score !== null && score !== undefined;

  return (
    <div className={styles.container}>
      <Arc type={type} score={score} />

      <div className={styles.title}>{item.title(type)}</div>

      {isScorePresent && <div className={styles.score}>{score}</div>}
      {isScorePresent || <div className={styles.noScore}>N/A</div>}
    </div>
  );
}
