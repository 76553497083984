import currencies from "world-currencies";

import alphabetSort from "utils/alphabet_sort";

export default () => Object.values(currencies)
  .filter(({ iso }) => iso.code !== "HRK")
  .map(({ iso, name }) => {
    return {
      value: iso.code,
      representation: `${name} (${iso.code})`,
    };
  })
  .sort(alphabetSort("representation"));
