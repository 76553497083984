import { useCallback, useMemo, useState } from "react";

export const useFilteredCollection = ({ collection, filterFn, filterField }) => {
  const [filterTerm, setFilterTerm] = useState(null);

  const handleFilterFieldChange = useCallback((event) => {
    setFilterTerm(event.target.value || null);
  }, [setFilterTerm]);

  const filteredCollection = useMemo(() => {
    if (filterTerm === null) {
      return collection;
    }

    if (filterFn) {
      return collection.filter((item) => filterFn(item, filterTerm));
    }

    if (filterField) {
      return collection.filter((item) => item[filterField]?.toLowerCase().includes(filterTerm.toLowerCase()));
    }

    throw new Error("Either filterFn or filterField must be provided");
  }, [collection, filterTerm, filterField, filterFn]);

  return {
    filteredItems: filteredCollection,
    filterTerm,
    handleFilterFieldChange,
  };
};
