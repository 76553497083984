import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { DownOutlined, SettingOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Switch } from "antd";

import SortableList from "./components/sortable_list";

import styles from "./mapping_list_settings.module.css";
import generalStyles from "styles/general.module.css";

class MappingListSettings extends Component {
  handleSortChange = ({ oldIndex, newIndex }) => {
    const { mappingSettings, onChangeSettings } = this.props;
    const { orderedMappingSchema } = mappingSettings;

    const item = orderedMappingSchema.splice(oldIndex, 1)[0];
    orderedMappingSchema.splice(newIndex, 0, item);

    onChangeSettings({
      ...mappingSettings,
      orderedMappingSchema,
    });
  };

  handleToggleMappingModeClick = () => {
    const { mappingSettings, onChangeSettings } = this.props;

    onChangeSettings({
      ...mappingSettings,
      reverseMapping: !mappingSettings.reverseMapping,
    });
  };

  buildMenu = () => {
    const { reorderEnabled, mappingSettings } = this.props;
    const { reverseMapping, orderedMappingSchema } = mappingSettings || {};
    const hasMultipleListItems = orderedMappingSchema.length > 1;

    return (
      <Menu>
        <div className={styles.menuItem}>
          <Switch
            size="small"
            checked={reverseMapping}
            onClick={this.handleToggleMappingModeClick}
          />
          &nbsp;Flip View
        </div>
        {reorderEnabled && hasMultipleListItems && (
          <>
            <Menu.Divider />
            <div className={styles.menuItem}>
              <div className={styles.menuItemHeader}>Order</div>
              <SortableList
                items={orderedMappingSchema}
                onSortEnd={this.handleSortChange}
                helperClass={styles.sortableHelper}
              />
            </div>
          </>
        )}
      </Menu>
    );
  };

  render() {
    const { t } = this.props;

    return (
      <div className={styles.settingsContainer}>
        <Dropdown overlay={this.buildMenu()} trigger={["click"]}>
          <a
            className={generalStyles.actionsToggle}
            href="#actions"
            onClick={(event) => event.preventDefault()}
          >
            <SettingOutlined className={styles.settingsIcon} />
            {t("channels_page:form:settings")} <DownOutlined />
          </a>
        </Dropdown>
      </div>
    );
  }
}

export default withTranslation()(MappingListSettings);
