import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import ChannexDrawer from "drawers/channex_drawer";

import PropertyForm from "components/property_form";

function PropertyFormDrawerControlled({ visible, propertyId, defaultTab, focusField, onClose }) {
  const { t } = useTranslation();
  const title = t(
    propertyId ? "properties_page:edit_dialog_header" : "properties_page:create_dialog_header",
  );

  return (
    <ChannexDrawer title={title} visible={visible} onClose={onClose}>
      {({ handleCloseFromContent }) => (
        <PropertyForm
          propertyId={propertyId}
          defaultTab={defaultTab}
          focusField={focusField}
          onClose={handleCloseFromContent}
        />
      )}
    </ChannexDrawer>
  );
}

PropertyFormDrawerControlled.propTypes = {
  visible: PropTypes.bool.isRequired,
  propertyId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PropertyFormDrawerControlled;
