import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Space } from "antd";

import Flexbox from "components/flexbox";

export default function InitialState({ onCloseClick, onStartClick }) {
  const { t } = useTranslation();

  return (
    <div>
      <p>{t("bookings_export:confirm_message")}</p>
      <Flexbox justifyContent="end">
        <Space>
          <Button onClick={onCloseClick}>{t("general:action:close")}</Button>
          <Button type="primary" onClick={onStartClick}>{t("bookings_export:action:export")}</Button>
        </Space>
      </Flexbox>
    </div>
  );
}
