import {
  LIVE_FEED_EVENT_ADD,
  LIVE_FEED_EVENTS_LOAD,
  LIVE_FEED_EVENTS_RESET,
  LIVE_FEED_SET_REQUEST_ID,
} from "../constants";

const initialState = {
  entities: null,
  meta: null,
  requestId: null,
};

const ACTION_HANDLERS = {
  [LIVE_FEED_EVENTS_LOAD]: (state, action) => {
    const data = action.payload.entities;
    const payloadRequestId = action.payload.requestId;
    const actualRequestId = state.requestId;

    const isStaleRequest = payloadRequestId !== actualRequestId;

    if (isStaleRequest) {
      return state;
    }

    let newEntities = data;

    if (state.entities) {
      newEntities = data.reduce((acc, entity) => {
        const isEntity = state.entities.find(({ id }) => id === entity.id);

        if (isEntity) {
          return acc;
        }
        return acc.concat(entity);
      }, state.entities);
    }

    return {
      ...state,
      entities: newEntities,
      meta: action.payload.meta,
    };
  },
  [LIVE_FEED_EVENT_ADD]: (state, action) => {
    const data = action.payload;
    const newEntities = [data, ...(state.entities || [])];
    const newTotal = state.meta.total + 1;
    return {
      ...state,
      entities: newEntities,
      meta: {
        ...state.entities.meta,
        total: newTotal,
      },
    };
  },
  [LIVE_FEED_SET_REQUEST_ID]: (state, action) => {
    return {
      ...state,
      requestId: action.payload,
    };
  },
  [LIVE_FEED_EVENTS_RESET]: (state) => {
    return {
      ...state,
      requestId: null,
      entities: null,
      meta: null,
    };
  },
};

export default function liveFeedEventsReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
