import React from "react";
import { Button } from "antd";
import classnames from "classnames";

import styles from "./link_button.module.css";

export default function LinkButton({ children, className, dataCy, ...buttonProps }) {
  const buttonClassname = classnames(styles.button, className);

  return (
    <Button data-cy={dataCy} {...buttonProps} className={buttonClassname} type="link">
      {children}
    </Button>
  );
}
