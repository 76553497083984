const COUNTRIES = [
  ["Aruba", "Aruba", "AW"],
  ["Afghanistan", "Islamic Republic of Afghanistan", "AF"],
  ["Angola", "Republic of Angola", "AO"],
  ["Anguilla", "Anguilla", "AI"],
  ["Åland Islands", "Åland Islands", "AX"],
  ["Albania", "Republic of Albania", "AL"],
  ["Andorra", "Principality of Andorra", "AD"],
  ["United Arab Emirates", "United Arab Emirates", "AE"],
  ["Argentina", "Argentine Republic", "AR"],
  ["Armenia", "Republic of Armenia", "AM"],
  ["American Samoa", "American Samoa", "AS"],
  ["Antarctica", "Antarctica", "AQ"],
  [
    "French Southern and Antarctic Lands",
    "Territory of the French Southern and Antarctic Lands",
    "TF",
  ],
  ["Antigua and Barbuda", "Antigua and Barbuda", "AG"],
  ["Australia", "Commonwealth of Australia", "AU"],
  ["Austria", "Republic of Austria", "AT"],
  ["Azerbaijan", "Republic of Azerbaijan", "AZ"],
  ["Burundi", "Republic of Burundi", "BI"],
  ["Belgium", "Kingdom of Belgium", "BE"],
  ["Benin", "Republic of Benin", "BJ"],
  ["Burkina Faso", "Burkina Faso", "BF"],
  ["Bangladesh", "People's Republic of Bangladesh", "BD"],
  ["Bulgaria", "Republic of Bulgaria", "BG"],
  ["Bahrain", "Kingdom of Bahrain", "BH"],
  ["Bahamas", "Commonwealth of the Bahamas", "BS"],
  ["Bosnia and Herzegovina", "Bosnia and Herzegovina", "BA"],
  ["Saint Barthélemy", "Collectivity of Saint Barthélemy", "BL"],
  [
    "Saint Helena, Ascension and Tristan da Cunha",
    "Saint Helena, Ascension and Tristan da Cunha",
    "SH",
  ],
  ["Belarus", "Republic of Belarus", "BY"],
  ["Belize", "Belize", "BZ"],
  ["Bermuda", "Bermuda", "BM"],
  ["Bolivia", "Plurinational State of Bolivia", "BO"],
  ["Caribbean Netherlands", "Bonaire, Sint Eustatius and Saba", "BQ"],
  ["Brazil", "Federative Republic of Brazil", "BR"],
  ["Barbados", "Barbados", "BB"],
  ["Brunei", "Nation of Brunei, Abode of Peace", "BN"],
  ["Bhutan", "Kingdom of Bhutan", "BT"],
  ["Bouvet Island", "Bouvet Island", "BV"],
  ["Botswana", "Republic of Botswana", "BW"],
  ["Central African Republic", "Central African Republic", "CF"],
  ["Canada", "Canada", "CA"],
  ["Cocos (Keeling) Islands", "Territory of the Cocos (Keeling) Islands", "CC"],
  ["Switzerland", "Swiss Confederation", "CH"],
  ["Chile", "Republic of Chile", "CL"],
  ["China", "People's Republic of China", "CN"],
  ["Ivory Coast", "Republic of Côte d'Ivoire", "CI"],
  ["Cameroon", "Republic of Cameroon", "CM"],
  ["DR Congo", "Democratic Republic of the Congo", "CD"],
  ["Republic of the Congo", "Republic of the Congo", "CG"],
  ["Cook Islands", "Cook Islands", "CK"],
  ["Colombia", "Republic of Colombia", "CO"],
  ["Comoros", "Union of the Comoros", "KM"],
  ["Cape Verde", "Republic of Cabo Verde", "CV"],
  ["Costa Rica", "Republic of Costa Rica", "CR"],
  ["Cuba", "Republic of Cuba", "CU"],
  ["Curaçao", "Country of Curaçao", "CW"],
  ["Christmas Island", "Territory of Christmas Island", "CX"],
  ["Cayman Islands", "Cayman Islands", "KY"],
  ["Cyprus", "Republic of Cyprus", "CY"],
  ["Czechia", "Czech Republic", "CZ"],
  ["Germany", "Federal Republic of Germany", "DE"],
  ["Djibouti", "Republic of Djibouti", "DJ"],
  ["Dominica", "Commonwealth of Dominica", "DM"],
  ["Denmark", "Kingdom of Denmark", "DK"],
  ["Dominican Republic", "Dominican Republic", "DO"],
  ["Algeria", "People's Democratic Republic of Algeria", "DZ"],
  ["Ecuador", "Republic of Ecuador", "EC"],
  ["Egypt", "Arab Republic of Egypt", "EG"],
  ["Eritrea", "State of Eritrea", "ER"],
  ["Western Sahara", "Sahrawi Arab Democratic Republic", "EH"],
  ["Spain", "Kingdom of Spain", "ES"],
  ["Estonia", "Republic of Estonia", "EE"],
  ["Ethiopia", "Federal Democratic Republic of Ethiopia", "ET"],
  ["Finland", "Republic of Finland", "FI"],
  ["Fiji", "Republic of Fiji", "FJ"],
  ["Falkland Islands", "Falkland Islands", "FK"],
  ["France", "French Republic", "FR"],
  ["Faroe Islands", "Faroe Islands", "FO"],
  ["Micronesia", "Federated States of Micronesia", "FM"],
  ["Gabon", "Gabonese Republic", "GA"],
  ["United Kingdom", "United Kingdom of Great Britain and Northern Ireland", "GB"],
  ["Georgia", "Georgia", "GE"],
  ["Guernsey", "Bailiwick of Guernsey", "GG"],
  ["Ghana", "Republic of Ghana", "GH"],
  ["Gibraltar", "Gibraltar", "GI"],
  ["Guinea", "Republic of Guinea", "GN"],
  ["Guadeloupe", "Guadeloupe", "GP"],
  ["Gambia", "Republic of the Gambia", "GM"],
  ["Guinea-Bissau", "Republic of Guinea-Bissau", "GW"],
  ["Equatorial Guinea", "Republic of Equatorial Guinea", "GQ"],
  ["Greece", "Hellenic Republic", "GR"],
  ["Grenada", "Grenada", "GD"],
  ["Greenland", "Greenland", "GL"],
  ["Guatemala", "Republic of Guatemala", "GT"],
  ["French Guiana", "Guiana", "GF"],
  ["Guam", "Guam", "GU"],
  ["Guyana", "Co-operative Republic of Guyana", "GY"],
  ["Hong Kong", "Hong Kong Special Administrative Region of the People's Republic of China", "HK"],
  ["Heard Island and McDonald Islands", "Heard Island and McDonald Islands", "HM"],
  ["Honduras", "Republic of Honduras", "HN"],
  ["Croatia", "Republic of Croatia", "HR"],
  ["Haiti", "Republic of Haiti", "HT"],
  ["Hungary", "Hungary", "HU"],
  ["Indonesia", "Republic of Indonesia", "ID"],
  ["Isle of Man", "Isle of Man", "IM"],
  ["India", "Republic of India", "IN"],
  ["British Indian Ocean Territory", "British Indian Ocean Territory", "IO"],
  ["Ireland", "Republic of Ireland", "IE"],
  ["Iran", "Islamic Republic of Iran", "IR"],
  ["Iraq", "Republic of Iraq", "IQ"],
  ["Iceland", "Iceland", "IS"],
  ["Israel", "State of Israel", "IL"],
  ["Italy", "Italian Republic", "IT"],
  ["Jamaica", "Jamaica", "JM"],
  ["Jersey", "Bailiwick of Jersey", "JE"],
  ["Jordan", "Hashemite Kingdom of Jordan", "JO"],
  ["Japan", "Japan", "JP"],
  ["Kazakhstan", "Republic of Kazakhstan", "KZ"],
  ["Kenya", "Republic of Kenya", "KE"],
  ["Kyrgyzstan", "Kyrgyz Republic", "KG"],
  ["Cambodia", "Kingdom of Cambodia", "KH"],
  ["Kiribati", "Independent and Sovereign Republic of Kiribati", "KI"],
  ["Saint Kitts and Nevis", "Federation of Saint Christopher and Nevisa", "KN"],
  ["South Korea", "Republic of Korea", "KR"],
  ["Kosovo", "Republic of Kosovo", "XK"],
  ["Kuwait", "State of Kuwait", "KW"],
  ["Laos", "Lao People's Democratic Republic", "LA"],
  ["Lebanon", "Lebanese Republic", "LB"],
  ["Liberia", "Republic of Liberia", "LR"],
  ["Libya", "State of Libya", "LY"],
  ["Saint Lucia", "Saint Lucia", "LC"],
  ["Liechtenstein", "Principality of Liechtenstein", "LI"],
  ["Sri Lanka", "Democratic Socialist Republic of Sri Lanka", "LK"],
  ["Lesotho", "Kingdom of Lesotho", "LS"],
  ["Lithuania", "Republic of Lithuania", "LT"],
  ["Luxembourg", "Grand Duchy of Luxembourg", "LU"],
  ["Latvia", "Republic of Latvia", "LV"],
  ["Macau", "Macao Special Administrative Region of the People's Republic of China", "MO"],
  ["Saint Martin", "Saint Martin", "MF"],
  ["Morocco", "Kingdom of Morocco", "MA"],
  ["Monaco", "Principality of Monaco", "MC"],
  ["Moldova", "Republic of Moldova", "MD"],
  ["Madagascar", "Republic of Madagascar", "MG"],
  ["Maldives", "Republic of the Maldives", "MV"],
  ["Mexico", "United Mexican States", "MX"],
  ["Marshall Islands", "Republic of the Marshall Islands", "MH"],
  ["Macedonia", "Republic of Macedonia", "MK"],
  ["Mali", "Republic of Mali", "ML"],
  ["Malta", "Republic of Malta", "MT"],
  ["Myanmar", "Republic of the Union of Myanmar", "MM"],
  ["Montenegro", "Montenegro", "ME"],
  ["Mongolia", "Mongolia", "MN"],
  ["Northern Mariana Islands", "Commonwealth of the Northern Mariana Islands", "MP"],
  ["Mozambique", "Republic of Mozambique", "MZ"],
  ["Mauritania", "Islamic Republic of Mauritania", "MR"],
  ["Montserrat", "Montserrat", "MS"],
  ["Martinique", "Martinique", "MQ"],
  ["Mauritius", "Republic of Mauritius", "MU"],
  ["Malawi", "Republic of Malawi", "MW"],
  ["Malaysia", "Malaysia", "MY"],
  ["Mayotte", "Department of Mayotte", "YT"],
  ["Namibia", "Republic of Namibia", "NA"],
  ["New Caledonia", "New Caledonia", "NC"],
  ["Niger", "Republic of Niger", "NE"],
  ["Norfolk Island", "Territory of Norfolk Island", "NF"],
  ["Nigeria", "Federal Republic of Nigeria", "NG"],
  ["Nicaragua", "Republic of Nicaragua", "NI"],
  ["Niue", "Niue", "NU"],
  ["Netherlands", "Kingdom of the Netherlands", "NL"],
  ["Norway", "Kingdom of Norway", "NO"],
  ["Nepal", "Federal Democratic Republic of Nepal", "NP"],
  ["Nauru", "Republic of Nauru", "NR"],
  ["New Zealand", "New Zealand", "NZ"],
  ["Oman", "Sultanate of Oman", "OM"],
  ["Pakistan", "Islamic Republic of Pakistan", "PK"],
  ["Panama", "Republic of Panama", "PA"],
  ["Pitcairn Islands", "Pitcairn Group of Islands", "PN"],
  ["Peru", "Republic of Peru", "PE"],
  ["Philippines", "Republic of the Philippines", "PH"],
  ["Palau", "Republic of Palau", "PW"],
  ["Papua New Guinea", "Independent State of Papua New Guinea", "PG"],
  ["Poland", "Republic of Poland", "PL"],
  ["Puerto Rico", "Commonwealth of Puerto Rico", "PR"],
  ["North Korea", "Democratic People's Republic of Korea", "KP"],
  ["Portugal", "Portuguese Republic", "PT"],
  ["Paraguay", "Republic of Paraguay", "PY"],
  ["Palestine", "State of Palestine", "PS"],
  ["French Polynesia", "French Polynesia", "PF"],
  ["Qatar", "State of Qatar", "QA"],
  ["Réunion", "Réunion Island", "RE"],
  ["Romania", "Romania", "RO"],
  ["Russia", "Russian Federation", "RU"],
  ["Rwanda", "Republic of Rwanda", "RW"],
  ["Saudi Arabia", "Kingdom of Saudi Arabia", "SA"],
  ["Sudan", "Republic of the Sudan", "SD"],
  ["Senegal", "Republic of Senegal", "SN"],
  ["Singapore", "Republic of Singapore", "SG"],
  ["South Georgia", "South Georgia and the South Sandwich Islands", "GS"],
  ["Svalbard and Jan Mayen", "Svalbard og Jan Mayen", "SJ"],
  ["Solomon Islands", "Solomon Islands", "SB"],
  ["Sierra Leone", "Republic of Sierra Leone", "SL"],
  ["El Salvador", "Republic of El Salvador", "SV"],
  ["San Marino", "Most Serene Republic of San Marino", "SM"],
  ["Somalia", "Federal Republic of Somalia", "SO"],
  ["Saint Pierre and Miquelon", "Saint Pierre and Miquelon", "PM"],
  ["Serbia", "Republic of Serbia", "RS"],
  ["South Sudan", "Republic of South Sudan", "SS"],
  ["São Tomé and Príncipe", "Democratic Republic of São Tomé and Príncipe", "ST"],
  ["Suriname", "Republic of Suriname", "SR"],
  ["Slovakia", "Slovak Republic", "SK"],
  ["Slovenia", "Republic of Slovenia", "SI"],
  ["Sweden", "Kingdom of Sweden", "SE"],
  ["Eswatini", "Kingdom of Eswatini", "SZ"],
  ["Sint Maarten", "Sint Maarten", "SX"],
  ["Seychelles", "Republic of Seychelles", "SC"],
  ["Syria", "Syrian Arab Republic", "SY"],
  ["Turks and Caicos Islands", "Turks and Caicos Islands", "TC"],
  ["Chad", "Republic of Chad", "TD"],
  ["Togo", "Togolese Republic", "TG"],
  ["Thailand", "Kingdom of Thailand", "TH"],
  ["Tajikistan", "Republic of Tajikistan", "TJ"],
  ["Tokelau", "Tokelau", "TK"],
  ["Turkmenistan", "Turkmenistan", "TM"],
  ["Timor-Leste", "Democratic Republic of Timor-Leste", "TL"],
  ["Tonga", "Kingdom of Tonga", "TO"],
  ["Trinidad and Tobago", "Republic of Trinidad and Tobago", "TT"],
  ["Tunisia", "Tunisian Republic", "TN"],
  ["Turkey", "Republic of Turkey", "TR"],
  ["Tuvalu", "Tuvalu", "TV"],
  ["Taiwan", "Republic of China (Taiwan)", "TW"],
  ["Tanzania", "United Republic of Tanzania", "TZ"],
  ["Uganda", "Republic of Uganda", "UG"],
  ["Ukraine", "Ukraine", "UA"],
  ["United States Minor Outlying Islands", "United States Minor Outlying Islands", "UM"],
  ["Uruguay", "Oriental Republic of Uruguay", "UY"],
  ["United States", "United States of America", "US"],
  ["Uzbekistan", "Republic of Uzbekistan", "UZ"],
  ["Vatican City", "Vatican City State", "VA"],
  ["Saint Vincent and the Grenadines", "Saint Vincent and the Grenadines", "VC"],
  ["Venezuela", "Bolivarian Republic of Venezuela", "VE"],
  ["British Virgin Islands", "Virgin Islands", "VG"],
  ["United States Virgin Islands", "Virgin Islands of the United States", "VI"],
  ["Vietnam", "Socialist Republic of Vietnam", "VN"],
  ["Vanuatu", "Republic of Vanuatu", "VU"],
  ["Wallis and Futuna", "Territory of the Wallis and Futuna Islands", "WF"],
  ["Samoa", "Independent State of Samoa", "WS"],
  ["Yemen", "Republic of Yemen", "YE"],
  ["South Africa", "Republic of South Africa", "ZA"],
  ["Zambia", "Republic of Zambia", "ZM"],
  ["Zimbabwe", "Republic of Zimbabwe", "ZW"],
];

export default COUNTRIES;
