import React from "react";

import ChannexDrawer from "drawers/channex_drawer";

import { ManageWhiteLabelPartner } from "./manage_white_label_partner/manage_white_label_partner";

export function EditDrawer({ visible, whiteLabelPartner, onClose }) {
  const title = "Edit White Label Partner";

  if (!whiteLabelPartner) {
    return null;
  }

  return (
    <ChannexDrawer title={title} visible={visible} onClose={onClose}>
      <ManageWhiteLabelPartner whiteLabelPartner={whiteLabelPartner} closeDrawer={onClose} />
    </ChannexDrawer>
  );
}
