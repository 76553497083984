import React from "react";
import { message } from "antd";

export default (WrappedComponent) => {
  class WithNotifications extends React.Component {
    componentDidMount() {
      const { location, navigate } = this.props;
      const notification = location && location.state && location.state.notification;
      const url = new URL(window.location.href);
      const error = url.searchParams.get("error");

      if (error) {
        message.error("Sorry, there is no user associated with this email address. Please try again or contact support.");
      }

      if (notification) {
        message.info(notification);

        navigate({
          ...location,
          state: undefined,
        });
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithNotifications;
};
