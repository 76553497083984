import { useMutation, useQueryClient } from "react-query";
import store from "store";

const { ExtraCategories } = store;

export function useCreateAction(propertyId) {
  const queryClient = useQueryClient();

  return useMutation(({ attrs }) => ExtraCategories.create(attrs), {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["extras", propertyId],
      });
    },
  });
}
