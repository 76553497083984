import React from "react";
import { useTranslation } from "react-i18next";

import FormItem from "components/forms/items/form_item";

import BookingEngineLinkWarning from "./booking_engine_link_warning";

import styles from "./booking_engine_link.module.css";

const DOMAIN_PREFIXES_BY_CODE = {
  GoogleHotelARI: "ghs",
  OpenShopping: "ibe",
  MemberButton: "ibe",
  HelpingHotels: "ibe",
  Reserva: "ibe",
  ReservaCars: "ibe",
  AbodeConnect: "ibe",
  HotelPoint: "ibe",
};

export default function BookingEngineLink({ form }) {
  const { t } = useTranslation();
  const { channel, id, settings } = form.values;
  const { slug: newSlug } = settings ?? {};
  const { slug } = form.initialValues.settings ?? {};
  let bookinEnginePrefix = DOMAIN_PREFIXES_BY_CODE[channel];

  if (bookinEnginePrefix && window.location.host === "staging.channex.io") {
    bookinEnginePrefix = "osa";
  }

  if (!bookinEnginePrefix) {
    return null;
  }

  const hotelId = slug || id;
  const newHotelId = newSlug || id;

  const baseLink = `https://${bookinEnginePrefix}.channex.io`;
  const shownLink = `${baseLink}/${newHotelId}`;
  const actualLink = `${baseLink}/${hotelId}`;
  const isWarningShown = actualLink !== shownLink;

  return (
    <FormItem
      label={t("channels_page:form:booking_engine_link")}
      extra={<BookingEngineLinkWarning visible={isWarningShown} />}
    >
      {newHotelId ? (
        <a
          data-cy="booking_engine_link"
          href={actualLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          {shownLink}
        </a>
      ) : (
        <span className={styles.placeholder}>
          {t("channels_page:form:booking_engine_link_placeholder")}
        </span>
      )}
    </FormItem>
  );
}
