import React from "react";
import { DatePicker } from "antd";

import dayjs from "utils/dayjs";

export default function DateRange({ value, onChange }) {
  const today = dayjs().endOf("day");
  const ninetyDaysAgo = dayjs().subtract(90, "days").startOf("day");

  return (
    <DatePicker.RangePicker
      style={{ width: "100%" }}
      value={value}
      onChange={onChange}
      disabledDate={(date) => today.isBefore(date) || ninetyDaysAgo.isAfter(date)}
      allowEmpty={[true, true]}
    />
  );
}
