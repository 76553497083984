import React from "react";

import styles from "./styles.module.css";

export function EventDescription({ children }) {
  return (
    <div className={styles.description}>
      {children}
    </div>
  );
}
