import React from "react";
import classnames from "classnames";

import styles from "./icon_placeholder.module.css";

export default function IconPlaceholder({ Icon, text, className, dataCy, centerPlacement = true }) {
  const contentClassName = classnames(className, {
    [styles.content]: centerPlacement,
  });

  return (
    <div className={contentClassName} data-cy={dataCy}>
      <Icon className={styles.icon} />
      <br />
      {text}
    </div>
  );
}
