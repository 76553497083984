import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./form_table_select.module.css";

export default function FormTableSelectLabel({ text, selected = 0, total = 0 }) {
  const { t } = useTranslation();

  return (
    <div>
      <div>{text}</div>
      <div className={styles.selectCount}>{`${selected} ${t("table_select:of")} ${total}`}</div>
    </div>
  );
}
