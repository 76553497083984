import React from "react";

import styles from "./item.module.css";

export default function Item({ term, description }) {
  return (
    <>
      <dt className={styles.term}>{term}:</dt>
      <dd className={styles.description}>{description}</dd>
    </>
  );
}
