import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { pathBuilder } from "routing";
import withRouter from "routing/with_router";

class ChannelLinkContent extends Component {
  render() {
    const { t, routes, issue } = this.props;

    const pathname = pathBuilder(routes.userAppRoutes.channelEvents, { channelId: issue.channel_id });

    return <Link to={{ pathname }}>{t("issues_drawer:channel_link:open_channel")}</Link>;
  }
}

export default withTranslation()(withRouter(ChannelLinkContent));
