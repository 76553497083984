import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "antd";
import store from "store";

import Loading from "components/loading";

import tableStyles from "styles/tables.module.css";

const { AdminBillingAccounts } = store;

export default function Properties({ billingAccountId }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [properties, setProperties] = useState([]);

  useEffect(
    function loadBillingAccountProperties() {
      AdminBillingAccounts.properties(billingAccountId).then((response) => {
        setProperties(response.data.map((el) => el.attributes));
        setLoading(false);
      });
    },
    [billingAccountId],
  );

  const columns = [
    {
      title: t("general:title"),
      key: "title",
      render: (_text, record) => {
        return record.title;
      },
    },
  ];

  if (loading) {
    return <Loading />;
  }

  return (
    <Table
      columns={columns}
      className={tableStyles.fullTabTable}
      dataSource={properties}
      pagination={false}
      showHeader={false}
      rowKey="id"
    />
  );
}
