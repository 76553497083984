import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Tabs } from "antd";

import styles from "../reviews.module.css";

function Header({ isRepliedMode, handleSetFilter }) {
  const { t } = useTranslation();
  const handleChange = useCallback((val) => handleSetFilter(val === "true"), [handleSetFilter]);

  const items = [{
    label: t("reviews_page:filters:non_replied"),
    key: "false",
  }, {
    label: t("reviews_page:filters:replied"),
    key: "true",
  }];

  return (
    <Tabs items={items} activeKey={String(isRepliedMode)} className={styles.filters} onChange={handleChange} />
  );
}

Header.propTypes = {
  isRepliedMode: PropTypes.bool,
  handleSetFilter: PropTypes.func.isRequired,
};

export default Header;
