import { useTranslation } from "react-i18next";

import { Workflow } from "components/workflow/workflow";

import ConfirmationStep from "./confirmation_step";
import ImportStep from "./import_step";
import PropertySearchForm from "./property_search_form";

export default function TriplaImportWorkflow() {
  const { t } = useTranslation();

  const STEPS = [{
    title: t("applications_page:tripla_import:steps:property_data"),
    render: (workflowProps) => (
      <PropertySearchForm {...workflowProps} />
    ),
  }, {
    title: t("applications_page:tripla_import:steps:confirmation"),
    nextButtonTitle: t("applications_page:tripla_import:steps:confirmation_next_button"),
    render: (workflowProps) => (
      <ConfirmationStep {...workflowProps} />
    ),
  }, {
    title: t("applications_page:tripla_import:steps:import"),
    render: (workflowProps) => (
      <ImportStep {...workflowProps} />
    ),
  }];

  return (
    <Workflow
      steps={STEPS}
    />
  );
}
