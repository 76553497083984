import React from "react";

import ContentContainer from "../../../../_common/content_container";

import BookingCom from "./by_ota/booking_com";
import { Feedback } from "./feedback";

const FEEDBACK_COMPONENTS = {
  BookingCom,
};
const DEFAULT_FEEDBACK = Feedback;

export default function GuestFeedback({ review }) {
  const ota = review.attributes.ota;

  const FeedbackComponent = FEEDBACK_COMPONENTS[ota] ?? DEFAULT_FEEDBACK;

  return (
    <ContentContainer quotes border>
      <FeedbackComponent review={review} />
    </ContentContainer>
  );
}
