import React from "react";
import Avatar from "react-avatar";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { getAppMode } from "store/storage/selectors/session_selector";

import appModes from "config/constants/app_modes";
import channelCodes from "config/constants/channels/channel_codes";

import BookingViewControlledDrawer from "drawers/booking_view_controlled_drawer";

import { pathBuilder } from "routing";
import useRouting from "routing/use_routing";

import dayjs from "utils/dayjs";
import useBoolState from "utils/use_bool_state";

import ChatHeaderActions from "./chat_header_actions";
import ChatHeaderDropDown from "./chat_header_drop_down";

import styles from "./dialog.module.css";

const DATE_FORMAT = "Do MMM YYYY";

const buildBookingInfo = (t, booking) => {
  if (!booking) {
    return null;
  }

  const { arrival_date, departure_date, rooms, properties } = booking;
  const arrivalDate = dayjs(arrival_date);
  const departureDate = dayjs(departure_date);

  const formattedArrival = arrivalDate.format(DATE_FORMAT);
  const lengthOfStay = departureDate.diff(arrivalDate, "day");
  const propertyTitles = properties
    ? Object.values(properties)
      .map((property) => property.title)
      .join(", ")
    : "";

  const lengthOfStayMessage = t("messages_page:dialog:length_of_stay", { amount: lengthOfStay });
  const roomNumberMessage = t("messages_page:dialog:room_amount", { amount: rooms?.length });

  return `${t(
    "messages_page:dialog:arriving",
  )}: ${formattedArrival}, ${roomNumberMessage}, ${lengthOfStayMessage} (${propertyTitles})`;
};

function DialogHeader({ isMobile, bookingParams, thread, handleCloseThread, handleOpenThread }) {
  const { t } = useTranslation();
  const [isOpenBooking, setOpenBooking, setCloseBooking] = useBoolState(false);
  const { userAppRoutes } = useRouting();
  const { relationships, attributes } = thread;
  const { title, provider, isClosed } = attributes;
  const { booking } = relationships;
  const path = pathBuilder(userAppRoutes.messages);
  const { title: channelTitle, icon } = channelCodes[provider];
  const bookingInfo = buildBookingInfo(t, bookingParams);
  const appMode = useSelector(getAppMode);
  const isHeadless = appMode === appModes.HEADLESS;
  const hiddenTabs = isHeadless ? ["revisions", "messages", "timeline"] : [];
  const hiddenFields = isHeadless ? ["channel", "reservation_id", "ruid", "property", "revision_id"] : [];

  return (
    <>
      {isMobile && (
        <NavLink to={`${path}?is_active=${!isClosed}`} className={styles.backLink}>
          <ArrowLeftOutlined />
          {t("messages_page:dialog:back_link")}
        </NavLink>
      )}
      <div className={styles.header}>
        <div>
          <div className={styles.headerTitleWrapper}>
            <p className={styles.headerTitle}>{title}</p>
            <Avatar
              name={channelTitle}
              size={24}
              src={icon}
              className={styles.headerProviderLogo}
              round
            />
            <p className={styles.headerProvider}>{channelTitle}</p>
          </div>
          {booking && <p className={styles.bookingInfo}>{bookingInfo || t("general:loading")}</p>}
        </div>
        {!isMobile && (
        <ChatHeaderActions
          handleCloseThread={handleCloseThread}
          handleOpenThread={handleOpenThread}
          handleOpenBooking={booking && setOpenBooking}
          isClosed={isClosed}
        />
        )}
        {isMobile && (
        <ChatHeaderDropDown
          handleCloseThread={handleCloseThread}
          handleOpenThread={handleOpenThread}
          handleOpenBooking={booking && setOpenBooking}
          isClosed={isClosed}
        />
        )}
      </div>
      {booking && (
        <BookingViewControlledDrawer
          bookingId={booking.data.id}
          visible={isOpenBooking}
          onClose={setCloseBooking}
          hiddenTabs={hiddenTabs}
          hiddenFields={hiddenFields}
          hideActions={isHeadless}
        />
      )}
    </>
  );
}

DialogHeader.propTypes = {
  thread: PropTypes.object,
  handleCloseThread: PropTypes.func,
  handleOpenThread: PropTypes.func,
};

export default DialogHeader;
