import React, { Component } from "react";

class WizardStep extends Component {
  form = React.createRef();

  beforeLeave = () => {
    if (!this.form.current) {
      return Promise.reject();
    }

    return this.form.current.validate ? this.form.current.validate() : Promise.resolve();
  };

  render() {
    const { value, errors, onChange, content, contentProps } = this.props;

    const UpdatedContent = React.cloneElement(content, {
      componentRef: this.form,
      value,
      errors,
      contentProps,
      onChange,
    });

    return UpdatedContent;
  }
}

export default WizardStep;
