import { useCallback, useEffect, useState } from "react";

const prepareInitialSelectedItems = (value) => {
  if (!value) {
    return {};
  }

  return value.reduce((acc, itemId) => {
    acc[itemId] = { selected: true };
    return acc;
  }, {});
};

export const useSelectState = ({ value, items, onChange }) => {
  const [allSelected, setAllSelected] = useState(false);
  const [allIndeterminate, setAllIndeterminate] = useState(false);
  const [selectedItems, setSelectedItems] = useState(() => prepareInitialSelectedItems(value));

  const toggleItem = useCallback((item) => {
    setSelectedItems((currentSelectedItems) => {
      const isSelected = currentSelectedItems[item.id]?.selected || false;

      const newSelectedItems = {
        ...currentSelectedItems,
        [item.id]: {
          selected: !isSelected,
        },
      };

      return newSelectedItems;
    });
  }, [setSelectedItems]);

  const toggleAll = useCallback((event) => {
    const state = event.target.checked;

    const newSelectedItems = items.reduce((acc, item) => {
      acc[item.id] = { selected: state };
      return acc;
    }, {});

    setSelectedItems(newSelectedItems);
  }, [items, setSelectedItems]);

  useEffect(() => {
    const newAllSelected = items.every(({ id }) => selectedItems[id]?.selected);
    const newAllIndeterminate = newAllSelected === false && items.some(({ id }) => selectedItems[id]?.selected);

    setAllSelected(newAllSelected);
    setAllIndeterminate(newAllIndeterminate);
  }, [selectedItems, items]);

  useEffect(() => {
    const selectedIds = Object.keys(selectedItems).filter((itemId) => selectedItems[itemId].selected);
    onChange(selectedIds);
  }, [selectedItems, onChange]);

  return {
    allSelected,
    allIndeterminate,
    selectedItems,
    toggleItem,
    toggleAll,
  };
};
