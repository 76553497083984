import React from "react";

import ChannexDrawer from "drawers/channex_drawer";

import DrawerFormWrapper from "components/drawer_form_wrapper";

import UserSelectForm from "./user_select_form";

export default function UserSelectDrawer({ applicationId, visible, onClose }) {
  return (
    <ChannexDrawer title="Select user" visible={visible} onClose={onClose}>
      {() => (
        <DrawerFormWrapper>
          <UserSelectForm applicationId={applicationId} onFinish={onClose} />
        </DrawerFormWrapper>
      )}
    </ChannexDrawer>
  );
}
