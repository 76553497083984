import * as yup from "yup";

import errorMessages from "config/constants/errors";

export const validationSchema = yup.object().shape({
  title: yup.string().trim().required(errorMessages.required()),
  domain: yup.string().trim().required(errorMessages.required()),
  favicon: yup.string().trim().required(errorMessages.required()),
  logoBig: yup.string().trim().required(errorMessages.required()),
  logoSmall: yup.string().trim().required(errorMessages.required()),
});
