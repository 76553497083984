import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DownOutlined, EditOutlined } from "@ant-design/icons";
import { Dropdown } from "antd";

import { removeMenuItem } from "components/menu/items/remove";

import { pathBuilder } from "routing";
import useRouting from "routing/use_routing";

import generalStyles from "styles/general.module.css";

export default function GroupsEntryMenu({ record, onDelete }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userAppRoutes } = useRouting();

  const items = [{
    key: "group_crud_entry_edit_action",
    onClick: () => navigate(pathBuilder(userAppRoutes.groups.edit, { groupId: record.id })),
    label: (
      <div data-cy="group_crud_entry_edit_action">
        <EditOutlined /> {t("general:action:edit")}
      </div>
    ),
  }, {
    type: "divider",
  },
  removeMenuItem({
    onRemove: () => onDelete(record.id),
    disabled: record.properties.length > 0,
  }),
  ];

  return (
    <Dropdown menu={{ items }} trigger={["click"]}>
      <a
        data-cy="crud_entry_actions_menu"
        className={generalStyles.actionsToggle}
        onClick={(e) => e.preventDefault()}
      >
        {t("general:actions")} <DownOutlined />
      </a>
    </Dropdown>
  );
}
