import React from "react";
import PropTypes from "prop-types";
import { Typography } from "antd";
import classNames from "classnames";

import styles from "./auth_form_title.module.css";

function AuthFormTitle({ children, isOnly = false }) {
  return (
    <Typography.Title
      level={4}
      className={classNames(styles.title, {
        [`${styles.titleWithSubTitle}`]: !isOnly,
      })}
    >
      {children}
    </Typography.Title>
  );
}

AuthFormTitle.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  isOnly: PropTypes.bool,
};

export default AuthFormTitle;
