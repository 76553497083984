import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "antd";

const columnLayout = { xs: { span: 24 }, sm: { span: 16, offset: 8 } };

function ChooseRoomTypeMessage({ t }) {
  return (
    <Row>
      <Col {...columnLayout}>{t("rates_page:form:choose_room_msg")}</Col>
    </Row>
  );
}

ChooseRoomTypeMessage.propTypes = {
  t: PropTypes.func,
};

export default ChooseRoomTypeMessage;
