import React from "react";
import { useSelector } from "react-redux";
import { getAppMode } from "store/storage/selectors/session_selector";

import APP_MODES from "config/constants/app_modes";

import SignTermsAndConditionsAgreement from "./sign_terms_and_conditions_agreement";

function isChannexDomain() {
  const hostname = window.location.hostname;

  return hostname === "channex.io" || hostname.endsWith(".channex.io");
}

function isLocalhost() {
  const hostname = window.location.hostname;

  return hostname === "localhost";
}

export default function EntryPoint() {
  const appMode = useSelector(getAppMode);

  if (appMode === APP_MODES.HEADLESS) {
    return null;
  }

  if (!isChannexDomain() && !isLocalhost()) {
    return null;
  }

  return <SignTermsAndConditionsAgreement />;
}
