import React from "react";
import { useTranslation } from "react-i18next";

import * as Page from "components/page";

import EmptyIcon from "static/empty_search_icon.svg";

export default function QueryErrorPage() {
  const { t } = useTranslation();

  return (
    <Page.Main>
      <Page.ErrorMessage text={t("query_error_page:message")} icon={EmptyIcon} />
    </Page.Main>
  );
}
