import React, { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "antd";
import * as yup from "yup";

import ChannexForm from "components/channex_form";
import SubmitButton from "components/forms/buttons/submit_button";
import FormikCheckbox from "components/forms/inputs/formik/form_checkbox";
import FormItem from "components/forms/items/form_item";

import useBoolState from "utils/use_bool_state";

import styles from "./user_ui_settings_view.module.css";

const getSchema = () => yup.object().shape({
  showNotifications: yup.boolean(),
});

export default function UserUiSettingsView(props) {
  const { value, defaultValue, errors, onChange, onSubmit } = props;
  const [loading, setLoading, setLoadingComplete] = useBoolState(false);
  const { t } = useTranslation();
  const formRef = useRef();

  const handleSubmitClick = () => {
    setLoading();

    formRef.current
      .validate()
      .then(() => onSubmit(value))
      .then(setLoadingComplete);
  };

  const validationSchema = useMemo(getSchema, []);

  return (
    <main className={styles.profileSection}>
      <div className={styles.header}>
        <p className={styles.title}>{t("user_profile_page:ui_settings_legend")}</p>
      </div>
      <div data-cy="user_ui_settings" className={styles.formWrapper}>
        <ChannexForm
          defaultValue={defaultValue}
          value={value}
          componentRef={formRef}
          errors={errors}
          validationSchema={validationSchema}
          onChange={onChange}
        >
          {({ handleSubmit }) => (
            <Form onFinish={handleSubmit}>
              <FormikCheckbox
                name="showNotifications"
                label={t("user_profile_page:show_notifications")}
              />
            </Form>
          )}
        </ChannexForm>
        <FormItem label colon={false}>
          <SubmitButton loading={loading} disabled={loading} onClick={handleSubmitClick}>
            {t("general:action:save")}
          </SubmitButton>
        </FormItem>
      </div>
    </main>
  );
}
