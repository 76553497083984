import { Alert, Col, Row } from "antd";

import { horizontalFormItemLayout } from "config/constants/layouts/form";

export const InputWarning = ({ message }) => {
  if (!message) {
    return null;
  }

  return (
    <Row>
      <Col {...horizontalFormItemLayout.labelCol} />
      <Col {...horizontalFormItemLayout.wrapperCol}>
        <Alert message={message} type="warning" />
      </Col>
    </Row>
  );
};
