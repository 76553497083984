import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  CheckCircleOutlined,
  LoadingOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { Button, Col, Row } from "antd";

import FormMessage from "components/forms/message/form_message";

import ConnectionSettingsForm from "../connection_settings_form";

import styles from "./connection_settings_components.module.css";

class ConnectionSettings extends Component {
  static propTypes = {
    t: PropTypes.func,
  };

  shouldRenderTestConnection = (props) => {
    let result = true;

    if (props.form.values.channel === "VRBO") {
      result = props.form.values.settings?.token?.status === "verified";
    }

    return result;
  };

  render() {
    const {
      t,
      settingsSchema,
      connectionStatus,
      connectionErrors,
      onTestConnectionClick,
      mappingSchema,
      form,
      namespace,
      focusField,
      handleFieldChange,
    } = this.props;

    return (
      <div>
        <legend>{t("channels_page:form:connection_settings")}</legend>
        {!settingsSchema && (
          <FormMessage message={t("channels_page:form:choose_channel")} />
        )}
        {settingsSchema && (
          <div>
            <ConnectionSettingsForm
              settingsSchema={settingsSchema}
              mappingSchema={mappingSchema}
              form={form}
              namespace={namespace}
              onChange={handleFieldChange}
              focusField={focusField}
            />
            {this.shouldRenderTestConnection(this.props) && (
              <div>
                <Row data-cy="connection">
                  <Col md={8} />
                  <Col md={16}>
                    <Row>
                      {connectionStatus === null && (
                        <Col span={12} className={styles.status} />
                      )}
                      {connectionStatus && connectionStatus === "loading" && (
                        <Col span={12} className={styles.status}>
                          <LoadingOutlined />
                          &nbsp;
                          {t("channels_page:connection_test:testing")}
                        </Col>
                      )}
                      {connectionStatus && connectionStatus === "success" && (
                        <Col
                          span={12}
                          className={styles.status}
                          data-cy="successful_connection"
                        >
                          <CheckCircleOutlined
                            className={styles.status__successIcon}
                          />
                          &nbsp;
                          {t("channels_page:connection_test:success")}
                        </Col>
                      )}
                      {connectionStatus && connectionStatus === "error" && (
                        <Col
                          span={12}
                          className={styles.status}
                          data-cy="incorrect_connection"
                        >
                          <WarningOutlined
                            className={styles.status__warningIcon}
                          />
                          &nbsp;
                          {t("channels_page:connection_test:incorrect")}
                        </Col>
                      )}
                      <Col
                        span={12}
                        className={styles.status__checkButtonContainer}
                      >
                        <Button
                          onClick={onTestConnectionClick}
                          data-cy="test_connection"
                        >
                          {t("channels_page:connection_test:test_connection")}
                        </Button>
                      </Col>
                    </Row>
                    {connectionErrors && (
                      <Row>
                        <Col
                          span={24}
                          className={styles.status__errorMessage}
                          data-cy="error"
                        >
                          {t(`channels_page:connection_test:errors:${connectionErrors}`)}
                        </Col>
                      </Row>
                    )}
                  </Col>
                </Row>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default ConnectionSettings;
