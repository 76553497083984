const PER_PERSON_MODE_CHANNELS = [
  "AdvertisingOnline",
  "Agoda",
  "AirBNB",
  "BookingCom",
  "Cultbooking",
  "CultBooking",
  "Despegar",
  "Expedia",
  "GoogleHotelARI",
  "HeyIceland",
  "Hostelworld",
  "HyperGuest",
  "LocalOTA",
  "MemberButton",
  "HelpingHotels",
  "Reserva",
  "ReservaCars",
  "OpenChannel",
  "OpenGDS",
  "OpenShopping",
  "RoomCloud",
  "TheSquare",
  "VerticalBooking",
  "AbodeConnect",
  "CTrip",
  "Webrooms",
  "Travia",
  "Bakuun",
  "stayforRewards",
  "Traveloka",
  "Spot2Nite",
  "ChannexExpress",
  "Tripla",
  "OTASync",
  "VRBO",
  "TiketCom",
  "Goibibo",
  "DreamIreland",
  "MGBedbank",
  "Travelgatex",
  "Feratel",
  "HRS",
  "OmniHotelier",
  "Guestaps",
  "Hertz",
  "Avis",
  "Budget",
  "Payless",
  "Europcar",
  "AtlantisORS",
  "Hipcamp",
  "ACEBookingEngine",
  "Wihp",
  "PriceTravel",
  "HotelNetwork",
  "Getaroom",
  "Ostrovok",
  "HotelPoint",
  "Szallas",
  "Wink",
  "Webready",
  "Hookusbookus",
  "Bookeasy",
  "Hotelbeds",
  "Hopper",
  "RoomBeast",
  "Reconline",
  "STC",
  "Roibos",
  "BookOutdoors",
  "HostelHop",
  "DidaTravel",
  "HotelTonight",
  "TheTPMGroup",
  "SleepRest",
  "Moverii",
  "TabletHotels",
  "SeminyakVillas",
  "SissaeLiving",
  "B2BGlobal",
  "RVPARKGURU",
  "CampingVision",
  "Bookola",
];

export default (channel) => PER_PERSON_MODE_CHANNELS.includes(channel);
