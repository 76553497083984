import React from "react";
import { useTranslation } from "react-i18next";

import Text from "../../../../../_common/text";

export default function ReplyMessage({ review }) {
  const { t } = useTranslation();
  const { attributes: { reply } } = review;

  if (!reply?.reply) {
    return (
      <Text disabled>{t("reviews_page:feedback:no_reply")}</Text>
    );
  }

  return (
    <Text>{reply.reply}</Text>
  );
}
