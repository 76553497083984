import React, { useCallback, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import PropTypes from "prop-types";
import { Timeline } from "antd";
import classNames from "classnames";
import _ from "lodash";

import { toLocalFromNow } from "utils/dates";
import usePagination from "utils/use_pagination";

import { getIsEventClickable } from "./common";
import EventsListLoader from "./events_list_loader";
import EventsListTimeLineItem from "./events_list_time_line_item";

import styles from "./events_list.module.css";

const DEBOUNCE_TIME = 500;

function EventsList({
  list,
  meta,
  handleLoadFeedEvents,
  loading,
  activeProperty,
  activeGroup,
  eventTypes,
}) {
  const { nextPage, isLastPage } = usePagination(meta);
  const next = _.debounce(() => {
    handleLoadFeedEvents(activeProperty, activeGroup, eventTypes, nextPage);
  }, DEBOUNCE_TIME);

  const infiniteScrollClassName = classNames(styles.inner, {
    [`${styles.inner__loading}`]: loading,
  });

  const [items, setItems] = useState([]);

  const buildItems = useCallback(() => {
    return list.map(({ attributes }) => {
      const { event } = attributes;
      const isClickable = getIsEventClickable(event);

      const itemClassName = classNames(styles.item, styles[`item--${event}`], {
        [`${styles.item__clickable}`]: isClickable,
      });

      return {
        label: toLocalFromNow(attributes.inserted_at),
        className: itemClassName,
        children: <EventsListTimeLineItem attributes={attributes} />,
      };
    });
  }, [list]);

  useEffect(() => {
    const interval = setInterval(() => {
      setItems(buildItems());
    }, 60000);

    setItems(buildItems());

    return () => clearInterval(interval);
  }, [buildItems]);

  return (
    <div className={styles.wrapper}>
      <InfiniteScroll
        dataLength={list.length}
        next={next}
        hasMore={!isLastPage}
        loader={<EventsListLoader />}
        height="calc(100vh - 153px)"
        className={infiniteScrollClassName}
      >
        <Timeline mode="left" items={items} />
      </InfiniteScroll>
    </div>
  );
}

EventsList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  meta: PropTypes.shape({}),
  loading: PropTypes.bool,
  handleLoadFeedEvents: PropTypes.func.isRequired,
};

export default EventsList;
