export default {
  apart_hotel: "hotel",
  apartment: "vacation_rental",
  boat: "vacation_rental",
  camping: "hotel",
  capsule_hotel: "hotel",
  chalet: "vacation_rental",
  country_house: "vacation_rental",
  farm_stay: "vacation_rental",
  guest_house: "hotel",
  holiday_home: "vacation_rental",
  holiday_park: "hotel",
  homestay: "hotel",
  hostel: "hotel",
  hotel: "hotel",
  inn: "hotel",
  lodge: "hotel",
  motel: "hotel",
  resort: "hotel",
  riad: "hotel",
  ryokan: "hotel",
  tent: "hotel",
  villa: "vacation_rental",
};
